import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {Link, useLocation} from 'react-router-dom'
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import styled  from 'styled-components'
import Routes from '../config/routes'
import {useTranslation} from "react-i18next";
import {getUserRole} from "../helper/acl";


const BoxStyled = styled(Box)`
  .MuiBox-root {
    background-color: var(--bazookka-darkblue);
    color: var(--bazookka-blue);
    min-width: 300px !important;
  }
  .MuiButtonBase-root:hover {
    background-color: var(--bazookka-blue);
  }

  div.visited {
    background-color: var(--bazookka-orange);
    width: 100%;
  }

  .MuiListItem-root:hover {
    background-color: var(--bazookka-pink);
  }

  div.visited:hover {
    background-color: purple;
  }
  

  i {
    color: white;
    height:fit-content;
    margin:auto 8px;
  }
  a {
    display: flex;
    flex-direction: row;
    color: white;
    font-size: 16px;
  }
`
const DrawerStyled = styled(Drawer)`
  .MuiPaper-root {
    background-color: var(--bazookka-blue);
    color: var(--bazookka-blue);
    height: fit-content;
  }
  
  i {
    color: var(--bazookka-white);
    height:fit-content;
    margin:auto 8px;
  }
  
  a {
    display: flex;
    flex-direction: row;
    color: var(--bazookka-white);
    
  }
`

const list = (anchor, me, t, toggleDrawer = function(){}, location, userRole) => (

    <BoxStyled
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
    >
        <List sx={{ marginTop: "10px"}}>
            {Routes
                .filter(route => {
                    if(route.locales && route.locales.includes("me")) {
                        return true;
                    }
                    return  false;
                })
                .filter(route => {
                    if(route.roles.includes(userRole)) {
                        return true;
                    }
                    return false;
                })
                .map((route, index) => (
                    <Link to={route.path} className={location.pathname === route.path ? 'visited' : ''} key={index}>
                        <ListItem button  className={location.pathname === route.path ? 'visited' : ''}>


                            <ListItemIcon>
                                {route.ico}
                            </ListItemIcon>
                            <ListItemText primary={t(route.label)} />


                    </ListItem>
                    </Link>
                ))}
        </List>
    </BoxStyled>
);

export default function TemporaryDrawer({toggle, toggleDrawer, me}) {
    const {t} = useTranslation()
    const location = useLocation()
    const [userRole, setUserRole] = useState()

    useEffect(()=> {
        if(me) {
            getUserRole(me?.select?.id).then( role => {
                setUserRole(role)
            })
        }

    }, [userRole])

    const anchor = 'left'
    return (
        <div>
            <React.Fragment>
                {/*<Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>*/}
                <DrawerStyled
                    anchor={anchor}
                    open={toggle}
                    onClose={toggleDrawer(anchor, false)}
                >
                    {list(anchor, me, t, toggleDrawer, location, userRole)}
                </DrawerStyled>
            </React.Fragment>

        </div>
    );
}


export const InnerMenu = ({me, t}) => {
    const location = useLocation()
    const [userRole, setUserRole] = useState()

    useEffect(()=> {
        if(me) {
            getUserRole(me?.select?.id).then( role => {
                setUserRole(role)
            })
        }

    }, [userRole])
    return (
        <>
            {list("left", me, t, function(){}, location, userRole)}
        </>

    )
}
