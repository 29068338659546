import React, {useState, useContext, useEffect, useRef} from "react"
import {MyContext} from "../stores";
import {Route, Switch, useParams, Link, useRouteMatch} from "react-router-dom"
import {L10n} from '@syncfusion/ej2-base'
import {Search, GridComponent, ColumnDirective, ColumnsDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, Inject, Toolbar} from "@syncfusion/ej2-react-grids";
import useSWR from 'swr'
import Avatar from "react-avatar";
import {useTranslation} from "react-i18next";
import Loader from '../components/Loader'
import {useSocket} from "../stores/socketProvider";

const CallableLink = (props) => {
    const {path} = useRouteMatch()
    const onSubmit = () => {
        alert(JSON.stringify(props))
    }
    return (
        <Link to={`${path}/edit/${props.id}`} className="bg-blue-700 p-2 rounded-l font-white" onClick={onSubmit}>
            color
        </Link>
    )
}

const AvatarHeader = (props) => {
    return <Avatar name={props.display_name} size={40} round={true}/>
}

const companyGrid = [
    {
        width: '50',
        headerText: 'Avatar',
        template: AvatarHeader
    },
    {
        headerText: 'log.groupsLogDataTable.nameColumn',
        field: 'display_name',
        width: '200',
    },
    {
        headerText: 'general.role',
        width: '200',
        template: function Display(props){
            const {t} = useTranslation()
            return (
                <div>
                    {t(props.role)}
                </div>
            )
        }
    },
    {
        headerText: 'log.groupsLogDataTable.entrepriseColumn',
        field: 'name',
        width: '200',
        textAlign: 'center'
    }
]

const StatsComponent = () => {
    const {globalId} = useContext(MyContext)
    const {data, error} = useSWR(`https://api.bazookka.com/stats`,
            url => fetch(url, {method: 'get', headers: {eid: globalId, 'Content-Type': 'application/json'}})
                .then( r => r.json()))

    if(!data) return <Loader></Loader>
    return (
        <div className="flex flex-wrap mb-2">
            <div className="w-full md:w-1/2 xl:w-1/2 pt-3 px-3 md:pl-2">
                <div className="bg-blue-600 border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pl-1 pr-4"><i className="fas fa-users fa-2x fa-fw fa-inverse"></i>
                        </div>
                        <div className="flex-1 text-right">
                            <h5 className="text-white">Utilisateurs Montréal Relève</h5>
                            <h3 className="text-white text-3xl">{data.kids + data.mentors + data.admins} <span className="text-blue-400"><i
                                className="fas fa-caret-up"></i></span></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-1/2 pt-3 px-3 md:pr-2 xl:pr-3 xl:pl-1">
                <div className="bg-orange-600 border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pl-1 pr-4"><i
                            className="fas fa-user-plus fa-2x fa-fw fa-inverse"></i></div>
                        <div className="flex-1 text-right pr-1">
                            <h5 className="text-white">Les stagiaires</h5>
                            <h3 className="text-white text-3xl">{data.kids} <span className="text-orange-400"><i
                                className="fas fa-caret-up"></i></span></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-1/2 pt-3 px-3 md:pl-2 xl:pl-3 xl:pr-2">
                <div className="bg-purple-600 border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pl-1 pr-4"><i className="fas fa-server fa-2x fa-fw fa-inverse"></i>
                        </div>
                        <div className="flex-1 text-right">
                            <h5 className="text-white">Les mentors</h5>
                            <h3 className="text-white text-3xl">{data.mentors}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-1/2 pt-3 px-3 md:pr-2 xl:pl-2 xl:pr-3">
                <div className="bg-red-600 border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pl-1 pr-4"><i className="fas fa-tasks fa-2x fa-fw fa-inverse"></i>
                        </div>
                        <div className="flex-1 text-right">
                            <h5 className="text-white">Les administrateurs</h5>
                            <h3 className="text-white text-3xl">{data.admins}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const fetcher = (url) => fetch(url).then((res) => res.json());
export default function UsersView(){
    const {t} = useTranslation()
    const ref = useRef()
    let {me, globalId, langChoice,setI10nLoadContent} = useContext(MyContext)
    const socket = useSocket()

    useEffect(() => {
        socket.on("status", msg => {
            if(msg.message === 'pending') {
                ref.current.classList.add('bg-gray-800')
                ref.current.setAttribute('disabled', true)
            }
            if(msg.message === 'done') {
                ref.current.classList.remove('bg-gray-800')
                ref.current.setAttribute('disabled', false)
            }
        })
        setI10nLoadContent(
            {
                'fr-FR': {
                    'grid': {
                        'EmptyDataSourceError': 'Erreur de chargement des données',
                        'EmptyRecord': `Aucun utilisateur á charger`,
                        'Item': 'utilisateur',
                        'Items': 'utilisateurs',
                        'name': 'nom',
                        'Search': "Chercher"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} de {1}',
                        'totalItemsInfo': '({0} utilisateurs)',
                        'totalItemInfo': '({0} utilisateur)'
                    }
                },
                'en-US': {
                    'grid': {
                        'EmptyDataSourceError': 'Error loading data',
                        'EmptyRecord': `There's no users to load`,
                        'Item': 'user',
                        'Items': 'users',
                        'name': 'nom',
                        'Search': "Search"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} of {1}',
                        'totalItemsInfo': '({0} users)',
                        'totalItemInfo': '({0} user)'
                    }
                }
            }
        )

    },[])
    const {data, error} = useSWR(`https://api.bazookka.com/users/me`,
            url => fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'eid': globalId
                },
                method: 'post',
                body: JSON.stringify({id: globalId})}).then(r => r.json()))

    function loadUsers(){
        // fetch(`https://api.bazookka.com/imports/students`, {method: 'get', headers: {eid: globalId}})
        //     .then(r => r.json())
        socket.emit("status", {import: globalId})
    }


    if(!data) {
        return <h1>LOADING!!!!!</h1>
    }

    return (
        <div className="h-full">
            <StatsComponent></StatsComponent>
            <div className="flex flex-row w-[100%]" style={{paddingTop: '1rem', paddingBottom: '1rem'}}>
                <button ref={ref} className="flex justify-center cursor-pointer p-2 m-2 bg-green-600 text-white rounded" onClick={loadUsers}>
                    MAJ des étudiants
                </button>
            </div>
            <GridComponent
                locale={langChoice === "fr" ? "fr-FR" : "en-US"}
                id="gridcomp"
                dataSource={data.data.Items}
                allowPaging
                allowSorting
                pageSettings={{pageSize: 25}}
                contextMenuItems={['AutoFit']}
                toolbar={["Search"]}
            >
                <ColumnsDirective
                >
                    {companyGrid.map((item, index) => {
                        return <ColumnDirective
                            key={index}
                            {...item}
                            headerText={t(item.headerText)}
                        />
                    })}
                </ColumnsDirective>
                <Inject services={[Resize, Sort, Page, ContextMenu, Filter, Search, Toolbar]} />
            </GridComponent>
        </div>
    )
}