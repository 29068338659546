import React, {useState, useContext} from 'react'
import {MyContext} from "../stores";
import styled from 'styled-components'
import {CloseRounded, Share} from '@mui/icons-material'
import {WarningIco} from "../ico";
import {IconButton} from "@mui/material";
import {useTranslation} from "react-i18next";
import {
    FacebookIcon,
    FacebookShareButton,
    EmailIcon,
    EmailShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterShareButton,
    TwitterIcon
} from "react-share";

const CircleStyle = styled.div`
  * {
    color: var(--bazookka-pink);
  }
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${ props => props.size || '54px'};
  height: ${ props => props.size || '54px'};
  box-shadow: var(--bazookka-box-shadow);
  margin: 0 ${props => props.size && '0px' || '10px'};
  cursor: pointer;  
`

const ModalStyle = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: scroll;
  background: rgba(0,0,0,0.3);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal__share {
    width: 350px;
    color: white;
    background-color: white;
    border-radius: 10px;
    box-shadow: var(--bazookka-box-shadow);
    padding: 1rem;
    .body {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
    .top {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .title {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 15px;
      }
    }
    
  }
  
  
`


export function ShareBig({share, size}){
    const {t} = useTranslation()
    const {dispatchModal} = useContext(MyContext)
    const displayOver = () => {
        dispatchModal({
            type: "OPEN_MODAL",
            payload: {
                modalType: "ModalSocial",
                modalProps: {
                    title: t('general.share'),
                    ico: <Share />,
                    share,
                    closeOut: () => dispatchModal({ type: "CLOSE_MODAL" }),
                },
            },
        });
    }
    return (
        <CircleStyle size={size}>
            <div onClick={displayOver}>
                <Share />
            </div>
        </CircleStyle>
    )
}

export function ShareSmall({share}){
    return <div></div>
}

export function ModalSocial({ico, title, closeOut, share}){

    return (
        <ModalStyle>
            <div className="modal__share">
                <div className="top">
                    <div className="title">
                        {title}
                        {ico}
                    </div>
                    <div className="close">
                        <IconButton onClick={closeOut}>
                            <CloseRounded />
                        </IconButton>
                    </div>
                </div>
                <hr/>
                <div className="body">
                    <FacebookShareButton
                        {...{quote: share.title, url: `https://app.bazookka.com/jobs/${share.id}`}}
                    >
                        <FacebookIcon />
                    </FacebookShareButton>
                    <LinkedinShareButton
                        {...{summary: share.tiny, title: share.title, url: `https://app.bazookka.com/jobs/${share.id}`}}
                    >
                        <LinkedinIcon />
                    </LinkedinShareButton>
                    <TwitterShareButton
                        {...{via: share.tiny, title: share.title, url: `https://app.bazookka.com/jobs/${share.id}`}}
                    >
                        <TwitterIcon />
                    </TwitterShareButton>
                    <EmailShareButton
                        {...{subject: share.title, body: share.tiny}}
                    >
                        <EmailIcon  />
                    </EmailShareButton>

                </div>
            </div>
        </ModalStyle>
    )
}