import React, { useContext, useState, useEffect } from "react";
import { Droppable } from "react-beautiful-dnd";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { FormElementCard } from "./FormElementCard";
import ToggleElements from "./ToggleElements";

const SectionStyled = styled.div`
  .section {
    min-height: 100vh;
    height: max-content;
    background-color: #ffffff;
    padding: 0 0 0 0;
    /* padding: 0 0 1.5rem 0; */
  }
  .input-title {
    position: relative;
    border-radius: 5px;
    height: max-content;
    min-height: 100px;
    width: auto;
    margin: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  }
  #title {
    background-color: var(--bazookka-pink);
    text-align: center;
    padding: 15px;
    color: white;
  }
  .button-create {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }
`;

export const FormElementColumn = ({ droppableId, elements, openFormModal, setToggle, toggle }) => {
  if (droppableId === "Form Elements" || droppableId === "Prebuilt Elements") {
    return (
      <SectionStyled>
        <Droppable droppableId={droppableId}>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="section"
            >
              <h2 id="title">{droppableId}</h2>
              {elements.map((element, index) => {
                return (
                  <FormElementCard
                    key={element.id}
                    id={element.id}
                    index={index}
                    name={element.question}
                    element={element}
                    droppableId={droppableId}
                    openFormModal={openFormModal}
                  />
                );
              })}
              {provided.placeholder}
              {droppableId === "Form Elements" ? (
                <div className="button-create">
                  <button
                    className="button"
                    onClick={() => openFormModal("create")}
                  >
                    Create an element
                  </button>
                </div>
              ) : null}
                <ToggleElements setToggle={setToggle} toggle={toggle}/>
            </div>
          )}
        </Droppable>
      </SectionStyled>
    );
  }
};

export const FormPrefabElementColumn = ({ droppableId, elements, setToggle, toggle }) => {
  return (
    <SectionStyled>
      <Droppable droppableId={droppableId} isDropDisabled={true}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="section"
          >
            <h2 id="title">{droppableId}</h2>
            {elements.map((element, index) => {
              return (
                <FormElementCard
                  key={element.id}
                  id={element.id}
                  index={index}
                  name={element.question}
                  element={element}
                  droppableId={droppableId}
                />
              );
            })}
            {provided.placeholder}
              <ToggleElements setToggle={setToggle} toggle={toggle}/>
          </div>

        )}
      </Droppable>
    </SectionStyled>
  );
};

export const TemplateColumn = ({
  droppableId,
  elements,
  openFormModal,
  setFormTitle,
  handleTemplateCreation,
  clearElement,
  handlePrefabElement,
  title
}) => {
  const handleTitleInput = (e) => {
    setFormTitle(e.target.value);
  };

  return (
    <SectionStyled>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            // className="section"//Visual bug
          >
            <h2 id="title">{droppableId}</h2>

            <div className="input-title">
              <label>Form title: </label>
              <input
                type="text"
                name="title"
                value={title || ""}
                placeholder="Insert form title"
                onChange={handleTitleInput}
              />
            </div>
            {elements.map((element, index) => {
              return (
                <FormElementCard
                  id={element.id}
                  key={element.id}
                  index={index}
                  name={element.question}
                  element={element}
                  droppableId={droppableId}
                  openFormModal={openFormModal}
                  handlePrefabElement={handlePrefabElement}
                  clearElement={clearElement}
                />
              );
            })}
            {provided.placeholder}
            <div className="button-create">
              <button
                className="button"
                onClick={() => handleTemplateCreation()}
              >
                Save the form template
              </button>
            </div>
          </div>
        )}
      </Droppable>
    </SectionStyled>
  );
};
