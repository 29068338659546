import React, {useContext, useEffect, useState, useRef} from "react";
import {useHistory, useParams, Link} from "react-router-dom"
import {firebaseInstance, db, groupsDoc, usersDoc} from "../firestore/firestoreService";
import Avatar from "react-avatar";
import styled from 'styled-components'
import {Form, Field, Formik} from "formik";
import {InputText} from "../components/FormElements";
import {Multiselect} from "multiselect-react-dropdown";
import {useTranslation} from "react-i18next";
import {MyContext} from "../stores";
import dedupArray from '../utils/dedupArray';
import {useAlert} from 'react-alert'


const Cover = styled.div`
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      width: 900px;
      h1 {
        text-align: center;
        color: white;
        font-size: 2rem;
      }
    } 
    
  
    
`

const BoxFormStyled = styled.div`
  
  border: 1px solid #e8e8e8;
  background: white;
  padding: 2rem;
  border-radius: 5px;
  .groups-form {
    display: grid;
    grid-template-columns: 1fr 300px;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e8e8e8;
    .sidebar {
      border-left: 1px solid #e8e8e8;
      .field {
        width: 80%;
        margin: auto;
      }
    }
  }
`

const UserStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  width: 100%;
  margin-top: 20px;
  .users {
    display: grid;
    grid-template-columns: 56px 50px 1fr;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    padding: 8px;
    width: 100%;
    cursor: pointer;
    margin: auto;
    &.right {
      width: 90%;
    }
    .round .rdelete.toDelete{
        display: grid;
        opacity: 1;
        pointer-events: auto;
        transition: opacity 300ms ease-in;
    }
    :hover {
      background-color: var(--bazookka-blue);
      color: white;
      .button-control a {
        color: white;
      }
      .round{
        .rdelete {
          display: grid;
          opacity: 1;
          pointer-events: auto;
          transition: opacity 300ms ease-in;
        }
      }
      
    }
    .round {
      padding: 4px;
      border: 3px solid var(--bazookka-orange);
      width: fit-content;
      border-radius: 50%;
      position: relative;
      .rdelete {
        display: grid;
        align-items: center;
        justify-content: center;
        color: white;
        position: absolute;
        width: 54px;
        height: 54px;
        min-width: 54px;
        min-height: 54px;
        top:-3px;
        left: -3px;
        opacity: 0;
        pointer-events: none;
        border-radius: 50%;
        background-color: rgba(10,10,10,0.7);
        transition: opacity 300ms ease-in;
      }
      
    }
    .button-control {
      display: flex;
      justify-content: right;
      flex-direction: column;
    }
    .button-control a {
      display: block;
      text-align: end;
      font-size: 1rem;
    }
  }

`
const UserList = ({users, right, remove}) => {
    const ref = useRef()
    const {aremove, uremove, setaRemove, setuRemove} = useContext(MyContext)
    useEffect(()=> {

    }, [users])

    const onRemove = (user) =>{
        if(right) {
            if(aremove.find(u => u.id === user.id)) {
                setaRemove(aremove.filter( u => u.id !== user.id))
            }else{
                setaRemove([...aremove, user])
            }
        }else {
            if(uremove.includes(user.id)) {
                setuRemove(uremove.filter( u => u !== user.id))
            }else{
                setuRemove([...uremove, user.id])
            }
        }
    }

    return (
        <UserStyled>
            {users.map( (user, key)=> {
                let toDeleteClass;
                if(right){
                    if(aremove.find( u => u.id === user.id)){
                        toDeleteClass = "toDelete";
                    }else{
                        toDeleteClass = "";
                    }
                }else{
                    if(uremove.includes(user.id)){
                        toDeleteClass = "toDelete";
                    }else{
                        toDeleteClass = "";
                    }

                }
                return (
                    <div className={`users ${right || ''}`} key={key}>
                        <div className="round">
                            <div className={`rdelete ${toDeleteClass}`} onClick={()=> onRemove(user)}>
                                <i className="mdi mdi-delete"></i>
                            </div>
                            {user.avatar ?
                                <Avatar src={user.avatar} round={true} size={40}/> :
                                <Avatar round={true} size={40} name={user.displayName}/>}
                        </div>
                        {user.displayName}
                        <div className="button-control">
                            <Link to={`/user/${user.id}`} ><i className="mdi mdi-eye-outline"></i></Link>
                            <a><i className="mdi mdi-chat"></i></a>
                        </div>
                    </div>
                )
            } )}

        </UserStyled>
    )
}
export default function EditGroups(){
    const {id} = useParams()
    const history = useHistory()
    const {t} = useTranslation()
    const {me, globalId, langChoice, dispatchModal, aremove, uremove, setaRemove, setuRemove} = useContext(MyContext)
    const [users, setUsers] = useState([])
    const [admin, setAdmin] = useState([])
    const [userslist, setUsersList] = useState([])
    const [adminslist, setAdminsList] = useState([])
    const [apicall, setApicall] = useState(true)
    const [select, setSelect] = useState([])
    const [selectadmin, setSelectadmin] = useState([])
    const [group, setGroup] = useState(null)
    const alert = useAlert()
    // const [aremove, setaRemove] = useState([])
    // const [uremove, setuRemove] = useState([])
    const [initialValues, setValue] = useState({
        name: "",
        school: "",
        desc: "",
        groupChat: "false"
    })
    const GroupChatUserLimit = 100;
    const onAbort = () => {
        history.goBack()
    }

    const getComputedUserList = (userslist, adminslist, aremove, uremove, select, selectadmin) => {
        const fullDedupedList = dedupArray([...userslist, ...adminslist, ...select, ...selectadmin]);
        const filteredList = fullDedupedList.filter((user) => !aremove.includes(user) && !uremove.includes(user.id));
        return filteredList;
    }

    const onSubmit = async (values, {setSubmitting}) => {
        setSubmitting(true)
        const completeUserList = getComputedUserList(userslist, adminslist, aremove, uremove, select, selectadmin);
        let group = {...values};
        if(group.groupChat === "false" || (group.groupChat === "true" && completeUserList.length <= GroupChatUserLimit)){
            if(id && id !== 'create') {
                await groupsDoc.doc(values.id).update(values)
                const updateGroupsUsers = firebaseInstance().functions().httpsCallable('UpdateGroupsUsers')
                await updateGroupsUsers({
                    users: select,
                    id: initialValues.id,
                    admin: selectadmin,
                    adminRemove: aremove,
                    usersRemove: uremove,
                    completeList: completeUserList,
                    groupChat: group.groupChat === "true" ? true : false,
                    groupName: group.name,
                })
                setaRemove([])
                setuRemove([])
                alert.success(t("general.updatedgroup"))
            }else {
                group.admin = [globalId, ...selectadmin.map( u => u.id)]
                group.school = me.select.id;
                let resp = await groupsDoc.add(group)
                const updateGroupsUsers = firebaseInstance().functions().httpsCallable('UpdateGroupsUsers')
                await updateGroupsUsers({
                    users: select,
                    id: resp.id,
                    admin: [...selectadmin, {id: globalId, ...me}],
                    groupChat: group.groupChat === "true" ? true : false,
                    groupName: group.name,
                })
                alert.success(t("general.createdgroup"))
                history.goBack()
            }
        }else{
            alert.error(`${t("general.notMoreThan")} ${GroupChatUserLimit} ${t("general.userInGroup")}`);
            return
        }
        setSelect([])
        setSubmitting(false)
        history.push("/settings/groups")

    }

    useEffect(()=> {
        if(apicall && me) {
            if(id && id !== "create") {
                groupsDoc.doc(id).onSnapshot(doc => {
                        setValue({...doc.data(), id: doc.id})
                    });
                db.collection(`groups/${id}/users`).onSnapshot(doc => {
                    setAdmin(doc.docs.map( d => {
                        return {id: d.id, ...d.data()}
                    }))
                    setAdminsList(doc.docs.map( d => {
                        return {id: d.id, ...d.data()}
                    }))
                })

            }
            usersDoc.where('schoolId', 'array-contains', me.select.id)
                .get()
                .then( doc => {
                    setUsers(doc.docs.map( d => {
                        return {id: d.id, ...d.data()}
                    }))

                })
        }
        if(apicall && me) {
            //setValue(data)
            usersDoc.where('schoolId', 'array-contains', me.select.id)
                .get()
                .then( doc => {
                    setUsers(doc.docs.map( d => {
                        return {id: d.id, ...d.data()}
                    }))
                    setApicall(false)
                })
            usersDoc.where('groupsId', "array-contains", id)
                .onSnapshot( doc => {
                    setUsersList(doc.docs.map( d => {
                        return {id: d.id, ...d.data()}
                    }))
                })

        }


    }, [apicall, me])

    const getUnselectedParticipants = (allUsers,selectedUsers) => {
        return  allUsers.filter(({ id: id1 }) => !userslist.some(({ id: id2 }) => id2 === id1));
    }

    return (
        <div className="event-page-wrapper">
            <Cover className="event-cover" style={{background: `url(assets/old/wallpaper.png)`}}>
                <div className="title">
                    <h1>{initialValues.name}</h1>
                </div>
            </Cover>
            <BoxFormStyled className="event-content">
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                >
                    {({values, isSubmitting}) => {
                        return (
                            <Form>
                                <div className="groups-form">
                                    <div  style={{width: '95%'}}>
                                        <div className="field">
                                            <InputText
                                                label={ t('listing.name')}
                                                value={values.name}
                                                name="name"
                                                disabled={values && values.noDelete}
                                            />
                                        </div>
                                        <div className="field">
                                            <InputText
                                                label={ t('general.description')}
                                                value={values.desc}
                                                name="desc" />
                                        </div>
                                        <div className="field">
                                            <label>{t('general.includeGroupChat')}</label>
                                            <label className="material-radio">
                                                <Field type="radio" name="groupChat" value="true" />
                                                <span className="dot"></span>
                                                <span className="radio-label">{t('general.yes')}</span>
                                            </label>
                                            <label className="material-radio">
                                                <Field type="radio" name="groupChat" value="false" />
                                                <span className="dot"></span>
                                                <span className="radio-label">{t('general.no')}</span>
                                            </label>
                                        </div>
                                        <div className="field">
                                            <label>Selectionner les étudiants</label>
                                            <div className="control">
                                                <Multiselect
                                                    options={getUnselectedParticipants(users,userslist)}
                                                    selectedValues={select}
                                                    onSelect={(v)=> setSelect(v)}
                                                    onRemove={(v)=> setSelect(v)}
                                                    displayValue="displayName"
                                                    placeholder={t('overlay.select')}
                                                    emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                />
                                            </div>
                                        </div>
                                        <button type="submit" className="button">{t('general.save')}</button>
                                        <a className="button" onClick={onAbort}>{t('general.backToGroupsButtonLabel')}</a>
                                    </div>
                                    <div className="sidebar">
                                        <div className="field">
                                            <label>Ajouter un administrateur</label>
                                            <div className="control">
                                                <Multiselect
                                                    options={getUnselectedParticipants(users,adminslist)}
                                                    selectedValues={selectadmin}
                                                    onSelect={(v)=> setSelectadmin(v)}
                                                    onRemove={(v)=> setSelectadmin(v)}
                                                    emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                    displayValue="displayName"
                                                    placeholder={t('overlay.select')}
                                                />
                                            </div>
                                        </div>
                                        <UserList users={adminslist} right="right" value={values} list={aremove} remove={setaRemove}/>

                                    </div>
                                </div>

                                <UserList users={userslist} value={values} list={uremove} remove={setuRemove}></UserList>
                            </Form>
                        )
                    }}
                </Formik>
            </BoxFormStyled>

        </div>
    )
}
