const PostTypes = {
    ACTIVE_JOB: 'activeJob',
    QUIZ: 'form.quiz',
    JOURNAL: "form.internship.journal",
    QUESTION: "form.question",
    MENTOR: 'mentor',
    FILE: 'file',
    POST: 'post',
    INTERNSHIP: 'form.internship',
    EVENT: 'event',
    JOB: 'jobs',
    INTERNSHIP: 'internships',
    RDV: 'rdv'

}

export default PostTypes
