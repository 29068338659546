import React from "react";
import styled from 'styled-components';
import Avatar from 'react-avatar';
import {formatDistance} from "date-fns";

const CommentListContainer = styled.div`

`

const CommentContainer = styled.div`
    margin-top: 10px;
    display : flex;
    &:first-child{
        margin-top:0px;
    }
`

const CommentDate = styled.div`
    color:var(--bazookka-gray);
    font-size:0.8em;
`

const CommentTextContainer = styled.div`
    display:inline-block;
    margin-left:10px;
    color:var(--bazookka-white);
`

const CommentBubble = styled.div`
    border-radius: 18px;
    background-color:var(--bazookka-blue);
    padding:10px;
`

const CommentText = styled.div`
    
`
const CommentDisplayName = styled.div`
    font-size:0.8em;
`

const AvatarContainer = styled.div`
    vertical-align:top;
    display:inline-block;
`

const CommentList = ({comments}) => {
    return (
        <CommentListContainer className="comment-list">
            {comments.length > 0 && comments.map( (comment, i) => {
                    return (  
                        <CommentContainer key={i}>
                            <AvatarContainer>
                                <Avatar
                                    name={comment.displayName}
                                    round={true}
                                    size={38}
                                />
                            </AvatarContainer>
                            <CommentTextContainer>
                                <CommentBubble>
                                    <CommentDisplayName>{comment.displayName}</CommentDisplayName>
                                    <CommentText>{comment.text}</CommentText>
                                </CommentBubble>
                                <CommentDate>{formatDistance(comment.timestamp, new Date())}</CommentDate>
                            </CommentTextContainer>
                        </CommentContainer>
                    );
                })
            }
        </CommentListContainer>
    )
}

export default CommentList;
