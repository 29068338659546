import styled from 'styled-components'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {MyContext} from "../stores";
import {contactsDocs, firestore} from "../firestore/firestoreService";
import Avatar from "react-avatar";
import {userState} from "../stores/enums/userPresence";
import {UserItems} from "../components/Messenger";
import {useAlert} from "react-alert";
import {formatDistance} from "date-fns";
import {fr} from "date-fns/locale";
import AttachmentList from "../components/AttachmentList";
import ChatInput from "../components/ChatInput";
import Loader from "../components/Loader";
import FileUpload from "../components/FileUpload";

const FlexBreak = styled.div`
	flex-basis: 100%;
	width: 0px; 
	height: 10px; 
	overflow: hidden;
`

const ChatMasterDiv = styled.div`
 position: absolute;
 z-index: 199999999999  ;
 width: 100%;
 height: 100vh;
 background-color: white;
 display: grid;
 grid-template-columns: 300px 1fr;
 grid-template-areas:
  "side chat";
 
 @media (max-width: 768px) {
   grid-template-columns: 1fr;
   grid-template-rows: 80px 1fr;
   grid-template-areas:
  "chat";
   .side {
     display: none;
   }
 } 
  
 .nav {
   grid-area: nav;
 } 
 .side {
   grid-area: side;
   height: 100%;
   overflow: scroll;
 } 
 .chat {
   grid-area: chat;
 }
  img.iMask {
    -webkit-mask-image: url(assets/old/logo-bazookka.png);
    -webkit-mask-size: 200px;
    -webkit-mask-position-y: center;
    -webkit-mask-repeat: no-repeat;
  }
  .chat-nav {
    width: 100%;
    height: 80px;
    background-color: var(--bazookka-green);
    display: flex;
    position: fixed;
    top: 0px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
    z-index: 1000;
  } 
  .nav-start {
    display: flex;
    
  }
  .recipient-block {
    display: flex;
    grid-gap: 10px;
  }
  #chat-sidebar {
    padding: 0 20px;
    position: relative;
    margin-top: 100px;
    margin-bottom: 100px;
    > a {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      display: block;
      height: 80px;
      background-color: white;
      width: 300px;
      > img {
        position: relative;
        width: 100%;
        height: 80px;
      }
    }
  }
  .footer-item {
    position: fixed;
    bottom: 0px;
    height: 80px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .add-button {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-item {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    margin: 10px 0px;
  }
  .message-text {
    width: fit-content;
  }
  .chat-body {
    display: block;
    margin-top: 100px;
    width: 100%;
    padding: 2rem;
    height: 100%;
    margin-bottom: 300px;
    
    position: relative
  }
  .chat-body-inner {
    width: 100%;
    overflow: scroll;
  }
  .chat-action {
    position: fixed;
    height: 300px;
  }
`
const SendButtonContainer = styled.div`
    z-index:10;
    position:absolute;
`

const AttachmentListContainer = styled.div`
    position: absolute;
    bottom: -40px;
    border: 1px solid #dbdbdb;
    background-color: white;
    left:101%;
    border-radius: 4px;

    .name{
        max-width:200px;
    }
`
const AttachmentUploadContainer = styled.div`
    position:absolute;
    right:10px;
    bottom:-40px;
    z-index:10;
`
const MessageContainer = styled.div`
  width:100%;
  min-width:70px;
  top:-25px !important;
  
  .chat-textbox{
      padding-left:45px;
      padding-right:45px;
      position:absolute;
      bottom:-40px;
  }
`

const ConversationsList = styled.div`
    overflow-y: scroll;
    height: calc(100% - 160px) !important;
`
const ChatContactFooter = styled.div`
    width:300px;
    background-color: var(--bazookka-green);
    position:fixed;
    left: 0px;

    .add-button{
        background-color:white;
    }
`
const AvatarGroup = styled.div`
    display: inline-block;
    vertical-align: middle;
    width: 40px;  
    height: 40px;
    border-radius: 100%;
    background-color:var(--bazookka-blue);
    display:grid;

    i{
        color:white;
        margin:auto;
    }
  
`
const UserDetailList = styled.ul`
    display:block;
    width:70%;
    margin:auto;

    li{
        margin-bottom:15px;
        
        .sb-avatar{
            margin-right:10px;
        }
    }
`
const renderAvatar = (contact) => {
    if(contact.users.length > 1){
        return (
            <AvatarGroup>
                <i className="mdi mdi-account-group"></i>
            </AvatarGroup>
        );
    }else{
        return <Avatar name={contact && contact.users[0]?.displayName} round={true} size={40}/>
    }
}

const renderNames = (contact) => {
    if(contact.groupName){
        return <div className="name">{contact && contact.groupName}</div>
    }else if(contact.users.length > 1){
        let returnString = "";
        const nbShownNames = 2;
        const otherCount = contact.users.length - nbShownNames;
        const plural = otherCount > 1 ? "s" : "";
        for (let i = 0; i < nbShownNames; i++) {
            if(i === 0){
                returnString += `${contact.users[i].displayName} `;
            }else{
                returnString += `,${contact.users[i].displayName} `;
            }
        }
        returnString += `and ${otherCount} other${plural}`;
        return <div className="name">{returnString}</div>
    }else{
        return <div className="name">{contact && contact.users[0]?.displayName}</div>
    }
}

const isContactOnline = (contact) => {
    const onlineUsers = contact.users.filter((user) => {
        if(user.status && user.status.state === userState.ONLINE){
            return true
        }else{
            return false
        }
    });
    if(onlineUsers.length > 0){
        return true;
    }else{
        return false;
    }
}

export default function Chat2() {
    const history = useHistory()
    const {t} = useTranslation()
    const {slug} = useParams();
    const [contacts, setContact, maincontact] = useState([])
    const [select, setSelect] = useState(null)
    const {me, globalId} = useContext(MyContext)

    useEffect(()=> {
        if(maincontact) {
            setSelect(maincontact)
        }
    }, [maincontact])

    useEffect(() => {
        if(slug && contacts){
            const slugContact = contacts.filter(contact => {
                return contact.id === slug
            });
            setSelect(slugContact[0]);
        }
    }, [slug, contacts]);

    useEffect(()=> {
        if(!maincontact) {
            contactsDocs.where('usersIds', 'array-contains', globalId)
                .onSnapshot(snapshot => {
                    setContact(
                        snapshot.docs.map(snap => {
                            let users = snap.data().users.filter( u => globalId !== u.id)
                            let usersIds = snap.data().usersIds;
                            return {id: snap.id, users: users, usersIds: usersIds}
                        })
                    )
                })
        } else {
            setSelect(maincontact)
        }
    }, [globalId])
    return (
        <ChatMasterDiv>
            <div className="side">
                <ChatSideBar
                 contacts={contacts}
                 setSelect={setSelect}
                />
            </div>
            <div className="chat">
                <ChatTopNav
                    me={me}
                    history={history}
                    select={select}
                />
                <ChatBody select={select} />
            </div>
        </ChatMasterDiv>
    )
}


const ChatTopNav = ({me, history, select}) => {
    const {t} = useTranslation()
    const ChatNavStatusClass = select && isContactOnline(select) ? 'online' : 'offline';
    return (
        <div className={`chat-nav ${ChatNavStatusClass}`}>
            <div className="nav-start">
                <div className="recipient-block">
                    <div className="avatar-container">
                        {select && renderAvatar(select)}
                    </div>
                    <div className="username">
                        {select && renderNames(select)}
                        {select &&
                        (
                            isContactOnline(select) ?
                                <span><i data-feather="star"></i> <span>| {t("general.online")}</span></span>
                                :
                                <span><i data-feather="star"></i> <span>| {t("general.offline")}</span></span>
                        )

                        }
                    </div>
                </div>
            </div>
            <div className="nav-end">
                <a className="chat-nav-item is-icon" onClick={(evt)=>{
                    evt.preventDefault();
                    history.goBack()

                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                         className="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </a>
            </div>
        </div>
    )
}

export const ChatSideBar = ({contacts, setSelect}) => {
    const {dispatchModal, me, globalId} = useContext(MyContext);
    const {t} = useTranslation()
    let sortedContacts = [];
    if(contacts){
        sortedContacts = contacts.sort((a, b) => {
            if(isContactOnline(a) && isContactOnline(b)){
                return 0
            }else if(isContactOnline(a) && !isContactOnline(b)){
                return -1
            }else{
                return 1
            }
        })
    }


    const unorderedArrayCompare = (arr1, arr2) => {
        //This is only to compare arrays of primitive
        arr1.sort();
        arr2.sort();
        return JSON.stringify(arr1) === JSON.stringify(arr2)
    }

    const handleNewConversation = async (users, usersIds) => {
        const arrayUsersIds = [globalId, ...usersIds];
        let existingContact = false;

        contacts.forEach(contact => {
            if(unorderedArrayCompare(arrayUsersIds, contact.usersIds)){
                setSelect(contact);
                existingContact = true;
            }
        });

        if(!existingContact){
            const fullUsers = users.map(fUser => {
                return {id: fUser.id, displayName: fUser.displayName}
            });
            const chatType = fullUsers.length > 1 ? 'multi-users' : 'users';
            await contactsDocs.add({
                type: chatType,
                users: [
                    {id: globalId, displayName: me.displayName},
                    ...fullUsers
                ],
                usersIds:arrayUsersIds,
            })
        }
    }

    const openModal = () => {
        dispatchModal({
            type: "OPEN_MODAL",
            payload: {
                modalType: "StartConversationModal",
                modalProps:{
                    title: t("general.startconversation"),
                    me,
                    ico: <i className="mdi mdi-chat"></i>,
                    onNewConversation: handleNewConversation,
                    closeOut: () => dispatchModal({type: 'CLOSE_MODAL'}),
                }
            }})

    }
    return (
        <div id="chat-sidebar" className="users-sidebar">

            <a >
                <img className="iMask" src="assets/old/wallpaper.png" alt="" />
            </a>

            <ConversationsList className="conversations-list has-slimscroll-xs">
                {
                    sortedContacts.length > 0 && sortedContacts.map( (contact, k) => <UserItems key={k} contact={contact} setSelect={setSelect}/>)
                }
            </ConversationsList>

            <ChatContactFooter className="footer-item">
                <div className="add-button modal-trigger" data-modal="add-conversation-modal" onClick={openModal}>
                    <i
                        className="mdi mdi-account-plus"
                    ></i>
                </div>
            </ChatContactFooter>
        </div>
    )
}

const ChatBody = ({select}) => {
    const {globalId, me, langChoice} = useContext(MyContext)
    const [text, setText] = useState('')
    const [messages, setMessages] = useState([])
    const [messageAttachments, setMessageAttachments] = useState([]);
    const [fileUploadStatus, setFileUploadStatus] = useState('idle');
    const [fileDestination, setFileDestination] = useState('');
    const [createdDoc, setCreatedDoc] = useState('');
    const messagesEndRef = useRef(null)
    const [showDeleteButton,setShowDeleteButton] =  useState(false);
    const [selectedReplyIndex,setSelectedReplyIndex] =  useState(-1);
    const [messageToDelete,setMessageToDelete] = useState({})
    const msgTimeSpacing = 20 //minutes
    const messageComponentRef = useRef(null)
    const alert = useAlert()
    const t = useTranslation()

    const onSend = (data) => {
        let id;
        if(text.length === 0) {
            alert.error(t("general.missingtext"))
        }
        contactsDocs.doc(select.id).collection('messages').add({
            text,
            timestamp: new Date().getTime(),
            displayName: me.displayName,
            isDeleted: false,
            myId: globalId
        }).then((docref) => {
            setText('');
            setCreatedDoc(docref.id);
            setFileDestination(`chat_attachments/${select.id}/${globalId}/`);
            setFileUploadStatus('uploading');
        })


    }

    const messageTimeSpace = (messages, i) => {
        //If it's the first message or if it was posted by a different person or the same person but with more than 20 minutes show the name and avatar
        return (
            i === 0 ||
            (i > 0 && messages[i].myId !== messages[i-1].myId) ||
            (((messages[i].timestamp - messages[i-1].timestamp) / 1000) / 60 > msgTimeSpacing)
        );
    }

    const handleMessageUpload = (fileRef, metadata) => {
        fileRef.getDownloadURL().then((url) => {
            const newAttachment = {
                name: metadata.name,
                path: url,
                type: metadata.contentType,
                timestamp: Date.now(),
            }
            contactsDocs.doc(select.id).collection('messages').doc(createdDoc).update({
                attachments: firestore.FieldValue.arrayUnion(newAttachment)
            }).catch((error) => {
                console.error("Error writing document: ", error);
            });
        });
    }

    const handleDelete = (toDelete) => {
        setMessageAttachments(prevState => {
            const newState = prevState.filter(function(att) {
                return att !== toDelete;
            });
            return newState;
        });
    }

    const handleQueueComplete = () => {
        //Reset all the states and Close the modal
        setFileUploadStatus('idle');
        setMessageAttachments([]);
        setFileDestination('');
        setCreatedDoc('');
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }



    useEffect(()=>{
        if(select) {
            contactsDocs.doc(`${select.id}`).collection('messages')
                .orderBy('timestamp', 'asc').onSnapshot(snaps => {

                const messagesWithIds = snaps.docs.map(
                    (d) => Object.assign({}, {id: d.id, ...d.data()})
                ).filter(msg =>  msg.isDeleted !== true);
                setMessages(messagesWithIds)
            })
        }
    }, [globalId, select])

    useEffect(() => {
        scrollToBottom();
    }, [messages])

    function shouldDisplayDeleteIcon(showDeleteButton, selectedReplyIndex, i, message, globalId) {
        return showDeleteButton && selectedReplyIndex === i && message.myId === globalId;
    }

    return (
        <div id="chat-body" className="chat-body is-opened">

            <div className="chat-body-inner has-slimscroll">
                {/*<div className="date-divider">*/}
                {/*    <hr className="date-divider-line" />*/}
                {/*        <span className="date-divider-text">Today</span>*/}
                {/*</div>*/}
                {messages && Array.isArray(messages) && messages.map( (message, i) => {
                    const messageBlockStreakClass = messageTimeSpace(messages, i) ? "" : " streak";
                    return (
                        <div key={i} className={`chat-message ${message.myId !== globalId ? `is-sent` : `is-received`} ${messageBlockStreakClass}` }>
                            {messageTimeSpace(messages, i) &&
                            <React.Fragment>
                                <Avatar name={message.displayName} round={true} size={40}/>
                                <div className="message-metadata">
                                    <span>{message.displayName}</span>
                                    <span>
                                            {
                                                formatDistance(new Date(message.timestamp), new Date(),
                                                    { addSuffix: true, locale: langChoice === 'fr'?fr: null })
                                            }</span>
                                </div>
                                <FlexBreak/>
                            </React.Fragment>
                            }

                            <div className="message-block">
                                <div ref={messageComponentRef} className="message-text" onClick={() => {
                                    setSelectedReplyIndex(i);
                                    setShowDeleteButton(true)
                                    setMessageToDelete(message)

                                }  }>
                                    { message.text }
                                    {message.attachments && message.attachments.length > 0 &&
                                    <AttachmentList attachments={message.attachments} metas={['name']} loading={fileUploadStatus === 'uploading'}/>
                                    }
                                </div>
                            </div>
                            {
                                shouldDisplayDeleteIcon(showDeleteButton, selectedReplyIndex, i, message, globalId) ?
                                    <i className="mdi mdi-delete" onClick={(i) => {
                                        var confirmDelete = window.confirm("Do you want to delete this comment");
                                        if(confirmDelete){
                                            contactsDocs.doc(`${select.id}`).collection('messages').doc(messageToDelete.id).update(
                                                { isDeleted : true})
                                            alert.remove(t("general.deletedcomment"))
                                        }
                                    }}> </i> : ""
                            }
                        </div>
                    )
                })}
                <div ref={messagesEndRef}></div>
            </div>

            <div className="chat-action">
                <div className="chat-action-inner">
                    <div className="control">
                        {/*<div className="dropdown compose-dropdown is-spaced is-accent is-up dropdown-trigger">*/}
                        {/*    <div>*/}
                        {/*        <div className="add-button">*/}
                        {/*            <div className="button-inner">*/}
                        {/*                <i data-feather="plus"></i>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="dropdown-menu" role="menu">*/}
                        {/*        <div className="dropdown-content">*/}
                        {/*            <a >*/}
                        {/*                <div className="media">*/}
                        {/*                    <i data-feather="code"></i>*/}
                        {/*                    <div className="media-content">*/}
                        {/*                        <h3>Code snippet</h3>*/}
                        {/*                        <small>Add and paste a code snippet.</small>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </a>*/}
                        {/*            <a className="dropdown-item">*/}
                        {/*                <div className="media">*/}
                        {/*                    <i data-feather="file-text"></i>*/}
                        {/*                    <div className="media-content">*/}
                        {/*                        <h3>Note</h3>*/}
                        {/*                        <small>Add and paste a note.</small>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </a>*/}
                        {/*            <hr className="dropdown-divider" />*/}
                        {/*                <a className="dropdown-item">*/}
                        {/*                    <div className="media">*/}
                        {/*                        <i data-feather="server"></i>*/}
                        {/*                        <div className="media-content">*/}
                        {/*                            <h3>Remote file</h3>*/}
                        {/*                            <small>Add a file from a remote drive.</small>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </a>*/}
                        {/*                <a className="dropdown-item">*/}
                        {/*                    <div className="media">*/}
                        {/*                        <i data-feather="monitor"></i>*/}
                        {/*                        <div className="media-content">*/}
                        {/*                            <h3>Local file</h3>*/}
                        {/*                            <small>Add a file from your computer.</small>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </a>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <MessageContainer className="dropdown compose-dropdown is-spaced is-accent is-up dropdown-trigger">
                            {select && (
                                <>
                                    <ChatInput className="chat-textbox" handleKeyUp={setText} onSubmit={onSend}/>
                                    <SendButtonContainer>
                                        <a className="add-button" onClick={onSend}>
                                            <i className="mdi mdi-send"></i>
                                        </a>
                                    </SendButtonContainer>
                                    {messageAttachments.length > 0 &&
                                    <AttachmentListContainer>
                                        {fileUploadStatus === 'uploading' &&
                                        <Loader/>
                                        }
                                        <AttachmentList attachments={messageAttachments} metas={['name']} onDelete={handleDelete}/>
                                    </AttachmentListContainer>
                                    }
                                    <AttachmentUploadContainer>
                                        <FileUpload dest={fileDestination} onUpload={handleMessageUpload} queueStatus={fileUploadStatus} queue={messageAttachments} setQueue={setMessageAttachments} onQueueComplete={handleQueueComplete}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                 strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-paperclip">
                                                <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                                            </svg>
                                        </FileUpload>
                                    </AttachmentUploadContainer>
                                </>
                            )}


                        </MessageContainer>
                    </div>
                </div>
            </div>

        </div>
    )
}
