import React, {useContext, useState, useEffect} from 'react'
import {MyContext} from "../stores";
import {useHistory} from 'react-router-dom'
import {firestore, contactsDocs} from "../firestore/firestoreService";
import {ChatBody, ChatRightPanel, ChatSideBar, ChatTopNav} from "../components/Messenger";
import {Trans, useTranslation} from "react-i18next";
import {useParams} from 'react-router-dom';
import Avatar from "react-avatar";
import {userState} from "../stores/enums/userPresence";



export default function ChatMain(props){
    const history = useHistory()
    const {t} = useTranslation()
    const {slug} = useParams();
    const [contacts, setContact, maincontact] = useState([])
    const [select, setSelect] = useState(null)
    const {me, globalId} = useContext(MyContext)

    useEffect(()=> {
        if(maincontact) {
            setSelect(maincontact)
        }
    }, [maincontact])

    useEffect(() => {
        if(slug && contacts){
            const slugContact = contacts.filter(contact => {
                return contact.id === slug
            });
            setSelect(slugContact[0]);
        }
    }, [slug, contacts]);

    useEffect(()=> {
        let cleanUp = null
        if(!maincontact) {
            cleanUp =  contactsDocs.where('usersIds', 'array-contains', globalId)
                .onSnapshot(snapshot => {
                    setContact(
                        snapshot.docs.map(snap => {
                            let users = snap.data().users.filter( u => globalId !== u.id)
                            let usersIds = snap.data().usersIds;
                            return {id: snap.id, users: users, usersIds: usersIds}
                        })
                    )
                })
                return () => cleanUp()
        } else {
            setSelect(maincontact)
        }
        

    }, [globalId])

    return (
        <div className="chat-wrapper is-standalone">
            <div className="chat-inner">
                <ChatTopNav history={history} me={me} select={select}/>
                <ChatSideBar contacts={contacts} setSelect={setSelect}/>
                <ChatRightPanel select={select}/>
                <ChatBody select={select}/>
            </div>
        </div>
    )
}




