import { useContext, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import { MyContext } from "../stores";
import { Multiselect } from "multiselect-react-dropdown";
import { ErrorMessage, Form, Formik } from "formik";
import { InputText, NiceSelect, SwitchBox, TextAreaInput } from "../components/FormElements";
import { firebaseInstance, schoolsDoc, usersDoc, db, storage } from "../firestore/firestoreService";
import ReactSelect from 'react-select'
import { useCatReducer } from "../stores/categories";
import { userRoles } from "../stores/enums/userRoles";
import * as Yup from "yup"
import { timeout, useDeBounce } from "../helper";
import { concat, omit } from 'lodash';
import { PencilLineIco } from "../ico";
import Avatar from 'react-avatar';
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import { ErrorContainer } from "../components/FormManager/ErrorContainer";
import FieldSetStyled from "../forms/FieldSetStyled";
import { FormSignUpStyled } from "../pages/SignUp/Registration";
import { JustCoverBG } from "../pages/main/CoverBG";
import {useLocalStorage} from "../helper";
import i18next from "i18next";


export default function CompleteFreemiumCompanyRegistration() {
    const [categories, dispatch] = useCatReducer();
    const [select, setSelect] = useState(null)
    const [cache, setCache] = useLocalStorage("loc")
    const [companySelect, setCompanySelect] = useState(null)
    const { t } = useTranslation()
    const [options, setOptions] = useState([])
    const [companyAddressOptions, setCompanyAddressOptions] = useState([])
    const [apicall, setApicall] = useState(true)
    const [registrationError, setRegistrationError] = useState()
    const addressRef = useRef()
    const companyAddressRef = useRef()
    const [save, setSave] = useState(null)
    const [companyAddressSaved, setCompanyAddressSave] = useState(false)
    const { me, verifyAuth, setMe, langChoice, dispatchModal } = useContext(MyContext)
    const [type, setType] = useState('password')
    const regexTelephone = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const regexEmail = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,25})+)$/;
    const history = useHistory();
    const [waitingAddressSuggestion, setWaitingAddressSuggestion] = useState(true)
    const [waitingCompanyAddressSuggestion, setWaitingCompanyAddressSuggestion] = useState(true)
    const [avatarToUplaod, setAvatarToUpload] = useState(null)
    const [coverToUpload, setCoverToUpload] = useState(null)
    const [stepOneData, setStepOneData] = useState({})
    const [companyName, setCompanyName] = useState("")


    const invalidFormatMessage = "Invalid format. Allowed formats: \n(123) 456-7890 \n (123)456-7890 \n 123-456-7890 \n +31636363634 \n 075-63546725 ";


    const [initialValues, setInitialValues] = useState({
        companyName: "",
        companyAddress: "",
        companyIntro: "",
        companyPhone: "",
        displayName: "",
        category: [],
        email: "",
        sector: [],
        name: "",
        tel: "",
        address: "",
        role: userRoles.CMP_ADMIN_BUSINESS,
        schoolId: ['0uuQdR7ieoV3xJOSmc7w'],

    });

    useEffect(() => {
        dispatch({ type: langChoice === 'fr' ? 'SET_FR' : 'SET_EN', payload: 'field.' })
        if (apicall) {
            setApicall(!apicall)
        }
    }, [apicall])

    useEffect(() => {
        setCache(null);
        if (localStorage.hasOwnProperty('stepOneUserData')) {
            setStepOneData(JSON.parse(localStorage.getItem("stepOneUserData")))
        }

        if (localStorage.hasOwnProperty('stepOneCompanyData')) {
            setCompanyName(localStorage.getItem("stepOneCompanyData"))
        }


    }, [])

    const AvatarEditButton = styled.div`
    position: relative;
    bottom: 30px;
    left: 70px;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3d70b2;
    box-shadow: 0 14px 26px -12px rgb(61 112 178 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(61 112 178 / 20%) !important;
    transform: rotate(0);
    transition: all .3s;
    cursor: pointer;
    z-index: 1;

    svg{
        height: 18px;
        width: 18px;
        stroke: #fcfcfc;
    }
`

    const onUploadAvatar = (uid, fileRef, metadata) => {
        fileRef.getDownloadURL().then((url) => {
            db.collection("users")
                .doc(uid)
                .update({ avatar: url })
        });
    };

    const onUploadCover = (uid, fileRef, metadata) => {
        fileRef.getDownloadURL().then((url) => {
            db.collection("users")
                .doc(uid)
                .update({ cover: url })
        });
    };

    const SignupSchema = Yup.object().shape({
    });

    const handleAvatarUpload = (uid) => {
        var storageRef = storage.ref();
        var fileRef = storageRef.child(`/profile_pictures/profile/${uid}/${avatarToUplaod.name}`);
        fileRef.put(avatarToUplaod).then((snapshot) => {
            onUploadAvatar(uid, snapshot.ref, snapshot.metadata);
        }).catch((error) => {
            console.log(error)
        });
    }

    const handleCoverUpload = (uid) => {
        var storageRef = storage.ref();
        var fileRef = storageRef.child(`/profile_pictures/cover/${uid}/${coverToUpload.name}`);
        fileRef.put(coverToUpload).then((snapshot) => {
            onUploadCover(uid, snapshot.ref, snapshot.metadata);
        }).catch((error) => {
            console.log(error)
        });
    }



    const onSubmit = async (v, { setSubmitting }) => {
        setSubmitting(true)
        let googleLL = firebaseInstance().functions().httpsCallable('googleFindLongLat')
        if (save) {
            v.address = save;
            let response = await googleLL({ value: save })
            v.location = response.data.results[0].geometry.location
        }

        if (companyAddressSaved) {
            v.companyAddress = companyAddressSaved;
            let response = await googleLL({ value: companyAddressSaved })
            v.comapnyLocation = response.data.results[0].geometry.location
        }
        let companyData = {
            address: v.companyAddress, sector: v.sector, intro: v.companyIntro, name: v.companyName, tel: v.companyPhone, type: "business",
            location: v.comapnyLocation
        }
        if (companyName) {
            companyData.name = companyName
        }
        let user, sch, doc

        if (stepOneData) {
            let userFullData = {
                ...omit(v, ["companyAddress", "sector", "companyIntro", "companyName", "companyPhone", "comapnyLocation"]), ...stepOneData,
                completed: "true", groupsId: ["all"], role: userRoles.CMP_ADMIN_BUSINESS, select: {
                    id: stepOneData.schoolId[0],
                    name: companyName,
                    type: userRoles.CMP_ADMIN_BUSINESS
                }
            }
            try {


                delete userFullData.cover
                await firebaseInstance()
                    .auth()
                    .signInWithEmailAndPassword(userFullData.email, userFullData.password);
                delete userFullData.password
                delete userFullData.cpassword
                doc = await usersDoc.doc(stepOneData.uid).set(userFullData, { merge: true })
                await schoolsDoc.doc(stepOneData.schoolId[0]).set(companyData, { merge: true })

                handleAvatarUpload(stepOneData.uid)
                handleCoverUpload(stepOneData.uid)

                history.push("/me")

            } catch (e) {
                var errorCode = e.code;
                if (errorCode === 'auth/email-already-in-use') {

                    setRegistrationError(t('registration.error.user_exists'))
                } else if (errorCode === "auth/invalid-email") {
                    setRegistrationError(t('registration.error.invalid_email'))
                }
                return e.message
            }
        }
        setSubmitting(false)
        history.push('me')
    }

    const updateRequest = async (value) => {
        let addressGetter = firebaseInstance().functions().httpsCallable('googleAddress')
        if (value.length > 7) {
            addressGetter({ value }).then((response) => {
                setOptions(response.data.response.predictions.map((d, index) => {
                    return { value: d.description, label: d.description }
                }))
                setWaitingAddressSuggestion(false)
            })

        }
    }

    const updateRequestCompanyAddress = async (value) => {
        let addressGetter = firebaseInstance().functions().httpsCallable('googleAddress')
        if (value.length > 7) {
            addressGetter({ value }).then((response) => {
                setCompanyAddressOptions(response.data.response.predictions.map((d, index) => {
                    return { value: d.description, label: d.description }
                }))
                setWaitingCompanyAddressSuggestion(false)
            })

        }
    }

    const handleCroppedAvatar = (croppedImage) => {
        setAvatarToUpload(croppedImage)
        dispatchModal({ type: 'CLOSE_MODAL' })
    }
    const handleCroppedCover = (croppedImage) => {
        setCoverToUpload(croppedImage)
        dispatchModal({ type: 'CLOSE_MODAL' })
    }

    const editAvatar = (e) => {
        e.preventDefault();

        dispatchModal({ type: 'OPEN_MODAL', payload: { modalType: 'BzkSeekerAvatarCropUploadModal', modalProps: { returnCroppedImage: handleCroppedAvatar, title: i18next.t("modal.title.editSeekerAvatar"), ico: <PencilLineIco />, closeOut: () => dispatchModal({ type: 'CLOSE_MODAL' }) } } })
    }

    const editCover = (e) => {
        e.preventDefault();
        dispatchModal({ type: 'OPEN_MODAL', payload: { modalType: 'BzkSeekerCoverCropUploadModal', modalProps: { returnCroppedImage: handleCroppedCover, title: i18next.t("modal.title.editSeekerCover"), ico: <PencilLineIco />, closeOut: () => dispatchModal({ type: 'CLOSE_MODAL' }) } } })
    }


    const handleChange = (v) => {
        setSelect(v)
        setSave(addressRef?.current?.state?.value?.label)
    }

    const handleCompanyAddressChange = (v) => {
        setCompanySelect(v)
        setCompanyAddressSave(companyAddressRef?.current?.state?.value?.label)
    }

    useDeBounce(() => updateRequest(select), 500, [select])
    useDeBounce(() => updateRequestCompanyAddress(companySelect), 500, [companySelect])


    return (
        <>
            <JustCoverBG />
            <FormSignUpStyled>
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={SignupSchema} enableReinitialize={true}>
                    {({ values, errors, isSubmitting, setFieldValue }) => {
                        return (
                            <Form>

                                <FieldSetStyled>
                                    <legend><pre> {t("forms.companyInformation")} </pre></legend>

                                    <label style={{ fontFamily: "Gotham Rounded bold", color: "#363636", fontSize: "0.9rem" }} >{t("general.companyPhoneAddress") + " *"}</label>
                                    <ReactSelect
                                        loadingMessage={() => 'searching...'}
                                        defaultValue={values.companyAddress || ''}
                                        options={companyAddressOptions}
                                        isLoading={waitingCompanyAddressSuggestion}
                                        onInputChange={handleCompanyAddressChange}
                                        ref={companyAddressRef}
                                    />

                                    <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.companyAddress}</div>} name="companyAddress" />

                                    <InputText
                                        label={t('general.companyIntro')}
                                        value={values.companyIntro}
                                        name="companyIntro"
                                    />
                                    <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.companyName}</div>} name="companyIntro" />

                                    <InputText label={t('general.companyPhoneNumber') + ' *'} name="companyPhone" />
                                    <ErrorMessage render={error => <div style={{ color: 'red', whiteSpace: "pre-wrap" }}>{errors.companyPhone}</div>} name="companyPhone" />

                                    <div className="field">
                                        <label>{t('general.companySector')} *</label>
                                        <div className="control">
                                            <Multiselect
                                                options={categories}
                                                placeholder={t('overlay.select')}
                                                selectedValues={values.sector || []}
                                                onSelect={(v) => {
                                                    values.sector = v;
                                                }}
                                                name="sector"
                                                emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                displayValue="value"
                                            />
                                        </div>
                                    </div>
                                </FieldSetStyled>

                                <FieldSetStyled>

                                    <legend><pre> {t("forms.personalInformation")} </pre></legend>
                                    <label style={{ fontFamily: "Gotham Rounded bold", color: "#363636", fontSize: "0.9rem" }} >{t("general.address") + " *"}</label>
                                    <ReactSelect
                                        loadingMessage={() => 'searching...'}
                                        defaultValue={values.address || ''}
                                        name="address"
                                        options={options}
                                        isLoading={waitingAddressSuggestion}
                                        onInputChange={handleChange}
                                        ref={addressRef}
                                    />
                                    <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.address}</div>} name="address" />
                                    <InputText label={t('settings.phone') + ' *'} name="tel" />
                                    <ErrorMessage render={error => <div style={{ color: 'red', whiteSpace: "pre-wrap" }}>{errors.tel}</div>} name="tel" />
                                    <TextAreaInput label={t('settings.intro')} name="intro" />
                                </FieldSetStyled>

                                <FieldSetStyled>
                                    <legend> <pre> {t('forms.profileImages')} </pre></legend>
                                    {t('upload.profilePicture')} <br />
                                    <Avatar src={avatarToUplaod ? URL.createObjectURL(avatarToUplaod) : ""} round={true} style={{ zIndex: 1 }} />
                                    <AvatarEditButton onClick={editAvatar}>
                                        <PencilLineIco />
                                        <i data-feather="plus"></i>
                                    </AvatarEditButton>
                                    <div>
                                        <a className="cover-edit modal-trigger" data-modal="change-cover-modal" onClick={editCover}>
                                            <i className="mdi mdi-camera"></i>
                                            <span>{t('upload.banner')}</span>
                                        </a>
                                    </div>
                                    <img className="cover-image" src={coverToUpload ? URL.createObjectURL(coverToUpload) : 'assets/old/wallpaper.png'}
                                        data-demo-src="assets/img/demo/bg/4.png" alt="" />
                                    <br />
                                </FieldSetStyled>

                                <button style={{ margin: "auto 0" }} className="button" type="submit" disabled={isSubmitting}>{t('general.save')}</button>
                                <Link to={me} style={{ margin: "auto 0" }} className="button" type="submit" disabled={isSubmitting}>{t('general.skip')}</Link>
                                {isSubmitting && <CircularProgress />}
                                {registrationError && <h1 style={{ color: "red" }}>{registrationError}</h1>}
                            </Form>
                        )
                    }}

                </Formik>
            </FormSignUpStyled>
        </>

    )
}