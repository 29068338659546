import React, {useContext, useEffect, useState} from "react";
import {MyContext} from "../../stores";
import {Link, useParams} from "react-router-dom";
import Avatar from "react-avatar";
import MainSettings from './Settings'
import PaymentInfo from './PaymentInfo';
import {
    FileCabinetIco,
    HelpIco,
    LockIco,
    NotifIco,
    PrefIco,
    SchoolIco,
    SettingsIco,
    ShieldIco,
    WarningIco
} from "../../ico";



const Settings = props => {
    const [isGeneral, setGeneral] = useState(true)
    const {me, settingsNav, updateHomeNav, setFooter, langChoice} = useContext(MyContext)
    setFooter(false)
    const {page} = useParams()
    const onClick = evt => {

    }
    useEffect(()=> {
        // provider.setFooter(false);
    }, [])
    return (
        <div className="view-wrapper is-full">
            <div className="settings-sidebar is-active">
                <div className="settings-sidebar-inner">
                    <div className="user-block">
                        <a className="close-settings-sidebar is-hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                 className="feather feather-x">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </a>
                        <div className="avatar-wrap">
                            {me && <Avatar size={58} round={true} name={me.displayName} />}
                            <div className="badge">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="feather feather-check">
                                    <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                            </div>
                        </div>

                        <h4>{me && me.firstname}</h4>
                        <p>{me && me.select.name}</p>
                    </div>
                    <div className="user-menu">
                        <div className="user-menu-inner has-slimscroll">
                            <div className="menu-block">
                                <ul>
                                    <li data-section="general" className={!page ? 'is-active': ''}>
                                        <Link to={`/${langChoice}/settings`} >
                                            <SettingsIco />
                                            <span>General</span>
                                        </Link>
                                    </li>
                                    
                                    <li data-section="educations" className={page==='educations' ? 'is-active': ''}>
                                        <Link to={`/${langChoice}/settings/educations`} >
                                            <SchoolIco />
                                            <span>Educations</span>
                                        </Link>
                                    </li>
                                    <li data-section="security" className={settingsNav['security'] ? 'is-active': ''}>
                                        <Link to={`/${langChoice}/settings/experiences`}>
                                            <ShieldIco />
                                            <span>Work Experiences</span>
                                        </Link>
                                    </li>
                                    <li data-section="security" className={settingsNav['security'] ? 'is-active': ''}>
                                        <Link to={`/${langChoice}/settings/files`}>
                                            <FileCabinetIco />
                                            <span>Documents</span>
                                        </Link>
                                    </li>
                                    <li data-section="personal" className={settingsNav['account'] ? 'is-active': ''}>
                                        {/*<a onClick={()=> updateHomeNav('account', 'settingsNav', 'setSettingsNav')}>*/}
                                        <Link to={`/${langChoice}/settings/account`}>
                                            <WarningIco />
                                            <span>Account</span>
                                        </Link>
                                    </li>
                                    
                                </ul>
                            </div>
                            <div className="separator"></div>
                            {/* Keeping this code for later work */}
                            {/*<div className="menu-block">*/}
                            {/*    <ul>*/}
                            {/*        <li data-section="privacy">*/}
                            {/*            <a>*/}
                            {/*                <LockIco />*/}
                            {/*                <span>Privacy</span>*/}
                            {/*            </a>*/}
                            {/*        </li>*/}
                            {/*        <li data-section="preferences">*/}
                            {/*            <a>*/}
                            {/*                <PrefIco />*/}
                            {/*                <span>Preferences</span>*/}
                            {/*            </a>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                            {/*<div className="separator"></div>*/}
                            {/*<div className="menu-block">*/}
                            {/*    <ul>*/}
                            {/*        <li data-section="notifications">*/}
                            {/*            <a>*/}
                            {/*                <NotifIco />*/}
                            {/*                <span>Notifications</span>*/}
                            {/*            </a>*/}
                            {/*        </li>*/}
                            {/*        <li data-section="support">*/}
                            {/*            <a>*/}
                            {/*                <HelpIco />*/}
                            {/*                <span>Help</span>*/}
                            {/*            </a>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className="settings-wrapper">
                <PaymentInfo page={page} me={me} />
                {/*{ !page && me && <General isActive={isGeneral} me={me} />}*/}
                {/*{ page === 'educations' && me && <Education type={page} me={me} />}*/}
                {/*{ page === 'experiences' && me && <Education type={page} me={me} />}*/}
                {/*{ page === 'files' && me && <DisplayFile type={page} me={me} />}*/}
                {/*{ page === 'account' && me && <Account type={page} me={me} />}*/}
                {/*{ page === 'payment-info' && me && <PaymentInfo type={page} me={me} />}*/}
            </div>
        </div>)
}
export default Settings
