import React, {useContext} from 'react'
import ModalWrapper from "./index";
import CoverBG, {DeskTopMenu} from "../pages/main/CoverBG";
import SubProfile from "../pages/main/SubProfile";
import styled from "styled-components";
import { useHistory } from 'react-router-dom'
import {format} from "date-fns";
import {contactsDocs} from "../firestore/firestoreService";
import {MyContext} from "../stores";

const CardInUserPageView = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
  .about-card {
    margin-bottom: 1rem;
  }
  .bringcenter {
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    div {
      font-size: 10px;
    }
  }
  .fframe{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    .ico {
      justify-content: center;
      display: flex;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #e0e0e0;
    }
  }
 
`

const ActionButton = styled.div`
  display: grid;
  border-radius: 10px;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr;
`

export default function PreviewUser({user, backgroundColor, closeColor}) {

    const {globalId, me, setMaincontact} = useContext(MyContext)
    const history = useHistory()
    const onChat = async (e) => {
        e.preventDefault()
        let contact = [globalId, user.id].sort().join("_")
        const doExist = await contactsDocs
            .where('contact', '==', contact)
            .get()
        if(doExist.empty) {
            await contactsDocs.add({
                type: 'users',
                users: [
                    me,
                    user
                ],
                usersIds: [
                    globalId,
                    user.id
                ],
                contact
            })
        } else {

        }
        setMaincontact(user)
        history.push(`/chat`)
    }
    return (
        <ModalWrapper type="imageModalFullWidth" >
            <div className="view-wrapper" style={{backgroundColor}}>
                <div className="container is-custom">
                    <div id="profile-main" className="view-wrap is-headless">
                        <div className="columns is-multiline no-margin">
                            <div className="column is-paddingless">
                                <CoverBG me={user}/>
                                <DeskTopMenu />
                                <SubProfile me={user} />
                                {/*<NavigationProfile />*/}
                                <div className="column">
                                    <ActionButton>
                                        <button className="button is-primary" onClick={onChat}>
                                            <i className="mdi mdi-chat"></i>
                                        </button>
                                        <button className="button is-link">
                                            <span>start proposition</span>
                                        </button>
                                    </ActionButton>
                                    <div className="content-section is-active" style={{marginBottom: '1rem'}}>
                                        <div className="about-card">
                                            <div className="header">
                                                <div className="icon-title">

                                                    <h3>Work experience</h3>
                                                </div>
                                            </div>
                                            <div className="body">
                                                {user?.experiences?.reverse(function(a, b){
                                                    return b.startDate - a.startDate
                                                }).map((v, key)=> {
                                                    return <CardInUserPageView key={key}>
                                                        <div className="fframe">
                                                            <div className="ico">
                                                                <i className="mdi mdi-briefcase-account"></i>
                                                            </div>
                                                        </div>
                                                        <div className="bringcenter">
                                                            <h3>{v.title}</h3>
                                                            <div>{format(new Date(v.startDate), "MM yyyy")} - {format(new Date(v.endDate), "MM yyyy")}</div>
                                                        </div>
                                                    </CardInUserPageView>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content-section is-active">
                                        <div className="about-card">
                                            <div className="header">
                                                <div className="icon-title">
                                                    <i className="mdi mdi-chair-school"></i>
                                                    <h3>Educations</h3>
                                                </div>

                                            </div>
                                            <div className="body">
                                                {user?.educations?.reverse(function(a, b){
                                                    return a.startDate - b.startDate
                                                }).map((v, key)=> {
                                                    return <CardInUserPageView key={key}>
                                                        <div className="fframe">
                                                            <div className="ico">
                                                                <i className="mdi mdi-school"></i>
                                                            </div>
                                                        </div>
                                                        <div className="bringcenter">
                                                            <h3>{v.title}</h3>
                                                            <div>{format(new Date(v.startDate), "MM yyyy")} - {format(new Date(v.endDate), "MM yyyy")}</div>
                                                        </div>
                                                    </CardInUserPageView>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalWrapper>

    )
}
