const ethnicities = [
    {"fr" : "Blanc",
        "en" : "White"
    },
    {"fr" : "Noir",
        "en" : "Black"
    },
    {"fr" : "Arabe",
        "en" : "Arab"
    },
    {"fr" : "Hispanique",
        "en" : "Hispanic"
    },
    {"fr" : "Premières nations",
        "en" : "First Nation"
    },
    {"fr" : "Autre",
    "en" : "Other"
    }
]



export default ethnicities