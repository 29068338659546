import React, {useContext} from "react";
import {MyContext} from "../stores";
import styled from 'styled-components';


export const WrapedSubHeader = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  .subheader-middle {
    width: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white !important;  
  }
`
const SubProfile = props => {
    const context = useContext(MyContext)
    return (
        <WrapedSubHeader className="profile-subheader" style={{display: 'none'}}>
            <div className="subheader-middle">
                <h2 style={{color: 'white'}}>{context.me && context.me.firstname} {context.me && context.me.lastname}</h2>
                <span style={{color: 'white'}}>{context.me && context.me.select.name}</span>
            </div>
        </WrapedSubHeader>
    )
}

export default SubProfile
