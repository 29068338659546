import React, {useEffect, useState, useRef} from "react";
import styled from 'styled-components'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { createTheme, ThemeProvider } from "@mui/material/styles";

import MUIRichTextEditor from "mui-rte";
import { v4 as uuid} from 'uuid'
import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    TextField,
    Select as SelectItems,
    MenuItem,
    IconButton,
    Button,
    FormControl,
    InputLabel,
    Switch, Toolbar, DialogContent, Dialog


} from '@material-ui/core';

import {Snackbar, Alert} from "@mui/material";
import {useTranslation} from "react-i18next";

import {
    Close as CloseIcon,
    Edit as EditIcon,
    ExpandMore as ExpandMoreIcon,
    Delete as DeleteIcon,
    AddCircleRounded as AddIcon
} from '@mui/icons-material'

import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import Doctypes from "../../stores/enums/Doctypes";
import {AppBarStyled} from "./Answers";
import {DynamicTextArea} from "./Preview";
import {postsDoc} from "../../firestore/firestoreService";


const turnString = (value) => {
    try{
        if(value instanceof Object) {
            return JSON.stringify(value)
        }
        if(typeof value === "string") {
            return value;
        }
    }catch(e) {}
}

const QuestionStyled = styled.div`
  background-color: white;
  height: 100%;
  padding: 30px 8px;
  border: 1px solid var(--bazookka-default-border-color);
  box-shadow: var(--bazookka-default-box-shadow);
  border-radius: 8px;
  width: 100%;
  margin: 1rem auto;
  .innerQuestion {
    display: block;
  }
  .add_new_question {
    display: grid;
    grid-template-columns: 1fr 1fr 50px;
    grid-gap: 1rem;
    margin-bottom: 1rem;
  }
  .edit_options {
    display: grid;
    grid-template-columns: 30px 150px 30px;
  }
  .add_n_option {
    display: flex;
    width: 100%;
    
  }
  .form__content {
    display: block;
    width: 800px;
    min-width: 320px;
    max-width: 800px;
    margin: auto;
  }
  .form__name {
    h2 {
      text-align: center;
    }
  }
`;

const displayOptions = ({...all}) => {

    switch(all.type) {
        case "Static text":
            return <AddTemplate
                {...all}
               />
        break;
        default:
            return <AddOptions
                {...all}
                />

}
}
 // a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const AddTemplate = ({openedit, setSelect, setQuestions, id, questions, edit, setOption, opt, setOpenEdit, select, single}) => {
    const {t} = useTranslation()
    const ref = useRef()

    const updateOption = (e) => {
        if(edit) {
            setQuestions(questions?.map(q => {
                if (q.id === single.id) {
                    q.content = e;
                }
                return q;
            }))
        }else {
            setOption({...single, content: e })
        }
    }

    if(!single) {
        return <h1>Save</h1>
    }


    return (
        <>
            <ThemeProvider theme={createTheme({ overrides: {
                    MUIRichTextEditor: {
                        root: {
                            border: 'none !important',
                            minHeight: 200
                        }
                    }
                }})}>
                <MUIRichTextEditor
                    style={{minHeight: 300}}
                    defaultValue={turnString(single.content)}
                    label={t("general.typeSOmething")}
                    onSave={ async state => {
                        updateOption(state)
                        // setFieldValue("desc", JSON.parse(state))
                        // setContent(JSON.parse(state))
                        //await postsDoc.doc(values.id).update({desc: JSON.parse(state)})
                    }}
                    ref={ref}
                    controls={["bold", "italic", "underline", "quote", "clear", "save"]}
                />
            </ThemeProvider>
    {/*    <Button variant="contained" startIcon={<EditIcon />} onClick={e => {*/}
    {/*        console.log("button", opt)*/}
    {/*        setSelect({...opt, content: ref.current})*/}
    {/*        setQuestions([...questions, ])*/}
    {/*        //setOpenEdit(true)*/}

    {/*}*/}
    {/*}>{t('general.save')}</Button>*/}

        </>
            )
}

const RichTextEdit = ({select, updateOption}) => {
    const myTheme = createTheme({})
    const ref = useRef()
    const {t} = useTranslation()

    return (
        <ThemeProvider theme={myTheme}>
            <MUIRichTextEditor
                style={{minHeight: 300}}
                ref={ref}
               defaultValue={select && select.content}
               label={t("general.typeSOmething")}
                inlineToolbar={true}
                onSave={ e => updateOption(e)}
            >

            </MUIRichTextEditor>
        </ThemeProvider>

    )
}

const AddOptions = ({options, type, setQuestions, id, questions, edit, setOption, opt}) => {
    const [single, setSingle] = useState({
    })

    const [op, setOp] = useState(options || [])

    function update(value, key) {
        setSingle({[key]: value})
    }
    function updateArray(value, key, index) {
        setOp(op.map(option => {
            if(option.id === index) {
                option[key]= value;
            }
            return option;
        }))
        if(edit) {
            setQuestions(questions.map(q => {
                if (q.id === id) {
                    q.options = op;
                }
                return q;
            }))
        }

    }


    function add(v = single) {
        setOp([...op, {id: uuid(), ...v}])
        setSingle({
            id: uuid(),
            optionText: ''
        })
        if(edit) {
            setQuestions(questions.map(q => {
                if (q.id === id) {
                    q.options = [...q.options, {id: uuid(), ...v}];
                }
                return q;
            }))

        } else {
            let temp = opt;
            if(!temp.options) {
                temp.options = [];
            }

            temp.options = [...temp.options, {id: uuid(), ...v} ]
            setOption(temp)
        }
    }

    function remove(index){
        setOp(op.filter( o => o.id !== index))
    }


    return (
        <div>
            <div className={`edit-options`}>
                <input type={type}/>
                <TextField value={single?.optionText} onChange={e => update(e.target.value, 'optionText')}/>
                <IconButton color="red" variant={`contained`} onClick={()=> add(single)}><AddIcon /></IconButton>
            </div>
            <div>
                {op && op.map( (option, i) => {
                    return (
                        <div key={i}>
                            <input type={type}/>
                            <TextField value={option?.optionText} onChange={e => updateArray(e.target.value, 'optionText', option.id)}/>
                            <IconButton variant="contained" onClick={e => remove(option.id)}><DeleteIcon /></IconButton>
                        </div>
                    )
                })}
            </div>
        </div>


    )
}

const EditQuestion = ({openedit, question, updateArray, setQuestions, questions, setOpenEdit, setSelect, select}) => {
    const update = () => {}
    const add = () => {}
    const {t} = useTranslation()
    const onDelete = () => {
        setQuestions(questions.filter(q => q.id !== question.id))
    }
    return (
        <div className="innerQuestion">
        <div className={`add_new_question`}>
            <TextField value={question?.questionText} onChange={e => updateArray(e.target.value, 'questionText', question.id)} />
            <select value={question?.questionType} onChange={ e => updateArray(e.target.value, 'questionType', question.id)} disabled={true}>
                <option value={`text`}>{t("formCreator.questionType.text")}</option>
                <option value={`paragraph`}>{t("formCreator.questionType.paragraph")}</option>
                <option value={`rating`}>{t( "formCreator.questionType.rating")}</option>
                <option value={`emoji rating`}>{t("formCreator.questionType.emojiRating")}</option>
                <option value={`checkbox`}>{t("formCreator.questionType.checkbox")}</option>
                <option value={`radio`}>{t("formCreator.questionType.radio")}</option>
                <option value={`Static text`}>{t("formCreator.questionType.staticText")}</option>
            </select>
            {/* <SelectItems value={question?.questionType} onChange={ e => updateArray(e.target.value, 'questionType', question.id)} disabled={true}>
                <MenuItem value={`text`}>Text</MenuItem>
                <MenuItem value={`paragraph`}>Paragraph</MenuItem>
                <MenuItem value={`emoji rating`}>Emoji rating</MenuItem>
                <MenuItem value={`rating`}>Rating</MenuItem>
                <MenuItem value={`checkbox`}>Checkbox</MenuItem>
                <MenuItem value={`radio`}>Radio</MenuItem>
                <MenuItem value={`Static text`}>Static text</MenuItem>
            </SelectItems> */}
        </div>
        <div className={`add_n_option`}>
            {(question.questionType == 'radio' || question.questionType == 'checkbox' || question.questionType === 'Static text')
            && displayOptions({openedit, setSelect, setOpenEdit, options:question.options, type: question.questionType, questions: questions, setQuestions:setQuestions, edit:true, id:question.id, opt: question, select, single: question})}
        </div>
        <div className="delete question">
            <Button variant="contained" startIcon={<DeleteIcon></DeleteIcon>} onClick={onDelete}>
                {t('general.delete')}
            </Button>
        </div>
        </div>
    )
}

export default function QuestionForm({name, questions, setQuestions, setDesc, desc, type, setType, checked, setChecked}){
    const [open, setOpen] = useState(false)
    const [openedit, setOpenEdit] = useState(false)
    const [select, setSelect] = useState(null)
    const {t} = useTranslation()
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            questions,
            result.source.index,
            result.destination.index
        );
        setQuestions(items);
    }

    const updateOption = (value) => {
        console.log(value)
    }

    const handleClose = () => setOpen(!open)


    const [single, setSingle] = useState({
        open: false,
        required: false,
        options: [],
        questionType: 'text',
        content: `{"blocks":[{"key":"8if0r","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}} `
    })

    function update(value, key){
        setSingle({...single, [key]: value})
    }

    function updateArray(value, key, id){
        setQuestions(questions.map( ques => {
            if(id === ques.id) {
                ques[key] = value;
            }
            return ques;
        }))
    }

    function add(){
        if(!single.questionText || single.questionText.length === 0 || single.questionText.length <= 3) {
            setOpen(true)
            return
        }
        setQuestions([...questions, {id: uuid(), ...single}])
        setSingle({
            open: false,
            required: false,
            questionText: '',
            questionType: 'text',
            options: [],
            content: `{"blocks":[{"key":"8if0r","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`
        })

    }

    const displayMenue = () => {
        return Object.keys(Doctypes).map((k, i)=>{
            return <option value={Doctypes[k]} key={i}>{t(Doctypes[k])}</option>
        })
    }

    useEffect(()=> {


    }, [questions, select])


    return (
        <QuestionStyled>
            <div className="form__content">
                <div className="form__name">
                    <h2>{name}</h2>
                </div>
                <div className="form__description">
                    <label>Description</label>
                    <TextareaAutosize
                        area-label="description"
                        style={{width: "100%", padding: 10}}
                        value={desc}
                        onChange={e => setDesc(e.target.value)}
                        minRows={5}/>
                </div>
                <div className={`add_new_question`}>
                    <FormControl sx={{m:1, minWidth: 120}}>
                        <InputLabel>{t('formBuilder.selectFormType')}</InputLabel>
                        {/* <SelectItems onChange={ e => setType(e.target.value)} value={type}>

                            {displayMenue()}

                        </SelectItems> */}
                        <select value={type} onChange={e => setType(e.target.value)}>
                        {displayMenue()}
                        </select>
                    </FormControl>
                </div>
                <div className={`add_new_question`}>
                    <FormControl>
                        <div>{t('formBuilder.addSignature')}</div>
                        <Switch
                            onChange={e => setChecked(e.target.checked)}
                            checked={checked}
                        ></Switch>
                    </FormControl>
                </div>
                <div className={`add_new_question`}>

                    <FormControl sx={{m:1, minWidth: 120}}>
                        <TextField
                            value={single.questionText} onChange={e => update(e.target.value, 'questionText')}
                            label={`Question`}
                        />
                    </FormControl>
                    <FormControl sx={{m:1, minWidth: 120}}>
                        <InputLabel>{t("formBuilder.selectFormQuestion")}</InputLabel>
                        <select value={single.questionType} onChange={ e => update(e.target.value, 'questionType')}>
                            <option value={`text`}>{t("formCreator.questionType.text")}</option>
                            <option value={`paragraph`}>{t("formCreator.questionType.paragraph")}</option>
                            <option value={`rating`}>{t( "formCreator.questionType.rating")}</option>
                            <option value={`emoji rating`}>{t("formCreator.questionType.emojiRating")}</option>
                            <option value={`checkbox`}>{t("formCreator.questionType.checkbox")}</option>
                            <option value={`radio`}>{t("formCreator.questionType.radio")}</option>
                            <option value={`Static text`}>{t("formCreator.questionType.staticText")}</option>
                        </select>

                        {/* <SelectItems  value={single.questionType} onChange={ e => update(e.target.value, 'questionType')} >
                            <MenuItem value={`text`}>Text</MenuItem>
                            <MenuItem value={`paragraph`}>Paragraph</MenuItem>
                            <MenuItem value={`rating`}>Rating</MenuItem>
                            <MenuItem value={`emoji rating`}>Emoji rating</MenuItem>
                            <MenuItem value={`checkbox`}>Checkbox</MenuItem>
                            <MenuItem value={`radio`}>Radio</MenuItem>
                            <MenuItem value={`Static text`}>Static text</MenuItem>
                        </SelectItems> */}
                    </FormControl>
                    <IconButton onClick={add} variant={`contained`}>
                        <AddIcon />
                    </IconButton>


                </div>
                <div>
                    {(single.questionType == 'radio' || single.questionType == 'checkbox' || single.questionType == 'Static text')
                        && displayOptions({select, openedit, setSelect, setOpenEdit, single, options: single.options || [], type: single.questionType, setOption: setSingle, opt: single})}
                </div>

                <div className="question__list">
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot)=>{
                                return (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                            background: snapshot.isDraggingOver
                                            ? "lightblue": "lightgrey",
                                            padding: 4,
                                            width: '100%',
                                            height: 'auto'
                                        }}
                                    >
                                        {questions.map((ques, i) => {
                                            return (
                                                <Draggable key={i} draggableId={ques.id} index={i}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                    userSelect: "none",
                                                                    padding: 16,
                                                                    margin: "0 0 8px 0",
                                                                    minHeight: "50px",
                                                                    backgroundColor: snapshot.isDragging
                                                                        ? "#263B4A"
                                                                        : "#456C86",
                                                                    color: "white",
                                                                    ...provided.draggableProps.style
                                                                }}
                                                            >
                                                                <Accordion key={i}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography>{ques.questionText}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <EditQuestion select={select} openedit={openedit} setSelect={setSelect} question={ques} updateArray={updateArray} questions={questions} setQuestions={setQuestions} setOpenEdit={setOpenEdit}/>
                                                    </AccordionDetails>
                                                </Accordion>
                                                            </div>)
                                                    }}
                                                </Draggable>
                                            )
                                        })}
                                    </div>
                                )
                            }}

                        </Droppable>

                    </DragDropContext>

                </div>

            </div>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
                   {t("formCreator.questionRequired")}
                </Alert>
            </Snackbar>
        </QuestionStyled>
    )
}
