import React, {useContext, useEffect, useState} from 'react'
import {MyContext} from "../stores";
import moment from "moment";
import {format} from "date-fns";
import {fr} from "date-fns/locale"
import {calendarsDoc,firestore,firebaseInstance} from "../firestore/firestoreService";
import {useTranslation} from "react-i18next";
import styled from 'styled-components'
import { Divider } from '@mui/material';

const ButtonPink = styled.a`
  background-color: var(--bazookka-pink);
  color: white;
  :hover {
    color: white;
    opacity: 0.70;
  }
`

const StyleCalenderCrud = styled.div`
  width: 100%;
  display: grid !important;
  grid-template-columns: auto 1fr auto auto auto;
  margin-bottom: 5px
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  padding: 10px 10px;
  background: white;
  .orange {
    background-color: var(--bazookka-orange) !important;
  }
  .blue {
    background-color: var(--bazookka-blue) !important;
  }
  .button {
    float: right;
  }
`

const ListMeeting = ({setAppEditCalendar, data}) => {
    const {t} = useTranslation()
    const {dispatchModal, langChoice, me} = useContext(MyContext)

    const rejectInvite = (apps) => {
        let user = firebaseInstance().auth().currentUser
        calendarsDoc.doc(apps.id).update({
            attendeesId : firestore.FieldValue.arrayRemove(user.uid)
        })
    }

    const formatDates = (date,formatString) => {
        if(typeof date === "object"){
            return format(date.seconds, formatString)
        }
        return format(date, formatString)
    } 

    return (
        <div className="schedule-events" style={{display: 'block'}}>
            <div className="schedule-events-title">
                {t('general.upcomingmeetingsorevents')}
            </div>
            {data.map( (apps, i) => {
                return (
                    <StyleCalenderCrud className="schedule-event" key={i}>
                       { apps.type === "rdv" ?  <div className="event-date orange">{formatDates(apps.startDate,'dd')}</div>
                            : <div className="event-date blue">{formatDates(apps.startDate,'dd')}</div>
                       }
                         <div className="event-title">
                            <span>{apps.title}</span>
                            <span>{formatDates(apps.startDate, 'hh:mm aa')} 
                            - {formatDates(apps.endDate, 'hh:mm aa')} | {formatDates(apps.startDate, 'MMM d yyyy',
                                {locale: langChoice === 'fr' && fr })}
                                </span>
                        </div>
                        <div className="button" onClick={()=> {
                            dispatchModal({
                                type: 'OPEN_MODAL',
                                payload: {
                                    modalType: 'EditMeetingModal',
                                    modalProps: {
                                        value: apps,
                                        title: t("general.edit"),
                                        edit: true,
                                        ico: <i className="mdi mdi-calendar-edit"></i>


                                    }
                                }
                            })
                        }}>
                            <i className="mdi mdi-calendar-edit"></i>
                        </div>
                        { apps.type === "event" ? <div  className="button" onClick={() => rejectInvite(apps)}>
                            <i style={{display: 'inline-block', width : "3.5rem"}} className="mdi mdi-delete"></i>
                        </div>
                        : <div className="button" key={apps.id} onClick={() => rejectInvite(apps)}>
                            {t('general.calendar.declineLabel')}
                        </div>
                        }
                    </StyleCalenderCrud>
                )
            })}
        </div>
    )
}

const Meeting = () => {
    return (
        <>
            <div className="day-header day-header--large">
                <div className="day-header-bg green animate"></div>
                <div className="day-header-close animate">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                         className="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </div>
                <div className="day-header-content animate-in">
                    <div className="day-header-title">
                        <div className="day-header-title-day">24</div>
                        <div className="day-header-title-month">October</div>
                    </div>
                    <div className="day-header-event">Workout Session</div>
                </div>
            </div>
            <div className="day-content has-slimscroll animate-in">
                <div id="event-1" className="event-details-wrap is-active">
                    <div className="meta-block">
                        <i className="mdi mdi-lock"></i>
                        <div className="meta">
                            <span>Private</span>
                            <span>This is a private event</span>
                        </div>
                    </div>
                    <div className="meta-block">
                        <i className="mdi mdi-map-marker"></i>
                        <div className="meta">
                            <span>Where</span>
                            <span>@ Mom and Dad's house</span>
                        </div>
                    </div>
                    <div className="meta-block">
                        <i className="mdi mdi-progress-clock"></i>
                        <div className="meta">
                            <span>When</span>
                            <span>02:00pm - 03:30pm</span>
                        </div>
                    </div>
                    <div className="participants-wrap">
                        <label>4 Participants</label>
                        <div className="participants">
                            <img src="https://via.placeholder.com/300x300"
                                 data-demo-src="assets/img/avatars/jenna.png" alt="" data-user-popover="0"/>
                            <img src="https://via.placeholder.com/300x300"
                                 data-demo-src="assets/img/avatars/nelly.png" alt="" data-user-popover="9"/>
                            <img src="https://via.placeholder.com/300x300"
                                 data-demo-src="assets/img/avatars/stella.jpg" alt="" data-user-popover="2"/>
                            <img src="https://via.placeholder.com/300x300"
                                 data-demo-src="assets/img/avatars/milly.jpg" alt=""
                                 data-user-popover="7"/>
                        </div>
                    </div>
                    <div className="event-description">
                        <label>Description</label>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci blanditiis commodi
                            accusamus dolores itaque repudiandae.</p>
                    </div>
                    <hr />
                    <div className="button-wrap">
                        <a className="button is-bold">Participating</a>
                        <a className="button is-bold">Details</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export const Calendar = ({}) => {
    const {setAppEditCalendar, setAppOver, me, dispatchModal, globalId} = useContext(MyContext)
    const [value, setValue] = useState(moment());
    const [apicall, setApicall] = useState(false)
    const [appointments, setAppointments] = useState([])
    const [calendrier, setCalendrier] = useState([])
    const [form, setForm] = useState(false)
    const {t} = useTranslation()

    useEffect(()=> {
        if(!apicall && me?.select) {
           const cleanUp =   calendarsDoc
                .where('attendeesId', 'array-contains-any', [globalId])
                .orderBy('startDate', 'asc')
                .onSnapshot( doc => {
                    setApicall(true)
                    setAppointments(doc.docs.map( d => {
                        return {id: d.id, ...d.data()}
                    }))
                })
                return () => cleanUp()
        }
    }, [apicall, me])
    useEffect(()=> {

        const day = startDay.clone().subtract(1, "day")
        const a = []
        while(day.isBefore(endDay, "day")){
            a.push(
                Array(7)
                    .fill(0)
                    .map( () => day.add(1, "day").clone())
            )
            setCalendrier(a);
        }
    }, [value])

    const startDay = value.clone().startOf('month').startOf('week')
    const endDay = value.clone().endOf('month').endOf('week')
    const currentMonth = () =>{
        return value.format('MMMM')
    }
    const activateCalendar = evt => {dispatchModal({type: 'OPEN_MODAL', payload: {
            modalType: 'EditMeetingModal',
            modalProps: {
                title: t('general.create'),
                ico: <i className="mdi mdi-calendar-star"></i>,
                closeOut: ()=> dispatchModal({type: 'CLOSE_MODAL'}),
                value

            }
            }})
    }
    const currentYear = () => {
        return value.format('YYYY')
    }
    const setColor = (value) => {
        let allMoment = appointments.filter( a => {
            return value.isSame(moment(a.startDate), 'day')
        })
        return allMoment.length > 0
    }
    const prevMonth = () => {
        return value.clone().subtract(1, 'month')
    }

    const nextMonth = () => {
        return value.clone().add(1, 'month')
    }
    return (
        <div id="schedule-content"  className={`content-section is-active`}>
            <div className="schedule">
            {true && <ListMeeting setAppEditCalendar={setAppEditCalendar} data={appointments}/>}
                <div className="schedule-day-container hidden">
                    {false && <Meeting/>}
                </div>
                <Divider sx={{display : "block",backgroundColor: "black",marginBottom:"2px", height : "1px" }} />
                <div className="schedule-header">
                    <div className="nav-icon" onClick={()=>setValue(prevMonth())}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="feather feather-chevron-left">
                            <polyline points="15 18 9 12 15 6"></polyline>
                        </svg>
                    </div>
                    <div className="month">{currentMonth()} {currentYear()}</div>
                    <div className="nav-icon" onClick={()=>setValue(nextMonth())}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </div>
                <div className="schedule-calendar">
                    <div className="calendar-row day-row">
                        <div className="day day-name">{t('general.1')}</div>
                        <div className="day day-name">{t('general.2')}</div>
                        <div className="day day-name">{t('general.3')}</div>
                        <div className="day day-name">{t('general.4')}</div>
                        <div className="day day-name">{t('general.5')}</div>
                        <div className="day day-name">{t('general.6')}</div>
                        <div className="day day-name">{t('general.7')}</div>
                    </div>
                    {calendrier.map( (week,key) => {
                        return (
                            <div key={key} className="calendar-row">
                                {week.map( (day,key) => {
                                   if(value.isSame(day, 'day')) {
                                       return (<div key={key} onClick={() => setValue(day)}
                                                    className={`day event green`}>{day.format("D")}</div>)
                                   }
                                   else if(setColor(day)){
                                       return (<div key={key} onClick={() => setValue(day)}
                                            className={`day event purple`}>{day.format("D")}</div>)
                                   }
                                   else{
                                       return (<div key={key} onClick={() => setValue(day)}
                                                    className={`day`}>{day.format("D")}</div>)
                                   }
                                })}
                            </div>
                        )
                    })}

                </div>
                <ButtonPink className="button is-fullwidth" onClick={activateCalendar}>{t('general.createappointment')}</ButtonPink>
                <div className="schedule-divider"></div>

            </div>
        </div>
    )
}
export const EditCalendar = ()=> {
    const {setAppEditCalendar} = useContext(MyContext)
    return (
        <div id="share-modal" className="modal share-modal is-xsmall has-light-bg is-active" >
            <div className="modal-background"></div>
            <div className="modal-content">

                <div className="card">
                    <div className="card-heading">
                        <div className="dropdown is-primary share-dropdown">
                            <div>
                                <div className="button">
                                    <i className="mdi mdi-format-float-left"></i> <span>Share in your feed</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-chevron-down">
                                        <polyline points="6 9 12 15 18 9"></polyline>
                                    </svg>
                                </div>
                            </div>
                            <div className="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                    <div className="dropdown-item" data-target-channel="feed">
                                        <div className="media">
                                            <i className="mdi mdi-format-float-left"></i>
                                            <div className="media-content">
                                                <h3>Share in your feed</h3>
                                                <small>Share this publication on your feed.</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item" data-target-channel="friend">
                                        <div className="media">
                                            <i className="mdi mdi-account-heart"></i>
                                            <div className="media-content">
                                                <h3>Share in a friend's feed</h3>
                                                <small>Share this publication on a friend's feed.</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item" data-target-channel="group">
                                        <div className="media">
                                            <i className="mdi mdi-account-group"></i>
                                            <div className="media-content">
                                                <h3>Share in a group</h3>
                                                <small>Share this publication in a group.</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown-item" data-target-channel="page">
                                        <div className="media">
                                            <i className="mdi mdi-file-document-box"></i>
                                            <div className="media-content">
                                                <h3>Share in a page</h3>
                                                <small>Share this publication in a page.</small>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="dropdown-divider" />
                                        <div className="dropdown-item" data-target-channel="private-message">
                                            <div className="media">
                                                <i className="mdi mdi-email-plus"></i>
                                                <div className="media-content">
                                                    <h3>Share in message</h3>
                                                    <small>Share this publication in a private message.</small>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>


                        <div className="close-wrap">
                        <span className="close-modal" onClick={()=> setAppEditCalendar(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6"
                                                                                             y2="18"></line><line x1="6"
                                                                                                                  y1="6"
                                                                                                                  x2="18"
                                                                                                                  y2="18"></line></svg>
                        </span>
                        </div>
                    </div>
                    {/*<Calendar widget={true}/>*/}
                    <div className="card-footer">
                        <div className="button-wrap">
                            <button type="button" className="button is-solid dark-grey-button close-modal" onClick={()=> setAppEditCalendar(false)}>Cancel
                            </button>
                            <button type="button" className="button is-solid primary-button close-modal">Save
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


