export default function dedupArray(arrArg){
    var result = arrArg.reduce((unique, o) => {
        if(!unique.some(obj => obj.id === o.id)) {
          unique.push(o);
        }
        return unique;
    },[]);
    return result;
  }

  export function dedupUserByMail(arrArg){
    var result = arrArg.reduce((unique, o) => {
        if(!unique.some(obj => obj.email === o.email)) {
          unique.push(o);
        }
        return unique;
    },[]);
    return result;
  }