const profileTypes = [
    {"fr" : "Je suis un.e étudiant.e AVEC résidence ou citoyenneté canadienne",
        "en" : "I am a student WITH Canadian residency or citizenship"
    },
    {"fr" : "Je suis un.e étudiant.e étranger.ère AVEC permis d'étude ou de travail",
        "en" : "I am a foreign student WITH study or work permit"
    },
    {"fr" : "Je suis un.e étudiant.e étranger.ère SANS permis d'étude ou de travail",
        "en" : "I am a foreign student WITHOUT a study or work permit"
    },
    {"fr" : "Je suis un.e candidat.e professionnel.le diplômé.e",
        "en" : "I am a professional graduate candidate"
    },
    {"fr" : "Je suis un.e candidat.e professionnel.le NON diplômé.e",
        "en" : "I am a professional NOT graduate applicant "
    },
    {"fr" : "Je suis un.e candidat.e étranger.ère recherchant une première expérience en sol canadien AVEC permis de travail valide",
    "en" : "I am a foreign candidate looking for a first experience on Canadian soil WITH a valid work permit"
    },
    {"fr" : "Je suis un.e candidat.e étranger.ère recherchant une première expérience en sol canadien SANS permis de travail valide",
    "en" : "I am a foreign candidate looking for a first experience on Canadian soil WITHOUT a valid work permit"
    }
]



export default profileTypes