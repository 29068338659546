import React, {useEffect, useState, useContext} from "react";
import {MyContext} from "../../stores";
import {postsDoc} from '../../firestore/firestoreService'
import CommentList from './CommentList';
import CommentComposer from './CommentComposer';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";

const CommentSection = styled.div`
    border-top: 1px solid #e8e8e8;
    margin: 0 16px;
    padding: 16px 0;
`

const MoreCommentsButton = styled.div`
    margin: auto;
    width: fit-content;
    color: var(--bazookka-blue);
    padding: 5px 15px;
    border: 1px solid #e8e8e8;
    border-radius: 20px;
    cursor:pointer;

    &:hover{
        border-color: var(--bazookka-blue);
        background-color: var(--bazookka-blue);
        color: var(--bazookka-white);
    }
`

const CommentsSection = ({postId, commentsCount = 0, previewComments = []}) => {
    const {me, globalId} = useContext(MyContext);
    const [Active, setActive] = useState(false);
    const [Comments, setComments] = useState([]);
    const {t} = useTranslation()

    // This is the number of previewComment store in the post.
    // If you want to change this value please modify the function/src/commentWriteTriggers.js and redeploy the cloud function first.
    const previewCommentSize = 3;

    const dedupArray = (arrArg) => {
        var result = arrArg.reduce((unique, o) => {
            if(!unique.some(obj => obj.id === o.id)) {
              unique.push(o);
            }
            return unique;
        },[]);
        return result;
      }

    const handleSubmit = (data) => {
        const newComment = {
            displayName: me.displayName,
            myId: globalId,
            text: data,
            timestamp: Date.now(),
        }
          
        postsDoc.doc(postId).collection('comments').add(newComment).then((createdDoc) => {
            if(!Active){
                newComment.id = createdDoc.id;
                setComments(prevState => {
                    let newState = [...prevState, newComment];
                    return newState;
                });
            }
        }).catch((error) => {
            console.error("Error writing document: ", error);
        });
    }

    const loadAllComments = () => {
        setActive(true);
    }

    useEffect(() => {
        if(postId){
            if(Active){
                postsDoc.doc(postId).collection('comments').orderBy('timestamp', 'asc').onSnapshot(data => {
                    setComments(data.docs.map(d=> Object.assign({},{id: d.id, ...d.data()})));
                });
            }else if(previewComments.length > 0){
                if(Comments.length > previewCommentSize){
                    const mergedArray = [...Comments,...previewComments];
                    const dedupedArray = dedupArray(mergedArray);
                    setComments(dedupedArray);
                }else{
                    setComments(previewComments);
                }
            }
        }
    }, [postId, Active, previewComments])

    return (
        <CommentSection>
            {!Active && commentsCount > previewCommentSize &&
                <MoreCommentsButton onClick={loadAllComments}>{t('general.comments.loadAll')}</MoreCommentsButton>
            }
            <CommentList comments={Comments} />
            <CommentComposer onSubmit={handleSubmit}/>
        </CommentSection>
    )
}

export default CommentsSection;
