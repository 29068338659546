import React, {useContext, useEffect, useState, useRef, useLayoutEffect} from 'react'
import {MyContext} from "../stores";
import {useParams} from 'react-router-dom'
import DisplayContentFromSlate, {initialValue} from "../components/DisplayContentFromSlate";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import {fr} from "date-fns/locale"
import Loader from "../components/Loader";
import embedVideo from "../stores/embed";
import ImageGallery from "../components/ImageGallery";
import {postsDoc, firestore, firebaseInstance,calendarsDoc} from "../firestore/firestoreService";
import { UserAction } from "../stores/enums/UserAction";
import EventParticipants from '../components/EventParticipants';
import { useAlert } from 'react-alert';
export default function EventPage() {
    const {me, langChoice, globalId} = useContext(MyContext)
    const [post, setPost] = useState(null)
    const [apicall, setApicall] = useState(true)
    const [one, setOne] = useState(true)
    const [isAttending,setIsAttending] = useState(false)
    const [attendeesIds,setAttendeesIds] = useState({})
    const [attendees,setAttendees] = useState({})
    const [was_set, setWaset] = useState(false)
    const {eventId} = useParams()
    const video = useRef()
    const {t} = useTranslation()
    const alert = useAlert()
    const selectExists =  () => {
        return new Promise( resolve => {
            calendarsDoc.doc(`event-${eventId}`).get().then(doc => {
                setAttendeesIds(doc.data()?.attendeesId)
                if(doc.data()?.attendeesId && doc.data()?.attendeesId.includes(globalId)){
                    setIsAttending(true)
                    resolve(true)
                }
            })
        })
    }

    useEffect(async () => {
        calendarsDoc.doc(`event-${eventId}`).onSnapshot((snap) => {
            snap.data() && setAttendees(snap.data().attendees) 
        })
    },[attendeesIds])
    useEffect(async ()=> {
        if(apicall) {
            postsDoc.doc(eventId).onSnapshot( async snap => {
                setApicall(false)
                setPost({...snap.data()})
            })
        }
    }, [apicall])

    useEffect(async ()=> {
        if(!was_set) {
            setWaset(await selectExists())
        }
    }, [was_set])

    useLayoutEffect(()=> {
        if(one && video && video.current) {
            embedVideo(video.current);
            setOne(false)
        }
    }, [video, video?.current])

    const onEventParticipation = async () => {
        let select = await calendarsDoc.doc(`event-${eventId}`).get()
        setIsAttending(true)
        if(select.exists) {
                await calendarsDoc.doc(`event-${eventId}`).update({attendeesId: firestore.FieldValue.arrayUnion(globalId),attendees : firestore.FieldValue.arrayUnion(me) })
           alert.success(t("general.addedtoevent"))
        } else {

            await calendarsDoc.doc(`event-${eventId}`).set(
                {
                    title: post.title,
                    startDate: post.startDate,
                    endDate: post.endDate,
                    type: 'event',
                    desc: post.desc,
                    creatorId: post.author.id,
                    attendees: firestore.FieldValue.arrayUnion(me),
                    attendeesId: firestore.FieldValue.arrayUnion(globalId),
                }
            )
            alert.success(t("general.addedtoevent"))
            const createUsersActions = firebaseInstance().functions().httpsCallable("createUsersActions")
            createUsersActions({
                type : UserAction.EVENT_REGISTRATION,
                postTitle : post.title,
                postType : post.type,
                displayName : me.displayName,
                globalId
                })
        }
    }

    const onEventParticipationCancelation = async () => {
        calendarsDoc.doc(`event-${eventId}`).update({
            attendeesId: firestore.FieldValue.arrayRemove(globalId),
            attendees: firestore.FieldValue.arrayRemove(me),
        });
        setIsAttending(false)
        const createUsersActions = firebaseInstance().functions().httpsCallable("createUsersActions")
        createUsersActions({
            type : UserAction.EVENT_REGISTRATION_CANCEL,
            postTitle : post.title,
            postType : post.type,
            displayName : me.displayName,
            globalId
            })
        alert.success(t("general.removedfromevent"))
    }

    const showParticipants = () => {
        let res = ""
        if (globalId === post.author.id && attendees && attendees.length > 0){
            res =  <EventParticipants participants={attendees}/>  
        }else if(globalId === post.author.id){
            res =  t("event.noAttendeesMessage") 
        }
        return res
    }

    if(!post){
        return <Loader />
    }

    return (
        <div className="event-page-wrapper">
            <div className="event-cover">
                <img className="cover-image" src={post?.cover || 'assets/old/wallpaper.png'}
                     data-demo-src="assets/img/demo/unsplash/55.jpg" alt="" />
            </div>

            <div className="event-content">
                <div className="event-head">
                    <div className="left">
                        <h2>{post?.title}</h2>
                        <h3>{post?.startDate && format(post.startDate, 'hh:mm aa')} - {post?.endDate && format(post.endDate, 'hh:mm aa')}</h3>
                        <div className="button-separator">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-chevron-right">
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                        <div className="info-block">
                            <div className="info-head">
                                <div className="event-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-calendar">
                                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="16" y1="2" x2="16" y2="6"></line>
                                        <line x1="8" y1="2" x2="8" y2="6"></line>
                                        <line x1="3" y1="10" x2="21" y2="10"></line>
                                    </svg>
                                </div>
                                <span>{t('general.scheduledon')}</span>
                            </div>
                            <div className="info-body">
                                <p>{post?.startDate && format(post.startDate, 'PPPP', {locale: langChoice === 'fr' && fr})}</p>
                            </div>
                        </div>
                        <div className="info-block">
                            <div className="info-head">
                                <div className="event-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-map-pin">
                                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                        <circle cx="12" cy="10" r="3"></circle>
                                    </svg>
                                </div>
                                <span>{t('general.elocation')}</span>
                            </div>
                            <div className="info-body">
                                <a>{post?.address}</a>
                            </div>
                        </div>
                        <div className="info-block">
                            <div className="info-head">
                                <div className="event-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-share-2">
                                        <circle cx="18" cy="5" r="3"></circle>
                                        <circle cx="6" cy="12" r="3"></circle>
                                        <circle cx="18" cy="19" r="3"></circle>
                                        <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                                        <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
                                    </svg>
                                </div>
                                <span>{t('general.share')}</span>
                            </div>
                            <div className="info-body">
                                <div className="socials">
                                    <a>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-facebook">
                                            <path
                                                d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                                        </svg>
                                    </a>
                                    <a>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-twitter">
                                            <path
                                                d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                                        </svg>
                                    </a>
                                    <a>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-linkedin">
                                            <path
                                                d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                                            <rect x="2" y="9" width="4" height="12"></rect>
                                            <circle cx="4" cy="4" r="2"></circle>
                                        </svg>
                                    </a>
                                    <a>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-instagram">
                                            <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                                            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                            <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <h2>{t('general.subscribenow')}</h2>
                        <div className="subscribe-block">
                            {/*<p>Add this event to your calendar</p>*/}
                            {
                                isAttending ?
                                <button className="button is-solid is-warning raised" onClick={onEventParticipationCancelation}>
                                {t('general.removeeventcalendar')}</button>
                                :
                                <button className="button is-solid primary-button raised" onClick={onEventParticipation}>
                                {t('general.addeventcalendar')}</button>
                            }
                        </div>
                        { showParticipants() }
                        <div className="condition has-text-centered" style={{display:'none'}}>
                            <span>Or</span>
                        </div>
                        <div className="subscribe-block" style={{display:'none'}}>
                            <p>Scan this QR code with your phone to automatically register for this event.</p>
                            <img
                                src="https://cdn.futura-sciences.com/buildsv6/images/largeoriginal/f/6/2/f621f61ff6_50038379_codeqr-futura.jpg"
                                alt=""/>
                        </div>
                    </div>
                </div>

                {/*<div className="event-participants">*/}
                {/*    <div className="container-inner">*/}
                {/*        <a className="participant">*/}
                {/*            <div className="participant-avatar">*/}
                {/*                <img className="avatar" src="https://via.placeholder.com/150x150"*/}
                {/*                     data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1" alt="" />*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*        <a className="participant">*/}
                {/*            <div className="participant-avatar">*/}
                {/*                <img className="avatar" src="https://via.placeholder.com/150x150"*/}
                {/*                     data-demo-src="assets/img/avatars/bobby.jpg" data-user-popover="8" alt="" />*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*        <a className="participant">*/}
                {/*            <div className="participant-avatar">*/}
                {/*                <img className="avatar" src="https://via.placeholder.com/150x150"*/}
                {/*                     data-demo-src="assets/img/avatars/stella.jpg" data-user-popover="2" alt="" />*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*        <a className="participant">*/}
                {/*            <div className="participant-avatar">*/}
                {/*                <img className="avatar" src="https://via.placeholder.com/150x150"*/}
                {/*                     data-demo-src="assets/img/avatars/milly.jpg" data-user-popover="7" alt="" />*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*        <a className="participant">*/}
                {/*            <div className="participant-avatar">*/}
                {/*                <img className="avatar" src="https://via.placeholder.com/150x150"*/}
                {/*                     data-demo-src="assets/img/avatars/elise.jpg" data-user-popover="4" alt="" />*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*        <a className="participant">*/}
                {/*            <div className="participant-avatar">*/}
                {/*                <img className="avatar" src="https://via.placeholder.com/150x150"*/}
                {/*                     data-demo-src="assets/img/avatars/lana.jpeg" data-user-popover="10" alt="" />*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*        <a className="participant">*/}
                {/*            <div className="participant-avatar">*/}
                {/*                <img className="avatar" src="https://via.placeholder.com/150x150"*/}
                {/*                     data-demo-src="assets/img/avatars/david.jpg" data-user-popover="4" alt="" />*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*        <a className="participant">*/}
                {/*            <div className="participant-avatar">*/}
                {/*                <img className="avatar" src="https://via.placeholder.com/150x150"*/}
                {/*                     data-demo-src="assets/img/avatars/mike.jpg" data-user-popover="12" alt="" />*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*        <a className="participant">*/}
                {/*            <div className="participant-avatar">*/}
                {/*                <img className="avatar" src="https://via.placeholder.com/150x150"*/}
                {/*                     data-demo-src="assets/img/avatars/azzouz.jpg" data-user-popover="20" alt=""/>*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*        <a className="participant">*/}
                {/*            <div className="participant-avatar">*/}
                {/*                <img className="avatar" src="https://via.placeholder.com/150x150"*/}
                {/*                     data-demo-src="assets/img/avatars/jenna.png" data-user-popover="0" alt=""/>*/}
                {/*                    <div className="more-overlay">*/}
                {/*                        <span>+26</span>*/}
                {/*                    </div>*/}
                {/*            </div>*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div style={{height: 50}}></div>
                <div className="event-details">
                    <div className="left">
                        <div className="details-block">
                            <h3>{t('general.eventdetails')}</h3>
                            {post && post.desc && <DisplayContentFromSlate content={post?.desc}></DisplayContentFromSlate>}
                        </div>
                        {(post.galleryImages || post.video) &&
                            <div className="details-block">
                                <h3>{t('general.eventphotosandvideos')}</h3>
                                {post?.video && post.video !== "" && (
                                    <div className="video-block-wrapper">
                                        <div ref={video}
// @ts-ignore
                                        channel="youtube"  id="video-embed" className="video-block-inner"
                                            data-url={post?.video}
                                        >
                                            <div className="video-overlay"
                                                style={{backgroundImage: 'url(http://i2.ytimg.com/vi/7WoFOjbb4fE/hqdefault.jpg)'}}></div>
                                            <div className="playbutton" >
                                                <div className="icon-play">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                        strokeLinecap="round" strokeLinejoin="round"
                                                        className="feather feather-play">
                                                        <polygon points="5 3 19 12 5 21 5 3"></polygon>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {post.galleryImages &&
                                    <ImageGallery images={post.galleryImages} />
                                }
                            </div>
                        }
                    </div>

                    <div className="right">
                        <div className="event-owner">
                            <img className="avatar" src="https://via.placeholder.com/150x150"
                                 data-demo-src="assets/img/avatars/stella.jpg" data-user-popover="2" alt=""/>
                                <div className="meta">
                                    <span>{t('general.eob')}</span>
                                    <span>{post?.eo}</span>
                                </div>
                        </div>

                        <div className="side-block">
                            <div className="side-head">
                                <span>{t('settings.phone')}</span>
                            </div>
                            <div className="side-body">
                                <a>{post?.phone}</a>
                            </div>
                        </div>

                        <div className="side-block">
                            <div className="side-head">
                                <span>{t('general.emailaddress')}</span>
                            </div>
                            <div className="side-body">
                                <a>{post?.email}</a>
                            </div>
                        </div>

                        <div className="side-block">
                            <div className="side-head">
                                <span>{t('general.website')}</span>
                            </div>
                            <div className="side-body">
                                <a>{post?.website}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
