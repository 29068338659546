import React, {useContext, useState, useEffect, useRef} from "react";
import {Formik, ErrorMessage, Form} from 'formik'
import {firestore, postsDoc} from "../firestore/firestoreService";
import {format, isToday} from "date-fns";
import {MyContext} from "../stores";
import {useHistory} from "react-router-dom";
import {PageLoader} from "../components/OtherOvers";
import {InputDate, InputText, SwitchBox} from "../components/FormElements";
import RichTextEditor from "../components/RichTextEditor";
import {initialValue} from "../components/DisplayContentFromSlate";
import styled from "styled-components"
import {Multiselect} from 'multiselect-react-dropdown'
import {useTranslation} from "react-i18next";
import {useCatReducer} from "../stores/categories";
import * as Yup from 'yup';
import {ErrorStyle} from "../helper/Styled"
import parseDate from '../helper/form/parseDate';
import {ErrorContainer} from "../components/FormManager/ErrorContainer";
import {useAlert} from "react-alert";
import MUIRichTextEditor from "mui-rte"
import {convertToRaw} from "draft-js";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export const SubmitContainer = styled.div`
   display: flex;
   flex-direction: row;
   margin-top:20px;
   
   button, a{
       width:100%;
   }
`
export default function EditInternshipForm({internship, type, groups, isNew}){
    const {me, globalId, langChoice} = useContext(MyContext)
    const [content, setContent] = useState({})
    const [edit, setEdit] = useState(true)
    const [cat, setCat] = useState([])
    const [apicall, setApicall] = useState(false)
    const [data, setData] = useState(null)
    const [select, setSelect] = useState([])
    const {t} = useTranslation()
    const [categories, dispatchAll] = useCatReducer()
    const rte = useRef()
    const alert = useAlert()
    function validation( v ) {
        try{
            if(v.hasOwnProperty("blocks")) {
                return JSON.stringify(v)
            }
        }catch(e){}

        return `{"blocks":[{"key":"7po5","text":"Type text","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":6,"length":5,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"5sea5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}} `
    }

    const validate = !internship ?  
    Yup.object({
        title: Yup.string()
            .min(3, t('general.nbcharactersmin'))
            .required(t('general.internshiptitlerequired')),
        needed: Yup.number().min(1),
        tiny: Yup.string()
            .max(140, t('general.nbcharacters140'))
            .required(t('general.resumerequired')).default(''),
        date: Yup.date().transform(parseDate).min(new Date(), t('validation.mindate')).required(t('validation.required')),
        endDate: Yup.date().transform(parseDate).required(t('validation.required')),
        //category: Yup.array().min(1).required('Field required')
    })
    :
    Yup.object({
        title: Yup.string()
            .min(3, t('general.nbcharactersmin'))
            .required(t('general.internshiptitlerequired')),
        needed: Yup.number().min(1),
        tiny: Yup.string()
            .max(140, t('general.nbcharacters140'))
            .required(t('general.resumerequired')).default(''),
        date: Yup.date().transform(parseDate).required(t('validation.required')),
        endDate: Yup.date().transform(parseDate).required(t('validation.required')),
        //category: Yup.array().min(1).required('Field required')
    });

    const initialValues = {
        title: '',
        needed: 1,
        tiny: '',
        desc: '',
        type,
        active: false,
        groups: [],
        date: new Date(new Date().setDate(new Date().getDate() + 1)), //tomorrow
        endDate: new Date(new Date().setDate(new Date().getDate() + 2)), //after tomorrow,
        category: []
    }

    async function waitFor(){
        return new Promise(resolve => {
            setTimeout(()=> {
                resolve(true)
            }, 2000)

        })
    }

    const onSubmit = async (data, {setSubmitting}) => {
        setSubmitting(true)
        data.type = "internships";
        try{
            data.date = new Date(data.date).getTime()
        }catch(e){}
        try{
            data.endDate = new Date(data.endDate).getTime()
        }catch(e){}
        data.category = cat;
        rte.current.save()
        await waitFor()

        if(data.id) {
            if(isNew) {
                if(data.groups.length === 0) {
                    data.groups = [groups[0].id]
                }else {
                    data.groups = data.groups.map(g => g.id)
                }

            }
            if(internship) {
                delete data.desc
                let res = await postsDoc.doc(data.id).update(data)
            }

        }else{
            data.authorId = firestore.FieldValue.arrayUnion(globalId);
            data.author = {
                id: globalId,
                displayName: me.displayName
            }
            data.businessId = firestore.FieldValue.arrayUnion(me.select.id)
            if(!internship) {
                postsDoc.add(data).then(r => {
                    alert.success(t("message.success"))
                }).catch(e => {
                    alert.error(e.message)
                })
            }
        }
        setSubmitting(false)
    }


    useEffect(()=> {
        dispatchAll({type: langChoice === 'fr' ? 'ALL_SET_FR': 'ALL_SET_EN'})


        if(internship) {
            setData({...internship})
            if(internship.category) {
                setSelect(internship.category.map( c => {
                    return {key: c, value: t(c)}
                }))
            }

            setContent(validation(internship.desc))
            setApicall(true)
        }

        if(!internship) {
            setData(initialValues)
            setApicall(true)
            setEdit(false)
        }
        return ()=> {
            setSelect([])
            setData(initialValues)
        }
    }, [apicall, type])

    const returnCat = (cat, list, values, a) => {
        return values[cat] && values[cat].map( k => {
            return list.find(l => l.key === k)
        }) || []
    }

    const findGroups = (groups, groupsIds) => {
        let returnGroups = [];
        try {
            groupsIds.forEach(groupId => {
                groups.forEach(group => {
                    if (groupId.id) {
                        if (group.id === groupId.id) {
                            returnGroups.push(group);
                        }
                    } else {
                        if (group.id === groupId) {
                            returnGroups.push(group);
                        }
                    }
                });
            });
        }catch(e) {
            returnGroups.push(groups[0])
        }
        return returnGroups;
    }

    return (
            <Formik initialValues={{...initialValues,...data}} onSubmit={onSubmit} validateOnMount={true} enableReinitialize={true} validationSchema={validate}>
                {({values, setFieldValue,handleSubmit, errors, isSubmitting})=> {
                    return (
                        <Form>
                            <div className="settings-form-wrapper">
                                <div className="settings-form">
                                    <div style={{width: '95%'}}>
                                        <div className="field">
                                            <InputText
                                                label={t('jobs.title')}
                                                value={values.title}
                                                name="title" />
                                            <ErrorStyle component='div' name='title' />
                                        </div>
                                        <div className="field">
                                            <InputText
                                                label={ langChoice === 'fr'? 'Petit résumé': 'Little summary'}
                                                name="tiny" />
                                            <ErrorStyle component='div' name='tiny' />
                                        </div>
                                        <div className="field">
                                            <label>
                                                {t('general.whoshouldseethepost')}
                                                <Multiselect
                                                    options={groups}
                                                    selectedValues={isNew ? [groups[0]] : findGroups(groups, values.groups)}
                                                    name={'groups'}
                                                    displayValue="name"
                                                    placeholder={t('overlay.select')}
                                                    emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                    onSelect={v => {
                                                        setFieldValue('groups', v)
                                                    }}
                                                    onRemove={v => {
                                                        setFieldValue('groups', v)
                                                    }}
                                                >
                                                </Multiselect>
                                            </label>
                                            <ErrorContainer name="groups" component="div" />
                                        </div>
                                        {categories && categories.map((cat, i)=> {
                                            return (
                                                <div className="field" key={i}>
                                                    <label>{t(cat[0])}
                                                        <div className="control">
                                                            <Multiselect
                                                                options={cat[1] ||[]}
                                                                displayValue="value"
                                                                name="category"
                                                                placeholder={t('overlay.select')}
                                                                emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                                selectedValues={ returnCat(cat[0], cat[1], values, cat)}
                                                                onSelect={(v) => {
                                                                    values[cat[0]] = v.map( k => k.key)
                                                                }}
                                                                onRemove={(v) => {
                                                                    values[cat[0]] = v.map( k => k.key)
                                                                }}

                                                            >

                                                            </Multiselect>
                                                        </div>
                                                    </label>
                                                </div>
                                            )
                                        })}

                                        <div className="field">
                                            <InputDate
                                                label={langChoice === 'fr'? 'Date de début':'Start date'}
                                                value={new Date(values?.date || 0)}
                                                name="date"
                                            />
                                            <ErrorStyle component='div' name='date' />
                                        </div>

                                        <div className="field">
                                            <InputDate
                                                label={langChoice === 'fr'? 'Date de fin':'End date'}
                                                value={new Date(values?.endDate || 0)}
                                                name="endDate"
                                            />
                                            <ErrorStyle component='div' name='endDate' />
                                        </div>
                                        <div className="field">
                                            <SwitchBox
                                                label={langChoice === 'fr' ? 'Actif': 'Active'}
                                                name="active"
                                                // value={active}
                                                onClick={() => {console.log("test")}}
                                            />
                                            <ErrorStyle component='div' name='active' />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <ThemeProvider theme={createTheme({})}>
                                <MUIRichTextEditor
                                    defaultValue={validation(values.desc)}
                                    label={t("general.typeSOmething")}
                                    onSave={ async state => {
                                        setFieldValue("desc", JSON.parse(state))
                                        setContent(JSON.parse(state))
                                        await postsDoc.doc(values.id).update({desc: JSON.parse(state)})
                                    }}
                                    ref={rte}
                                    controls={["bold", "italic", "underline", "quote", "clear"]}
                                />
                            </ThemeProvider>
                            <SubmitContainer>
                                <button type="submit" className="button is-small is-link" disabled={isSubmitting}>{t('general.save')}</button>
                            </SubmitContainer>
                        </Form>
                    )
                }}
            </Formik>
    )
}