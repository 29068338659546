import React, {createContext, useState, useRef, useEffect, useContext, useMemo} from "react";

import {
    Dialog,
    Typography,
    Button,
    AppBar,
    DialogContent, Toolbar, Box, IconButton
} from "@mui/material"

import {Close as CloseIcon, Print as PrintIcon} from '@mui/icons-material'
import {useTranslation} from "react-i18next";
import html2canvas from 'html2canvas'
import {jsPDF} from 'jspdf'

import PropTypes from "prop-types"

export const DialogContext = createContext()
export const DialogProvider = ({
                        children,
                        template,
                        ...props
                        }) => {
    const root = useRef()
    const dialog = useRef()
    const ref = useRef()
    const [open, setOpen] = useState(false)
    const [node, setNode] = useState(<div>Wow</div>)
    const [user, setUser] = useState(null)
    const [data, setData] = useState(null)
    const [print, setPrint] = useState(false)
    const {t} = useTranslation()

    const printFile = () => {
        html2canvas(ref.current).then( canvas => {
            const img = canvas.toDataURL("image/png")
            const pdf = new jsPDF()
            pdf.addImage(img, "JPEG", 0,0)
            pdf.save(data.title + ".pdf")
        })
    }

    dialog.current = {
        call: (v)=> console.log(`you call me again ${JSON.stringify(v)}`),
        open: () => setOpen(!open),
        node: (v) => setNode(v),
        user: (v) => setUser(v),
        data: (v) => setData(v),
        print: () => setPrint(true),
    }
    return (
        <DialogContext.Provider value={dialog}>
            <Dialog
                fullWidth={true}
                fullScreen
                open={open}
            >
                <Box sx={{flexGrow: 1}}>
                    <AppBar

                    >
                        <Toolbar>
                            <Button startIcon={<CloseIcon />} style={{color: 'white'}} onClick={e=> setOpen(false)}>{t('general.close')}</Button>
                            <Box sx={{ flexGrow: 1 }} />
                            {user && user.displayName && <span>{user?.displayName}</span>}
                            {data && <span>{data.title}</span>}
                            <Box
                                sx={{ flexGrow: 1 }}
                            >

                            </Box>

                            <IconButton onClick={printFile}>
                                <PrintIcon style={{color: 'white'}}></PrintIcon>
                            </IconButton>

                        </Toolbar>

                    </AppBar>
                    <div ref={ref} style={{margin: '200px auto', width: 800, }}>
                        {node}
                    </div>

                </Box>

            </Dialog>
            {children}


        </DialogContext.Provider>
    )
}

export const useDialog = Context => {
    const dialogContext = useContext(DialogContext)
    const dialog = useMemo(()=> {
        return dialogContext.current
    }, [dialogContext])
    return dialog
}

