import React, {useEffect, useState, useContext} from 'react';
import {useTranslation} from "react-i18next";
import styled from 'styled-components';
import firebase from "firebase/app";
import {MyContext} from "../stores";
import {firebaseInstance} from "../firestore/firestoreService";

const LoginLink = styled.a`
span, img{
    vertical-align:middle;
}
`

const ZoomLogin = ({redirectUrl, loginComplete, setLoading = false}) => {
    const {globalId} = useContext(MyContext);
    const {t} = useTranslation();
    const [authorizationUrl, setAuthorizationUrl] = useState("");
    const ONE_MINUTES = 60 * 1000; /* ms */
    let zoomAPICalled = false;

    useEffect(() => {
        if(globalId && redirectUrl){
            const encodedRedirect = encodeURIComponent(redirectUrl);
            setAuthorizationUrl(`https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_API_KEY}&redirect_uri=${encodedRedirect}`)

            firebase.database().ref('/externalApi/zoom/code/' + globalId).on('value', function (snapshot) {
                const userZoomToken = snapshot.val();

                //Check if the Zoom Auth code is expired
                if(userZoomToken && ((new Date) - userZoomToken.updated) < ONE_MINUTES){
                    //Check if we're not waiting for a token already
                    if(!zoomAPICalled){
                        if(setLoading){
                            setLoading(true)
                        }
                        const getZoomToken = firebaseInstance().functions().httpsCallable('GetZoomToken');
                        getZoomToken({
                            code: userZoomToken.code,
                            redirectUrl: redirectUrl,
                        }).then( results => {
                            if(setLoading){
                                setLoading(false)
                            }
                            const userZoomTokenDatabaseRef = firebase.database().ref('/externalApi/zoom/token/' + globalId);
                            const zoomToken = {
                                code: results,
                                updated: firebase.database.ServerValue.TIMESTAMP,
                            };
                            userZoomTokenDatabaseRef.set(zoomToken).then(function () {
                                loginComplete(results.data);
                            });
                        }).catch(err=> {
                            if(setLoading){
                                setLoading(false)
                            }
                            console.log(err.code, err.message, err.details);
                        })
                    }
                }
            });
        }
    }, [redirectUrl, globalId])

    return(
        <LoginLink href={authorizationUrl} target="_blank"><span>{t("general.loginwith")}</span> <img style={{height: '10px'}} src="https://st1.zoom.us/static/5.2.2957/image/new/ZoomLogo.png" /></LoginLink>
    )
}

export default ZoomLogin;
