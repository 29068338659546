import React, {useState, useContext, useEffect} from 'react'
import styled from 'styled-components'
import {MyContext} from "../stores";
import {Elements, CardElement} from '@stripe/react-stripe-js'
import {loadStripe} from "@stripe/stripe-js";
import {FormControlLabel, FormGroup, Switch} from "@mui/material";
import Select from "react-select";
import NoItems from "../components/NoItems";
import {useTranslation} from "react-i18next";
import {BannerStyle, FilterOption} from "./Schools";
import {usePromise} from "../helper";
import {firestore, jobPosts, postsDoc} from "../firestore/firestoreService";
import {Title} from "@mui/icons-material";
import {PageLoader} from "../components/OtherOvers";
import {ShareBig} from "../components/Share";
import {Link} from "react-router-dom";
import {useQuery} from "../helper";

const load = (async () => {
    let data = await loadStripe('pk_test_jnr18UR9A1OQcZmlcqiszZuU00sxKvEMjR')
    return data;
})()

const Style = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  h1 {
    text-align: center;
    width: 100%;
    font-size: 1.5rem;
  }
 .top {
   width: 100%;
   margin-top: 1rem;
   display: inline-flex;
   justify-content: center;
   
 }
  .button_buy {
    padding: 1rem 2rem;
    border-radius: 8px;
    display: block;
    color: white;
    background-color: var(--bazookka-pink);
    border-color: var(--bazookka-pink);
    filter: drop-shadow(0.15rem 0.15rem 0.15rem rgba(0,0,0,0.5));
  }
`

const JobsCard = styled.div`
  display: flex;
  max-width: 100%;
  margin: auto;
  justify-content: center;
  .news-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .news-card {
    min-width: 250px;
    max-width: 300px;
  }
  
  
`

const BusinessesCard = ({data, alert}) => {
    const {langChoice, globalId, me} = useContext(MyContext)
    const {t} = useTranslation()

    const onCancel = (e) => {
        e.preventDefault()
        jobPosts.doc(data.id)
            .collection('applications')
            .doc(globalId)
            .delete()
        jobPosts.doc(data.id).update({
            applicationIds: firestore.FieldValue.arrayRemove(globalId)
        })
    }
    const onClick = ( e ) => {
        e.preventDefault()
        postsDoc.doc(data.id)
            .collection('applications')
            .doc(globalId)
            .set(me)
            .then( result => {
                alert.success(t("general.userapplied"))
            })
        postsDoc.doc(data.id).update({
            applicationIds: firestore.FieldValue.arrayUnion(globalId)
        })
    }
    return (

        <div className="news-card is-default" style={{boxShadow: 'var(--bazookka-box-shadow)'}}>
                    <span className="tag category politics" style={{display: 'none'}}>
                        {data.cat}
                    </span>
            {/*<div className="avatar-wrapper" style={{display: 'none'}}>*/}
            {/*    <div className="avatar-wrap">*/}
            {/*        <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/ken.jpg"*/}
            {/*             data-user-popover="14" alt="" />*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<img src="https://via.placeholder.com/1600x900" data-demo-src="assets/img/demo/news/p3.jpg"  style={{display: 'none'}} alt=""/>*/}
            <div className="news-content">
                <h3 className="news-title">{data.title}</h3>
                <h4>{data.author.companyName}</h4>
                <p>{data.tiny}
                </p>
                <div>{t(data.type)}</div>
                <div className="button-wrap">
                    <ShareBig share={data}/>
                    {!data.ext && (
                        <Link className="button" to={`/jobs/${data.id}`} style={{boxShadow: 'var(--bazookka-box-shadow)'}}><i className="mdi mdi-eye"></i></Link>
                    )}
                    {data.ext && (
                        <a href={data.link} className="button" style={{boxShadow: 'var(--bazookka-box-shadow)'}}><i className="mdi mdi-eye"></i></a>
                    )}

                </div>
                {!data.active && (
                    <div className="button-wrap">
                        <div className="actions" style={{display: "block"}}>
                            <a className="action" onClick={ async e => {
                                let resp = await fetch(`https://us-central1-bazookka-app.cloudfunctions.net/apiStripe/create_payment_link?id=${data.id}`, {method: "post", body: JSON.stringify({id: data.id}) })
                                let json = await resp.json();
                                console.log(json)
                                window.location.href = json.url;
                            }}>
                                {t('general.purchase')}
                            </a>
                        </div>
                    </div>
                )}


            </div>
        </div>

    )
}


export default function(){
    const {me, dispatchModal} = useContext(MyContext)
    const search = useQuery()
    const {t} = useTranslation()
    const [search_jobs, setSearchJobs] = useState([])
    const [lastdoc, setLastdoc] = useState(null)
    const [api, set_api] = useState(true)

    const [jobs, set_jobs] = useState([])

    async function callJobs() {
        let job = postsDoc.where("type", "==", "jobs")
            .where("businessId", "array-contains", me.select.id)
            .limit(10).get()
        let intr = postsDoc.where("type", "==", "internships")
            .where("businessId", "array-contains", me.select.id)
            .limit(10).get()
        let [job_resp, intr_resp] = await Promise.all([job, intr])
        return [...job_resp.docs.map(d => Object.assign({id: d.id, ...d.data()})),
            ...intr_resp.docs.map(d => Object.assign({id: d.id, ...d.data()}))]
    }

    useEffect(()=> {
        if(search.get("id")) {
            postsDoc.doc(search.get("id")).update({active: true}).then( v => {

            })
        }else {
            console.log("no id")
        }
        if(me && api) {
            callJobs().then( v => {
                set_jobs(v)
                set_api(false)
            })
        }
    })

    const onCreate = () => {
        dispatchModal({
            type: 'OPEN_MODAL',
            payload: {
                modalType: 'CreatePayJobs',
                modalProps: {
                    closeOut: ()=> dispatchModal({type: 'CLOSE_MODAL'})
                }
            }


        })
    }

    // if(jobLoad) {
    //     return (
    //         <PageLoader />
    //     )
    // }
    //
    // if(jobError) {
    //     return <div>Error</div>
    // }

    return (
        <Elements stripe={load}>
            <Style>
                <h1>{t('general.job.propositions')}</h1>
                <div className="top">
                    <a className="button_buy" onClick={onCreate}>{t('general.purchase_job_internship')}</a>
                </div>
                <div className="view-wrapper">
                    <div className="columns">

                        <div className="column">
                            {/*<SearchBar person={person }/>*/}
                        </div>
                    </div>
                    <JobsCard className="section is-news">
                        {jobs.length ===  0 && <NoItems />}
                        <div className="news-grid">
                            {jobs.length > 0 && jobs.map( c => {
                                return <BusinessesCard data={c} key={c.id} alert={alert}/>
                            })}

                        </div>
                    </JobsCard>
                </div>

            </Style>
        </Elements>


    )
}