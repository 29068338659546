import React, {useState, useContext} from 'react'
import {MyContext} from "../stores";
import Loader from '../components/Loader'
import {AiOutlineDownload, AiOutlineSync} from 'react-icons/ai'
import {FaUserGraduate} from 'react-icons/fa'
import useSWR from "swr";
import styled from 'styled-components'
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';
import {useTranslation} from "react-i18next";
import Avatar from "react-avatar";

const Grid2Col = styled.div`
 display: flex;
 gap: 1rem; 
 padding-top: 2rem; 
`

export const SectionHeader = ({title, category}) => (
    <div className=" mb-10">
        { category && <p className="text text-gray-400">{category}</p>}
        <p className={`${category? 'text-lg ': 'text-3xl'} font-extrabold tracking-tight text-slate-900`}>
            {title}
        </p>
    </div>
)

const DashboardComponent = () => {
    const [dbObj, setdbobj] = useState(null)
    const {globalId} = useContext(MyContext)
    const selectionsettings = { persistSelection: true };
    const toolbarOptions = ['Delete'];
    const editing = { allowDeleting: true, allowEditing: true };
    const topGrid = [
        {
            width: '50',
            headerText: 'Avatar',
            template: function Display(props) {
                return <Avatar name={props.display_name} size={40} round={true}/>
            }
        },
        {
            headerText: 'Nom',
            field: 'display_name',
            width: '200',
        },
        {
            headerText: 'Moyenne',
            field: 'average',
            width: '200',
            template: function Display(props){
                return <div>{parseFloat(props.average).toFixed(2)}</div>
            }
        }
    ]

    const bestGrid = [
        {
            width: '50',
            headerText: 'Avatar',
            template: function Display(props) {
                return <Avatar name={props.display_name} size={40} round={true}/>
            }
        },
        {
            headerText: 'Nom',
            field: 'display_name',
            width: '200',
        },
        {
            headerText: 'Qualifié',
            field: 'total',
            width: '200',
        }
    ]


    const {data, error, mutation} = useSWR(`https://api.bazookka.com/stats/dashboard`, url =>
    fetch(url, {
        method: 'get',
        headers: {
            'Content-type': 'application/json',
            'eid': globalId
        }
    }).then( r => r.json()))

    const {data: users, error: eUsers, mutation: uMutation} = useSWR(`https://api.bazookka.com/stats/dashboard-users`, url =>
        fetch(url, {
            method: 'get',
            headers: {
                'Content-type': 'application/json',
                'eid': globalId
            }
        }).then( r => r.json()))

    const {data: mentors, error: eMentors, mutation: mMentor} = useSWR(`https://api.bazookka.com/stats/mentorats`, url =>
        fetch(url, {
            method: 'get',
            headers: {
                'Content-type': 'application/json',
                'eid': globalId
            }
        }).then( r => r.json()))

    const {data: count, error: eCount, mutation: mCount} = useSWR(`https://api.bazookka.com/users/rates/count`, url =>
        fetch(url, {
            method: 'get',
            headers: {
                'Content-type': 'application/json',
                'eid': globalId
            }
        }).then( r => r.json()))
    if(!data || !users || !mentors || !count) {
        return <Loader></Loader>
    }

    return (
        <div className="mt-24 w-full">
            <SectionHeader title={'Tableau de bord'}/>
            <div className="flex flex-wrap lg:flex-nowrap justify-items-start ">
                <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
                    <div className="flex justify-between items-start">
                        <div>
                            <p className="font-bold text-gray-400">Charger les utilisateurs</p>
                            <p className="text-2xl">{users.length}</p>
                        </div>
                        <button
                            type="button"
                            className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4 bg-green-600"
                        >
                            <AiOutlineSync />
                        </button>
                    </div>
                </div>
            </div>
            <Grid2Col>
                <div className="w-full md:w-1/2 xl:w-1/2 pt-3 px-3 md:pl-2">
                    <div className="bg-blue-600 border rounded shadow p-2">
                        <div className="flex flex-row items-center">
                            <div className="flex-shrink pl-1 pr-4"><i className="fas fa-users fa-2x fa-fw fa-inverse"></i>
                            </div>
                            <div className="flex-1 text-right">
                                <h5 className="text-white">Étudiants</h5>
                                <h3 className="text-white text-3xl">{users.filter(u=> u.role === 'mtr.kid').length} <span className="text-blue-400"><i
                                    className="fas fa-caret-up"></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/2 xl:w-1/2 pt-3 px-3 md:pr-2 xl:pr-3 xl:pl-1">
                    <div className="bg-orange-600 border rounded shadow p-2">
                        <div className="flex flex-row items-center">
                            <div className="flex-shrink pl-1 pr-4"><i
                                className="fas fa-user-plus fa-2x fa-fw fa-inverse"></i></div>
                            <div className="flex-1 text-right pr-1">
                                <h5 className="text-white">Les mentors</h5>
                                <h3 className="text-white text-3xl">{users.filter(u => u.role === 'mtr.mentor').length} <span className="text-orange-400"><i
                                    className="fas fa-caret-up"></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/2 xl:w-1/2 pt-3 px-3 md:pl-2 xl:pl-3 xl:pr-2">
                    <div className="bg-purple-600 border rounded shadow p-2">
                        <div className="flex flex-row items-center">
                            <div className="flex-shrink pl-1 pr-4"><i className="fas fa-server fa-2x fa-fw fa-inverse"></i>
                            </div>
                            <div className="flex-1 text-right">
                                <h5 className="text-white">Administrateurs</h5>
                                <h3 className="text-white text-3xl">{users.filter(u => u.role === 'mtr.admin').length}</h3>
                            </div>
                        </div>
                    </div>
                </div>

            </Grid2Col>
            <div className="flex flex-wrap mb-2">
                <div className="w-full md:w-1/2 xl:w-1/3 pt-3 px-3 md:pl-2">
                    <div className="bg-blue-600 border rounded shadow p-2">
                        <div className="flex flex-row items-center">
                            <div className="flex-shrink pl-1 pr-4">
                            </div>
                            <div className="flex-1 text-right">
                                <h5 className="text-white">Stages disponible</h5>
                                <h3 className="text-white text-3xl">{mentors.training} <span className="text-blue-400"><i
                                    className="fas fa-caret-up"></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/2 xl:w-1/3 pt-3 px-3 md:pr-2 xl:pr-3 xl:pl-1">
                    <div className="bg-orange-600 border rounded shadow p-2">
                        <div className="flex flex-row items-center">
                            <div className="flex-shrink pl-1 pr-4"></div>
                            <div className="flex-1 text-right pr-1">
                                <h5 className="text-white">Place disponible</h5>
                                <h3 className="text-white text-3xl">{mentors.spots} <span className="text-orange-400"><i
                                    className="fas fa-caret-up"></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap mb-2">
                <div className="w-full md:w-1/2 xl:w-1/3 pt-3 px-3 md:pl-2">
                    <div className="bg-red-700 border rounded shadow p-2">
                        <div className="flex flex-row items-center">
                            <div className="flex-shrink pl-1 pr-4">
                                <i className="fas fa-warning fa-2x fa-fw fa-inverse"></i>
                            </div>
                            <div className="flex-1 text-right">
                                <h5 className="text-white">Aucune concordance</h5>
                                <h3 className="text-white text-3xl">{count.count[0].count} <span className="text-red-400"><i
                                    className="fas fa-caret-up"></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/2 xl:w-1/3 pt-3 px-3 md:pl-2">
                    <div className="bg-blue-600 border rounded shadow p-2">
                        <div className="flex flex-row items-center">
                            <div className="flex-shrink pl-1 pr-4">
                            </div>
                            <div className="flex-1 text-right">
                                <h5 className="text-white">Utilisateurs sélectionnés</h5>
                                <h3 className="text-white text-3xl">{data.count}  <span className="text-blue-400"><i
                                    className="fas fa-caret-up"></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/2 xl:w-1/3 pt-3 px-3 md:pl-2">
                    <div className="bg-blue-600 border rounded shadow p-2">
                        <div className="flex flex-row items-center">
                            <div className="flex-shrink pl-1 pr-4">
                            </div>
                            <div className="flex-1 text-right">
                                <h5 className="text-white">Stages</h5>
                                <h3 className="text-white text-3xl">{data.stages[0].count}  <span className="text-blue-400"><i
                                    className="fas fa-caret-up"></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Grid2Col>
                <div className="w-1/2">
                    <SectionHeader title={'Meilleur moyennes'} category={'Top 10'}/>
                    <GridComponent
                        dataSource={data.tops}
                        enableHover={false}
                        pageSettings={{ pageCount: 5 }}
                        selectionSettings={selectionsettings}
                    >
                        <ColumnsDirective>
                            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                            {topGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
                        </ColumnsDirective>
                        <Inject services={[Selection]} />
                    </GridComponent>
                </div>
                <div className="w-1/2">
                    <SectionHeader title={'Qualifiés'} category={'Top 10'}/>
                    <GridComponent
                        dataSource={data.bestmatch}
                        enableHover={false}
                        pageSettings={{ pageCount: 5 }}
                        selectionSettings={selectionsettings}
                    >
                        <ColumnsDirective>
                            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                            {bestGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
                        </ColumnsDirective>
                        <Inject services={[Selection]} />
                    </GridComponent>

                </div>
            </Grid2Col>
        </div>

    )
}


export default DashboardComponent