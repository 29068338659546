import React from 'react'
import styled from "styled-components"

const StyledNoItems =  styled.div`

    max-width: 800px !important;
    min-width: 350px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  
`

export default function NoItems(){
    return (
    <StyledNoItems style={{marginTop: '-2rem'}}>
        <img src="assets/old/noitems.svg" style={{width: '90%'}}/>
    </StyledNoItems>
    )
}


