import { useContext, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { MyContext } from "../stores";
import { JustCoverBG } from "./main/CoverBG";
import { FormSignUpStyled } from "./SignUp/Registration";
import { Multiselect } from "multiselect-react-dropdown";
import { ErrorMessage, Form, Formik } from "formik";
import { InputText, NiceSelect, SwitchBox, TextAreaInput } from "../components/FormElements";
import { firebaseInstance, schoolsDoc, usersDoc, db, storage } from "../firestore/firestoreService";
import ReactSelect from 'react-select'
import { useCatReducer } from "../stores/categories";
import { userRoles } from "../stores/enums/userRoles";
import * as Yup from "yup"
import { timeout, useDeBounce } from "../helper";
import { omit } from 'lodash';
import { PencilLineIco } from "../ico";
import Avatar from 'react-avatar';
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import { ErrorContainer } from "../components/FormManager/ErrorContainer";
import FieldSetStyled from "../forms/FieldSetStyled";
import { useAlert } from "react-alert";
import {useLocalStorage} from "../helper";


export default function CompanyFreemiumRegistration() {
    const [categories, dispatch] = useCatReducer();
    const [select, setSelect] = useState(null)
    const [companySelect, setCompanySelect] = useState(null)
    const { t } = useTranslation()
    const [cache, setCache] = useLocalStorage("loc", null)
    const [options, setOptions] = useState([])
    const [companyAddressOptions, setCompanyAddressOptions] = useState([])
    const [apicall, setApicall] = useState(true)
    const [registrationError, setRegistrationError] = useState()
    const addressRef = useRef()
    const companyAddressRef = useRef()
    const [save, setSave] = useState(null)
    const [companyAddressSaved, setCompanyAddressSave] = useState(false)
    const { me, verifyAuth, setMe, langChoice, dispatchModal } = useContext(MyContext)
    const [type, setType] = useState('password')
    const regexTelephone = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const regexEmail = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,25})+)$/;
    const history = useHistory();
    const [waitingAddressSuggestion, setWaitingAddressSuggestion] = useState(true)
    const [waitingCompanyAddressSuggestion, setWaitingCompanyAddressSuggestion] = useState(true)
    const [avatarToUplaod, setAvatarToUpload] = useState(null)
    const [coverToUpload, setCoverToUpload] = useState(null)
    const [done, setDone] = useState(false)
    const alert = useAlert()
    const invalidFormatMessage = "Invalid format. Allowed formats: \n(123) 456-7890 \n (123)456-7890 \n 123-456-7890 \n +31636363634 \n 075-63546725 ";


    const [initialValues, setInitialValues] = useState({
        companyName: "",
        companyAddress: "",
        companyIntro: "",
        companyPhone: "",
        displayName: "",
        category: [],
        email: "",
        sector: [],
        name: "",
        tel: "",
        address: "",
        role: userRoles.BIZ_JOBLISTING_ADMIN,
        schoolId: ['0uuQdR7ieoV3xJOSmc7w'],

    });

    useEffect(() => {
        dispatch({ type: langChoice === 'fr' ? 'SET_FR' : 'SET_EN', payload: 'field.' })
        if (apicall) {
            setApicall(!apicall)
        }
    }, [apicall])

    const AvatarEditButton = styled.div`
    position: relative;
    bottom: 30px;
    left: 70px;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3d70b2;
    box-shadow: 0 14px 26px -12px rgb(61 112 178 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(61 112 178 / 20%) !important;
    transform: rotate(0);
    transition: all .3s;
    cursor: pointer;
    z-index: 1;

    svg{
        height: 18px;
        width: 18px;
        stroke: #fcfcfc;
    }
`

    const SignupSchema = Yup.object().shape({
        displayName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required(t('forms.errors.displayNameRequired')),
        companyName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required(t("forms.errors.companyNameRequired")),
        email: Yup.string().required(t('forms.errors.emailRequired')).matches(regexEmail, t('forms.errors.emailFormatInvalid')),
        password: Yup.string().min(5).required(t("forms.errors.passwordRequired")),
        cpassword: Yup.string().min(5).oneOf([Yup.ref('password'), null], t("forms.errors.passwordConfirmationMismatch")).required()
    });


    const createAccount = async (v) => {
        const exists = await usersDoc.where("email", "==", v.email).get()
        if(!exists.empty) {
            alert.info(t("registration.userExists"))
            return
        }

        let docRef = await schoolsDoc.add({name : v.companyName, type : "business" })
        let bData = await docRef.get()
        let doc, sch
        try {
            const createUsers = firebaseInstance().functions().httpsCallable("CreateUsers")
            const userData = {
                email: v.email,
                password: v.password,
                displayName: v.displayName,
                select: {id: docRef.id, ...bData.data()},
                schoolId: [docRef.id],
                claims: { [docRef.id]: userRoles.BIZ_JOBLISTING_ADMIN, 'defaultRole': userRoles.BIZ_JOBLISTING_ADMIN }
            }
            const data = await createUsers(userData)

            userData.uid = data.data.id
            localStorage.setItem("stepOneUserData",JSON.stringify(userData))
            localStorage.setItem("stepOneCompanyData",v.companyName)

            await usersDoc.doc(data.data.id).collection('schools').doc(docRef.id).set({
                [docRef.id]: docRef.id,
                name: v.companyName,
                type: userRoles.BIZ_JOBLISTING_ADMIN
            })

            const sendEmail = firebaseInstance().functions().httpsCallable('SendEmail');
            const langTemplate = langChoice === 'fr' ? 'frFreemiumCompanyStepTwoRegistration' : 'enFreemiumCompanyStepTwoRegistration';
            await sendEmail({cc : "sam@bazookka.com",template : langTemplate, displayName : v.displayName, email : v.email, password : v.password})
            alert.show(t("registration.complete_registration_message"),{type : "success",timeout : 0})
            delete v.companyName
            setCache("completeFreemiumCompanyRegistration")
            setDone(true)

            return null;



        } catch (e) {
            var errorCode = e.code;
            if (errorCode === 'auth/email-already-in-use') {

                setRegistrationError(t('registration.error.user_exists'))
            } else if (errorCode === "auth/invalid-email") {
                setRegistrationError(t('registration.error.invalid_email'))
            }else{
                alert.show(t("registration.error.user_exists"),{type : "error",timeout : 0})
            }
            return e.message
        }

    }


    const onSubmit = async (v, { setSubmitting }) => {
        setSubmitting(true)
        await createAccount(v)
        setSubmitting(false)

    }

    if(done) {
        return (
            <>
                <JustCoverBG />
                <FormSignUpStyled>
                    <FieldSetStyled>
                        {t('registration.complete_registration_message')}
                    </FieldSetStyled>
                </FormSignUpStyled>
            </>
        )
    }


    return (
        <>
            <JustCoverBG />
            <FormSignUpStyled>
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={SignupSchema} enableReinitialize={true}>
                    {({ values, errors, isSubmitting, setFieldValue }) => {
                        return (
                            <Form>

                                <FieldSetStyled>
                                    <legend><pre> {t("forms.companyInformation")} </pre></legend>
                                    <InputText
                                        label={t('general.companyName') + ' *'}
                                        value={values.companyName}
                                        name="companyName"
                                    />
                                    <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.companyName}</div>} name="companyName" />

                                </FieldSetStyled>

                                <FieldSetStyled>
                                    
                                    <legend><pre> {t("forms.personalInformation")} </pre></legend>
                                    <InputText
                                        label={t('listing.name') + ' *'}
                                        value={values.displayName}
                                        name="displayName" />
                                    <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.displayName}</div>} name="displayName" />
                                    <InputText
                                        label={t('general.emailaddress') + ' *'}
                                        value={values.email}
                                        name="email" />
                                    <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.email}</div>} name="email" />
                            
                                </FieldSetStyled>

                                <FieldSetStyled>
                                    <legend><pre> {t("forms.accountInformation")} </pre></legend>
                                    <InputText
                                        label={t('general.pwd') + ' *'}
                                        name="password"
                                        value={values.password}
                                        type={type}
                                    />
                                    <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.password}</div>} name="password" />
                                    <InputText
                                        label={t('general.rpwd') + ' *'}
                                        name="cpassword"
                                        value={values.cpassword}
                                        type={type}
                                    />
                                    <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.cpassword}</div>} name="cpassword" />
                                    <SwitchBox
                                        label={t('form.revealPassword')}
                                        name="display"
                                        onClick={() => values.display ? setType('password') : setType('text')}
                                    ></SwitchBox>

                                </FieldSetStyled>

                                <button style={{ margin: "auto 0" }} className="button" type="submit" disabled={isSubmitting}>{t('general.register')}</button>
                                {isSubmitting && <CircularProgress />}
                                {registrationError && <h1 style={{ color: "red" }}>{registrationError}</h1>}
                            </Form>
                        )
                    }}

                </Formik>
            </FormSignUpStyled>
        </>

    )
}