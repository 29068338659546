import React, {useState, useContext, useEffect} from "react"
import {MyContext} from "../stores";
import {Route, Switch, useParams, Link, useRouteMatch} from "react-router-dom"
import {L10n} from '@syncfusion/ej2-base'
import {Search, GridComponent, ColumnDirective, ColumnsDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, Inject, Toolbar} from "@syncfusion/ej2-react-grids";
import useSWR from 'swr'
import Avatar from "react-avatar";
import {useTranslation} from "react-i18next";
import styled from 'styled-components'
import Loader from "../components/Loader";
import {useAlert} from "react-alert";
import {DialogComponent} from "@syncfusion/ej2-react-popups";

const RatesWrapped = styled.div`
 padding-top: 2rem;
  .top {
    display: block;
    margin-bottom: 2rem;
  }
  .no-match {
    padding: 1rem 2rem;
    color: white;
    background-color: var(--bazookka-darkblue);
    border-radius: .5rem;
  }
  
`

export default function Rates(){
    const {path} = useRouteMatch()
    const {data, error} = useSWR(`https://api.bazookka.com/users/rates/count`, url =>
     fetch(url, {method: 'get', headers: {'Content-Type': 'application/json'}})
         .then( r => r.json())
    )

    useEffect(()=> {}, [data])
    return (
        <RatesWrapped className="h-full" style={{position: "relative"}}>
            <div className="top">
                { data ? <Link className="no-match shadow-2xl" to={'/me/rates/no-match'}>Aucune concordance {data.count[0].count} / {data.all[0].count}</Link> : <Link className="no-match shadow-2xl" to={'/me/rates/no-match'}>Loading ...</Link>}
            </div>
            <Switch>
                <Route path={`/me/rates`} component={RateView} exact>
                </Route>
                <Route
                 path={'/me/rates/no-match'}
                 component={LeftOverView}
                ></Route>
            </Switch>
        </RatesWrapped>
    )
}


function LeftOverView() {
    const {t} = useTranslation()
    const {path} = useRouteMatch()
    const {me, globalId,langChoice, setI10nLoadContent} = useContext(MyContext)

    useEffect(() => {
        setI10nLoadContent(
            {
                'fr-FR': {
                    'grid': {
                        'EmptyDataSourceError': 'Erreur de chargement des données',
                        'EmptyRecord': `Aucun utilisateur á charger`,
                        'Item': 'étudiant',
                        'Items': 'étudiants',
                        'name': 'nom',
                        'Search': "Chercher"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} de {1}',
                        'totalItemsInfo': '({0} étudiants)',
                        'totalItemInfo': '({0} étudiant)'
                    }
                },
                'en-US': {
                    'grid': {
                        'EmptyDataSourceError': 'Error loading data',
                        'EmptyRecord': `There's no students to load`,
                        'Item': 'student',
                        'Items': 'students',
                        'name': 'nom',
                        'Search': "Search"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} of {1}',
                        'totalItemsInfo': '({0} students)',
                        'totalItemInfo': '({0} student)'
                    }
                }
            }
        )

    },[])
    return (
        <div>

        </div>
    )
}

function RateView(){
    const {t} = useTranslation()
    const {path} = useRouteMatch()
    const [hideDialog, setHideDialog] = useState(false)
    const [currentUserId, setCurrentUserId] = useState()
    const {me, globalId,langChoice, setI10nLoadContent} = useContext(MyContext)

    const handleClick = (id) => {
        setCurrentUserId(id)
        setHideDialog(true)
    }

    const onOverlayClick = () => {
        setHideDialog(false)
    };

    const dialogClose = () => {
        setHideDialog(false)
    };

    function headerTemplate() {
        const userD = data.find(elem => elem.id === currentUserId)
        return (userD ? <div className="flex flex-row justify-items-center align-item">
            <Avatar className="mr-2" name={userD.display_name} size={40} round={true} />
            <div clas title={userD.display_name} className="e-icon-settings dlg-template mb-3">{userD.display_name}</div>
        </div> : "");
    }

    useEffect(() => {
        setI10nLoadContent(
            {
                'fr-FR': {
                    'grid': {
                        'EmptyDataSourceError': 'Erreur de chargement des données',
                        'EmptyRecord': `Aucun utilisateur á charger`,
                        'Item': 'étudiant',
                        'Items': 'étudiants',
                        'name': 'nom',
                        'Search': "Chercher"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} de {1}',
                        'totalItemsInfo': '({0} étudiants)',
                        'totalItemInfo': '({0} étudiant)'
                    }
                },
                'en-US': {
                    'grid': {
                        'EmptyDataSourceError': 'Error loading data',
                        'EmptyRecord': `There's no students to load`,
                        'Item': 'student',
                        'Items': 'students',
                        'name': 'nom',
                        'Search': "Search"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} of {1}',
                        'totalItemsInfo': '({0} students)',
                        'totalItemInfo': '({0} student)'
                    }
                }
            }
        )

    },[])

    let {data, error} = useSWR(`https://api.bazookka.com/users/rates`,
        url => fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'eid': globalId
            },
            method: 'get',
            }).then(r => r.json()))

    if(!data) {
        return <Loader />
    }

    const rateGrid = [
        {
            headerText: "ratingView.titleColumn",
            width: '80',
            template: function Display(props){
                return (
                    <Avatar size={40} round={true} name={props.display_name} />
                )
            }
        },
        {
            headerText: "MRID",
            field: 'meta.MRID',
            width: '100',

        },
        {
            headerText: "ratingView.nameColumn",
            field: 'display_name',
            width: '200',

        },
        {
            headerText: "ratingView.averageColumn",
            field: 'metric.avg',
            width: '80',
            textAlign: 'center',
            template: function Display(props) {
                return <div>{parseFloat(props.metric.avg ?? 0).toFixed(2)}</div>
            }
        },
        {
            headerText: 'ratingView.maximumColumn',
            field: 'metric.max',
            width: '80',
            textAlign: 'center',
            template: function Display(props) {
                return <div>{parseFloat(props.metric.max ?? 0).toFixed(2)}</div>
            }
        },
        {
            headerText: "ratingView.minimumColumn",
            field: 'metric.min',
            width: '80',
            template: function Display(props) {
                return <div>{parseFloat(props.metric.min ?? 0).toFixed(2)}</div>
            }
        },
        {
            headerText: "ratingView.totalColumn",
            field: 'metric.count',
            width: '80',
            textAlign: 'center',
            template: function Display(props) {
                return <div>{parseInt(props.metric.count)}</div>
            }
        },
        {
            headerText: 'Details',
            field: '',
            width: '200',
            template: function Buttons(props) {
                const { path } = useRouteMatch()
                const id = props.id
                return <button onClick={() => handleClick(id)}  ><i className="mdi mdi-eye-check" style={{ fontSize: '1rem' }}></i> </button>
            }

        },
    ]

    const separatorWithTitle = (title) => {
        return (<div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-4 border-gray-400"></div>
            <span class="flex-shrink mx-4 text-gray-400">{title}</span>
            <div class="flex-grow border-t border-4 border-gray-400"></div>
        </div>)
    }

    function bodyTemplate() {
        const user = data.find(elem => elem.id === currentUserId)
        if (user) {
            return (
                <div className="m-auto" style={{ fontWeight: "normal" }}>
                    {separatorWithTitle(t("mentorView.userView.details.contactInformations"))}
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.zohoId")} : </span> {user.meta.zohoId}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.address")} : </span> {user.meta.address}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.kidEmail")} : </span> {user.meta.email}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.kidPhone")} : </span> {user.meta.phone}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.schoolLevel")} : </span> {user.meta.schoolLevel}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.parentPhone")} : </span> {user.meta.parent.phone}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.parentEmail")} </span> {user.meta.parent.email}</div>
                    {separatorWithTitle("Trait")}
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.trait1")} : </span> {user.meta.traits.trait1}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.trait2")} : </span> {user.meta.traits.trait2}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.trait3")} </span> {user.meta.traits.trait3}</div>

                    {separatorWithTitle(t("mentorView.userView.details.interests"))}
                    {
                        <div className="grid grid-cols-1 divide-y-2">
                            {user.meta.interests.map((i, index) => {
                                let label1 = `mentorView.userView.details.sector${index + 1}`
                                let label2 = `mentorView.userView.details.sectorPrecision${index + 1}`
                                return <div key={index} className="mb-3" >
                                    <div>
                                        <div className="mb-3" style={{ fontWeight: "bold" }}>{t(label1)}: <span style={{ fontWeight: "normal" }} >{i.field}</span></div>
                                        <div style={{ fontWeight: "bold" }}>{t(label2)}:  <span style={{ fontWeight: "normal" }}>{i.reason}</span></div>
                                    </div>
                                </div>
                            })}
                        </div>
                    }




                </div>
            )
        }
        return ""
    }

    return (
        <div className="mt-24 w-full">
            <DialogComponent allowDragging showCloseIcon animationSettings={{ effect: 'Zoom', duration: 400, delay: 0 }} cssClass="e-dlg-content" width='500px' isModal={true} header={headerTemplate} visible={hideDialog} close={dialogClose} overlayClick={onOverlayClick}>
                {bodyTemplate()}

            </DialogComponent>
            <GridComponent
                locale={langChoice === "fr" ? "fr-FR" : "en-US"}
                width={`auto`}
                id="gridcomp"
                dataSource={data}
                allowPaging
                allowSorting
                pageSettings={{pageSize: 25}}
                contextMenuItems={['AutoFit']}
                toolbar={["Search"]}
                allowResizing={true}
                allowFiltering={true}
            >
                <ColumnsDirective
                >
                    {rateGrid.map((item, index) => {
                        return <ColumnDirective
                            key={index}
                            {...item}
                            headerText={t(item.headerText)}
                            maxWidth={800}
                        />
                    })}
                </ColumnsDirective>
                <Inject services={[Resize, Sort, Page, ContextMenu, Filter, Search, Toolbar]} />
            </GridComponent>
        </div>


    )
}