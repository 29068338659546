import React, {useContext} from 'react'
import {MyContext} from "../stores";
import {ScheduleComponent, ResourcesDirective, ResourceDirective, ViewsDirective, ViewDirective, DragAndDrop, Day, Week, Month, Inject, Agenda, Resize} from '@syncfusion/ej2-react-schedule'
import {DatePickerComponent} from '@syncfusion/ej2-react-calendars'
import useSWR from 'swr'
import Loader from "../components/Loader"
import { L10n, loadCldr } from '@syncfusion/ej2-base';

loadCldr(
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/main/fr/ca-gregorian.json'),
    require('cldr-data/main/fr/numbers.json'),
    require('cldr-data/main/fr/timeZoneNames.json'),
    require('cldr-data/main/en/ca-gregorian.json'),
    require('cldr-data/main/en/numbers.json'),
    require('cldr-data/main/en/timeZoneNames.json')
    );
  

const CalendarPage  = () => {
    const {me, globalId, langChoice} = useContext(MyContext)
    langChoice === 'fr' ?  L10n.load(require('@syncfusion/ej2-locale/src/fr.json')) : L10n.load(require('@syncfusion/ej2-locale/src/en-US.json'))
    const {data, error} = useSWR(`https://api.bazookka.com/calendars`,
            url => fetch(url, {method: 'get', headers: {
                'Content-Type': 'appliication/json',
                eid: globalId
                }}).then(r =>{
                    return r.json()
            }))

    if(!data) return <Loader />

    return (
        <div className="bg-white p-2 rounded shadow">
            <ScheduleComponent
              locale={langChoice === "fr" ? "fr" : "en-US"}
             actionComplete={ data => {
                 console.log(data)
             }}
             height="650px"
             popupOpen={props => {
                 console.log(props)
                 let div = document.createElement("div")
                 let e_dialog = props.element.querySelector(".e-dialog-parent")
                 let e_info = props.element.querySelector('.e-description-row')
                 div.innerHTML = "<h1>was code</h1>";
                 e_dialog.insertBefore(div, e_info)

             }}
             eventSettings={{
                 dataSource: data.data.filter( d => (d.meta.date !== undefined || d.meta.startDate !== undefined) && d.meta.endDate !== undefined).map( (event, i) => {
                     event.Id = i+1
                     event.Subject = event.title
                     event.StartTime = new Date(event.meta.date || (event.meta.startDate instanceof Object && event.meta.startDate._seconds * 1000) || event.meta.startDate)
                     event.EndTime = new Date(event.meta.endDate)
                    return event;
                 })
             }}
            >

                <Inject services={[Day, Week, Month, Agenda, Resize, DragAndDrop]} />
            </ScheduleComponent>

        </div>
    )
}



export default CalendarPage