function template(strings, ...keys) {
    return function(...values) {
        let dict = values[values.length - 1] || {};
        let result = [strings[0]];
        keys.forEach(function(key, i) {
            let value = Number.isInteger(key) ? values[key] : dict[key];
            result.push(value, strings[i + 1]);
        });
        return result.join('');
    };
}
function frConfUser({...info}){
    let title = `Confirme l'inscription à ton compte Bazookka`;
    let body = template`<p style="text-align: justify;">Bonjour ${'displayName'}</p>
<p style="text-align: justify;">Clique sur le lien suivant afin de finaliser l'inscription de ton compte.</p>
<p style="text-align: justify;">https://app.bazookka.com/signup/${'invitationToken'}</p>
<p style="text-align: justify;">Nom d'utilisateur : ${'email'}</p>
<p style="text-align: justify;">Une fois terminé, tu pourras commencer à connecter avec les employeurs les plus fun ! </p>
<p style="text-align: justify;">Si tu n'as pas fait cette demande, tu n'as qu'à ignorer ce courriel de confirmation.</p>`;
    let parse = body(info)
    return {
        subject: title,
        text: parse,
        html: parse,
        to: info.email
    }
}
function enConfUser({...info}){
    let title = 'Registration confirmation to Bazookka';
    let body = template`<p style="text-align: justify;">Hello ${'displayName'}</p>
<p style="text-align: justify;">Click on the following link to confirm the opening of your account on  Bazookka.</p>
<p style="text-align: justify;">https://app.bazookka.com/signup/${'invitationToken'}</p>
<p style="text-align: justify;">User name: ${'email'}</p>
<p style="text-align: justify;">Once you're done, you can start connecting with the most fun employers!</p>
<p style="text-align: justify;">If you haven't made this request, simply ignore this confirmation email.</p>
<p style="text-align: justify;">Bazookka is the online app that helps to boost careers!</p>`;
    let parse = body(info)
    return {
        title,
        body,
        parse
    }
}
function frConfBusiness({...info}){
    let title = `Confirmez l'inscription de votre compte Bazookka`;
    let body = template`<p style="text-align: justify;">Bonjour ${'displayName'}</p>
<p style="text-align: justify;">Veuillez cliquer sur le lien suivant afin de confirmer l'ouverture de votre compte.</p>
<p style="text-align: justify;">https://app.bazookka.com/signup/${'invitationToken'}</p>
<p style="text-align: justify;">Nom d'utilisateur : ${'email'}</p>
<p style="text-align: justify;">Un membre de l'équipe communiquera avec vous au numéro de téléphone fourni pour finaliser votre inscription.</p>
<p style="text-align: justify;">Si vous n'avez pas fait cette demande, veuillez ignorer ce courriel de confirmation.</p>
<p style="text-align: justify;">L'équipe Bazookka, la communauté qui aide à propulser les carrières !</p>`;
    let parse = body(info)
    return {
        title,
        body,
        parse
    }
}
function enConfBusiness({...info}){
    let title = `Registration confirmation to Bazookka`;
    let body = template`<p style="text-align: justify;">Hello ${'displayName'}</p>
<p style="text-align: justify;">Click on the following link to confirm the opening of your account on  Bazookka.</p>
<p style="text-align: justify;">https://app.bazookka.com/signup/${'invitationToken'}</p>
<p style="text-align: justify;">User name: ${'email'}</p>
<p style="text-align: justify;">A team member will contact you at the phone number provided to finalize your registration.</p>
<p style="text-align: justify;">If you have not made this request, simply ignore this confirmation email.</p>
 <p style="text-align: justify;">Bazookka is the online community that helps propel careers!</p>`;
    let parse= body(info)
    return {
        title,
        body,
        parse
    }
}
function enPwdReset(info){
    let title = "Resetting your password"
    let body = template`
    <p style="text-align: justify;">Hello ${'displayName'}</p>
    <p style="text-align: justify;">As requested, please click on the following link to reset your password:</p>
    <p style="text-align: justify;"><a href="https://app.bazookka.com/resetpassword/${'magiclink'}">https://app.bazookka.com/resetpassword/${'magiclink'}</a></p>
    <p style="text-align: justify;">If you have not made this request, please ignore it.</p>
    <p style="text-align: justify;">If you have any further questions, please do not hesitate to contact us.</p>
    `;
    let parse = body(info)
    return {
        title,
        body,
        parse
    }
}
function frPwdReset(info){
    let title = "Réinitialisation de votre mot de passe"
    let body = template`
    <p style="text-align: justify;">Bonjour ${'firstname'} ${'lastname'}</p>
    <p style="text-align: justify;">Tel que demandé, veuillez cliquer sur le lien suivant afin de réinitialiser votre mot de passe :</p>
    <p style="text-align: justify;"><a href="https://app.bazookka.com/resetpassword/${'magiclink'}">https://app.bazookka.com/resetpassword/${'magiclink'}</a></p>
    <p style="text-align: justify;">Si vous n'avez pas fait cette demande, veuillez l'ignorer.</p>
    <p style="text-align: justify;">Si vous avez d'autres questions, n'hésitez pas à communiquer avec nous.</p>
    <p style="text-align: justify;">Pour en savoir plus, visitez-nous sur Bazookka.com et sur les réseaux sociaux.</p>
    `;
    let parse = body(info)
    return {
        title,
        body,
        parse
    }
}
function frInvite(info){
    let title = "Invitation"
    let body = template`
    <p style="text-align: justify;">Vous avez été invité par ${'name'} à faire partie de leur équipe</p>
    <p style="text-align: justify;">Pour en savoir plus, visitez-nous sur Bazookka.com et sur les réseaux sociaux.</p>
    `;
    let parse = body(info)
    return {
        title,
        body,
        parse
    }
}
function enInvite(info){
    let title = "Invitation"
    let body = template`
    <p style="text-align: justify;">You've been invited by ${'name'} to be part of their team</p>
    <p style="text-align: justify;">If you have any further questions, please do not hesitate to contact us.</p>
    `;
    let parse = body(info)
    return {
        title,
        body,
        parse
    }
}
function frInvNewinfo(info){
    let title = `Invitation à Bazookka`;
    let body = template`<p style="text-align: justify;">Bonjour ${'displayName'}</p>
<p style="text-align: justify;">Cliquez sur le lien suivant pour accéder à votre compte.</p>
<p style="text-align: justify;">https://app.bazookka.com/signup/${'invitationToken'}</p>
<p style="text-align: justify;">Nom d'utilisateur : ${'email'}</p>
<p style="text-align: justify;">L'équipe Bazookka, la communauté qui aide à propulser les carrières !</p>`;
    let parse = body(info)
    return {
        title,
        body,
        parse
    }
}
function enInvNewinfo(info){
    let title = `Invitation to Bazookka`;
    let body = template`<p style="text-align: justify;">Hello ${'displayName'}</p>
<p style="text-align: justify;">Click on the following link to access your account.</p>
<p style="text-align: justify;">https://app.bazookka.com/login</p>
<p style="text-align: justify;">User name: ${'email'}</p>
 <p style="text-align: justify;">Bazookka is the online community that helps propel careers!</p>`;
    let parse= body(info)
    return {
        title,
        body,
        parse
    }
}
export const enEmail = {
    enConfUser,
    enConfBusiness,
    enPwdReset,
    enInvite,
    enInvNewinfo
}
export const frEmail = {
    frConfUser,
    frConfBusiness,
    frPwdReset,
    frInvite,
    frInvNewinfo
}
