import React, {useState} from "react"
import tw from "tailwind-styled-components"
import {useHistory, Link, useRouteMatch} from "react-router-dom";
import useSWR from "swr"
import Avatar from "react-avatar";

const fetcher = (url) => fetch(url).then((res) => res.json());

const ButtonAdd = tw.div`
    flex py-2 px-4 bg-blue-700 text-neutral-50 shadow rounded-lg cursor-pointer justify-center
`

const InputTw = tw.input`
py-2 px-3 placeholder-gray-600 text-black rounded
ring-1 ring-gray-300 focus:ring-2 focus:ring-gray-600
`


/**
 * @param {[]Object} data from api
 * @param title
 * @param fetcher
 * @param mutate
 * @param action
 * @returns {JSX.Element}
 * @constructor
 */
export default function TableComponent({columns, data, title, fetcher, mutate, action, actionButons = [], searchLink}){
    const [account, setAccount] = useState(data)
    const {path, url} = useRouteMatch()

    const onSearch = (e) => {
        fetch(`${searchLink}${e.target.value}`).then( d => d.json())
            .then( d => setAccount( [...d]))
    }

    function selectButtons(){
        actionButons.forEach( a => {
            console.log(a)
            console.log(Object.keys(a)[0])
            Object.keys(a).forEach(k => {
                if(k === 'edit') {
                    console.log('in retr')
                    return (
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <ButtonAdd>Edit</ButtonAdd>
                        </td>
                    )
                }
            })
        })
    }

    return (
        <div className="container mx-auto px-4 sm:px-8 shadow-2xl">
            <div className="py-8">
                <div>
                    <h2 className="text-2xl font-semibold leading-tight">{title}</h2>
                </div>
                <div className="flex justify-end gap-4">
                    {searchLink && (
                        <InputTw
                            type="text"
                            placeholder="Search by name"
                            autoComplete="off"
                            onChange={onSearch}
                        />
                    )}

                    <ButtonAdd>
                        + add
                    </ButtonAdd>
                </div>
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div
                        className="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
                    >
                        <table className="min-w-full leading-normal">
                            <thead>
                            <tr>
                                {columns.map((column, i)=> {
                                    return (
                                        <th key={i}
                                            className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                                        >
                                            {column}
                                        </th>
                                    )
                                })}

                            </tr>
                            </thead>
                            <tbody>
                            {account.map((elem, i)=> {
                                return (
                                    <tr key={i}>
                                        {/*<td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">*/}
                                        {/*    <div className="flex">*/}
                                        {/*        <div className="flex-shrink-0 w-10 h-10">*/}
                                        {/*            <img*/}
                                        {/*                className="w-full h-full rounded-full"*/}
                                        {/*                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"*/}
                                        {/*                alt=""*/}
                                        {/*            />*/}
                                        {/*        </div>*/}
                                        {/*        <div className="ml-3">*/}
                                        {/*            <p className="text-gray-900 whitespace-no-wrap">*/}
                                        {/*                Molly Sanders*/}
                                        {/*            </p>*/}
                                        {/*            <p className="text-gray-600 whitespace-no-wrap">000004</p>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</td>*/}
                                        {columns.map((col, index)=> {
                                            return (
                                                <td key={index} className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <p className="text-gray-900 whitespace-no-wrap">{elem[col]}</p>
                                                </td>
                                            )
                                        })}


                                        {/*<td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">*/}
                                        {/*    <span*/}
                                        {/*        className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight"*/}
                                        {/*    >*/}
                                        {/*      <span*/}
                                        {/*          aria-hidden*/}
                                        {/*          className="absolute inset-0 bg-green-200 opacity-50 rounded-full"*/}
                                        {/*      ></span>*/}
                                        {/*      <span className="relative">Paid</span>*/}
                                        {/*    </span>*/}
                                        {/*</td>*/}
                                        {action && (
                                            (
                                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <Link to={`${path}/edit/${elem.id}`}
                                                    >edit</Link>
                                                </td>
                                            )
                                        )}


                                            {/*// <td*/}
                                            {/*//     className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-right"*/}
                                            {/*// >*/}
                                            {/*//     <button*/}
                                            {/*//         type="button"*/}
                                            {/*//         className="inline-block text-gray-500 hover:text-gray-700"*/}
                                            {/*//     >*/}
                                            {/*//         <svg*/}
                                            {/*//             className="inline-block h-6 w-6 fill-current"*/}
                                            {/*//             viewBox="0 0 24 24"*/}
                                            {/*//         >*/}
                                            {/*//             <path*/}
                                            {/*//                 d="M12 6a2 2 0 110-4 2 2 0 010 4zm0 8a2 2 0 110-4 2 2 0 010 4zm-2 6a2 2 0 104 0 2 2 0 00-4 0z"*/}
                                            {/*//             />*/}
                                            {/*//         </svg>*/}
                                            {/*//     </button>*/}
                                            {/*// </td>*/}


                                    </tr>
                                )
                            })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
