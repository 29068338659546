import CustomFooterLogTable from "./CustomFooterLogTable"
import React from "react"
import styled from "styled-components"

const BoxLogs = styled.div`
  width: 80%;
  margin: auto;
  .tile {
    display: flex;
    .innertile {
      padding: 8px;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-bottom: 4px;
      border: 1px solid var(--bazookka-default-border-color);
      border-radius: 8px;
      width: 100%;
      justify-content: space-between;
    }
  }
`

const CrudBox = ({data}) => {
    return (
    <BoxLogs>
        {data.map( (doc, key)=> {
            return (
                <div key={key} className="tile">
                    <div className="innertile">
                        <div>{doc.displayName}</div>
                        <div>{doc.offer}</div>
                        <div>{doc.created}</div>
                    </div>

                </div>
            )
        })}
    </BoxLogs>
    )
}
const Datatable =  ({title, data, columns, rowsPerPage,translator}) => {
     let tableOptions = {
        filter: true,
        tableBodyHeight: '500px',
        responsive: 'simple',
        rowsPerPage: rowsPerPage,
        selectableRows: 'none' ,
        textLabels: {
            body: {
              noMatch: <div>{translator("log.groupsLogDataTable.emptyTable")}</div>
            }
          },
        customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
          return (
            <CustomFooterLogTable
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              changeRowsPerPage={changeRowsPerPage}
              changePage={changePage}
              textLabels={textLabels}
            />
          );
        }
      }
      
    return <CrudBox
          title={title}
          data={data}
          columns={columns}
          options={tableOptions}
        />
} 

export default Datatable