import React, {useState, useEffect} from 'react'
import {SearchBar} from "../components/SearchBar";
import {MyContext} from "../stores";
import { users,jobPosts } from "../firestore/firestoreService";
import moment from 'moment'
import {JobPostCard} from '../components/JobPostCard'


const IntershipCard = ({data}) => {
    return (
        <div className="column is-3">
            <div className="card">

            <div className="card-content">
                <div className="media">
                    <div className="media-left">
                        <figure className="image is-48x48">
                            <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image" />
                        </figure>
                    </div>
                    <div className="media-content">
                        <p className="title is-4">{data.student.firstname} {data.student.lastname}</p>

                    </div>
                </div>

                <div className="content">
                    <p>
                        <i class="mdi mdi-home-modern" style={{fontSize: 22}}></i><span>{data.company.name}</span>
                        <br/>
                        <i class="mdi mdi-home-modern" style={{fontSize: 22}}></i><span>{data.cat}</span>
                        <br/>
                        <i className="mdi mdi-message-text-outline" style={{fontSize: 22}}></i><span>{data.messages.length}</span>
                    </p>
                    <time dateTime="2016-1-1">{moment(data.startDate).format('L')} - {moment(data.endDate).format('L')}</time>
                </div>
            </div>
            <footer className="card-footer">

            </footer>
        </div>
        </div>
    )
}


const Internships = props => {
    const [internshipPost, setInterns] = useState([])
    useEffect(()=> {
        jobPosts.get().then( data => {
            setInterns(data.docs.map(d=> Object.assign({},{id: d.id, ...d.data()})))
        })
    }, [])
    return (
        <div className="view-wrapper">
            <div className="container is-custom">
                <div id="profile-friends" className="view-wrap is-headless">
                    <div className="columns">
                        <div className="column">
                            <SearchBar person={false}></SearchBar>
                            <div className="news-grid" style={{paddingTop: 20}}>
                                <div className="columns is-multiline">
                                    {internshipPost.map( c => {
                                        return <JobPostCard data={c} key={c.id} type={`student`}/>
                                    })}
                                </div>
                                <div className=" load-more-wrap has-text-centered">
                                    {internshipPost.length > 10 && <a >Load More</a>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Internships;
