import React, {useContext, useEffect, useState} from 'react'
import {Formik, Form, Field} from "formik";
import {InputDate, InputText, SwitchBox} from "../components/FormElements";
import {format, setHours, setMinutes, getMinutes, getHours, getDate, setDate} from "date-fns";
import {fr} from "date-fns/locale";
import RichTextEditor, {RichTextEditorFix} from "../components/RichTextEditor";
import {initialValue} from "../components/DisplayContentFromSlate";
import {MyContext} from "../stores";
import {documents} from "../firestore/firestoreService";
import {useTranslation} from "react-i18next";
import {Multiselect} from 'multiselect-react-dropdown';
import styled from 'styled-components';
import {CustomSelect} from "../components/FormElements";
import Loader from "../components/Loader";
import {ToggleSharingOptions} from "../components/FormManager/ToggleElements";
import {PencilLineIco} from "../ico";
import FileUpload from '../components/FileUpload';
import { storage } from "../firestore/firestoreService";

const FormWrapper = styled.div`
        .richTextContainer{
            border:1px solid #e8e8e8;
            margin:10px 0;
        }
        .is-fullwidth {
          color: white;
          font-weight: bold;
          background-color: var(--bazookka-orange);
          :hover {
            filter: drop-shadow(1px 1px 1px rgba(0,0,0, 0.25));
            font-size: 15px;
            transition: all 300ms;
          }
        } 
        .react-datepicker__input-container input {
          padding: 8px;
          border: 1px solid var(--bazookka-orange);
          border-radius: 5px;
          background-color: var(--bazookka-orange);
          color: white;
          font-weight: bold;
          filter: drop-shadow(0px 1px 1px rgba(0,0,0, 0.4));
        }
        .toolbar{
            height:30px;
            margin:0;
            padding:1px 0px 17px 5px;
        }

        .richInput{
            padding:10px;
        }

        .toolButton{
            height:24px;
        }
    `

const UploadInputWrapper = styled.div`
        margin:15px 0;
`

export default function EditDocumentForm({document, groups, options, creating, onCreate}){
    const {me, langChoice, globalId, dispatchModal} = useContext(MyContext)
    const [toggle, setToggle] = useState("user");
    const [uploadedDocument, setUploadedDocument] = useState([]);
    const [fileDestination, setFileDestination] = useState("");
    const [fileUploadStatus, setFileUploadStatus] = useState('idle');
    const [createdDoc, setCreatedDoc] = useState('');
    const {t} = useTranslation();
    const [initialState, setData] = useState({
        name: '',
        confidential: false,
        users: [],
        groups: [],
    });

    useEffect(()=>{
        if(globalId){
            setFileDestination(`documents/${globalId}/`)
        }
        if(document){
            const tempUsers = document.allowedUsers.filter((user) => user !== globalId);
            setData({
                ...document,
                groups: document.allowedGroups,
                users: tempUsers,
            })
        }else{
            if(me && globalId){
                setData({
                    ...initialState
                });
            }
        }
    }, [ me, globalId, document])

    //This function is to handle when group is selected and confidential turned on
    const handleConfidential = (e) => {
        //For some reason this is inverted false means that confidential is true
        if(e.target.value === 'false'){
            setToggle('user');
        }
        
    }

    const updateFileMetadata = (fileRef, confidential, allowedUsers) => {
        var newMetadata = {
            customMetadata: {
                'confidential': confidential,
                'allowedUsers': allowedUsers.toString(),
            }
        }
        fileRef.updateMetadata(newMetadata).then(() => {
            onCreate();
        })
        .catch((error) => {
          console.log("There was an error updating File metaData", error)
        });
    }

    const handleDocumentUpload = (fileRef, metadata) => {
        fileRef.getDownloadURL().then((url) => {
            documents.doc(createdDoc.id).update({
                url: url,
                fileName: metadata.name
                }).then(() => {
                    setFileUploadStatus('idle');
                    updateFileMetadata(fileRef, createdDoc.confidential, createdDoc.allowedUsers);
                    onCreate();
                }).catch((error) => {
                console.error("Error writing document: ", error);
            });
        });
    }

    const onSubmit = (data, {setSubmitting}) => {
        if(!document && creating) {
            setSubmitting(true)
            const saveDoc = {
                name: data.name,
                authorId: globalId,
                confidential: data.confidential,
                uploadDate: Date.now(),
                allowedGroups: data.confidential === false ? data.groups : [],
                allowedUsers: [globalId, ...data.users],
            }
            documents.add(saveDoc).then(docref => {
                setCreatedDoc({id: docref.id, confidential: saveDoc.confidential, allowedUsers: saveDoc.allowedUsers});
                setFileUploadStatus('uploading');
                setSubmitting(false);
            })
        } else {
            setSubmitting(true)
            const saveDoc = {
                name: data.name,
                confidential: data.confidential,
                allowedGroups:  data.confidential === false ? data.groups : [],
                allowedUsers: [globalId, ...data.users],
            }
            documents.doc(data.id).update(saveDoc).then(response => {
                const storageRef = storage.ref();
                const fileRef = storageRef.child(fileDestination+document.fileName);
                updateFileMetadata(fileRef, saveDoc.confidential, saveDoc.allowedUsers);
                setSubmitting(false);
            })
        }
    }

    if(fileUploadStatus === 'uploading'){
        <Loader/>
    }

    return (
        <Formik initialValues={initialState} onSubmit={onSubmit} enableReinitialize={true}>
            {({values, isSubmitting, resetForm}) => {
                return (
                    <Form>
                        <FormWrapper className="settings-form-wrapper">
                            {creating &&
                                <UploadInputWrapper>
                                    <FileUpload dest={fileDestination}  onUpload={handleDocumentUpload} queueStatus={fileUploadStatus} queue={uploadedDocument} setQueue={setUploadedDocument}>
                                        <div className="button is-primary is-fullwidth"><i className="mdi mdi-plus"></i></div>
                                    </FileUpload>
                                </UploadInputWrapper>
                            }
                            {uploadedDocument.length > 0 &&
                                <div>{uploadedDocument[0].name}</div>
                            }
                            <div className="settings-form">
                                <div className="field">
                                    <InputText
                                        label={ t('listing.name')}
                                        name="name" />
                                </div>
                                <div className="field">
                                    <SwitchBox
                                        label={langChoice === 'fr' ? 'Confidentiel': 'Confidential'}
                                        name="confidential"
                                        onClick={handleConfidential}
                                    />
                                </div>
                                <div className="field">
                                    { values.confidential === false ? 
                                        (
                                        <ToggleSharingOptions
                                            toggle={toggle}
                                            setToggle={setToggle}
                                            translator={t}
                                        ></ToggleSharingOptions>
                                        ) : (
                                            <div>{t('general.users')}</div>
                                        )
                                    }
                            </div>
                            <div className="field">
                                {toggle === "user" &&
                                    <Field
                                        name="users"
                                        options={options}
                                        component={CustomSelect}
                                        placeholder={t('general.documents.usersLabel')}
                                        isMulti={true}
                                    />
                                }
                                {toggle === "group" && values.confidential === false &&
                                    <Field
                                        name="groups"
                                        options={groups}
                                        component={CustomSelect}
                                        placeholder={t('general.documents.groupsLabel')}
                                        isMulti={true}
                                    />
                                }
                                {/* TODO FIGURE OUT HOW TO GET THE VALUE OF THIS SELECT*/}
                            </div>
                            <button className="button is-fullwidth" type="submit">{t('general.save')}</button>
                            </div>
                        </FormWrapper>
                    </Form>
                )
            }}
        </Formik>
    )
}
