import {useEffect, useRef, useCallback, useState, useMemo} from "react";
import {useFormik} from "formik";
import {useLocation} from "react-router-dom";

export const useTimeout = (callback, delay) => {
    const callbackRef = useRef(callback)
    const timeoutRef = useRef()

    useEffect(()=> {
        callbackRef.current = callback
    }, [callback])

    const set = useCallback(()=>{
        timeoutRef.current = setTimeout(()=>{
            callbackRef.current()
        }, delay)

    }, [delay])

    const clear = useCallback(()=> {
        timeoutRef.current && clearTimeout(timeoutRef.current)
    }, [])

    useEffect(()=> {
        set()
        return clear
    }, [delay, set, clear])

    const reset = useCallback(()=> {
        clear()
        set()
    }, [set, clear])

    return {reset, clear}

}


export const useDeBounce = (callback, delay, depend) => {
    const {reset, clear } = useTimeout(callback, delay)
    useEffect(reset, [...depend, reset])
    useEffect(clear, [])
}

export function usePromise(callback, dependencies = []){
    const [loading, setLoading] =useState(true)
    const [error, setError] =useState()
    const [value, setValue] =useState()

    const callbackMemory = useCallback(()=> {
        setLoading(true)
        setError(undefined)
        setValue(undefined)
        callback()
            .then(v => {
                setValue(v)
            })
            .catch((e) => {
                setError(true)
            })
            .finally(()=> setLoading(false))
    }, [...dependencies])

    useEffect(()=> {
        callbackMemory()
    }, [callbackMemory])

    return [value, error, loading]
}

export function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name, avatar) {
    return {
        src: avatar,
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export function timeout(t){
    return new Promise( resolve => {
        setTimeout(()=> {
            resolve(true)
        }, t)
    })
}


export function useLocalStorage(key, initialValue) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === "undefined") {
            return initialValue;
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            if (typeof window !== "undefined") {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };
    return [storedValue, setValue];
}

export function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}
