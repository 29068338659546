import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { MyContext } from "../stores";
import Avatar from 'react-avatar'
import { schoolsDoc, firebaseInstance, jobPosts, firestore, postsDoc } from "../firestore/firestoreService";
import { SearchBar } from "../components/SearchBar";
import styled from 'styled-components';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Switch, FormGroup, FormControlLabel } from "@mui/material";
import { useCatReducer } from "../stores/categories";
import NoItems from "../components/NoItems";
import { ShareBig } from "../components/Share";

import useSWR from 'swr';
import "./accordion.css"
import { Formik, Field, Form } from 'formik';


export const FilterOption = styled.div`
  display: flex;
  max-width: 800px;
  flex-direction: column;
  margin: auto;
  .basic-single {
    width: 100%;
  }
`

export const BannerStyle = styled.div`
  max-width: 1080px;
  height: 360px;
  background: url(assets/old/job.png);
  background-position: top;
  margin: auto;
  border-radius: 15px;
  img {
    width: 320px;
  }
  
`

export const JobsCard = styled.div`
  display: flex;
  max-width: 1280px;
  margin: auto;
  justify-content: center;
  .news-grid {
    justify-content: center;
  }
  .news-card {
    min-width: 320px;
    max-width: 350px;
  }
  
  
`

const LoadingAnim = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Title = styled.h1`
  font-size: 2rem;
  background-position: center;
  background: url(assets/old/wallpaper.png);
  background-size: 600px;
  text-decoration: underline overline;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 1em;
`
const Cards = ({ person, values }) => {

    useEffect(() => {
        const studentsInSchools = firebaseInstance().functions()
            .httpsCallable('GetStudentCount')
        studentsInSchools({ docPath: `schools/${values.id}/students` }).then(results => {
        }).catch(err => {
            console.log(err.code, err.message, err.details)
        })
    }, [])
    return (
        <div className="column is-3">
            <a className="friend-item has-text-centered">
                <div className="avatar-wrap">
                    <div className="circle"></div>
                    <div className="chat-button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                            className="feather feather-message-circle">
                            <path
                                d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
                        </svg>
                    </div>
                    {person ? <Avatar name={`${values.firstname} ${values.lastname}`} round={true} /> : <img src={values.image} data-demo-src="assets/img/avatars/milly.jpg"
                        data-user-popover="7" alt="" />}

                </div>
                {person ? <h3>{values.firstname} {values.lastname}</h3> : <h3>{values.name}</h3>}


            </a>
        </div>
    )
}

export const BusinessesCard = ({ data, alert }) => {
    const { langChoice, globalId, me } = useContext(MyContext)
    const { t } = useTranslation()

    const onCancel = (e) => {
        e.preventDefault()
        jobPosts.doc(data.id)
            .collection('applications')
            .doc(globalId)
            .delete()
        jobPosts.doc(data.id).update({
            applicationIds: firestore.FieldValue.arrayRemove(globalId)
        })
    }
    const onClick = (e) => {
        e.preventDefault()
        postsDoc.doc(data.id)
            .collection('applications')
            .doc(globalId)
            .set(me)
            .then(result => {
                alert.success(t("general.userapplied"))
            })
        postsDoc.doc(data.id).update({
            applicationIds: firestore.FieldValue.arrayUnion(globalId)
        })
    }
    return (

        <div className="news-card is-default" style={{ boxShadow: 'var(--bazookka-box-shadow)' }}>
            {/* <span className="tag category politics" style={{display: 'none'}}>
                        {data.cat}
                    </span> */}

            <div className="news-content">
                <h3 className="news-title">{data.title}</h3>
                <h4>Categorie : {data.meta && data.meta.cat ? t(`${data.meta.cat}`) : t("jobs.uncategorized")} </h4>
                <h4>{data.organisation_id}</h4>
                <p>{data.tiny}
                </p>
                <div>{t(data.type)}</div>
                <div className="button-wrap">
                    <ShareBig share={data} />
                    {data.meta && !data.meta.ext && (
                        <Link className="button"
                            to={`/jobs/${data.id}`}
                            top={`/jobs/${data.id}`} style={{ boxShadow: 'var(--bazookka-box-shadow)' }}><i className="mdi mdi-eye"></i></Link>
                    )}

                    {data.meta && data.meta.ext && (
                        <a href={data.meta.link} target={'_blank'} className="button" style={{ boxShadow: 'var(--bazookka-box-shadow)' }}><i className="mdi mdi-eye"></i></a>
                    )}
                    <div className="actions" style={{ display: "none" }}>
                        <a className="action">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round" className="feather feather-bookmark">
                                <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                            </svg>
                        </a>
                        <a className="action is-like" style={{ display: "none" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round" className="feather feather-heart">
                                <path
                                    d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    )
}

const FileCard = props => {
    const { numeric } = props
    return (
        <div className="column is-3">
            <div className="card">
                <header className="card-header">
                    <p className="card-header-title">
                        File name
                    </p>
                </header>
                <div className="card-content">
                    <div className="content">
                        <i class="mdi mdi-file"></i>
                        <br />
                        <time dateTime="2016-1-1">11:09 PM - 1 Jan 2016</time>
                    </div>
                </div>
                <footer className="card-footer">
                    {!numeric ? (<a >
                        <i className="mdi mdi-download"></i>
                    </a>) : <></>}

                    <a >
                        <i className="mdi mdi-send"></i>
                    </a>
                    <a >
                        <i className="mdi mdi-pencil"></i>
                    </a>
                    <a >
                        <i className="mdi mdi-delete"></i>
                    </a>
                </footer>
            </div>
        </div>

    )
}

const AddUsers = props => {
    const { setAppAddUsers } = useContext(MyContext)
    const adduser = () => {
        setAppAddUsers(true)
    }

    const addMultipleUsers = () => {

    }
    useEffect(() => {

    }, [])

    const downloadFile = () => {
        // evt.preventDefault()
        let fakedata = [['my@email.com', 'schoolnumber', 'teacherref']]
        var csv = `email, schoolid, class\n`
        fakedata.forEach(data => {
            csv += data.join(',');
            csv += '\n'
        })

        return 'data:text/csv;charset=utf-8,' + csv;
    }
    return (
        <div id="posts-feed" className="container" style={{ paddingBottom: 50 }}>
            <div className="stories-list">
                <div className="container-inner">
                    <a className="story modal-trigger">
                        <a className="story modal-trigger" data-modal="new-story-modal" style={{ margin: 0 }} onClick={adduser}>
                            <div className="story-avatar">
                                <i className="mdi mdi-account-plus"></i>
                                <div className="add-overlay">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                        strokeLinecap="round" strokeLinejoin="round"
                                        className="feather feather-plus">
                                        <line x1="12" y1="5" x2="12" y2="19"></line>
                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                    </svg>
                                </div>
                            </div>
                        </a>
                    </a>
                    <a className="story" onClick={addMultipleUsers}>
                        <div className="story-avatar">
                            <i className="mdi mdi-account-multiple-plus"></i>
                        </div>
                    </a>
                    <Link
                        className="story"
                        download="user_download.csv"
                        to={downloadFile()}
                        alt="file sample to download">
                        <div className="story-avatar">
                            <i className="mdi mdi-file-download-outline"></i>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export const ListAllBusinesses = ({ person }) => {
    const { langChoice } = useContext(MyContext)
    const SearchJobs = firebaseInstance().functions().httpsCallable("SearchJobs")
    const [search_jobs, setSearchJobs] = useState([])
    const [switchToInternship, setSwithToInternship] = useState(false)
    const [select, setSelect] = useState("general.filter.all")
    const [lastdoc, setLastdoc] = useState(null)
    const [refs, setRefs] = useState(null)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [jobSql, setJobSql] = useState([])
    const [jobCount, setJobCount] = useState(0)
    const [searchedOfferTitle, setSearchedOfferTitle] = useState('')
    const [selectedCategories, setSelectedCategories] = useState([])
    const [joinCategories, setJoinCategories] = useState("")
    const [isActive, setIsActive] = useState(true);
    const formikRef = useRef()

    let { data } = useSWR(selectedCategories && searchedOfferTitle != "undefined" && jobCount >= jobSql.length ? `https://api.bazookka.com/posts/job?type=${switchToInternship ? 'internships' : 'jobs'}&start=${start}&limit=${limit}&categories=${selectedCategories.join(',')}&search=${searchedOfferTitle}` : '',
        url => fetch(url, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'get'
        }).then(r => {
            return r.json()
        }
        ));

    useEffect(() => {
        if (data) {
            setJobSql(data.rows)
            setJobCount(data.count)
        }
    }, [data, limit, switchToInternship])


    const [categories, dispatchAll] = useCatReducer(["field"])

    useEffect(() => {
        dispatchAll({ type: langChoice === 'fr' ? 'SET_FR' : 'SET_EN', payload: 'field.' })
    }, [])


    const { t } = useTranslation()

    const alert = useAlert()

    async function chargeMore(e) {
        setStart((prevState) => prevState + limit)
        setLimit((prevState) => prevState + limit)
    }

    async function ifWasCheck(e) {
        setSwithToInternship(e.target.checked)
        setStart(0)
        setLimit(3)
    }

    return (
        <div className="view-wrapper">
            <BannerStyle>
            </BannerStyle>
            <Title>{t('general.jobs')}</Title>

            <div class="w-full flex flex-col sm:flex-row flex-grow overflow-hidden">
                <div className="sm:w-1/3 md:1/4 w-full flex-shrink flex-grow-0 p-4">
                    <div className="sticky p-4 w-full">
                        <ul className="flex sm:flex-col overflow-hidden content-center justify-between">
                            <FormGroup>
                                <Formik
                                    onSubmit={(values) => {
                                        setSearchedOfferTitle(values.searchedOfferTitle)
                                    }}
                                    innerRef={formikRef}
                                    initialValues={{
                                        searchedOfferTitle: '',
                                        selectedCategories: []
                                    }}
                                >
                                    {({ values, errors, isSubmitting, setFieldValue, handleChange }) => {
                                        return <Form>
                                            <div className='control-section'>
                                                <div className="accord">
                                                    <div className="accordion-item">
                                                        <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                                                            <div>{t('listing.filterByCategorie')}</div>
                                                            <div>{isActive ? '-' : '+'}</div>
                                                        </div>
                                                        {isActive &&
                                                            <div className="accordion-content">
                                                                {
                                                                    categories.map((cat) => {
                                                                        return <div>
                                                                            <Field type="checkbox"
                                                                                onChange={e => {
                                                                                    handleChange(e)
                                                                                    if (e.target.checked === true) {
                                                                                        setSelectedCategories([...selectedCategories, e.target.value]);
                                                                                    } else {
                                                                                        const selected = selectedCategories.filter(a => {
                                                                                            if (a === e.target.value) return false;
                                                                                            return true;
                                                                                        });
                                                                                        setSelectedCategories([...selected]);
                                                                                    }
                                                                                }}
                                                                                value={cat.key} key={cat.key} name="selectedCategories" />  {t(`${cat.key}`)}
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                            </div>


                                        </Form>
                                    }}
                                </Formik>
                            </FormGroup>
                        </ul>
                    </div>
                </div>
                <main role="main" class="w-full h-full flex-grow p-3 overflow-auto">
                    <FilterOption>

                        <FormGroup>
                            <label htmlFor="jobTitle">{t('listing.filterByTitle')}</label>
                            <input className='w-full' id="searchedOfferTitle" name="searchedOfferTitle" placeholder={t("listing.filterByTitlePlaceHolder")}
                                onChange={e => {
                                    setSearchedOfferTitle(e.target.value)
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <FormControlLabel control={<Switch checked={switchToInternship} onChange={ifWasCheck} />} label={t('internships')} />
                        </FormGroup>

                    </FilterOption>

                    <JobsCard className="section is-news">
                        {jobSql && jobSql.length === 0 && <NoItems />}
                        <div className="news-grid">
                            {jobSql && jobSql.length > 0 && jobSql.map(c => {
                                return <BusinessesCard data={c} key={c.id} alert={alert} />
                            })}

                        </div>
                    </JobsCard>
                    <div className=" load-more-wrap has-text-centered">
                        {jobCount > jobSql.length ? <a onClick={chargeMore}>{t('general.loadmore')}</a> : <h1>{t('jobs.noMoreJobLoad')}</h1>}
                    </div>
                </main>
            </div>

        </div>
    )
}

const ListAllItems = ({ type, person, files, numeric }) => {
    const { schools, users, businesses, setSchools } = useContext(MyContext)
    const [count, setCount] = useState([])
    const getAllSchools = () => {
        return new Promise((resolve, reject) => {
            schoolsDoc.get().then(res => {
                let temp = res.docs.map(doc => {
                    return { ...doc.data(), id: doc.id }
                })

                setSchools({ type: 'GetAllSchools', payload: temp })
                setCount(schools)
                resolve()
            }).catch(error => {
                setCount([])
                console.log(error)
                resolve()
            })
        })

    }

    useEffect(async () => {

        if (type === 'schools' && schools.length === 0) {
            await getAllSchools()
            const studentsInSchools = firebaseInstance().functions()
                .httpsCallable('GetStudentCount')
            studentsInSchools({ docPath: `schools` }).then(results => {
                console.log('res', results.data.collections)
            }).catch(err => {
                console.log(err.code, err.message, err.details)
            })
        }
        if (type === 'schools' && schools.length > 0) {
            setCount(schools)
        }
        if (type === 'students') {
            setCount(users.slice(0, 20))
        }
        if (type === 'businesses') {
            setCount(businesses)
        }
    }, [schools])

    return (
        <div className="view-wrapper">
            <div className="container is-custom">
                <div id="profile-friends" className="view-wrap is-headless">
                    <div className="columns">
                        <div className="column">
                            <SearchBar person={person} />
                            {person && (<AddUsers />)}
                            <div className="friends-grid">
                                <div className="columns is-multiline">
                                    {!files ? count.map((std, key) => {
                                        return <Cards person={person} key={key} values={std} />
                                    }) :
                                        count.map((std, key) => {
                                            return <FileCard key={key} numeric={numeric} />
                                        })

                                    }
                                </div>
                            </div>
                            <div className=" load-more-wrap has-text-centered">
                                <a >Load More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListAllItems
