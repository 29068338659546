import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { CloseIco, PencilLineIco, TrashIco } from "../../ico";
import styled from "styled-components";
import { InputText, TextAreaInput } from "../FormElements";
import { Form, Formik } from "formik";

const ItemStyled = styled.div`
  .movable-item {
    position: relative;
    border-radius: 5px;
    background-color: #fafdff;
    height: max-content;
    min-height: 100px;
    width: auto;
    margin: 10px 20px;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    .top-right-corner {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .text-display-zone {
      padding: 20px 20px;
    }
  }
  .top-right {
    position: absolute;
    top: 0px;
    right: 0px;
  }
`;

export const FormElementCard = ({
  id,
  index,
  name,
  element,
  droppableId,
  openFormModal,
  clearElement,
  handlePrefabElement,
}) => {
  if (droppableId === "Form Elements" || droppableId === "Prebuilt Elements") {
    return (
      <ItemStyled>
        <Draggable draggableId={id} index={index}>
          {(provided) => (
            <div
              className={"movable-item"}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {element.elementType === "prebuilt" ? null : (
                <div className="top-right-corner">
                  <span onClick={() => openFormModal("modify", element)}>
                    <PencilLineIco />
                  </span>
                  <span onClick={() => openFormModal("delete", element)}>
                    <TrashIco />
                  </span>
                </div>
              )}
              <div className="text-display-zone">{name || element.type}</div>
            </div>
          )}
        </Draggable>
      </ItemStyled>
    );
  } else if (droppableId === "Form Template" && element.elementType === "prebuilt") {
    return (
      <ItemStyled>
        <Draggable draggableId={id} index={index}>
          {(provided) => (
            <div
              className={"movable-item"}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Formik
                enableReinitialize={true}
                initialValues={{
                  id: element.id,
                  type: element.type,
                  question: element.question,
                  questionDescription: element.questionDescription,
                  required: element.required,
                  elementType: "prebuilt",
                }}
                onSubmit={(values) => {
                  handlePrefabElement(values);
                }}
              >
                {() => (
                  <Form>
                    <div className="settings-form-wrapper">
                      <div className="settings-form">
                        <div style={{ width: "95%" }}>
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                            }}
                          >
                            <span onClick={() => clearElement(element)}>
                              <CloseIco />
                            </span>
                          </div>
                          <div className="field">
                            <InputText
                              id="question"
                              name="question"
                              label="Question"
                            />
                          </div>
                          <div className="field">
                            <TextAreaInput
                              id="questionDescription"
                              name="questionDescription"
                              label="Description"
                              placeholder="Describe your question"
                            />
                          </div>
                          <div className="field">
                            <button
                              type="submit"
                              className="button is-solid primary-button is-fullwidth"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </Draggable>
      </ItemStyled>
    );
  } else {
    return (
      <ItemStyled>
        <Draggable draggableId={id} index={index}>
          {(provided) => (
            <div
              className={"movable-item"}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <h4>{name}</h4>
              <div className="top-right">
                <span onClick={() => clearElement(element)}>
                  <CloseIco />
                </span>
              </div>
            </div>
          )}
        </Draggable>
      </ItemStyled>
    );
  }
};
