import React, {useState, useContext, useEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from "formik";
import styled from 'styled-components'
import {MyContext} from "../../stores";
import {NiceInput, InputText, TextAreaInput} from "../../components/FormElements";
import {Multiselect} from "multiselect-react-dropdown";
import {useCatReducer} from "../../stores/categories";
import {db, firebaseInstance} from "../../firestore/firestoreService";
import {useDeBounce} from "../../helper";
import ReactSelect from 'react-select'
import {isNoDataNeedDeepInfo} from "../../helper/acl";
import {ProfileBusinessForm} from "../Settings/Settings";

export const FormSignUpStyled = styled.div`
  border-radius: 1rem;
  background: white;
  display: flex;
  justify-content: center;
  width: 800px;
  
  border: 1px solid #e0e0e0;
  height: auto;
  margin: 2rem auto;
  padding: 3rem 0;
  h2 {
    font-size: 2rem;
    text-align: center;
  }
  form {
    width: 100%;
    padding: 2rem;
  }
  .column {
    width: 100%;
  }
  .split {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`

export default function Registration({setBadToken, badToken, t, data, id, setSignUpStep, saved, setData}, children){
    const {me, langChoice} = useContext(MyContext)
    const [categories, dispatchAll] = useCatReducer();
    const history = useHistory()
    const addressRef = useRef()
    const [select, setSelect] = useState(null)
    const [save, setSave] = useState(null)
    const [options, setOptions] = useState([])
    const [needed, setNeeded] = useState(null)
    const [lockedMode, setLockedMode] = useState(false)
    let googleLL = firebaseInstance().functions().httpsCallable('googleFindLongLat')
    const onSubmit = async (v, {setSubmitting}) => {
        setSubmitting(true)
        if(save) {
            v.address = save;
            let response = await googleLL({value: save})
            v.location = response.data.results[0].geometry.location
        }
        await db.collection('createAccountInvitations').doc(id).update(v)
        setSignUpStep(2)
        setSubmitting(false)
    }
    const returnCat = (cat, list, values) => {
        return values[cat] && values[cat].map( k => {
            return list.find(l => l.key === k)
        }) || []
    }

    const onCancel = () => {
        history.push('/')
    }
    const onSave = async  (v, setSubmitting) => {
        setSubmitting(true)
        if(save) {
            v.address = save;
            let response = await googleLL({value: save})
            v.location = response.data.results[0].geometry.location
        }
        await db.collection(`createAccountInvitations`).doc(id).update(v)
        setSubmitting(false)
    }
    useEffect(()=> {
        dispatchAll({
            type: langChoice === 'fr' ? 'ALL_SET_FR' : 'ALL_SET_EN'
        })

        if(data && data.invitedBy){
            if(data.invitedBy === 'ZohoSyncScript'){
                setLockedMode(true);
            }
        }

        isNoDataNeedDeepInfo(data.role).then( info => setNeeded(info))
        if(me) {
            history.push('/me')
        }
    }, [me, data])

    const updateRequest = async (value) => {
        let addressGetter = firebaseInstance().functions().httpsCallable('googleAddress')
        // let response = await axios.get(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${urlEncode(value)}&types=geocode&key=AIzaSyDU-7VwiLZiZwAZALo-0dqQlUAZQKXga1Y`)
        if(value.length > 7) {
            let response = await addressGetter({value})

            setOptions(response.data.response.predictions.map( (d, index) => {
                return {value: d.description, label: d.description}
            }))


        }
    }

    const handleChange = (v) => {
        setSelect(v)
        setSave(addressRef?.current?.state?.value?.label)
    }

    useDeBounce(()=> updateRequest(select), 2000, [select])
    return (
    <FormSignUpStyled>
        <Formik initialValues={data} onSubmit={onSubmit} enableReinitialize={true}>
            {({values, isSubmitting , resetForm, setSubmitting}) => {
                return (
                    <Form autoComplete="off">
                        <h2>{t('general.registerform')}</h2>
                        <div className="column">
                            <InputText
                                label={ t('listing.name')}
                                value={values.displayName}
                                name="displayName" disabled={lockedMode} />
                            <InputText
                                label={ t('general.role')}
                                value={t(values.role)}
                                name="role" disabled />
                            <InputText
                                label={ t('general.emailaddress')}
                                value={values.email}
                                name="email" disabled={lockedMode} />
                            {t('general.location')}:  {values.address}
                            <ReactSelect
                                defaultValue={values.address || ''}
                                options={options}
                                onInputChange={handleChange}
                                ref={addressRef}
                                disabled={lockedMode}
                            />
                            <InputText label={t('settings.phone')} value={values.phone || ''} name="tel" disabled={lockedMode}/>
                            <TextAreaInput label={t('settings.intro')} value={values.intro || ''} name="intro" disabled={lockedMode}/>
                            {needed && categories && categories.map((cat, i)=> {
                                return (
                                    <div className="field" key={i}>
                                        <label>{t(cat[0])}
                                            <div className="control">
                                                <Multiselect
                                                    options={cat[1] ||[]}
                                                    displayValue="value"
                                                    placeholder={t('overlay.select')}
                                                    selectedValues={ returnCat(cat[0], cat[1], values)}
                                                    emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                    onSelect={(v) => {
                                                        values[cat[0]] = v.map( k => k.key)
                                                    }}
                                                    onRemove={(v) => {
                                                        values[cat[0]] = v.map( k => k.key)
                                                    }}

                                                >

                                                </Multiselect>
                                            </div>
                                        </label>
                                    </div>
                                )
                            })}
                           <div className="split">
                               {saved && <button type="button" className="button" onClick={()=> onSave(values, setSubmitting)}
                                                disabled={isSubmitting}
                               >{t('general.saveforlater')}</button>}
                               <button type="button" className="button" disabled={isSubmitting}
                                onClick={e => setSignUpStep(2)}
                               >{t('general.next')}</button>
                               <button type="button" className="button" onClick={onCancel}>{t('general.cancel')}</button>
                           </div>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    </FormSignUpStyled>
    )
}

export function YouveRegister({t}){
    const history = useHistory()
    useEffect(()=> {
        setTimeout(()=>{
            history.push('/')
        }, 5000)
    })
    return (
        <FormSignUpStyled>
            {t('message.account_creation')}
        </FormSignUpStyled>
    )
}

export function JustRegister({t, initialValues,invitation, setSignUpStep}) {
    const submit = async () => {
        const value = Object.assign({claims:{ [initialValues.schoolId[0]]: initialValues.role}}, initialValues)
        createUser(value).then( res => {
            let updateCreateUserInvitation = firebaseInstance().functions().httpsCallable('UpdateCreateUserInvitation')
            updateCreateUserInvitation({
                infos: {
                    acceptedAt: new Date().getTime(),
                },
                invitationId: invitation.id,
            })
        })
        setSignUpStep(3)
    }
    let createUser = firebaseInstance()
        .functions()
        .httpsCallable('CreateUsers')

    return (
        <FormSignUpStyled>
            <div>
                <h4>{initialValues.displayName}</h4>
                <br />
                <a onClick={submit} className="button">{t('general.registeras')}</a>
            </div>

        </FormSignUpStyled>
    )
}

export function CompanyForm(){
    const {me, globalId} = useContext(MyContext)
    return (
        <ProfileBusinessForm me={me} globalId={globalId}></ProfileBusinessForm>
    )
}
