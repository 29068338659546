import React, {useContext, useState} from "react";
import {MyContext} from "../../stores";
import styled from 'styled-components';
import Avatar from 'react-avatar';
import ChatInput from "../../components/ChatInput";
import {useTranslation} from "react-i18next";
import Loader from "../Loader";

const ComposerContainer = styled.div`
    display:flex;
    margin-top:10px;
`

const AvatarContainer = styled.div`
    min-width:38px;
    flex:1%;
`

const InputContainer = styled.div`
    padding-left:0;
    margin-left:10px;
    flex:99%;
    display:flex;
`

const PostButton = styled.div`
    flex:10%;
    border-radius: 5px;
    color:white;
    background-color:var(--bazookka-blue);
    font-size:12px;
    margin-left:10px;
    text-align:center;
    height:100%;
    line-height:3;
    cursor:pointer;

    &.disabled{
        background-color:transparent;
        color:#dbdbdb;
        border: 1px solid #dbdbdb;
        cursor:default;
    }
`

const CommentComposer = ({onSubmit}) => {
    const {me} = useContext(MyContext);
    const {t} = useTranslation();
    const [fieldValue, setFieldValue] = useState("")
    const [buttonPostClass, setPostButtonClass] = useState("disabled");

    const onKeyUp = (data) => {
        setFieldValue(data);
        if(data.length > 0){
            setPostButtonClass("")
        }else if(data.length === 0){
            setPostButtonClass("disabled");
        }
    }

    const handleClick = () => {
        if(buttonPostClass !== "disabled"){
            onSubmit(fieldValue);
        }
    }
    
    if(!me){
        return <Loader />;
    }

    return (
        <ComposerContainer>
            <AvatarContainer>
                <Avatar
                    name={me?.displayName}
                    round={true}
                    size={38}
                />
            </AvatarContainer>
            <InputContainer>
                <ChatInput handleKeyUp={onKeyUp} placeholder={t('feed.writeacomment')} onSubmit={onSubmit}/>
                <PostButton className={buttonPostClass} onClick={handleClick}>{t('general.send')}</PostButton>
            </InputContainer>
        </ComposerContainer>
    )
}

export default CommentComposer;
