import React, {useState, useContext, useEffect, Fragment} from "react";
import {Link, useParams, useLocation, useHistory} from "react-router-dom";
import {MyContext} from "../stores";
import {
    jobPosts,
    activeJobs,
    postsDoc,
    firestore,
    usersDoc,
    firebaseInstance,
    groupsDoc
} from "../firestore/firestoreService";
import {useTranslation} from "react-i18next";
import styled from 'styled-components';
import {formatDistance} from "date-fns";
import NoItems from "./NoItems";
import TileComponent from "./TileComponent";
import {JobIntershipCrud} from "../pages/Applications";
import LikeButton from "../components/LikeButton";
import BookmarkButton from "../components/BookmarkButton";
import Avatar from "react-avatar";
import { UserAction } from "../stores/enums/UserAction";
import {isAdmin} from "../helper/acl";
import {useAlert} from "react-alert";
import {returnRelatedUserByRoles} from "../utils/arraySortByField";
import {usePromise} from "../helper";
import {CircularProgress} from "@mui/material";
import {ShareBig} from "./Share";
import { fr } from "date-fns/locale";
import enCA from "date-fns/locale/en-CA";
import i18next from "i18next";

const LineBreak = styled.div`
    width:100%;
    clear:both;
  `
const HeaderButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0px ;
  button {
    display: flex;
    padding: 10px 15px;
    background: var(--bazookka-pink);
    font-size: 1rem;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid var(--bazookka-pink);
    
  }

`

const Heading = styled.div`
    flex-wrap: wrap;
    transition: all 0.35s ease-out;
`

const FormContainer = styled.div`
    width:100%;
    margin-top:30px;
    padding:5px;
`

const JobCardStyle = styled.div`
    position: relative;
    width: 250px !important;
    height: 100%;
    border: 1px solid var(--bazookka-pink);
    border-radius: 5px;
    background-color: white;
   .jobcard > div {
    position: relative;
    width: 100%; 
  }
  

  .jobcard-top {
    position: relative;
    width: 90%;
    margin: auto;
    padding-top: 9px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .image-container img{
    width: 100%;
  }

  .logo-container {
    bottom: -25px;
    right: 35px;
    width: 50px;
    height: 50px;
    border: 4px solid white;
    border-radius: 50%;
    overflow: hidden;
  }
  i {
    font-size: 2rem;
  }
  .side-right {
    text-align: right;
  }
  .jobpost-bottom {
    position: absolute;
    bottom: 0px;
  }
  button {
    background-color: var(--bazookka-pink);
    color: white;
  }
  p {
    font-size: 12px;
    width: 90%;
    margin: auto;
  }
  
  .date p {
    font-size: 14px;
  }
  .date {
    margin-bottom: 1rem;
  }
  h3 {
    width: 90%;
    margin: auto;
  }
`;

const CardStyled = styled.div`
  width: 100%;
  margin-top:15px;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  .card {
    padding: 8px 15px !important;
    h4 {
      padding-bottom: 10px;
    }
  }
  .card__buttom{
    display: flex;
    flex-direction: row;
    text-align: right;
    justify-items: right;
    font-size: 1.5rem;
    border-top: 2px dashed #e8e8e8;
    > a {
      padding: 10px 10px 0px 0px;
      cursor: pointer;
    }
  }
  .usersContainer{
      max-height:200px;
  }

  .card__top {
        .user{
            margin:10px 0;
            display: grid;
            grid-template-columns: 40px 1fr;
            grid-gap: 3px;
            
            h4{
                padding-bottom:0px;
                margin:inherit;
                margin-left:3px;
            }
        }

        .businessName{
            font-size:16px;
        }

        .jobTitle{
            font-size:14px;
            color:#999;
        }
    }
`
const FullWidthStyle = styled.div`
  background: white;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: 1px solid #e8e8e8;
  padding:16px;

  .report div, .report a {
    color: white;
    filter: drop-shadow(0 0.1rem 0 rgba(0,0,0,0.5));
    border:0;
  }
  .view .button  {
    background: var(--bazookka-blue-buble);
  }
  .apply .button {
    background: var(--bazookka-pink);
  }
  .button {
    background: var(--bazookka-pink);
  }
  .question-content {
      max-width:none;
  }
  .question-block {
    border-bottom:none !important;
  }
  
`

const InternshipActionsContainer = styled.div`
    margin-top:15px;
    
    .report{
        display:inline-block;
        margin-right:15px;
}
`

const PostActionsContainer = styled.div`
    display:flex;
    flex:auto;
    flex-direction: row-reverse;
    margin-top:-40px;

    .bookmark-wrapper{
        margin:0 10px;
    }
`

const SocialWrapper = styled.div`
    .social-count{
        margin-left:0;
    }

    .likes-count{
        svg{
            height: 18px;
            width: 18px;
            stroke: #888da8;
            vertical-align:middle;
        }

        span{
            font-size: .9rem;
            color: #888da8;
            margin: 0 5px;
            vertical-align:middle;
        }
    }
`

const CenteredText = styled.div`
    text-align:center;
`

export const JobPostCardNew = ({data, type}) => {
    const {t} = useTranslation()
    const {dispatchModal} =useContext(MyContext)
    return (
        <JobCardStyle className="jobcard">
            <div>
                <div className="jobcard-top">

                    <div className="logo-container">
                        <img src="assets/old/wallpaper.png" alt=""
                             data-demo-src="assets/img/icons/logos/slicer.svg" data-page-popover="4"/>
                    </div>
                    <div className="side-right">
                        <i className="mdi mdi-eye"></i>
                        {/*<i className="mdi mdi-note"></i>*/}
                    </div>
                </div>
                <div className="jobpost-bottom">
                    <h3>{data.title}</h3>
                    <div className="date">
                       <p>{}</p>
                    </div>
                    <button className="button is-fullwidth">{t('listing.applyNow')}</button>
                </div>
            </div>
        </JobCardStyle>
    )
}


export const JobPostCard = ({data, type, hidetop}) => {
    const {me, globalId, dispatchModal} = useContext(MyContext)
    const {page} = useParams()
    const {t} = useTranslation()
    let media = matchMedia(`max-width: 768px`)
    let width = media.matches ? `calc(100% - 20px)`: `calc(33% - 20px)`
    const alert = useAlert()
    const applyOnJob = (event, data) => {
        event.preventDefault()
        jobPosts.doc(data.id)
            .collection('applications')
            .doc(globalId)
            .set(me)
            .then( result => {
                alert.success(t("general.userapplied"))
            })

    }
    const linkUrl = type === 'intern' ? `/my-internship/${data.id}` : `/jobs/${data.id}`;

    return (
        <JobCardStyle className="news-card is-default" style={{width}}>
            {hidetop &&
            <Fragment>
                        <span className="tag category politics">
                {data.cat}
            </span>
                <div className="avatar-wrapper">
                    <div className="avatar-wrap">
                        <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/ken.jpg"
                             data-user-popover="14" alt="" />
                    </div>
                </div>
                <img src="assets/old/wallpaper.png" data-demo-src="assets/img/demo/news/p3.jpg" alt=""/>
            </Fragment>}

            <div className="news-content">
                <h3 className="news-title">{data.student && data.student.displayName || data.title}</h3>
                <p>{data.student && data.student.displayName && data.title || data.tiny}</p>
                <div className="button-wrap">

                    {type === 'owner' ?
                        <a
                            to={`/dashboard/editJob/${data.id}`}
                            onClick={(e)=>{
                                e.preventDefault()
                                dispatchModal({
                                    type: 'OPEN_MODAL',
                                    payload: {
                                        modalType:'EditJobModal',
                                        modalProps: {
                                            title: i18next.t("modal.title.EditJobPost"),
                                            job: data.id
                                        }
                                    }
                                })
                            }}
                            className="button">Edit</a>
                        :
                        <a className="button" onClick={evt => applyOnJob(evt, data)}>{t('listing.apply')}</a>}
                    {/*<Link className="button" to={`/${langChoice}/jobs/${data.id}`}><i className="mdi mdi-eye"></i></Link>*/}

                    {/*{type === 'owner' && <a className="button" onClick={editPost}>Edit</a>}*/}
                    {type === 'student' && <a className="button" onClick={evt => applyOnJob(evt, data)}>{t('listing.apply')}</a>}

                    <Link className="button" to={linkUrl}><i className="mdi mdi-eye"></i></Link>
                    {(page === 'applications' || window.location.href.indexOf('dashboard') !==-1) &&

                        <Link
                            className="button"
                            to={`/dashboard/applications/${data.id}`}
                        >
                            <i className="mdi mdi-account-group"></i>
                        </Link>

                    }

                    <div className="actions" style={{display: 'none'}}>
                        <a className="action">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-bookmark">
                                <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                            </svg>
                        </a>
                        <a className="action is-like">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-heart">
                                <path
                                    d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </JobCardStyle>

    )
}

export const JobPostFullWidth = ({ type, owner, title }) => {
    const [jobPost,setJobPost] = useState([])
    const [bookmarks,setBookmarks] = useState([])
    const [admin, setAdmin] = useState()
    const [list,setList] = useState(['jobs', 'internships'])
    const {me, globalId, dispatchModal} = useContext(MyContext)
    const {t} = useTranslation()

    useEffect(()=>{
        if(me && me.select) {
            isAdmin(me.select.id).then(v => setAdmin(v))
        }
    }, [me])

    useEffect(()=>{
        const cleanup = "undefined"
        if(globalId){
            try {
                cleanup = usersDoc.doc(globalId).collection('bookmarks').doc('bookmarkDocument').onSnapshot(data => {
                    try {
                        setBookmarks(data.data().bookmarks);
                    }catch(e) {}
                });
            }catch(e) {
            }
             return () => typeof x === "function" && cleanup()
        }

        postsDoc
            .where('types','array-contains-any', list)
            .onSnapshot(data=>{
            let postCollection = data.docs.map(d=>Object.assign({id: d.id, ...d.data()}))
            if(type === 'bookmarks'){
                postCollection = postCollection.filter((post) => bookmarks.includes(post.id));
            }
            setJobPost(postCollection);
        })
    },[globalId, bookmarks, list, type])
    return (
        <React.Fragment>
            <HeaderButton className="two-buttons">
                <button onClick={()=> setList(['jobs'])}>{t('jobs')} <i className="mdi mdi-briefcase"></i></button>
                <button onClick={()=> setList(['internships'])}>{t('internships')} <i className="mdi mdi-chair-school"></i></button>
            </HeaderButton>
            {jobPost.length === 0 && type === 'bookmarks' && <CenteredText>{t("general.nobookmark")}</CenteredText>}
            {jobPost.length === 0 && <NoItems />}

            {jobPost.map((post, key)=>{
                return (
            <SingleJobPost key={key} index={key} owner={owner} post={post} bookmarks={bookmarks} admin={admin}></SingleJobPost>
            )
            })}
            {jobPost && jobPost.length > 10 &&
            <div className=" load-more-wrap has-text-centered">
                <a href="#" className="load-more-button">{t('general.loadmore')}</a>
            </div>
            }

        </React.Fragment>

    )
};
export const JobPostFullWidthAdmin = ({ type, owner, title }) => {
    const [jobPost,setJobPost]=useState([])
    const [create,setCreate]=useState(false)
    const {t} = useTranslation()
    const {me, globalId, dispatchModal, dispatchCategories, langChoice} = useContext(MyContext)
    const [groups, groupError, groupLoad ] = usePromise(async ()=> {
        let doc= await groupsDoc.where('admin', 'array-contains-any', [globalId]).get()
        return doc.docs.map( d => Object.assign({id: d.id, ...d.data()}))
    }, [globalId])
    const alert = useAlert()
    const onApply = post => {
        postsDoc.doc(post.id)
            .collection('applications')
            .doc(globalId)
            .set(me)
            .then( result => {
                alert.success(t("general.userapplied"))
            })
    }
    const onEdit = post => {
        dispatchModal({
            type: 'OPEN_MODAL',
            payload: {
                modalType:'EditJobModal',
                modalProps: {
                    title: i18next.t("modal.title.EditJobPost"),
                    job: post,
                    type
                }
            }
        })
    }
    useEffect(()=>{
        if(owner &&  me?.select) {
            postsDoc.where('businessId','array-contains',me.select.id)
                .where('type', '==', type)
                .onSnapshot(data=>{
                    setJobPost(data.docs.map(d=>Object.assign({id: d.id, ...d.data()})))
                })
        } else {
            postsDoc.where('type','==', type).get().then(data=>{
                setJobPost(data.docs.map(d=>Object.assign({id: d.id, ...d.data()})))
            }).catch(e => console.log(e))
        }


    },[globalId, me])

    if(!groupLoad) {
       return (
           <CircularProgress />
       )
    }

    return (
        <div className="posts-wrapper">
            <Heading id="profile-timeline-posts" className="box-heading">
                    <h4>{t(title)}</h4>
                        <div className="button-wrap"><a className="button is-active" onClick={(e)=> {
                            e.preventDefault()
                            dispatchModal({
                                type: 'OPEN_MODAL',
                                payload: {
                                    modalType:'EditJobModal',
                                    modalProps: {
                                        title: i18next.t("modal.title.EditJobPost"),
                                        type
                                    }
                                }
                            })
                        }}>{t('general.create')}</a></div>
                </Heading>
            {jobPost.map((post, key)=>{
                return (
                    <JobIntershipCrud owner={owner} post={post} key={key} onApply={onApply} onEdit={onEdit} type={type}></JobIntershipCrud>
                )
            })}
            {jobPost && jobPost.length > 10 &&
            <div className=" load-more-wrap has-text-centered">
                <a href="#" className="load-more-button">{t('general.loadmore')}</a>
            </div>
            }

        </div>

    )
};
export const SingleJobPost = ({index, post, owner = false, admin}) => {
    const [bookmarks,setBookmarks] = useState([])
    const alert = useAlert()
    const {me, globalId, langChoice} = useContext(MyContext)
    useEffect(() => {
        if(globalId){
            usersDoc.doc(globalId).collection('bookmarks').doc('bookmarkDocument').onSnapshot(data => {
                setBookmarks(data.data()?.bookmarks || []);
            });
        }
    },[globalId])

    const {t} = useTranslation()
    const onApply = post => {
        const createUsersActions = firebaseInstance().functions().httpsCallable("createUsersActions")
        createUsersActions({type : UserAction.OFFER_APPLICATION,
            action : "apply",
            postTitle : post.title,
            postType : post.type,
             displayName : me.displayName,
              globalId})

        postsDoc.doc(post.id)
            .collection('applications')
            .doc(globalId)
            .set(me)

        postsDoc.doc(post.id).update({
            applicationIds: firestore.FieldValue.arrayUnion(globalId)
        }).then( d => {
            alert.success(t("general.userapplied"))
        })
    }

    const onCancel = post => {
        const createUsersActions = firebaseInstance().functions().httpsCallable("createUsersActions")
        createUsersActions({type : UserAction.OFFER_CANCEL,
             action : "cancel",
             postTitle : post.title,
             postType : post.type,
             displayName : me.displayName,
            globalId
        })
        postsDoc.doc(post.id)
            .collection('applications')
            .doc(globalId)
            .delete()

        postsDoc.doc(post.id).update({
            applicationIds: firestore.FieldValue.arrayRemove(globalId)
        }).then( d => {
            alert.remove(t("general.applicationremoved"))
        })
    }
    return (
        <FullWidthStyle key={index}>
            <TileComponent label={post.type} ico={post.type === 'jobs' ? <i className="mdi mdi-briefcase"></i> : <i className="mdi mdi-school"></i>}/>
            <div className="question-content">


                <div className={`question-block ${index !== 0 && 'is-top-spaced'}`}>

                    <Link to={`/jobs/${post.id}`} className="question-title is-link">{post.title}</Link>

                    <div className="question-author">
                        <img src="assets/old/wallpaper.png" data-demo-src="assets/img/avatars/milly.jpg"
                             data-user-popover="7" alt="" />
                        <div className="meta">
                            <span>{post?.author?.companyName}</span>
                            <span>{formatDistance(new Date(post.date), new Date(), {locale: langChoice === 'fr' && fr })}</span>
                        </div>
                    </div>

                    <div className="question-text">
                        <p>{post.tiny}</p>
                        <div className="tags">
                            {post.categories && Array.isArray(post.categories) && post.categories.map( (cat, key)=> {
                                return (
                                    <span className="tag" key={key}>{t(cat)}</span>
                                )
                            }) }

                        </div>
                    </div>
                    <div className="question-actions">
                        <InternshipActionsContainer>
                            <div className="report view">
                                <Link className="button" to={`/jobs/${post.id}`}>{t('general.view')}</Link>
                            </div>
                            {!admin && (
                            <>
                                {post?.applicationIds?.includes(globalId) ?
                                    <div className="report apply">
                                        <div className="button" onClick={evt => {
                                            evt.preventDefault()
                                            onCancel(post)
                                        }}>{t('listing.cancelApp')}</div>
                                    </div>
                                    :
                                    <div className="report apply">
                                        <div className="button" onClick={evt => {
                                            evt.preventDefault()
                                            onApply(post)
                                        }}>{t('listing.apply')}</div>
                                    </div>
                                }
                            </>
                            )}

                        </InternshipActionsContainer>
                    </div>
                    <div>
                        <PostActionsContainer>
                            <ShareBig share={post}></ShareBig>
                            <LikeButton postId={post.id} likes={post.likedBy} />
                            <BookmarkButton postId={post.id} bookmarks={bookmarks} className="bookmark-wrapper" />
                        </PostActionsContainer>
                    </div>
                </div>
            </div>
        </FullWidthStyle>
    )
}

const GroupInternshipList = ({internships, admin}) => {
    return (
        <CardStyled className="parent__card">

        {internships.map((post, key)=>{
            return (
                <div className="card" key={key}>
                    <div className="card__top">
                        <div className="businessName">{post?.businessName}</div>
                        <div className="jobTitle">{post?.title}</div>
                        <div className="usersContainer">
                            {returnRelatedUserByRoles('multi', 'student', post.relatedUsers).map((user) => {
                            return(
                                <div className="user">
                                    {user?.avatar ?
                                        <Avatar
                                            src={user.avatar}
                                            round={true}
                                            size={40}
                                        /> :
                                        <Avatar
                                            name={user.displayName}
                                            round={true}
                                            size={40}
                                            />
                                    }
                                    <h4>{user.displayName}</h4>
                                </div>
                            )
                            })}
                        </div>
                    </div>

                    <div className="card__buttom">
                        <Link to={`/my-internship/${post.id}`}>
                            <i className="mdi mdi-eye-outline"></i>
                        </Link>
                        {admin && (
                            <Link to={`/me/my-internships/edit/${post.id}`}>
                                <i className="mdi mdi-pencil"></i>
                            </Link>
                        )}

                    </div>

                </div>
            )
        })}
    </CardStyled>
    )
}

const SingleInternshipList = ({internships, admin}) => {
    return (
        <CardStyled className="parent__card">

        {internships.map((post, key)=>{
            return (
                <div className="card" key={key}>
                    <div className="card__top">
                        <div className="businessName">{post?.businessName}</div>
                        <div className="jobTitle">{post?.title}</div>
                        <div className="user">
                            {returnRelatedUserByRoles('single', 'student', post.relatedUsers)?.avatar ?
                                <Avatar
                                    src={returnRelatedUserByRoles('single', 'student', post.relatedUsers).avatar}
                                    round={true}
                                    size={40}
                                /> :
                                <Avatar
                                    name={returnRelatedUserByRoles('single', 'student', post.relatedUsers).displayName}
                                    round={true}
                                    size={40}
                                    />
                            }
                            <h4>{returnRelatedUserByRoles('single', 'student', post.relatedUsers).displayName}</h4>
                        </div>
                    </div>

                    <div className="card__buttom">
                        <Link to={`/my-internship/${post.id}`}>
                            <i className="mdi mdi-eye-outline"></i>
                        </Link>
                        {admin && (
                            <Link to={`/me/my-internships/edit/${post.id}`}>
                                <i className="mdi mdi-pencil"></i>
                            </Link>
                        )}

                    </div>

                </div>
            )
        })}
    </CardStyled>
    )
}

const InsideGroupInternshipList = ({internships, admin}) => {
    return (
        <CardStyled className="parent__card">

        {internships.map((post, key)=>{
            return (
                <div className="card" key={key}>
                    <div className="card__top">
                        <div className="businessName">{post?.businessName}</div>
                        <div className="jobTitle">{post?.title}</div>
                        <div className="user">
                            {returnRelatedUserByRoles('single', 'student', post.relatedUsers)?.avatar ?
                                <Avatar
                                    src={returnRelatedUserByRoles('single', 'student', post.relatedUsers).avatar}
                                    round={true}
                                    size={40}
                                /> :
                                <Avatar
                                    name={returnRelatedUserByRoles('single', 'student', post.relatedUsers).displayName}
                                    round={true}
                                    size={40}
                                    />
                            }
                            <h4>{returnRelatedUserByRoles('single', 'student', post.relatedUsers).displayName}</h4>
                        </div>
                    </div>

                    <div className="card__buttom">
                        <Link to={`/my-internship/${post.id}`}>
                            <i className="mdi mdi-eye-outline"></i>
                        </Link>
                        {admin && (
                            <Link to={`/me/my-internships/edit/${post.id}`}>
                                <i className="mdi mdi-pencil"></i>
                            </Link>
                        )}

                    </div>

                </div>
            )
        })}
    </CardStyled>
    )
}

export const InternshipFullWidthCard = ({type}) => {
    const fakeInternshipGroups = [
        {
            businessId: ['Qn1iPS4757loPqvZJdY0'],
            businessName: "Riesling LLC.",
            description: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
            endDate: 1648872000000,
            id: "2AbhpmQHpaeA9KyCoiKq",
            relatedPeople:['PwHZbaTAbMY3x8NpoBWw2jxaBTm1', 'PwHZbaTAbMY3x8NpoBWw2jxaBTm1', 'vRM9H1NHDvWA5PWYYZkuAF6shAB2', 'S85kOGAA9gOqFOo9US1WyXRIzzW2'],
            relatedPeoples: {S85kOGAA9gOqFOo9US1WyXRIzzW2: true, PwHZbaTAbMY3x8NpoBWw2jxaBTm1: true, vRM9H1NHDvWA5PWYYZkuAF6shAB2: true},
            relatedUsers:[
                {
                    avatar: "https://firebasestorage.googleapis.com/v0/b/bazookka-app.appspot.com/o/profile_pictures%2Fprofile%2FPwHZbaTAbMY3x8NpoBWw2jxaBTm1%2FcroppedCover.jpeg?alt=media&token=c168d4b9-a2ec-4d2b-a824-a1dd3407c56c",
                    displayName: "Sam Bellamy",
                    id: "PwHZbaTAbMY3x8NpoBWw2jxaBTm1",
                    internshipRole: "student",
                },
                {
                    avatar: null,
                    displayName: "Germanda Krajcik",
                    id: "XN8cYdofQYW07ZOkPAVReKmJo872",
                    internshipRole: "student",
                },
                {
                    avatar: null,
                    displayName: "Cecile Osinski",
                    id: "vRM9H1NHDvWA5PWYYZkuAF6shAB2",
                    internshipRole: "watcher",
                },
                {
                    avatar: "https://firebasestorage.googleapis.com/v0/b/bazookka-app.appspot.com/o/profile_pictures%2Fprofile%2FS85kOGAA9gOqFOo9US1WyXRIzzW2%2FcroppedCover.jpeg?alt=media&token=06a76a0f-a7b4-4679-abfd-dab150b4a1ea",
                    displayName: "Remi Richardson",
                    id: "S85kOGAA9gOqFOo9US1WyXRIzzW2",
                    internshipRole: "watcher",
                },
            ],
            startDate: 1643778000000,
            status: "active",
            tiny: "Web developer with minimum one year experience.",
            title: "Web Developer",
            type: 'group',
        },
        {
            active: true,
            author: {id: 'S85kOGAA9gOqFOo9US1WyXRIzzW2', avatar: 'https://firebasestorage.googleapis.com/v0/b/bazook…=media&token=46c8d3f6-9c6e-40ac-b6ef-2c0310a0494c', displayName: 'Kaci Kling'},
            businessId: ['Qn1iPS4757loPqvZJdY0'],
            businessName: "Développeur web junior",
            endDate: 1648872000000,
            id: "5ZHG7H2uuJfm5789gVic",
            jobSelect: true,
            relatedPeople: ['PwHZbaTAbMY3x8NpoBWw2jxaBTm1', 'S85kOGAA9gOqFOo9US1WyXRIzzW2', 'XN8cYdofQYW07ZOkPAVReKmJo872', 'PwHZbaTAbMY3x8NpoBWw2jxaBTm1', 'S85kOGAA9gOqFOo9US1WyXRIzzW2', 'XN8cYdofQYW07ZOkPAVReKmJo872'],
            relatedPeoples: {PwHZbaTAbMY3x8NpoBWw2jxaBTm1: true, XN8cYdofQYW07ZOkPAVReKmJo872: true, S85kOGAA9gOqFOo9US1WyXRIzzW2: true},
            relatedUsers: [
                {
                    avatar: "https://firebasestorage.googleapis.com/v0/b/bazookka-app.appspot.com/o/profile_pictures%2Fprofile%2FPwHZbaTAbMY3x8NpoBWw2jxaBTm1%2FcroppedCover.jpeg?alt=media&token=c168d4b9-a2ec-4d2b-a824-a1dd3407c56c",
                    displayName: "Sam Bellamy",
                    id: "PwHZbaTAbMY3x8NpoBWw2jxaBTm1",
                    internshipRole: "student",
                },
                {
                    avatar: null,
                    displayName: "Germanda Krajcik",
                    id: "XN8cYdofQYW07ZOkPAVReKmJo872",
                    internshipRole: "student",
                },
                {
                    avatar: "https://firebasestorage.googleapis.com/v0/b/bazookka-app.appspot.com/o/profile_pictures%2Fprofile%2FbjL5Vr7KatahpwlHEk1pX0mtGQ32%2FcroppedCover.jpeg?alt=media&token=43b88344-d8f5-4d41-96fa-f6edd185915c",
                    displayName: "Zion Lee ",
                    id: "bjL5Vr7KatahpwlHEk1pX0mtGQ32",
                    internshipRole: "student",
                },
                {
                    avatar: "https://firebasestorage.googleapis.com/v0/b/bazookka-app.appspot.com/o/profile_pictures%2Fprofile%2FS85kOGAA9gOqFOo9US1WyXRIzzW2%2FcroppedCover.jpeg?alt=media&token=46c8d3f6-9c6e-40ac-b6ef-2c0310a0494c",
                    displayName: "Kaci Kling",
                    id: "S85kOGAA9gOqFOo9US1WyXRIzzW2",
                    internshipRole: "teacher",
                },
                {
                    avatar: null,
                    displayName: "Germanda Krajcik",
                    id: "XN8cYdofQYW07ZOkPAVReKmJo872",
                    internshipRole: "supervisor",
                },
            ],
            startDate: 1648872000000,
            tiny: "minimum d'un an d'expérience",
            title: "minimum d'un an d'expérience",
            type: 'group',
        },
        {
            active: true,
            author: {displayName: 'Kaci Kling', avatar: 'https://firebasestorage.googleapis.com/v0/b/bazook…=media&token=46c8d3f6-9c6e-40ac-b6ef-2c0310a0494c', id: 'S85kOGAA9gOqFOo9US1WyXRIzzW2'},
            businessId: ['Qn1iPS4757loPqvZJdY0'],
            businessName: "Dacima",
            endDate: 1649966620000,
            id: "8Di4zvQI5rfIlD9VcABe",
            jobSelect: false,
            relatedPeople: ['bjL5Vr7KatahpwlHEk1pX0mtGQ32', 'S85kOGAA9gOqFOo9US1WyXRIzzW2', 'S85kOGAA9gOqFOo9US1WyXRIzzW2', 'S85kOGAA9gOqFOo9US1WyXRIzzW2'],
            relatedPeoples: {S85kOGAA9gOqFOo9US1WyXRIzzW2: true, bjL5Vr7KatahpwlHEk1pX0mtGQ32: true},
            relatedUsers: [
                {
                    avatar: "https://firebasestorage.googleapis.com/v0/b/bazookka-app.appspot.com/o/profile_pictures%2Fprofile%2FbjL5Vr7KatahpwlHEk1pX0mtGQ32%2FcroppedCover.jpeg?alt=media&token=43b88344-d8f5-4d41-96fa-f6edd185915c",
                    displayName: "Zion Lee ",
                    id: "bjL5Vr7KatahpwlHEk1pX0mtGQ32",
                    internshipRole: "student",
                },
                {
                    avatar: "https://firebasestorage.googleapis.com/v0/b/bazookka-app.appspot.com/o/profile_pictures%2Fprofile%2FPwHZbaTAbMY3x8NpoBWw2jxaBTm1%2FcroppedCover.jpeg?alt=media&token=c168d4b9-a2ec-4d2b-a824-a1dd3407c56c",
                    displayName: "Sam Bellamy",
                    id: "PwHZbaTAbMY3x8NpoBWw2jxaBTm1",
                    internshipRole: "student",
                },
                {
                    avatar: "https://firebasestorage.googleapis.com/v0/b/bazookka-app.appspot.com/o/profile_pictures%2Fprofile%2FS85kOGAA9gOqFOo9US1WyXRIzzW2%2FcroppedCover.jpeg?alt=media&token=46c8d3f6-9c6e-40ac-b6ef-2c0310a0494c",
                    displayName: "Kaci Kling",
                    id: "S85kOGAA9gOqFOo9US1WyXRIzzW2",
                    internshipRole: "teacher",
                },
            ],
            startDate: 1648497820101,
            tiny: "test ismail ghedamsi",
            title: "test ismail ghedamsi",
            type: 'group',
        },
    ]

    const ModeContainer = styled.div`
        display:grid;
        grid-template-columns:repeat(3, 1fr);
        gap:10px;
        margin:10px 0;
    `

    const ModeButton = styled.div`
        cursor:pointer;
        border-radius:5px;
        padding:10px;
        text-align:center;
        text-transform: capitalize; 
        background-color: var(--bazookka-darkblue);
        color:var(--bazookka-white);

        &:hover{
            background-color: var(--bazookka-blue);
            color: var(--bazookka-black);
        }

        &.selected{
            background-color: var(--bazookka-green);
            color:var(--bazookka-white);
        }   
    `
    const location = useLocation();
    const slug = useParams();
    const history = useHistory();
    const [internships,setInternships] = useState([])
    const {me, langChoice, globalId, dispatchModal} = useContext(MyContext)
    const {t} = useTranslation();
    const [admin, setAdmin] = useState(false);
    const [student, setStudent] = useState(null)
    const [mode, setMode] = useState('individual');

    useEffect(()=>{
        if(globalId){
            if(mode === 'individual'){
                activeJobs.where(`relatedPeoples.${globalId}`, '==', true)
                .onSnapshot( data => {
                    setInternships(data.docs.map( intr => {
                        return {id: intr.id, ...intr.data()}
                    }))
                })
            }

            if(mode === 'group'){
                setInternships(fakeInternshipGroups);
            }
            isAdmin(me?.select?.id).then( v => setAdmin(v))
        }
    },[globalId, me])

    useEffect(() => {
        console.log("INTERNSHIPS", internships);
    }, [internships]);

    useEffect(() => {
        if(location && location.pathname.includes("my-internships-groups")){
            setMode('group')
        }
    }, [location])

    const handleModeClick = (mode) => {
        setMode(mode);
        if(mode === 'individual'){
            history.push("/me/my-internships");
        }else{
            history.push("/me/my-internships-groups");
        }
    }

    return (
        <div className="flex flex-col w-[100%]">
            <h1 style={{textTransform: "uppercase", fontSize: "1.5rem", textAlign: "center"}}>{t('overlay.myInternships')}</h1>
            {admin && (
                <Link to={`/me/my-internships/create`}>
                    <button className="button is-fullwidth"><i className="mdi mdi-plus"></i>{t('general.create')}</button>
                </Link>
            )}
            <ModeContainer>
                <ModeButton className={mode === 'individual' ? 'selected' : ''} onClick={()=>{handleModeClick('individual')}}>{t('general.interships')}</ModeButton>
                <ModeButton className={mode === 'group' ? 'selected' : ''} onClick={()=>{handleModeClick('group')}}>{t('general.intershipsgroups')}</ModeButton>
            </ModeContainer>
            {internships.length === 0 && (
                <>
                    <div style={{textAlign: "center", textTransform: "capitalize"}}>{t('general.nocontent')}</div>
                    <img src="assets/old/noitems.svg" width="80%" style={{margin: "auto", display: "block"}}/>
                </>
            )}
            {mode === 'individual' && (
                <SingleInternshipList internships={internships} admin={admin} />
            )}
            {mode === 'group' && slug && (
                <GroupInternshipList internships={fakeInternshipGroups} admin={admin} />
            )}
            {mode === 'group' && !slug && (
                <InsideGroupInternshipList internships={fakeInternshipGroups} admin={admin} />
            )}
            {internships.length > 30 && (
                <div className=" load-more-wrap has-text-centered">
                    <a href="#" className="load-more-button">{t('general.loadmore')}</a>
                </div>
            )}

        </div>
    )
};

