import styled from "styled-components"

const LinkButton = styled.button`
background: none!important;
border: none;
padding: 0!important;
/*optional*/
font-family: arial, sans-serif;
/*input has OS specific font-family*/
color: #069;
text-decoration: none;
cursor: pointer;
display: block;
font-size: xx-large;
margin: 0 auto;
font-weight : bold;
`
export default LinkButton