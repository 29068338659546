import styled from 'styled-components'

const LoaderContainer = styled.div`
    position:absolute;
    opacity: 1;
    width: 100%;
    height:100%;
    overflow:hidden;
    background-color: rgba(250,250,250,1);
    z-index: 999998;
    transition: -webkit-opacity 0.35s ease-out;
    -webkit-transition: opacity 0.35s ease-out;
    transition: opacity 0.35s ease-out;
    transition: opacity 0.35s ease-out, -webkit-transform 0.35s ease-out;
    will-change: opacity;

  &.small{
    position:static;
    background-color:transparent;
    overflow:visible;
    width:auto;
    height:auto;

    &:before, &:after{
      position:static;
    }

    &:before{
      margin:0;
    }

    &:after{
      margin-top: -130px;
      margin-left: -70px;
    }
  }

  &:before, &:after{
    position: relative;
    top: 50%;
    left: 50%;
    display: block;
    border-radius: 100%;
    content: "";
    z-index: 999999;
  }
  
  &:after {
    background: ${props => props.color};
    border: ${props => props.color};
    margin-top: -130px;
    margin-left: -100px;
    width: 200px;
    height: 200px;
    background-color: rgba(61,112,178,0.5);
    border: 3px solid rgba(61,112,178,0.75);
    -webkit-animation: page-loader-inner 1.5s infinite ease-out;
    animation: page-loader-inner 1.5s infinite ease-out;
  }

  &:before {
    background: ${props => props.color};
    border: ${props => props.color};
    margin-top: -30px;
    margin-left: -30px;
    width: 60px;
    height: 60px;
    background-color: #3d70b2;
    -webkit-animation: page-loader-outer 1.5s infinite ease-in;
    animation: page-loader-outer 1.5s infinite ease-in;
  }
`

const Loader = ({small = false}) => {
    const color = [
        '#00d794',
        '#eb5264',
        '#1f9dd9',
        '#ffca06'
    ]

    const smallClass = small ? "small" : "";

    return (
        <LoaderContainer className={`loaderAnim ${smallClass}`} color={color[Math.floor(Math.random() * 4)]} ></LoaderContainer>
    )
}

export default Loader;
