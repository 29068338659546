import React from 'react'


const ShopHeader = props => {
    return (
        <React.Fragment>
            <div className="header-inner" style={{paddingTop: 100}}>
                <div className="store-block">
                    <div className="img-container">
                        <img src="assets/img/icons/logos/store.svg" alt=""/>
                            <div className="follow-badge is-hidden">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="feather feather-check">
                                    <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                            </div>
                    </div>
                    <div className="store-meta">
                        <h3>Name of the school</h3>
                        <span style={{display: 'none'}}>Quick fashion for everyone</span>
                    </div>
                </div>

            </div>
            <div className="store-tabs">
                <a data-tab="products-tab" className="tab-control is-active">Products</a>
                <div className="store-naver"></div>
            </div>
        </React.Fragment>
    )
}

const StoreSectionItems = props => {
    const products = [
        {
            name: 'Free',
            desc: '',
            price: 0
        },
        {
            name: 'small',
            desc: '',
            price: 25
        },
        {
            name: 'medium',
            desc: '',
            price: 300
        },
        {
            name: 'premium',
            desc: '',
            price: 500
        },
        {
            name: 'unlimited',
            desc: '',
            price: 1000
        },
    ]
    return (
        <div className="store-sections" style={{paddingBottom: 100}}>
            <div className="container">
                <div id="products-tab" className="store-tab-pane is-active">
                    <div className="columns is-multiline">
                        { products.map((product, index)=> {
                            return (
                                <div key={index} className="column is-one-fifth-fullhd is-one-quarter-widescreen is-one-third-desktop is-one-third-tablet is-half-mobile">
                                    <div className="product-card" data-name="Spring Red Dress" data-price="44.00"
                                         data-colors="true" data-variants="true" data-path="assets/img/products/1">
                                        <a className="quickview-trigger">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-more-horizontal">
                                                <circle cx="12" cy="12" r="1"></circle>
                                                <circle cx="19" cy="12" r="1"></circle>
                                                <circle cx="5" cy="12" r="1"></circle>
                                            </svg>
                                        </a>
                                        <div className="product-image">
                                            <img src="assets/img/svg/planifications.svg" alt="" />
                                        </div>
                                        <div className="product-info">
                                            <h3>{product.name}</h3>
                                            <p>A beautiful dress for you best evenings and important dates</p>
                                        </div>
                                        <div className="product-actions">
                                            <div className="right">
                                                <a className="button is-solid accent-button raised">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-shopping-cart">
                                                        <circle cx="9" cy="21" r="1"></circle>
                                                        <circle cx="20" cy="21" r="1"></circle>
                                                        <path
                                                            d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                                    </svg>
                                                    <span>${product.price}</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}


const Plans = props => {
    return (
       <div id="shop-page" className="shop-wrapper">
           <div className="shop-header">
               <div className="container">
                    <ShopHeader  />
                    <StoreSectionItems />
               </div>
           </div>
       </div>
    )
}

export default Plans;
