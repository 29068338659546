import React, {useContext, useState, useEffect} from 'react';
import {postsDoc} from "../firestore/firestoreService";
import TileComponent from "../components/TileComponent";
import {Link, useParams} from 'react-router-dom';
import {MyContext} from "../stores";
import Avatar from 'react-avatar';
import AttachmentList from '../components/AttachmentList';
import {fr} from 'date-fns/locale';
import {formatDistance} from 'date-fns';
import CommentsSection from '../components/Comments/CommentsSection';
import Loader from "../components/Loader";
import styled from 'styled-components';
import {WrappedPostContent} from "./MyInternship"
const Post = () => {
    const {slug} = useParams()
    const {langChoice} = useContext(MyContext)
    const [PostData, setPostData] = useState({})

    useEffect(()=> {
        if(slug) {
            postsDoc.doc(slug).onSnapshot(doc => {
                setPostData(doc.data())
            });
        }
    }, [slug])

const PostWrapper = styled.div`
    width: 800px;
    margin: -10rem auto 3rem auto;
    border:1px solid #e8e8e8; 
    padding: 10px 10px;
  
`

    const ContentWrap = styled.div`
        min-height:490px;
        padding-bottom:20px;
    `

    if(!PostData || Object.keys(PostData).length === 0){
        return (<Loader/>)
    }

    return (
        <WrappedPostContent className="stories-view">
                <div className="event-page-wrapper">
                    <div className="event-cover">
                        <img className="cover-image" src="assets/old/wallpaper.png"
                                                      data-demo-src="assets/img/demo/unsplash/55.jpg" alt=""/>
                    </div>
                    <PostWrapper className="card is-post">
            <TileComponent label={PostData.type} ico={<i className="mdi mdi-message-bulleted"></i>} margin={true}/>

            <ContentWrap className="content-wrap">

                <div className="card-heading">

                    <div className="user-block">
                        <div className="image">
                            <Avatar name={PostData.author.displayName} size={38} round={true} alt={PostData.author.displayName}/>
                        </div>
                        <div className="user-info">
                            <a >{PostData.author.name}</a>
                            <span className="time">{formatDistance(new Date(PostData.date), new Date(), { addSuffix: true, locale: langChoice === 'fr'? fr : null })}</span>
                        </div>
                    </div>

                    <div className="dropdown is-spaced is-right is-neutral dropdown-trigger">
                        <div>
                            <div className="button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                     viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                     strokeLinecap="round" strokeLinejoin="round"
                                     className="feather feather-more-vertical">
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="12" cy="5" r="1"></circle>
                                    <circle cx="12" cy="19" r="1"></circle>
                                </svg>
                            </div>
                        </div>
                        <div className="dropdown-menu" role="menu">
                            <div className="dropdown-content">
                                <a >
                                    <div className="media">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                             strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-bookmark">
                                            <path
                                                d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                                        </svg>
                                        <div className="media-content">
                                            <h3>Bookmark</h3>
                                            <small>Add this post to your bookmarks.</small>
                                        </div>
                                    </div>
                                </a>
                                <a className="dropdown-item">
                                    <div className="media">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                             strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-bell">
                                            <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                            <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                        </svg>
                                        <div className="media-content">
                                            <h3>Notify me</h3>
                                            <small>Send me the updates.</small>
                                        </div>
                                    </div>
                                </a>
                                <hr className="dropdown-divider"/>
                                <a >
                                    <div className="media">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                             strokeWidth="2" strokeLinecap="round"
                                             strokeLinejoin="round" className="feather feather-flag">
                                            <path
                                                d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
                                            <line x1="4" y1="22" x2="4" y2="15"></line>
                                        </svg>
                                        <div className="media-content">
                                            <h3>Flag</h3>
                                            <small>In case of inappropriate content.</small>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="card-body">

                    <div className="post-text">
                        <p>{PostData.text}</p>
                    </div>

                    <div className="post-actions">
                        <div className="like-wrapper">
                            <Link  to={`/fr/feed/${slug}`}>
                                <i className="mdi mdi-eye not-liked bouncy"></i>
                                <i className="mdi mdi-eye is-liked bouncy"></i>
                                <span className="like-overlay"></span>
                            </Link>
                        </div>
                    </div>
                    {PostData.attachments && PostData.attachments.length > 0 &&
                        <div className="attachments-container">
                            <AttachmentList attachments={PostData.attachments} metas={['name']} isHorizontal />
                        </div>
                    }
                </div>

                <div className="card-footer" style={{borderTop:'1px solid #e8e8e8', padding:'16px 0', margin:'0 16px'}}>
                    <div className="social-count">
                        <div className="shares-count">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                 strokeLinecap="round" strokeLinejoin="round"
                                 className="feather feather-link-2">
                                <path
                                    d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3"></path>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                            <span>{PostData.attachments ? PostData.attachments.length : 0}</span>
                        </div>
                        <div className="comments-count">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                 strokeLinecap="round" strokeLinejoin="round"
                                 className="feather feather-message-circle">
                                <path
                                    d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
                            </svg>
                            <span>{PostData.commentsCount ? PostData.commentsCount : 0}</span>
                        </div>
                    </div>
                </div>
                <CommentsSection postId={slug} commentsCount={PostData.commentsCount} previewComments={PostData.latestComments}/>
            </ContentWrap>
        </PostWrapper>
                </div>
        </WrappedPostContent>
    )
}

export default Post
