import React, {useContext} from 'react'
import {format} from 'date-fns'
import {Link} from "react-router-dom";
import TileComponent from "./TileComponent";
import {fr} from 'date-fns/locale'
import {MyContext} from "../stores";
import LikeButton from "../components/LikeButton";
import styled from 'styled-components';

const SocialWrapper = styled.div`
    .social-count{
        margin-left:0;
    }
`

export default function EventCard({data}) {
    const {langChoice} = useContext(MyContext)
    return (
        <div className="card is-post">
            <TileComponent label={data.type} margin={true} ico={<i className="mdi mdi-calendar-check"></i>}/>
            <div className="content-wrap">

                <div className="card-heading">
                    <div className="user-block">
                        <div className="image">
                            <img src={'assets/old/wallpaper.png'} data-demo-src="assets/img/avatars/jenna.png"
                                 data-user-popover="0" alt="" />
                        </div>
                        <div className="user-info">
                            <Link to={`/events/${data.id}`}>{data.title}</Link>
                            {data.startDate &&
                                <span className="time">{format(data.startDate, 'd MMMM, yyyy hh:mm aa', {locale: langChoice === 'fr' && fr } )}</span>
                            }
                        </div>
                    </div>
                </div>



                <div className="card-body">

                    <div className="post-text">
                        <p>
                            {data.tiny}
                        </p><p>
                    </p></div>

                    <div className="post-image">
                        <Link
                            to={`/events/${data.id}`}
                            data-fancybox="profile-post3" data-lightbox-type="comments"
                           data-thumb="/assets/img/demo/video/dress.jpg" href="#myVideo">
                            <img src={data.cover || 'assets/old/wallpaper.png'}
                                 data-demo-src="/assets/img/demo/video/dress.jpg" alt="" />
                        </Link>

                        <LikeButton postId={data.id} likes={data.likedBy} />
                    </div>
                </div>
            </div>



            <div className="comments-wrap is-hidden">

                <div className="comments-heading">
                    <h4>Comments
                        <small>(9)</small></h4>
                    <div className="close-comments">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </div>
                </div>


                <div className="card-footer">
                    <div className="media post-comment has-emojis">

                        <div className="media-content">
                            <div className="field">
                                <p className="control">
                                    <textarea className="textarea comment-textarea" rows="5"
                                              placeholder="Write a comment..." id="post-comment-textarea-3"></textarea>
                                </p>
                            </div>

                            <div className="actions">
                                <div className="image is-32x32">
                                    <img className="is-rounded" src="https://via.placeholder.com/300x300"
                                         data-demo-src="assets/img/avatars/jenna.png" data-user-popover="0" alt="" />
                                </div>
                                <div className="toolbar">
                                    <div className="action is-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-at-sign">
                                            <circle cx="12" cy="12" r="4"></circle>
                                            <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                                        </svg>
                                    </div>
                                    <div className="action is-emoji" id="post-comment-button-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-smile">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                            <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                            <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                        </svg>
                                    </div>
                                    <div className="action is-upload">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-camera">
                                            <path
                                                d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
                                            <circle cx="12" cy="13" r="4"></circle>
                                        </svg>
                                        <input type="file" />
                                    </div>
                                    <a className="button is-solid primary-button raised">Post Comment</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}
