import React, { useContext, useState, useEffect } from "react";
import { MyContext } from "../stores";
import _ from "lodash"
import {
  formTemplates,
  activeForms,
  users, groupsDoc,
} from "../firestore/firestoreService";

import { useTranslation } from "react-i18next";
import { GlobeIco } from "../ico";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";


const ListStyled = styled.div`
  h2 {
    font-size: 2rem;
    text-align: center;
  }
  .list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    height: 30px;
    width: 30px;
    color: black;
    border-radius: 50%;
    &:hover {
      color: var(--bazookka-yellow);
      background-color: white;
      border-radius: 50%;
      transition: all 100ms;
    }
  }
  .card {
    display: grid;
    grid-template-columns: 1fr 40px 40px;
    border: 1px solid #e8e8e8;
    align-items: center;
    padding: 10px;
    background-color: white;
    width: 100%;
    > div {
      cursor: pointer;
      i {
        font-size: 1.5rem;
      }
    }
    &:hover {
      background-color: var(--bazookka-pink);
      color: white;
      border-color: var(--bazookka-pink);
      filter: drop-shadow(1px 1px 1px rgba(0,0,0, 0.6));
      transition: background-color 300ms;
    }
  }
`;

const PostWrapper = styled.div`
  .box-heading{
    margin-bottom:25px;
  }
`

export const FormDashboard = () => {
  const { me, globalId } = useContext(MyContext);
  const {t} = useTranslation()
  const [templates, setTemplates] = useState([]);
  const [options, setOptions] = useState([]);
  const [formUsers, setFormUsers] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [api, setApi] = useState(true);
  const [groups, setGroups] = useState([]);


  //added for testing state

  //This is probably wrong, infinite calls?
  useEffect(async () => {
    let cleanUpGroup = null
    let cleanUpUser = null
    let formTemplateCleanUp = null
    if (!apiCall && globalId && me) {
      cleanUpGroup = groupsDoc.where("school", "==", me.select.id)
          .get()
          .then(snap => {
            setGroups(snap.docs.map( doc => {
              return {id: doc.id, ...doc.data(), label: doc.data().name, value: doc.id}
            }))
            setApi(false)
          }).catch( error => alert(error))

          formTemplateCleanUp = formTemplates.where("adminId", "array-contains", globalId).get().then((querySnapshot) => {
        setTemplates(querySnapshot.docs.map( doc => {
          return {id: doc.id, ...doc.data()}
        }))
      });

      cleanUpUser = users.get().then((querySnapshot) => {
        setFormUsers(querySnapshot.docs.map( doc => {
          return {id: doc.id, ...doc.data()}
        }))

        setOptions(querySnapshot.docs.map( doc => {
          return {id: doc.id, label: doc.data().displayName, value: doc.id}
        }));
      });
      setApiCall(true);
    }
    return () => {
      cleanUpGroup()
      cleanUpUser()
      formTemplateCleanUp()
    }
  }, [globalId, me]);

  return (
      <PostWrapper className="posts-wrapper">
        <div id="profile-timeline-posts" className="box-heading">
          <h4>{t('general.mngforms')}</h4>
          <div className="button-wrap"><Link to={`/formcreator`} className="button is-active">
            {t('general.create')}
          </Link></div>
        </div>
        <ListStyled>
          <TemplateList
            templates={templates}
            formUsers={formUsers}
            options={options}
            groups={groups}
          ></TemplateList>
        </ListStyled>
    </PostWrapper>
  );
};

export const FormUserDashboard = () => {
  const { globalId, me } = useContext(MyContext);
  const [myForms, setMyForms] = useState([]);
  const [apiCall, setApiCall] = useState(false);

  useEffect(async () => {

    const [byUser, byGroup] = await Promise.all([
        activeForms
            .where("authorizedUsers", "array-contains", globalId || "")
            .get(),
        activeForms
            .where("groupId", "array-contains-any", me.groupsId)
            .get()
    ])
    setMyForms(()=> {
       let temps = [];
       temps = !byUser.empty ? byUser.docs.map(doc => {
           return {id: doc.id, ...doc.data()}
       }) : []

       temps =  !byGroup.empty ? [...temps, ...byGroup.docs.map(doc => {
           return {id: doc.id, ...doc.data()}
       })] : [...temps]

       return _.uniqBy(temps, e => {
           return e.id
       })
    })

  }, [globalId, me]);

  return (
    <div className="view-wrapper">
      <ListStyled>
        <ActiveFormList myForms={myForms}></ActiveFormList>
      </ListStyled>
    </div>
  );
};

const TemplateList = ({ templates, formUsers, options, groups }) => {
  return (
    <div className="list">
        {templates.map((template, index) => {
          return (
            <FormCard
              key={index}
              form={template}
              formUsers={formUsers}
              options={options}
              groups={groups}
            ></FormCard>
          );
        })}
    </div>
  );
};

const ActiveFormList = ({ myForms }) => {
  const {t} = useTranslation()
  return (
    <div className="list">
      <h2>{t('general.myforms')}</h2>
      <div className="content">
        {myForms.map((myForm, index) => {
          return <ActiveFormcard key={index} form={myForm}></ActiveFormcard>;
        })}
      </div>
    </div>
  );
};

const FormCard = ({ form, formUsers, options, groups }) => {
  const { dispatchModal } = useContext(MyContext);
  const {t} = useTranslation()
  const openModal = () => {
    dispatchModal({
      type: "OPEN_MODAL",
      payload: {
        modalType: "FormInviteModal",
        modalProps: {
          title: t("formCreator.share"),
          ico: <GlobeIco />,
          template: form,
          formUsers: formUsers,
          options: options,
          groups,
          closeOut: () => dispatchModal({ type: "CLOSE_MODAL" }),
        },
      },
    });
  };

  return (
    <div className="card" >
      <span style={{ display: "inline" }}>{form.name || form.title}</span>
      <Link to={`/formcreator/${form.id}`} className="circle">
        <i className="mdi mdi-pencil"></i>
      </Link>
      <a className="circle" onClick={() => openModal()}>
        <i className="mdi mdi-share"></i>
      </a>
    </div>
  );
};

const ActiveFormcard = ({ form }) => {
  const history = useHistory();

  const openForm = (form) => {
    history.push(`/form/${form.id}`);
  };

  return (
    <div className="card">
      <span style={{ display: "inline" }}>{form.formContent.title || form.formContent.name}</span>
      <div onClick={() => openForm(form)}><i className="mdi mdi-eye-outline"></i></div>
    </div>
  );
};
