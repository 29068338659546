import React, {useContext} from 'react'
import {MyContext} from "../stores";


export const SearchBar = props => {
    const {lang, langChoice} = useContext(MyContext)
    let selected = <lang langChoice=""></lang>
    return (
        <div className="box-heading">
            <div className="dropdown friends-dropdown is-spaced is-neutral dropdown-trigger">
                <div>
                    <div className="button">
                        <span>All {!props.person ? 'Schools': 'Students or Teachers'}</span>
                        <i data-feather="chevron-down"></i>
                    </div>
                </div>
                <div className="dropdown-menu" role="menu">
                    <div className="dropdown-content">
                        <a >
                            <div className="media">
                                <i data-feather="heart"></i>
                                <div className="media-content">
                                    <h3>Close Friends</h3>
                                    <small>Your closest friends list.</small>
                                </div>
                            </div>
                        </a>
                        <a className="dropdown-item">
                            <div className="media">
                                <i data-feather="bell"></i>
                                <div className="media-content">
                                    <h3>Followed</h3>
                                    <small>Friends you are following.</small>
                                </div>
                            </div>
                        </a>
                        <a className="dropdown-item">
                            <div className="media">
                                <i data-feather="coffee"></i>
                                <div className="media-content">
                                    <h3>Work relations</h3>
                                    <small>Your work relations.</small>
                                </div>
                            </div>
                        </a>
                        <hr className="dropdown-divider" />
                        <a className="dropdown-item modal-trigger" data-modal="albums-modal">
                            <div class=" media">
                                <i data-feather=" mail"></i>
                                <div class=" media-content">
                                    <h3>Friend Requests</h3>
                                    <small>Your pending friend requests.</small>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class=" control heading-search">
                <input type=" text" class=" input is-rounded" placeholder={`Search ${props.person ? 'Students or teachers': 'Schools'}...`} />
                <div class=" search-icon">
                    <i data-feather=" search"></i>
                </div>
            </div>
        </div>
    )
}
