import React, { useContext, useState, useEffect, useRef } from "react";
import { Formik, Form } from 'formik'
import { MyContext } from "../stores";
import { InputDate, InputText, SwitchBox } from "../components/FormElements";
import styled from "styled-components"
import { Multiselect } from 'multiselect-react-dropdown'
import { useTranslation } from "react-i18next";
import { useCatReducer } from "../stores/categories";
import * as Yup from 'yup';
import { ErrorStyle } from "../helper/Styled"
import parseDate from '../helper/form/parseDate';
import { ErrorContainer } from "../components/FormManager/ErrorContainer";
import axios from "axios";
import { omit } from 'lodash';
import Swal from "sweetalert2";



export const SubmitContainer = styled.div`
   display: flex;
   flex-direction: row;
   margin-top:20px;
   
   button, a{
       width:100%;
   }
`
export default function EditJobForm({setOpen, job, mutateAdminJob, type, isNew, groups }) {
    
    const {langChoice, globalIdSql, organizationId } = useContext(MyContext)
    const [cat, setCat] = useState([])
    const { t } = useTranslation()
    const [categories, dispatchAll] = useCatReducer()
    const [categoriesSelection, setCategoriesSelection] = useState({
        disploma: [],
        categories:[],
        qualities:[],
        skills:[],
        teamvalues:[],
        teamvaluesCompany:[],
        workactivities:[],
        workActivitiesCompany:[],
        contracttypes:[],
        supervisontype:[],
        supervisionTypeCompany:[],
        mot:[],
        motCompany:[],
        benefits:[],
        benefitsCompany:[],
        leader:[],
        leaderCompany:[],
        field:[],
    })

    let selectedCategories = []
    if (!isNew && job && job.meta) {
        selectedCategories = omit(job.meta, ["active", "author_id", "category", "date", "endDate", "groups", "needed", "value"])
    }

    function validation(v) {
        try {
            if (v.hasOwnProperty("blocks")) {
                return JSON.stringify(v)
            }
        } catch (e) { }

        return `{"blocks":[{"key":"7po5","text":"Type text","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":6,"length":5,"style":"BOLD"}],"entityRanges":[],"data":{}},{"key":"5sea5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}} `
    }

    const validate = !job ?
        Yup.object({
            title: Yup.string()
                .min(3, t('general.nbcharactersmin'))
                .required(t('general.jobtitlerequired')),
            needed: Yup.number().min(1),
            tiny: Yup.string()
                .max(140, t('general.nbcharacters140'))
                .required(t('general.resumerequired')).default(''),
            date: Yup.date().transform(parseDate).min(new Date(), t('validation.mindate')).required(t('validation.required')),
            endDate: Yup.date().transform(parseDate).required(t('validation.required')),
        })
        :
        Yup.object({
            title: Yup.string()
                .min(3, t('general.nbcharactersmin'))
                .required(t('general.jobtitlerequired')),
            needed: Yup.number().min(1),
            tiny: Yup.string()
                .max(140, t('general.nbcharacters140'))
                .required(t('general.resumerequired')).default(''),
            date: Yup.date().transform(parseDate).required(t('validation.required')),
            endDate: Yup.date().transform(parseDate).required(t('validation.required')),
        });


    const initialValues = isNew ? {
        title: '',
        author_id: globalIdSql,
        tiny: '',
        needed: 1,
        groups: [],
        desc: '',
        value: '',
        type: "jobs",
        active: false,
        date: new Date(new Date().setDate(new Date().getDate() + 1)), //tomorrow
        endDate: new Date(new Date().setDate(new Date().getDate() + 2)), //after tomorrow
        category: [{}],
        disploma: [],
        categories:[],
        qualities:[],
        skills:[],
        teamvalues:[],
        teamvaluesCompany:[],
        workactivities:[],
        workActivitiesCompany:[],
        contracttypes:[],
        supervisontype:[],
        supervisionTypeCompany:[],
        mot:[],
        motCompany:[],
        benefits:[],
        benefitsCompany:[],
        leader:[],
        leaderCompany:[],
        field:[],
    } : {
        title: job.title,
        author_id: job.author_id,
        tiny: job.tiny,
        needed: job.needed,
        groups: job.meta && job.meta.hasOwnProperty('groups') ? job.meta.groups : [],
        desc: '',
        type: "jobs",
        active: job.meta ? job.meta.active : false,
        date: job.meta ? job.meta.date : new Date(),
        endDate: job.meta ? job.meta.endDate : new Date(new Date().setDate(new Date().getDate() + 1)),
        disploma: [],
        categories:[],
        qualities:[],
        skills:[],
        teamvalues:[],
        teamvaluesCompany:[],
        workactivities:[],
        workActivitiesCompany:[],
        contracttypes:[],
        supervisontype:[],
        supervisionTypeCompany:[],
        mot:[],
        motCompany:[],
        benefits:[],
        benefitsCompany:[],
        leader:[],
        leaderCompany:[],
        field:[],


    }

    const onSubmit = async (values) => {

        const meta = omit(values, ["tiny", "title", "type", "desc", "companyPhone", "comapnyLocation", "author_id"])
        values.meta = {...meta,...categoriesSelection }
        values.organisation_id = organizationId
        values.author_id = globalIdSql
        values.user_id = globalIdSql
        if (!isNew) {
            values.id = job.id
        }
        axios.put(`https://api.bazookka.com/posts/jobUpsert`, values).then(res => {console.log(res);mutateAdminJob() })
        mutateAdminJob(`https://api.bazookka.com/posts/job/admin/${globalIdSql}`,j => [...j, values],{ optimisticData: values, rollbackOnError: true })
        setOpen(false)
        isNew ? Swal.fire(t("settings.jobs.created")) :  Swal.fire(t("settings.jobs.updated"))
    }

    useEffect(() => {
        dispatchAll({ type: langChoice === 'fr' ? 'ALL_SET_FR' : 'ALL_SET_EN' })
    }, [])

    const formikRef = useRef()

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnBlur={true} validationSchema={validate}>

            {({ values, setFieldValue, handleSubmit, errors, isSubmitting }) => {
                return (
                    <Form>
                        <div className="settings-form-wrapper">
                            <div className="settings-form">
                                <div style={{ width: '95%' }}>
                                    <div className="field">
                                        <InputText
                                            label={t('jobs.title')}
                                            value={values.title}
                                            name="title" />
                                        <ErrorStyle component='div' name='title' />
                                    </div>
                                    <div className="field">
                                        <InputText
                                            label={langChoice === 'fr' ? 'Petit résumé' : 'Little summary'}
                                            value={values.tiny}
                                            name="tiny" />
                                        <ErrorStyle component='div' name='tiny' />
                                    </div>
                                    <div className="field">
                                        <label>
                                            {t('general.whoshouldseethepost')}
                                            <Multiselect
                                                options={groups}
                                                selectedValues={!isNew && job.meta ? job.meta.groups : []}
                                                name={'groups'}
                                                emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                displayValue="name"
                                                onSelect={v => {
                                                    setFieldValue('groups', v)
                                                }}
                                                onRemove={v => {
                                                    setFieldValue('groups', v)
                                                }}
                                            >
                                            </Multiselect>
                                        </label>
                                        <ErrorContainer name="groups" component="div" />
                                    </div>

                                    {categories && categories.map((cat, i) => {
                                        let selectedCategoriesMultiSelectFormat = []
                                        if (cat[0] in selectedCategories) {
                                            selectedCategories[cat[0]].forEach((e) => {
                                                selectedCategoriesMultiSelectFormat.push({"key":e.key, "value": t(`${e.key}`) })
                                            })

                                        }
                                        return (
                                            <div className="field" key={i}>
                                                <label>{t(cat[0])}
                                                    <div className="control">
                                                        <Multiselect
                                                            options={cat[1] || []}
                                                            emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                            displayValue="value"
                                                            placeholder={t('overlay.select')}
                                                            selectedValues={!isNew ? selectedCategoriesMultiSelectFormat : []}
                                                            onSelect={(v) => {
                                                                let modifiedSelectedCategory = {...categoriesSelection}
                                                                modifiedSelectedCategory[cat[0]] = v
                                                                setCategoriesSelection(modifiedSelectedCategory)
                                                            }}
                                                            onRemove={(v) => {
                                                                let modifiedSelectedCategory = {...categoriesSelection}
                                                                modifiedSelectedCategory[cat[0]] = v
                                                                setCategoriesSelection(modifiedSelectedCategory)
                                                            }}

                                                        >
                                                        </Multiselect>
                                                    </div>
                                                </label>
                                            </div>
                                        )
                                    })}

                                    <div className="field">
                                        <InputDate
                                            label={langChoice === 'fr' ? 'Date de début' : 'Start date'}
                                            value={new Date(values?.date || 0)}
                                            name="date"
                                        />
                                        <ErrorStyle component='div' name='date' />
                                    </div>

                                    <div className="field">
                                        <InputDate
                                            label={langChoice === 'fr' ? 'Date de fin' : 'End date'}
                                            value={new Date(values?.endDate || 0)}
                                            name="endDate"
                                        />
                                        <ErrorStyle component='div' name='endDate' />
                                    </div>
                                    <div className="field">
                                        <SwitchBox
                                            label={langChoice === 'fr' ? 'Actif' : 'Active'}
                                            name="active"
                                        />
                                        <ErrorStyle component='div' name='active' />
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/*<RichTextEditor />
                            <ThemeProvider theme={createTheme({})}>
                                <MUIRichTextEditor
                                    defaultValue={validation(values.desc)}
                                    label={t("general.typeSOmething")}
                                    onSave={ async state => {
                                        console.log("WAS TRIGGER =======================>")
                                        console.log(state)
                                        console.log("WAS TRIGGER =======================>")
                                        setFieldValue("desc", JSON.parse(state))
                                        setContent(JSON.parse(state))
                                        await postsDoc.doc(values.id).update({desc: JSON.parse(state)})
                                    }}
                                    ref={rte}
                                    controls={["bold", "italic", "underline", "quote", "clear"]}
                                />
                            </ThemeProvider> */}

                        <SubmitContainer>
                            <button type="submit" className="button is-small is-link" disabled={isSubmitting}>{t('general.save')}</button>
                        </SubmitContainer>
                    </Form>
                )
            }}
        </Formik>
    )
}