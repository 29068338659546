import React, {useContext, useState} from 'react'
import styled from 'styled-components'
import {MyContext} from '../stores/index'
import {users} from '../firestore/firestoreService'
import firebase from '../config/firebase'

const StyleModal = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    z-index: 10000;
    background-color: rgba(255,255,255,.7);
    display: flex;
    justify-content: center;
    align-items: center;
    
    .modal-box {
      display: block;
      animation-name: grow-box;
      animation-duration: 0.4s;
      animation-timing-funvyion: ease-in-out;
      
      @keyframes grow-box {
      0% {opacity: 0.2;}
      25% {opacity: 0.4;}
      50% {opacity: 0.6;}
      75% {opacity: 0.8;}
      100% {
        opacity: 1;
        width: 300px;
     
        }
      }
      
      
        
    }
`
export const SimpleModal = () => {
    const {inviteUser, setInvite} = useContext(MyContext)
    const defaultValue = {
        email: '',
        cat: null,
    }
    const [sendInvite, setSendInvite] = useState(defaultValue)
    const onClose = () => {
        setInvite(false)
    }
    const onSubmit = async (evt) => {
        evt.preventDefault();
        let u = await users.where('email', '==' , sendInvite.email).get()
        if(u.empty){
            firebase.auth().sendSignInLinkToEmail(sendInvite.email,
                {url: 'http://localhost:3004/signin',  handleCodeInApp: true,
                    lang: 'fr'})
            users.add(sendInvite);
        } else {
            firebase.auth().sendSignInLinkToEmail(sendInvite.email,
                {url: 'http://localhost:3004/signin',  handleCodeInApp: true,
                    lang: 'fr'})
        }
    }
    const onChange = evt => setSendInvite({...sendInvite, [evt.target.name]: evt.target.value})
   return (
       <StyleModal>
           <div className="modal-box">

                   <div className="modal-card" style={{width: 300}}>
                       <header className="modal-card-head">
                           <p className="modal-card-title">Invite User</p>
                           <button className="delete" aria-label="close" onClick={onClose} ></button>
                       </header>
                       <section className="modal-card-body">
                           <div className="settings-form-wrapper">
                               <div className="settings-form">
                                   <div className="field field-group">
                                       <label>Email</label>
                                       <div className="control has-icon">
                                           <input type="text" className="input is-fade"
                                                  value={sendInvite.email}
                                                  name="email"
                                                  onChange={onChange} />
                                           <div className="form-icon">
                                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                    strokeLinecap="round" strokeLinejoin="round"
                                                    className="feather feather-mail">
                                                   <path
                                                       d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                                   <polyline points="22,6 12,13 2,6"></polyline>
                                               </svg>
                                           </div>
                                       </div>
                                   </div>
                                   <div className="field field-group">
                                       <label className="material-radio">
                                           <input type="radio" name="group-demo1" checked />
                                               <span className="dot"></span>
                                               <span className="radio-label">Choice 1</span>
                                       </label>
                                       <label className="material-radio">
                                           <input type="radio" name="group-demo1"  />
                                               <span className="dot"></span>
                                               <span className="radio-label">Choice 2</span>
                                       </label>
                                       <label className="material-radio">
                                           <input type="radio" name="group-demo1" />
                                               <span className="dot"></span>
                                               <span className="radio-label">Choice 3</span>
                                       </label>
                                   </div>
                               </div>
                           </div>


                       </section>
                       <footer className="modal-card-foot">
                           <button className="button is-success" onClick={onSubmit}><span>Send</span><span class="icon"><i className="mdi mdi-send"></i></span></button>
                           <button className="button" onClick={onClose}>Cancel</button>
                       </footer>
                   </div>

           </div>
       </StyleModal>
   )
}

export const LangSwitch = () => {
    return (
        <StyleModal>
            <div className="modal-box">
                <div className="modal-card" style={{width: 300, backgroundColor: 'white'}}>
                    <div className="navbar-item is-account drop-trigger has-caret">
                        <div className="account-dropdown">
                            <div className="nav-drop is-account-dropdown is-active">
                                <div className="inner">
                                    <div className="nav-drop-header"><span className="username">Steve Alliance</span><a
                                        >Profile</a></div>
                                    <div className="nav-drop-body account-items"><a id="profile-link"
                                                                                    className="account-item">
                                        <div className="media">
                                            <div className="media-left" style={{width: 45}}>
                                                <div className="image"><img src="https://via.placeholder.com/400x400"
                                                                            data-demo-src="assets/img/avatars/jenna.png" alt="" />
                                                </div>
                                            </div>
                                            <div className="media-content"><h3>Steve Alliance</h3><small>Main account</small>
                                            </div>
                                            <div className="media-right">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                     strokeLinecap="round" strokeLinejoin="round"
                                                     className="feather feather-check">
                                                    <polyline points="20 6 9 17 4 12"></polyline>
                                                </svg>
                                            </div>
                                        </div>
                                    </a>
                                        <hr className="account-divider" /><a className="account-item"  >
                                            <div className="media">
                                                <div className="icon-wrap">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                         strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-settings">
                                                        <circle cx="12" cy="12" r="3"></circle>
                                                        <path
                                                            d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                                                    </svg>
                                                </div>
                                                <div className="media-content"><h3>Settings</h3><small>Access widget
                                                    settings.</small></div>
                                            </div>
                                        </a><a className="account-item">
                                            <div className="media">
                                                <div className="icon-wrap">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                         strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-life-buoy">
                                                        <circle cx="12" cy="12" r="10"></circle>
                                                        <circle cx="12" cy="12" r="4"></circle>
                                                        <line x1="4.93" y1="4.93" x2="9.17" y2="9.17"></line>
                                                        <line x1="14.83" y1="14.83" x2="19.07" y2="19.07"></line>
                                                        <line x1="14.83" y1="9.17" x2="19.07" y2="4.93"></line>
                                                        <line x1="14.83" y1="9.17" x2="18.36" y2="5.64"></line>
                                                        <line x1="4.93" y1="19.07" x2="9.17" y2="14.83"></line>
                                                    </svg>
                                                </div>
                                                <div className="media-content"><h3>Help</h3><small>Contact our support.</small>
                                                </div>
                                            </div>
                                        </a><a className="account-item">
                                            <div className="media">
                                                <div className="icon-wrap">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                         strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-power">
                                                        <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                                                        <line x1="12" y1="2" x2="12" y2="12"></line>
                                                    </svg>
                                                </div>
                                                <div className="media-content"><h3>Log out</h3><small>Log out from your
                                                    account.</small></div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyleModal>
    )
}
