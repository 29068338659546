import React, { useState, useEffect, useContext } from 'react';
// import { BatchHttpLink } from '@apollo/client/link/batch-http'
// import {TestForm} from "./forms";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import Routes from './config/routes'
import io from 'socket.io-client'
import { FullNavBar } from './components/NavBar'
import Footer from './components/Footers'
import MessengerPanel from './components/Messenger'
import MyProvider, {MyContext, useMyProvider} from './stores';
import OverlayMenu from "./components/OverLayMenu";
import { ModalManager } from "./modals";
import { SimpleModal } from "./components/Modals";
import { AppOverlay, OverlayWrapper } from "./components/OtherOvers";
import Businss from './pages/Businesses'
import { isAllowed } from './helper/acl';
import ErrorPage from "./pages/ErrorPage";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Subscription from "./pages/payment/Subscription";
import ReportBugs from "./components/ReportBugs";
import ScrollToTop from "./components/ScrollToTop";
import { PageLoader } from "./components/OtherOvers";
import { positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic"
import { DialogProvider } from "./stores/dialog";
import {SocketProvider} from "./stores/socketProvider";
import { LocalStorageProvider, LocalStorageContext } from "./stores/localStorage";
import './App.css'
import {L10n} from '@syncfusion/ej2-base'
const stripePromise = loadStripe("pk_live_8dq1TV7LPPinH6VEWKOXiqsX003qGQTD3h");

/**
 *
 * comment websocket for demo will have this connected when decision
 * will be taken between dgraph and hasura
 */
// const link = new WebSocketLink({
//     uri: `ws://localhost:4000/`,
//     options: {
//         reconnect: true
//     }
// })
//
// const client = new ApolloClient({
//     uri: 'http://localhost:4000/',
//     cache,
//     link,
// });

function PrivateRoute({ me, roles, children, ...rest }) {
    const [routeAccess, setRouteAccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (me && roles) {
            isAllowed(roles, me.select.id).then((data) => {
                setRouteAccess(data);
                setIsLoading(false);
            });
        }
    }, [me, roles])

    if (isLoading) {
        return (<PageLoader />);
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                routeAccess ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/notallowed",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

const App = () => {
    const [showMessenger, setMessenger] = useState(false)
    const [showMenu, setMenu] = useState(false)
    const options = {
        timeout: 5000,
        position: positions.MIDDLE
    }

    return (
        <Elements stripe={stripePromise}>
            <Router>

                <AlertProvider template={AlertTemplate} {...options}>
                    <DialogProvider>
                        <SocketProvider>
                            <LocalStorageProvider>
                                <MyProvider>
                                    <OverlayWrapper />
                                    <ModalManager />
                                    <AppOverlay />
                                    <ReportBugs />

                                    <LocalStorageContext.Consumer>
                                        {localStorageData => (
                                            <MyContext.Consumer>
                                                {value => {
                                                    L10n.load(
                                                        value.i10nLoadContent
                                                    );

                                                    return (
                                                        <React.Fragment>
                                                            <FullNavBar showMessenger={showMessenger} setMessenger={setMessenger} setMenu={setMenu} showMenu={showMenu}></FullNavBar>
                                                            <ScrollToTop>
                                                                <Switch>
                                                                    {Routes.filter(route => {
                                                                        if (route.component) {
                                                                            return true
                                                                        }
                                                                        return false
                                                                    })
                                                                        .map((route, index) => {
                                                                            if (route.roles && route.roles.length > 0) {
                                                                                return (
                                                                                    <PrivateRoute
                                                                                        me={value.me}
                                                                                        roles={route.roles}
                                                                                        key={index}
                                                                                        path={route.path}
                                                                                        exact={route.exact}
                                                                                        children={route.component}
                                                                                        localStorageData={localStorageData}
                                                                                    />)
                                                                            } else {
                                                                                return (
                                                                                    <Route
                                                                                        key={index}
                                                                                        path={route.path}
                                                                                        exact={route.exact}
                                                                                        children={route.component}
                                                                                        localStorageData={localStorageData}
                                                                                    />)
                                                                            }
                                                                        })}
                                                                    <Route path={`/${value.langChoice}/subscription`} exact>

                                                                        <Subscription user={value.me} />

                                                                    </Route>
                                                                    <Route path={`/${value.langChoice}/test`}>
                                                                        { /* <TestForm /> */}
                                                                        <Businss />
                                                                    </Route>
                                                                    <Route>
                                                                        <ErrorPage />
                                                                    </Route>
                                                                </Switch>
                                                            </ScrollToTop>
                                                            {value.footer && <Footer />}

                                                            {/*<FooterComponent />*/}
                                                            <MessengerPanel showMessenger={showMessenger} setMessenger={setMessenger} />
                                                            <OverlayMenu showMenu={showMenu} items={Routes.filter(item => Array.isArray(item.roles))} />
                                                        </React.Fragment>
                                                    )
                                                }}
                                            </MyContext.Consumer>
                                        )}
                                    </LocalStorageContext.Consumer>

                                    <MyContext.Consumer>
                                        {value => value.inviteUser && <SimpleModal />}
                                    </MyContext.Consumer>
                                </MyProvider>
                            </LocalStorageProvider>
                        </SocketProvider>

                    </DialogProvider>

                </AlertProvider>
            </Router>
        </Elements>
    );
}

export default App
