import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

let firebaseConfig = {
    apiKey: "AIzaSyDt3WTg2A7jTXKGRqyddKgrFJNsOUgS_AE",
    authDomain: "bazookka-app.firebaseapp.com",
    databaseURL: "https://bazookka-app.firebaseio.com",
    projectId: "bazookka-app",
    storageBucket: "bazookka-app.appspot.com",
    messagingSenderId: "183949454211",
    appId: "1:183949454211:web:ee24ef2deee59843e1a4b6",
    measurementId: "G-T96RZ8FPD1"
}

if(window.location.hostname === 'locahost:3005') {
    firebaseConfig = {
        ...firebaseConfig,
        databaseURL: 'http://localhost:9000?ns=emulatorui',
        authDomain: 'localhost:9099'
    }
}


firebase.initializeApp(firebaseConfig)
firebase.firestore()

export default firebase
