import React, {useContext, useState} from "react";
import CheckoutForm from "./CheckoutForm";
import {MyContext} from "../../stores";

const subscriptions = {
    business: {
      monthly: {
        code: process.env.REACT_APP_STRIPE_CODE_BUSINESS_MONTHLY,
        description: 'Abonnement mensuel pour compagnies',
        value: '900'
      },
      yearly: {
        code: process.env.REACT_APP_STRIPE_CODE_BUSINESS_YEARLY,
        description: 'Abonnement annuel pour compagnies',
        value: '10000'
      },
      title: 'Abonnement pour compagnies'
    },
    school: {
      monthly: {
        code: process.env.REACT_APP_STRIPE_CODE_SCHOOL_MONTHLY,
        description: 'Abonnement mensuel pour écoles',
        value: '1535'
      },
      yearly: {
        code: process.env.REACT_APP_STRIPE_CODE_SCHOOL_YEARLY,
        description: 'Abonnement annuel pour écoles',
        value: '17000'
      },
      title: 'Abonnement pour écoles'
    },
    admin_school: {
      monthly: {
        code: process.env.REACT_APP_STRIPE_CODE_SCHOOL_MONTHLY,
        description: 'Abonnement mensuel pour écoles',
        value: '1535'
      },
      yearly: {
        code: process.env.REACT_APP_STRIPE_CODE_SCHOOL_YEARLY,
        description: 'Abonnement annuel pour écoles',
        value: '17000'
      },
      title: 'Abonnement pour écoles'
    },
    student: {
      monthly: {
        code: process.env.REACT_APP_STRIPE_CODE_STUDENT_MONTHLY,
        description: 'Abonnement mensuel pour étudiants',
        value: '11'
      },
      yearly: {
        code: process.env.REACT_APP_STRIPE_CODE_STUDENT_YEARLY,
        description: 'Abonnement annuel pour étudiants',
        value: '120'
      },
      title: 'Abonnements pour étudiants'
    }
  };

const Subscription = ({ user }) => {
    const [chosenPlan, setChosenPlan] = useState(null);
    const {me} = useContext(MyContext)
    return (
    <div className="view-wrapper">
        <div className="navbar-ghost is-hidden-mobile"></div>
        { me !== null && me.role ?
        <div class="container is-custom">
            <h2>{ subscriptions[me.role].title }</h2>
            <div class="columns">
                <div class="column">
                <div class="card">
                    <div class="card-content">
                    <p class="title is-4">Abonnement mensuel</p>
                    <div class="content">
                        <p>{ subscriptions[me.role].monthly.description }</p>
                    </div>
                    <p class="subtitle is-6">{ subscriptions[me.role].monthly.value }$</p>
                    <span class="button center" onClick={()=>{setChosenPlan({code: subscriptions[user.role].monthly.code, type: 'Abonnement mensuel'} )}}>Selectionner</span>
                    </div>
                </div>
                </div>
                <div class="column">
                <div class="card">
                    <div class="card-content">
                    <p class="title is-4">Abonnement annuel</p>
                    <div class="content">
                        <p>{ subscriptions[me.role].yearly.description }</p>
                    </div>
                    <p class="subtitle is-6">{ subscriptions[me.role].yearly.value }$</p>
                    <span class="button center" onClick={()=>{setChosenPlan({code: subscriptions[me.role].yearly.code, type: 'Abonnement annuel'})}}>Selectionner</span>
                    </div>
                </div>
                </div>
            </div>
            <CheckoutForm chosenPlan={chosenPlan} />
        </div> : <div div class="container is-custom"></div>
        }
    </div>
    );
}

export default Subscription;
