import {userRoles} from "../stores/enums/userRoles";
import Login, {ResetPassword, PickTypeAccount } from "../pages/Login";
import SignUp from "../pages/SignUp/SignUp";
import { Event, EventsPage } from "../pages/Events";
import NewEvents from "../pages/NewEvents";
import Blog, { BlogPost, JobPostComponent } from "../pages/Blog";
import Settings from "../pages/Settings";
import Schedules from "../pages/Schedules";
import MainProfile from "../pages/Main";
import SignIn from "../pages/Signin";
import Schools, { ListAllBusinesses } from "../pages/Schools";
import Internships from "../pages/Internships";
import MyInternships from "../pages/MyInternships";
import MyInternship from "../pages/MyInternship";
import { UploadImage } from "../pages/UploadImages";
import Plans from "../pages/Plan";
import SelectSchools from "../pages/SignUp/SelectSchools";
import Home from "../pages/Home";
import BusinessesProfile from "../pages/BusinessesProfile";
import InviteUsers from "../pages/InviteUsers";
import ChatMain from "../pages/Chat";
import ErrorPage from "../pages/ErrorPage";
import CorpDashboard from "../pages/CorpDashboard";
import Contact from "../pages/Contact";
import MainJobs from "../pages/MainJobs";
import EventPage from "../pages/EventPage";
import EditGroups from "../pages/EditGroups";
import UserProfilePage from "../pages/UserProfilePage";
import {DisplayCovTemplateList, EditConvTemplate} from "../pages/CreateEditCoTemplate";
import Post from "../pages/Post";
import FormCreator from "../pages/FormCreator";
import { FormDashboard, FormUserDashboard } from "../pages/FormDashboard";
import { FormPage } from "../pages/FormPage";
import AdvanceInputText from "../components/AdvanceInputText";
import JobPage from "../pages/JobPage";
import Documents from "../pages/Documents";
import ActionLogs from "../pages/ActionsLogs";
import UserLogs from '../pages/Userlogs'
import EditFormComponent from "../pages/formGenerator";
import UserRegistration from "../pages/userRegistration";
import CompanyRegistration from "../pages/companyRegistration";
import Authorization from "../pages/Authorization";
import Popup from "../pages/Popup"
import Chat2 from "../pages/Chat2";
import Chat3 from "../pages/Chat3";
import Chat4 from "../pages/Chat4"
import CompanyFreemiumRegistration from "../pages/CompanyFreemiumRegistration";
import CompleteSeekerRegistration from "../pages/CompleteSeekerRegistration";
import CompleteFreemiumCompanyRegistration from "../components/CompleteFreemiumCompanyRegistration";
import EditGroupsMigration from "../migration/pages/EditGroupsMegration";
import { ClassRoomMigration } from "../migration/components/ClassRoomMigration";
import ManageCompany from "../pages/ManageCompany";
import UsersView from '../pages/UsersView'


/**
 * Had to change the value label to the key of the translation file
 */
const Routes = [
  {
    label: "general.home",
    path: "/me",
    exact: false,
    component: <MainProfile />,
    inMenu: true,
    locales: ["me", "overlay"],
    ico: <i className="mdi mdi-home"></i>,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.SCH_STUDENT,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.SCH_BUSINESS,
      userRoles.BZK_SEEKER,
      userRoles.MTR_ADMIN,
      userRoles.SCH_TEACHER,
      userRoles.MTR_KID,
      userRoles.MTR_MENTOR,
      userRoles.BIZ_JOBLISTING_ADMIN,
      userRoles.BIZ_JOBLISTING_USER,
    ],
  },
  {
    label: '',
    path: "/me/mentors_view/views/8d572260-70e4-4847-b4a3-e6390a695911"
  },
  {
    label: '',
    path: "/me/mentors_view/views"
  },
  {
    label: 'Aucune correspondance',
    path: '/me/nomatch',
    ico: <i className="fas fa-warning"></i>,
    locales: ['me'],
    roles: [
        userRoles.MTR_ADMIN
    ]
  },
  {
    label: "overlay.myInternships",
    ico: <i className="mdi mdi-matrix"></i>,
    locales: ['me'],
    path: "/me/io",
    roles: [
        userRoles.MTR_ADMIN,
        userRoles.SCH_STUDENT,
        userRoles.SCH_ADMIN_SCHOOL
    ]
  },
  {
    label: 'general.matchrate',
    ico: <i className="mdi mdi-equal-box"></i>,
    locales: ['me'],
    path: '/me/rates',
    roles: [
        userRoles.MTR_ADMIN
    ]
  },
  {
    label: "general.users",
    ico: <i className="mdi mdi-google-classroom" /> ,
    path: "/me/users_view",
    locales: ['me'],
    roles: [
        userRoles.MTR_ADMIN,
        userRoles.SCH_ADMIN_SCHOOL
    ]

  },
  {
    label: "general.mentors",
    ico: <i className="mdi mdi-google-classroom" /> ,
    path: "/me/mentors_view",
    locales: ['me'],
    roles: [
      userRoles.MTR_ADMIN,
    ]
  },
  {
    label:"general.forms",
    ico: <i className="mdi mdi-puzzle"></i>,
    path: "/me/forms",
    locales: ['me'],
    roles: [
        userRoles.SCH_ADMIN_SCHOOL
    ]
  },
  {
    exact: true,
    path: "/popup",
    component: <Popup />
  },
  {
    exact: true,
    path: "/chat3",
    component: <Chat3 />
  },
  {
    exact: true,
    path: "/chat2",
    component: <Chat2 />
  },
  {
    exact: true,
    path: "/userRegistration",
    component: <UserRegistration />
  },

  {
    exact: true,
    path: "/completeSeekerRegistration",
    component: <CompleteSeekerRegistration />
  },
  {
    exact: true,
    path: "/companyFreemiumRegistration",
    component: <CompanyFreemiumRegistration />
  },
  {
    exact: true,
    path: "/completeFreemiumCompanyRegistration",
    component: <CompleteFreemiumCompanyRegistration />
  },
  {
    exact: true,
    path: "/companyRegistration",
    component: <CompanyRegistration />
  },
  {
    exact: false,
    path: "/user/:userId",
    component: <UserProfilePage />,
  },
  // {
  //   label: "general.home",
  //   path: "/",
  //   exact: true,
  //   component: <Login />,
  //   inMenu: true,
  //   ico: <i className="mdi mdi-home"></i>,
  //   roles: [userRoles.SCH_ADMIN_SCHOOL],
  // },

  {
    path: '/chooseYourAccountType',
    exact: true,
    component: <PickTypeAccount />
  },
  {
    label: "overlay.jobs",
    path: `/jobs`,
    exact: true,
    locales: ["me", "overlay"],
    component: <ListAllBusinesses businesses={true} />,
    inMenu: true,
    roles: [
      // userRoles.SCH_ADMIN_SCHOOL,
      // userRoles.SCH_BUSINESS,
      // userRoles.SCH_TEACHER,
      // userRoles.SCH_STUDENT,
      // userRoles.SCH_SEEKER,
      // userRoles.CMP_ADMIN_BUSINESS,
      // userRoles.CMP_STUDENT,
      // userRoles.CMP_USER,
      // userRoles.CMP_SEEKER,
      // userRoles.CMP_EMPLOYEE,
      // userRoles.MTR_ADMIN,
      // userRoles.MTR_MENTOR,
      // userRoles.BIZ_JOBLISTING_ADMIN,
      // userRoles.BIZ_JOBLISTING_USER,
      // userRoles.BZK_SUPER,
      // userRoles.BZK_GUEST,
      // userRoles.BZK_SEEKER,
    ],
    ico: <i className="fa fa-briefcase"></i>
  },
  {
    label: "general.organisationpanel",
    path: `/settings`,
    exact: false,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.SCH_BUSINESS,
      userRoles.MTR_ADMIN,
      userRoles.BIZ_JOBLISTING_ADMIN,
    ],
    locales: ["me", "overlay"],
    ico: <i className="mdi mdi-cogs"></i>,
  },
  {
    label: "general.actionsLog",
    path: "/me/logs",
    exact: false,
    component: <UserLogs />,
    inMenu: true,
    locales: ["me"],
    ico: <i className="mdi mdi-chart-bar"></i>,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.SCH_STUDENT,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.SCH_BUSINESS,
      userRoles.MTR_KID,
      userRoles.MTR_MENTOR,
    ],
  },
  {
    label: "general.calendar",
    path: "/me/calendar",
    exact: true,
  },
  {
    label: "general.globalmessage",
    path: `/me/posts`,
    exact: true,
    inMenu: true,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.SCH_STUDENT,
      userRoles.BZK_SEEKER,
      userRoles.MTR_KID,
      userRoles.MTR_MENTOR,
      userRoles.BIZ_JOBLISTING_ADMIN,
      userRoles.BIZ_JOBLISTING_USER,
    ],
    //locales: ["me", "overlay", "sch.admin_school", "sch.business"],
    ico: <i className="mdi mdi-message-bulleted"></i>,
  },
  {
    label: "general.organisationpanel",
    path: `/settings`,
    exact: false,
    inMenu: true,
    component: <MainProfile />,
    roles: [userRoles.SCH_ADMIN_SCHOOL, userRoles.MTR_ADMIN, userRoles.CMP_ADMIN_BUSINESS, userRoles.BIZ_JOBLISTING_ADMIN],
    //locales: ["me", "sch.admin_school", "sch.business"],
    ico: <i className="mdi mdi-office-building"></i>,
  },
  {
    label: "general.settings",
    path: `/settings/edit`,
    exact: true,
    inMenu: true,
    component: <MainProfile />,
    roles: [userRoles.SCH_ADMIN_SCHOOL, userRoles.MTR_ADMIN, userRoles.CMP_ADMIN_BUSINESS, userRoles.BIZ_JOBLISTING_ADMIN],
    locales: ["overlay", "settings"],
    ico: <i className="mdi mdi-cogs"></i>,
  },
  {
    label: "general.classroom",
    path: `/settings/groups`,
    exact: true,
    inMenu: true,
    subItem: true,
    roles: [userRoles.SCH_ADMIN_SCHOOL, userRoles.MTR_ADMIN, userRoles.CMP_ADMIN_BUSINESS],
    locales: ["overlay", "settings", "sch.admin_school", "sch.business"],
    ico: <i className="mdi mdi-google-classroom"></i>,
  },
  {
    label: "general.classroom",
    path: `/migration/settings/groups`,
    component: <ClassRoomMigration />,
    exact: true,
    roles: [userRoles.SCH_ADMIN_SCHOOL, userRoles.MTR_ADMIN, userRoles.CMP_ADMIN_BUSINESS],
    locales: ["overlay", "settings", "sch.admin_school", "sch.business"],
    ico: <i className="mdi mdi-google-classroom"></i>,
  },
  {
    label: "general.classroom",
    path: `/groups/:id`,
    exact: false,
    inMenu: true,
    subItem: true,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.MTR_ADMIN,
      userRoles.CMP_ADMIN_BUSINESS
    ],
    component: <EditGroups />,
    ico: <i className="mdi mdi-google-classroom"></i>
  },
  {
    label: "general.classroom",
    path: `/migration/groups/:id`,
    exact: false,
    inMenu: true,
    subItem: true,
    // roles: [
    //   userRoles.SCH_ADMIN_SCHOOL,
    //   userRoles.MTR_ADMIN,
    //   userRoles.CMP_ADMIN_BUSINESS
    // ],
    component: <EditGroupsMigration />,
    ico: <i className="mdi mdi-google-classroom"></i>
  },
  {
    label: "general.managejoboffers",
    path: `/settings/jobs`,
    exact: false,
    inMenu: true,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.SCH_BUSINESS
    ],
    subItem: true,
    locales: ["settings", "overlay", "mainJob"],
    ico: <i className="mdi mdi-briefcase-edit"></i>,
  },
  {
    label: "general.manageinternships",
    path: `/settings/internships`,
    exact: false,
    inMenu: true,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.SCH_BUSINESS,
      userRoles.MTR_ADMIN,
      userRoles.BIZ_JOBLISTING_ADMIN,
    ],
    subItem: true,
    locales: ["settings", "overlay"],
    ico: <i className="mdi mdi-school"></i>,
  },
  {
    label: "general.approvejoboffers",
    path: `/me/approve-jobs`,
    exact: false,
    inMenu: true,
    roles: [userRoles.SCH_ADMIN_SCHOOL, userRoles.BIZ_JOBLISTING_ADMIN,],
    locales: [],
    ico: <i className="mdi mdi-briefcase-check"></i>,
  },
  {
    label: "general.applications",
    path: "/settings/applications",
    exact: false,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.CMP_USER,
      userRoles.SCH_BUSINESS,
      userRoles.BIZ_JOBLISTING_ADMIN,
    ],
    subItem: true,
    locales: ["overlay", "settings"],
    ico: <i className="mdi mdi-account-plus"></i>,
  },
  {
    label: "general.invite",
    path: "/settings/invite",
    exact: false,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.CMP_USER,
      userRoles.MTR_ADMIN,
      userRoles.BIZ_JOBLISTING_ADMIN,
    ],
    subItem: true,
    locales: ["overlay", "settings"],
    ico: <i className="mdi mdi-account-plus"></i>,
  },
  // {
  //   label: "general.external",
  //   path: "/settings/externals",
  //   exact: false,
  //   roles: [
  //     userRoles.SCH_ADMIN_SCHOOL,
  //     userRoles.CMP_ADMIN_BUSINESS,
  //     userRoles.CMP_USER,
  //     userRoles.MTR_ADMIN
  //   ],
  //   subItem: true,
  //   locales: ["overlay", "settings"],
  //   ico: <i className="mdi mdi-access-point"></i>,
  // },
  {
    label: "general.joboffers",
    path: `/me/jobs-internships`,
    exact: false,
    inMenu: true,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.SCH_STUDENT,
      userRoles.SCH_SEEKER,
      userRoles.CMP_SEEKER,
      userRoles.CMP_USER,
      userRoles.SCH_BUSINESS,
      userRoles.MTR_KID,
      userRoles.MTR_MENTOR,
      userRoles.BIZ_JOBLISTING_ADMIN,
      userRoles.BIZ_JOBLISTING_USER,
    ],
    //locales: ["me", "overlay"],
    ico: <i className="mdi mdi-briefcase"></i>,
  },
  {
    label: "overlay.myInternships",
    path: `/me/my-internships`,
    exact: false,
    inMenu: true,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.SCH_STUDENT,
      userRoles.CMP_USER,
      userRoles.BZK_SEEKER,
      userRoles.MTR_KID,
      userRoles.MTR_MENTOR,
      userRoles.BIZ_JOBLISTING_ADMIN,
      userRoles.BIZ_JOBLISTING_USER,
    ],
    locales: ["me", "overlay"],
    ico: <i className="mdi mdi-briefcase-account"></i>,
  },
  {
    label: "overlay.bookmarks",
    path: `/me/bookmarks`,
    exact: false,
    inMenu: true,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.SCH_STUDENT,
      userRoles.CMP_USER,
      userRoles.BZK_SEEKER,
      userRoles.MTR_KID,
      userRoles.MTR_MENTOR,
    ],
    locales: ["me", "overlay"],
    ico: <i className="mdi mdi-bookmark"></i>,
  },
  {
    label: "general.events",
    path: `/me/events`,
    exact: false,
    inMenu: true,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.SCH_STUDENT,
      userRoles.CMP_USER,
      userRoles.BZK_SEEKER,
      userRoles.MTR_KID,
      userRoles.MTR_MENTOR,
      userRoles.BIZ_JOBLISTING_ADMIN,
      userRoles.BIZ_JOBLISTING_USER,
    ],
    //locales: ["me", "overlay"],
    ico: <i className="mdi mdi-calendar-check"></i>,
  },
  {
    label: "general.events",
    path: `/settings/events`,
    exact: false,
    inMenu: true,
    subItem: true,
    roles: [userRoles.SCH_ADMIN_SCHOOL, userRoles.MTR_ADMIN, userRoles.BIZ_JOBLISTING_ADMIN],
    locales: ["settings", "overlay"],
    ico: <i className="mdi mdi-calendar-edit"></i>,
  },
  {
    label: "general.events",
    path: `/me/events`,
    exact: false,
    inMenu: true,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.SCH_STUDENT,
      userRoles.CMP_USER,
      userRoles.SCH_BUSINESS,
      userRoles.MTR_ADMIN,
      userRoles.BZK_SEEKER,
      userRoles.MTR_KID,
      userRoles.MTR_MENTOR,
      userRoles.BIZ_JOBLISTING_ADMIN,
      userRoles.BIZ_JOBLISTING_USER,
    ],
    locales: ["feeds"],
    ico: <i className="mdi mdi-calendar-edit"></i>,
  },
  {
    label: "general.calendar",
    path: `/me/calendar`,
    exact: false,
    inMenu: true,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.SCH_BUSINESS,
      userRoles.MTR_ADMIN,
      userRoles.BZK_SEEKER,
      userRoles.MTR_KID,
      userRoles.MTR_MENTOR,
      userRoles.BIZ_JOBLISTING_ADMIN,
      userRoles.BIZ_JOBLISTING_USER,
      userRoles.CMP_ADMIN_BUSINESS
    ],
    locales: ["me", "overlay",],
    ico: <i className="mdi mdi-calendar-star"></i>,
  },
  {
    label: "general.notifications",
    path: `/me/notifications`,
    exact: false,
    inMenu: true,
    roles: [userRoles.SCH_ADMIN_SCHOOL],
    locales: ["feeds", "overlay"],
    ico: <i className="mdi mdi-bell-alert"></i>,
  },
  {
    label: "general.manageusers",
    path: `/settings/users`,
    exact: false,
    inMenu: true,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.SCH_BUSINESS,
      userRoles.MTR_ADMIN
    ],
    subItem: true,
    locales: ["settings", "overlay"],
    ico: <i className="mdi mdi-account-multiple"></i>,
  },
  {
    label: "Corp",
    path: `/corp-dashboard`,
    exact: false,
    component: <CorpDashboard />,
  },
  {
    label: "overlay.businessProfile",
    path: `/b-profile`,
    exact: true,
    component: <BusinessesProfile />,
    inMenu: true,
  },
  {
    label: "overlay.businessProfilePage",
    path: `/b-profile/:page`,
    exact: true,
    component: <BusinessesProfile />,
  },
  {
    label: "Dashboard page job",
    path: `/dashboard/:page/:job`,
    exact: true,
    component: <BusinessesProfile />,
  },
  {
    label: "overlay.businesses",
    path: `/businesses`,
    exact: false,
    component: <Schools person={false} type="businesses" />,
    inMenu: true,
  },
  {
    label: "general.schools",
    path: `/schools`,
    exact: false,
    component: <Schools person={false} type="schools" files={false} />,
    inMenu: true,
  },
  {
    label: "overlay.students",
    path: `/students`,
    exact: false,
    component: <Schools person={true} type="students" />,
    inMenu: true,
  },
  {
    label: "overlay.teachers",
    path: `/teachers`,
    exact: false,
    component: <Schools person={true} />,
    inMenu: true,
    roles: [userRoles.SCH_ADMIN_SCHOOL],
    ico: <i className="fa fa-chalkboard-teacher"></i>,
  },
  {
    label: "general.login",
    path: `/`,
    exact: true,
    component: <Login />,
    inMenu: true,
  },
  {
    label: "general.login",
    path: `/reset`,
    exact: true,
    component: <ResetPassword />,
    inMenu: true,
  },
  {
    label: "Signup",
    path: `/signup/:token`,
    exact: false,
    component: <SignUp />,
  },
  {
    label: "general.signup",
    path: `/signup`,
    exact: false,
    component: <SignUp />,
    inMenu: true,
  },
  {
    label: "general.settings",
    path: `/settings2`,
    exact: false,
    component: <Settings />,
    inMenu: true,
    roles: [userRoles.SCH_ADMIN_SCHOOL],
    ico: <i className="mdi mdi-cogs"></i>,
  },
  {
    label: "Settings Page",
    path: `/settings/:page`,
    exact: false,
    component: <Settings />,
  },
  {
    label: "overlay.schedules",
    path: `/schedules`,
    exact: false,
    component: <Schedules />,
    inMenu: true,
  },
  {
    label: "overlay.events",
    path: `/events`,
    exact: true,
    component: <NewEvents />,
    inMenu: true,
  },
  {
    label: "overlay.files",
    path: `/me/documents`,
    exact: true,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.SCH_BUSINESS,
      userRoles.SCH_STUDENT,
    ],
    component: <Documents />,
    locales: ["me", "overlay"],
    ico: <i className="mdi mdi-briefcase"></i>,
    inMenu: true,
  },
  {
    label: "Events page",
    path: `/events/:eventId`,
    exact: false,
    component: <EventPage />,
    roles: [
        userRoles.SCH_ADMIN_SCHOOL,
        userRoles.SCH_STUDENT,
        userRoles.MTR_ADMIN,
        userRoles.BZK_SEEKER,
        userRoles.MTR_PARENT,
        userRoles.MTR_KID,
        userRoles.MTR_MENTOR,
        userRoles.CMP_ADMIN_BUSINESS,
        userRoles.SCH_TEACHER,
        userRoles.BIZ_JOBLISTING_ADMIN,
        userRoles.BIZ_JOBLISTING_USER,
    ],
    ico: <i className="mdi mdi-calendar-star"></i>,
  },
  {
    label: "general.myprofile",
    path: `/mon-profile`,
    exact: true,
    component: <MainProfile />,
    inMenu: true,
  },

  {
    label: "Mon Profile page",
    path: `/mon-profile/:page`,
    exact: true,
    component: <MainProfile />,
  },
  {
    label: "footer.blog",
    path: `/blog`,
    exact: true,
    component: <Blog />,
    inMenu: true,
  },
  {
    label: "Blog page",
    path: `/blog/:slug`,
    exact: false,
    component: <BlogPost />,
  },
  {
    label: "overlay.signIn",
    path: `/signin`,
    exact: false,
    component: <SignIn />,
    inMenu: true,
  },
  {
    label: "overlay.internships",
    path: `/internships`,
    exact: true,
    component: <Internships />,
    inMenu: true,
    roles: [userRoles.SCH_ADMIN_SCHOOL],
    ico: <i className="mdi mdi-briefcase"></i>,
  },
  {
    label: "Internships Page",
    path: `/internships/:slug`,
    exact: false,
    component: <JobPage />,
  },
  {
    label: "conv template",
    path: `/settings/convtemplates`,
    exact: true,
    roles: [userRoles.SCH_ADMIN_SCHOOL],
    locales: [],
    subItem: true,
    component: <DisplayCovTemplateList />,
    inMenu: true,
    ico: <i className="mdi mdi-file-cabinet"></i>
  },
  {
    path: `/convtemplates/:method/:id`,
    roles: [userRoles.SCH_ADMIN_SCHOOL],
    component: <EditConvTemplate />,
    inMenu: true,
    ico: <i className="mdi mdi-file-cabinet"></i>
  },
  {
    path: `/convtemplates/:method`,
    roles: [userRoles.SCH_ADMIN_SCHOOL],
    component: <EditConvTemplate />,
    inMenu: true,
    ico: <i className="mdi mdi-file-cabinet"></i>
  },
  {
    label: "My Internship Page",
    path: `/my-internship/:slug`,
    exact: true,
    component: <MyInternship />,
  },

  {
    label: "Job Page",
    path: `/jobs/:slug`,
    exact: true,
    component: <JobPage />,
    // component: <JobPostComponent type={`job`} />,
  },
  {
    label: "Post Page",
    path: `/post/:slug`,
    exact: true,
    component: <Post />,
  },
  {
    label: "overlay.files",
    path: `/files`,
    exact: true,
    component: <Schools files={true} />,
    inMenu: true,
  },
  {
    label: "overlay.forms",
    path: `/forms`,
    exact: true,
    component: <Schools files={true} numeric={true} />,
    inMenu: true,
  },
  {
    label: "overlay.upload",
    path: `/upload`,
    exact: true,
    component: <UploadImage files={true} numeric={true} />,
    inMenu: true,
  },
  {
    label: "overlay.plans",
    path: `/plans`,
    exact: true,
    component: <Plans files={true} numeric={true} />,
    inMenu: true,
  },
  {
    label: "overlay.chat",
    path: `/chat/:slug?`,
    exact: false,
    component: <ChatMain />,
    inMenu: true,
    roles: [userRoles.SCH_ADMIN_SCHOOL, userRoles.SCH_STUDENT, userRoles.CMP_USER, userRoles.CMP_ADMIN_BUSINESS, userRoles.MTR_ADMIN, userRoles.MTR_KID, userRoles.MTR_MENTOR, userRoles.BZK_SEEKER],
    ico: <i className="mdi mdi-forum"></i>,
  },
  // {
  //   label: "overlay.feed",
  //   path: `/feed/:slug`,
  //   exact: true,
  //   component: <BlogPost type={`feed`} />,
  //   inMenu: true,
  // },
  {
    label: "overlay.select",
    path: `/select`,
    exact: true,
    component: <SelectSchools />,
    inMenu: true,
  },
  {
    label: "Not Allowed",
    path: `/notallowed`,
    exact: false,
    component: <ErrorPage />,
  },
  {
    label: "contact",
    path: `/contact`,
    exact: false,
    component: <Contact />,
  },
  {
    label: "general.settings",
    path: `/me/settings`,
    exact: false,
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.SCH_STUDENT,
      userRoles.SCH_SEEKER,
      userRoles.CMP_SEEKER,
      userRoles.SCH_BUSINESS,
      userRoles.BZK_SEEKER,
      userRoles.MTR_ADMIN,
    ],
    locales: [],
    ico: <i className="mdi mdi-cogs"></i>,
  },
  {
    label: "",
    path: "/fr/settings/payment-info",
  },
  {

    path: `/formcreator`,
    exact: true,
    component: <EditFormComponent />,
  },
  {

    path: `/formcreator/:slug`,
    exact: true,
    component: <EditFormComponent />,
  },
  {
    label: "general.mngforms",
    ico: <i className="mdi mdi-format-list-checks"></i>,
    locales: ["settings","overlay"],
    roles: [userRoles.SCH_ADMIN_SCHOOL, userRoles.MTR_ADMIN,],
            path: `/settings/formdashboard`,
    exact: false,
    component: <FormDashboard />,
  },
  {
    label: "general.myforms",
    roles: [
      userRoles.SCH_ADMIN_SCHOOL,
      userRoles.SCH_STUDENT,
      userRoles.SCH_SEEKER,
      userRoles.CMP_SEEKER,
      userRoles.CMP_ADMIN_BUSINESS,
      userRoles.BZK_SEEKER
    ],
    path: `/me/myforms`,
    locales: ["me", "overlay"],
    exact: false,
    ico: <i className="mdi mdi-format-list-checks"></i>,
    component: <FormUserDashboard />,
  },
  {
    path: `/form/:slug`,
    exact: false,
    component: <FormPage />,
  },
  {
    path: `/authorization`,
    exact: true,
    component: <Authorization />,
  }
];

export default Routes;
