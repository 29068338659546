import React, {useContext, useState, useEffect} from 'react';
import {MyContext} from "../stores";
import moment from 'moment'
import {Link, useParams} from "react-router-dom";
import parser from 'html-react-parser'
import Avatar from 'react-avatar'
import {useTranslation} from "react-i18next";
import {jobPosts, firestore, postsDoc} from "../firestore/firestoreService";
import DisplayContentFromSlate from "../components/DisplayContentFromSlate";
import {format} from 'date-fns'
import { useAlert } from 'react-alert';

const PostOneImage = ({meta}) => {
    return (
        <div id="feed-post-1" className="card is-post">

            <div className="content-wrap">
                <div className="card-body">
                    <div className="post-image">
                        <Link data-fancybox="post1" data-lightbox-type="comments" to={`/fr/blog/${meta.id}`}
                           data-thumb="assets/img/demo/unsplash/1.jpg" href={meta.mainImage}
                           data-demo->
                            <img src={meta.mainImage} style={{width: '100%', height: 200}}
                                 data-demo-src="assets/img/demo/unsplash/1.jpg" alt="" />
                        </Link>
                        <div className="like-wrapper">
                            <a >
                                <i className="mdi mdi-heart not-liked bouncy"></i>
                                <i className="mdi mdi-heart is-liked bouncy"></i>
                                <span className="like-overlay"></span>
                            </a>
                        </div>

                        <div className="fab-wrapper is-share">
                            <a
                               data-modal="share-modal">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="feather feather-link-2">
                                    <path
                                        d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3"></path>
                                    <line x1="8" y1="12" x2="16" y2="12"></line>
                                </svg>
                            </a>
                        </div>

                        <div className="fab-wrapper is-comment">
                            <a >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="feather feather-message-circle">
                                    <path
                                        d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <h2 className="is-large">{meta.title}</h2>
                    <div className="card-heading">

                        <div className="user-block">
                            <div className="image">
                                <img src={meta.author.ico} data-demo-src="assets/img/avatars/dan.jpg"
                                     data-user-popover="1" alt="" data-target="webuiPopover32" />
                            </div>
                            <div className="user-info">
                                <a >{meta.author.name}</a>
                                <span className="time">{moment(meta.date).format('LLLL')}</span>
                            </div>
                        </div>

                        <div className="dropdown is-spaced is-right is-neutral dropdown-trigger">
                            <div>
                                <div className="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-more-vertical">
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="12" cy="5" r="1"></circle>
                                        <circle cx="12" cy="19" r="1"></circle>
                                    </svg>
                                </div>
                            </div>
                            <div className="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                    <a >
                                        <div className="media">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-bookmark">
                                                <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                                            </svg>
                                            <div className="media-content">
                                                <h3>Bookmark</h3>
                                                <small>Add this post to your bookmarks.</small>
                                            </div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item">
                                        <div className="media">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-bell">
                                                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                            </svg>
                                            <div className="media-content">
                                                <h3>Notify me</h3>
                                                <small>Send me the updates.</small>
                                            </div>
                                        </div>
                                    </a>
                                    <hr className="dropdown-divider" />
                                    <a >
                                        <div className="media">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-flag">
                                                <path
                                                    d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
                                                <line x1="4" y1="22" x2="4" y2="15"></line>
                                            </svg>
                                            <div className="media-content">
                                                <h3>Flag</h3>
                                                <small>In case of inappropriate content.</small>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="post-text" style={{paddingBottom: 15}}>
                         <p>{meta.tiny}</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

const PostMultiImage = props => {
    return (
        <div id="feed-post-1" className="card is-post">

            <div className="content-wrap">
                <div className="card-body">
                    <div className="post-image">

                        <div className="masonry-grid">

                            <div className="masonry-column-left">
                                <a data-fancybox="post3" data-lightbox-type="comments"
                                   data-thumb="assets/img/demo/unsplash/3.jpg"

                                   data-demo->
                                    <img src="https://via.placeholder.com/1600x900"
                                         data-demo-src="assets/img/demo/unsplash/3.jpg" alt="" />
                                </a>
                                <a data-fancybox="post3" data-lightbox-type="comments"
                                   data-thumb="assets/img/demo/unsplash/4.jpg"

                                   data-demo->
                                    <img src="https://via.placeholder.com/1600x900"
                                         data-demo-src="assets/img/demo/unsplash/4.jpg" alt="" />
                                </a>
                            </div>
                            <div className="masonry-column-right">
                                <a data-fancybox="post3" data-lightbox-type="comments"
                                   data-thumb="assets/img/demo/unsplash/5.jpg"

                                   data-demo->
                                    <img src="https://via.placeholder.com/1600x900"
                                         data-demo-src="assets/img/demo/unsplash/5.jpg" alt=""/>
                                </a>
                                <a data-fancybox="post3" data-lightbox-type="comments"
                                   data-thumb="assets/img/demo/unsplash/6.jpg"

                                   data-demo->
                                    <img src="https://via.placeholder.com/1600x900"
                                         data-demo-src="assets/img/demo/unsplash/6.jpg" alt=""/>
                                </a>
                                <a data-fancybox="post3" data-lightbox-type="comments"
                                   data-thumb="assets/img/demo/unsplash/7.jpg"

                                   data-demo->
                                    <img src="https://via.placeholder.com/1600x900"
                                         data-demo-src="assets/img/demo/unsplash/7.jpg" alt=""/>
                                </a>
                            </div>
                            <div className="like-wrapper">
                                <a >
                                    <i className="mdi mdi-heart not-liked bouncy"></i>
                                    <i className="mdi mdi-heart is-liked bouncy"></i>
                                    <span className="like-overlay"></span>
                                </a>
                            </div>

                            <div className="fab-wrapper is-share">
                                <a
                                   data-modal="share-modal">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-link-2">
                                        <path
                                            d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3"></path>
                                        <line x1="8" y1="12" x2="16" y2="12"></line>
                                    </svg>
                                </a>
                            </div>

                            <div className="fab-wrapper is-comment">
                                <a >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-message-circle">
                                        <path
                                            d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <h2 className="is-large">Title</h2>
                    <div className="card-heading">

                        <div className="user-block">
                            <div className="image">
                                <img src="https://via.placeholder.com/300x300" data-demo-src="assets/img/avatars/dan.jpg"
                                     data-user-popover="1" alt="" data-target="webuiPopover32" />
                            </div>
                            <div className="user-info">
                                <a >Dan Walker</a>
                                <span className="time">July 26 2018, 01:03pm</span>
                            </div>
                        </div>

                        <div className="dropdown is-spaced is-right is-neutral dropdown-trigger">
                            <div>
                                <div className="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-more-vertical">
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="12" cy="5" r="1"></circle>
                                        <circle cx="12" cy="19" r="1"></circle>
                                    </svg>
                                </div>
                            </div>
                            <div className="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                    <a >
                                        <div className="media">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-bookmark">
                                                <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                                            </svg>
                                            <div className="media-content">
                                                <h3>Bookmark</h3>
                                                <small>Add this post to your bookmarks.</small>
                                            </div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item">
                                        <div className="media">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-bell">
                                                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                            </svg>
                                            <div className="media-content">
                                                <h3>Notify me</h3>
                                                <small>Send me the updates.</small>
                                            </div>
                                        </div>
                                    </a>
                                    <hr className="dropdown-divider" />
                                    <a >
                                        <div className="media">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-flag">
                                                <path
                                                    d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
                                                <line x1="4" y1="22" x2="4" y2="15"></line>
                                            </svg>
                                            <div className="media-content">
                                                <h3>Flag</h3>
                                                <small>In case of inappropriate content.</small>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="post-text" style={{paddingBottom: 15}}>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

const PostNoImage = props => {
    return (
        <div id="feed-post-1" className="card is-post" style={{}}>

            <div className="content-wrap">
                <div className="card-body">
                    <h2 className="is-large">Title</h2>
                    <div className="card-heading">

                        <div className="user-block">
                            <div className="image">
                                <img src="https://via.placeholder.com/300x300" data-demo-src="assets/img/avatars/dan.jpg"
                                     data-user-popover="1" alt="" data-target="webuiPopover32" />
                            </div>
                            <div className="user-info">
                                <a >Dan Walker</a>
                                <span className="time">July 26 2018, 01:03pm</span>
                            </div>
                        </div>

                        <div className="dropdown is-spaced is-right is-neutral dropdown-trigger">
                            <div>
                                <div className="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-more-vertical">
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="12" cy="5" r="1"></circle>
                                        <circle cx="12" cy="19" r="1"></circle>
                                    </svg>
                                </div>
                            </div>
                            <div className="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                    <a >
                                        <div className="media">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-bookmark">
                                                <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                                            </svg>
                                            <div className="media-content">
                                                <h3>Bookmark</h3>
                                                <small>Add this post to your bookmarks.</small>
                                            </div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item">
                                        <div className="media">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-bell">
                                                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                            </svg>
                                            <div className="media-content">
                                                <h3>Notify me</h3>
                                                <small>Send me the updates.</small>
                                            </div>
                                        </div>
                                    </a>
                                    <hr className="dropdown-divider" />
                                    <a >
                                        <div className="media">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-flag">
                                                <path
                                                    d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
                                                <line x1="4" y1="22" x2="4" y2="15"></line>
                                            </svg>
                                            <div className="media-content">
                                                <h3>Flag</h3>
                                                <small>In case of inappropriate content.</small>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="post-text" style={{paddingBottom: 15}}>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export const BlogPost = ({type, data}) => {
    const {slug} = useParams()

    const [blogss, setBlogss] = useState([]);
    const [Blog, setBlog] = useState({})
    const {messagePost, blogs, jobPost, internshipPost} = useContext(MyContext)

    function selectIco(value){
        switch(value){
            case 'pdf':
                return 'mdi-file-pdf'
            break;
            case 'doc':
                return 'mdi-file-word'
                break;
            case 'excel':
                return 'mdi-file-excel'
            break;
            case 'pp':
                return 'mdi-file-powerpoint'
                break;
            case 'text':
                return 'mdi-note-text'
                break;
            case 'image':
                return 'mdi-file-image'
                break;
            case 'digital':
                return 'mdi-file-cloud'
                break;
            case 'csv':
                return 'mdi-file-delimited'
                break;
        }
    }

    useEffect(()=> {
        if(type === 'feed'){
            setBlogss(messagePost)
        }else if (type === 'job'){
            jobPosts.doc(slug).get().then( doc => {
                setBlog(doc.data())
            })
        } else if( type === 'internship') {
            postsDoc.doc(slug).get().then( doc => {
                setBlog({id: doc.id, ...doc.data()})
            })

        }
        // blogss.forEach( b => {
        //     console.log('in for each', b.id, slug)
        //     if(b.id === slug) {
        //         console.log('selected', b)
        //         setBlog(b)
        //     }
        // })
        // console.log('code2', Blog);
    }, [])

    return (
        <div className="stories-view">
            <div className="stories-wrapper is-home">
                <div className="inner-wrapper">
                    <div className="story-post-wrapper">
                        <div className="story-post">
                            <div className="post-title" style={{paddingTop: 100}}>
                                <h2>{Blog.title}</h2>
                            </div>
                            {type === 'internship' && (<div className="event-participants">
                                <div className="container-inner">
                                    <a className="participant">
                                        <div className="participant-avatar" >
                                            <img className="avatar" src="assets/img/avatars/mike.jpg"
                                                 data-demo-src="assets/img/avatars/mike.jpg" data-user-popover="12"
                                                 alt="" style={{ display: 'block',
                                                height: 50,
                                                width: 50,
                                                borderRadius: '50%'}}/>
                                        </div>
                                    </a>
                                    <a className="participant">
                                        <div className="participant-avatar">
                                            <img className="avatar" src="https://via.placeholder.com/150x150"
                                                 data-demo-src="assets/img/avatars/jenna.png" data-user-popover="0"
                                                 alt=""/>
                                                <div className="more-overlay">
                                                    <span>+26</span>
                                                </div>
                                        </div>
                                    </a>
                                </div>
                            </div>)}
                            <div className="post-image-wrap">
                                <img className="post-image" src={Blog.mainImage} style={{width: '100%'}}
                                     data-demo-src="assets/img/demo/unsplash/3.jpg" alt="" />
                            </div>
                            <div className="post-meta">
                                <div className="post-author">
                                    <div className="story-avatar">
                                        <img className="avatar" src="https://via.placeholder.com/150x150"
                                             data-demo-src="assets/img/avatars/stella.jpg" data-user-popover="2" alt="" />
                                    </div>
                                    <div className="meta">
                                        <span>{Blog.author && Blog.author.name}</span>
                                        <span>{moment(Blog.date).format('LLLL')}</span>
                                    </div>
                                </div>

                                <div className="post-stats">
                                    <div className="stat-item">

                                        {type === 'job' ? <i class="mdi mdi-human"></i>: <i class="mdi mdi-message-text"></i>}
                                        {type === 'job'
                                            ? <span>{Blog.applications && Blog.applications.length}</span>:
                                             <span>{Blog.messages && Blog.messages.length}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="post-meta">
                                <div className="post-author">
                                    <div className="story-avatar">
                                        <i class="mdi mdi-calendar"></i>
                                    </div>
                                    <div className="meta">
                                        <span>start date</span>
                                        <span>{moment(Blog.startDate).format('LL')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="post-meta">
                                <div className="post-author">
                                    <div className="story-avatar">
                                        <i className="mdi mdi-calendar"></i>
                                    </div>
                                    <div className="meta">
                                        <span>end date</span>
                                        <span>{moment(Blog.endDate).format('LL')}</span>
                                    </div>
                                </div>
                            </div>

                            {type === 'internship' && Blog.attachments && Blog.attachments.length && (<div>
                                {Blog.attachments.map( (att,key) => {
                                    return (
                                        <div key={key} className="post-meta">
                                        <div className="post-author">
                                            <div className="story-avatar">
                                                <i className={`mdi ${selectIco(att.type)}`}></i>
                                            </div>
                                            <div className="meta">
                                                <span>{att.name}</span>
                                                <span>{moment(Blog.endDate).format('LL')}</span>
                                            </div>
                                        </div>
                                    </div>)
                                })}
                            </div>)}
                            {type === 'job' && <a class="button is-solid accent-button raised is-fullwidth" style={{marginBottom: 15}}>Apply</a>}
                            <div className="post-text content">
                                {typeof Blog.text === 'string' && parser(Blog.text)}
                            </div>
                            <div className="post-tags">
                                <div className="tags">
                                    {Blog.users && Blog.users.map( (u, i) => <span key={i} className="tag" data-user-popover="1">@{u.firstname} {u.lastname}</span>)}
                                </div>
                            </div>
                            <div className="post-compose">
                                <div className="control">
                                    <textarea className="textarea" placeholder="Post a comment..."></textarea>
                                </div>
                                <div className="compose-controls">
                                    <img className="avatar" src="https://via.placeholder.com/150x150"
                                         data-demo-src="assets/img/avatars/jenna.png" data-user-popover="0" alt="" />
                                        <div className="compose-actions">
                                            <a className="action">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                     className="feather feather-at-sign">
                                                    <circle cx="12" cy="12" r="4"></circle>
                                                    <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                                                </svg>
                                            </a>
                                            <a className="action">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                     className="feather feather-image">
                                                    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                                    <circle cx="8.5" cy="8.5" r="1.5"></circle>
                                                    <polyline points="21 15 16 10 5 21"></polyline>
                                                </svg>
                                            </a>
                                            <a className="action">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                     className="feather feather-paperclip">
                                                    <path
                                                        d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                                                </svg>
                                            </a>
                                        </div>
                                        <a className="button is-solid accent-button raised">Post Comment</a>
                                </div>
                            </div>

                            <div className="comments-wrap">
                                <div className="comments-count">
                                    <h3>Comments ({Blog.messages && Blog.messages.length})</h3>
                                </div>
                                {Blog.messages && Blog.messages.map( (message, i) => {
                                    return (
                                        <div key={i} className="media is-comment">
                                            <div className="media-left">
                                                <div className="avatar-wrap is-smaller">
                                                    <img src={message.image}
                                                         data-demo-src="assets/img/avatars/dan.jpg" data-user-popover="1"
                                                         alt="" />
                                                    <div className="badge">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                             viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                             strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                             className="feather feather-check">
                                                            <polyline points="20 6 9 17 4 12"></polyline>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="media-content">
                                                <div className="comment-meta">
                                                    <h4><a>{message.firstname} {message.lastname}</a> <small> · {moment(message.date).format('LLLL')}</small></h4>
                                                    {message.text}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const JobPostComponent  = ({type}) => {
    const {slug} = useParams()
    const {t} = useTranslation()
    const [Blog, setBlog] = useState({})
    const {messagePost, langChoice, globalId, me} = useContext(MyContext)
    const [text, setText] = useState('')
    const [messages, setMessages] = useState([])
    const alert = useAlert()
    const onChange = (e) => {
        setText(e.target.value)
    }
    const onCancel = (e) => {
        e.preventDefault()
        postsDoc.doc(slug).collection('applications')
            .doc(globalId)
            .delete()
        postsDoc.doc(slug).update({
            applicationIds: firestore.FieldValue.arrayRemove(globalId)
        })
    }
    const onApply = (e) => {
        e.preventDefault()
        postsDoc.doc(slug).collection('applications')
            .doc(globalId)
            .set(me)
            .then( r => {
                alert.success(t("general.userupdated"))
            })

        postsDoc.doc(slug).update({
            applicationIds: firestore.FieldValue.arrayUnion(globalId)
        })
    }
    const onClick = (e) => {
        e.preventDefault();
        postsDoc.doc(slug).collection('comments').add({
            timestamp: new Date().getTime(),
            text,
            uid: globalId,
            me: {displayName: me.displayName, id: globalId}
        })
    }
    function selectIco(value){
        switch(value){
            case 'pdf':
                return 'mdi-file-pdf'
                break;
            case 'doc':
                return 'mdi-file-word'
                break;
            case 'excel':
                return 'mdi-file-excel'
                break;
            case 'pp':
                return 'mdi-file-powerpoint'
                break;
            case 'text':
                return 'mdi-note-text'
                break;
            case 'image':
                return 'mdi-file-image'
                break;
            case 'digital':
                return 'mdi-file-cloud'
                break;
            case 'csv':
                return 'mdi-file-delimited'
                break;
        }
    }

    useEffect(()=> {
        postsDoc.doc(slug).onSnapshot( doc => {
            setBlog(doc.data())
        })
        postsDoc.doc(slug).collection('comments').onSnapshot( doc => {
            setMessages(doc.docs.map( d => {
                return {id: d.id, ...d.data()}}))
        },
            onError => alert(onError))

    }, [])

    return (
        <div className="stories-view">
            <div className="stories-wrapper is-home">
                <div className="inner-wrapper">
                    <div className="story-post-wrapper">
                        <div className="story-post">
                            <div className="post-title" style={{paddingTop: 100}}>
                                <h2>{Blog.title}</h2>
                            </div>
                            {type === 'internship' && (<div className="event-participants">
                                <div className="container-inner">
                                    <a className="participant">
                                        <div className="participant-avatar" >
                                            <img className="avatar" src="assets/img/avatars/mike.jpg"
                                                 data-demo-src="assets/img/avatars/mike.jpg" data-user-popover="12"
                                                 alt="" style={{ display: 'block',
                                                height: 50,
                                                width: 50,
                                                borderRadius: '50%'}}/>
                                        </div>
                                    </a>
                                    <a className="participant">
                                        <div className="participant-avatar">
                                            <img className="avatar" src="https://via.placeholder.com/150x150"
                                                 data-demo-src="assets/img/avatars/jenna.png" data-user-popover="0"
                                                 alt=""/>
                                            <div className="more-overlay">
                                                <span>+26</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>)}
                            <div className="post-image-wrap">
                                <img className="post-image" src={Blog.mainImage || 'assets/old/wallpaper.png'} style={{width: '100%'}}
                                     data-demo-src="assets/img/demo/unsplash/3.jpg" alt="" />
                            </div>
                            <div className="post-meta">
                                <div className="post-author">
                                    <div className="story-avatar">
                                        <img className="avatar" src="https://via.placeholder.com/150x150"
                                             data-demo-src="assets/img/avatars/stella.jpg" data-user-popover="2" alt="" />
                                    </div>
                                    <div className="meta">
                                        <span>{Blog.author && Blog.author.name}</span>
                                        <span>{moment(Blog.date).format('LLLL')}</span>
                                    </div>
                                </div>

                                <div className="post-stats">
                                    <div className="stat-item">

                                        {type === 'job' ? <i class="mdi mdi-human"></i>: <i class="mdi mdi-message-text"></i>}
                                        {
                                            <span>{Blog.applicationIds && Blog.applicationIds.length}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="post-meta">
                                <div className="post-author">
                                    <div className="story-avatar">
                                        <i class="mdi mdi-calendar"></i>
                                    </div>
                                    <div className="meta">
                                        <span>{t('jobs.startDate')}</span>
                                        <span>{moment(Blog.startDate).format('LL')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="post-meta">
                                <div className="post-author">
                                    <div className="story-avatar">
                                        <i className="mdi mdi-calendar"></i>
                                    </div>
                                    <div className="meta">
                                        <span>{t('jobs.endDate')}</span>
                                        <span>{moment(Blog.endDate).format('LL')}</span>
                                    </div>
                                </div>
                            </div>

                            {type === 'internship' && Blog.attachments && Blog.attachments.length && (<div>
                                {Blog.attachments.map( (att,key) => {
                                    return (
                                        <div key={key} className="post-meta">
                                            <div className="post-author">
                                                <div className="story-avatar">
                                                    <i className={`mdi ${selectIco(att.type)}`}></i>
                                                </div>
                                                <div className="meta">
                                                    <span>{att.name}</span>
                                                    <span>{moment(Blog.endDate).format('LL')}</span>
                                                </div>
                                            </div>
                                        </div>)
                                })}
                            </div>)}
                            {Blog.applicationIds && Blog.applicationIds.includes(globalId) &&
                            <a className="button is-solid accent-button raised is-fullwidth" onClick={onCancel} style={{marginBottom: 15}}>{t('listing.cancelApp')}</a>
                                ||
                            <a className="button is-solid accent-button raised is-fullwidth" onClick={onApply} style={{marginBottom: 15}}>{t('listing.apply')}</a>
                            }
                            <h2>{langChoice === 'fr'? 'Description du poste:': 'Job description:' }</h2>
                            <div className="post-text content">
                                {(typeof Blog.desc === 'string' || Array.isArray(Blog.desc)) && <DisplayContentFromSlate content={Blog.desc} /> }
                            </div>
                            <div className="post-tags">
                                <div className="tags">
                                    {Blog.users && Blog.users.map( (u, i) => <span key={i} className="tag" data-user-popover="1">@{u.firstname} {u.lastname}</span>)}
                                </div>
                            </div>
                            <div className="post-compose">
                                <div className="control">
                                    <textarea className="textarea" placeholder={`${t('general.typecomment')}...`} onChange={onChange} value={text}></textarea>
                                </div>
                                <div className="compose-controls">
                                    <img className="avatar" src="https://via.placeholder.com/150x150"
                                         data-demo-src="assets/img/avatars/jenna.png" data-user-popover="0" alt="" />
                                    <a className="button is-solid accent-button raised" onClick={onClick}>{t('general.postcomment')}</a>
                                </div>
                            </div>

                            <div className="comments-wrap">
                                <div className="comments-count">
                                    <h3>{t('general.comments')} ({messages && messages.length})</h3>
                                </div>
                                {messages && messages.map( (message, i) => {
                                    return (
                                        <div key={i} className="media is-comment">
                                            <div className="media-left">
                                                <div className="avatar-wrap is-smaller">
                                                    <Avatar name={message.me.displayName} round={true} size={40}/>
                                                    <div className="badge">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                             viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                             strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                             className="feather feather-check">
                                                            <polyline points="20 6 9 17 4 12"></polyline>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="media-content">
                                                <div className="comment-meta">
                                                    <h4><a>{message.me.displayName}</a> <small> · {moment(message.timestamp).format('LLLL')}</small></h4>
                                                    {message.text}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Blog = props => {
    const {blogs} = useContext(MyContext)
    return (
        <div className="view-wrapper">
            <div id="main-feed" className="container">
                <div id="activity-feed" className="view-wrap true-dom">
                    <div className="columns">
                        <div className="column is-3 is-hidden-mobile"></div>
                        <div className="column is-6">
                            {blogs.map((blog, key) => {
                                return (<PostOneImage key={key} meta={blog} />)
                            })}
                        </div>
                        <div className="column is-3 is-hidden-mobile"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog
