import React, {useState, useContext, useEffect} from "react";
import styled from "styled-components"
import {IconButton} from "@mui/material";
import {CloseRounded} from "@mui/icons-material";
import {Applicants} from "../pages/Applications";

const CircleStyle = styled.div`
  * {
    color: var(--bazookka-pink);
  }
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${ props => props.size || '54px'};
  height: ${ props => props.size || '54px'};
  box-shadow: var(--bazookka-box-shadow);
  margin: 0 ${props => props.size && '0px' || '10px'};
  cursor: pointer;  
`

const ModalStyle = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow: scroll;
  background: rgba(0,0,0,0.3);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal__share {
    width: 800px;
    color: white;
    background-color: white;
    border-radius: 10px;
    box-shadow: var(--bazookka-box-shadow);
    padding: 1rem;
    .body {
      display: flex;
      color: #0a0a0a;
      gap: 10px;
    }
    .top {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .title {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 15px;
      }
    }
    
  }
  
  
`

export default function UsersListModal({title, ico, data, closeOut, empty, message }) {


    return (
        <ModalStyle>
            <div className="modal__share">
                <div className="top">
                    <div className="title">
                        {title}
                        {ico}
                    </div>
                    <div className="close">
                        <IconButton onClick={closeOut}>
                            <CloseRounded />
                        </IconButton>
                    </div>
                </div>
                <hr/>
                {empty &&
                    (
                        <div className="body">
                            {message}
                        </div>
                    )
                }
                {!empty && (
                    <div className="body">
                        {data.map( (appl, key) => {
                           return  <Applicants key={key} appl={appl} skey={true} closeOut={closeOut} />
                        })}
                    </div>
                )}

            </div>
        </ModalStyle>
    )
}