import React, { useState } from "react";
import {
  CardElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import "./styles.css";
import {firebaseInstance} from "../../firestore/firestoreService";

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange
}) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);


const CheckoutForm = ({ chosenPlan }) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [billingDetails, setBillingDetails] = useState({
    email: "",
    phone: "",
    name: ""
  });

  const elements = useElements();
  const stripe = useStripe();

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };


  const handleSubmitPay = async (event) => {
    event.preventDefault();
    setProcessing(true);

    const paymentMethod = {
      card: elements.getElement(CardElement),
      billing_details: billingDetails,
    };
    
    let pay = firebaseInstance().functions().httpsCallable('Pay');
    pay({
      email: billingDetails.email, 
      paymentMethod: paymentMethod, 
      currency: 'cad', 
      amount: 271181
    }).then( res => {
      setError(null);

      const payload = res.data;
      if (payload.paymentIntent.status === 'succeeded') {
        setSucceeded(true);
        setBillingDetails({
          email: "",
          phone: "",
          name: ""
        });
      } else {
        setError(payload.paymentIntent.status);
      }
      
    }).catch ( err => {
      setError(err.message);
    }); 
    
    setProcessing(false);
  };

  const handleSubmitSub = async (event) => {
    event.preventDefault();
    setProcessing(true);

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: billingDetails
    });

    if (payload.error) {
      setError(`${payload.error.message}`);
    } else {
      const subscribe = firebaseInstance().functions().httpsCallable('Subscribe');
      subscribe({
        'paymentMethod': payload.paymentMethod.id, 
        'email': billingDetails.email, 
        'plan': chosenPlan.code
      }).then( res => {
        const subscription = res.data;
        if (subscription.error) {
          setError(`${subscription.error.message}`);
        } else {
          setError(null);
          setSucceeded(true);
          setBillingDetails({
            email: "",
            phone: "",
            name: ""
          });
        }
      }).catch ( err => {
        setError(err.message);
      });
    }; 
    
    setProcessing(false);
  };
  
  return (
    <div>
      { chosenPlan ? 
      <div className="payment">
        <form id="payment-form" onSubmit={handleSubmitSub}>
          <fieldset className="FormGroup">
            <h3>Information de la carte :</h3>
            <Field
                label="Nom complet"
                id="name"
                type="text"
                placeholder="Nom sur la carte"
                required
                autoComplete="name"
                value={billingDetails.name}
                onChange={(e) => {
                  setBillingDetails({ ...billingDetails, name: e.target.value });
                }}
              />
              <Field
                label="Courriel"
                id="email"
                type="email"
                placeholder="***@***.***"
                required
                autoComplete="email"
                value={billingDetails.email}
                onChange={(e) => {
                  setBillingDetails({ ...billingDetails, email: e.target.value });
                }}
              />
              <Field
                label="Numéro de téléphone"
                id="phone"
                type="tel"
                placeholder="(---) --- ----"
                required
                autoComplete="tel"
                value={billingDetails.phone}
                onChange={(e) => {
                  setBillingDetails({ ...billingDetails, phone: e.target.value });
                }}
              />
          </fieldset>
          <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
          <p>{chosenPlan.type}</p>
          <button
            disabled={processing || disabled || succeeded}
            id="submit"
          >
            <span id="button-text">
              {processing ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Inscription"
              )}
            </span>
          </button>
          {/* Show any error that happens when processing the payment */}
          {error && (
            <div className="card-error" role="alert">
              {error}
            </div>
          )}
          {/* Show a success message upon completion */}
          <p className={succeeded ? "result-message" : "result-message hidden"}>
            Votre abonnement est complété!
          </p>
        </form>
      </div> : <div></div> }
    </div>
    );
}

export default CheckoutForm;
