import React, {useContext, useEffect, useState} from 'react'
import {MyContext} from "../stores";
import {Link, useParams} from "react-router-dom";
import {Route} from "react-router-dom";
import styled from 'styled-components'
import {eventsDocs, jobPosts, usersDoc, firebaseInstance, postsDoc} from "../firestore/firestoreService";
import {JobPostCard, JobPostCardNew} from "../components/JobPostCard";
import EditOverlay from "../components/EditOverLay";
import {Formik, Form, Field} from "formik";
import {InputEmail, InputText} from "../components/FormElements";
import {useTranslation} from "react-i18next";
import EventCard from "../components/EventCard";
import {Applicants} from "./Applications";
import {useAlert} from  'react-alert';

const ColumnStyle = styled.div`
  
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  
    @media(max-width: 1080px){
        flex-direction: column;
    }
`

const SubJobStyle = styled.div`
  .title-wrapper {
    border: 1px solid var(--bazookka-green);
    padding:1rem;
    background: white;
    font-size: 1rem;
    text-align: center;
    border-radius: 10px;
  }
  .section.is-news {
    width: 100%;
  }
  .news-grid {
    display: flex;
    justify-content: normal;
    > div {
      margin-bottom: 1rem;
      margin-right: 5px;
      flex-wrap: wrap;
    }
  }
  .invite {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    color: white;
    background: var(--bazookka-blue);
    width: 300px;
    padding: 5px;
    margin-top: 1rem;
    border-radius: 10px;
    form {
      display: block;
      width: max-content;
      margin: auto;
    }
    .field {
      width: 200px;
    }
    > form > label {
      display: block;
    }
    input[type='radio'] {
      margin-right: 10px;
    }
  }
  .listUsers .flex-block{
      margin:1rem 0;

      & > img{
          margin-right:10px;
      }
  }
`
export const SubCompanySection = ({children}) => {
    const [jobPost,setJobPost]=useState([])
    const {me, langChoice, globalId, dispatchModal} = useContext(MyContext)
    const {t} = useTranslation()

    useEffect(()=>{
        if(me && me.select){
            jobPosts.where('businessId','array-contains',me.select.id).onSnapshot(data=>{
                setJobPost(data.docs.map(d=>Object.assign({id: d.id, ...d.data()})))
            })
        }

    },[globalId, me])

    return (
        <>
            <div id="profile-timeline-posts" className="box-heading">
                <h4>{window.location.href.includes('manage-job') ? t('general.managejoboffers') : t('general.joboffers')}</h4>
                <div className="button-wrap">
                    {/*<Link type="button" className="button is-active" to="/feeds/c/createJob">Create</Link>*/}
                    <a type="button" className="button is-active"
                     onClick={(e)=> {
                         e.preventDefault()
                         dispatchModal({
                             type: 'OPEN_MODAL',
                             payload: {
                                 modalType: 'EditJobModal',
                                 modalProps: {
                                     title: t('jobs.create'),
                                     page: 'createJob'
                                 }
                             }
                         })
                     }}
                    >{t('jobs.create')}</a>
                </div>
            </div>
            <SubJobStyle className="posts-wrapper">
                {/*<SearchBar />*/}
                <div className="columns is-multiline" style={{ marginTop: 25 }}>
                    <div className="section is-news">
                        <div className="news-grid">
                            {jobPost &&
                            jobPost.map((doc, key) => (
                                <JobPostCard key={key} data={doc} type={`owner`} hidetop={true} />
                            ))}
                        </div>
                    </div>

                </div>
            </SubJobStyle>
        </>

    );
};

export const BusinessSettings = () => {
    return (
        <div>Eco</div>
    )
}

export const JobListing = ({children}) => {
    const [jobPost,setJobPost]=useState([])
    const {me, langChoice, globalId} = useContext(MyContext)
    const {t} = useTranslation()
    useEffect(()=>{
        if(me && me.select){
            jobPosts.get().then(data=>{
                setJobPost(data.docs.map(d=>Object.assign({id: d.id, ...d.data()})))
            }).catch(e => console.log(e))
        }

    },[globalId, me])

    return (
        <>
            <div id="profile-timeline-posts" className="box-heading">
                <h4>{t('general.joboffers')}</h4>
                <div className="button-wrap">
                {/*    <Link type="button" className="button is-active" to="/feeds/c/createJob">Create</Link>*/}
                </div>
            </div>
            <SubJobStyle className="posts-wrapper">
                {/*<SearchBar />*/}
                <div className="columns is-multiline" style={{ marginTop: 25 }}>
                    <div className="section is-news">
                        <div className="news-grid" style={{display: 'flex'}}>
                            {jobPost &&
                            jobPost.map((doc, key) => (
                                <JobPostCard key={key} data={doc}  hidetop={true} />
                            ))}
                        </div>
                    </div>

                </div>
            </SubJobStyle>
        </>

    );
};


export const SubCompanyEvent = () => {
    const [events, setEvents] = useState([])
    const {me, langChoice, globalId, dispatchModal} = useContext(MyContext)
    const {t} = useTranslation()

    useEffect(()=>{
        if(me && me.select){
            postsDoc.where('groups','array-contains-any',me.classroomId)
                .where('type', '==', 'event')
                .onSnapshot(data=>{
                setEvents(data.docs.map(d=>Object.assign({id: d.id, ...d.data()})))
            })
        }

    },[globalId, me])


    return (
        <SubJobStyle className="posts-wrapper">
            <div id="profile-timeline-posts" className="box-heading">
                <h4>{t('general.events')}</h4>
                <div className="button-wrap">
                        <a  className="button is-active"
                         onClick={(e) => {
                             e.preventDefault()
                             dispatchModal({
                                 type: 'OPEN_MODAL',
                                 payload: {
                                     modalType: 'EditEventModal',
                                     modalProps: {
                                         title: t("general.events"),
                                         ico: <i className="mdi mdi-calendar-star"></i>
                                     }
                                 }
                             })
                         }}
                        >{t('general.eventcreate')}</a>
                </div>
            </div>
            <div className="columns is-multiline" style={{ marginTop: 25 }}>
                <div className="section is-news">
                    <div className="news-grid">
                        {events &&
                        events.map((doc, key) => (
                            <EventCard key={key} data={doc} type={`owner`} hidetop={true} />
                        ))}
                    </div>
                </div>

            </div>
        </SubJobStyle>
    )
}
const StatsMenu = () => {
    return (
        <div>HELLO</div>
    )
}

export const UsersPage = () => {
    const {langChoice, me, globalId} = useContext(MyContext)
    const [apicall, setApicall] = useState(false)
    const [users, setUsers] = useState([])
    const {t} = useTranslation()

    useEffect(()=> {
        if(me) {
            usersDoc.where('schoolId', 'array-contains-any', [me.select.id]).onSnapshot( doc => {
                setApicall(true)
                setUsers(doc.docs.map( docs => {
                return {id: docs.id, ...docs.data()}})
                )

            })
        }

    }, [apicall, me])

    return (
        <React.Fragment>
            <div id="profile-timeline-posts" className="box-heading">
                <h4>{t('general.manageusers')}</h4>
                <div>

                </div>
                <div className="button-wrap">
                    <Link
                        to={`/settings/invite`}
                        className="button is-active"
                    >{t('general.invite')}</Link>
                </div>
            </div>
            <SubJobStyle className="posts-wrapper">

                <div className="wrapped">
                    <div className="listUsers">
                        {users.map( (user, key) => <Applicants appl={user} key={key} type={`user`}></Applicants>)}
                    </div>
                </div>

            </SubJobStyle>
        </React.Fragment>

    )
}
export default function CorpDashboard({}){
    const {homeNav, updateHomeNav, me, globalId} = useContext(MyContext)


    return (
        <div className="view-wrapper">
            <div className="container is-custom">
                <div id="profile-main" className="view-wrap is-headless">
                    <div className="columns is-multiline no-margin">
                        <div className="column is-paddingless">
                            <Route path={`/dashboard`} component={StatsMenu} exact={true}></Route>

                            <Route path={`/dashboard/jobs`} component={SubCompanySection} exact></Route>
                            <Route path={`/dashboard/users`} component={UsersPage} exact></Route>
                            <Route path={`/dashboard/events`} component={SubCompanyEvent} exact></Route>
                            <Route path={`/dashboard/c/:page`} component={EditOverlay} />
                            <Route path={`/dashboard/editJob/:job`} component={EditOverlay} />
                            <Route path={`/dashboard/editEvent/:event`} component={EditOverlay} />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
