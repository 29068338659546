import firebase from '../../config/firebase';
import {userRoles} from "../../stores/enums/userRoles";

export async function getUserRole(selectId){
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return claimToken.claims[selectId];
}

export async function isAllowed(roles, selectId) {
    try{
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return roles.includes(claimToken.claims[selectId]);
    }catch(e) {
        return false
    }
}

export async function isAdmin(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.CMP_ADMIN_BUSINESS, userRoles.SCH_ADMIN_SCHOOL, userRoles.MTR_ADMIN, userRoles.SCH_TEACHER, userRoles.BIZ_JOBLISTING_ADMIN].includes(claimToken.claims[selectId]);
}

//SCH

export async function isSchoolAdmin(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.SCH_ADMIN_SCHOOL,userRoles.SCH_TEACHER ].includes(claimToken.claims[selectId]);
}

export async function isSchoolBusiness(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.SCH_BUSINESS].includes(claimToken.claims[selectId]);
}

export async function isSchoolSeeker(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.SCH_SEEKER].includes(claimToken.claims[selectId]);
}

export async function isSchoolStudent(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.SCH_STUDENT].includes(claimToken.claims[selectId]);
}

export async function isSchoolTeacher(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.SCH_TEACHER].includes(claimToken.claims[selectId]);
}

//CMP

export async function isCompanyAdmin(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.CMP_ADMIN_BUSINESS].includes(claimToken.claims[selectId]);
}

export async function isCompanyEmployee(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.CMP_EMPLOYEE].includes(claimToken.claims[selectId]);
}

export async function isCompanySeeker(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.CMP_SEEKER].includes(claimToken.claims[selectId]);
}

export async function isCompanyStudent(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.CMP_STUDENT].includes(claimToken.claims[selectId]);
}

export async function isCompanyUser(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.CMP_USER].includes(claimToken.claims[selectId]);
}

//MTR

export async function isMentorAdmin(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.MTR_ADMIN].includes(claimToken.claims[selectId]);
}

export async function isMentor(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.MTR_MENTOR].includes(claimToken.claims[selectId]);
}

export async function isKid(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.MTR_KID].includes(claimToken.claims[selectId]);
}

export async function isParent(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.MTR_PARENT].includes(claimToken.claims[selectId]);
}

//BIZ

export async function isBizAdmin(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.BIZ_JOBLISTING_ADMIN].includes(claimToken.claims[selectId]);
}

export async function isBizUser(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [userRoles.BIZ_JOBLISTING_USER].includes(claimToken.claims[selectId]);
}

export async function isNeedDeepInfo(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true);
    return [
        userRoles.CMP_SEEKER,
        userRoles.SCH_STUDENT,
        userRoles.CMP_USER
    ].includes(claimToken.claims[selectId])
}

export async function isJobs(selectId){
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true)
    return [
        userRoles.BIZ_JOBLISTING_ADMIN,
        userRoles.BIZ_JOBLISTING_USER
    ].includes(claimToken.claims[selectId])
}

export async function isProfile(selectId) {
    const claimToken = await firebase.auth().currentUser.getIdTokenResult(true)
    if([
        userRoles.BIZ_JOBLISTING_ADMIN,
        userRoles.BIZ_JOBLISTING_USER
    ].includes(claimToken.claims[selectId])
    ) {
        return 'job'
    }

    if(
        [
            userRoles.MTR_MENTOR
        ].includes(claimToken.claims[selectId])

    ){
        return 'mentor'
    }

}

export function isNoDataNeedDeepInfo(role) {
    return new Promise( resolve => {
        resolve(
            [
                userRoles.CMP_SEEKER,
                userRoles.SCH_STUDENT,
                userRoles.CMP_USER,
                userRoles.BZK_SEEKER
            ].includes(role)
        )
    })
}
