import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import {
  postContactInfo,
  firebaseInstance,
} from "../firestore/firestoreService";

const FormStyle = styled.div`
  .form-title {
    color: var(--bazookka-pink);
    font-weight: 600;
    font-style: normal;
    font-size: 55px;
    letter-spacing: 0.02em;
    line-height: 0.9em;
    text-align: center;
  }

  .main-container {
    padding: 90px 25% 0px 25%;
  }
  .container {
    padding-top: 30px;
    width: auto;
    margin: auto;
  }
  .container input {
    width: 100%;
    clear: both;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    border-color: #ccc;
  }
  .container label {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0em;
    line-height: 1.6em;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0.01em;
    line-height: 1.3em;
    text-transform: none;
    color: #222;
  }
  .error-msg {
    color: red;
  }

  .radio-container {
    padding-top: 10px;
    padding-bottom: 60px;
    margin: 10px;
    label {
      float: left;
      padding-left: 13px;
      cursor: pointer;
      font-size: 12px;
    }
    input[type="radio"] {
      float: left;
      padding: 20px;
    }
  }
  button {
    color: #fff;
    background-color: #eb6b76;
    border-color: #eb6b76;
    display: block;
    width: 300px;
    height: auto;
    padding: 1em 2.5em;
    border-width: 0.2px;
    border-radius: 100px;
    text-align: center;
    cursor: pointer;
    margin: 60px 0;
    text-transform: uppercase;
  }
`;

const SuccessStyle = styled.div`
  .container {
    padding: 15% 15%;
    display: flex;
    justify-content: center;
  }
  .row {
    width: auto;
  }
  .flex-item {
    background-color: tomato;
    padding-top: 25px;
    padding-bottom: 25px;
    color: white;
    font-weight: bold;
    font-size: 2em;
    text-align: center;
  }
`;

const highlightInput = () => {
  return {
    width: "100%",
    clear: "both",
    padding: "10px",
    margin: "10px 0",
    border: "2px solid red",
  };
};

const recaptchaRef = React.createRef();
const captchaAPIkey = "6LewKqAaAAAAAEpTnSq1KoJ0NaZ9QhM1n49WtXzo";

const regexTelephone = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const regexZipCode = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/; //Canadian zip code
const requiredMessage = <Trans i18nKey={"general.requiredfield"} />;
const invalidFormatMessage = "Invalid format";
const invalidEmailMessage = "Invalid email format";

const schema = Yup.object().shape({
  schoolName: Yup.string().required(requiredMessage),
  address: Yup.string().required(requiredMessage),
  city: Yup.string().required(requiredMessage),
  province: Yup.string().required(requiredMessage),
  zipCode: Yup.string()
    .matches(regexZipCode, invalidFormatMessage)
    .required(requiredMessage),
  country: Yup.string().required(requiredMessage),
  contactName: Yup.string().required(requiredMessage),
  contactTitle: Yup.string().required(requiredMessage),
  phoneNumber: Yup.string()
    .matches(regexTelephone, invalidFormatMessage)
    .required(requiredMessage),
  timeToContact: Yup.string().required(requiredMessage),
  email: Yup.string().email(invalidEmailMessage).required(requiredMessage),
});

const Contact = () => {
  let { t } = useTranslation("common");
  const [success, setSuccess] = useState(false);
  const [captchaSuccess, setCaptchaSuccess] = useState(false);

  // this is called when captcha is solved
  const captchaTokenValidation = async (value) => {

    const validateToken = firebaseInstance()
      .functions()
      .httpsCallable("ValidateClientToken");

    try {
      const response = await validateToken({ data:{value:value }}); //or {value:value} ?
      response.data.result.success? setCaptchaSuccess(true): setCaptchaSuccess(false);
    } catch (error) {
      console.log("error message: " + error);
    }
  };

  //Prevent another call to firebase if the captcha expires
  const resetCaptcha = () => {
    recaptchaRef.current.reset();
  };

  return (
    <FormStyle>
      {!success ? (
        <Formik
          initialValues={{
            schoolName: "",
            address: "",
            address1: "",
            address2: "",
            city: "",
            province: "",
            zipCode: "",
            country: "",
            contactName: "",
            contactTitle: "",
            phoneNumber: "",
            email: "",
            timeToContact: "",
            // recaptcha: "",
          }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            if (captchaSuccess) {
              setSubmitting(true); //This disable the submit button
              //send form data to firestore
              postContactInfo(values)
                .then((docRef) => {
                  resetForm();
                  setSuccess(true); //Will render success message when contact form is successfully submitted
                })
                .catch((error) => {
                  console.log("Error: " + error);
                });
            } else {
              setSubmitting(false);
              resetCaptcha();
            }
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <div className="main-container">
              <h1 className="form-title">
                <Trans i18nKey="contact.formtitle">Communiquez avec nous</Trans>
              </h1>
              <div className="container">
                <Form>
                  <label htmlFor="schoolName">{t("contact.schoolName")}</label>
                  <div className="error-msg">
                    <ErrorMessage name="schoolName" />
                  </div>
                  <Field
                    id="schoolName"
                    name="schoolName"
                    style={
                      errors.schoolName && touched.schoolName
                        ? highlightInput()
                        : null
                    }
                  />

                  <label htmlFor="address">
                    <Trans i18nKey="contact.address">Adresse *</Trans>
                  </label>
                  <div className="error-msg">
                    <ErrorMessage name="address" />
                  </div>
                  <Field
                    id="address"
                    name="address"
                    style={
                      errors.address && touched.address
                        ? highlightInput()
                        : null
                    }
                  />

                  <label htmlFor="address1">
                    <Trans i18nKey="contact.address1">Adresse 1</Trans>
                  </label>
                  <Field id="address1" name="address1" />

                  <label htmlFor="address2">
                    <Trans i18nKey="contact.address2">Adresse 2</Trans>
                  </label>
                  <Field id="address2" name="address2" />

                  <label htmlFor="city">
                    <Trans i18nKey="contact.city">Ville *</Trans>
                  </label>
                  <div className="error-msg">
                    <ErrorMessage name="city" />
                  </div>
                  <Field
                    id="city"
                    name="city"
                    style={
                      errors.city && touched.city ? highlightInput() : null
                    }
                  />

                  <label htmlFor="province">{t("contact.province")}</label>
                  <div className="error-msg">
                    <ErrorMessage name="province" />
                  </div>
                  <Field
                    id="province"
                    name="province"
                    style={
                      errors.province && touched.province
                        ? highlightInput()
                        : null
                    }
                  />

                  <label htmlFor="zipCode">{t("contact.zipCode")}</label>
                  <div className="error-msg">
                    <ErrorMessage name="zipCode" />
                  </div>
                  <Field
                    id="zipCode"
                    name="zipCode"
                    style={
                      errors.zipCode && touched.zipCode
                        ? highlightInput()
                        : null
                    }
                  />

                  <label htmlFor="country">{t("contact.country")}</label>
                  <div className="error-msg">
                    <ErrorMessage name="country" />
                  </div>
                  <Field
                    id="country"
                    name="country"
                    style={
                      errors.country && touched.country
                        ? highlightInput()
                        : null
                    }
                  />

                  <label htmlFor="contactName">
                    {t("contact.contactName")}
                  </label>
                  <div className="error-msg">
                    <ErrorMessage name="contactName" />
                  </div>
                  <Field
                    id="contactName"
                    name="contactName"
                    style={
                      errors.contactName && touched.contactName
                        ? highlightInput()
                        : null
                    }
                  />

                  <label htmlFor="contactTitle">
                    {t("contact.contactTitle")}
                  </label>
                  <div className="error-msg">
                    <ErrorMessage name="contactTitle" />
                  </div>
                  <Field
                    id="contactTitle"
                    name="contactTitle"
                    style={
                      errors.contactTitle && touched.contactTitle
                        ? highlightInput()
                        : null
                    }
                  />

                  <label htmlFor="phoneNumber">{t("contact.phone")}</label>
                  <div className="error-msg">
                    <ErrorMessage name="phoneNumber" />
                  </div>
                  <Field
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    placeholder="123-456-7890"
                    style={
                      errors.phoneNumber && touched.phoneNumber
                        ? highlightInput()
                        : null
                    }
                  />

                  <label htmlFor="email">{t("contact.email")}</label>
                  <div className="error-msg">
                    <ErrorMessage name="email" />
                  </div>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    style={
                      errors.email && touched.email ? highlightInput() : null
                    }
                  />

                  <label htmlFor="timeToContact">
                    {t("contact.timeOfDay")}
                  </label>
                  <div className="error-msg">
                    <ErrorMessage name="timeToContact" />
                  </div>
                  <div className="radio-container">
                    <label>
                      <Field
                        type="radio"
                        name="timeToContact"
                        value="morning"
                      />
                      <Trans i18nKey="contact.am">avant-midi</Trans>
                    </label>
                    <label>
                      <Field
                        type="radio"
                        name="timeToContact"
                        value="afternoon"
                      />
                      <Trans i18nKey="contact.pm">après-midi</Trans>
                    </label>
                    <label>
                      <Field
                        type="radio"
                        name="timeToContact"
                        value="evening"
                      />
                      <Trans i18nKey="contact.evening">soirée</Trans>
                    </label>
                  </div>

                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={captchaAPIkey}
                    onChange={captchaTokenValidation}
                    onExpired={resetCaptcha} //Default behavior of Recaptcha is to call onChange when a captcha expires
                  />

                  <button type="submit" disabled={isSubmitting}>
                    <Trans i18nKey="contact.submit">Soumettre</Trans>
                  </button>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      ) : (
        <Success />
      )}
    </FormStyle>
  );
};

const Success = () => {
  return (
    <SuccessStyle>
      <div className="container">
        <div className="row">
          <div className="flex-item">
            <h1>À bientôt!</h1>
          </div>
          <div className="flex-item">
            <p>Nous serons prochainement en contact avec vous.</p>
          </div>
        </div>
      </div>
    </SuccessStyle>
  );
};

export default Contact;
