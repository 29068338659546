/**
 * @variable userRoles
 * @type {{SCH_SEEKER: string, ALL: (function(): string[]), BZK_GUEST: string, SCH_STUDENT: string, BIZ_JOBLISTING_ADMIN: string, BZK_SEEKER: string, SCH_ADMIN_SCHOOL: string, MTR_MENTOR: string, MTR_PARENT: string, SCH_BUSINESS: string, CMP_STUDENT: string, CMP_EMPLOYEE: string, CMP_USER: string, SCH_TEACHER: string, CMP_ADMIN_BUSINESS: string, BIZ_JOBLISTING_USER: string, MTR_KID: string, CMP_SEEKER: string, BZK_SUPER: string, MTR_ADMIN: string}}
 */
export const userRoles = {
    //School
    SCH_ADMIN_SCHOOL: 'sch.admin_school',
    SCH_BUSINESS: 'sch.business',
    SCH_TEACHER: 'sch.teacher',
    SCH_STUDENT: 'sch.student',
    SCH_SEEKER: 'sch.seeker',

    //Company (Business)
    CMP_ADMIN_BUSINESS: 'cmp.admin_business',
    CMP_STUDENT: 'cmp.student',
    CMP_USER: 'cmp.user',
    CMP_SEEKER: 'cmp.seeker',
    CMP_EMPLOYEE: 'cmp.employee',
    CMP_GUEST: 'cmp.guest',

    //Mentor
    MTR_ADMIN: 'mtr.admin',
    MTR_MENTOR: 'mtr.mentor',
    MTR_KID: 'mtr.kid',
    MTR_PARENT: 'mtr.parent',

    //Biz
    BIZ_JOBLISTING_ADMIN: 'biz.joblisting_admin',
    BIZ_JOBLISTING_USER: 'biz.joblisting_user',

    //super user
    BZK_SUPER: 'bzk.super',

    //Guest they can only see internship object and calendar
    BZK_GUEST: 'bzk.guest',
    BZK_SEEKER: 'bzk.seeker',
    ALL: function(){
        return Object.keys(this)
    }
}
/**
 *
 * @param type :string
 * @param t :function i18n
 * @returns {{value: *, key: *}[]}
 */
export const is2Object = (type, t) => {
  const company = isCompany(type);
  return company.map( com => {
      return {
          key: com,
          value: t(com)
      }
  })
}
/**
 *
 * @param type :string :enum
 * @returns {[string,string,string,string]|[string,string,string,string,string]|*[]|[string,string,string,string,string,null]|[string,string]}
 */
export const isCompany = (type) => {
    switch(type){
        case 'listings':
            return [
                userRoles.BIZ_JOBLISTING_ADMIN,
                userRoles.BIZ_JOBLISTING_USER
            ]
        case 'business':
            return [
                userRoles.CMP_ADMIN_BUSINESS,
                userRoles.CMP_EMPLOYEE,
                userRoles.CMP_USER,
                userRoles.CMP_SEEKER,
                userRoles.CMP_STUDENT,
                userRoles.BZK_GUEST,
            ]
        break;
        case 'company':
            return [
               userRoles.CMP_ADMIN_BUSINESS,
               userRoles.CMP_EMPLOYEE,
               userRoles.CMP_USER,
               userRoles.CMP_SEEKER,
               userRoles.CMP_STUDENT,
               userRoles.BZK_GUEST,
            ]
        break;
        case 'school':
            return [
                userRoles.SCH_ADMIN_SCHOOL,
                userRoles.SCH_BUSINESS,
                userRoles.SCH_TEACHER,
                userRoles.SCH_STUDENT,
                userRoles.BZK_GUEST,
            ]
        break;
        case 'mentor':
            return [
                userRoles.MTR_ADMIN,
                userRoles.MTR_MENTOR,
                userRoles.MTR_PARENT,
                userRoles.MTR_KID,
            ]
        break;
        default:
            return []
    }

}
