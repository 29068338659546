import { useContext, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { MyContext } from "../stores";
import { JustCoverBG } from "./main/CoverBG";
import { FormSignUpStyled } from "./SignUp/Registration";
import { ErrorMessage, Form, Formik } from "formik";
import { InputText, SwitchBox, TextAreaInput } from "../components/FormElements";
import { db, firebaseInstance, storage, usersDoc } from "../firestore/firestoreService";
import { useCatReducer } from "../stores/categories";
import { userRoles } from "../stores/enums/userRoles";
import * as Yup from "yup"
import 'react-languages-select/css/react-languages-select.css';
import { CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import FieldSetStyled from "../forms/FieldSetStyled";
import { useAlert } from "react-alert";



export default function UserRegistration() {
    const { t } = useTranslation()
    const [apicall, setApicall] = useState(true)
    const [registrationError, setRegistrationError] = useState()
    const { me, setMe, verifyAuth, langChoice, dispatchModal } = useContext(MyContext)
    const [type, setType] = useState('password')
    const regexEmail = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/;
    const history = useHistory();
    const alert = useAlert()
 
    const [data, setData] = useState({
        role: userRoles.BZK_SEEKER, schoolId: ['0uuQdR7ieoV3xJOSmc7w'], select: {
            id: '0uuQdR7ieoV3xJOSmc7w',
            name: 'Bazookka Inc.',
            type: userRoles.BZK_SEEKER
        }
    })


    const [initialValues, setInitialValues] = useState({
        displayName: "",
        email: "",
        name: "",
        gender: "f",
        role: userRoles.BZK_SEEKER,
        schoolId: ['0uuQdR7ieoV3xJOSmc7w'],
        select: {
            id: '0uuQdR7ieoV3xJOSmc7w',
            name: 'Bazookka Inc.',
            type: userRoles.BZK_SEEKER
        }
    });

    useEffect(() => {
        if (me) {
            history.push("/")
        }
    }, [me])



    const SignupSchema = Yup.object().shape({
        displayName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required(t('forms.errors.displayNameRequired')),
        email: Yup.string().required(t('forms.errors.emailRequired')).matches(regexEmail, t('forms.errors.emailFormatInvalid')),
        password: Yup.string().min(5).required(t("forms.errors.passwordRequired")),
        cpassword: Yup.string().min(5).oneOf([Yup.ref('password'), null], t("forms.errors.passwordConfirmationMismatch")).required(),
    });


    const createAccount = async (v) => {
      
        try {
            const createUsers = firebaseInstance().functions().httpsCallable("CreateUsers")
      
            const data = await createUsers({
                email: v.email,
                password: v.password,
                displayName: v.displayName,
                schoolId: "0uuQdR7ieoV3xJOSmc7w",
                claims: { "0uuQdR7ieoV3xJOSmc7w": "bzk.seeker", 'defaultRole': "bzk.seeker" }
            })

            localStorage.setItem("stepOneData",JSON.stringify({
                email: v.email,
                password: v.password,
                displayName: v.displayName,
                schoolId: "0uuQdR7ieoV3xJOSmc7w",
                uid: data.data.id,
                claims: { "0uuQdR7ieoV3xJOSmc7w": "bzk.seeker", 'defaultRole': "bzk.seeker" }
            }))

            const sendEmail = firebaseInstance().functions().httpsCallable('SendEmail');
            const langTemplate = langChoice === 'fr' ? 'frSeekerStepTwoRegistration' : 'enSeekerStepTwoRegistration';
            await sendEmail({template : langTemplate, displayName : v.displayName, email : v.email, password : v.password})
            .then(val => {
                alert.show(t("registration.complete_registration_message"),{type : "success",timeout : 0})
            })

        } catch (e) {
            var errorCode = e.code;
            if (errorCode === 'auth/email-already-in-use') {

                setRegistrationError(t('registration.error.user_exists'))
            } else if (errorCode === "auth/invalid-email") {
                setRegistrationError(t('registration.error.invalid_email'))
            } else {
                alert.show(t("registration.error.user_exists"),{type : "error",timeout : 0})
            }
            return e.message
        }
        return data.displayName

    }

    const onSubmit = async (v, { setSubmitting }) => {
        setSubmitting(true)
        await createAccount(v)
        setSubmitting(false)
    }

    return (
        <>
            <JustCoverBG />
            <FormSignUpStyled>
                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={SignupSchema} enableReinitialize={true}>
                    {({ values, errors, isSubmitting, setFieldValue }) => {
                
                        return (
                            <Form>
                                <FieldSetStyled>
                                    <legend><pre> {t("forms.accountInformation")} </pre></legend>
                                    <InputText
                                        label={t('listing.name') + ' *'}
                                        value={values.displayName}
                                        name="displayName" />
                                    <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.displayName}</div>} name="displayName" />

                                    <FormControl>
                                        <FormLabel sx={{ fontFamily: "Gotham Rounded bold", color: "#363636", fontSize: "0.8rem" }} id="demo-row-radio-buttons-group-label">{t('forms.gender')}</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            defaultValue={JSON.stringify({"en" : "woman","fr" : "femme" })}
                                            display="block"
                                            onChange={(event) => {
                                                setFieldValue("gender", JSON.parse(JSON.stringify(event.target.value)))
                                            }}
                                        >
                                            <FormControlLabel value={JSON.stringify({"en" : "woman","fr" : "femme" })} control={<Radio />} label={t("forms.gender.woman")} />
                                            <FormControlLabel value={JSON.stringify({"en" : "man","fr" : "homme" })}  control={<Radio />} label={t("forms.gender.man")} />
                                            <FormControlLabel value={JSON.stringify({"en" : "non-binary","fr" : "Non-binaire" })}  control={<Radio />} label={t("forms.gender.nonBinary")}  />
                                            <FormControlLabel value={JSON.stringify({"en" : "prefer not to say","fr" : "préfère ne pas répondre" })}  control={<Radio />} label={t("forms.gender.preferNotToSay")}  />
                                            <FormControlLabel value={JSON.stringify({"en" : "not listed","fr" : "Non listé" })}  control={<Radio />} label={t("forms.gender.notListed")}  />
                                        </RadioGroup>
                                    </FormControl>
                                    <br />


                                    <InputText
                                        label={t('general.emailaddress') + ' *'}
                                        value={values.email}
                                        name="email" />
                                    <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.email}</div>} name="email" />
                                    <InputText
                                        label={t('general.pwd') + " *"}
                                        name="password"
                                        value={values.password}
                                        type={type}
                                    />
                                    <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.password}</div>} name="password" />
                                    <InputText
                                        label={t('general.rpwd') + " *"}
                                        name="cpassword"
                                        value={values.cpassword}
                                        type={type}
                                    />
                                    <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.cpassword}</div>} name="cpassword" />
                                    <SwitchBox
                                        label={t('form.revealPassword')}
                                        name="display"
                                        onClick={() => values.display ? setType('password') : setType('text')}
                                    ></SwitchBox>

                           
                                </FieldSetStyled>

                                <button style={{ margin: "auto 0" }} className="button" type="submit" disabled={isSubmitting}>{t("general.register")}</button>
                                {isSubmitting && <CircularProgress />
                                }
                                {registrationError && <h1 style={{ color: "red" }}>{registrationError}</h1>}
                            </Form>
                        )
                    }}

                </Formik>
            </FormSignUpStyled>
        </>

    )
}