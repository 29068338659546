import React, {useContext, useEffect} from "react";
import {useLocation} from 'react-router-dom';
import {MyContext} from "../stores";
import Loader from "../components/Loader";
import queryString from 'query-string';
import firebase from "firebase/app";

export default function Authorization(){
    const {globalId} = useContext(MyContext)
    const location = useLocation();

    useEffect(() => {
        console.log("location", location);
        if(globalId){
            if(location.search && location.search !== ''){
                const urlValues = queryString.parse(location.search)
                if(urlValues.state && urlValues.state === 'azure'){
                    //AZURE
                    const userAzureCodeDatabaseRef = firebase.database().ref('/externalApi/azure/code/' + globalId);
                    const azureCode = {
                        code: urlValues.code,
                        updated: firebase.database.ServerValue.TIMESTAMP,
                    };
                    userAzureCodeDatabaseRef.set(azureCode).then(function () {
                        window.close();
                    });
                }else{
                    //ZOOM
                    const userZoomCodeDatabaseRef = firebase.database().ref('/externalApi/zoom/code/' + globalId);
                    const zoomCode = {
                        code: urlValues.code,
                        updated: firebase.database.ServerValue.TIMESTAMP,
                    };
                    userZoomCodeDatabaseRef.set(zoomCode).then(function () {
                        window.close();
                    });
                }
            }
        }
    }, [location, globalId])

    return (
        <Loader></Loader>
    )
}