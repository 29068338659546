import React, {useEffect, useState, useContext} from "react";
import {MyContext} from "../stores";
import {firebaseInstance, usersDoc} from '../firestore/firestoreService'
import styled from 'styled-components';

const BookmarkButtonContainer = styled.div`
    position: relative;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background: #fff;
    -webkit-box-shadow: 0px 5px 43px rgba(0,0,0,0.18);
    box-shadow: 0px 5px 43px rgba(0,0,0,0.18);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    opacity: 1 !important;
    overflow: hidden;
    cursor:pointer;

    i.bookmarked{
        color:var(--bazookka-yellow);
    }

    &:hover{
        transition: all .3s;
        transform: scale(1.1);

        i{
            transition: all .3s;
            font-size:20px;
        }
    }
`

const BookmarkButton = ({postId, bookmarks = [], className = null}) => {
    const {globalId} = useContext(MyContext);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [isBookmarkedClass, setIsBookmarkedClass] = useState("");
    let wrapperClass = className ? className : '';

    useEffect(() => {
        setIsBookmarked(bookmarks.includes(postId));
        const tempBookmarkedClass = isBookmarked ? "bookmarked" : "";
        setIsBookmarkedClass(tempBookmarkedClass);
    }, [globalId, bookmarks, isBookmarked])

    const bookmarksSubCollectionExists = () => {
        let exists = false;
        usersDoc.doc(globalId).collection('bookmarks').limit(1).get().then(res => {
            if(res.size > 0){
                exists = true
            }
        })
        return exists
    }

    const handleClick = () => {
        if(isBookmarked){
            //Removing Like
            usersDoc.doc(globalId).collection('bookmarks').doc('bookmarkDocument').update({
                bookmarks: firebaseInstance().firestore.FieldValue.arrayRemove(postId)
            });
            setIsBookmarked(false);
        }else{
            //Adding Like
            if(!bookmarksSubCollectionExists()){
                // usersDoc.doc(globalId).collection('bookmarks').add({})
                usersDoc.doc(globalId).collection('bookmarks').doc("bookmarkDocument").set({})
            }
            usersDoc.doc(globalId).collection('bookmarks').doc('bookmarkDocument').update({
                bookmarks: firebaseInstance().firestore.FieldValue.arrayUnion(postId)
            });
            setIsBookmarked(true);
        }
    }

    return (
        <BookmarkButtonContainer className={wrapperClass} onClick={handleClick}>
            <a className="bookmark-button">
                <i className={`mdi mdi-bookmark bouncy ${isBookmarkedClass}`}></i>
                <span className="like-overlay"></span>
            </a>
        </BookmarkButtonContainer>
    )
}

export default BookmarkButton;
