import { useContext, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { MyContext } from "../stores";
import { JustCoverBG } from "./main/CoverBG";
import { FormSignUpStyled } from "./SignUp/Registration";
import { Multiselect } from "multiselect-react-dropdown";
import { ErrorMessage, Form, Formik } from "formik";
import { InputText, SwitchBox, TextAreaInput } from "../components/FormElements";
import { db, firebaseInstance, storage, usersDoc } from "../firestore/firestoreService";
import ReactSelect from 'react-select'
import { useDeBounce } from "../helper";
import { useCatReducer } from "../stores/categories";
import { userRoles } from "../stores/enums/userRoles";
import * as Yup from "yup"
import Avatar from 'react-avatar';
import styled from "styled-components";
import { PencilLineIco } from "../ico";
import 'react-languages-select/css/react-languages-select.css';
import { Autocomplete, Chip, CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@mui/material";
import countries from "../stores/countryNames"
import { languages } from "../stores/languagesNames"
import ethnicities from "../stores/ethnicities"
import profileTypes from "../stores/profileTypes"
import FieldSetStyled from "../forms/FieldSetStyled";
import { useHistory } from "react-router-dom";

export default function CompleteSeekerRegistration(){

    
    const [select, setSelect] = useState(null)
    const [options, setOptions] = useState([])
    const [categories, dispatchAll] = useCatReducer();
    const addressRef = useRef()
    const [saved, setSave] = useState(false)
    const { me, setMe, verifyAuth, langChoice, dispatchModal, apicall, setApicall } = useContext(MyContext)
    const regexTelephone = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const [waitingAddressSuggestion, setWaitingAddressSuggestion] = useState(true)
    const [avatarToUplaod, setAvatarToUpload] = useState(null)
    const [coverToUpload, setCoverToUpload] = useState(null)
    const requiredMessage = <Trans i18nKey={"general.requiredfield"} />;
    const invalidFormatMessage = "Invalid format. Allowed formats: \n 123-456-7890 \n 075-63546725 ";
    const { t } = useTranslation()
    const [registrationError, setRegistrationError] = useState()
    const [stepOneData, setStepOneData] = useState({})
    const history = useHistory();

    useEffect(() => {    
        if(localStorage.hasOwnProperty('stepOneData')){
            setStepOneData(JSON.parse(localStorage.getItem("stepOneData"))) 
        }
    },[])

    const AvatarEditButton = styled.div`
    position: relative;
    bottom: 30px;
    left: 70px;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3d70b2;
    box-shadow: 0 14px 26px -12px rgb(61 112 178 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(61 112 178 / 20%) !important;
    transform: rotate(0);
    transition: all .3s;
    cursor: pointer;
    z-index: 1;

    svg{
        height: 18px;
        width: 18px;
        stroke: #fcfcfc;
    }
`

useEffect(() => {
    dispatchAll({
        type: langChoice === 'fr' ? 'ALL_SET_FR' : 'ALL_SET_EN'
    })
    if (apicall) {
        setApicall(!apicall)
    }
}, [apicall])

    const initialValues = {
        secondayLanguages: [],
        maternalLanguage: "",
        country: {},
        tel: "",
        address: "",
        ethnicity: "",
        choosenProfile: {"fr" : "Je suis un.e étudiant.e AVEC résidence ou citoyenneté canadienne",
        "en" : "I am a student WITH Canadian residency or citizenship"}
    }

    categories && categories.pop()

    const handleChange = (v) => {
        setSelect(v)
        setSave(addressRef?.current?.state?.value?.label)
    }

    useDeBounce(() => updateRequest(select), 500, [select])

    const updateRequest = async (value) => {
        let addressGetter = firebaseInstance().functions().httpsCallable('googleAddress')
        if (value.length > 7) {
            addressGetter({ value }).then((response) => {
                setOptions(response.data.response.predictions.map((d, index) => {
                    return { value: d.description, label: d.description }
                }))
                setWaitingAddressSuggestion(false)
            })

        }
    }

    const SignupSchema = Yup.object().shape({});

    const handleCroppedAvatar = (croppedImage) => {
        setAvatarToUpload(croppedImage)
        dispatchModal({ type: 'CLOSE_MODAL' })
    }
    const handleCroppedCover = (croppedImage) => {
        setCoverToUpload(croppedImage)
        dispatchModal({ type: 'CLOSE_MODAL' })
    }

    const editAvatar = (e) => {
        e.preventDefault();

        dispatchModal({ type: 'OPEN_MODAL', payload: { modalType: 'BzkSeekerAvatarCropUploadModal', modalProps: { returnCroppedImage: handleCroppedAvatar, title: t("modal.title.editSeekerAvatar"), ico: <PencilLineIco />, closeOut: () => dispatchModal({ type: 'CLOSE_MODAL' }) } } })
    }

    const editCover = (e) => {
        e.preventDefault();
        dispatchModal({ type: 'OPEN_MODAL', payload: { modalType: 'BzkSeekerCoverCropUploadModal', modalProps: { returnCroppedImage: handleCroppedCover, title: t('modal.title.editSeekerCover'), ico: <PencilLineIco />, closeOut: () => dispatchModal({ type: 'CLOSE_MODAL' }) } } })
    }


    const returnCat = (cat, list, values) => {
        return values[cat] && values[cat].map(k => {
            return list.find(l => l.key === k)
        }) || []
    }

    const handleAvatarUpload = (uid) => {
        var storageRef = storage.ref();
        if(avatarToUplaod){
            var fileRef = storageRef.child(`/profile_pictures/profile/${uid}/${avatarToUplaod.name}`);
            fileRef.put(avatarToUplaod).then((snapshot) => {
                onUploadAvatar(uid, snapshot.ref, snapshot.metadata);
            }).catch((error) => {
                console.log(error)
            });
        }
    }

    const onUploadAvatar = (uid, fileRef, metadata) => {
        fileRef.getDownloadURL().then((url) => {
            db.collection("users")
                .doc(uid)
                .update({ avatar: url })
        });
    };

    const onUploadCover = (uid, fileRef, metadata) => {
        fileRef.getDownloadURL().then((url) => {
            db.collection("users")
                .doc(uid)
                .update({ cover: url })
        });
    };

    const handleCoverUpload = (uid) => {
        var storageRef = storage.ref();
        if(coverToUpload){
            var fileRef = storageRef.child(`/profile_pictures/cover/${uid}/${coverToUpload.name}`);
            fileRef.put(coverToUpload).then((snapshot) => {
                onUploadCover(uid, snapshot.ref, snapshot.metadata);
            }).catch((error) => {
                console.log(error)
            });
        }
    }
    const onSubmit = async (v) => {
        let  doc
        const logsOverview = {
            totalLog: 0,
            cancellationLog: 0,
            applicationLog: 0,
            conversationReplyLogCount: 0,
            activeJobAddLogCount: 0,
            eventRegistrationLogCount: 0,
            eventRegistrationCancelLogCount: 0,
            inviteAcceptationLogCount: 0,
            inviteCancelationLogCount: 0
        }

        let fullData = { ...v, logsOverview,...stepOneData }

        fullData.completed = 'true'
        fullData.groupsId = ["all"]
        delete fullData.cover
        fullData.claims = { '0uuQdR7ieoV3xJOSmc7w': 'bzk.seeker', 'defaultRole': 'bzk.seeker' }
        fullData.role = "bzk.seeker"
        fullData.select = {
            id: '0uuQdR7ieoV3xJOSmc7w',
            name: 'Bazookka Inc.',
            type: userRoles.BZK_SEEKER
        }
        
        if(stepOneData){
            await usersDoc.doc(stepOneData.uid).set(fullData,{ merge: true })
            await usersDoc.doc(stepOneData.uid).collection('schools').doc('0uuQdR7ieoV3xJOSmc7w').set({
                id: '0uuQdR7ieoV3xJOSmc7w',
                name: 'Bazookka Inc.',
                type: 'bzk'
            })

            await firebaseInstance()
            .auth()
            .signInWithEmailAndPassword(fullData.email, fullData.password);
            handleAvatarUpload(stepOneData.uid)
            handleCoverUpload(stepOneData.uid)
            delete fullData.password
            delete fullData.cpassword
            history.push('/me')
        }
     
    }

    return (
        <>
                  <JustCoverBG />
            <FormSignUpStyled>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={SignupSchema} enableReinitialize={true}>
                    {({ values, errors, isSubmitting, setFieldValue }) => {
                        return <Form>
                                                  <FieldSetStyled>
                                    <legend> <pre> {t('forms.personalInformation')} </pre></legend>

                                    <label style={{ fontFamily: "Gotham Rounded bold", color: "#363636", fontSize: "0.9rem" }} >{t('forms.country')}</label>
                                    <Autocomplete
                                        sx={{ width: "100%", border: "1px solid transparent" }}
                                        disablePortal
                                        id="combo-box-demo"
                                        options={countries}
                                        getOptionLabel={(option) => langChoice === "fr" ? option.fr : option.en}
                                        onChange={(e, value) => {
                                            setFieldValue(
                                                "country",
                                                value
                                            );
                                        }}
                                        renderInput={(params) => <TextField {...params} label={langChoice === "fr" ? "selectionner un pays" : "select a country"} />}
                                    />
                                    <label style={{ fontFamily: "Gotham Rounded bold", color: "#363636", fontSize: "0.9rem" }} >{t('forms.principalLanguage')}</label>
                                    <Autocomplete
                                        sx={{ width: "100%", border: "1px solid transparent" }}
                                        disablePortal
                                        id="combo-box-demo"
                                        options={[{"code " : "n/a", "french" : "Préfère ne pas répondre","english" : "Prefer not to answer" }].concat(languages)}
                                        getOptionLabel={(option) => langChoice === "fr" ? option.french : option.english}
                                        onChange={(e, value) => {
                                            setFieldValue("maternalLanguage", value)
                                        }}
                                        renderInput={(params) => <TextField {...params} label={langChoice === "fr" ? "séléctionnez votre langue maternelle" : "select your main languge"} />}
                                    />
                                    <label style={{ fontFamily: "Gotham Rounded bold", color: "#363636", fontSize: "0.9rem" }} >{langChoice === "fr" ? "Langues secondaires" : "Secondary Languages"}</label>
                                    <Autocomplete
                                        multiple
                                        id="tags-outlined"
                                        options={languages}
                                        getOptionLabel={(option) => langChoice === "fr" ? option.french : option.english}
                                        onChange={(e, value) => {
                                            setFieldValue("secondayLanguages", value)
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    key={index}
                                                    sx={{ backgroundColor: "#0096fb" }}
                                                    variant="outlined"
                                                    label={`${langChoice === "fr" ? option.french : option.english}`}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={langChoice === "fr" ? "séléctionnez votre langues secondaires" : "select your secondary languges"}
                                            />
                                        )}
                                />

                                    <label style={{ fontFamily: "Gotham Rounded bold", color: "#363636", fontSize: "0.9rem" }} >{langChoice === "fr" ? "Ethnicité" : "Ethnicity"}</label>
                                    <Autocomplete
                                        id="tags-outlined"
                                        options={[{"code " : "n/a", "fr" : "Préfère ne pas répondre","en" : "Prefer not to answer" }].concat(ethnicities)}
                                        getOptionLabel={(option) => langChoice === "fr" ? option.fr : option.en}
                                        onChange={(e, value) => {
                                            setFieldValue("ethnicity", value)
                                        }}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={langChoice === "fr" ? "séléctionnez votre ethnicité" : "select your ethnicity "}
                                            />
                                        )}
                                    />

                                    <label style={{ fontFamily: "Gotham Rounded bold", color: "#363636", fontSize: "0.9rem" }} >{t("general.address") + " *"}</label>
                                    <ReactSelect
                                        loadingMessage={() => 'searching...'}
                                        defaultValue={values.address || ''}
                                        options={options}
                                        isLoading={waitingAddressSuggestion}
                                        onInputChange={handleChange}
                                        ref={addressRef}
                                    />
                                    <InputText label={t('settings.phone') + ' *'} name="tel" />
                                    <ErrorMessage render={error => <div style={{ color: 'red', whiteSpace: "pre-wrap" }}>{errors.tel}</div>} name="tel" />
                                    <TextAreaInput label={t('forms.seekerIntro')} name="intro" />
                                </FieldSetStyled>

                                <FieldSetStyled>
                                    <legend> <pre> {t('forms.seekerProfile')} </pre></legend>
                                    <label style={{ fontFamily: "Gotham Rounded bold", color: "#363636", fontSize: "0.9rem" }} >{t('forms.chooseProfile')}</label>
                                    <Autocomplete
                                        id="tags-outlined"
                                        options={profileTypes}
                                        defaultValue={ {"fr" : "Je suis un.e étudiant.e AVEC résidence ou citoyenneté canadienne",
                                        "en" : "I am a student WITH Canadian residency or citizenship"
                                    }}
                                        getOptionLabel={(option) => langChoice === "fr" ? option.fr : option.en}
                                        onChange={(e, value) => {
                                            setFieldValue("choosenProfile", value)
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    key={index}
                                                    sx={{ backgroundColor: "#0096fb" }}
                                                    variant="outlined"
                                                    label={`${langChoice === "fr" ? option.fr : option.en}`}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={langChoice === "fr" ? "séléctionnez votre langues secondaires" : "select your secondary languges"}
                                            />
                                        )}
                                />
                                </FieldSetStyled>
                                <FieldSetStyled>
                                    <legend> <pre> {t('forms.skillsAndStudyField')} </pre></legend>
                                    {categories && categories.map((cat, i) => {
                                        return (
                                            <div className="field" key={i}>
                                                <label>{t(cat[0])}
                                                    <div className="control">
                                                        <Multiselect
                                                            options={cat[1] || []}
                                                            displayValue="value"
                                                            placeholder={t('overlay.select')}
                                                            selectionLimit={["teamvalues","workactivities","benefits","leader"].includes(cat[0])  ? 3 : -1}
                                                            selectedValues={returnCat(cat[0], cat[1], values)}
                                                            emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                            onSelect={(v) => {
                                                                values[cat[0]] = v.map(k => k.key)
                                                            }}
                                                            onRemove={(v) => {
                                                                values[cat[0]] = v.map(k => k.key)
                                                            }}

                                                        >
                                                        </Multiselect>
                                                    </div>
                                                </label>
                                            </div>
                                        )
                                    })}
                                </FieldSetStyled>

                                <FieldSetStyled>
                                    <legend> <pre> {t('forms.profileImages')} </pre></legend>
                                    {t('upload.profilePicture')} <br />
                                    <Avatar src={avatarToUplaod ? URL.createObjectURL(avatarToUplaod) : ""} round={true} style={{ zIndex: 1 }} />
                                    <AvatarEditButton style={{ textAlign: "center" }} onClick={editAvatar}>
                                        <PencilLineIco />
                                        <i data-feather="plus"></i>
                                    </AvatarEditButton>
                                    <div>
                                        <a className="cover-edit modal-trigger" data-modal="change-cover-modal" onClick={editCover}>
                                            <i className="mdi mdi-camera"></i>
                                            <span>{t('upload.banner')}</span>
                                        </a>
                                    </div>
                                    <img className="cover-image" src={coverToUpload ? URL.createObjectURL(coverToUpload) : 'assets/old/wallpaper.png'}
                                        data-demo-src="assets/img/demo/bg/4.png" alt="" />
                                    <br />
                                </FieldSetStyled>


                                <button style={{ margin: "auto 0" }} className="button" type="submit" disabled={isSubmitting}>{t("general.finishRegistration")}</button>
                                {isSubmitting && <CircularProgress />}
                                {registrationError && <h1 style={{ color: "red" }}>{registrationError}</h1>}
                        </Form>
                    }
                }
            </Formik>
            </FormSignUpStyled>
        </>
    )
}