import React, { useState, useCallback, useRef, useEffect } from 'react';
import firebase from "firebase/app";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import slc from 'styled-components';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

const SaveButton = slc.a`
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
`

const ChooseButton = slc.a`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
`

const MinRequirements = slc.div`
    position: relative;
    width:fit-content;
    left: 50%;
    transform: translateX(-50%);
    color:white;
`

function ImageCrUpload({dest, onUpload, aspectRatio = 16/9, minHeight, minWidth, returnMode = false, onReturn, filename,queueStatus, queue, setQueue, onQueueComplete}) {
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const {t} = useTranslation();
    const [crop, setCrop] = useState({width: minWidth, minHeight:minHeight, aspect: aspectRatio });
    const defaultFileName = 'croppedCover.jpeg';
    const alert = useAlert()

    const handleFinishCrop = (croppedFile) => {
        if(queueStatus && queue && setQueue){
            handleQueue(croppedFile)
        }else{
            setCrop(croppedFile)
        }
    }

    const handleQueue = croppedFile => {
        setQueue(prevState => {
          let newState = [...prevState, croppedFile];
          return newState;
        });
      };

      useEffect(() => {
        if(queueStatus === 'uploading' && dest !== ''){
          const uploadPromises = []
          queue.forEach(file => {
            const uploadPromise = new Promise((resolve,reject) => {
              //File Upload process For Queue
              var storageRef = firebase.storage().ref();
              var fileRef = storageRef.child(dest+file.name);
              fileRef.put(file).then((snapshot) => {
                onUpload(snapshot.ref, snapshot.metadata);
                resolve();
              }).catch((error) => {
                reject(error);
              });
            });
    
            uploadPromises.push(uploadPromise)
          });
          Promise.all(uploadPromises).then((response) => {
              if(onQueueComplete){
                onQueueComplete();
              }
            }
          ).catch((error) => {
            console.log('Error during file upload.', error)
          })
        }
      }, [queueStatus, dest])

    

    
    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }
                blob.name = fileName;
                resolve(blob);
        }, 'image/jpeg');
        });
    }

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();

            reader.addEventListener('load', () => {
                //Checking the chosen image size.
                const img = new Image;
                img.onload = function() {
                    if(img.width >= minWidth && img.height >= minHeight){
                        setUpImg(reader.result);
                    }else{
                        alert.error(t("general.imagesizeerror"));
                    }
                }
                img.src = reader.result;
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback((img) => {
            imgRef.current = img;
      }, []);

    const hiddenFileInput = React.useRef(null);

    const handleChoose = event => {
        hiddenFileInput.current.click();
    };
    
    const handleSave = async (event) => {
        const fileName = filename ? filename : defaultFileName;
        const croppedImage = await getCroppedImg(imgRef.current, crop, fileName);
        if(returnMode){
            onReturn(croppedImage);
            // clo
        }else{
            var storageRef = firebase.storage().ref();
            var fileRef = storageRef.child(dest+croppedImage.name);
            fileRef.put(croppedImage).then((snapshot) => {
                onUpload(snapshot.ref, snapshot.metadata);
                event.target.value = null;
            });
        }
    };

    return (
    <div>
        <ChooseButton className="action button is-rounded mb-2" onClick={handleChoose}>
            Choose Picture
        </ChooseButton>
        <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => handleFinishCrop(c)}
            minWidth={minWidth}
            minHeight={minHeight}
        />
        {!upImg &&
            <MinRequirements>{`Minimum Size: ${minWidth} x ${minHeight}`}</MinRequirements>
        }
        {upImg &&
            <SaveButton className="button is-rounded mt-2 is-primary" onClick={handleSave}>
            Save
            </SaveButton>
        }

        
        <input type="file" accept="image/*" onChange={onSelectFile} style={{display:'none'}} ref={hiddenFileInput} />
    </div>
    )
}

export default ImageCrUpload
