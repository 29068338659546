import React, {useEffect, useState} from "react";
import firebase from "firebase/app";
import { storage } from "../firestore/firestoreService";

//queueStatus && queue && setQueue need to be set in order to Use the Queue system.
  //onQueueComplete is optional
//Also state management of the all the queue needs to be done by the parent component.
//QueueStatus should have 3 states:
  //idle
  //uploading
  //complete

export default function FileUpload({children, dest, onUpload, queueStatus, queue, setQueue, onQueueComplete}){ 
  const hiddenFileInput = React.useRef(null);
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleQueue = event => {
    let file = event.target.files[0];
    setQueue(prevState => {
      let newState = [...prevState, file];
      return newState;
    });
    event.target.value = null;
  };

  function handleUpload(event) {
    let file = event.target.files[0];
    var storageRef = firebase.storage().ref();
    var fileRef = storageRef.child(dest+file.name);
    fileRef.put(file).then((snapshot) => {
      onUpload(snapshot.ref, snapshot.metadata);
      event.target.value = null;
    });
  }

  //Queueing Process
  useEffect(() => {
    if(queueStatus === 'uploading' && dest !== ''){
      const uploadPromises = []
      queue.forEach(file => {
        const uploadPromise = new Promise((resolve,reject) => {
          //File Upload process For Queue
          var storageRef = firebase.storage().ref();
          var fileRef = storageRef.child(dest+file.name);
          fileRef.put(file).then((snapshot) => {
            onUpload(snapshot.ref, snapshot.metadata);
            resolve();
          }).catch((error) => {
            reject(error);
          });
        });

        uploadPromises.push(uploadPromise)
      });
      Promise.all(uploadPromises).then((response) => {
          if(onQueueComplete){
            onQueueComplete();
          }
        }
      ).catch((error) => {
        console.log('Error during file upload.', error)
      })
    }
  }, [queueStatus, dest])

  return (
    <div id="upload-box">
      <a className="action" onClick={handleClick}>
        {children}
      </a>
      {queueStatus && queue && setQueue ?
      <input type="file" onChange={handleQueue} style={{display:'none'}} ref={hiddenFileInput} />
      :
      <input type="file" onChange={handleUpload} style={{display:'none'}} ref={hiddenFileInput} />}
    </div>
  );
}