import React, {useState, useEffect, useContext} from 'react';
import {useParams, useHistory} from "react-router-dom";
import MoreAboutYou from './MoreAboutYou';
import Create from './Create';
import SecureAccount from './SecureAccount';
import ProcessBarWrap from './ProcessBarWrap';
import AccountType from './AccountType';
import StepButton from './StepButton';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { version as uuidVersion, validate as uuidValidate } from 'uuid';
import {db} from "../../firestore/firestoreService";
import {firebaseInstance, schoolsDoc, usersDoc} from "../../firestore/firestoreService";
import {MyContext} from "../../stores"
import {useTranslation} from "react-i18next";
import Registration, {YouveRegister, JustRegister} from "./Registration";
import Password from "./Password";
import {useDeBounce} from "../../helper";
import {JustCoverBG} from "../main/CoverBG";


const SignUp = props => {
    const {me} = useContext(MyContext);
    const {t} = useTranslation()
    let history = useHistory();
    const [signUpStep, setSignUpStep] = useState(1);
    const [isInvitation, setIsInvitation] = useState(false);
    const [invitation, setInvitation] = useState(null);
    const [created, setCreated] = useState(false);
    const [accountRole, setAcccountRole] = useState(null);
    const [badToken, setBadToken] = useState(false);
    const [id, setId] = useState(null);
    const [regist, isRegist] = useState(false)
    const register = firebaseInstance()
        .functions()
        .httpsCallable('checkUserExist')
    const [initialValues, setInitialValues] = useState({
        displayName: "",
        email: "",
        name: "",
        address: "",
        invites: []
    });
    const {token} = useParams();

    const updateStep = step => {
        setSignUpStep(step);
    }

    function uuidValidateV5(uuid) {
        return uuidValidate(uuid) && uuidVersion(uuid) === 5;
    }

    useEffect(async ()=> {
        if(!regist) {
           let check = await register({email: initialValues.email})
            if(check?.data?.email) {
               isRegist(true)
           }
        }
    }, [regist, initialValues])

    useEffect(()=> {
        const verifyToken = token =>  {
            if(uuidValidateV5(token)){
                db.collection("createAccountInvitations").where('token', '==' , token).get().then( data => {
                    let tokenFound = data.docs.map( d => {
                        return { id: d.id,  data: d.data() };
                    });

                    if(tokenFound.length > 0)  { // and not accepted
                        if(tokenFound[0].data.acceptedAt === null) {
                            setInvitation(tokenFound[0]);
                            if(tokenFound[0].data.meta.role === 'sch.business') {
                                setSignUpStep(1);
                            } else {
                                setSignUpStep(1);
                            }

                            setIsInvitation(true);
                            setAcccountRole(tokenFound[0].data.meta.role);
                            setInitialValues(tokenFound[0].data);
                            setId(tokenFound[0].id)
                        } else {
                            setBadToken(3); // Invitation already accepted
                        }
                    } else {
                        setBadToken(2); // Token not found
                    }

                }).catch(err => {
                    console.log('Error: ' + err.toString());
                });
            } else {
                console.log('bad token')
                setBadToken(1); // Bad uuidv5 token
            }
        }

        if (me) {
            //Checks if a user has a select assigned to him otherwise fores a school selection
            if (me.select) {
                history.push(`/me`);
            } else {
                history.push(`/select`);
            }
        } else {
            if(token) {
                verifyToken(token);
            }
        }
    }, [me, token, history]);

    const createNewAccount = async values =>  {

        let business;
        const userEmail = invitation !== null ? invitation.data.email : values.email;
        const userRole = invitation !== null ? invitation.data.meta.role : accountRole;
        let business2becreated = {
            name: values.name,
            address: values.address,
            type: 'business'
        }
        if(values.name && values.name ){

            try{
                business = await schoolsDoc.add(business2becreated)
            }catch(e) {
                business = 'null'
            }

        }
        let data = {
            ...values,
            password: values.password,
            displayName: values.displayName,
            email: userEmail,
            role: userRole,
            createdAt: new Date().getTime(),
            groups: {

            },
            claims: null
        };
        try {
            data.groups[`${business.id}`] = values.name;
        }catch(e) {}
        if(invitation !== null){
            data.claims = { [values.schoolId[0]]: values.role, defaultRole: values.role}
            data.schools = invitation.data.schools;
            data.schoolId = invitation.data.schoolId
            data.logsOverview ={
              totalLog: 0,
              cancellationLog: 0,
              applicationLog: 0,
              conversationReplyLogCount: 0,
              activeJobAddLogCount: 0,
              eventRegistrationLogCount: 0,
              eventRegistrationCancelLogCount: 0,
              inviteAcceptationLogCount: 0,
              inviteCancelationLogCount: 0
            }

            data.groupsId = values.groupsId.map(v=> {
                if(typeof(v) === 'string') {
                    return v
                }
                return Object.keys(v)[0]
            })
        }

        let createUser = firebaseInstance().functions().httpsCallable('CreateUsers')
        try{
            const res = await createUser(data);
            setCreated(1); // Account Created
            if(invitation !== null) {
                let updateCreateUserInvitation = firebaseInstance().functions().httpsCallable('UpdateCreateUserInvitation')

                updateCreateUserInvitation({
                    infos: {
                        acceptedAt: new Date().getTime(),
                        meta: {
                            ...invitation.data.meta,
                            uid: res.data.uid
                        }
                    },
                    invitationId: invitation.id,
                }).catch(err => {
                    setCreated(3); // Invitation not updated
                    console.log('Error: ' + err.toString());
                });
                
                return res.data.id;
            }
        }catch(err) {
            setCreated(2); // User not created
            console.log('Error: ' + err.toString());
        }
        

    };
    const caseComponents = () => {
        switch(signUpStep) {
            case 1:
                return  regist ? <JustRegister invitation={invitation} t={t} initialValues={initialValues} setSignUpStep={setSignUpStep}/> : <Registration setSignUpStep={setSignUpStep} setBadToken={setBadToken} badToken={badToken} t={t} data={initialValues} id={id} saved={true}/>
            break;
            case 2:
                return <Password id={id} t={t} setSignUpStep={setSignUpStep} createNewAccount={createNewAccount}/>
            break;
            case 3:
                return <YouveRegister t={t}/>
                break;
            default:
                return <Registration setSignUpStep={setSignUpStep} setBadToken={setBadToken} badToken={badToken} t={t} data={initialValues} id={id}/>
        }
    }
    return (
            <React.Fragment>
                { invitation && <JustCoverBG {...invitation.data}/> }
                {
                    caseComponents()
                }
            </React.Fragment>
        )


    // return (
    //     <div>
    //         <div className="navbar-ghost is-hidden-mobile"></div>
    //         <div class="signup-wrapper">
    //             <ProcessBarWrap step={signUpStep} />
    //
    //             <Formik
    //             enableReinitialize
    //             initialValues={{...initialValues, invites: []}}
    //             validationSchema={Yup.object({
    //                 displayName: Yup.string()
    //                 .min(10, "Must be 10 characters or less")
    //                 .required("Required"),
    //                 email: Yup.string()
    //                 .email("Invalid email addresss`")
    //                 .required("Required"),
    //                 password: Yup.string()
    //                 .required('No password provided.')
    //                 .min(8, 'Password is too short - should be 8 chars minimum.'),
    //                 passwordConfirmation: Yup.string()
    //                 .oneOf([Yup.ref('password')], 'Passwords must match')
    //             })}
    //             onSubmit={async (values, { setSubmitting }) => {
    //                 createNewAccount(values);
    //                 setSubmitting(false);
    //             }}
    //             >
    //                 {({values}) => {
    //                     return (
    //                         <Form autoComplete="off">
    //                             <div class="outer-panel">
    //                                 <div class="outer-panel-inner">
    //                                     <div class="process-title">
    //                                         <h2 id="step-title-1" className={signUpStep == 1 ? 'step-title is-active' : 'step-title'} style={{ opacity: 0.3 }} >{t('general.wsaatype')}</h2>
    //                                         <h2 id="step-title-2" className={signUpStep == 2 ? 'step-title is-active' : 'step-title'} >{t('general.tell_us_more_about_you')}</h2>
    //                                         <h2 id="step-title-3" className={signUpStep == 3 ? 'step-title is-active' : 'step-title'} >{t('general.secure_your_account')}</h2>
    //                                         <h2 id="step-title-4" className={signUpStep == 4 ? 'step-title is-active' : 'step-title'} >{t('general.you_re_all_set_ready')}</h2>
    //                                     </div>
    //
    //                                     <div id="signup-panel-1" className={signUpStep == 1 ? 'process-panel-wrap is-active' : 'process-panel-wrap is-narrow'}>
    //                                         <AccountType updateStep={updateStep} setAcccountRole={setAcccountRole} handleToken={badToken} accountRole={accountRole}/>
    //                                     </div>
    //
    //                                     <div id="signup-panel-2"  className={signUpStep == 2 ? 'process-panel-wrap is-active' : 'process-panel-wrap is-narrow'}>
    //                                         <MoreAboutYou lockEmail={isInvitation} invites={invites} setInvites={setInvites} values={values}/>
    //                                         <StepButton back="1" next="3" updateStep={updateStep} displayPrevious={!isInvitation} />
    //                                     </div>
    //                                     <div id="signup-panel-3"  className={signUpStep == 3 ? 'process-panel-wrap is-active' : 'process-panel-wrap is-narrow'}>
    //                                         <SecureAccount />
    //                                         <StepButton back="2" next="4" updateStep={updateStep} />
    //                                     </div>
    //                                     <div id="signup-panel-4"  className={signUpStep == 4 ? 'process-panel-wrap is-active' : 'process-panel-wrap is-narrow'}>
    //                                         <Create created={created} />
    //                                     </div>
    //                                 </div>
    //                             </div>
    //
    //                         </Form>
    //                     )
    //                 }}
    //
    //             </Formik>
    //         </div>
    //     </div>
    // );
}

export default SignUp;
