export default function arraySortByField(arrArg, field){
    const returnArray = [];
    arrArg.forEach(element => {
        if(element[field]){
            if(returnArray[element[field]]){
                returnArray[element[field]].push(element);
            }else{
                returnArray[element[field]] = [element];
            }
        }else{
            return 'Field does not exist';
        }
    });
    return returnArray;
}

export function returnRelatedUserByRoles(type, role, data) {
    switch(type) {
        case 'single':
            return data.reduce((old, curr)=> {
                if(role === curr.internshipRole) {
                    return curr;
                }
                return old;
            }, {})
        break;
        case 'multi':
            return data.filter( curr => role === curr.internshipRole)
        break;
        default:
            return data.reduce((old, curr)=> {
                if(role === curr.internshipRole) {
                    return curr;
                }
                return old;
            }, {})
    }
}
