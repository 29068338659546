import React, {useState, useEffect, useContext, useRef} from 'react'
import {SearchBar} from "../components/SearchBar";
import {MyContext} from "../stores";
import { activeJobs } from "../firestore/firestoreService";
import styled from 'styled-components'
import arraySortByField , {returnRelatedUserByRoles} from '../utils/arraySortByField';

const MyInternshipStyled = styled.div`
  .parentcard {
    display: flex;
    flex-direction: row;
    .card {
      width: 100%;
    }
  }
`

const Card = ({data}) => {
    const [student, setStudent] = useState(null)
    useEffect(()=> {
        setStudent(arraySortByField(data.relatedUsers, 'student'))
    }, [data])
    return (
        <div className="card">
            {/*{student?.displayName}*/}
            no name
        </div>
    )
}

const MyInternships = props => {
    const {globalId} = useContext(MyContext);
    const [internshipPost, setInternships] = useState([])

    useEffect(()=> {
        if(globalId){
            activeJobs.where(`relatedPeoples.${globalId}`, '==', true).get().then( data => {
                setInternships(data.docs.map(d=> Object.assign({},{id: d.id, ...d.data()})));
            })
        }
    }, [globalId])
    return (
        <div className="flex flex-col w-[100%]">
            <div className="">
                { internshipPost.length ?
                <MyInternshipStyled id="profile-friends" className="view-wrap is-headless">
                    <div className="columns">
                        <div className="column">
                            <SearchBar person={false}></SearchBar>
                            <div className="news-grid" style={{paddingTop: 20}}>
                                <div className="columns is-multiline">
                                {internshipPost.length &&
                                    internshipPost.map((doc, key) => (
                                        <Card key={key} data={doc} type={`intern`} />
                                    ))
                                }
                                </div>
                                <div className=" load-more-wrap has-text-centered">
                                    {internshipPost.length > 10 && <a >Load More</a>}

                                </div>
                            </div>
                        </div>
                    </div>
                </MyInternshipStyled> :
                <div style={{marginTop: '80px', minHeight: '200px'}}><div style={{color: '#00a1e7', fontSize: '30px'}}>There are no internships in which you are a participant.</div></div>}
            </div>
        </div>
    )
}

export default MyInternships;
