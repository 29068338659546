import React, {useReducer} from 'react'
import fr from './language-ressources/fr.json'
import en from './language-ressources/en.json'
const SET_FR = 'SET_FR'
const SET_EN = 'SET_EN'


const initialtState = [];

export default function useRolesReducer(props){
    const [rolesValue, dispatchRoles] = useReducer(Reducer, props)
    return [rolesValue, dispatchRoles]
}
function Object2array(lang, filter){
    let transformation_object_filter = Object.entries(lang).filter( getFirstItems => getFirstItems[0].includes(filter));
    return transformation_object_filter.map( data => {
        return {
            key: data[0],
            value: data[1]
        }
    })
}
function Reducer(state = initialtState, {type, payload}) {

    switch(type) {
        case SET_EN:
            return Object2array(en, payload)
        case SET_FR:
            return Object2array(fr, payload);
        default:
            return [];
    }
}
