import React, {useContext, useEffect} from 'react'
import {EditCalendar} from "./EditCalendar";
import {MyContext} from "../stores";
import {AddUsers, MultipleUsers} from "./InviteUsers";
import styled from 'styled-components'

const OverLayStyle = styled.div`
  opacity: 1;
  
  ::after {
    background: ${props => props.color};
    border: ${props => props.color};
  }
  ::before {
    background: ${props => props.color};
    border: ${props => props.color};
  }
`

export const PageLoader = () => {
    const color = [
        '#00d794',
        '#eb5264',
        '#1f9dd9',
        '#ffca06'
    ]
    return (
        <OverLayStyle className="pageloader" color={color[Math.floor(Math.random() * 4)]} ></OverLayStyle>
    )
}

export const Infraloader = ( ) => {
    return (
        <div className="infraloader"></div>
    )
}

export const OverlayWrapper = () => {
    const {appEditCalendar, appAddUsers, appAddMUsers} = useContext(MyContext)
    useEffect(()=> {

    }, [appEditCalendar])
    return (
        <React.Fragment>
            {appEditCalendar && <EditCalendar />}
            {appAddUsers && <AddUsers/>}
            {appAddMUsers && <MultipleUsers />}
        </React.Fragment>
    )
}

export const AppOverlay = ({isActive}) => {
    const {appOver} = useContext(MyContext)
    return (
        <div className={`app-overlay ${appOver || isActive ?'is-active':''}`}></div>
    )
}
