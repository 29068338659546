import React, {useContext, useEffect, useState} from 'react'
import {Formik, Form} from "formik";
import ModalWrapper from "./index";
import {InputDate, InputText, SwitchBox} from "../components/FormElements";
import {format} from "date-fns";
import RichTextEditor from "../components/RichTextEditor";
import {initialValue} from "../components/DisplayContentFromSlate";
import {MyContext} from "../stores";
import {postsDoc} from "../firestore/firestoreService";
import {useTranslation} from "react-i18next";
import {Multiselect} from 'multiselect-react-dropdown';
import { useAlert } from 'react-alert';

export default function EditEventModal({event}){
    const {me, langChoice, globalId, dispatchModal, content, setContent} = useContext(MyContext)
    const {t} = useTranslation()
    const alert = useAlert()
    const [initialState, setData] = useState({
        title:'',
        type: 'event',
        tiny: '',
        groups: [me.select.id, 'all'],
        date: new Date(),
        active: false,
        startDate: 0,
        endDate: 0,
        desc: initialValue,
        mainImage: [],
        location: '',
        website: '',
        company: {

        },
        attendeesId: [],
        author: {
            displayName: me.displayName,
            id: globalId
        },
        facebook: '',
        twitter: '',
        linkedin: '',
        instagram: '',
        businessId: [me.select.id]

    })
    useEffect(()=>{
        if(event){

            setData(event)
            setContent(event.desc)
        }
    }, [event])

    const onSubmit = (data, {setSubmitting}) => {
        if(!event) {
            setSubmitting(true)
            data.desc = content;
            data.date = new Date().getTime();
            data.startDate = new Date(data.startDate).getTime()
            postsDoc.add(data).then(response => {
                setSubmitting(false)
                alert.success(t("general.eventsaved"))
                dispatchModal({type: 'CLOSE_MODAL'})
            })
        } else {
            setSubmitting(true)
            data.desc = content;
            data.startDate = new Date(data.startDate).getTime()
            postsDoc.doc(data.id).update(data).then(response => {
                setSubmitting(false)
                alert.success(t("general.eventsaved"))
                dispatchModal({type: 'CLOSE_MODAL'})
            })
        }
    }
    return (
        <ModalWrapper>
            <Formik initialValues={initialState} onSubmit={onSubmit} enableReinitialize={true}>
                {({values, isSubmitting}) => {
                    return (
                        <Form>
                            <div className="settings-form-wrapper">
                                <div className="settings-form">
                                    <div style={{width: '95%'}}>
                                        <div className="field">
                                            <InputText
                                                label={ langChoice === 'fr'? 'titre': 'title'}
                                                value={values.title}
                                                name="title" />
                                        </div>
                                        <div className="field">
                                            <InputText
                                                label={ langChoice === 'fr'? 'Petit résumé': 'Little summary'}
                                                name="tiny" />
                                        </div>
                                        <div className="field">
                                            <InputDate
                                                label={langChoice === 'fr'? 'Date de début':'Start date'}
                                                value={format(new Date(values?.startDate || 0), 'dd/MM/yyyy')}
                                                name="startDate"
                                            />
                                        </div>
                                        <div className="field">
                                            <SwitchBox
                                                label={langChoice === 'fr' ? 'Actif': 'Active'}
                                                name="active"
                                            />
                                        </div>
                                        <div className="field">
                                            <Multiselect
                                                options={[{label:'all', id: 1}, {label:'all2', id: 2}, {label:'all3', id: 3}]}
                                                displayValue="label"
                                            >

                                            </Multiselect>
                                        </div>

                                        {/*<div className="field" style={{display:'flex', justifyContent: 'space-between'}}>*/}
                                        {/*    <button type="submit" className="button is-primary" disabled={isSubmitting}>{langChoice === 'fr' ? 'sauvegarder': 'save'}</button>*/}
                                        {/*    /!*<button onClick={(e) => returnBack(e, resetForm)} className="button is-danger">{langChoice === 'fr' ? 'ne pas sauvegarder': 'do not save'}</button>*!/*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <RichTextEditor />
                            <button className="button is-fullwidth" type="submit">{t('general.save')}</button>
                        </Form>
                    )
                }}

            </Formik>
        </ModalWrapper>
    )
}
