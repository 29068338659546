
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { isAdmin, isBizAdmin } from "../../helper/acl"
import { MyContext } from "../../stores"
import styled from "styled-components"
import { postsDoc } from "../../firestore/firestoreService"
import TopForms from "./TopForms"
import PostTypes from "../../stores/enums/PostTypes"
import HomePost from "./HomePost"
import { SingleJobPost } from "../JobPostCard"
import EventCard from "../EventCard"
import { Box } from "@mui/system"
import {
    Work as WorkIcon,
    WorkOutline,
    Sms as SmsIcon,
    SmsOutlined,
    Event as EventIcon,
    EventOutlined,
    School as SchoolIcon,
    SchoolOutlined,
    Create as  PencilIcon
} from '@mui/icons-material'

const GridStyled = styled(Grid)`
background: white;
border-radius: 10px;
margin-bottom: 1rem;
border: 1px solid #e8e8e8;
padding: 16px;
`

const FeedHome = () => {
    const [posts_message, setPost] = useState([])
    const [query, setQuery] = useState(false)
    const {globalId, langChoice, me} = useContext(MyContext)
    const [admin, setAdmin] = useState()
    const [bizAdmin, setBizAdmin] = useState()
    const [filter, setFilter] = useState("all")
    const [limit, setLimit] = useState(3)
    const [hasMore,setHasMore] = useState(false)
    const [totalDocs,setTotalDocs] = useState(-1)
    const {t} = useTranslation()
    const [currentPanels, setCurrentPanels] = useState(['message', 'job', 'event']);



    function removeFilter(e) {
        console.log(e.target.value)
        setLimit(3)
        if(e.target.checked)  {
            setHasMore(false)
            setFilter(e.target.value)
        }
}

    const getPage = r => {
            const isCollectionEmpty = r.size === 0
            if(!isCollectionEmpty){
                const newPosts = r.docs.map(d => {
                    return {id: d.id, ...d.data()}
                })
                setPost(newPosts)
            }else{
                setHasMore(true)
            }
        }

    const loadMore = () => {
        setLimit((prevLimit) => {return prevLimit + limit})
    }

    useEffect(()=> {
        try {
            let rtrn = postsDoc
                .where('groups', 'array-contains-any', me.groupsId || []);

            if (filter !== "all") {
                rtrn = rtrn.where('type', '==', filter)
            }


            rtrn.get().then((r) => setTotalDocs(r.size))
            totalDocs !== -1 && limit >= totalDocs && setHasMore(true)
            rtrn = rtrn
                .orderBy('date', 'desc')
                .limit(limit)

            if (globalId && me) {
                // retourner au premier element

                if (filter === 'all') {
                    rtrn = rtrn
                        // .where('schoolIds', "array-contains-any", [`${me.select.id}`])
                        .onSnapshot(r => {
                                getPage(r)
                            }
                        )
                } else {
                    rtrn = rtrn
                        // .where('schoolIds', "array-contains-any", [`${me.select.id}`])
                        .onSnapshot(r => {
                                setQuery(true)
                                setPost(r.docs.map(d => {
                                    return {id: d.id, ...d.data()}
                                }))

                            },
                            error => console.log(error.message),
                            onCompleted => console.log(onCompleted)
                        )

                }

                return () => rtrn


            }
        }catch(e) {
            return null;
        }
    }, [globalId, me, query, filter, limit])

    useEffect(()=> {
        if(me && me.select) {
            isAdmin(me.select.id).then(v => setAdmin(v));
            isBizAdmin(me.select.id).then(v => setBizAdmin(v));
        }
    }, [me])

    useEffect(() => {
        if(bizAdmin){
            setCurrentPanels(['job', 'event']);
        }else{
            setCurrentPanels(['message', 'job', 'event']);
        }
    }, [bizAdmin])

    return (
        <div id="feeds-content" className={`content-section is-active`}>
            <div id="feeds-glider" className="slider-timeline about-glider" style={{display: 'block'}}>
                <GridStyled
                    container
                    direction="row"
                    spacing={10}
                    justifyContent="center"
                    alignItems="center"
                    style={{width: '100%',
                    margin: 'auto',
                }}>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="gender" name="row-radio-buttons-group" defaultValue="all">
                            <FormControlLabel
                                value="all"
                                control={<Radio  onChange={removeFilter} />}
                                label={t('general.all')}
                                checked={filter === "all"}
                            />
                            <FormControlLabel value="post" control={<Radio onChange={removeFilter} icon={<SmsOutlined />} checkedIcon={<SmsIcon />}    checked={filter === "post"} />} label={t('general.globalmessage')}  />
                            <FormControlLabel value="jobs" control={<Radio  onChange={removeFilter} icon={<WorkOutline />} checkedIcon={<WorkIcon />}  checked={filter === "jobs"}  />} label={t('overlay.jobs')} />
                            <FormControlLabel value="internships" control={<Radio onChange={removeFilter} icon={<SchoolOutlined />} checkedIcon={<SchoolIcon />} checked={filter === "internships"}  />} label={t('overlay.internships')} />
                            <FormControlLabel
                                checked={filter === "event"}
                                value="event"
                                control={<Radio  icon={<EventOutlined />} checkedIcon={<EventIcon />}  onChange={removeFilter} />}
                                label={t('general.events')}
                            />

                        </RadioGroup>
                    </FormControl>
                    {admin && <TopForms t={t} globalId={globalId} panels={currentPanels}/>}

                </GridStyled>

                <div className="profile-timeline">

                    {posts_message.map( (post) => {
                        switch(post.type) {
                            case PostTypes.POST:
                                return <HomePost key={post.id} data={post} langChoice={langChoice} attachments={post.attachments && post.attachments}/>
                            break;
                            case PostTypes.JOB:
                                return <SingleJobPost key={post.id} post={post} owner={false} admin={admin}/>
                            break;
                            case PostTypes.INTERNSHIP:
                                return <SingleJobPost key={post.id} post={post} owner={false} admin={admin}/>
                            break;
                            case PostTypes.EVENT:
                                return <EventCard key={post.id} data={post} owner={false}/>
                            break;
                            default:
                                return <React.Fragment key={post.id}></React.Fragment>

                        }
                    })}
                            { hasMore === false ? <Box sx={{display: "flex", justifyContent: "center",alignitems: "center"}}>
                                <Button  onClick={loadMore}>{t("pagination.loadButton")}</Button>
                            </Box> : <h1 style={{textAlign : "center"}}>{t("pagination.fullyLoaded")}</h1>}
                </div>
            </div>
        </div>)
}

export default FeedHome