import firebase from "../config/firebase";

export const db = firebase.firestore();
export function firebaseInstance() {
  return firebase;
}
export const firestore = firebase.firestore;
export const storage = firebase.storage();
export const users = db.collection("users");
export const usersDoc = db.collection("users");
export const postsDoc = db.collection("posts");
export const schoolsDoc = db.collection("schools");
export const businesses = db.collection("businesses");
export const jobPosts = db.collection("jobPosts");
export const activeJobs = db.collection("activeJobs");
export const messagesDocs = db.collection("messages");
export const contactsDocs = db.collection("contacts");
export const eventsDocs = db.collection("events");
export const bugReports = db.collection("bugReports");
export const notificationsDocs = db.collection("notifications");
export const formElements = db.collection("formElements");
export const formTemplates = db.collection("formTemplates");
export const activeForms = db.collection("activeForms");
export const formResponses = db.collection("formResponses");
export const groupsDoc = db.collection('groups');
export const calendarsDoc = db.collection('calendars');
export const convDocs = db.collection('conv');
export const documents = db.collection('documents');
export const userlogs = db.collection('usersActions');
export const uToken = db.collection('utoken');

export function getUsersFromFirestore(observer) {
  return db.collection("users");
}

export function getPostsFromFirestore(observer) {
  return db.collection("posts");
}

export function getBusinessesFromFirestore(observer) {
  return db.collection("businesses");
}

export function getJobPostsFromFirestore(observer) {
  return db.collection("jobPosts");
}

export function dynamicFirestoreObsrv(observer, path) {
  return db.collection(path).onSnapshot(observer);
}

export function dataFromSnap(snapshot) {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data();

  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof firebase.firestore.Timestamp) {
        data[prop] = data[prop].toDate();
      }
    }
  }

  return {
    ...data,
    id: snapshot.id,
  };
}

export async function authWithEmail(creds) {
  return await firebase
    .auth()
    .signInWithEmailAndPassword(creds.email, creds.password);
}

export function postContactInfo(info) {
  return db.collection("contactus").add(info);
}

export function collectionExists(collectionPath) {
    let exists = false;
    db.collection(collectionPath).limit(1).get().then(res => {
        if(res.size > 0){
            exists = true
        }
    })
    return exists
}

export function subCollectionExists(collectionPath,subCollectionPath) {
  let exists = false;
  db.collection(collectionPath).doc(subCollectionPath).get().then(res => {
      if(res){
        exists = true;
      }
  })
  return exists
}
