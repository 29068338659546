import React from 'react'
import {storage} from '../firestore/firestoreService'

export const UploadImage = props => {
    let fakedata = [['my@email.com', 'schoolnumber', 'teacherref']]
    var csv = `email, schoolid, class\n`
    fakedata.forEach( data => {
        csv += data.join(',');
        csv += '\n'
    })
    let ref = storage.ref()
    let file = ref.child('bzk_media_test/file.csv')
    let blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
    file.put(blob)
    return (
        <React.Fragment>
            <div style={{height: 300}}></div>
            <h1>Upload image</h1>
            <div></div>
        </React.Fragment>

    )
}
