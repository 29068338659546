import { useState, useEffect } from "react";
import firebase from '../config/firebase';

const db = firebase.firestore();
//custom hook to use data from a firestore collection.
const useFirestore = (businesses) => {
    const [docs, setdocs] = useState([]);
  
    useEffect(() => {
       db.collection(businesses)
        .onSnapshot((snap) => {
          let documents = [];
          snap.forEach((doc) => {
            documents.push({ ...doc.data(), id: doc.id });
          });
          setdocs(documents);
        });
      //return () => unsub();  //cleanUp function
    }, [businesses]);
    return { docs };
  };
  
  export default useFirestore;