import React, {useContext, useEffect, useState} from 'react'
import {MyContext} from "../stores";
import { Link } from 'react-router-dom';
import {ArchiveOtl, AwardOtl, BriefcaseOtl, CheckCircleOtl, FlagOtl, CloseIco} from "../ico";
import styled from 'styled-components'
import { useTranslation } from "react-i18next";
import {getUserRole} from "../helper/acl";


export const CardLayout = styled.div`
  width: 100%;
  height: 90px;
  background: var(--bazookka-green);
  background-image: url(assets/old/wallpaper.png);
  background-size: auto;
  background-position: bottom;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .alink {
    display: flex;
    flex-direction: row;
    .circle {
      background: var(--bazookka-white);
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin: 10px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      i {
        font-size: 40px;
      }
    }
    .label {
      color: white;
      align-items: center;
      justify-content: left;
      display: flex;
    }
  }
`;

export const RenderMenuItem = ({langUrl, path, ico, label, key, t, langChoice, setAllFalse}) => {
    const url = langUrl ? `/${langChoice}${path}` : path;

    return (
      <div className="column is-4" key={key}>
        <CardLayout>
          <Link to={url} className="alink" onClick={()=>setAllFalse()}>
            <div className="circle">{ico}</div>
            <div className="label">{t(label)}</div>
          </Link>
        </CardLayout>
      </div>
    );
    // return (<li><Link to={url} onClick={removeOver}><span>{itemData.label}</span></Link></li>);
}
const OverLayMenu = (props) => {
    const {predicate, me, setAllFalse, langChoice} = useContext(MyContext)
    const [userRole, setUserRole] = useState(null)
    const { t } = useTranslation();
    const removeOver = () => {
        setAllFalse()
    }

    //This code is temporary waiting for the categories to be defined and used
    //You may remove this when your sorting of by category is complete.
    function chunkArray(myArray, chunk_size){
        var chunks = [], i = 0, n = myArray.length;
        while (i < n) {
            chunks.push(myArray.slice(i, i += chunk_size));
        }
        return chunks;
    }

    //Current dataModel [[item1,item2,item3], [item4, item5, item6]]
    //In the future this should be an array of category sorted arrays of menu items
    //Each sub-array should contain category meta-data as well as the list of items
    //Future DataModel suggestion [[cat:'users', items:[item1,item2,item3]],[cat:'school', items:[item4,item5,item6]]...]
    const sortedItemsArray = chunkArray(props.items, 4);

    useEffect(()=> {
        if(me && me.select)
            getUserRole(me.select.id).then((role) => {
                setUserRole(role);
            });
    }, [me])

    return (
        <div className={`drop-trigger explorer-menu ${predicate.get('bigdrop') ? 'is-active': ''}`}>
            <div className="explorer-inner">
                <div className="explorer-container">

                    <div className="explorer-header">
                        <h3>Explore</h3>
                        <div className="control">
                                <a onClick={removeOver} className="has-text-black">
                                    <CloseIco />
                                </a>
                        </div>
                    </div>

                    <div className="explore-list">
                        {props.items.filter(data => {
                            //return true;
                            return data.roles.includes(userRole) && (data.locales?.includes('overlay'))
                        }).map((data, key) => RenderMenuItem({...data, key, t, setAllFalse, langChoice}))}
                      
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OverLayMenu
