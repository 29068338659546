import React, { useContext, useState, useEffect } from "react";
import { MyContext } from "../stores";
import {useParams} from "react-router-dom"
import { DragDropContext } from "react-beautiful-dnd";
import { formElements, formTemplates, activeForms } from "../firestore/firestoreService";
import {
  FormElementColumn,
  FormPrefabElementColumn,
  TemplateColumn,
} from "../components/FormManager/FormSectionColumn";
import { FormPreview } from "../components/FormManager/FormPreview";
import { Trans, useTranslation } from "react-i18next";
import { PencilLineIco, TrashIco } from "../ico";
import { v4 as uuidv4 } from "uuid";
import ToggleElements from "../components/FormManager/ToggleElements";
import styled from "styled-components"
import i18next from "i18next";
import { useAlert } from "react-alert";

const ThreeHeaderStyled = styled.div`
  .events-wrappers {
    display: grid;
    position: relative;
    grid-template-columns: 300px 1fr 360px;
    > div {
      height: 100vh;
      position: relative;
    }
  }
`
export const FormCreator = () => {
  const { me, globalId, dispatchModal, setFooter } = useContext(MyContext);
  const {id} = useParams()
  const alert = useAlert()
  const [columns, setColumns] = useState([
    {
      groupName: "Form Elements",
      elements: [],
    },
    {
      groupName: "Form Template",
      elements: [],
    },
    {
      //Prebuilt elements are added manually

      groupName: "Prebuilt Elements",
      elements: [
        {
          id: uuidv4(),
          type: "Short Question",
          question: "",
          questionDescription: "",
          required: "",
          elementType: "prebuilt",
        },
      ],
    },
  ]);
  const [formTitle, setFormTitle] = useState("");
  const [toggle, setToggle] = useState("Prebuilt elements");
  const [apiCall, setApiCall] = useState(false);
  const [edit, setEdit] = useState(false);
  const {t} = useTranslation()

  setFooter(false)
  //Recuperation des elements
  useEffect(() => {
    if (!apiCall) {
      let array = [];
      formElements
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let docData = doc.data();
            array.push(docData);
          });
        })
        .then(() => {
          setColumns((prevState) => {
            let indexOfColumn = columns.findIndex(
              (column) => column.groupName === "Form Elements"
            );
            let stateCopy = [...prevState];
            stateCopy[indexOfColumn] = {
              ...stateCopy[indexOfColumn],
              elements: array,
            };
            return stateCopy;
          });
        });
      if(id) {
        setEdit(true)
        formTemplates.doc(id).onSnapshot( snaps => {
          let temp =  columns;
          setFormTitle(snaps.data().title)
          temp[1].elements = snaps.data().formContent;
          setColumns(temp)
          setApiCall(true);
        })

      }

    }
  }, [apiCall]);

  //Callback lorsque l'element est drop, c'est ici que l'ordre est persisté
  const onDragEnd = ({ destination, source, draggableId }) => {
    //Si l'element est drop à l'extérieur de la colonne, rien ne se produit
    if (!destination) {
      return;
    }

    //Si l'element est drop au meme index de la meme colonne, rien ne se produit
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    //On filtre pour retourner les elements qui sont dans la colonne initiale
    //ex: tous les elements dans Form Elements avant le deplacement
    const [sourceGroup] = columns.filter(
      (column) => column.groupName === source.droppableId
    );

    //On filtre pour obtenir les elements de la colonne de la colonne receptrice de l'element
    const [destinationGroup] = columns.filter(
      (column) => column.groupName === destination.droppableId
    );

    // Sauvegarde de l'element qui est manipulé
    const [draggableElement] = sourceGroup.elements.filter(
      (element) => element.id === draggableId
    );


    //On enleve l'element de la liste dans laquelle il etait initialement
    const newSourceGroup = sourceGroup.elements.splice(source.index, 1);

    //Insertion de l'element dans la colonne receptrice
    const newDestinationGroup = destinationGroup.elements.splice(
      destination.index,
      0,
      draggableElement
    );

    //Permet la copie d'un element prebuilt
    if (source.droppableId === "Prebuilt Elements") {
      setColumns(
        // copy(draggableElement, sourceGroup.elements, destination)
        sourceGroup.elements.splice(destination.index, 0, {
          ...draggableElement,
          id: uuidv4(),
        })
      );
    }

    const updatedColumns = columns.map((column) => {
      if (column.groupName === source.groupName) {
        return {
          groupName: column.groupName,
          elements: newSourceGroup,
        };
      }
      if (column.groupName === destination.groupName) {
        return {
          groupName: column.groupName,
          elements: newDestinationGroup,
        };
      }
      return column;
    });
    setColumns(updatedColumns);
  };

  // const copy = (
  //   draggableElement,
  //   sourceGroupElements,
  //   droppableDestination
  // ) => {
  //   sourceGroupElements.splice(droppableDestination.index, 0, {
  //     ...draggableElement,
  //     id: uuidv4(),
  //   });
  //   return sourceGroupElements;
  // };

  const handleAddElement = (values) => {
    let indexOfColumn = columns.findIndex(
      (col) => col.groupName === "Form Elements"
    );

    //Ajoute un element dans la liste
    let copyElements = columns[indexOfColumn].elements;
    copyElements.push(values);

    setColumns((prevState) => {
      let copy = [...prevState];
      copy[indexOfColumn] = { ...copy[indexOfColumn], elements: copyElements };
      return copy;
    });
  };

  const handleElementUpdate = (values) => {
    setColumns((prevState) => {
      let indexOfColumn = columns.findIndex(
        (col) => col.groupName === "Form Elements"
      );
      let indexOfElement = columns[indexOfColumn].elements.findIndex(
        (element) => element.id === values.id
      );
      let stateCopy = [...prevState];

      stateCopy[indexOfColumn].elements[indexOfElement] = values;

      return stateCopy;
    });
  };

  const handleElementDelete = (value) => {
    formElements
      .doc(value.id)
      .delete()
      .then(
        setColumns((prevState) => {
          let copyState = prevState;
          let updatedElementArray = copyState[0].elements.filter(
            (element) => element.id !== value.id
          );
          copyState[0] = {
            ...copyState[0],
            elements: updatedElementArray,
          };
          return copyState;
        })
      );
  };

  const handleTemplateCreation = async () => {
    if(edit) {
      await formTemplates.doc(id).update({
        title: formTitle,
        formContent: columns[1].elements,
      })
      alert.success(t("formCreator.formUpdated"))
      return;
    }
    let template = {
      title: formTitle,
      formContent: columns[1].elements,
      adminId: [globalId],
      organisationId: me.select.id, //Business or school id
    };
    formTemplates
      .add(template)
      .then((docRef) => {
        formTemplates
          .doc(docRef.id)
          .update({ id: docRef.id })
          .then(resetFormCreator());
      })
      .catch((error) => {
        console.log("Error: " + error);
      });
  };

  //MIGHT HAVE TO DO A REFACTOR INSTEAD OF FORMIK FIELDS: SEE FormElementCard
  const handlePrefabElement = (values) => {
    setColumns((prevState) => {
      let stateCopy = [...prevState];
      let indexOfColumn = columns.findIndex(
        (col) => col.groupName === "Form Template"
      );

      let indexOfElement = stateCopy[indexOfColumn].elements.findIndex(
        (element) => element.id === values.id
      );

      stateCopy[indexOfColumn].elements[indexOfElement] = {
        ...stateCopy[indexOfColumn].elements[indexOfElement],
        question: values.question,
        questionDescription: values.questionDescription,
      };

      return stateCopy;
    });
  };

  //Réinitialisation des elements apres la sauvegarde d'un template
  const resetFormCreator = () => {
    setFormTitle("");
    setColumns((prevState) => {
      let stateCopy = [...prevState];
      let indexOfElementColumn = columns.findIndex(
        (column) => column.groupName === "Form Elements"
      );
      let indexOfPrefabColumn = columns.findIndex(
        (column) => column.groupName === "Prebuilt Elements"
      );
      let indexOfTemplateColumn = columns.findIndex(
        (column) => column.groupName === "Form Template"
      );

      let updatedArray = stateCopy[indexOfElementColumn].elements;

      updatedArray.push.apply(
        updatedArray,
        stateCopy[indexOfTemplateColumn].elements.filter(
          (elem) => elem.elementType !== "prebuilt"
        )
      );

      stateCopy[indexOfElementColumn] = {
        ...stateCopy[indexOfElementColumn],
        elements: updatedArray,
      };
      stateCopy[indexOfPrefabColumn] = {
        ...stateCopy[indexOfPrefabColumn],

        //must refactor, hard-coded prefab elements will be a problem
        elements: [
          {
            id: uuidv4(),
            type: "Short Question",
            question: "",
            questionDescription: "",
            required: "",
            elementType: "prebuilt",
          },
        ],
      };
      stateCopy[indexOfTemplateColumn] = {
        ...stateCopy[indexOfTemplateColumn],
        elements: [],
      };

      return stateCopy;
    });
  };

  //Enleve un element de la liste d'elements dans le template
  const clearElement = (element) => {
    setColumns((prevState) => {
      let stateCopy = [...prevState];
      let indexOfTemplateColumn = columns.findIndex(
        (column) => column.groupName === "Form Template"
      );
      let indexOfElementColumn = "";
      let updatedElements = [];

      let updatedTemplate = stateCopy[indexOfTemplateColumn].elements.filter(
        (value) => value.id !== element.id
      );

      if (element.elementType === "prebuilt") {
        indexOfElementColumn = columns.findIndex(
          (column) => column.groupName === "Prebuilt Elements"
        );
        updatedElements = stateCopy[indexOfElementColumn].elements;
        updatedElements = [...updatedElements];
      } else {
        indexOfElementColumn = columns.findIndex(
          (column) => column.groupName === "Form Elements"
        );
        updatedElements = stateCopy[indexOfElementColumn].elements;
        updatedElements = [...updatedElements, element];
      }

      stateCopy[indexOfElementColumn] = {
        ...stateCopy[indexOfElementColumn],
        elements: updatedElements,
      };
      stateCopy[indexOfTemplateColumn] = {
        ...stateCopy[indexOfTemplateColumn],
        elements: updatedTemplate,
      };
      return stateCopy;
    });
  };

  const openFormModal = (type, element = {}) => {
    switch (type) {
      case "create":
        dispatchModal({
          type: "OPEN_MODAL",
          payload: {
            modalType: "FormElementCreationModal",
            modalProps: {
              title: i18next.t("formCreator.createFormElement"),
              ico: <PencilLineIco />,
              handleAddElement: (value) => handleAddElement(value),
              closeOut: () => dispatchModal({ type: "CLOSE_MODAL" }),
            },
          },
        });
        break;
      case "modify":
        dispatchModal({
          type: "OPEN_MODAL",
          payload: {
            modalType: "FormElementModificationModal",
            modalProps: {
              title: i18next.t("formCreator.modifyFormElement"),
              ico: <PencilLineIco />,
              element: element,
              handleElementUpdate: (values) => handleElementUpdate(values),
              closeOut: () => dispatchModal({ type: "CLOSE_MODAL" }),
            },
          },
        });
        break;
      case "delete":
        dispatchModal({
          type: "OPEN_MODAL",
          payload: {
            modalType: "FormElementDeletionModal",
            modalProps: {
              title: i18next.t("formCreator.deleteConfirmation"),
              ico: <TrashIco />,
              element: element,
              handleElementDelete: (values) => handleElementDelete(values),
              closeOut: () => dispatchModal({ type: "CLOSE_MODAL" }),
            },
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ThreeHeaderStyled className="view-wrapper">
        <div id="events-page" className="events-wrappers">
          <div className="left-panel">
            <div className="left-panel-inner has-slimscroll">
              {toggle === "Prebuilt elements" ? (
                <FormPrefabElementColumn
                  droppableId={columns[2].groupName}
                  elements={columns[2].elements}
                  toggle={toggle}
                  setToggle={setToggle}
                />
              ) : (
                <FormElementColumn
                  droppableId={columns[0].groupName}
                  elements={columns[0].elements}
                  openFormModal={openFormModal}
                  toggle={toggle}
                  setToggle={setToggle}
                />
              )}
            </div>
          </div>
          <div className="wrapper-inner has-slimscroll">
            <TemplateColumn
              droppableId={columns[1].groupName}
              elements={columns[1].elements}
              openFormModal={openFormModal}
              setFormTitle={setFormTitle}
              title={formTitle}
              handlePrefabElement={handlePrefabElement}
              clearElement={clearElement}
              handleTemplateCreation={handleTemplateCreation}
            />
          </div>
          <div className="right-panel">
            <div className="panel-header">
              <h3>Form Preview</h3>
            </div>
            <br />
            <div className="panel-body has-slimscroll">
              <div className="activity-block">
                <FormPreview title={formTitle} elements={columns[1].elements} />
              </div>
            </div>
          </div>
        </div>
      </ThreeHeaderStyled>
    </DragDropContext>
  );
};

export default FormCreator;
