import React, {useReducer} from 'react'
const ADD_FILES = 'ADD_FILES'
const REMOVE_FILE = 'REMOVE_FILE'
const REMOVE_FILES = 'REMOVE_FILES'
export function addFile(payload){
    return {
        type: ADD_FILES,
        payload
    }
}

export function removeFiles(payload) {
    if(payload){
        return {
            type: REMOVE_FILE,
            payload
        }
    }else {
        return {
            type: REMOVE_FILES
        }
    }

}

const initialtState = null;

export let files, dispatch
export function useFileReducer(init){
    [files, dispatch] = useReducer(fileReducer, init)
}
export function fileReducer(state = initialtState, {type, payload}) {
    switch(type) {
        case ADD_FILES:
            return [...state, ...payload ]
        case REMOVE_FILE:
            return state.filter((v, i) => {
                if(i === payload){
                    return false
                }
                return true
            });
        case REMOVE_FILES:
            return [];
        default:
            return [];
    }
}