import React, {useContext, useEffect, useState} from 'react'
import {Formik, Form} from "formik";
import {useLocation} from 'react-router-dom';
import {InputDate, InputText, SwitchBox} from "../components/FormElements";
import {format, setHours, setMinutes, getMinutes, getHours, addHours} from "date-fns";
import {firebaseInstance} from "../firestore/firestoreService";
import  {RichTextEditorFix} from "../components/RichTextEditor";
import {initialValue} from "../components/DisplayContentFromSlate";
import ZoomLogin from "../components/ZoomLogin";
import AzureLogin from "../components/AzureLogin";
import Loader from "../components/Loader";
import {MyContext} from "../stores";
import {postsDoc} from "../firestore/firestoreService";
import {useTranslation} from "react-i18next";
import {Multiselect} from 'multiselect-react-dropdown';
import styled from 'styled-components';
import {PencilLineIco} from "../ico";
import firebase from "firebase/app";
import { v4 as uuidv4 } from "uuid";
import { useAlert } from 'react-alert';
import * as Yup from 'yup'
import {ErrorContainer} from '../components/FormManager/ErrorContainer';
import parseDate from '../helper/form/parseDate';

const FormWrapper = styled.div`
        .richTextContainer{
            border:1px solid #e8e8e8;
            margin:10px 0;
        }
        .is-fullwidth {
          color: white;
          font-weight: bold;
          background-color: var(--bazookka-orange);
          :hover {
            filter: drop-shadow(1px 1px 1px rgba(0,0,0, 0.25));
            font-size: 15px;
            transition: all 300ms;
          }
        } 
        .react-datepicker__input-container input {
          padding: 8px;
          border: 1px solid var(--bazookka-orange);
          border-radius: 5px;
          background-color: var(--bazookka-orange);
          color: white;
          font-weight: bold;
          filter: drop-shadow(0px 1px 1px rgba(0,0,0, 0.4));
        }
        .toolbar{
            height:30px;
            margin:0;
            padding:1px 0px 17px 5px;
        }

        .richInput{
            padding:10px;
        }

        .toolButton{
            height:24px;
        }

        .crop-button{
            vertical-align:middle;
        }

        .cropped-cover-name{
            vertical-align:middle;
            padding-left:5px;
        }

        .cover-image{
            width:auto;
            height:auto;
            border-radius:3px;
            margin-top:10px;
        }
    `

const GalleryContainer = styled.div`
    display:grid;
    grid-template-columns:repeat(3, 1fr);
    gap:10px;
`

const GalleryImageWrapper = styled.div`
    width:240px;
    height:135px;
    background-color:var(--bazookka-gray);

    i.mdi-plus{
        display:block;
        font-size:70px;
        height:105px;
        width:70px;
        position:relative;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    i{
        display:none;
    }

    &:hover div{
        height:100%;
        width:100%;
        background-color:rgba(0,0,0,0.5);
        display:grid;
        grid-template-columns:repeat(2, 1fr);
        gap:10px;

        i{
            color:white;
            display:block;
            font-size:40px;
            margin:auto;
        }
    }
`

const ExternalServiceCard = styled.div`
    width: fit-content;
    background-color:var(--bazookka-darkblue);
    border-radius:10px;
    padding:10px;
    margin: 5px;

    &.green{
        background-color:var(--bazookka-green);
    }

    a{
        color:white;
        vertical-align:middle;
        transition: all 50ms;
    }

    span{
        color:white;
        vertical-align:middle;
    }

    img{
        filter: drop-shadow(1px 1px 1px rgba(0,0,0, 0.25));
        vertical-align:middle;
    }

    & a:hover {
        filter: drop-shadow(1px 1px 1px rgba(0,0,0, 0.25));
        font-size: 16px;
        transition: all 50ms;
      }
`

export default function EditEventForm({event, groups, isNew}){
    const gallerySize = 6;
    const {me, langChoice, globalId, dispatchModal} = useContext(MyContext)
    const location = useLocation();
    const [content, setContent] = useState(initialValue);
    const [startDate, setStartDate] = useState();
    const {t} = useTranslation();
    const [hasImage, setHasImage] = useState(false);
    const [hasGallery, setHasGallery] = useState(false);
    const [CroppedCover, setCroppedCover] = useState('');
    const [CroppedGallery, setCroppedGallery] = useState([]);
    const [redirectUrl, setRedirectUrl] = useState(null);
    //Zoom
    const [zoomLoggedIn, setZoomLoggedIn] = useState(false);
    const [zoomToken, setZoomToken] = useState(null);
    const [zoomMeeting, setZoomMeeting] = useState(null)
    const [zoomLoading, setZoomLoading] = useState(false);
    //Azure
    const [azureLoggedIn, setAzureLoggedIn] = useState(false);
    const [azureToken, setAzureToken] = useState(null);
    const [azureMeeting, setAzureMeeting] = useState(null)
    const [azureLoading, setAzureLoading] = useState(false);
    const [azureTeamsAllowed, setAzureTeamsAllowed] = useState(false);

    const alert = useAlert()
    const [initialState, setData] = useState({
        title:'',
        type: 'event',
        tiny: '',
        phone: '',
        video: '',
        groups: [],
        startDate: new Date(new Date().setDate(new Date().getDate() + 1)), //tomorrow
        endDate: new Date(new Date().setDate(new Date().getDate() + 2)), //after tomorrow
        active: false,
        desc: initialValue,
        email: '',
        mainImage: [],
        website: '',
        address : '',
        eo: '',
        company: {

        },
        attendeesId: [],
        author: {
            displayName: '',
            id: ''
        },
        facebook: '',
        twitter: '',
        linkedin: '',
        instagram: '',
        businessId: []
    });

    useEffect(()=>{
        //Load Event Data
        if(event){
            setData(event)
            setContent(event.desc)
            if(event.zoom){
                setZoomMeeting(event.zoom);
            }
            if(event.galleryImages && CroppedGallery.length === 0){
                const tempArray = [];
                let tempId;
                event.galleryImages.forEach((image) => {
                    tempId = uuidv4();
                    tempArray.push({id: tempId, image: image})
                });
                setCroppedGallery(tempArray);
            }
        }else{
            //New group Default Data
            if(me && globalId){
                setData({
                    ...initialState,
                    author: {displayName: me.displayName, id: globalId},
                    businessId: [me.select.id],
                });
            }
        }
    }, [ me, globalId, event])

    useEffect(() => {
        if (typeof window !== 'undefined' && location) {
            setRedirectUrl(`${window.location.protocol}//${window.location.host}/authorization`)
        }
    }, [location])

    const findGroups = (groups, groupsIds) => {
        let returnGroups = [];

        groupsIds.forEach(groupId => {
            groups.forEach(group => {
                if(groupId.id){
                    if(group.id === groupId.id){
                        returnGroups.push(group);
                    }
                }else{
                    if(group.id === groupId){
                        returnGroups.push(group);
                    }
                }
            });
        });
        return returnGroups;
    }

    const handleCoverCrop = (croppedImage) => {
        setCroppedCover(croppedImage);
    }

    const handleGalleryCrop = (croppedImage, index) => {
        const tempId = uuidv4();
        const tempGallery = CroppedGallery;
        tempGallery[index] = {id: tempId, blob: croppedImage};
        setCroppedGallery(tempGallery);
    }

    const editCover = (e) => {
        e.preventDefault();
        dispatchModal({type: 'OPEN_MODAL', payload: {modalType:'EventCoverCropUploadModal', modalProps: {
            title: t("modal.title.editSchoolCover"),
            ico: <PencilLineIco />,
            setHandle: setHasImage,
            closeOut: () => dispatchModal({type: 'CLOSE_MODAL'}),
            onCrop: handleCoverCrop
        }}})
    }

    const editGalleryImage = (index) => {
        dispatchModal({type: 'OPEN_MODAL', payload: {modalType:'EventGalleryCropUploadModal', modalProps: {
            title: t("modal.title.editSchoolGallerie"),
            ico: <PencilLineIco />,
            setHandle: setHasGallery,
            closeOut: () => dispatchModal({type: 'CLOSE_MODAL'}),
            onCrop: handleGalleryCrop,
            imageNumber:index
        }}})
    }

    const removeGalleryImage = (index) => {
        setCroppedGallery(CroppedGallery.filter((image, galleryIndex) => galleryIndex !== index))
        setHasGallery(true);
    }

    const uploadCover = (eventId) => {
        var storageRef = firebase.storage().ref();
        var fileRef = storageRef.child(`profile_pictures/events/${eventId}/${CroppedCover.name}`);
        fileRef.put(CroppedCover).then((snapshot) => {
            fileRef.getDownloadURL().then((url) => {
                postsDoc.doc(eventId).update({ cover: url });
                setCroppedCover('');
              });
        });
    }

    const uploadGalleryImage = (eventId) => {
        var storageRef = firebase.storage().ref();
        const uploadedFileUrls = [];
        const uploadPromises = [];

        CroppedGallery.forEach((file, index) => {
            if(file.blob){
                const uploadPromise = new Promise((resolve,reject) => {
                    var storageRef = firebase.storage().ref();
                    var fileRef = storageRef.child(`profile_pictures/events/${eventId}/gallery/${file.blob.name}`);
                    fileRef.put(file.blob).then((snapshot) => {
                        snapshot.ref.getDownloadURL().then((url) => {
                            uploadedFileUrls[index] = url;
                            resolve();
                        });
                    }).catch((error) => {
                        reject(error);
                    });
                });

                uploadPromises.push(uploadPromise);
            }else if(file.image){
                const uploadPromise = new Promise((resolve,reject) => {
                    uploadedFileUrls[index] = file.image;
                    resolve();
                });

                uploadPromises.push(uploadPromise);
            }
          });

          Promise.all(uploadPromises).then(
            () => {
                postsDoc.doc(eventId).update({ galleryImages: uploadedFileUrls });
                setCroppedGallery([]);
            },
            () => console.log('Error during files upload.'),
          )
    }

    const GalleryImage = ({data}) => {
        const index = CroppedGallery.indexOf(data);
        if(data.blob){
            const url = URL.createObjectURL(data.blob);
            return(
                <GalleryImageWrapper style={{backgroundImage:`url(${url})`, backgroundSize: 'contain'}}>
                    <div>
                        <i className="mdi mdi-pencil" onClick={() => editGalleryImage(index)}></i>
                        <i className="mdi mdi-trash-can" onClick={() => removeGalleryImage(index)}></i>
                    </div>
                </GalleryImageWrapper>
            );
        }else if(data.image){
            return(
                <GalleryImageWrapper style={{backgroundImage:`url(${data.image})`, backgroundSize: 'contain'}} >
                    <div>
                        <i className="mdi mdi-pencil" onClick={() => editGalleryImage(index)}></i>
                        <i className="mdi mdi-trash-can" onClick={() => removeGalleryImage(index)}></i>
                    </div>
                </GalleryImageWrapper>
            );
        }
    }

    const renderCover = (image) => {
        const url = URL.createObjectURL(image);
        const returnJSX = <img className="cover-image" src={url}></img>;
        return returnJSX;
    }

    const onSubmit = (data, {setSubmitting, resetForm}) => {
        if(!event) {
            setSubmitting(true)
            data.desc = content;
            data.date = new Date().getTime();
            data.startDate = new Date(data.startDate).getTime()
            data.endDate = new Date(data.endDate).getTime()
            if(zoomMeeting){
                data.zoom = zoomMeeting;
            }
            data.groups = data.groups.map( g => g.id)
            postsDoc.add(data).then(response => {
                setSubmitting(false)
                if(hasImage) {
                    uploadCover(response.id);
                }

                if(hasGallery){
                    uploadGalleryImage(response.id);
                }
                alert.success(t("general.imagesaved"))
                resetForm()
            })
        } else {
            setSubmitting(true)
            data.desc = content;
            data.startDate = new Date(data.startDate).getTime()
            data.endDate = new Date(data.endDate).getTime()
            data.groups = data.groups.map( g => g.id)
            postsDoc.doc(data.id).update(data).then(response => {
                setSubmitting(false)
                if(hasImage) {
                    uploadCover(data.id);
                }

                if(hasGallery){
                    uploadGalleryImage(data.id);
                }
                alert.success(t("general.imagesaved"))
                resetForm()
            })
        }
    }

    //Zoom components

    const ZoomMeetingCreationButton = ({formData}) => {
        return(
            <div>
                {zoomLoading ?
                    <Loader small/>
                :
                    <a onClick={() => createZoomMeeting(zoomToken, formData)}><ExternalServiceCard><span>{t('general.createmeetingwith')}</span> <img style={{height: '10px'}} src="https://st1.zoom.us/static/5.2.2957/image/new/ZoomLogo.png" /></ExternalServiceCard></a>
                }
            </div>
        );
    }

    const ZoomMeetingCreationContainer = ({formData}) => {
        return(
            <label>
                {zoomLoggedIn ? 
                    <div className="field">
                        <ZoomMeetingCreationButton formData={formData} />
                    </div>
                :
                    <ExternalServiceCard>
                        <ZoomLogin redirectUrl={redirectUrl} loginComplete={zoomLoginComplete} />
                    </ExternalServiceCard>
                }
            </label>
        )
    }

    const ZoomMeetingContainer = ({meeting}) => {
        if(meeting){
            return (
                <ExternalServiceCard className="green"><a href={meeting.join_url} target="_blank">{meeting.topic} <img style={{height: '10px'}} src="https://st1.zoom.us/static/5.2.2957/image/new/ZoomLogo.png" /></a></ExternalServiceCard>
            )
        }else{
            alert.error('meeting.noZoomCreated')
        }
    }

    const zoomLoginComplete = (zoomTokenObject) => {
        setZoomLoggedIn(true);
        setZoomToken(zoomTokenObject);
    }

    const createZoomMeeting = (zoomToken, formValues) => {
        setZoomLoading(true);
        const createZoomMeeting = firebaseInstance().functions()
            .httpsCallable('CreateZoomMeeting')
            createZoomMeeting({
             token: zoomToken,
             title: formValues.title,
             description: formValues.tiny,
             startDate: formValues.startDate,
             email: me.email,
             timezone: "America/New_York",
            }).then( results => {
            setZoomMeeting(results.data);
            setZoomLoading(false);
        }).catch(err=> {
            console.log(err.code, err.message, err.details);
        })
    }

    //Azure Components
    const azureLoginComplete = (azureTokenObject) => {
        //Checking that the current Azure user is able to create teams meetings.
        setAzureLoading(true);
        const getAzureCalendarDetails = firebaseInstance().functions()
            .httpsCallable('GetAzureCalendarDetails');
        getAzureCalendarDetails({token: azureTokenObject}).then( results => {
            if(results.data.allowedOnlineMeetingProviders.includes("teamsForBusiness")){
                setAzureTeamsAllowed(true);
            }
            setAzureLoggedIn(true);
            setAzureToken(azureTokenObject);
            setAzureLoading(false);
        });
    }

    const AzureMeetingCreationContainer = ({formData}) => {
        if(azureLoading){
            return <Loader small/>
        }

        return(
            <label>
                {azureLoggedIn ? 
                    <div className="field">
                        <AzureMeetingCreationButton formData={formData} />
                    </div>
                :
                    <ExternalServiceCard>
                        <AzureLogin redirectUrl={redirectUrl} loginComplete={azureLoginComplete} setLoading={setAzureLoading}/>
                    </ExternalServiceCard>
                }
            </label>
        )
    }

    const AzureMeetingCreationButton = ({formData}) => {
        return(
            <div>
                {azureTeamsAllowed ?
                    <a onClick={() => createAzureMeeting(azureToken, formData)}><ExternalServiceCard><span>{t('general.createmeetingwith')}</span> <img alt="Microsoft Teams" src="assets/img/svg/microsoft-teams.svg" style={{width: '30px', height: '30px'}} /></ExternalServiceCard></a>
                :
                    <span>{t("form.noTeamsAccess")}</span>
                }
            </div>
        );
    }

    const createAzureMeeting = (azureToken, formValues) => {
        setAzureLoading(true);
        const createAzureMeeting = firebaseInstance().functions()
            .httpsCallable('CreateAzureMeeting')
            createAzureMeeting({
             token: azureToken,
             title: formValues.title,
             description: formValues.tiny,
             startDate: formValues.startDate,
             endDate: formValues.endDate,
             name: me.displayName,
             email: me.email,
             timezone: "America/New_York",
            }).then( results => {
            console.log("MEETING RESULT", results.data);
            setAzureMeeting(results.data);
            setAzureLoading(false);
        }).catch(err=> {
            console.log(err.code, err.message, err.details);
        })
    }

    const AzureMeetingContainer = ({meeting}) => {
        if(meeting){
            return (
                //TODO UNSTRING HREF when enable real accounts
                <ExternalServiceCard className="green"><a href={meeting.onlineMeeting.joinUrl} target="_blank">{meeting.subject}</a><img alt="Microsoft Teams" src="assets/img/svg/microsoft-teams.svg" style={{width: '30px', height: '30px'}} /></ExternalServiceCard>
            )
        }else{
            alert.error('meeting.noZoomCreated')
        }
    }

    const validationSchema = !event ? 
        Yup.object({
            title: Yup.string().required(t('validation.required')),
            tiny: Yup.string().required(t('validation.required')),
            startDate: Yup.date().transform(parseDate).min(new Date(), t('validation.mindate')).required(t('validation.required')),
            endDate: Yup.date().transform(parseDate).required(t('validation.required')),
            // groups: Yup.array().min(1, t('validation.groups')).required(t('validation.required')),
        })
    :
        Yup.object({
            title: Yup.string().required(t('validation.required')),
            tiny: Yup.string().required(t('validation.required')),
            startDate: Yup.date().transform(parseDate).required(t('validation.required')),
            endDate: Yup.date().transform(parseDate).required(t('validation.required')),
            // groups: Yup.array().min(1, t('validation.groups')).required(t('validation.required')),
        });

    return (
        <Formik initialValues={initialState} onSubmit={onSubmit} enableReinitialize={true}
                validationSchema={validationSchema}
            >
            {({values, isSubmitting, setFieldValue}) => {
                return (
                    <Form>
                        <FormWrapper className="settings-form-wrapper">
                            <div className="settings-form">
                                <div className="field">
                                    <SwitchBox
                                        label={langChoice === 'fr' ? 'Actif': 'Active'}
                                        name="active"
                                    />
                                </div>
                                <div className="field">
                                    <h2>{t('general.cover')}</h2>
                                    <button className="button is-solid primary-button raised crop-button" onClick={editCover}>{t('general.editcoverimage')}</button>
                                    {CroppedCover &&
                                        renderCover(CroppedCover)
                                    }
                                    {values.cover && CroppedCover === '' &&
                                        <img className="cover-image" src={values.cover}></img>
                                    }
                                </div>
                                <div className="field">
                                    <h2>{t('general.gallery')}</h2>
                                    <GalleryContainer>
                                        {CroppedGallery.map((image) => (
                                            <GalleryImage key={`key_${image.id}`} data={image}/>
                                        ))}
                                        {CroppedGallery.length < gallerySize &&
                                            <GalleryImageWrapper key="droppable_add_button" onClick={() => editGalleryImage(CroppedGallery.length)}><i className="mdi mdi-plus"></i></GalleryImageWrapper>
                                        }
                                    </GalleryContainer>
                                </div>
                                <div className="field">
                                    <InputText
                                        label={ langChoice === 'fr'? 'Titre': 'Title'}
                                        value={values.title}
                                        name="title" />
                                    <ErrorContainer name="title" component="div" />
                                </div>
                                <div className="field">
                                    <label>
                                        {t('general.whoshouldseethepost')}
                                        <Multiselect
                                            options={groups}
                                            selectedValues={isNew ? [groups[0]] : findGroups(groups, values.groups)}
                                            name={'groups'}
                                            displayValue="name"
                                            onSelect={v => {
                                                setFieldValue('groups', v)
                                            }}
                                            onRemove={v => {
                                                setFieldValue('groups', v)
                                            }}
                                        >
                                        </Multiselect>
                                    </label>
                                    <ErrorContainer name="groups" component="div" />
                                </div>
                                 <div className="field">
                                    <InputText
                                        label={ t('general.fulladdress')}
                                        value={values.address}
                                        name="address" />
                                </div>
                              <div className="field">
                                    <InputText
                                        label={ t('general.eob')}
                                        value={values.eo}
                                        name="eo" />
                                </div>
                                 <div className="field">
                                    <InputText
                                        label={ t('general.emailaddress')}
                                        value={values.email}
                                        name="email" />
                                </div>
                                <div className="field">
                                    <InputText
                                        label={ t('general.website')}
                                        value={values.website}
                                        name="website" />
                                </div>
                                <div className="field">
                                    <InputText
                                        label={ t('settings.phone')}
                                        value={values.phone}
                                        name="phone" />
                                </div>
                                 <div className="field">
                                    <InputText
                                        label={ t('general.videoUrl')}
                                        value={values.video}
                                        name="video" />
                                </div>
                                   <div className="field">
                                        <InputText
                                            label={ langChoice === 'fr'? 'Courte Description': 'Short Description'}
                                            name="tiny" />
                                        <ErrorContainer name="tiny" component="div" />
                                    </div>
                                    <div className="field">
                                        <InputDate
                                            showTimeSelect
                                            label={langChoice === 'fr'? 'Date de début':'Start date'}
                                           value={format(new Date(values.date || Date.now()), 'dd/MM/yyyy')}
                                            locale={langChoice === 'fr' ? 'frFR': 'en-US'}
                                            dateFormat="MMMM d, yyyy hh:mm aa"
                                            timeIntervals={15}
                                            name="startDate"
                                            extraChange={(value) => {
                                                setFieldValue("endDate", value);
                                            }}
                                        />
                                        <ErrorContainer name="startDate" component="div" />
                                    </div>
                                <div className="field">
                                    <InputDate
                                        showTimeSelect
                                        showTimeSelectOnly
                                        label={langChoice === 'fr'? 'Date de fin':'end date'}
                                        locale={langChoice === 'fr' ? 'fr': 'en-US'}
                                        value={format(new Date(values.endTime || addHours(Date.now(),1) ), 'dd/MM/yyyy')}
                                        dateFormat="MMMM d, yyyy hh:mm aa"
                                        minDate={new Date(startDate)}
                                        maxDate={new Date(startDate)}
                                        timeIntervals={15}
                                        minTime={setHours(
                                            setMinutes(new Date(values.startDate), getMinutes(new Date(values.startDate))),
                                            getHours(new Date(values.startDate))
                                        )}
                                        maxTime={setHours(new Date(values.startDate), 23)}
                                        name="endDate"
                                    />
                                    <ErrorContainer name="endDate" component="div" />
                                </div>
                                <div className="field">
                                    <label>
                                        <div>Description</div>
                                    <RichTextEditorFix content={content} setContent={setContent} />
                                    </label>
                                </div>
                                <div className="field">
                                    <label>
                                        <div>{t('form.videoconference')}</div>
                                    </label>    
                                    <div>
                                        {zoomMeeting ? 
                                            <ZoomMeetingContainer meeting={zoomMeeting}/>
                                        :
                                            <ZoomMeetingCreationContainer formData={values}/>
                                        }
                                    </div>
                                    <div>
                                        {azureMeeting ?
                                            <AzureMeetingContainer meeting={azureMeeting} />
                                            :
                                            <AzureMeetingCreationContainer formData={values}/>
                                        }
                                    </div>
                                </div>
                                <button className="button is-fullwidth" type="submit" disabled={isSubmitting}>{t('general.save')}</button>
                            </div>
                        </FormWrapper>
                    </Form>
                )
            }}
        </Formik>
    )
}
