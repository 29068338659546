import { Form, Formik } from "formik";
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { v5 as uuidv5 } from 'uuid';
import * as Yup from "yup";
import { NiceInput, NiceSelect } from "../../components/FormElements";
import TileComponent from "../../components/TileComponent";
import { db, firebaseInstance, groupsDoc } from "../../firestore/firestoreService";
import { MyContext } from "../../stores";
import { is2Object, userRoles } from "../../stores/enums/userRoles";
const options = [
    { value: 'seeker', label: 'Student' },
    { value: 'admin_company', label: 'Business' },
    { value: 'user', label: 'Emloyee' }
];


const InviteContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
border-radius: 5px;
padding: 10px;
background-color: var(--bazookka-white);
border: 0.5px solid #e8e8e8;
margin-bottom: 1rem;
button {
  background-color: var(--bazookka-blue);
  color: var(--bazookka-white);
}
.error {
  color: red;
}
`;

const ResetButton = styled.button`
    margin-top:15px;
    background-color: var(--bazookka-pink) !important;
`

const email = (displayName, link, accountType) => {
    return `
    <div style="text-align: left;">
        <h1 style="text-align: left;">Hi ${displayName}</h1>
        <p>You've been invited to join Bazookka for <span style="font-weight: 900;">${accountType}</span></p>
        <p style="text-align: center;">
            <a href="${link}" target="_blank" style="background-color: #4CAF50; 
            border: none; color: white; padding: 10px; 
            text-decoration: none; display: inline-block;
            cursor: pointer;">CREATE ACCOUNT</a>
        </p>

        <p>Welcome to Bazookka</p>
    </div>
    `;
};

const InvitationForm = () => {
    const {t} = useTranslation()
    const [company, setCompany] = useState(false)
    const {me, globalId, rolesValue, dispatchRoles, langChoice} = useContext(MyContext)
    const [invitationStatus, setInvitationStatus] = useState(null)
    const [invitationEmail, setInvitationEmail] = useState(null);
    const [sendingEmailStatus, setSendingEmailStatus] = useState(null);
    const [saveRequestStatus, setSaveRequestStatus] = useState(null);
    const [groups, setGroups] = useState([])
    const alert = useAlert()
    const Roles = Object.keys(userRoles).map( k => {
        return userRoles[k]
    })

    const createInvitationToken = email => {
        setInvitationEmail(email);
        return uuidv5(email, process.env.REACT_APP_UUID5_NAMESPACE);
    };

    useEffect(()=> {
        if(me && me.select) {
            dispatchRoles({
                type: langChoice === 'fr' ? 'SET_FR': 'SET_EN',
                payload: me.select.type === 'school' ? 'sch.': 'cmp.' })
            groupsDoc.where('school', '==', me.select.id)
                .get()
                .then( doc => {
                    setGroups(doc.docs.map( d => {
                        return {id: d.id, ...d.data()}
                    }))
                })

        }

    }, [me])

    const sendInvitation = async (values, invitationToken) => {
        const sendEmail = firebaseInstance().functions().httpsCallable('SendEmail');
        const link = `${process.env.REACT_APP_HOST}/signup/${invitationToken}`;
        const accountType = values.accountRole;
        const langTemplate = langChoice === 'fr' ? 'frConfUser' : 'enConfUser';
        await sendEmail({cc : "sam@bazookka.com",template: langTemplate, invitationToken, ...values})
            .then(val => {
                setInvitationStatus(1);
                setSendingEmailStatus('Invitation email sent!');
            }).catch(err => {
                setInvitationStatus(0)
                setSendingEmailStatus(err.message + 'invite')
            });
    };

    const registerInvitation = async (values, setSubmitting, resetForm) => {
        setInvitationStatus(null)
        setInvitationEmail(null)
        setSendingEmailStatus(null)
        setSaveRequestStatus(null)
        let companyName = null;
        if(values.accountRole === 'sch.business') {
            // companyName = prompt(t('general.companyname'))
            // if(companyName === null || companyName === "") {
            //     alert('Must enter name')
            //     return
            // }
        }

        const invitationToken = createInvitationToken(values.email);

        await db.collection('createAccountInvitations').add({
                acceptedAt: null,
                createdAt: new Date().getTime(),
                email: values.email,
                invitedBy: globalId,
                companyName: me.select.name,
                displayName: values.displayName,
                role: values.accountRole,
                meta : {
                    displayName: values.displayName,
                    role: values.accountRole
                },
                groupsId: [...values.groupsId],
                select: me.select,
                token: invitationToken,
                schools: [me.select],
                schoolId: [me.select.id],
            })
        setSaveRequestStatus('Invitation request registered!');
        alert.success(t("general.registeredinvite"))
        setSubmitting(true)
        resetForm()
        // Send email

        await sendInvitation(values, invitationToken);
        alert.success(t("general.registeredinvite"))


        // db.collection("createAccountInvitations").where('email', '==' , values.email).get().then( data => {
        //     let emailFound = data.docs.map(d=>d.data());
        //     console.log('emailfound', emailFound)
        //     console.log('email', values)
        //     console.log('invitationToken', invitationToken)
        //     if(emailFound.length < 1)  {
        //         db.collection('createAccountInvitations').add({
        //             acceptedAt: null,
        //             createdAt: new Date().getTime(),
        //             email: values.email,
        //             invitedBy: globalId,
        //             companyName,
        //             displayName: values.displayName,
        //             role: values.accountRole,
        //             meta : {
        //                 displayName: values.displayName,
        //                 role: values.accountRole
        //             },
        //             groups: ['all', ...values?.groups],
        //             groupsId: [...values.groupsId],
        //             select: me.select,
        //             token: invitationToken,
        //             schools: [me.select],
        //             schoolId: [me.select.id],
        //         }).then( e => {
        //             setSaveRequestStatus('Invitation request registered!');
        //             alert("Invitation request registered!")
        //             setSubmitting(true)
        //             resetForm()
        //             // Send email
        //
        //             sendInvitation(values, invitationToken);
        //         }).catch(e => {
        //             setInvitationStatus(0)
        //             setSendingEmailStatus(null)
        //             setSaveRequestStatus(e.message + 'create')
        //             alert(e.message)
        //         });
        //     } else {
        //         setSaveRequestStatus('Invitation request exists!');
        //         // Send email
        //         sendInvitation(values, invitationToken);
        //     }
        //
        // }).catch(e => {
        //     setInvitationStatus(0)
        //     setSendingEmailStatus(null)
        //     setSaveRequestStatus(e.message + ' no db creation')
        // });
    };
    return (
        <InviteContainer>
            <div className="title-wrap">
                <TileComponent
                    marginBottom={`1rem`}
                    ico={<i className="mdi mdi-email-plus"></i>}
                    label={t('general.invitetocreateaccount')} />
            </div>
            <Formik
            initialValues={{
                displayName: "",
                email: "",
                groupsId: [],
                accountRole: "" // added for our select
            }}
            validationSchema={Yup.object({
                displayName: Yup.string()
                .min(5, "Must be 5 characters or less")
                .required(t('validation.required')),
                email: Yup.string()
                .email("Invalid email addresss`")
                .required(t('validation.required')),
                accountRole: Yup.string()
                // specify the set of valid values for job type
                // @see http://bit.ly/yup-mixed-oneOf
                .oneOf(
                    Roles,
                    "Invalid Account Type"
                )
                .required(t('validation.required')),
               // groupsId: Yup.array().min(1).required(t('validation.musthaveonegroupe'))
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                registerInvitation(values, setSubmitting, resetForm);
            }}
            >
                {({values, isSubmitting, setFieldValue, errors, resetForm}) => {
                    return (
                        <Form>
                        <NiceInput
                            label={t('general.fullname')}
                            name="displayName"
                            type="text"
                            placeholder="Jane Doe"
                        />
                        <NiceInput
                            label={t('general.emailaddress')}
                            name="email"
                            type="email"
                            placeholder="jane@bazookka.com"
                        />
                        <NiceSelect label={t('general.accounttype')} name="accountRole">
                            <option value="">{t('general.selecttype')}</option>
                            { me &&
                                is2Object(me.select.type, t).map( (option, key) => {
                                    return <option key={key} value={option.key}>{option.value}</option>
                                })
                            }

                        </NiceSelect>
                        <div className="field">
                            <label>{t('general.groups')}</label>
                            <div className="control">
                                <Multiselect
                                    name="groupsId"
                                    options={groups}
                                    displayValue="name"
                                    placeholder={t('overlay.select')}
                                    emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                    onSelect={(v)=> {
                                        setFieldValue('groupsId', v.map( k => k.id))
                                    }}
                                    onRemove={(v)=> {
                                        setFieldValue('groupsId', v.map( k => k.id))
                                    }}
                                />
                                {errors.groupsId && <div style={{color: 'red'}}>{errors.groupsId}</div>}
                            </div>
                        </div>

                        {company && <NiceInput
                            label={t('general.companyname')}
                            name="name"
                            type="text"
                            placeholder={t('general.companyname')}
                        />}
                        <button className="button is-fullwidth" type="submit" disabled={isSubmitting}>{t('general.submit')}</button>
                        <ResetButton className="button is-fullwidth" type="button" disabled={isSubmitting}
                          onClick={e => resetForm()}
                        >{t('listing.reset')}
                        </ResetButton>
                        { invitationStatus !== null && (
                            <div className={ invitationStatus === 0 ? 'error' : '' } >
                                <p>{t('general.invitefor')} {invitationEmail}:</p>
                                <ul>
                                    <li>{saveRequestStatus}</li>
                                    <li>{sendingEmailStatus}</li>
                                </ul>
                            </div>
                        )}
                    </Form>
                    )
                }}
            </Formik>
        </InviteContainer>
    );
};

export default InvitationForm
