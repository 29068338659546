

export  function schoolsReducer(state, action){
    switch(action.type) {
        case 'GetAllSchools':
            return [...action.payload]
        break;
        default:
            return state;
    }
}
