import React, {useContext} from 'react'
import styled from 'styled-components'
import {MyContext} from "../stores";
import {useTranslation} from "react-i18next";
import {
  WarningIco,
} from "../ico";
import i18next from 'i18next';

export const BugReportButton = styled.div`
  position: fixed;
  z-index: 3000;
  right: 2rem;
  bottom: 6.4rem;
  width: 5rem;
  height: 5rem;
  //:hover::after {
  //  content: ' ';
  //  position: absolute;
  //  width: 15px;
  //  height: 15px;
  //  left: auto;
  //  right: 38px;
  //  top: -20px;
  //  background: white;
  //  border: 1px solid;
  //  border-color: #e8e8e8;
  //  //border-top: 15px solid white;
  //  //border-left: 15px solid transparent;
  //}
  :hover::before {
    content: "${(props)=>props.message || 'Test'}";
    position: absolute;
    top: -6rem;
    left: -5rem;
    padding: 1rem;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #e8e8e8;
  }
  div.button {
    color: white;
    background-color: var(--bazookka-pink);
    border: var(--bazookka-pink);
    border-radius: 20%;
    overflow: hidden;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0.15rem 0.15rem 0.15rem rgba(0, 0, 0, 0.5));
  }
`;

export default function ReportBugs({}){
    const {me,dispatchModal} = useContext(MyContext)
    const {t} = useTranslation()

    const delayBeforeScreenshot = (delay) => {
      return new Promise(function (resolve) {
        setTimeout(resolve, delay);
      });
    };

    const takeScreenshot = (e) => {
      navigator.mediaDevices
        .getDisplayMedia()
        .then((stream) => {
          let feedVideo = stream.getVideoTracks()[0]; //Video du screensharing
          let imageCapture = new ImageCapture(feedVideo); //Creates a new ImageCapture object which can be used to capture still frames (photos) from a given MediaStreamTrack which represents a video stream.
          delayBeforeScreenshot(1200).then(() => {
            imageCapture.grabFrame().then((bitmapImage) => {
              feedVideo.stop(); //arrete le partage
              dispatchModal({
                type: "OPEN_MODAL",
                payload: {
                  modalType: "BugReportModal",
                  modalProps: {
                    title: i18next.t("modal.title.bugReport"),
                    ico: <WarningIco />,
                    bitMapImage: bitmapImage,
                    closeOut: () => dispatchModal({ type: "CLOSE_MODAL" }),
                  },
                },
              });
            });
          });
        })
        .catch((e) => console.log(e));
    };
    if(me){
    return (
        <BugReportButton message={t('general.bugreport')}>
            <div className="button" onClick={takeScreenshot}><WarningIco/></div>
        </BugReportButton>
    )}
    else{
        return <></>
    }
}
