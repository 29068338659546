import React, {useEffect, useContext, useState} from "react";
import {MyContext} from "../stores";
import {useHistory, Link, useParams} from 'react-router-dom'
import {Formik, Form} from "formik";
import RichTextEditor, {RichTextEditorSimple} from '../components/RichTextEditor'
import {InputText, TextAreaInput, RichFormikText} from "../components/FormElements";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components'
import {convDocs} from "../firestore/firestoreService";
import {useTranslation} from "react-i18next";

const StyleCrudDocs = styled.div`
  .crud {
    display: grid;
    justify-content: left;
    align-content: center;
    grid-template-columns: 1fr 50px;
    width: 100%;
    padding: 5px 10px;
    background: white;
    border-radius: 5px;
    margin: 1rem 0px;
    border: 1px dashed var(--bazookka-pink);
    > a {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      filter: drop-shadow(1px 2px 2px rgba(0,0,0, 0.4));
      &:hover {
        background: var(--bazookka-blue);
      }
    }
  }
`


export const DisplayCovTemplateList = () => {
    const {history} = useHistory()
    const {globalId} = useContext(MyContext)
    const [docs, setDocs] = useState([])
    const [api, setApi] = useState(true)
    const {t} = useTranslation()

    useEffect(()=> {
        if(api) {
            convDocs.where('authors', 'array-contains', globalId)
                .get()
                .then( docs => {
                    setDocs(docs.docs.map( doc => {
                        return {id: doc.id, ...doc.data()}
                    }))
                    setApi(false)
                })
        }
    }, [api])


    return (
        <StyleCrudDocs className="posts-wrapper">
            <div className="box-heading">
                <h4>noame</h4>
                <div className="button-wrap">
                    <Link  className="button is-active" to={`/convtemplates/Create`}>create</Link>
                </div>
            </div>
            {docs.map( (doc, index) => {
                return (
                    <div className="crud" key={index}>
                        <span>{doc.name}</span>
                        <Link to={`/convtemplates/edit/${doc.id}`}><i className="mdi mdi-pencil"></i></Link>
                    </div>
                )
            })}
        </StyleCrudDocs>
    )
}

const StyledCoverTemplate = styled.div`
  position: relative;
  top: 0px;
  left: 0px;
  margin-bottom: 1rem;
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 300px 1fr 200px;
  > div {
    margin-top: 58px;
    position: relative;
    overflow: scroll;
    .title {
      height: 58px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      color: var(--bazookka-blue);
      filter: drop-shadow(0px 1px 1px rgba(0,0,0,0.20));
    }
    .element {
      display: grid;
      grid-template-columns: 20px 1fr 33px;
      justify-content: center;
      align-items: center;
      background: white;
      border: 1px var(--bazookka-blue) solid;
      border-radius: 5px;
      padding: 5px 10px;
      width: 96%;
      height: 45px;
      color: var(--bazookka-blue);
      margin: 5px auto;
      .circle {
        background: white;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.1));
      }
      
    }
  }
  h4 {
    font-size: 16px;
  }
  .close {
    text-align: right;
    cursor: pointer;
    z-index: 1000;
  }
  .main-drag {
    width: 98% !important;
    padding: 1rem;
    background: white;
    margin: 1rem auto;
    border-radius: 5px;
    border: 1px solid transparent;
    > div {
      width: 98% !important;
      padding: 1rem;
      background: white;
      margin: 1rem auto;
      border-radius: 5px;
      border: 1px solid var(--bazookka-blue)
    }
  }
`

export const EditConvTemplate = () => {
    const [template, setTemplate] = useState([])
    const [convert, setConvert] = useState([])
    const [data, setData] = useState(null)
    const {method, id} = useParams()
    const {t} = useTranslation()


    const addTitle = (e) => {
       switch(e)
        {
            case 'title':
                 setTemplate([...template, {type: 'title', id: uuidv4(), value: ""}])
            break;
            case 'text':
                 setTemplate([...template, {type: 'text', id: uuidv4(), value: ""}])
            break;
            case 'line':
                 setTemplate([...template, {type: 'line', id: uuidv4(), value: ""}])
            break;
            case 'money':
                setTemplate([...template, {type: 'richtext', id: uuidv4(), value: ""}])
            break;
            default:
                 setTemplate([...template, {type: 'line', id: uuidv4(), value: ""}])
        }
    }
    useEffect(()=> {
        if(id) {
            convDocs.doc(id)
                .get()
                .then( doc => {
                    setTemplate(doc.data().items)
                    setData(doc.data())
                })
                .catch( e=> {
                    console.log(e)
                })




        }

    }, [id])
    return (
        <StyledCoverTemplate>
            <div className="items">
                <div className="title">
                    <h4>Elements</h4>
                </div>
                <div className="element">
                    <span>
                        <i className="mdi mdi-format-title"></i>
                    </span>
                    <span>Title</span>
                    <a className="circle" onClick={()=> addTitle('title')}><i className="mdi mdi-plus"></i></a>
                </div>
                <div className="element">
                    <span>
                        <i className="mdi mdi-textbox"></i>
                    </span>
                    <span>Text</span>
                    <a className="circle" onClick={()=> addTitle('text')}><i className="mdi mdi-plus"></i></a>
                </div>
                <div className="element">
                    <span>
                        <i className="mdi mdi-format-line-spacing"></i>
                    </span>
                    <span>Line</span>
                    <a className="circle" onClick={()=> addTitle('line')}><i className="mdi mdi-plus"></i></a>
                </div>
                <div className="element">
                    <span>
                        <i className="mdi mdi-format-text"></i>
                    </span>
                    <span>Rich text</span>
                    <a className="circle" onClick={()=> addTitle('money')}><i className="mdi mdi-plus"></i></a>
                </div>
            </div>
            <div className="edit">
                <div className="title">
                    <h4>Edit</h4>
                </div>
                <Edit items={template} setItems={setTemplate} setConvert={setConvert} convert={convert} data={data}/>
            </div>
            <div className="preview">
                <div className="title">
                    <h4>Preview</h4>
                </div>
                <Preview items={convert} />
            </div>
        </StyledCoverTemplate> )
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
const grid = 8;
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "white",
    padding: grid,
    width: 250
});
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    width: `250px !important`,
    overflow: 'hidden',

    // change background colour if dragging
    background: isDragging ? "#e8e8e8" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
});

const Edit = ({items, setItems, data}) =>{
    const {globalId, me} = useContext(MyContext)
    const {method, id} = useParams()
    const history = useHistory()
    const {t} = useTranslation()
    const returnElement = (elem, values) => {
        switch(elem.type) {
            case "text":
                return <TextAreaInput name={elem.id}  />
            break;
            case "title":
                 return <InputText name={elem.id}  />
            break;
            case "richtext":

                return <RichFormikText name={elem.id} method={method && true} value={values[elem.id]} />
            break;
            case "line":
                return <hr />
            break;
            default:
                return <br />
        }
    }


    const onDragEnd = (result) =>{
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const itemsNew = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(itemsNew)
    }
    const onSubmit = async (values) => {
        if(!method) {
            await convDocs.add({
                ...values,
                authors: [globalId],
                date: new Date().getTime(),
                updateAt: new Date().getTime(),
                items
            })
        }else {
            await convDocs.doc(id).update({
                ...values,
                updateAt: new Date().getTime(),
                items
            })
        }
            alert(t("general.saved"))
            history.goBack()


    }

        return (
            <Formik initialValues={data || {}} onSubmit={onSubmit} enableReinitialize={true}>
                {({values}) => {
                    return (
                        <Form>
                            <InputText name="name" label="name" />
                            <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => {
                                    return (
                                        <div className="main-drag"
                                             {...provided.droppableProps}
                                             ref={provided.innerRef}
                                             style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {items.map((item, i) => {
                                                return (
                                                    <Draggable key={item.id} draggableId={item.id} index={i}>
                                                        {(provided, snapshot) => {
                                                            return (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    <div className="close"
                                                                        onClick={()=>{
                                                                            setItems(items.filter( its => its.id !== item.id))
                                                                        }}
                                                                    >
                                                                        <i className="mdi mdi-tag-remove"></i></div>
                                                                    {item.type}
                                                                    {returnElement(item, values)}
                                                                </div>
                                                            )
                                                        }}

                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}

                                        </div>

                                    )
                                }}
                            </Droppable>
                        </DragDropContext>
                            <div>
                                <button type="submit" className="button is-fullwidth">Save</button>
                            </div>
                        </Form>
                    )
                }}

            </Formik>
        )

}

const Preview = ({items}) => {
    const type = (e, i) => {
        switch (e.type) {
            case 'title':
                return <h4 key={i}>{e.value}</h4>
            break;
            default:
                return <br key={i} />
        }
    }
    return (
        <div>
            {items.map((item, i) => {
                return type(item, i)
            })}
        </div>
    )
}
