const englishLanguageName = [
  {
   "code": "aa",
   "label": "Afar"
  },
  {
   "code": "ab",
   "label": "Abkhazian"
  },
  {
   "code": "ae",
   "label": "Avestan"
  },
  {
   "code": "af",
   "label": "Afrikaans"
  },
  {
   "code": "ak",
   "label": "Akan"
  },
  {
   "code": "am",
   "label": "Amharic"
  },
  {
   "code": "an",
   "label": "Aragonese"
  },
  {
   "code": "ar",
   "label": "Arabic"
  },
  {
   "code": "as",
   "label": "Assamese"
  },
  {
   "code": "av",
   "label": "Avaric"
  },
  {
   "code": "ay",
   "label": "Aymara"
  },
  {
   "code": "az",
   "label": "Azerbaijani"
  },
  {
   "code": "ba",
   "label": "Bashkir"
  },
  {
   "code": "be",
   "label": "Belarusian"
  },
  {
   "code": "bg",
   "label": "Bulgarian"
  },
  {
   "code": "bh",
   "label": "Bihari"
  },
  {
   "code": "bi",
   "label": "Bislama"
  },
  {
   "code": "bm",
   "label": "Bambara"
  },
  {
   "code": "bn",
   "label": "Bengali"
  },
  {
   "code": "bo",
   "label": "Tibetan"
  },
  {
   "code": "br",
   "label": "Breton"
  },
  {
   "code": "bs",
   "label": "Bosnian"
  },
  {
   "code": "ca",
   "label": "Catalan"
  },
  {
   "code": "ce",
   "label": "Chechen"
  },
  {
   "code": "ch",
   "label": "Chamorro"
  },
  {
   "code": "co",
   "label": "Corsican"
  },
  {
   "code": "cr",
   "label": "Cree"
  },
  {
   "code": "cs",
   "label": "Czech"
  },
  {
   "code": "cu",
   "label": "Old Church Slavonic"
  },
  {
   "code": "cv",
   "label": "Chuvash"
  },
  {
   "code": "cy",
   "label": "Welsh"
  },
  {
   "code": "da",
   "label": "Danish"
  },
  {
   "code": "de",
   "label": "German"
  },
  {
   "code": "dv",
   "label": "Divehi"
  },
  {
   "code": "dz",
   "label": "Dzongkha"
  },
  {
   "code": "ee",
   "label": "Ewe"
  },
  {
   "code": "el",
   "label": "Greek"
  },
  {
   "code": "en",
   "label": "English"
  },
  {
   "code": "eo",
   "label": "Esperanto"
  },
  {
   "code": "es",
   "label": "Spanish"
  },
  {
   "code": "et",
   "label": "Estonian"
  },
  {
   "code": "eu",
   "label": "Basque"
  },
  {
   "code": "fa",
   "label": "Persian"
  },
  {
   "code": "ff",
   "label": "Fulah"
  },
  {
   "code": "fi",
   "label": "Finnish"
  },
  {
   "code": "fj",
   "label": "Fijian"
  },
  {
   "code": "fo",
   "label": "Faroese"
  },
  {
   "code": "fr",
   "label": "French"
  },
  {
   "code": "fy",
   "label": "Western Frisian"
  },
  {
   "code": "ga",
   "label": "Irish"
  },
  {
   "code": "gd",
   "label": "Scottish Gaelic"
  },
  {
   "code": "gl",
   "label": "Galician"
  },
  {
   "code": "gn",
   "label": "Guarani"
  },
  {
   "code": "gu",
   "label": "Gujarati"
  },
  {
   "code": "gv",
   "label": "Manx"
  },
  {
   "code": "ha",
   "label": "Hausa"
  },
  {
   "code": "he",
   "label": "Hebrew"
  },
  {
   "code": "hi",
   "label": "Hindi"
  },
  {
   "code": "ho",
   "label": "Hiri Motu"
  },
  {
   "code": "hr",
   "label": "Croatian"
  },
  {
   "code": "ht",
   "label": "Haitian"
  },
  {
   "code": "hu",
   "label": "Hungarian"
  },
  {
   "code": "hy",
   "label": "Armenian"
  },
  {
   "code": "hz",
   "label": "Herero"
  },
  {
   "code": "ia",
   "label": "Interlingua"
  },
  {
   "code": "id",
   "label": "Indonesian"
  },
  {
   "code": "ie",
   "label": "Interlingue"
  },
  {
   "code": "ig",
   "label": "Igbo"
  },
  {
   "code": "ii",
   "label": "Sichuan Yi"
  },
  {
   "code": "ik",
   "label": "Inupiaq"
  },
  {
   "code": "io",
   "label": "Ido"
  },
  {
   "code": "is",
   "label": "Icelandic"
  },
  {
   "code": "it",
   "label": "Italian"
  },
  {
   "code": "iu",
   "label": "Inuktitut"
  },
  {
   "code": "ja",
   "label": "Japanese"
  },
  {
   "code": "jv",
   "label": "Javanese"
  },
  {
   "code": "ka",
   "label": "Georgian"
  },
  {
   "code": "kg",
   "label": "Kongo"
  },
  {
   "code": "ki",
   "label": "Kikuyu"
  },
  {
   "code": "kj",
   "label": "Kwanyama"
  },
  {
   "code": "kk",
   "label": "Kazakh"
  },
  {
   "code": "kl",
   "label": "Greenlandic"
  },
  {
   "code": "km",
   "label": "Khmer"
  },
  {
   "code": "kn",
   "label": "Kannada"
  },
  {
   "code": "ko",
   "label": "Korean"
  },
  {
   "code": "kr",
   "label": "Kanuri"
  },
  {
   "code": "ks",
   "label": "Kashmiri"
  },
  {
   "code": "ku",
   "label": "Kurdish"
  },
  {
   "code": "kv",
   "label": "Komi"
  },
  {
   "code": "kw",
   "label": "Cornish"
  },
  {
   "code": "ky",
   "label": "Kirghiz"
  },
  {
   "code": "la",
   "label": "Latin"
  },
  {
   "code": "lb",
   "label": "Luxembourgish"
  },
  {
   "code": "lg",
   "label": "Ganda"
  },
  {
   "code": "li",
   "label": "Limburgish"
  },
  {
   "code": "ln",
   "label": "Lingala"
  },
  {
   "code": "lo",
   "label": "Lao"
  },
  {
   "code": "lt",
   "label": "Lithuanian"
  },
  {
   "code": "lu",
   "label": "Luba"
  },
  {
   "code": "lv",
   "label": "Latvian"
  },
  {
   "code": "mg",
   "label": "Malagasy"
  },
  {
   "code": "mh",
   "label": "Marshallese"
  },
  {
   "code": "mi",
   "label": "Māori"
  },
  {
   "code": "mk",
   "label": "Macedonian"
  },
  {
   "code": "ml",
   "label": "Malayalam"
  },
  {
   "code": "mn",
   "label": "Mongolian"
  },
  {
   "code": "mo",
   "label": "Moldavian"
  },
  {
   "code": "mr",
   "label": "Marathi"
  },
  {
   "code": "ms",
   "label": "Malay"
  },
  {
   "code": "mt",
   "label": "Maltese"
  },
  {
   "code": "my",
   "label": "Burmese"
  },
  {
   "code": "na",
   "label": "Nauru"
  },
  {
   "code": "nb",
   "label": "Norwegian Bokmål"
  },
  {
   "code": "nd",
   "label": "North Ndebele"
  },
  {
   "code": "ne",
   "label": "Nepali"
  },
  {
   "code": "ng",
   "label": "Ndonga"
  },
  {
   "code": "nl",
   "label": "Dutch"
  },
  {
   "code": "nn",
   "label": "Norwegian Nynorsk"
  },
  {
   "code": "no",
   "label": "Norwegian"
  },
  {
   "code": "nr",
   "label": "South Ndebele"
  },
  {
   "code": "nv",
   "label": "Navajo"
  },
  {
   "code": "ny",
   "label": "Chichewa"
  },
  {
   "code": "oc",
   "label": "Occitan"
  },
  {
   "code": "oj",
   "label": "Ojibwa"
  },
  {
   "code": "om",
   "label": "Oromo"
  },
  {
   "code": "or",
   "label": "Oriya"
  },
  {
   "code": "os",
   "label": "Ossetian"
  },
  {
   "code": "pa",
   "label": "Panjabi"
  },
  {
   "code": "pi",
   "label": "Pāli"
  },
  {
   "code": "pl",
   "label": "Polish"
  },
  {
   "code": "ps",
   "label": "Pashto"
  },
  {
   "code": "pt",
   "label": "Portuguese"
  },
  {
   "code": "qu",
   "label": "Quechua"
  },
  {
   "code": "rc",
   "label": "Reunionese"
  },
  {
   "code": "rm",
   "label": "Romansh"
  },
  {
   "code": "rn",
   "label": "Kirundi"
  },
  {
   "code": "ro",
   "label": "Romanian"
  },
  {
   "code": "ru",
   "label": "Russian"
  },
  {
   "code": "rw",
   "label": "Kinyarwanda"
  },
  {
   "code": "sa",
   "label": "Sanskrit"
  },
  {
   "code": "sc",
   "label": "Sardinian"
  },
  {
   "code": "sd",
   "label": "Sindhi"
  },
  {
   "code": "se",
   "label": "Northern Sami"
  },
  {
   "code": "sg",
   "label": "Sango"
  },
  {
   "code": "sh",
   "label": "Serbo-Croatian"
  },
  {
   "code": "si",
   "label": "Sinhalese"
  },
  {
   "code": "sk",
   "label": "Slovak"
  },
  {
   "code": "sl",
   "label": "Slovenian"
  },
  {
   "code": "sm",
   "label": "Samoan"
  },
  {
   "code": "sn",
   "label": "Shona"
  },
  {
   "code": "so",
   "label": "Somali"
  },
  {
   "code": "sq",
   "label": "Albanian"
  },
  {
   "code": "sr",
   "label": "Serbian"
  },
  {
   "code": "ss",
   "label": "Swati"
  },
  {
   "code": "st",
   "label": "Sotho"
  },
  {
   "code": "su",
   "label": "Sundanese"
  },
  {
   "code": "sv",
   "label": "Swedish"
  },
  {
   "code": "sw",
   "label": "Swahili"
  },
  {
   "code": "ta",
   "label": "Tamil"
  },
  {
   "code": "te",
   "label": "Telugu"
  },
  {
   "code": "tg",
   "label": "Tajik"
  },
  {
   "code": "th",
   "label": "Thai"
  },
  {
   "code": "ti",
   "label": "Tigrinya"
  },
  {
   "code": "tk",
   "label": "Turkmen"
  },
  {
   "code": "tl",
   "label": "Tagalog"
  },
  {
   "code": "tn",
   "label": "Tswana"
  },
  {
   "code": "to",
   "label": "Tonga"
  },
  {
   "code": "tr",
   "label": "Turkish"
  },
  {
   "code": "ts",
   "label": "Tsonga"
  },
  {
   "code": "tt",
   "label": "Tatar"
  },
  {
   "code": "tw",
   "label": "Twi"
  },
  {
   "code": "ty",
   "label": "Tahitian"
  },
  {
   "code": "ug",
   "label": "Uighur"
  },
  {
   "code": "uk",
   "label": "Ukrainian"
  },
  {
   "code": "ur",
   "label": "Urdu"
  },
  {
   "code": "uz",
   "label": "Uzbek"
  },
  {
   "code": "ve",
   "label": "Venda"
  },
  {
   "code": "vi",
   "label": "Viêt Namese"
  },
  {
   "code": "vo",
   "label": "Volapük"
  },
  {
   "code": "wa",
   "label": "Walloon"
  },
  {
   "code": "wo",
   "label": "Wolof"
  },
  {
   "code": "xh",
   "label": "Xhosa"
  },
  {
   "code": "yi",
   "label": "Yiddish"
  },
  {
   "code": "yo",
   "label": "Yoruba"
  },
  {
   "code": "za",
   "label": "Zhuang"
  },
  {
   "code": "zh",
   "label": "Chinese"
  },
  {
   "code": "zu",
   "label": "Zulu"
  }
 ]


const frenchLanguageName = [
  {
   "code": "aa",
   "label": "Afar"
  },
  {
   "code": "ab",
   "label": "Abkhaze"
  },
  {
   "code": "ae",
   "label": "Avestique"
  },
  {
   "code": "af",
   "label": "Afrikaans"
  },
  {
   "code": "ak",
   "label": "Akan"
  },
  {
   "code": "am",
   "label": "Amharique"
  },
  {
   "code": "an",
   "label": "Aragonais"
  },
  {
   "code": "ar",
   "label": "Arabe"
  },
  {
   "code": "as",
   "label": "Assamais"
  },
  {
   "code": "av",
   "label": "Avar"
  },
  {
   "code": "ay",
   "label": "Aymara"
  },
  {
   "code": "az",
   "label": "Azéri"
  },
  {
   "code": "ba",
   "label": "Bachkir"
  },
  {
   "code": "be",
   "label": "Biélorusse"
  },
  {
   "code": "bg",
   "label": "Bulgare"
  },
  {
   "code": "bh",
   "label": "Bihari"
  },
  {
   "code": "bi",
   "label": "Bichelamar"
  },
  {
   "code": "bm",
   "label": "Bambara"
  },
  {
   "code": "bn",
   "label": "Bengali"
  },
  {
   "code": "bo",
   "label": "Tibétain"
  },
  {
   "code": "br",
   "label": "Breton"
  },
  {
   "code": "bs",
   "label": "Bosnien"
  },
  {
   "code": "ca",
   "label": "Catalan"
  },
  {
   "code": "ce",
   "label": "Tchétchène"
  },
  {
   "code": "ch",
   "label": "Chamorro"
  },
  {
   "code": "co",
   "label": "Corse"
  },
  {
   "code": "cr",
   "label": "Cri"
  },
  {
   "code": "cs",
   "label": "Tchèque"
  },
  {
   "code": "cu",
   "label": "Vieux-slave"
  },
  {
   "code": "cv",
   "label": "Tchouvache"
  },
  {
   "code": "cy",
   "label": "Gallois"
  },
  {
   "code": "da",
   "label": "Danois"
  },
  {
   "code": "de",
   "label": "Allemand"
  },
  {
   "code": "dv",
   "label": "Maldivien"
  },
  {
   "code": "dz",
   "label": "Dzongkha"
  },
  {
   "code": "ee",
   "label": "Ewe"
  },
  {
   "code": "el",
   "label": "Grec moderne"
  },
  {
   "code": "en",
   "label": "Anglais"
  },
  {
   "code": "eo",
   "label": "Espéranto"
  },
  {
   "code": "es",
   "label": "Espagnol"
  },
  {
   "code": "et",
   "label": "Estonien"
  },
  {
   "code": "eu",
   "label": "Basque"
  },
  {
   "code": "fa",
   "label": "Persan"
  },
  {
   "code": "ff",
   "label": "Peul"
  },
  {
   "code": "fi",
   "label": "Finnois"
  },
  {
   "code": "fj",
   "label": "Fidjien"
  },
  {
   "code": "fo",
   "label": "Féroïen"
  },
  {
   "code": "fr",
   "label": "Français"
  },
  {
   "code": "fy",
   "label": "Frison occidental"
  },
  {
   "code": "ga",
   "label": "Irlandais"
  },
  {
   "code": "gd",
   "label": "Écossais"
  },
  {
   "code": "gl",
   "label": "Galicien"
  },
  {
   "code": "gn",
   "label": "Guarani"
  },
  {
   "code": "gu",
   "label": "Gujarati"
  },
  {
   "code": "gv",
   "label": "Mannois"
  },
  {
   "code": "ha",
   "label": "Haoussa"
  },
  {
   "code": "he",
   "label": "Hébreu"
  },
  {
   "code": "hi",
   "label": "Hindi"
  },
  {
   "code": "ho",
   "label": "Hiri motu"
  },
  {
   "code": "hr",
   "label": "Croate"
  },
  {
   "code": "ht",
   "label": "Créole haïtien"
  },
  {
   "code": "hu",
   "label": "Hongrois"
  },
  {
   "code": "hy",
   "label": "Arménien"
  },
  {
   "code": "hz",
   "label": "Héréro"
  },
  {
   "code": "ia",
   "label": "Interlingua"
  },
  {
   "code": "id",
   "label": "Indonésien"
  },
  {
   "code": "ie",
   "label": "Occidental"
  },
  {
   "code": "ig",
   "label": "Igbo"
  },
  {
   "code": "ii",
   "label": "Yi"
  },
  {
   "code": "ik",
   "label": "Inupiak"
  },
  {
   "code": "io",
   "label": "Ido"
  },
  {
   "code": "is",
   "label": "Islandais"
  },
  {
   "code": "it",
   "label": "Italien"
  },
  {
   "code": "iu",
   "label": "Inuktitut"
  },
  {
   "code": "ja",
   "label": "Japonais"
  },
  {
   "code": "jv",
   "label": "Javanais"
  },
  {
   "code": "ka",
   "label": "Géorgien"
  },
  {
   "code": "kg",
   "label": "Kikongo"
  },
  {
   "code": "ki",
   "label": "Kikuyu"
  },
  {
   "code": "kj",
   "label": "Kuanyama"
  },
  {
   "code": "kk",
   "label": "Kazakh"
  },
  {
   "code": "kl",
   "label": "Groenlandais"
  },
  {
   "code": "km",
   "label": "Khmer"
  },
  {
   "code": "kn",
   "label": "Kannada"
  },
  {
   "code": "ko",
   "label": "Coréen"
  },
  {
   "code": "kr",
   "label": "Kanouri"
  },
  {
   "code": "ks",
   "label": "Cachemiri"
  },
  {
   "code": "ku",
   "label": "Kurde"
  },
  {
   "code": "kv",
   "label": "Komi"
  },
  {
   "code": "kw",
   "label": "Cornique"
  },
  {
   "code": "ky",
   "label": "Kirghiz"
  },
  {
   "code": "la",
   "label": "Latin"
  },
  {
   "code": "lb",
   "label": "Luxembourgeois"
  },
  {
   "code": "lg",
   "label": "Ganda"
  },
  {
   "code": "li",
   "label": "Limbourgeois"
  },
  {
   "code": "ln",
   "label": "Lingala"
  },
  {
   "code": "lo",
   "label": "Lao"
  },
  {
   "code": "lt",
   "label": "Lituanien"
  },
  {
   "code": "lu",
   "label": "Luba"
  },
  {
   "code": "lv",
   "label": "Letton"
  },
  {
   "code": "mg",
   "label": "Malgache"
  },
  {
   "code": "mh",
   "label": "Marshallais"
  },
  {
   "code": "mi",
   "label": "Maori de Nouvelle-Zélande"
  },
  {
   "code": "mk",
   "label": "Macédonien"
  },
  {
   "code": "ml",
   "label": "Malayalam"
  },
  {
   "code": "mn",
   "label": "Mongol"
  },
  {
   "code": "mo",
   "label": "Moldave"
  },
  {
   "code": "mr",
   "label": "Marathi"
  },
  {
   "code": "ms",
   "label": "Malais"
  },
  {
   "code": "mt",
   "label": "Maltais"
  },
  {
   "code": "my",
   "label": "Birman"
  },
  {
   "code": "na",
   "label": "Nauruan"
  },
  {
   "code": "nb",
   "label": "Norvégien Bokmål"
  },
  {
   "code": "nd",
   "label": "Sindebele"
  },
  {
   "code": "ne",
   "label": "Népalais"
  },
  {
   "code": "ng",
   "label": "Ndonga"
  },
  {
   "code": "nl",
   "label": "Néerlandais"
  },
  {
   "code": "nn",
   "label": "Norvégien Nynorsk"
  },
  {
   "code": "no",
   "label": "Norvégien"
  },
  {
   "code": "nr",
   "label": "Nrebele"
  },
  {
   "code": "nv",
   "label": "Navajo"
  },
  {
   "code": "ny",
   "label": "Chichewa"
  },
  {
   "code": "oc",
   "label": "Occitan"
  },
  {
   "code": "oj",
   "label": "Ojibwé"
  },
  {
   "code": "om",
   "label": "Oromo"
  },
  {
   "code": "or",
   "label": "Oriya"
  },
  {
   "code": "os",
   "label": "Ossète"
  },
  {
   "code": "pa",
   "label": "Pendjabi"
  },
  {
   "code": "pi",
   "label": "Pali"
  },
  {
   "code": "pl",
   "label": "Polonais"
  },
  {
   "code": "ps",
   "label": "Pachto"
  },
  {
   "code": "pt",
   "label": "Portugais"
  },
  {
   "code": "qu",
   "label": "Quechua"
  },
  {
   "code": "rc",
   "label": "Créole Réunionnais"
  },
  {
   "code": "rm",
   "label": "Romanche"
  },
  {
   "code": "rn",
   "label": "Kirundi"
  },
  {
   "code": "ro",
   "label": "Roumain"
  },
  {
   "code": "ru",
   "label": "Russe"
  },
  {
   "code": "rw",
   "label": "Kinyarwanda"
  },
  {
   "code": "sa",
   "label": "Sanskrit"
  },
  {
   "code": "sc",
   "label": "Sarde"
  },
  {
   "code": "sd",
   "label": "Sindhi"
  },
  {
   "code": "se",
   "label": "Same du Nord"
  },
  {
   "code": "sg",
   "label": "Sango"
  },
  {
   "code": "sh",
   "label": "Serbo-croate"
  },
  {
   "code": "si",
   "label": "Cingalais"
  },
  {
   "code": "sk",
   "label": "Slovaque"
  },
  {
   "code": "sl",
   "label": "Slovène"
  },
  {
   "code": "sm",
   "label": "Samoan"
  },
  {
   "code": "sn",
   "label": "Shona"
  },
  {
   "code": "so",
   "label": "Somali"
  },
  {
   "code": "sq",
   "label": "Albanais"
  },
  {
   "code": "sr",
   "label": "Serbe"
  },
  {
   "code": "ss",
   "label": "Swati"
  },
  {
   "code": "st",
   "label": "Sotho du Sud"
  },
  {
   "code": "su",
   "label": "Soundanais"
  },
  {
   "code": "sv",
   "label": "Suédois"
  },
  {
   "code": "sw",
   "label": "Swahili"
  },
  {
   "code": "ta",
   "label": "Tamoul"
  },
  {
   "code": "te",
   "label": "Télougou"
  },
  {
   "code": "tg",
   "label": "Tadjik"
  },
  {
   "code": "th",
   "label": "Thaï"
  },
  {
   "code": "ti",
   "label": "Tigrigna"
  },
  {
   "code": "tk",
   "label": "Turkmène"
  },
  {
   "code": "tl",
   "label": "Tagalog"
  },
  {
   "code": "tn",
   "label": "Tswana"
  },
  {
   "code": "to",
   "label": "Tongien"
  },
  {
   "code": "tr",
   "label": "Turc"
  },
  {
   "code": "ts",
   "label": "Tsonga"
  },
  {
   "code": "tt",
   "label": "Tatar"
  },
  {
   "code": "tw",
   "label": "Twi"
  },
  {
   "code": "ty",
   "label": "Tahitien"
  },
  {
   "code": "ug",
   "label": "Ouïghour"
  },
  {
   "code": "uk",
   "label": "Ukrainien"
  },
  {
   "code": "ur",
   "label": "Ourdou"
  },
  {
   "code": "uz",
   "label": "Ouzbek"
  },
  {
   "code": "ve",
   "label": "Venda"
  },
  {
   "code": "vi",
   "label": "Vietnamien"
  },
  {
   "code": "vo",
   "label": "Volapük"
  },
  {
   "code": "wa",
   "label": "Wallon"
  },
  {
   "code": "wo",
   "label": "Wolof"
  },
  {
   "code": "xh",
   "label": "Xhosa"
  },
  {
   "code": "yi",
   "label": "Yiddish"
  },
  {
   "code": "yo",
   "label": "Yoruba"
  },
  {
   "code": "za",
   "label": "Zhuang"
  },
  {
   "code": "zh",
   "label": "Chinois"
  },
  {
   "code": "zu",
   "label": "Zoulou"
  }
 ]


 const languages = [
  {
   "code": "aa",
   "french": "Afar",
   "english": "Afar"
  },
  {
   "code": "ab",
   "french": "Abkhaze",
   "english": "Abkhazian"
  },
  {
   "code": "ae",
   "french": "Avestique",
   "english": "Avestan"
  },
  {
   "code": "af",
   "french": "Afrikaans",
   "english": "Afrikaans"
  },
  {
   "code": "ak",
   "french": "Akan",
   "english": "Akan"
  },
  {
   "code": "am",
   "french": "Amharique",
   "english": "Amharic"
  },
  {
   "code": "an",
   "french": "Aragonais",
   "english": "Aragonese"
  },
  {
   "code": "ar",
   "french": "Arabe",
   "english": "Arabic"
  },
  {
   "code": "as",
   "french": "Assamais",
   "english": "Assamese"
  },
  {
   "code": "av",
   "french": "Avar",
   "english": "Avaric"
  },
  {
   "code": "ay",
   "french": "Aymara",
   "english": "Aymara"
  },
  {
   "code": "az",
   "french": "Azéri",
   "english": "Azerbaijani"
  },
  {
   "code": "ba",
   "french": "Bachkir",
   "english": "Bashkir"
  },
  {
   "code": "be",
   "french": "Biélorusse",
   "english": "Belarusian"
  },
  {
   "code": "bg",
   "french": "Bulgare",
   "english": "Bulgarian"
  },
  {
   "code": "bh",
   "french": "Bihari",
   "english": "Bihari"
  },
  {
   "code": "bi",
   "french": "Bichelamar",
   "english": "Bislama"
  },
  {
   "code": "bm",
   "french": "Bambara",
   "english": "Bambara"
  },
  {
   "code": "bn",
   "french": "Bengali",
   "english": "Bengali"
  },
  {
   "code": "bo",
   "french": "Tibétain",
   "english": "Tibetan"
  },
  {
   "code": "br",
   "french": "Breton",
   "english": "Breton"
  },
  {
   "code": "bs",
   "french": "Bosnien",
   "english": "Bosnian"
  },
  {
   "code": "ca",
   "french": "Catalan",
   "english": "Catalan"
  },
  {
   "code": "ce",
   "french": "Tchétchène",
   "english": "Chechen"
  },
  {
   "code": "ch",
   "french": "Chamorro",
   "english": "Chamorro"
  },
  {
   "code": "co",
   "french": "Corse",
   "english": "Corsican"
  },
  {
   "code": "cr",
   "french": "Cri",
   "english": "Cree"
  },
  {
   "code": "cs",
   "french": "Tchèque",
   "english": "Czech"
  },
  {
   "code": "cu",
   "french": "Vieux-slave",
   "english": "Old Church Slavonic"
  },
  {
   "code": "cv",
   "french": "Tchouvache",
   "english": "Chuvash"
  },
  {
   "code": "cy",
   "french": "Gallois",
   "english": "Welsh"
  },
  {
   "code": "da",
   "french": "Danois",
   "english": "Danish"
  },
  {
   "code": "de",
   "french": "Allemand",
   "english": "German"
  },
  {
   "code": "dv",
   "french": "Maldivien",
   "english": "Divehi"
  },
  {
   "code": "dz",
   "french": "Dzongkha",
   "english": "Dzongkha"
  },
  {
   "code": "ee",
   "french": "Ewe",
   "english": "Ewe"
  },
  {
   "code": "el",
   "french": "Grec moderne",
   "english": "Greek"
  },
  {
   "code": "en",
   "french": "Anglais",
   "english": "English"
  },
  {
   "code": "eo",
   "french": "Espéranto",
   "english": "Esperanto"
  },
  {
   "code": "es",
   "french": "Espagnol",
   "english": "Spanish"
  },
  {
   "code": "et",
   "french": "Estonien",
   "english": "Estonian"
  },
  {
   "code": "eu",
   "french": "Basque",
   "english": "Basque"
  },
  {
   "code": "fa",
   "french": "Persan",
   "english": "Persian"
  },
  {
   "code": "ff",
   "french": "Peul",
   "english": "Fulah"
  },
  {
   "code": "fi",
   "french": "Finnois",
   "english": "Finnish"
  },
  {
   "code": "fj",
   "french": "Fidjien",
   "english": "Fijian"
  },
  {
   "code": "fo",
   "french": "Féroïen",
   "english": "Faroese"
  },
  {
   "code": "fr",
   "french": "Français",
   "english": "French"
  },
  {
   "code": "fy",
   "french": "Frison occidental",
   "english": "Western Frisian"
  },
  {
   "code": "ga",
   "french": "Irlandais",
   "english": "Irish"
  },
  {
   "code": "gd",
   "french": "Écossais",
   "english": "Scottish Gaelic"
  },
  {
   "code": "gl",
   "french": "Galicien",
   "english": "Galician"
  },
  {
   "code": "gn",
   "french": "Guarani",
   "english": "Guarani"
  },
  {
   "code": "gu",
   "french": "Gujarati",
   "english": "Gujarati"
  },
  {
   "code": "gv",
   "french": "Mannois",
   "english": "Manx"
  },
  {
   "code": "ha",
   "french": "Haoussa",
   "english": "Hausa"
  },
  {
   "code": "he",
   "french": "Hébreu",
   "english": "Hebrew"
  },
  {
   "code": "hi",
   "french": "Hindi",
   "english": "Hindi"
  },
  {
   "code": "ho",
   "french": "Hiri motu",
   "english": "Hiri Motu"
  },
  {
   "code": "hr",
   "french": "Croate",
   "english": "Croatian"
  },
  {
   "code": "ht",
   "french": "Créole haïtien",
   "english": "Haitian"
  },
  {
   "code": "hu",
   "french": "Hongrois",
   "english": "Hungarian"
  },
  {
   "code": "hy",
   "french": "Arménien",
   "english": "Armenian"
  },
  {
   "code": "hz",
   "french": "Héréro",
   "english": "Herero"
  },
  {
   "code": "ia",
   "french": "Interlingua",
   "english": "Interlingua"
  },
  {
   "code": "id",
   "french": "Indonésien",
   "english": "Indonesian"
  },
  {
   "code": "ie",
   "french": "Occidental",
   "english": "Interlingue"
  },
  {
   "code": "ig",
   "french": "Igbo",
   "english": "Igbo"
  },
  {
   "code": "ii",
   "french": "Yi",
   "english": "Sichuan Yi"
  },
  {
   "code": "ik",
   "french": "Inupiak",
   "english": "Inupiaq"
  },
  {
   "code": "io",
   "french": "Ido",
   "english": "Ido"
  },
  {
   "code": "is",
   "french": "Islandais",
   "english": "Icelandic"
  },
  {
   "code": "it",
   "french": "Italien",
   "english": "Italian"
  },
  {
   "code": "iu",
   "french": "Inuktitut",
   "english": "Inuktitut"
  },
  {
   "code": "ja",
   "french": "Japonais",
   "english": "Japanese"
  },
  {
   "code": "jv",
   "french": "Javanais",
   "english": "Javanese"
  },
  {
   "code": "ka",
   "french": "Géorgien",
   "english": "Georgian"
  },
  {
   "code": "kg",
   "french": "Kikongo",
   "english": "Kongo"
  },
  {
   "code": "ki",
   "french": "Kikuyu",
   "english": "Kikuyu"
  },
  {
   "code": "kj",
   "french": "Kuanyama",
   "english": "Kwanyama"
  },
  {
   "code": "kk",
   "french": "Kazakh",
   "english": "Kazakh"
  },
  {
   "code": "kl",
   "french": "Groenlandais",
   "english": "Greenlandic"
  },
  {
   "code": "km",
   "french": "Khmer",
   "english": "Khmer"
  },
  {
   "code": "kn",
   "french": "Kannada",
   "english": "Kannada"
  },
  {
   "code": "ko",
   "french": "Coréen",
   "english": "Korean"
  },
  {
   "code": "kr",
   "french": "Kanouri",
   "english": "Kanuri"
  },
  {
   "code": "ks",
   "french": "Cachemiri",
   "english": "Kashmiri"
  },
  {
   "code": "ku",
   "french": "Kurde",
   "english": "Kurdish"
  },
  {
   "code": "kv",
   "french": "Komi",
   "english": "Komi"
  },
  {
   "code": "kw",
   "french": "Cornique",
   "english": "Cornish"
  },
  {
   "code": "ky",
   "french": "Kirghiz",
   "english": "Kirghiz"
  },
  {
   "code": "la",
   "french": "Latin",
   "english": "Latin"
  },
  {
   "code": "lb",
   "french": "Luxembourgeois",
   "english": "Luxembourgish"
  },
  {
   "code": "lg",
   "french": "Ganda",
   "english": "Ganda"
  },
  {
   "code": "li",
   "french": "Limbourgeois",
   "english": "Limburgish"
  },
  {
   "code": "ln",
   "french": "Lingala",
   "english": "Lingala"
  },
  {
   "code": "lo",
   "french": "Lao",
   "english": "Lao"
  },
  {
   "code": "lt",
   "french": "Lituanien",
   "english": "Lithuanian"
  },
  {
   "code": "lu",
   "french": "Luba",
   "english": "Luba"
  },
  {
   "code": "lv",
   "french": "Letton",
   "english": "Latvian"
  },
  {
   "code": "mg",
   "french": "Malgache",
   "english": "Malagasy"
  },
  {
   "code": "mh",
   "french": "Marshallais",
   "english": "Marshallese"
  },
  {
   "code": "mi",
   "french": "Maori de Nouvelle-Zélande",
   "english": "Māori"
  },
  {
   "code": "mk",
   "french": "Macédonien",
   "english": "Macedonian"
  },
  {
   "code": "ml",
   "french": "Malayalam",
   "english": "Malayalam"
  },
  {
   "code": "mn",
   "french": "Mongol",
   "english": "Mongolian"
  },
  {
   "code": "mo",
   "french": "Moldave",
   "english": "Moldavian"
  },
  {
   "code": "mr",
   "french": "Marathi",
   "english": "Marathi"
  },
  {
   "code": "ms",
   "french": "Malais",
   "english": "Malay"
  },
  {
   "code": "mt",
   "french": "Maltais",
   "english": "Maltese"
  },
  {
   "code": "my",
   "french": "Birman",
   "english": "Burmese"
  },
  {
   "code": "na",
   "french": "Nauruan",
   "english": "Nauru"
  },
  {
   "code": "nb",
   "french": "Norvégien Bokmål",
   "english": "Norwegian Bokmål"
  },
  {
   "code": "nd",
   "french": "Sindebele",
   "english": "North Ndebele"
  },
  {
   "code": "ne",
   "french": "Népalais",
   "english": "Nepali"
  },
  {
   "code": "ng",
   "french": "Ndonga",
   "english": "Ndonga"
  },
  {
   "code": "nl",
   "french": "Néerlandais",
   "english": "Dutch"
  },
  {
   "code": "nn",
   "french": "Norvégien Nynorsk",
   "english": "Norwegian Nynorsk"
  },
  {
   "code": "no",
   "french": "Norvégien",
   "english": "Norwegian"
  },
  {
   "code": "nr",
   "french": "Nrebele",
   "english": "South Ndebele"
  },
  {
   "code": "nv",
   "french": "Navajo",
   "english": "Navajo"
  },
  {
   "code": "ny",
   "french": "Chichewa",
   "english": "Chichewa"
  },
  {
   "code": "oc",
   "french": "Occitan",
   "english": "Occitan"
  },
  {
   "code": "oj",
   "french": "Ojibwé",
   "english": "Ojibwa"
  },
  {
   "code": "om",
   "french": "Oromo",
   "english": "Oromo"
  },
  {
   "code": "or",
   "french": "Oriya",
   "english": "Oriya"
  },
  {
   "code": "os",
   "french": "Ossète",
   "english": "Ossetian"
  },
  {
   "code": "pa",
   "french": "Pendjabi",
   "english": "Panjabi"
  },
  {
   "code": "pi",
   "french": "Pali",
   "english": "Pāli"
  },
  {
   "code": "pl",
   "french": "Polonais",
   "english": "Polish"
  },
  {
   "code": "ps",
   "french": "Pachto",
   "english": "Pashto"
  },
  {
   "code": "pt",
   "french": "Portugais",
   "english": "Portuguese"
  },
  {
   "code": "qu",
   "french": "Quechua",
   "english": "Quechua"
  },
  {
   "code": "rc",
   "french": "Créole Réunionnais",
   "english": "Reunionese"
  },
  {
   "code": "rm",
   "french": "Romanche",
   "english": "Romansh"
  },
  {
   "code": "rn",
   "french": "Kirundi",
   "english": "Kirundi"
  },
  {
   "code": "ro",
   "french": "Roumain",
   "english": "Romanian"
  },
  {
   "code": "ru",
   "french": "Russe",
   "english": "Russian"
  },
  {
   "code": "rw",
   "french": "Kinyarwanda",
   "english": "Kinyarwanda"
  },
  {
   "code": "sa",
   "french": "Sanskrit",
   "english": "Sanskrit"
  },
  {
   "code": "sc",
   "french": "Sarde",
   "english": "Sardinian"
  },
  {
   "code": "sd",
   "french": "Sindhi",
   "english": "Sindhi"
  },
  {
   "code": "se",
   "french": "Same du Nord",
   "english": "Northern Sami"
  },
  {
   "code": "sg",
   "french": "Sango",
   "english": "Sango"
  },
  {
   "code": "sh",
   "french": "Serbo-croate",
   "english": "Serbo-Croatian"
  },
  {
   "code": "si",
   "french": "Cingalais",
   "english": "Sinhalese"
  },
  {
   "code": "sk",
   "french": "Slovaque",
   "english": "Slovak"
  },
  {
   "code": "sl",
   "french": "Slovène",
   "english": "Slovenian"
  },
  {
   "code": "sm",
   "french": "Samoan",
   "english": "Samoan"
  },
  {
   "code": "sn",
   "french": "Shona",
   "english": "Shona"
  },
  {
   "code": "so",
   "french": "Somali",
   "english": "Somali"
  },
  {
   "code": "sq",
   "french": "Albanais",
   "english": "Albanian"
  },
  {
   "code": "sr",
   "french": "Serbe",
   "english": "Serbian"
  },
  {
   "code": "ss",
   "french": "Swati",
   "english": "Swati"
  },
  {
   "code": "st",
   "french": "Sotho du Sud",
   "english": "Sotho"
  },
  {
   "code": "su",
   "french": "Soundanais",
   "english": "Sundanese"
  },
  {
   "code": "sv",
   "french": "Suédois",
   "english": "Swedish"
  },
  {
   "code": "sw",
   "french": "Swahili",
   "english": "Swahili"
  },
  {
   "code": "ta",
   "french": "Tamoul",
   "english": "Tamil"
  },
  {
   "code": "te",
   "french": "Télougou",
   "english": "Telugu"
  },
  {
   "code": "tg",
   "french": "Tadjik",
   "english": "Tajik"
  },
  {
   "code": "th",
   "french": "Thaï",
   "english": "Thai"
  },
  {
   "code": "ti",
   "french": "Tigrigna",
   "english": "Tigrinya"
  },
  {
   "code": "tk",
   "french": "Turkmène",
   "english": "Turkmen"
  },
  {
   "code": "tl",
   "french": "Tagalog",
   "english": "Tagalog"
  },
  {
   "code": "tn",
   "french": "Tswana",
   "english": "Tswana"
  },
  {
   "code": "to",
   "french": "Tongien",
   "english": "Tonga"
  },
  {
   "code": "tr",
   "french": "Turc",
   "english": "Turkish"
  },
  {
   "code": "ts",
   "french": "Tsonga",
   "english": "Tsonga"
  },
  {
   "code": "tt",
   "french": "Tatar",
   "english": "Tatar"
  },
  {
   "code": "tw",
   "french": "Twi",
   "english": "Twi"
  },
  {
   "code": "ty",
   "french": "Tahitien",
   "english": "Tahitian"
  },
  {
   "code": "ug",
   "french": "Ouïghour",
   "english": "Uighur"
  },
  {
   "code": "uk",
   "french": "Ukrainien",
   "english": "Ukrainian"
  },
  {
   "code": "ur",
   "french": "Ourdou",
   "english": "Urdu"
  },
  {
   "code": "uz",
   "french": "Ouzbek",
   "english": "Uzbek"
  },
  {
   "code": "ve",
   "french": "Venda",
   "english": "Venda"
  },
  {
   "code": "vi",
   "french": "Vietnamien",
   "english": "Viêt Namese"
  },
  {
   "code": "vo",
   "french": "Volapük",
   "english": "Volapük"
  },
  {
   "code": "wa",
   "french": "Wallon",
   "english": "Walloon"
  },
  {
   "code": "wo",
   "french": "Wolof",
   "english": "Wolof"
  },
  {
   "code": "xh",
   "french": "Xhosa",
   "english": "Xhosa"
  },
  {
   "code": "yi",
   "french": "Yiddish",
   "english": "Yiddish"
  },
  {
   "code": "yo",
   "french": "Yoruba",
   "english": "Yoruba"
  },
  {
   "code": "za",
   "french": "Zhuang",
   "english": "Zhuang"
  },
  {
   "code": "zh",
   "french": "Chinois",
   "english": "Chinese"
  },
  {
   "code": "zu",
   "french": "Zoulou",
   "english": "Zulu"
  }
 ]
export {englishLanguageName,frenchLanguageName,languages}