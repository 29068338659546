import React, {useContext, useState, useEffect} from 'react'
import styled from 'styled-components'
import {NavBarLogoutStyle} from '../NavBar'
import {MyContext} from "../../stores";
import FormTemplate from "./FormTemplate";
import {useHistory, useParams} from 'react-router-dom'
import {Formik, Form} from 'formik'
import {firestore, jobPosts, eventsDocs} from "../../firestore/firestoreService";
import {InputDate, InputText, SwitchBox} from "../FormElements";
import {format} from "date-fns";
import RichTextEditor from "../RichTextEditor";
import {PageLoader} from "../OtherOvers";
import {useAlert, UseAlert} from "react-alert";

const OverBanner = styled.div`
  position: fixed;
  z-index: 1050;
  top: 0px;
  left: 0px;
  width: 100vw;
  right: 0px;
  bottom: 0px;
  background-color: var(--bazookka-white);
  overflow: scroll;
`

const Main = styled.div`
  display: flex;
  flex-direction: row;
`
const Left = styled.div`
  display: block;
  width: 400px;
  background-color: #FAFBFC;
  height: 100vh;
  padding: 10px;
  overflow: scroll;
  border: 1px solid black;
  
`
const Right = styled.div`
  display: block;
  width: calc(100% - 400px);
  background-color: white;
  height: 400px;
  padding-left: 40px;
  
`
const Title = styled.div`
  display: block;
  width: 100vw;
  h1 {
    font-size: 2rem;
    text-align: center;
  }
`

const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  height: 60px;
  width: 100vw;
  background-color: #0e0e0e;
  left: 0px;
`



function NavBar({handleSubmit, click}){
    return (

        <NavBarLogoutStyle className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="" href="https://bulma.io">
                    <img src="/assets/old/logo-bazookka.png" width="285" height="38"  />
                </a>

                <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false"
                   data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbarBasicExample" className="navbar-menu">
                <div className="navbar-start">
                    <a className="navbar-item">
                        Home
                    </a>

                    <a className="navbar-item">
                        Documentation
                    </a>

                    <div className="navbar-item has-dropdown is-hoverable">
                        <a className="navbar-link">
                            More
                        </a>

                        <div className="navbar-dropdown">
                            <a className="navbar-item">
                                About
                            </a>
                            <a className="navbar-item">
                                Jobs
                            </a>
                            <a className="navbar-item">
                                Contact
                            </a>
                            <hr className="navbar-divider" />
                                <a className="navbar-item">
                                    Report an issue
                                </a>
                        </div>
                    </div>
                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            <button className="button is-primary" onClick={handleSubmit} >
                                <strong>Sign up</strong>
                            </button>
                            <a className="button is-light">
                                <i className="mdi mdi-close" style={{color: 'blue !important'}}>X</i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </NavBarLogoutStyle>
    )
}

export default function EditOverlay({}) {
    const {me, globalId, langChoice, content, setContent} = useContext(MyContext)
    const history = useHistory()
    const [edit, setEdit] = useState(true)
    const [apicall, setApicall] = useState(false)
    const [data, setData] = useState(null)
    const alert = useAlert()

    function validation( v ) {
        if(Array.isArray(v)) {
            return v
        }
        return [
            {
                type: 'paragraph',
                children: [
                    {
                        text: v,
                    },
                ],
            },
        ]
    }

    const initialValues = {
        title: 'Test',
        needed: 1,
        tiny: '',
        desc: '',
        active: false,
        startDate: new Date(),
        endDate: new Date(),
        category: []
    }
    const {job, event, page} = useParams()

    const onSubmit = (data) => {
        data.desc = content;
        try{
            data.startDate = new Date(data.startDate).getTime()
        }catch(e){}
        try{
            data.endDate = new Date(data.endDate).getTime()
        }catch(e){}
        if(data.id) {
            if(job) {
                jobPosts.doc(data.id).update(data).then(r => {
                    //setSubmitting(false)
                    setTimeout(() => {
                        history.goBack()
                    }, 500)
                })
            }
            if(event) {
                eventsDocs.doc(data.id).update(data).then(r => {
                    //setSubmitting(false)
                    setTimeout(() => {
                        history.goBack()
                    }, 500)
                })
            }

        }else{
            data.authorId = firestore.FieldValue.arrayUnion(globalId);
            data.author = {
                id: globalId,
                displayName: me.displayName
            }
            data.businessId = firestore.FieldValue.arrayUnion(me.select.id)
            if(page === 'createJob') {
                jobPosts.add(data).then(r => {
                    //setSubmitting(false)
                    // setTimeout(() => {
                    //     history.goBack()
                    // }, 0)
                }).catch(e => {
                    alert.error(e.message)
                })
            }
            if(page === 'createEvent') {
                eventsDocs.add(data).then(r => {
                    //setSubmitting(false)
                    // setTimeout(() => {
                    //     history.goBack()
                    // }, 0)
                }).catch(e => {
                    alert.error(e.message)
                })
            }
        }
    }
    useEffect(()=> {
        if(job) {
            jobPosts.doc(job).get().then(doc => {
                setData({id: doc.id, ...doc.data()})
                setApicall(true)
                setContent(validation(doc.data().desc))
            })
        }

    },[apicall])

    useEffect(()=>{
        if(event) {
            eventsDocs.doc(event).get().then( doc => {
                setData({id: doc.id, ...doc.data()})
                setApicall(true)
                setContent(validation(doc.data().desc))
            })
        }
    }, [apicall])

    useEffect(()=> {
        if(page === 'createEvent'){
            setData(initialValues)
            setApicall(true)
            setEdit(false)
        }

        if(page === 'createJob') {
            setData(initialValues)
            setApicall(true)
            setEdit(false)
        }
    }, [apicall])
    if(!apicall) {
        return <PageLoader isActive={true}/>
    }
    return (
        <Formik initialValues={{...data}} onSubmit={onSubmit}>
            {({values, handleSubmit})=> {
                return (
                    <Form>
                        <OverBanner>
                            <NavBar handleSubmit={handleSubmit}/>
                            <Title>
                                <h1>Name</h1>
                            </Title>
                            <Main>
                                <Left>
                                    <div className="settings-form-wrapper">
                                        <div className="settings-form">
                                            <div style={{width: '95%'}}>
                                                <div className="field">
                                                    <InputText
                                                        label={ langChoice === 'fr'? 'titre': 'title'}
                                                        value={values.title}
                                                        name="title" />
                                                </div>
                                                <div className="field">
                                                    <InputText
                                                        label={ langChoice === 'fr'? 'Petit résumé': 'Little summary'}
                                                        name="tiny" />
                                                </div>
                                                <div className="field">
                                                    <InputDate
                                                        label={langChoice === 'fr'? 'Date de début':'Start date'}
                                                        value={format(new Date(values.startDate), 'dd/MM/yyyy')}
                                                        name="startDate"
                                                    />
                                                </div>
                                                <div className="field">
                                                    <InputDate
                                                        label={langChoice === 'fr'? 'Date de fin':'End date'}
                                                        value={format(new Date(values.endDate), 'dd/MM/yyyy')}
                                                        name="endDate"
                                                    />
                                                </div>
                                                <div className="field">
                                                    <SwitchBox
                                                        label={langChoice === 'fr' ? 'Actif': 'Active'}
                                                        name="active"
                                                    />
                                                </div>

                                                {/*<div className="field" style={{display:'flex', justifyContent: 'space-between'}}>*/}
                                                {/*    <button type="submit" className="button is-primary" disabled={isSubmitting}>{langChoice === 'fr' ? 'sauvegarder': 'save'}</button>*/}
                                                {/*    /!*<button onClick={(e) => returnBack(e, resetForm)} className="button is-danger">{langChoice === 'fr' ? 'ne pas sauvegarder': 'do not save'}</button>*!/*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </Left>
                                <Right>
                                    <RichTextEditor />
                                </Right>
                            </Main>
                            <Footer />
                        </OverBanner>
                    </Form>

                )
            }}

        </Formik>



    )
}
