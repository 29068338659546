import React, {useState, useContext, useEffect} from 'react'
import {MyContext} from "../../stores";
import {usersDoc, schoolsDoc} from '../../firestore/firestoreService'
import {useHistory}  from 'react-router-dom'
import {usePromise} from "../../helper";
import Loader from '../../components/Loader'
import {useTranslation} from "react-i18next";

export const SelectSchools = props => {
    const {me, langChoice, globalId, building, setBuilding} = useContext(MyContext)
    const {t} = useTranslation();
    const [selected, setSelected] = useState(null)
    const history = useHistory()
    const [schools, schoolsError, schoolsLoad] = usePromise(async ()=>{
        let docs = await usersDoc.doc(globalId).collection('schools').get()
        if(docs.empty) {
            return []
        }
        return docs.docs.map(doc => Object.assign({id: doc.id, ...doc.data()}))
    }, [globalId])
    const updateProfile = async school => {
        let sch = await schoolsDoc.doc(school.id).get()
        await usersDoc.doc(globalId).update({select: {id: sch.id, ...sch.data()}})
        await usersDoc.doc(globalId).collection('schools').doc(sch.id).set({id: sch.id, ...sch.data()})
        history.push(`me`)
    }

    const schoolOrBusiness = () => {
        if(!me) {
            return [];
        }
        if(Array.isArray(me.schools)) {
            setBuilding('schools')


        }else {
            setBuilding('businesses')

        }
    }

    const renderSchools = schools => {
        return schools.map((school, i) =>{
            return (
                <div className="column is-12" key={i}>
                    <div className="account-type">
                        <h3>{school.name}</h3>
                        <a className="button is-fullwidth is-rounded process-button" data-step="step-dot-2" onClick={ (evt) => {
                            evt.preventDefault()
                            updateProfile(school)
                        }}>
                            Select
                        </a>
                    </div>
                </div>
            )
        })
    }

    const renderBusinesses = businesses => {
        return businesses.map((business, i) =>{
            return (
                <div className="column is-12" key={i}>
                    <div className="account-type">
                        <h3>{business.name}</h3>
                        <a className="button is-fullwidth is-rounded process-button" data-step="step-dot-2" onClick={ (evt) => {
                            evt.preventDefault()
                            updateProfile(business)
                        }}>
                            Select
                        </a>
                    </div>
                </div>
            )
        })
    }

    const renderSchoolOrBusiness = (schools, me) => {
        if(schools && schools.length > 0){
            return renderSchools(schools);
        }else if(me && me.businesses && me.businesses.length > 0){
            return renderBusinesses(me.businesses);
        }else{
            return <h1>{t('general.noschools')}</h1>
        }
    }

    useEffect(()=> {
        schoolOrBusiness()
    }, [building, selected, me])

    if(schoolsLoad) {
        return <Loader></Loader>
    }

    return (
        <div className="signup-wrapper" style={{paddingTop: 90}}>
            <div className="outer-panel">
                <div className="outer-panel-inner">
                    <div className="process-title">
                        <h2 id="step-title-1" className="step-title is-active"></h2>
                    </div>

                    <div id="signup-panel-1" className="process-panel-wrap is-active">
                        <div className="columns">
                        {renderSchoolOrBusiness(schools, me)}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SelectSchools
