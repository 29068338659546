import {
    Link,
    useParams,
    } from 'react-router-dom'
import React, {useContext, useState, useEffect, useRef} from 'react'
import {MyContext} from "../../stores";
import styled from 'styled-components'
import {Formik, Form} from "formik";
import {InputDate, InputText, SwitchBox, TextAreaInput} from "../../components/FormElements";
import ListEduJob from "./ListEduJob";
import {usersDoc, firebaseInstance, schoolsDoc} from "../../firestore/firestoreService";
import {useTranslation} from "react-i18next";
import {PencilLineIco} from "../../ico";
import Avatar from 'react-avatar';
import { Trans } from "react-i18next";
import AttachmentList from "../../components/AttachmentList";
import FileUpload from '../../components/FileUpload';
import {Multiselect} from 'multiselect-react-dropdown'
import {useCatReducer} from '../../stores/categories'
import ReactSelect from 'react-select'
import {useDeBounce} from "../../helper";
import { useAlert } from 'react-alert'
import { userRoles } from '../../stores/enums/userRoles';


const AvatarEditButton = styled.div`
    position: relative;
    bottom: 30px;
    left: 70px;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3d70b2;
    box-shadow: 0 14px 26px -12px rgb(61 112 178 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(61 112 178 / 20%) !important;
    transform: rotate(0);
    transition: all .3s;
    cursor: pointer;
    z-index: 1;

    svg{
        height: 18px;
        width: 18px;
        stroke: #fcfcfc;
    }
`
const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    background-color: var(--bazookka-white);
    border: 2px solid #e8e8e8;
    margin-bottom: 1rem;

    div {
        margin-top: 5px;
    }

    i {
        font-size: 30px;
    }

    .chip i {
        font-size: 18px;
    }

    button {
        background-color: var(--bazookka-green);
        color: var(--bazookka-white);
        font-size: 1rem;
    }

    .parent__avatar {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`


const JobContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    background-color: var(--bazookka-white);
    border: 0.5px solid #e8e8e8;
    margin-bottom: 1rem;

    i {
        font-size: 30px;
    }

    button {
        background-color: var(--bazookka-yellow);
        color: var(--bazookka-white);
        font-size: 1rem;
    }
`
const MyDocumentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    background-color: var(--bazookka-white);
    border: 0.5px solid #e8e8e8;
    margin-bottom: 1rem;

    i {
        font-size: 30px;
    }

    button {
        background-color: var(--bazookka-blue);
        color: var(--bazookka-white)
        font-size: 1rem;
    }
`

const EducContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    background-color: var(--bazookka-white);
    border: 0.5px solid #e8e8e8;
    margin-bottom: 1rem;

    i {
        font-size: 30px;
    }

    button {
        background-color: var(--bazookka-pink);
        color: var(--bazookka-white);
    }
`

const JobProfile =  ({me, globalId}) => {
    const {dispatchModal} = useContext(MyContext)
    const {t} = useTranslation()
    const onClick = () => {
        dispatchModal({type: 'OPEN_MODAL',
        payload: {
            modalType: 'EducationModal',
            modalProps:
                {

                    title: `${t('general.add')} ${t('settings.wexperi')}`,//'Add work experiences',
                    page: 'experiences',
                    data: null,
                    closeOut: () => dispatchModal({type: 'CLOSE_MODAL'})
                }}});
    }
    return (
        <JobContainer>
            <div className="title-wrap">
                <a className="mobile-sidebar-trigger">
                    <i className="mdi mdi-briefcase-plus"></i>
                </a>
                <h2><Trans i18nKey="settings.wexperi">General Settings</Trans></h2>
            </div>
            <button className="button is-fullwidth" onClick={onClick}>{t('general.addnow')}</button>
            {me?.experiences && <ListEduJob page={`experiences`} data={me.experiences}></ListEduJob>}

        </JobContainer>
    )
}

const MyDocuments =  ({me, globalId}) => {
    const [Documents, setDocuments] = useState([]);
    const [fileDestination, setFileDestination] = useState('')

    useEffect(() => {
        if(globalId){
            setFileDestination(`my_documents/${globalId}/`);

            usersDoc.doc(globalId).collection('documents').orderBy('timestamp', 'desc').onSnapshot(col => {
                setDocuments(col.docs.map(doc => doc.data()));
            });
        }
    }, [globalId]);

    function handleUpload(fileRef, metadata){
        fileRef.getDownloadURL().then((url) => {
            const newAttachment = {
                name: metadata.name,
                path: url,
                type: metadata.contentType,
                timestamp: Date.now()
            }

            usersDoc.doc(globalId).collection('documents').doc().set(newAttachment).catch((error) => {
                console.error("Error writing document: ", error);
            });
        });
    }

    return (
        <MyDocumentsContainer>
            <div className="title-wrap">
                <a className="mobile-sidebar-trigger">
                    <i className="mdi mdi-file-document"></i>
                </a>
                <h2>
                    <Trans i18nKey="settings.myDocuments">
                    My Documents
                    </Trans>
                </h2>
            </div>
            {Documents.length > 0 &&
                <AttachmentList attachments={Documents} metas={['name','date']}/>
            }
            <FileUpload dest={fileDestination} onUpload={handleUpload}>
                <button className="button is-fullwidth"><i className="mdi mdi-plus"></i></button>
            </FileUpload>
        </MyDocumentsContainer>
    )
}

const EducProfile = ({me, globalId}) => {
    const {dispatchModal} = useContext(MyContext)
    const {t} = useTranslation()
    const onClick = () => {
        dispatchModal({type: 'OPEN_MODAL',
        payload: {
            modalType: 'EducationModal',
            modalProps:
                {

                    title: `${t('general.add')} ${t('cat.education')}`,//'Add Education',
                    page: 'educations',
                    closeOut: () => dispatchModal({type: 'CLOSE_MODAL'})}}})

    }

    const onEdit = () => {
        dispatchModal({
            modalType: 'EducationModal',
            modalProps:
                {

                    title: 'Add Education',
                    page: 'educations',
                    closeOut: () => dispatchModal({type: 'CLOSE_MODAL'})}})
    }

    return (
        <EducContainer>
            <div className="title-wrap">
                <a className="mobile-sidebar-trigger">
                    <i className="mdi mdi-school"></i>
                </a>
                <h2><Trans i18nKey="settings.educert">General Settings</Trans></h2>
            </div>
            <button className="button is-fullwidth" onClick={onClick}>{t('general.addnow')}</button>
            {me?.educations && <ListEduJob page={`educations`} data={me.educations}></ListEduJob>}
        </EducContainer>
    )
}

const ProfileForm = ({me, globalId}) => {
    const {currentUser, langChoice} = useContext(MyContext)
    const [categories, dispatchAll] = useCatReducer();
    const [select, setSelect] = useState(null)
    const [options, setOptions] = useState([])
    const [apicall, setApicall] = useState(true)
    const [save, setSave] = useState(null)
    const {t} = useTranslation()
    const addressRef = useRef()
 

    const filterCategoriesByRole = () => {
            return categories && categories.filter(cat => {
                if([userRoles.SCH_ADMIN_SCHOOL,userRoles.CMP_ADMIN_BUSINESS,userRoles.SCH_BUSINESS].includes(me.role) && cat){
                    return !["disploma","skills","contracttypes","field","qualities","supervisontype","teamvalues","workactivities","benefits","mot","leader"].includes(cat[0])
                }else {
                    return !["supervisionTypeCompany","teamvaluesCompany","workActivitiesCompany","benefitsCompany","motCompany","leaderCompany"].includes(cat[0])
                }
            })
    }

    useEffect(() => {
        dispatchAll({
            type: langChoice === 'fr' ? 'ALL_SET_FR' : 'ALL_SET_EN'
        })
        if(apicall) {
            setApicall(!apicall)
        }
    }, [globalId, apicall])
    const onSubmit = async (data, {setSubmitting}) => {
        let googleLL = firebaseInstance().functions().httpsCallable('googleFindLongLat')
        if(save) {
           data.address = save;
           let response = await googleLL({value: save})
           data.location = response.data.results[0].geometry.location
        }
        setSubmitting(true)
        usersDoc.doc(globalId).update(data).then(d => {
            setSubmitting(false)
        })
    }

    const returnCat = (cat, list, values) => {
        return values[cat] && values[cat].map( k => {
            return list.find(l => l.key === k)
        }) || []
    }

    const updateRequest = async (value) => {
        let addressGetter = firebaseInstance().functions().httpsCallable('googleAddress')
        // let response = await axios.get(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${urlEncode(value)}&types=geocode&key=AIzaSyDU-7VwiLZiZwAZALo-0dqQlUAZQKXga1Y`)
        if(value.length > 7) {
            let response = await addressGetter({value})

            setOptions(response.data.response.predictions.map( (d, index) => {
                return {value: d.description, label: d.description}
            }))


        }
    }

    const handleChange = (v) => {
        setSelect(v)
        setSave(addressRef?.current?.state?.value?.label)
    }

    useDeBounce(()=> updateRequest(select), 3000, [select])

    return (
        <ProfileContainer>
            <div className="title-wrap">
                <a className="mobile-sidebar-trigger">
                    <i className="mdi mdi-information"></i>
                </a>
                <h2>{t('general.settings')}</h2>
            </div>
            <Formik initialValues={{...me}} onSubmit={onSubmit} enableReinitialize={true}>
                {({values, isSubmitting}) => {
                    return (
                        <Form>
                            <InputText label={t('listing.name')} name="displayName"/>
                            <InputText label={t('general.emailaddress')} name="email"/>
                            <InputText label={t('settings.phone')} name="tel"/>
                           { ![userRoles.SCH_ADMIN_SCHOOL,userRoles.CMP_ADMIN_BUSINESS,userRoles.SCH_BUSINESS].includes(me.role) && <div>
                            {t('general.location')}:  {values.address}
                            <ReactSelect
                                defaultValue={values.address || ''}
                                options={options}
                                onInputChange={handleChange}
                                ref={addressRef}
                            />
                            </div> }
                            <TextAreaInput label={t('settings.intro')} name="intro"/>
                            {categories && filterCategoriesByRole().map((cat, i)=> {
                                return (
                                    <div className="field" key={i}>
                                    { cat ?
                                        <label>{t(cat[0])}
                                            <div className="control">
                                                <Multiselect
                                                    options={cat[1] ||[]}
                                                    displayValue="value"
                                                    placeholder={t('overlay.select')}
                                                    emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                    id={cat[0]}
                                                    selectedValues={ returnCat(cat[0], cat[1], values)}
                                                    onSelect={(v) => {
                                                        values[cat[0]] = v.map( k => k.key)
                                                    }}
                                                    onRemove={(v) => {
                                                        values[cat[0]] = v.map( k => k.key)
                                                    }}

                                                >

                                                </Multiselect>
                                            </div>
                                        </label>
                                    : ""
                                }
                                    </div>
                                )
                            })}


                            <button type="submit" className="button is-fullwidth">{t('general.submit')}</button>
                        </Form>
                    )
                }}

            </Formik>
        </ProfileContainer>
    )
};

const ProfileResetPassword = ({me, currentUser}) => {
    const {t} = useTranslation()
    const alert = useAlert()
   

    const reauthenticate = (currentPassword) => {
        let user = firebaseInstance().auth().currentUser;
        let cred = firebaseInstance().auth.EmailAuthProvider.credential(
            user.email, currentPassword);
        return user.reauthenticateWithCredential(cred);
    }
    const onSubmit = async (value, {setSubmitting}) => {
        if(value.new !== value.confirm) {
            setSubmitting(true)
            return
        }
        setSubmitting(true)
        if(value.current === value.confirm) {
            try {
                await reauthenticate(value.current)
                await firebaseInstance().auth().currentUser.updatePassword(value.new)
                alert.success(t("general.pwordsuccess"))

            }catch(e) {
                alert.error(t("general.pworderror"))
            }
        }
        setSubmitting(false)
    }
    return (
        <ProfileContainer>
            <div className="title-wrap">
                <a className="mobile-sidebar-trigger">
                    <i className="mdi mdi-lock"></i>
                </a>
                <h2>{t('securitySettings.ButtonLabel')}</h2>
            </div>
            <Formik initialValues={{current: "", confirm:"", new: ""}} onSubmit={onSubmit}>
                {({values, isSubmitting}) => {
                    return (
                        <Form>
                            <InputText label={t('securitySettings.CurrentPassword')} name="current" value={values && values.current} type="password"></InputText>
                            <InputText label={t('securitySettings.RetypePassword')} name="confirm" value={values && values.confirm} type="password"></InputText>
                            <InputText label={t('securitySettings.NewPassword')} name="new" value={values && values.new} type="password"></InputText>
                            <button className="button is-fullwidth" type="submit" disabled={isSubmitting}>{t('contact.submit')}</button>
                        </Form>
                    )
                }}
            </Formik>
        </ProfileContainer>
    )
}

export const ProfileBusinessForm = () => {
    const {currentUser, me, globalId, dispatchModal, langChoice} = useContext(MyContext)
    const [school, setSchool] = useState([])
    const [apicall, setApicall] = useState(true)
    const [select, setSelect] = useState([])
    const [categories, dispatch] = useCatReducer([])
    const {t} = useTranslation()
    useEffect(()=> {
        dispatch({type: langChoice === 'fr' ? 'SET_FR': 'SET_EN', payload: 'field.'})
        if(apicall) {
            try {
                schoolsDoc.doc(me.select.id).onSnapshot(async doc => {
                    setSchool({...doc.data()})

                    setApicall(false)
                    await usersDoc.doc(globalId).update({select: {id: doc.id, ...doc.data()}})
                })
            }catch(e) {}
        }

    }, [globalId, apicall])
    const onSubmit = (data, {setSubmitting}) => {
        setSubmitting(true)
        schoolsDoc.doc(me.select.id).update(data).then( d => {
            setSubmitting(false)
        })
    }

    const editCover = (e) => {
        e.preventDefault();
        dispatchModal({type: 'OPEN_MODAL', payload: {modalType:'SchoolCoverCropUploadModal', modalProps: {title: t('modal.title.editSchoolCover'), ico: <PencilLineIco />, closeOut: () => dispatchModal({type: 'CLOSE_MODAL'})}}})
    }

    const editAvatar = (e) => {
        e.preventDefault();
        dispatchModal({type: 'OPEN_MODAL', payload: {modalType:'SchoolAvatarCropUploadModal', modalProps: {title: t('modal.title.editSchoolAvatar'), ico: <PencilLineIco />, closeOut: () => dispatchModal({type: 'CLOSE_MODAL'})}}})
    }

    return (
        <ProfileContainer>
            <div className="parent__avatar">
                <h2 className="mt-6 mb-1"><Trans i18nKey="school.images">Upload school images</Trans></h2>
                <div className="avatar">
                    <Avatar src={`${school && school.avatar}`} name={`${school && school.name}`} round={true} style={{zIndex: 1}}/>
                    <AvatarEditButton onClick={editAvatar}>
                        <PencilLineIco />
                        <i data-feather="plus"></i>
                    </AvatarEditButton>
                </div>
            </div>
            <h2 className="mt-6 mb-1"><Trans i18nKey="school.images">Upload school images</Trans></h2>
            <div className="cover-bg">
                <img className="cover-image" src={(school && school.cover) ? school.cover : 'assets/old/wallpaper.png'}
                     data-demo-src="assets/img/demo/bg/4.png" alt="" />
                <div className="cover-overlay mt-0"></div>
                <a className="cover-edit modal-trigger" data-modal="change-cover-modal" onClick={editCover}>
                    <i className="mdi mdi-camera"></i>
                    <span>{t('general.editcoverimage')}</span>
                </a>
            </div>
            <Formik initialValues={{name : "", address : "",tel: "",intro: "",...school}} onSubmit={onSubmit} enableReinitialize={true}>
                
                {({values, isSubmitting }) => {
                    return (
                        <React.Fragment>
                            <Form>
                                <InputText label={t('listing.name')} name="name"></InputText>
                                <InputText label={t('general.fulladdress')} name="address"></InputText>
                                <InputText label={t('settings.phone')} name="tel" />
                                <TextAreaInput label={t('settings.intro')} name="intro"/>
                                <div className="field">
                                    <label>Secteur</label>
                                    <div className="control">
                                        <Multiselect
                                            options={categories}
                                            placeholder={t('overlay.select')}
                                            selectedValues={values.field || []}
                                            emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                            onSelect={(v)=> {
                                                values.field = v;
                                            }}
                                            onRemove={(v)=> {
                                                values.field = v;
                                            }}
                                            displayValue="value"
                                        />
                                    </div>
                                </div>
                                <button type="submit" className="button is-fullwidth" disabled={isSubmitting}>{t('general.submit')}</button>
                            </Form>
                        </React.Fragment>
                    )
                }}

            </Formik>
        </ProfileContainer>
    )
}


const DeskTopMenu = props => {
    return (
        <div className="profile-menu is-hidden-mobile"  style={{height: 70}}>
            <div className="menu-start" style={{display: 'none'}}>
                <a >Timeline</a>
                <a >About</a>
            </div>
            <div className="menu-end" style={{display: 'none'}}>
                <a id="profile-students-link" >students</a>
                <a >Photos</a>
            </div>
        </div>
    )
}

const SubProfile = props => {
    const context = useContext(MyContext)
    return (
        <div className="profile-subheader">
            <div className="subheader-start is-hidden-mobile">

            </div>
            <div className="subheader-middle">
                <h2>{context.me && context.me.firstname} {context.me && context.me.lastname}</h2>
                <span>{context.me && context.me.select.name}</span>
            </div>
            <div className="subheader-end is-hidden-mobile">
                <a className="button has-icon is-bold">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                         className="feather feather-clock">
                        <circle cx="12" cy="12" r="10"></circle>
                        <polyline points="12 6 12 12 16 14"></polyline>
                    </svg>
                    History
                </a>
            </div>
        </div>
    )
}

const InternalLeftMenu = ({update, homeNav}) => {
    const {langChoice} = useContext(MyContext)
    return (
        <div className="left-menu" style={{display: 'none'}}>
            <div className="left-menu-inner">
                <div className={`menu-item ${homeNav['feeds']? 'is-active':''}`} data-content="feeds-content" >
                    <Link to={`/${langChoice}/mon-profile`}>
                        <div className="menu-icon">
                            <i className="mdi mdi-message-bulleted"></i>
                            <span>Global message</span>
                        </div>
                    </Link>
                </div>
                <div className={`menu-item ${homeNav['calendar']? 'is-active':''}`} data-content="personal-content" >
                    <Link to={`/${langChoice}/mon-profile/calendar`}>
                        <div className="menu-icon">
                            <i className="mdi mdi-calendar"></i>
                            <span>Calendar</span>
                        </div>
                    </Link>

                </div>
                <div className={`menu-item ${homeNav['notifications']? 'is-active':''}`} data-content="personal-content">
                    <Link to={`/${langChoice}/mon-profile/notifications`}>
                        <div className="menu-icon">
                            <i className="mdi mdi-bell"></i>
                            <span>Notifications</span>
                        </div>
                    </Link>

                </div>
                <div className={`menu-item ${homeNav['educations']? 'is-active':''}`} onClick={()=> update('educations', 'homeNav','setHomeNav')}>
                    <div className="menu-icon">
                        <i className="mdi mdi-school"></i>
                        <span>Education</span>
                    </div>
                </div>
                <div className={`menu-item ${homeNav['jobs']? 'is-active':''}`} data-content="job-content" onClick={()=> update('jobs', 'homeNav','setHomeNav')}>
                    <div className="menu-icon">
                        <i className="mdi mdi-briefcase-plus"></i>
                        <span>Jobs</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const NavigationProfile = props => {
    const {homeNav, updateHomeNav, me} = useContext(MyContext)
    return (
        <div className="column">
            <div className="profile-about side-menu">
                <InternalLeftMenu update={updateHomeNav} homeNav={homeNav}/>
                <div className="right-content">

                </div>
            </div>
        </div>
    )
}

export const PageInternship = props => {
    return (
        <div className="view-wrapper">
            <div className="container is-custom">
                <div id="profile-main" className="view-wrap is-headless">
                    <div className="columns is-multiline no-margin">
                        <div className="column is-paddingless">
                            <DeskTopMenu />
                            <SubProfile />
                            <NavigationProfile />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



const UserProfileSettings = props => {
    const {homeNav, updateHomeNav, me, globalId, currentUser} = useContext(MyContext)
    const {page} = useParams()
    const declarePage = page => {
        return <div>shit</div>
    }
    return (
            <div>
                {me && <ProfileForm me={me} globalId={globalId}></ProfileForm>}
                {me && <ProfileResetPassword me={me} currentUser={currentUser} globalId={globalId}></ProfileResetPassword>}
                {me &&  me.role !== "sch.admin_school" && <EducProfile me={me}></EducProfile>}
                {me && me.role !== "sch.admin_school"  && <JobProfile me={me}></JobProfile>}
                {me && me.role !== "sch.admin_school"  && <MyDocuments me={me} globalId={globalId}></MyDocuments>}
            </div>

    )
}

export const BusinessProfileSetting = props => {
    const {business} = props
    const {homeNav, updateHomeNav, me, globalId} = useContext(MyContext)
    const {page} = useParams()
    const declarePage = page => {
        return <div>shit</div>
    }
    return (
        <div>
            {(me || business) && <ProfileBusinessForm me={me} globalId={globalId}></ProfileBusinessForm>}
        </div>

    )
}

export default UserProfileSettings
