import { Button, DialogActions, Grid, Icon, List, ListItem, Tooltip } from "@mui/material"
import Avatar from '@mui/material/Avatar';
import {useTranslation} from "react-i18next";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { Box } from "@mui/system";


const EventParticipants = ({participants}) => {
    const {t} = useTranslation()
    const [showAll,setShowAll] = useState(false)

    const handleClickAway = () => {
        setShowAll(false);
      };

    const printParticipant = (count=participants.length) => {
        return participants.slice(0,count).map((av, key) => {
            return <Grid key={key} item sx={{marginRight : "5px"}} >
             <Tooltip title={av.displayName}>
                 <Avatar src={av.avatar}/>
             </Tooltip>
             </Grid>
          })
    }
 return (
     <div>
           <h2>{t('event.attendees')}</h2>
         <Grid container>
        {printParticipant(20)}
         <Grid item>
            {participants.length > 20 && <Avatar onClick={() => setShowAll(true)}><Icon baseClassName="fas" className="fa-plus-circle" fontSize="small" /></Avatar>}
         </Grid>
         </Grid>
         {showAll && 
            <ClickAwayListener onClickAway={handleClickAway}>
                <AllParticipantDialog showAll={showAll} setShowAll={setShowAll} printParticipant={printParticipant}/>
            </ClickAwayListener>
         }
     </div>
 )
}

const AllParticipantDialog = ({showAll,setShowAll,printParticipant}) => {
    const {t} = useTranslation()

    return (<Dialog sx={{ textAlign:"center", maxWidth : "500px"}} open={showAll}>
        <DialogTitle>{t('event.attendees')}</DialogTitle>
        <Grid sx={{justifyContent:"center"}} container>
            {printParticipant()}
        </Grid>

        <DialogActions>
          <Button onClick={() => setShowAll(false)}>Close</Button>
        </DialogActions>
    </Dialog>
    )
}

export default EventParticipants