import React, {useContext} from 'react'
import {MyContext} from "../../stores";
import {useTranslation} from "react-i18next";
import {firebaseInstance} from "../../firestore/firestoreService";

export default function SubProfile({me}) {
    const context = useContext(MyContext)
    const {t} = useTranslation()

    const lolClick = () => {
        const syncZohoData = firebaseInstance().functions().httpsCallable('syncZohoData')
        syncZohoData()
            .then( val => {
                console.log("success");
            }).catch( error => {
                console.log("ERROR", error);
            })
    }

    return (
        <div className="profile-subheader">
            <div className="subheader-start is-hidden-mobile">

            </div>
            <div className="subheader-middle">
                <h2>{me?.displayName}</h2>
                <span>{me && me?.select?.name}</span>
            </div>
            <div className="subheader-end is-hidden-mobile" style={{visibility: 'hidden'}}>
                <a className="button has-icon is-bold" onClick={lolClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                         className="feather feather-clock">
                        <circle cx="12" cy="12" r="10"></circle>
                        <polyline points="12 6 12 12 16 14"></polyline>
                    </svg>
                    {t('general.history')}
                </a>
            </div>
        </div>
    )
}
