import React from "react";
import styled from 'styled-components';

const ChatInputField = styled.textarea`
    border-color: #dbdbdb;
    border-radius: 20px;
    color: #363636;
    resize: none;
    height:38px;
    min-height: 38px;
    line-height: 1.5;
    vertical-align:middle;
    padding:9px;
    padding-left:15px;
    width:100%;
    overflow:hidden;

    &:focus{
        outline:none;
        border-color:var(--bazookka-blue);
    }
`

const ChatInput = ({onSubmit, placeholder = '', handleKeyUp = null, className = ''}) => {

    const textAreaAdjust = (e) => {
        e.target.style.height = "1px";
        e.target.style.height = (e.target.scrollHeight)+"px";
        if(handleKeyUp){
            handleKeyUp(e.target.value);
        }
    }

    const newLineOrSubmit = (e) => {
        const keyCode = e.which || e.keyCode;
    
        // 13 represents the Enter key
        if (keyCode === 13 && !e.shiftKey) {
            // Don't generate a new line
            e.preventDefault();
            onSubmit(e.target.value);
            e.target.value = null;
        }
    }

    return (
        <ChatInputField className={className} onKeyUp={(e) => textAreaAdjust(e)} onKeyDown={(e) => newLineOrSubmit(e)} placeholder={placeholder}></ChatInputField>
    )
}

export default ChatInput;
