import { render } from 'react-dom';
import * as React from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Tooltip } from "@mui/material"
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Resize, Page, ContextMenu, Toolbar, Sort, Filter, Search, ExcelExport } from "@syncfusion/ej2-react-grids"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import useSWR from "swr"
import Loader from "../components/Loader"
import { useContext, useEffect, useRef } from "react"
import { MyContext } from "../stores";
import axios from "axios"
import { L10n } from '@syncfusion/ej2-base'
import { UserAction } from "../stores/enums/UserAction";
import  PostTypes from "../stores/enums/PostTypes";
import { isAdmin } from '../helper/acl';
import {fr} from "date-fns/locale"
import { format } from 'date-fns';
export default function Userlogs() {
    const { langChoice, globalIdSql, setI10nLoadContent, me } = useContext(MyContext)
    const {t} = useTranslation()
    const logGridRef = useRef()
    const [admin ,setAdmin] = React.useState()
    let {data, error} = useSWR(!admin ? `https://api.bazookka.com/users/u/${globalIdSql}/logs` : `https://api.bazookka.com/users/admin/${globalIdSql}/logs`,
    url => axios.get(url).then(res => res.data))
    useEffect(()=> {
      try {
          isAdmin(me.select.id).then(v => setAdmin(v))
      }catch(e) {}
  }, [admin])

        const filterSettings = { type: 'CheckBox' };
        if (!data) return <Loader></Loader>


        const getTypeMessage = (actionType,t) => {
            let message = ""
            switch(actionType){
              case UserAction.OFFER_APPLICATION :  
                message = t("logs.type.offerApplication") 
               break
              case UserAction.OFFER_CANCEL :
                message = t("logs.type.offerCancel")
                break
                case UserAction.CONVERSATION_REPLY :
                  message = t("logs.type.conversationReply")
                  break
                case UserAction.ACTIVE_JOB_ADDED :
                  message = t("logs.type.activeJobAdded")
                  break
                  case UserAction.EVENT_REGISTRATION :
                    message = t("logs.type.eventRegistration")
                    break
                    case UserAction.EVENT_REGISTRATION_CANCEL :
                    message = t("logs.type.eventRegistrationCancel")
                    break
                    case UserAction.SUBMIT_FORM : 
                    message = t("logs.type.submitForm")
                    break
                    case UserAction.VIEW_FORM : 
                    message = t("logs.type.viewForm")
                    break
            }
            return message
          }

          const getPostTypeLabel = (postType,t) => {
            let label = ""
            switch(postType){
              case PostTypes.POST :  
               label = t("postType.post") 
               break
              case PostTypes.FILE :
                label = t("postType.file")
                break
                case UserAction.EVENT :
                  label = t("postType.event")
                  break
                  case PostTypes.ACTIVE_JOB :
                    label = t("postType.job")
                    break
                    case PostTypes.JOB :
                      label = t("postType.job")
                      break
                    case PostTypes.EVENT :
                      label = t("postType.event")
                      break
                      case PostTypes.QUIZ :
                      label = t("postType.quiz")
                      break
                      default :
                      label =  t("postType.unknown")
                      break

            }
            return label
          }

        data = data.map(d =>  { return {...d,createdAt: format(new Date(d.createdAt),"MMM d yyyy H:mm",{locale: langChoice === 'fr' && fr }), post_type: getPostTypeLabel(d.post_type,t), type : getTypeMessage(d.type,t)}} );

    L10n.load({
        'fr-FR': {
            'grid': {
                'EmptyDataSourceError': 'Erreur de chargement des donnée',
                'EmptyRecord': `Aucune action á charger`,
                'Item': 'élément',
                'Items': 'éléments',
                'name': 'nom',
                'Search': "Chercher",
                "Print" : "Imprimer",
                "Excelexport" : "Exporter en excel"
            },
            'pager': {
                'currentPageInfo': 'Page {0} de {1}',
                'totalItemsInfo': '({0} actions)',
                'totalItemInfo': '({0} action)'
            }
        },
        'en-US': {
            'grid': {
                'EmptyDataSourceError': 'Error loading data',
                'EmptyRecord': `There's no log to load`,
                'Item': 'element',
                'Items': 'elements',
                'name': 'nom',
                'Search': "Search"
            },
            'pager': {
                'currentPageInfo': 'Page {0} of {1}',
                'totalItemsInfo': '({0} logs)',
                'totalItemInfo': '({0} log)'
            }
        }
    });

      const toolbarClick = (args) => {
        if (logGridRef.current && args.item.id === 'grid_excelexport_excelexport') {
          logGridRef.current.excelExport();
        }
    };
    
        return (<div className='control-pane'>
                <div className='control-section row'>
                    <GridComponent dataSource={data} 
                    id = "grid_excelexport"
                    toolbarClick={toolbarClick}
                       locale={"fr-FR"}
                       allowExcelExport={true}
                      allowPaging={true}  
                      pageSettings={{ pageSize: 25 }} 
                       allowResizing={true}
                       toolbar={["Print","ExcelExport"]}
                       ref={logGridRef}
                       allowSorting={true} 
                       sortSettings={ {
                        columns: [{ field: 'createdAt', direction: 'Descending' }]}} 
                        allowFiltering={true} filterSettings={filterSettings}>
                        <ColumnsDirective>
                         { admin && <ColumnDirective field='display_name' headerText={t('log.groupsLogDataTable.nameColumn')}  format='N2'/> }
                            <ColumnDirective field='type' headerText={t('log.groupsLogDataTable.typeColumn')}></ColumnDirective>
                            <ColumnDirective field='title' headerText={t('log.targetColumn')} ></ColumnDirective>
                            <ColumnDirective field='post_type' headerText={t('log.targetTypeColumn')} ></ColumnDirective>
                            <ColumnDirective field='createdAt' headerText={t('Date')} ></ColumnDirective>
                        </ColumnsDirective>
                        <Inject services={[Resize, Sort, Page, Filter, Search, Toolbar, ContextMenu,ExcelExport]}/>
                    </GridComponent>
                </div>

            </div>);

}