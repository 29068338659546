import React, { useState, useContext, useEffect } from "react";
import { groupsDoc, postsDoc } from "../firestore/firestoreService";
import { MyContext } from "../stores";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import EditEventForm from '../forms/EditEventForm';
import { usePromise } from "../helper";
import Loader from "../components/Loader";
import { Visibility as EyeIcon, Print as PrintIcon } from "@mui/icons-material";
import Box from '@mui/material/Box';
import {useHistory} from "react-router-dom";

const allGroup = {
    groupChat: "false",
    id: "all",
    name: "public",
}

const StyleEventCrud = styled.div`
  margin: 1rem 0px;
  align-items: center;
  transition: all .3s;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  background: white;
  
  .template {
    width: 100%;
    display: grid;
    grid-template-columns: 50px auto 160px;
    align-items: center;
  }
  .flex-block-meta {
    margin-left: 10px;
  }
  .div-button {
    background-color: var(--bazookka-green);
    cursor: pointer;
    width: 42px;
    padding: 0px 5px !important;
    margin: 0px 5px !important;
    float: left;
    height: 42px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.25));
  }
  .titletext {
    display: flex;
    padding-left: 1rem;
    align-items: center;
  }
  .go-button {
    cursor: pointer;
    background-color: var(--bazookka-blue);
    width: 42px;
    padding: 0px 5px !important;
    margin: 0px 5px !important;
    float: left;
    height: 42px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    opacity: 0;
    box-shadow: 0 14px 26px -12px rgb(85 150 230 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(85 150 230 / 20%) !important;

  }
  :hover .go-button {
    opacity: 1;
    transform: rotate(360deg);
    transition: all .3s;
  }`

export const LineBreak = styled.div`
    width:100%;
    clear:both;
  `

export const Heading = styled.div`
    flex-wrap: wrap;
    transition: all 0.35s ease-out;
`

export const FormContainer = styled.div`
    width:100%;
    margin-top:30px;
    padding:5px;
`

const EventTitle = styled.div`
padding-left:5px;
`


export default function EventCrud() {
    const { t } = useTranslation()
    const [apicall, setApicall] = useState(false)
    const [creating, setCreating] = useState(false)
    const [events, setEvents] = useState([])
    const { me, globalId } = useContext(MyContext)
    const [groups, groupError, groupLoad] = usePromise(async () => {
        let doc = await groupsDoc.where('admin', 'array-contains-any', [globalId]).get()
        return doc.docs.map(d => Object.assign({ id: d.id, ...d.data() }))
    }, [globalId])

    useEffect(() => {
        if (me && !apicall) {
            postsDoc.where('businessId', 'array-contains-any', [me.select.id])
                .where('type', '==', 'event')
                .onSnapshot(snap => {
                    setEvents(snap.docs.map(doc => {
                        return { id: doc.id, ...doc.data() }
                    }))
                    setApicall(true)
                })
        }
    }, [apicall, me])

    if (groupLoad) {
        return <Loader></Loader>
    }

    return (
        <div className="posts-wrapper">
            <Heading id="profile-timeline-posts" className="box-heading">
                <h4>{t('general.events')}</h4>
                {creating ?
                    <div className="button-wrap">
                        <a className="button is-active"
                            onClick={(e) => {
                                e.preventDefault()
                                setCreating(!creating);
                            }}
                        >{t('general.cancel')}</a>
                    </div> :
                    <div className="button-wrap">
                        <a className="button is-active"
                            onClick={(e) => {
                                e.preventDefault()
                                setCreating(!creating);
                            }}
                        >{t('general.eventcreate')}</a>
                    </div>
                }
                <LineBreak />
                {creating &&
                    <FormContainer>
                        <EditEventForm groups={[allGroup, ...groups]} isNew={true}/>
                    </FormContainer>
                }
            </Heading>
            {events.map((event, key) => {
                return <EventCrudCard key={key} event={event} groups={[allGroup, ...groups]} />
            })}
        </div>
    )
}

const EventCrudCard = ({ event, groups }) => {
    const { dispatchModal } = useContext(MyContext)
    const [editing, setEditing] = useState(false)
    const history = useHistory()
    return (
        <StyleEventCrud>
            <div className="template">
                <div>
                    <div className="div-button">
                        <i className="mdi mdi-calendar-check"></i>
                    </div>

                </div>

                <EventTitle>{event.title}</EventTitle>
                <Box sx={{ display: 'flex' }}>
                    {editing ?
                        <div className="go-button float-left" onClick={(e) => {
                            setEditing(false)
                        }}>
                            <i style={{fontSize : "20px"}} className="mdi mdi-close"></i>
                        </div> :
                        <div className="go-button float-left" onClick={(e) => {
                            e.preventDefault()
                            setEditing(!editing);
                        }}>
                            <i style={{fontSize : "20px"}} className="mdi mdi-pencil"></i>
                        </div>
                    }

                    {/*<div className="go-button float-left">
                        <i style={{fontSize : "20px"}} className="mdi mdi-delete"></i>
                    </div>*/}

                    <div className="go-button float-left" onClick={() =>  history.push(`/events/${event.id}`)}>
                        <EyeIcon sx={{fontSize : "20px"}} />
                    </div>

                </Box>
            </div>
            {editing &&
                <FormContainer>
                    <EditEventForm event={event} groups={groups} />
                </FormContainer>
            }
        </StyleEventCrud>
    )
}
