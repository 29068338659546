import React, {useEffect, useContext} from 'react'
import {MyContext} from "../stores";

const EventTile = props => {
    return (
        <a >
                            <span className="date-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                     strokeLinejoin="round" className="feather feather-calendar"><rect x="3" y="4"
                                                                                                        width="18"
                                                                                                        height="18"
                                                                                                        rx="2"
                                                                                                        ry="2"></rect><line
                                    x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line
                                    x1="3" y1="10" x2="21" y2="10"></line></svg>
                                <span className="month">July 5th</span>
                            </span>
            <span className="meta-block">
                                <span className="time">at 08:00 pm</span>
                            </span>
        </a>
    )
}

const EventDesc = props => {
    return (
        <div id="event-1" className="event-item">
            <div className="event-inner-wrap">

                <div className="dropdown is-spaced event-options is-accent is-right dropdown-trigger">
                    <div>
                        <div className="button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                 strokeLinecap="round" strokeLinejoin="round"
                                 className="feather feather-settings">
                                <circle cx="12" cy="12" r="3"></circle>
                                <path
                                    d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="dropdown-menu" role="menu">
                        <div className="dropdown-content">
                            <a >
                                <div className="media">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                         className="feather feather-calendar">
                                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="16" y1="2" x2="16" y2="6"></line>
                                        <line x1="8" y1="2" x2="8" y2="6"></line>
                                        <line x1="3" y1="10" x2="21" y2="10"></line>
                                    </svg>
                                    <div className="media-content">
                                        <h3>View Event</h3>
                                        <small>Open event details.</small>
                                    </div>
                                </div>
                            </a>
                            <a className="dropdown-item">
                                <div className="media">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                         className="feather feather-smile">
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                        <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                        <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                    </svg>
                                    <div className="media-content">
                                        <h3>Owner</h3>
                                        <small>View owner details.</small>
                                    </div>
                                </div>
                            </a>
                            <hr className="dropdown-divider" />
                                <a >
                                    <div className="media">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                             strokeWidth="2" strokeLinecap="round"
                                             strokeLinejoin="round" className="feather feather-delete">
                                            <path
                                                d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path>
                                            <line x1="18" y1="9" x2="12" y2="15"></line>
                                            <line x1="12" y1="9" x2="18" y2="15"></line>
                                        </svg>
                                        <div className="media-content">
                                            <h3>Leave</h3>
                                            <small>Leave this event.</small>
                                        </div>
                                    </div>
                                </a>
                                <hr className="dropdown-divider" />
                                <a >
                                    <div className="media">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                             height="24" viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-flag">
                                            <path
                                                d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
                                            <line x1="4" y1="22" x2="4" y2="15"></line>
                                        </svg>
                                        <div className="media-content">
                                            <h3>Report</h3>
                                            <small>unappropriate content.</small>
                                        </div>
                                    </div>
                                </a>
                        </div>
                    </div>
                </div>
                <h2 className="event-title">Golang expo</h2>
                <div className="event-subtitle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                         strokeLinejoin="round" className="feather feather-map-pin">
                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                        <circle cx="12" cy="10" r="3"></circle>
                    </svg>
                    <h3>Plaza place | July 5, 2020.</h3>
                </div>
                <div className="event-content">
                    <div className="event-description content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Restatis igitur vos;
                            Putabam equidem
                            satis, inquit, me
                            dixisse. Sed ea mala virtuti magnitudine obruebantur. Tenent mordicus. Duo
                            Reges:
                            constructio interrete. </p>

                        <p>Tollitur beneficium, tollitur gratia, quae sunt vincla concordiae. Falli
                            igitur possumus.
                            Bonum patria: miserum
                            exilium. Sullae consulatum? Eiuro, inquit adridens, iniquum, hac quidem de
                            re; Tu vero,
                            inquam, ducas licet, si
                            sequetur; </p>

                        <p>Quorum altera prosunt, nocent altera. Quis hoc dicit? Hos contra singulos
                            dici est melius.
                            Quid iudicant sensus? </p>

                    </div>
                </div>
                <div className="event-participants">
                    <div className="participants-group">
                        <img src="https://via.placeholder.com/300x300"
                             data-demo-src="assets/img/avatars/jenna.png" data-user-popover="0" alt="" />
                            <img src="https://via.placeholder.com/300x300"
                                 data-demo-src="assets/img/avatars/david.jpg" data-user-popover="4"
                                 alt="" />
                                <img src="https://via.placeholder.com/300x300"
                                     data-demo-src="assets/img/avatars/edward.jpeg"
                                     data-user-popover="5" alt="" />
                                    <img src="https://via.placeholder.com/300x300"
                                         data-demo-src="assets/img/avatars/milly.jpg"
                                         data-user-popover="7" alt="" data-target="webuiPopover9" />
                    </div>
                    <div className="participants-text">
                        <p>
                            <a >Me</a>,
                            <a >Greg</a>
                        </p>
                        <p>and 23 more are participating</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Schedules = props => {
    const provider = useContext(MyContext)
    useEffect(()=> {
        provider.setFooter(false);
    }, [])
    return (
        <div className="view-wrapper">
            <div id="events-page" className="events-wrapper">
                <div className="left-panel">
                    <div className="left-panel-inner has-slimscroll">
                        <EventTile />
                        <div className="add-event">
                            <button className="button">New Meeting</button>
                        </div>
                    </div>
                </div>

                <div className="wrapper-inner">
                    <div id="event-list" className="event-list">
                        <EventDesc />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Schedules
