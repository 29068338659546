import React, { useContext, useEffect, useState } from 'react'
import { MyContext } from "../stores";
import { Field, Form, Formik } from "formik";
import { activeForms, groupsDoc, firebaseInstance, firestore, usersDoc } from "../firestore/firestoreService";
import { ToggleSharingOptions } from "../components/FormManager/ToggleElements";
import { CustomSelect } from "../components/FormElements";
import ModalWrapper from "./index";
import styled from 'styled-components'
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Grid, Tooltip, tooltipClasses } from '@mui/material';
import ReactTooltip from 'react-tooltip';

const OverLayStyle = styled.div`
  height: max-content;
  min-height: 400px;
`

export default function FormInviteModal({ template, closeOut, formUsers, options, groups }) {
    const { me, globalId } = useContext(MyContext);
    const [sharedWithUsersId, setSharedWithUsersId] = useState([])
    const [sharedWithUsers, setSharedWithUsers] = useState([])
    const [notSharedWithUsers,setNotSharedWithUsers] = useState([])
    const [toggle, setToggle] = useState("user");
    const [api, setApi] = useState(true)
    const { t } = useTranslation()
    const alert = useAlert()


    useEffect(() => {
        let filteredOptions = options.filter(option => !sharedWithUsersId.includes(option.id))
        setNotSharedWithUsers(filteredOptions)
    },[sharedWithUsers, sharedWithUsersId])

    useEffect(() => {
       
        const users = []
        sharedWithUsersId.map(async (userId, index) => {

            const user = await usersDoc.doc(userId).get()
            users.push(user.data())
            if (index === sharedWithUsersId.length - 1) {
                setSharedWithUsers(users)
            }

        })
    },[sharedWithUsersId])



    useEffect(() => {

        activeForms.doc(`${globalId}-${template.id}`).onSnapshot((snap) => {
            setSharedWithUsersId(snap.data().authorizedUsers)
        })
       
    }, [globalId, template,template.id ])

    return (
        <ModalWrapper>
            <OverLayStyle>
                <Formik
                    initialValues={{ user: "" }}
                    onSubmit={async (values, { setSubmitting }) => {
                        let af = await activeForms.doc(`${globalId}-${template.id}`).get()
                        let tempUser = values.user || []
                        let tempGroup = values.group || []
                        if (af.exists) {
                            await activeForms.doc(`${globalId}-${template.id}`).update({
                                formContent: template,
                                authorizedUsers: firestore.FieldValue.arrayUnion(...tempUser),
                                groupId: firestore.FieldValue.arrayUnion(...tempGroup)
                            })
                            alert.success(t("customForm.formShared"))
                            closeOut()
                        } else {
                            let activeForm = {
                                formContent: template,
                                authorizedUsers: firestore.FieldValue.arrayUnion(...tempUser),
                                groupId: firestore.FieldValue.arrayUnion(...tempGroup),
                                formOwnerId: globalId,
                            };
                            activeForm.authorizedUsers = values.user
                            await activeForms
                                .doc(`${globalId}-${template.id}`)
                                .set(activeForm)
                            alert.success(t("customForm.formShared"))
                            closeOut()
                        }

                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="field">
                                <ToggleSharingOptions
                                    toggle={toggle}
                                    setToggle={setToggle}
                                    translator={t}
                                ></ToggleSharingOptions>
                            </div>
                            <div className="field">
                                {toggle === "user" ? (
                                    <Field
                                        name="user"
                                        options={notSharedWithUsers}
                                        component={CustomSelect}
                                        placeholder={t("general.documents.usersLabel")}
                                        isMulti={true}
                                    />
                                ) : (
                                    <Field
                                        name="group"
                                        options={groups}
                                        component={CustomSelect}
                                        placeholder={t("general.documents.groupsLabel")}
                                        isMulti={true}
                                    />
                                )}
                                {/* TODO FIGURE OUT HOW TO GET THE VALUE OF THIS SELECT*/}
                            </div>
                                
                            <Grid container sx={{ alignItems:"center", justifyContent:"center"}}>
                                
                           
                                {
                                 
                                toggle === "user" && sharedWithUsers && sharedWithUsers.length > 0 && sharedWithUsers.map((user, key) => {
                                    return user && user.select && <Grid key={key} item sx={{margin : "0 10px 10px 0"}} >
                                            <Avatar data-tip={user.displayName} src={user.select.cover} />
                                            <ReactTooltip />
                                    </Grid>
                                })}
                            </Grid>

                            <div className="field">
                                <button
                                    type="submit"
                                    className="button is-solid primary-button is-fullwidth"
                                    disabled={isSubmitting}
                                >
                                    {t('general.send')}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </OverLayStyle>
        </ModalWrapper>
    );
};
