import React, {useState, useEffect, useContext} from 'react'
import ModalWrapper from "./index";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import {groupsDoc, usersDoc, firebaseInstance} from "../firestore/firestoreService";
import {MyContext} from "../stores";
import {InputText} from "../components/FormElements";
import {Multiselect} from "multiselect-react-dropdown"
import { useAlert } from 'react-alert';

export default function EditGroupModal({data, edit}) {
    const {t} = useTranslation()
    const {me, globalId, langChoice, dispatchModal} = useContext(MyContext)
    const [users, setUsers] = useState([])
    const [apicall, setApicall] = useState(true)
    const [select, setSelect] = useState([])
    const alert = useAlert()
    const [initialValues, setValue] = useState({
        name: "",
        school: "",
        desc: ""
    })

    const onSubmit = async (values, {}) => {
        const updateGroupsUsers = firebaseInstance().functions().httpsCallable('UpdateGroupsUsers')
        await updateGroupsUsers({users: select, id: data.id})

        let group = {...values};
        if(edit) {
            groupsDoc.doc(values.id).update(values)
                .then(()=> {
                    alert.success(t("general.updatedgroup"))
                    dispatchModal({type: 'CLOSE_MODAL'})
                })
        }else {
            group.admin = [globalId]
            group.school = me.select.id;
            groupsDoc.add(group).then(()=> {
                alert.success(t("general.createdgroup"))
                dispatchModal({type: 'CLOSE_MODAL'})
            })
        }


    }

    useEffect(()=> {
        if(data) {
            setValue(data)
            usersDoc.where('schoolsId', 'array-contains', me.select.id)
                .get()
                .then( doc => {
                    setUsers(doc.docs.map( d => {
                        return {id: d.id, ...d.data()}
                    }))
                })
        }
        if(apicall) {
            //setValue(data)
            usersDoc.where('schoolsId', 'array-contains', me.select.id)
                .get()
                .then( doc => {
                    setUsers(doc.docs.map( d => {
                        return {id: d.id, ...d.data()}
                    }))
                    setApicall(false)
                })
        }

    }, [data, apicall])
    return (
        <ModalWrapper>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({values, isSubmitting}) => {
                    return (
                        <Form>
                            <div style={{width: '95%'}}>
                                <div className="field">
                                    <InputText
                                        label={ t('listing.name')}
                                        value={values.name}
                                        name="name"
                                        disabled={data && data.noDelete}
                                    />
                                </div>
                                <div className="field">
                                    <InputText
                                        label={ t('general.description')}
                                        value={values.desc}
                                        name="desc" />
                                </div>
                                <div className="field">
                                    <label>Selectionner les étudiants</label>
                                    <div className="control">
                                        <Multiselect
                                           options={users}
                                           emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                           selectedValues={select}
                                           onSelect={(v)=> setSelect(v)}
                                           onRemove={(v)=> setSelect(v)}
                                           displayValue="displayName"
                                        />
                                    </div>
                                </div>
                                <div style={{height: 200}}></div>
                                <button type="submit" className="button">{t('general.save')}</button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </ModalWrapper>
    )
}
