import {loadStripe} from "@stripe/stripe-js";
import React, {useContext, useEffect, useState} from "react";
import {MyContext} from "../stores";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import styled from "styled-components";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {InputDate, InputText, SwitchBox} from "../components/FormElements";
import {ErrorContainer} from "../components/FormManager/ErrorContainer";
import {Multiselect} from "multiselect-react-dropdown";
import {format} from "date-fns";
import {DynamicTextArea} from "../pages/formGenerator/Preview";
import {StickyFooter} from "./EditJobModal";
import {FormContainer} from "../pages/EventCrud";
import {useTranslation} from "react-i18next";
import {userRoles} from "../stores/enums/userRoles";
import {useCatReducer} from "../stores/categories";
import {eventsDocs, firestore, postsDoc} from "../firestore/firestoreService";
import {initialValue} from "../components/DisplayContentFromSlate";
import {useAlert} from "react-alert";

const load = (async () => {
    let data = await loadStripe('pk_test_jnr18UR9A1OQcZmlcqiszZuU00sxKvEMjR')
    return data;
})()

const Style = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  width:100%;
  height: 100vh;
  background-color: white;
  z-index: 200000;
  justify-content: center;
  overflow: scroll;
  h1 {
    text-align: center;
    width: 100%;
    font-size: 1.5rem;
  }
 .top {
   width: 100%;
   margin-top: 1rem;
   display: inline-flex;
   
 }
  .wrap {
    width: 800px;
    overflow: scroll;
    height: 100vh;
    margin: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .inner {
    display: flex;
    flex-direction: column;
    width: 800px; 
    margin: 2rem auto;
  }
  .close {
    position: fixed;
    top: 2rem;
    right: 2rem;
    aspect-ratio: 1/1;
    width: 50px;
    font-size: 30px;
    color: black;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`


export default function({job, page, event, closeOut}){
    const {me, langChoice, dispatchModal, setContent, globalId} = useContext(MyContext)
    const stripe = useStripe()
    const elements = useElements()
    const [cat, setCat] = useState([])
    const [apicall, setApicall] = useState(false)
    const [groups, setGroups] = useState([])
    const [edit, setEdit] = useState(false)
    const [select, setSelect] = useState([])
    const [creating, setCreating] = useState(true)
    const alert = useAlert()

    const {t} = useTranslation()
    const [data, setData] = useState(null)
    const [categories, dispatchAll] = useCatReducer()
    categories && categories.pop()

    const initialValues = {
        title: '',
        needed: 1,
        tiny: '',
        desc: '',
        type: [{type:'jobs', label: t('overlay.jobs')}],
        active: false,
        date: new Date(),
        startDate: new Date(),
        endDate: new Date(),
        category: [],
        groups: [],
        status: 'pending'
    }

    function validation( v ) {
        if(Array.isArray(v)) {
            return v
        }
        return [
            {
                type: 'paragraph',
                children: [
                    {
                        text: v,
                    },
                ],
            },
        ]
    }

    const filterCategoriesByRole = () => {
        return categories && categories.filter(cat => {
            if([userRoles.SCH_ADMIN_SCHOOL,userRoles.CMP_ADMIN_BUSINESS,userRoles.SCH_BUSINESS, userRoles.BIZ_JOBLISTING_ADMIN].includes(me.role) && cat){
                return ["categories"].includes(cat[0])
            }else {
                return !["supervisionTypeCompany","teamvaluesCompany","workActivitiesCompany","benefitsCompany","motCompany","leaderCompany"].includes(cat[0])
            }
        })
    }

    const returnCat = (cat, list, values) => {
        return values[cat] && values[cat].map( k => {
            return list.find(l => l.key === k)
        }) || []
    }

    const onSubmit = (data, {setSubmitting}) => {
        setSubmitting(true)
        //data.desc = content;
        console.log({...data})
        data.type = data.type[0].type;
        try{
            data.date = new Date(data.date).getTime()
        }catch(e){}
        try{
            data.endDate = new Date(data.endDate).getTime()
        }catch(e){}
        data.category = cat;
        data.groups = data.groups.map(g => g.id)
        data.authorId = firestore.FieldValue.arrayUnion(globalId);
        data.author = {
            id: globalId,
            displayName: me.displayName,
            companyName: me.select.name
        }
        data.businessId = firestore.FieldValue.arrayUnion(me.select.id)
        console.log(data)
        postsDoc.add(data).then(async (d) => {
            //setSubmitting(false)
            // setTimeout(() => {
            //     history.goBack()
            // }, 0)

            alert.success(t("post.created"))
            setCreating(false)
            console.log(d)

            let resp = await fetch(`https://us-central1-bazookka-app.cloudfunctions.net/apiStripe/create_payment_link?id=${d.id}`, {method: "post", body: JSON.stringify({id: d.id}) })
            let json = await resp.json();
            console.log(json)
            setSubmitting(false)
            window.location.href = json.url;
            closeOut()
        }).catch(e => {
            alert.error(e.message)
        })
    }

    useEffect(()=> {
        const cleanUp = "undefined"
        dispatchAll({type: langChoice === 'fr' ? 'ALL_SET_FR': 'ALL_SET_EN'})


        if(job) {
            setData({...job})
            if(job.category) {
                setSelect(job.category.map( c => {
                    return {key: c, value: t(c)}
                }))
            }

            //setContent(validation(job.desc))
            setApicall(true)
        }

        if(event) {
            cleanUp = eventsDocs.doc(event).get().then( doc => {
                setData({id: doc.id, ...doc.data()})
                setApicall(true)
                setContent(validation(doc.data().desc))
            })
        }
        if(page === 'createEvent'){
            setData(initialValues)
            setApicall(true)
            setEdit(false)
        }

        if(!job) {
            setData(initialValues)
            setApicall(true)
            setEdit(false)
        }
        return ()=> {
            setContent(initialValue)
            setSelect([])
            setData(initialValues)
            return () => typeof x === "function" && cleanUp()
        }
    }, [apicall])

    const onClose = () => {
        closeOut()
    }
    return (

            <Style>
                <div className="close" onClick={onClose}><div>X</div></div>
                <div className="wrap">
                    <div className="inner">
                    <div><img src="assets/old/favicon.png" height={56}/></div>
                    <div className="top"></div>
                    <FormContainer>
                        <Formik
                            initialValues={{...data}}
                            onSubmit={onSubmit}
                            validationSchema={Yup.object({
                                title: Yup.string().required(t('validation.required')),
                                tiny: Yup.string().required(t('validation.required')),
                                //type: Yup.string().min(1).required(t('validation.required')),
                                //groups: Yup.array().min(1).required(t('validation.musthaveonegroupe')),
                                // date: Yup.date().required(t('validation.required')),
                                // endDate: Yup.date().default(null)
                                //     .when("date",
                                //         {
                                //             is: Yup.date().isValid(),
                                //             then: Yup.date().min(Yup.ref("date", "date must be later then start date")),
                                //             otherwise: Yup.date().nullable(),
                                //     }).typeError("Invalide End date ")
                            })}
                            enableReinitialize={true}>
                            {({values, setFieldValue, isSubmitting}) => {
                                return (
                                    <Form>
                                        <div className="settings-form-wrapper">
                                            <div className="settings-form">
                                                <div style={{width: '95%'}}>
                                                    <div className="field">
                                                        <InputText
                                                            label={t('special.title')}
                                                            value={values.title}
                                                            name="title"/>
                                                        <ErrorContainer component="div" name={'title'} />
                                                    </div>
                                                    <div className="field">
                                                        <InputText
                                                            label={t('special.tiny')}
                                                            name="tiny"/>
                                                        <ErrorContainer component="div" name={'tiny'} />
                                                    </div>
                                                    {/*<div className="field">*/}
                                                    {/*    <label>{t('general.whoshouldseethepost')}</label>*/}
                                                    {/*    <Multiselect*/}
                                                    {/*        emptyRecordMsg={t("multiselect.noOptionAvailable")}*/}
                                                    {/*        name={`groups`}*/}
                                                    {/*        options={groups}*/}
                                                    {/*        placeholder={t("overlay.select")}*/}
                                                    {/*        onSelect={v => {*/}
                                                    {/*            setFieldValue('groups', v)*/}
                                                    {/*        }}*/}
                                                    {/*        defaultValue={[groups[0]]}*/}
                                                    {/*        displayValue={'name'}*/}
                                                    {/*    />*/}
                                                    {/*    <ErrorContainer component="div" name={'groups'} />*/}
                                                    {/*</div>*/}
                                                    <div className="field">
                                                        <label>{t('listing.jobType')}</label>
                                                        <Multiselect
                                                            name={'type'}
                                                            singleSelect
                                                            emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                            options={
                                                                [
                                                                    {type: 'jobs', label:t('overlay.jobs')},
                                                                    {type: 'internships', label:t('overlay.internships')},
                                                                ]
                                                            }
                                                            onSelect={ v =>{
                                                                setFieldValue('type', v)
                                                            }}
                                                            selectedValues={[{type: 'jobs', label:t('overlay.jobs')}]}
                                                            displayValue={'label'}
                                                        />
                                                        <ErrorContainer component="div" name={'type'} />
                                                    </div>
                                                    {categories && filterCategoriesByRole().map((cat, i) => {
                                                        return (
                                                            <div className="field" key={i}>
                                                                <label>{t(cat[0])}
                                                                    <div className="control">
                                                                        <Multiselect
                                                                            options={cat[1] || []}
                                                                            displayValue="value"
                                                                            emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                                            placeholder={t('overlay.select')}
                                                                            selectionLimit={["teamvalues","workactivities","benefits","leader"].includes(cat[0])  ? 3 : -1}
                                                                            selectedValues={returnCat(cat[0], cat[1], values, cat)}
                                                                            onSelect={(v) => {
                                                                                values[cat[0]] = v.map(k => k.key)
                                                                            }}
                                                                            onRemove={(v) => {
                                                                                values[cat[0]] = v.map(k => k.key)
                                                                            }}

                                                                        >

                                                                        </Multiselect>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        )
                                                    })}

                                                    <div className="field">
                                                        <InputDate
                                                            label={langChoice === 'fr' ? 'Date de début' : 'Start date'}
                                                            value={format(new Date(values?.date || 0), 'dd/MM/yyyy')}
                                                            name="date"
                                                        />
                                                        <ErrorContainer component="div" name={'date'} />
                                                    </div>

                                                    <div className="field">
                                                        <InputDate
                                                            label={langChoice === 'fr' ? 'Date de fin' : 'End date'}
                                                            value={format(new Date(values?.endDate || 0), 'dd/MM/yyyy')}
                                                            name="endDate"
                                                        />
                                                        <ErrorContainer component="div" name={'endDate'} />
                                                    </div>
                                                    {/*<div className="field">*/}
                                                    {/*    <SwitchBox*/}
                                                    {/*        label={langChoice === 'fr' ? 'Actif' : 'Active'}*/}
                                                    {/*        name="active"*/}
                                                    {/*    />*/}
                                                    {/*</div>*/}

                                                    {/*<div className="field" style={{display:'flex', justifyContent: 'space-between'}}>*/}
                                                    {/*    <button type="submit" className="button is-primary" disabled={isSubmitting}>{langChoice === 'fr' ? 'sauvegarder': 'save'}</button>*/}
                                                    {/*    /!*<button onClick={(e) => returnBack(e, resetForm)} className="button is-danger">{langChoice === 'fr' ? 'ne pas sauvegarder': 'do not save'}</button>*!/*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                        {/*<RichTextEditor/>*/}
                                        <DynamicTextArea
                                            onChange={ stateText => {
                                                setFieldValue("desc", stateText)
                                            }}/>
                                        <StickyFooter>
                                            <button type="submit"
                                                    className="button is-small is-link" disabled={isSubmitting}>{t('general.purchase')}</button>
                                            <button className="button is-small is-danger"
                                                    onClick={() => dispatchModal({type: 'CLOSE_MODAL'})}
                                            >{t('general.cancel')}</button>
                                        </StickyFooter>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </FormContainer>

                </div>
                </div>

            </Style>



    )
}