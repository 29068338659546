import React, {useState, useEffect, useContext} from 'react'
import {MyContext} from "../stores";
import {Grid, Paper} from "@mui/material"
import {useTranslation} from "react-i18next";
import {getUserRole} from "../helper/acl";
import Routes from "../config/routes"
import styled from "styled-components"
import {useHistory} from "react-router-dom"

const GridStyled = styled(Grid)`
 display: grid;
 grid-template-columns: repeat(2, 1fr);
 grid-gap: 1rem; 
 justify-content: left;
 align-items: center; 

 > .tile {
    border: 1px solid #b3b3b3;
    border-radius: 8px;
    padding:  1rem;
    font-size: 1rem;
    background-color: white;
    cursor: pointer;

    &:hover{
        background-color:#344258;
        color:white;
    }
    
    > i {
        font-size: 1.2rem;
        margin-right: 8px;
        height:10px;
    }

    > span{
        padding-top:2px;
    }
 }
`
/**
 *
 * @param me
 * @returns {JSX.Element}
 * @constructor
 */
export default function BusinessMenu({me}){
    const {t} = useTranslation()
    const [userRole, setUserRole] = useState(null)
    const locale = 'settings'
    const history = useHistory()

    function linkTo(path){
        history.push(path)
    }

    useEffect(()=> {
        if(me && me.select) {
            getUserRole(me?.select?.id).then(role => {
                setUserRole(role)
            })
        }
    }, [me])

    return (
        <>
            <GridStyled>
                {Routes.filter( bylink => {
                    if(bylink.locales && bylink.roles) {
                        if(bylink.locales.includes(locale) && bylink.roles.includes(userRole)) {
                            return true
                        }
                        return false
                    }
                    return false;
                })
                    .map( (lnk, i) => {
                        return (
                            <Grid className={'tile'} key={i} columns={{ xs: 12, sm: 12, md: 12 }}
                                onClick={ e => linkTo(lnk.path)}
                            >
                                {lnk.ico} <span>{t(lnk.label)}</span>
                            </Grid>
                        )
                    })
                }
            </GridStyled>
        </>

    )
}
