import React, {useState, useEffect} from 'react'
import {formTemplates} from "../../firestore/firestoreService";
import MUIDataTable from "mui-datatables";
import {useTranslation} from "react-i18next";
import {
    IconButton,
    Dialog,
    DialogContent,
    List,
    ListItem,
    CircularProgress,
    AppBar, Toolbar, Button, Typography, Avatar} from "@material-ui/core";
import {Visibility as VisibilityIcon, Close as CloseIcon} from "@mui/icons-material";
import styled from 'styled-components'
import NoItems from "../../components/NoItems";
import {usePromise} from "../../helper";
import {useAlert} from 'react-alert'

export const AppBarStyled = styled(AppBar)`
  &.MuiAppBar-colorPrimary {
    background-color: var(--bazookka-blue);
  }
`

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}


const displayQuestionName = (name, questions) => {
    return questions.reduce((prev, curr) => {
        if(curr.questionText === name) {
            return name;
        }
        return prev;
    }, "")
}

const displayObject = (obj) => {
    let temp = [];
    for(let k in obj) {
        if(obj[k] === true) {
            temp.push(k)
        }
    }
    return temp.join("-");
}

export default function Answers({slug, questions, title}) {

    const {t} = useTranslation()
    const [columns, setColumns] = useState(["name", {name: 'Edit'}])
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [select, setSelect] = useState(null)
    const alert = useAlert()
    const [answers, answersError, answersLoading] = usePromise(async ()=>{
        const users = await formTemplates.doc(slug).collection('users').where('done', '==', true).get()
        return users.docs.map( doc => {
            return {id: doc.id, ...doc.data()}
        })
    }, [])

    const onOpen = (data) => {

        setSelect(data);
        setOpen(true)
    }

    const handleClose = () => setOpen(false)

    useEffect(async ()=> {
        setColumns([{
            name: 'Avatar',
            options: {
                filter: false,
                customBodyRender: value => {
                    return value.avatar ? <Avatar src={value.avatar}/> : <Avatar  {...stringAvatar(value.displayName)} />
                }
            }
        },t('formAnswerTable.nameColumn'), {
            name: t('formAnswerTable.editColumn'),
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton onClick={ e => onOpen(value)}>
                            <VisibilityIcon></VisibilityIcon>
                        </IconButton>
                    )
                }
            }

        }])
    }, [slug])

    useEffect(()=> {
        try {
            setData(answers.map(ans => {
                return [ans.users, ans.users.displayName, ans]
            }))
        }catch(e) {

        }
    }, [columns, answers])

    if(answersLoading) {
        return <CircularProgress />
    }

    if(!answers || answersError) {
        return <NoItems />
    }

         const options = {
        textLabels: {
          body: {
            noMatch: t('formAnswerTable.noAnswer'),
          },
          pagination: {
            next: t("formAnswerTable.pagination.nextPage"),
            previous: t("formAnswerTable.pagination.previousPage"),
            rowsPerPage: t("formAnswerTable.pagination.rowPerPage"),
            displayRows: t("formAnswerTable.pagination.displayRow"),
          },
          toolbar: {
            search: t("general.search"),
            downloadCsv: t("formAnswerTable.downloadCsv"),
            print: t("formAnswerTable.print"),
            viewColumns: t("formAnswerTable.viewColumns"),
            filterTable: t("formAnswerTable.filterTable")
          },
          filter: {
            all: t("formAnswerTable.filterTable.all"),
            title: t("formAnswerTable.filterTable.title"),
            reset: t("formAnswerTable.filterTable.reset"),
          },
          viewColumns: {
            title: t("formAnswerTable.viewColumns.title"),
            titleAria: t("formAnswerTable.viewColumns.titleAria"),
          },
          selectedRows: {
            text: t("formAnswerTable.selectedRows.text"),
            delete: t("formAnswerTable.selectedRows.delete"),
            deleteAria: t("formAnswerTable.selectedRows.deleteArea"),
          },
        },
        download : false,
        selectableRows: false
      }

    return (
        <div>
           <MUIDataTable
               title={t(`general.answers`)}
               columns={columns}
               data={data}
            options={
                options
            }
           ></MUIDataTable>
           <Dialog
               style={{zIndex: 10000000000000}}
               fullScreen
               open={open}>
               <AppBarStyled sx={{ position: 'relative', backgroundColor: 'var(--bazookka-blue)'}}>
                   <Toolbar>
                       <IconButton
                           edge="start"
                           color="inherit"
                           onClick={handleClose}
                           aria-label="close"
                       >
                           <CloseIcon />
                       </IconButton>
                       <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                           {select?.users?.displayName} - {title}
                       </Typography>
                   </Toolbar>
               </AppBarStyled>
               <DialogContent style={{paddingTop: 100}}>
                   <List>
                       {select?.answers && Object.keys(select.answers).map((k, i)=>{
                           return (
                               <ListItem key={i}>{k}: {select.answers[k] instanceof Object ? displayObject(select.answers[k]) : select.answers[k] }</ListItem>
                           )
                       })}
                   </List>
               </DialogContent>
           </Dialog>
        </div>
    )

}
