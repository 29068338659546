import React from 'react';
import moment from 'moment';
import slc from 'styled-components';
import { TrashIco } from "../ico";

const AttachmentBadge = slc.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    width: 46px;
    background: #fff;
    border-radius: 50%;
    border: 1.6px solid #e8e8e8;
    -webkit-transition: all .3s;
    transition: all .3s;
`

const AttachmentsContainer = slc.div`
`

const AttachmentContainer = slc.div`
    display: ${props => props.isHorizontal ? "inline-block" : "grid"};
    grid-template-columns:1fr 3fr;
    padding: 10px;
`

const AttachmentLink = slc.a`
    display: ${props => props.isHorizontal ? "block" : "inline-block"};
    margin:${props => props.isHorizontal ? "auto" : "0"};
    width:46px;
`

const AttachmentDeleteLink = slc.a`
    display:inline;
    vertical-align:top;
`

const MetaContainer = slc.div`
    display:inline-block;
    vertical-align:middle;
    margin: auto 0 auto 12px;
    font-size:.9em;

    .date{
        color:#999;
    }
`

function AttachmentList({attachments, isHorizontal = false, metas = [], onDelete}) {
    return (
        <AttachmentsContainer>
             {attachments.length > 0 && attachments.map( (att, i) => {
                 const formattedType = att.doc.meta.type.split('/')[0];
                    return (  
                        <AttachmentContainer isHorizontal={isHorizontal} key={i}>
                            <AttachmentLink href={att.doc.meta.path} alt={att.doc.meta.name} target="_blank">
                                <AttachmentBadge>
                                    <i className={`mdi ${selectIco(formattedType)}`}></i>
                                </AttachmentBadge>
                            </AttachmentLink>
                            {metas.length > 0 && 
                            <MetaContainer>
                                {metas.includes('name') &&
                                    <div className="name">{att.doc.meta.name}</div>
                                }
                                {metas.includes('date') &&
                                    <div className="date">{moment(att.doc.created_at).format('LL')}</div>
                                }
                                {onDelete &&
                                    <AttachmentDeleteLink onClick={() => onDelete(att)}><TrashIco /></AttachmentDeleteLink>
                                }

                            </MetaContainer>
                            }
                        </AttachmentContainer>
                    )
                })
            }
        </AttachmentsContainer>
    )
}

const selectIco = (value) => {
    switch(value){
        case 'pdf':
            return 'mdi-file-pdf'
        break;
        case 'doc':
            return 'mdi-file-word'
            break;
        case 'excel':
            return 'mdi-file-excel'
        break;
        case 'pp':
            return 'mdi-file-powerpoint'
            break;
        case 'text':
            return 'mdi-note-text'
            break;
        case 'image':
            return 'mdi-file-image'
            break;
        case 'digital':
            return 'mdi-file-cloud'
            break;
        case 'csv':
            return 'mdi-file-delimited'
            break;
    }
}

export default AttachmentList
