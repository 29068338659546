import React, {useContext, useState, useRef} from 'react'
import {MyContext} from "../stores";

export const InviteUsers = () => {
    const {setAppAddUsers, setAppAddMUsers} = useContext(MyContext)
    const [user, setUser] = useState({
        email: '',
        firstname: '',
        lastname: '',
        profiles: [
            {
                name:'',
                rules: '',
                selected: false
            }
        ]
    })
    const [highlighted, setHighlighted] = useState(false)

    const onClose = () => {
        setAppAddUsers(false)
    }
    const onChange = evt => {
        setUser({...user, [evt.target.name]: evt.target.value})
    }
    const onFocus = evt => {
        setHighlighted(true)
    }
    return (
        <div id="compose-card"  className={`card is-new-content ${highlighted? 'is-highlighted': ''}`}>
            <div className="tabs-wrapper">
                <div className="tabs is-boxed is-fullwidth">
                    <ul>
                        <li className="is-active">
                            <a>
                                <span className="icon is-small">
                                    <i className="mdi mdi-account-plus"></i>
                                </span>
                                <span>user</span>
                            </a>
                        </li>
                        <li className="" onClick={()=> setAppAddMUsers(true)}>
                            <a>
                                <span className="icon is-small">
                                    <i className="mdi mdi-account-multiple-plus"></i>
                                </span>
                                <span>users</span>
                            </a>
                        </li>
                        <li className="close-wrap" onClick={onClose}>
                                <span className="close-publish">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                         className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                </span>
                        </li>
                    </ul>
                </div>
                <div className="tab-content">
                    <div className="compose">
                        <div className="compose-form">
                            <div className="control">
                                    <textarea id="publish" className="textarea" rows="3" onFocus={onFocus} onChange={onChange} value={user.email} name="email"
                                              placeholder="Enter email of user..."></textarea>
                            </div>
                        </div>
                        <div id="options-summary" className="options-summary"></div>

                    </div>
                    <div className="hidden-options is-opened">
                        <div className="target-channels">
                            <div className="channel">
                                <div className="round-checkbox is-small">
                                    <div>
                                        <input type="checkbox" id="checkbox-1" checked="" />
                                        <label htmlFor="checkbox-1"></label>
                                    </div>
                                </div>
                                <div className="channel-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-bell">
                                        <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                        <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                    </svg>
                                </div>
                                <div className="channel-name">Activity Feed</div>

                                <div className="dropdown is-spaced is-modern is-right is-neutral dropdown-trigger">
                                    <div>
                                        <button className="button" aria-haspopup="true">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-smile main-icon">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                                <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                                <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                            </svg>
                                            <span>Friends</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-chevron-down caret">
                                                <polyline points="6 9 12 15 18 9"></polyline>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="dropdown-menu" role="menu">
                                        <div className="dropdown-content">
                                            <a >
                                                <div className="media">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-globe">
                                                        <circle cx="12" cy="12" r="10"></circle>
                                                        <line x1="2" y1="12" x2="22" y2="12"></line>
                                                        <path
                                                            d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                                                    </svg>
                                                    <div className="media-content">
                                                        <h3>Public</h3>
                                                        <small>Anyone can see this publication.</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item">
                                                <div className="media">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-users">
                                                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                        <circle cx="9" cy="7" r="4"></circle>
                                                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                                    </svg>
                                                    <div className="media-content">
                                                        <h3>Friends</h3>
                                                        <small>only friends can see this publication.</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item">
                                                <div className="media">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-user">
                                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                        <circle cx="12" cy="7" r="4"></circle>
                                                    </svg>
                                                    <div className="media-content">
                                                        <h3>Specific friends</h3>
                                                        <small>Don't show it to some friends.</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <hr className="dropdown-divider" />
                                            <a className="dropdown-item">
                                                <div className="media">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round"
                                                         strokeLinejoin="round" className="feather feather-lock">
                                                        <rect x="3" y="11" width="18" height="11" rx="2"
                                                              ry="2"></rect>
                                                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                    </svg>
                                                    <div className="media-content">
                                                        <h3>Only me</h3>
                                                        <small>Only me can see this publication.</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="more-wrap">

                        <button id="show-compose-friends" type="button" className="button is-more" aria-haspopup="true" style={{display: 'none'}}>
                            <i data-feather="more-vertical"></i>
                            <span>View More</span>
                        </button>
                        { highlighted ?
                            <button  id="publish-button" type="button"
                                    className="button is-solid accent-button is-fullwidth">
                                Publish
                            </button>:
                            <button  id="publish-button" type="button"
                                     className="button is-solid accent-button is-fullwidth is-disabled">
                                Publish
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export const AddUsers = () => {
    const {setAppAddUsers} = useContext(MyContext)
    return (
        <div id="share-modal" className="modal share-modal is-xsmall has-light-bg is-active">
            <div className="modal-background"></div>
            <div className="modal-content">

                <div className="card">
                    <div className="card-heading">
                        <div><span>
                            Create
                        </span></div>
                        <div className="close-wrap">
                        <span className="close-modal" onClick={()=> setAppAddUsers(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6"
                                                                                            y2="18"></line><line x1="6"
                                                                                                                 y1="6"
                                                                                                                 x2="18"
                                                                                                                 y2="18"></line></svg>
                        </span>
                        </div>
                    </div>
                    <InviteUsers />
                    <div className="card-footer">
                        <div className="button-wrap">
                            <button type="button" className="button is-solid dark-grey-button close-modal" onClick={()=> setAppAddUsers(false)}>Cancel
                            </button>
                            <button type="button" className="button is-solid primary-button close-modal">Save
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export const MultipleUsers = props => {
    const fileInput = useRef()
    const {setAppAddMUsers} = useContext(MyContext)
    const [menu, setMenu] = useState(false)
    const [users, setUsers] = useState([])
    const clickMenu = evt => {
        setMenu(!menu)
    }
    const onUsers = evt => {

    }

    const onFile = evt => {
        console.log(evt.target.files)
    }

    const onClick = evt => {
        fileInput.current.click()
    }
    return (
        <div id="albums-modal" className={`modal albums-modal is-xxl has-light-bg is-active`}>
            <div className="modal-background"></div>
            <div className="modal-content">

                <div className="card">
                    <div className="card-heading">
                        <h3>Upload users list</h3>
                        <div className="button is-solid accent-button fileinput-button dz-clickable">
                        </div>
                        <div className="close-wrap" onClick={()=> setAppAddMUsers(false)}>
                                <span className="close-modal">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6"
                                                                                                     x2="6"
                                                                                                     y2="18"></line><line
                                        x1="6" y1="6" x2="18" y2="18"></line></svg>
                                </span>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="left-section">
                            {false && (
                                <React.Fragment>
                                    <div className="album-form">

                                    </div>

                                    <div id="tagged-in-album" className="tagged-in-album" >
                                        <div className="head">
                                            <h4>Tag friends in this album</h4>
                                            <button type="button"
                                                    className="button is-solid dark-grey-button icon-button is-hidden">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                     strokeLinecap="round" strokeLinejoin="round"
                                                     className="feather feather-plus">
                                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                                </svg>
                                            </button>
                                        </div>

                                        <p className="is-hidden">Tag friends in this album. Tagged friends can see photos they
                                            are tagged in.</p>
                                        <div className="field is-autocomplete">
                                            <div className="control">
                                                <div className="easy-autocomplete" style={{width: 0}}><input
                                                    id="create-album-friends-autocpl" type="text"
                                                    className="input is-sm is-fade" placeholder="Search for friends"
                                                    autoComplete="off"/>
                                                    <div className="easy-autocomplete-container"
                                                         id="eac-container-create-album-friends-autocpl">
                                                        <ul style={{display: 'none'}}>
                                                            <li className="selected">
                                                                <div className="eac-item">
                                                                    <div className="template-wrapper">
                                                                        <div className="avatar-wrapper"><img
                                                                            className="autocpl-avatar"
                                                                            src="https://via.placeholder.com/150x150" /><img
                                                                            className="avatar-badge"
                                                                            src="assets/img/icons/flags/germany.svg" /></div>
                                                                        <div className="entry-text">Stella
                                                                            Bergmann<br /><span>Berlin</span></div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="eac-item">
                                                                    <div className="template-wrapper">
                                                                        <div className="avatar-wrapper"><img
                                                                            className="autocpl-avatar"
                                                                            src="https://via.placeholder.com/150x150"/><img
                                                                            className="avatar-badge"
                                                                            src="assets/img/icons/flags/italy.svg"/></div>
                                                                        <div className="entry-text">Milly
                                                                            Augustine<br /><span>Rome</span></div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div></div>
                                                <div className="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                         strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-search">
                                                        <circle cx="11" cy="11" r="8"></circle>
                                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="album-tag-list" className="album-tag-list">
                                            <div className="tagged-user">
                                                <img src="https://via.placeholder.com/150x150" alt="" data-user-popover="2"
                                                     data-target="webuiPopover82"/>
                                                <div className="remove-tag">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-x">
                                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="shared-album">
                                        <div className="head">
                                            <h4>Allow friends to add photos</h4>
                                            <div className="basic-checkbox">
                                                <input className="styled-checkbox" id="styled-checkbox-1" type="checkbox"
                                                       value="value1"/>
                                                <label htmlFor="styled-checkbox-1"></label>
                                            </div>
                                        </div>

                                        <p>Tagged friends will be able to share content inside this album.</p>
                                    </div>
                                </React.Fragment>
                            )}


                        </div>
                        <div className="right-section has-slimscroll">

                            <div className="modal-uploader">
                                <div id="actions" className="columns is-multiline no-mb">
                                    <div className="column is-12">
                                            <span
                                                className="button has-icon is-solid grey-button fileinput-button dz-clickable" onClick={onClick}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                     strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                     className="feather feather-plus">
                                                    <line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                                <input type="file" ref={fileInput} onChange={onFile}/>
                                            </span>
                                        <button type="submit" className="button start is-hidden">
                                            <span>Upload</span>
                                        </button>
                                        <button type="reset" className="button is-solid grey-button cancel">
                                            <span>Clear all</span>
                                        </button>
                                        {false && (
                                            <span className="file-count">
                                                <span id="modal-uploader-file-count">0</span> file(s) selected
                                            </span>
                                        )
                                        }

                                    </div>

                                    <div className="column is-12 is-hidden">

                                        <div className="fileupload-process">
                                            <div id="total-progress" className="progress progress-striped active"
                                                 role="progressbar" aria-valuemin="0" aria-valuemax="100"
                                                 aria-valuenow="0">
                                                <div className="progress-bar progress-bar-success" style={{width: '0%'}}
                                                     data-dz-uploadprogress=""></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className="columns is-multiline" id="previews">


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">

                        <div className={`dropdown is-up is-spaced is-modern is-neutral is-right dropdown-trigger ${menu ?'is-active': ''}`}>
                            <div>
                                <button className="button" aria-haspopup="true" onClick={clickMenu}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-smile main-icon">
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                        <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                        <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                    </svg>
                                    <span>Select profile</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-chevron-down caret">
                                        <polyline points="6 9 12 15 18 9"></polyline>
                                    </svg>
                                </button>
                            </div>
                            <div className="dropdown-menu" role="menu">
                                <div className="dropdown-content">
                                    <a >
                                        <div className="media">
                                            <i className="mdi mdi-school-outline"></i>
                                            <div className="media-content">
                                                <h3>students</h3>
                                                <small>Students added</small>
                                            </div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item">
                                        <div className="media">
                                            <i className="mdi mdi-account-group-outline" style={{display: 'none'}}></i>
                                            <div className="media-content">
                                                <h3>Teachers</h3>
                                                <small>Group of teachers to be added</small>
                                            </div>
                                        </div>
                                    </a>
                                    <a className="dropdown-item" style={{display: 'none'}}>
                                        <div className="media">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-user" style={{display: 'none'}}>
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="12" cy="7" r="4"></circle>
                                            </svg>
                                            <div className="media-content">
                                                <h3>Specific friends</h3>
                                                <small>Don't show it to some friends.</small>
                                            </div>
                                        </div>
                                    </a>
                                    <hr className="dropdown-divider" style={{display: 'none'}} />

                                </div>
                            </div>
                        </div>
                        <button type="button" className="button is-solid accent-button close-modal" onClick={onUsers}>upload user list
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

