import React, {useContext, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom"
import {MyContext} from "../stores";
import {JustCoverBG} from "./main/CoverBG";
import {useTranslation} from "react-i18next";
import {FormSignUpStyled} from "./SignUp/Registration";
import {Form, Formik} from "formik";
import {InputText, SwitchBox, TextAreaInput} from "../components/FormElements";
import {Multiselect} from "multiselect-react-dropdown";

import {useCatReducer} from "../stores/categories";
import {db, firebaseInstance} from "../firestore/firestoreService";
import {isNoDataNeedDeepInfo} from "../helper/acl";
import {useDeBounce} from "../helper";
import ReactSelect from "react-select";
import styled from "styled-components";
import * as Yup from "yup";

export const PasswordStyled = styled.div`
  border-radius: 1rem;
  background: white;
  display: flex;
  justify-content: center;
  width: 600px;

  border: 1px solid #e0e0e0;
  height: auto;
  margin: 2rem auto;
  padding: 3rem 0;
`
export function PasswordNew({id, t, setData, data, setSignUpStep, signUpStep}) {
    const {langChoice} = useContext(MyContext)
    const [type, setType] = useState('password')
    const onSubmit = async (value, {setSubmitting}) => {
        setSubmitting(true)
        setData(value)
        setSubmitting(false)
        setSignUpStep(signUpStep + 1)

    }

    const validate = Yup.object().shape({
        password: Yup.string().min(5).required(),
        cpassword: Yup.string().min(5).oneOf([Yup.ref('password'), null], 'password must match').required()
    })

    return (
        <PasswordStyled>
            <Formik
                onSubmit={onSubmit}
                initialValues={data}
                validationSchema={validate}
            >
                {({values, isSubmitting, errors, touched}) => {
                    return (
                        <Form>
                            <InputText
                                label={t('general.pwd')}
                                name="password"
                                value={values.password}
                                type={type}
                            />
                            <InputText
                                label={t('general.rpwd')}
                                name="cpassword"
                                value={values.cpassword}
                                type={type}
                            />
                            {errors.cpassword && touched.cpassword ? (
                                <div>{errors.cpassword}</div>
                            ) : null}
                            <SwitchBox
                                label={t('form.revealPassword')}
                                name="display"
                                onClick={()=> values.display ? setType('password') : setType('text')}
                            ></SwitchBox>
                            {}
                            <div className="split">
                                <button className="button" type="button"
                                 onClick={v => setSignUpStep(signUpStep -1)}
                                >{t('general.previous')}</button>
                                <button className="button" type="submit" disabled={isSubmitting}>{t('general.next')}</button>
                            </div>
                        </Form>
                    )
                }}

            </Formik>
        </PasswordStyled>
    )
}

export const Registration = ({t, data, setData, saved, setSignUpStep, categories}) => {
    const {me, langChoice} = useContext(MyContext)
    const history = useHistory()
    const addressRef = useRef()
    const [select, setSelect] = useState(null)
    const [save, setSave] = useState(null)
    const [options, setOptions] = useState([])
    const [needed, setNeeded] = useState(null)
    let googleLL = firebaseInstance().functions().httpsCallable('googleFindLongLat')
    const onSubmit = async (v, {setSubmitting}) => {
        setSubmitting(true)
        if(save) {
            v.address = save;
            let response = await googleLL({value: save})
            v.location = response.data.results[0].geometry.location
        }

        setData(v)
        setSignUpStep(2)
        setSubmitting(false)
    }
    const returnCat = (cat, list, values) => {

        return values[cat] && values[cat].map( k => {
            return list.find(l => l.key === k)
        }) || []
    }

    const onCancel = () => {
        history.push('/')
    }
    const onSave = async  (v, setSubmitting) => {
        setSubmitting(true)
        if(save) {
            v.address = save;
            let response = await googleLL({value: save})
            v.location = response.data.results[0].geometry.location
        }
        setSubmitting(false)
    }
    useEffect(()=> {

        isNoDataNeedDeepInfo(data.role).then( info => setNeeded(info))
        if(me) {
            history.push('/me')
        }
    }, [me])

    const updateRequest = async (value) => {
        let addressGetter = firebaseInstance().functions().httpsCallable('googleAddress')
        // let response = await axios.get(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${urlEncode(value)}&types=geocode&key=AIzaSyDU-7VwiLZiZwAZALo-0dqQlUAZQKXga1Y`)
        if(value.length > 7) {
            let response = await addressGetter({value})

            setOptions(response.data.response.predictions.map( (d, index) => {
                return {value: d.description, label: d.description}
            }))


        }
    }

    const handleChange = (v) => {
        setSelect(v)
        setSave(addressRef?.current?.state?.value?.label)
    }

    useDeBounce(()=> updateRequest(select), 2000, [select])
    return (
        <FormSignUpStyled>
            <Formik initialValues={data} onSubmit={onSubmit} enableReinitialize={true}>
                {({values, isSubmitting , resetForm, setSubmitting}) => {
                    return (
                        <Form autoComplete="off">
                            <h2>{t('general.registerform')}</h2>
                            <div className="column">
                                <InputText
                                    label={ t('listing.name')}
                                    value={values.displayName}
                                    name="displayName" />
                                <InputText
                                    label={ t('general.role')}
                                    value={t(values.role)}
                                    name="role" disabled />
                                <InputText
                                    label={ t('general.emailaddress')}
                                    value={values.email}
                                    name="email" />
                                {t('general.location')}:  {values.address}
                                <ReactSelect
                                    defaultValue={values.address || ''}
                                    options={options}
                                    onInputChange={handleChange}
                                    ref={addressRef}
                                />
                                <InputText label={t('settings.phone')} name="tel"/>
                                <TextAreaInput label={t('settings.intro')} name="intro"/>
                                {needed && categories && categories.map((cat, i)=> {
                                    return (
                                        <div className="field" key={i}>
                                            <label>{t(cat[0])}
                                                <div className="control">
                                                    <Multiselect
                                                        options={cat[1] ||[]}
                                                        displayValue="value"
                                                        placeholder={t('overlay.select')}
                                                        selectedValues={ returnCat(cat[0], cat[1], values)}
                                                        emptyRecordMsg={t("multiselect.noOptionAvailable")}

                                                        onSelect={(v) => {
                                                            values[cat[0]] = v.map( k => k.key)
                                                        }}
                                                        onRemove={(v) => {
                                                            values[cat[0]] = v.map( k => k.key)
                                                        }}

                                                    >

                                                    </Multiselect>
                                                </div>
                                            </label>
                                        </div>
                                    )
                                })}
                                <div className="split">
                                    {saved && <button type="button" className="button" onClick={()=> onSave(values, setSubmitting)}
                                                      disabled={isSubmitting}
                                    >Save for later</button>}
                                    <button type="submit" className="button" disabled={isSubmitting}
                                    >{t('general.next')}</button>
                                    <button type="button" className="button" onClick={onCancel}>Cancel</button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </FormSignUpStyled>
    )
}

const BusinessForm = (props) => {
    const {data, signUpStep, setSignUpStep, t, setData, categories} = props
    const onSubmit = (values) => {
        setData(values)
        setSignUpStep(signUpStep + 1)
    }
    return (
        <FormSignUpStyled>
            <Formik initialValues={{...data}} onSubmit={onSubmit} enableReinitialize={true}>
                {({values, isSubmitting, setFieldValue }) => {
                    return (
                        <React.Fragment>
                            <Form>
                                <InputText label={t('listing.name')} name="name" value={values && values.company}
                                  onChange={ v => {
                                      setFieldValue('company.name', v.target.value)
                                  }}
                                ></InputText>
                                <InputText label={t('general.fulladdress')} name="address"
                                    onChange={v => {
                                        setFieldValue('company.address', v.target.value)
                                    }}
                                ></InputText>
                                <InputText label={t('settings.phone')} name="tel"
                                    onChange={v => {
                                        setFieldValue('company.phone', v.target.value)
                                    }}
                                />
                                <TextAreaInput label={t('settings.intro')} name="intro"
                                  onChange={v => {
                                      setFieldValue('company.intro', v.target.value)
                                  }}
                                />
                                <div className="field">
                                    <label>Secteur</label>
                                    <div className="control">
                                        <Multiselect
                                            options={categories}
                                            emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                            placeholder={t('overlay.select')}
                                            selectedValues={values.field || []}
                                            onSelect={(v)=> {
                                                setFieldValue('company.field', v)
                                            }}
                                            displayValue="value"
                                        />
                                    </div>
                                </div>
                                <button type={'button'} className={'button'}
                                    onClick={e => setSignUpStep(signUpStep - 1)}
                                >{t('general.previous')}</button>
                                <button type="submit" className="button" disabled={isSubmitting}>{t('general.next')}</button>
                            </Form>
                        </React.Fragment>
                    )
                }}

            </Formik>
        </FormSignUpStyled>
    )
}

const DisplayFormRegistration = (props) => {
    const {signUpStep} = props;

    switch(signUpStep) {
        case 1:
            return <Registration {...props} />
            break;
        case 2:
            return <BusinessForm {...props} />
        break;
        case 3:
            return <PasswordNew {...props} />
        break;
        default:
            return <Registration {...props} />
    }
};

export default function CompanyRegistration(){
    const {me, langChoice} = useContext(MyContext)
    const [signUpStep, setSignUpStep] = useState(1)
    const [categories, dispatchAll] = useCatReducer()
    const [data, setData] = useState({role: 'cmp.admin_business'})
    const {t} = useTranslation()
    const history = useHistory();

    useEffect(()=>{

        dispatchAll({
            type: langChoice === 'fr' ? 'ALL_SET_FR' : 'ALL_SET_EN'
        });

        if(me) {
            history.push("me")
        }
    }, [me])

    return (
        <React.Fragment>
            <JustCoverBG />
            <DisplayFormRegistration
                signUpStep={signUpStep}
                setSignUpStep={setSignUpStep}
                t={t}
                setData={setData}
                categories={categories}
                data={data}/>
        </React.Fragment>
    )

}
