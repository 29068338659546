
export const UserAction = {
    OFFER_CANCEL : "Internship/Job Application Cancellation",
    OFFER_APPLICATION : "Internship/Job Application",
    ACTIVE_JOB_ADDED : "Added to active job",
    EVENT_REGISTRATION : "User register to an event",
    EVENT_REGISTRATION_CANCEL : "User cancel his registration to an event",
    CONVERSATION_REPLY : "User reply to an conversation",
    INVITE_ACCEPTATION : "User accepted invite ",
    INVITE_CANCELLATION : "User rejected invite",
    SUBMIT_FORM: "User has filled and submit a form",
    VIEW_FORM: "User has viewed a form"
    
}

