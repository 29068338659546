import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {MyContext} from "../stores";
import moment from "moment";
import ModalWrapper from "./index";
import {Formik, Form, Field} from "formik"
import {InputDate} from "../components/FormElements";
import {postsDoc, usersDoc, calendarsDoc, firebaseInstance} from "../firestore/firestoreService";
import styled from 'styled-components'
import {Multiselect} from 'multiselect-react-dropdown'
import {getHours, getMinutes, setHours, setMinutes} from "date-fns";
import {useAlert} from 'react-alert';
import { date } from 'joi';
import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
  } from '@react-hook/window-size'

const StyledCalendarOverlay = styled.div`
  .react-datepicker {
    width: 328px !important;
  }
  .b-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1rem;
  }
  .field:not(:last-child) {
     margin-bottom: 0rem; 
  }
  input[type="text"] {
    padding: 0.5rem;
    border-color: #dbdbdb;
    border-width: 1px;
    border-radius: 5px;
  }
  .multiselect-container {
    width: 70% !important;
  }
`


// eslint-disable-next-line import/no-anonymous-default-export
export default ({value, edit, attend}) => {
    const {me, globalId, dispatchModal, langChoice} = useContext(MyContext)
    const [highlighted, setHighlighted] = useState(false)
    const [users, setUsers] = useState([])
    const [select, setSelect] = useState([])
    const [startTime, setStartTime] = useState('00.00')
    const [duration, setDuration] = useState('00.00')
    const [apicall, setApicall] = useState(false)
    const alert = useAlert()
    const {t} = useTranslation()
    const windowWidth = useWindowWidth()
    useEffect(()=>{
        if(attend) {
            setSelect([attend])
        }
        if(!apicall && me?.select)
        usersDoc.where('schoolsId', 'array-contains', me.select.id)
            .get()
            .then( doc => {
                setApicall(true)
                setUsers(doc.docs.map( usr => {
                    return {id: usr.id, ...usr.data()}
                }))
            })
    }, [apicall, me])
    let m = moment();
    let hours = m.clone().set({hour: 0, minute: 0, millisecond: 0})
    //let [selected, setSelected] = useState(hours.format('HH:mm'))
    let endOfDay = hours.clone().set({hour:24, minute: 3})
    let clock = [];
    while(hours.isBefore(endOfDay, 'hour' )) {
        clock.push(hours.clone())
        hours.add(15, 'minute')
    }
    const [data, setData] = useState({
        title: '',
        desc: '',
        startDate: new Date(),
        startTime: new Date().getTime(),
        endDate: new Date(),
        duration: '',
        users: [],
        type: 'rdv'
    })

    const constructRichTextParagraph = (val) => {
        let completeParagraph = ""
        if(  typeof value.desc.forEach === "function"){
            value.desc.forEach(desc => desc.children && desc.children.forEach(child => completeParagraph += child.text)) 
        }else{
            completeParagraph = value.desc
        }
        return completeParagraph
    }
    useEffect(()=> {
        if(edit) {
            setData(value)
            if(value.type === "event" && data.type === "event"){
                data.desc = constructRichTextParagraph(value)
            }else{
                data.desc = value.desc
            }

            setSelect(value.attendees)
            setStartTime(value.startTime)
            setDuration(value.duration || '00.00')
        }
    }, [data])

    const updateStart = (inside) => {

        // console.log('hours',inside.clone().get('hours'))
        // console.log('hours-e',value.clone().add({hour: inside.minutes()}).valueOf())
       // update('startDate', value.clone().add({hour: inside.hours(), minute: inside.minutes()}).valueOf())
        //update('startTime', inside.clone().format('HH:mm'))
        setStartTime(inside.clone().format('HH:mm'))
    }

    const updateDuration = (inside) => {
        setDuration(inside.clone().format('HH:mm'))
    }

    const updateEnd = (inside) => {
        //update('endDate', value.clone().add({hour: inside.hours(), minute: inside.minutes()}).valueOf())
        update('endDate', inside.clone().format('HH:mm'))
    }

    const update = (name, value) => {
        setData(Object.assign({...data, [name]: value}))
    }
    const onFocus = evt => {
        console.log('on click')
    }

    const onClose = evt => {
        setHighlighted(false)
    }

    const onSelect = (value) => {
        setSelect(value)
    }
    const onRemove = (value) => {
        setSelect(value)
    }

    const [currentWidth, setCurrentWidth] = useState(0)

    const onPublish = (values, {setSubmitting}) => {

      
        setSubmitting(true)
        values.attendeesId = [globalId, ...select.map( s => s.id != undefined && s.id)]
        values.startDate = new Date(values.startDate).getTime()
        values.endDate = new Date(values.endDate).getTime()
        values.startTime = startTime;
        values.duration = duration;
        values.creatorId =  firebaseInstance().auth().currentUser.uid
        if(!edit) {
            calendarsDoc.add({...values, attendees: select})
                .then(doc => {
                    alert.success(t("calendar.calendarupdated"))
                    setSubmitting(false)
                    dispatchModal({type: 'CLOSE_MODAL'})

                })
        }else {
            calendarsDoc.doc(values.id).update({...values, attendees: select}).then( d => {
                alert.success(t("calendar.calendarupdated"))
                setSubmitting(false)
                dispatchModal({type: 'CLOSE_MODAL'})
            })
        }
        /**
         * You should  insert the logic to send the message to firebase here
         */
    }
    return (
        <ModalWrapper>
            <Formik initialValues={data} onSubmit={onPublish} enableReinitialize={true}>
                {({values, isSubmitting, setFieldValue}) => {
                    return (
                        <Form>
                            <StyledCalendarOverlay id="compose-card" className={`card is-new-content ${true ? 'is-highlighted': ''}`}>
                                <div className="tabs-wrapper">
                                    <div className="tabs is-boxed is-fullwidth">
                                        <ul>
                                            <li className="is-active">
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-content">
                                        <div className="compose">
                                            <div className="compose-form">
                                                <img src="https://via.placeholder.com/300x300" data-demo-src="assets/img/avatars/jenna.png"
                                                     alt="" style={{display: 'none'}}/>
                                                <i className="mdi mdi-format-text"></i>
                                                <div className="control">
                                    <Field
                                        as="textarea"
                                        id="publish"
                                        className="textarea"
                                        rows="3"
                                        onFocus={onFocus}
                                        placeholder={t('jobs.title')}
                                        name="title" />
                                                </div>
                                            </div>
                                            <hr />

                                            <div className="compose-form">
                                                <i className="mdi mdi-human"></i>
                                                <div className="control">
                                                    <div className="field field-group">
                                                    <label>{t('event.attendees')}</label>
                                                        <div className="control">
                                                            <Multiselect
                                                                emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                                onSelect={ onSelect }
                                                                onRemove={ onRemove }
                                                                selectedValues={select}
                                                                placeholder={t('overlay.select')}
                                                                options={users|| []}
                                                                displayValue={`displayName`}
                                                                style={{width: '70%'}}></Multiselect>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <hr />
                                            <div className="compose-form">
                                                <i className="mdi mdi-clock"></i>
                                                <div className={windowWidth < 800 ? "" : "b-control"}>
                                                    <InputDate
                                                        showTimeSelect
                                                       
                                                        name="startDate"
                                                        label={t('jobs.startDate')}
                                                        dateFormat="MMMM d, yyyy hh:mm aa"
                                                        locale={langChoice === 'fr' ? 'fr': 'en-US'}
                                                    />
                                                    <InputDate
                                                        showTimeSelect
                                                        locale={langChoice === 'fr' ? 'fr': 'en-US'}
                                                        name="endDate"
                                                        label={t('jobs.endDate')}
                                                        dateFormat="MMMM d, yyyy hh:mm aa"
                                                        minDate={new Date(values.startDate)}
                                                        maxDate={new Date(values.startDate)}
                                                        minTime={setHours(
                                                            setMinutes(new Date(values.startDate), getMinutes(new Date(values.startDate))),
                                                            getHours(new Date(values.startDate))
                                                        )}
                                                        maxTime={setHours(new Date(values.startDate), 23)}
                                                    />
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="compose-form">
                                                <i className="mdi mdi-text"></i>
                                                <div className="control">
                                    <Field
                                        as="textarea"
                                        id="publish"
                                        className="textarea"
                                        rows="3"
                                        onFocus={onFocus}
                                        placeholder={t('feed.writeacomment')}
                                        onChange={(event) => {
                                            setFieldValue("desc", JSON.parse(JSON.stringify(event.target.value)))
                                            
                                        }}
                                        name="desc"
                                         />
                                                </div>
                                            </div>
                                            <div id="options-summary" className="options-summary"></div>


                                        </div>
                                        {/* code was removed and put in the bottom */}

                                        <div className="more-wrap">

                                            <button id="show-compose-friends" type="button" className="button is-more" aria-haspopup="true" style={{display: 'none'}}>
                                                <i data-feather="more-vertical"></i>
                                                <span>View More</span>
                                            </button>
                                            { highlighted ?
                                                <button onClick={onPublish} id="publish-button" type="button"
                                                        className="button is-solid accent-button is-fullwidth">
                                                    Publish
                                                </button>:
                                                <button  id="publish-button" type="submit"
                                                         className="button is-solid accent-button is-fullwidth"
                                                         disabled={isSubmitting}
                                                >
                                                    {t('general.savebasic')}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </StyledCalendarOverlay>

                        </Form>
                    )
                }}

            </Formik>

        </ModalWrapper>
    )
};

const Dropdown = ({selected, setSelected, clock}) => {
    let [active, setActive] = useState(false)

    return (

        <div className={`dropdown ${active ? 'is-active': ''}`} style={{margin: '0px 15px' +
                ''}}>
            <div className="dropdown-trigger">
                <div className="button" aria-haspopup="true" onClick={()=> setActive(!active)}
                        aria-controls="dropdown-menu">
                    <span>{selected}</span>
                    <span className="icon is-small">
        <i className="fas fa-angle-down" aria-hidden="true"></i>
      </span>
                </div>
            </div>
            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content" style={{height: 300, overflow:'scroll'}}>
                    {clock.map((c, index) => {
                        return (
                            <a  className="dropdown-item" onClick={()=> {
                                setSelected(c)
                                setActive(!active)
                            }
                            } key={index}>
                                {c.format('HH:mm')}
                            </a>
                        )
                    })}

                </div>
            </div>
        </div>

    )

}

/*
 <div className="hidden-options is-opened">
                                        <div className="target-channels">
                                            <div className="channel">
                                                <div className="round-checkbox is-small">
                                                    <div>
                                                        <input type="checkbox" id="checkbox-1" checked="" />
                                                        <label htmlFor="checkbox-1"></label>
                                                    </div>
                                                </div>
                                                <div className="channel-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                         strokeLinejoin="round" className="feather feather-bell">
                                                        <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                                        <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                                    </svg>
                                                </div>
                                                <div className="channel-name">Activity Feed</div>

                                                <div className="dropdown is-spaced is-modern is-right is-neutral dropdown-trigger">
                                                    <div>
                                                        <button className="button" aria-haspopup="true">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                                 strokeLinecap="round" strokeLinejoin="round"
                                                                 className="feather feather-smile main-icon">
                                                                <circle cx="12" cy="12" r="10"></circle>
                                                                <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                                                <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                                                <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                                            </svg>
                                                            <span>Friends</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                                 strokeLinecap="round" strokeLinejoin="round"
                                                                 className="feather feather-chevron-down caret">
                                                                <polyline points="6 9 12 15 18 9"></polyline>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <div className="dropdown-menu" role="menu">
                                                        <div className="dropdown-content">
                                                            <a >
                                                                <div className="media">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                                         className="feather feather-globe">
                                                                        <circle cx="12" cy="12" r="10"></circle>
                                                                        <line x1="2" y1="12" x2="22" y2="12"></line>
                                                                        <path
                                                                            d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                                                                    </svg>
                                                                    <div className="media-content">
                                                                        <h3>Public</h3>
                                                                        <small>Anyone can see this publication.</small>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a className="dropdown-item">
                                                                <div className="media">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                                         className="feather feather-users">
                                                                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                                        <circle cx="9" cy="7" r="4"></circle>
                                                                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                                                    </svg>
                                                                    <div className="media-content">
                                                                        <h3>Friends</h3>
                                                                        <small>only friends can see this publication.</small>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <a className="dropdown-item">
                                                                <div className="media">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                                         className="feather feather-user">
                                                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                                        <circle cx="12" cy="7" r="4"></circle>
                                                                    </svg>
                                                                    <div className="media-content">
                                                                        <h3>Specific friends</h3>
                                                                        <small>Don't show it to some friends.</small>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <hr className="dropdown-divider" />
                                                            <a className="dropdown-item">
                                                                <div className="media">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                         strokeWidth="2" strokeLinecap="round"
                                                                         strokeLinejoin="round" className="feather feather-lock">
                                                                        <rect x="3" y="11" width="18" height="11" rx="2"
                                                                              ry="2"></rect>
                                                                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                                    </svg>
                                                                    <div className="media-content">
                                                                        <h3>Only me</h3>
                                                                        <small>Only me can see this publication.</small>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
 */
