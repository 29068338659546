import React from "react"
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {Navigation, Pagination, Autoplay} from 'swiper/core';
import 'swiper/swiper-bundle.css';
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"

SwiperCore.use([Navigation, Pagination, Autoplay]);

const GalleryContainer = styled.div`
.swiper-container {
    width: 100%;
    height: 100%;
    border-radius:8px;

    .swiper-button-next, .swiper-button-prev{
        opacity:0;
        transition: all 0.35s ease-out;
        color: var(--bazookka-blue);
  
        &.swiper-button-disabled{
            display:none;
        }
    }

    &:hover{
        .swiper-button-next, .swiper-button-prev{
            opacity:1;
        }
    }
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }  

  .swiper-pagination-bullet-active{
      background:var(--bazookka-blue);
  }
`

export default function ImageGallery({images}){
    return (
        <GalleryContainer>
            <Swiper 
                navigation={true} 
                pagination={true}
                spaceBetween={30}
                autoplay={{
                    "delay": 7500,
                    "disableOnInteraction": false
                }} 
                className="event-gallery"
            >
                {images.map((image, index) => <SwiperSlide key={`gallery-${index}`}><img src={image} alt={`gallery-${index}`} /></SwiperSlide>)}
            </Swiper>
        </GalleryContainer>
      )
}
