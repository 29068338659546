import styled from "styled-components";


export const GridF3x1 = styled.div`
  display: grid;
  min-height: auto;
  grid-gap: 1rem;
  grid-template-columns: 250px minmax(350px, auto);
  @media screen and (max-width: 900px) {
    grid-template-columns: minmax(350px, 950px);
  }
`
