import React, { useState, useContext, useEffect } from "react";
import {groupsDoc, postsDoc} from "../firestore/firestoreService";
import { MyContext } from "../stores";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import EditJobForm from '../forms/EditJobForm';
import { usePromise } from "../helper";
import { Visibility as EyeIcon } from "@mui/icons-material";
import Box from '@mui/material/Box';
import {useHistory} from "react-router-dom";
import Swal from 'sweetalert2';
import {ShareBig} from "../components/Share";
import axios from "axios";
import useSWR from "swr";

const allGroups  = {
    groupChat: "false",
    id: "all",
    name: "public",
}

const StyleJobCrud = styled.div`
  margin: 1rem 0px;
  align-items: center;
  transition: all .3s;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  background: white;
  
  .template {
    width: 100%;
    display: grid;
    grid-template-columns: 50px auto 160px;
    align-items: center;
  }
  .flex-block-meta {
    margin-left: 10px;
  }
  .div-button {
    background-color: var(--bazookka-green);
    cursor: pointer;
    width: 42px;
    padding: 0px 5px !important;
    margin: 0px 5px !important;
    float: left;
    height: 42px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.25));
  }
  .titletext {
    display: flex;
    padding-left: 1rem;
    align-items: center;
  }
  .go-button {
    cursor: pointer;
    background-color: var(--bazookka-blue);
    width: 42px;
    padding: 0px 5px !important;
    margin: 0px 5px !important;
    float: left;
    height: 42px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    opacity: 1;
    box-shadow: 0 14px 26px -12px rgb(85 150 230 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(85 150 230 / 20%) !important;

  }
  :hover .go-button {
    transform: rotate(360deg);
    transition: all .3s;
  }`

export const LineBreak = styled.div`
    width:100%;
    clear:both;
  `

export const Heading = styled.div`
    flex-wrap: wrap;
    transition: all 0.35s ease-out;
`

export const FormContainer = styled.div`
    width:100%;
    margin-top:30px;
    padding:5px;
`

const JobTitle = styled.div`
padding-left:5px;
`


export default function JobCrud() {
    const { t } = useTranslation()
    const [apicall, setApicall] = useState(false)
    const [creating, setCreating] = useState(false)
    const [jobs, setJobs] = useState([])
    const { me, globalId, organizationId, globalIdSql } = useContext(MyContext)

    const [groups, groupError, groupLoad ] = usePromise(async ()=> {
        let doc= await groupsDoc.where('admin', 'array-contains-any', [globalId]).get()
        return doc.docs.map( d => Object.assign({id: d.id, ...d.data()}))
    }, [globalId])

    const {data:orgGroups, error, mutation} = useSWR(organizationId ? `https://api.bazookka.com/groups/school/${organizationId}` : null, url =>
    axios.get(url).then( r => {
        return r.data}))

    const {data:adminJobs, mutate:mutateAdminJob} = useSWR(`https://api.bazookka.com/posts/job/admin/${globalIdSql}`, url =>
    axios.get(url).then( r => {
        return r.data}))

    return (
        <div className="posts-wrapper">
            <Heading id="profile-timeline-posts" className="box-heading">
                <h4>{t('general.jobs')}</h4>
                {creating ?
                    <div className="button-wrap">
                        <a className="button is-active"
                            onClick={(e) => {
                                e.preventDefault()
                                setCreating(!creating);
                            }}
                        >{t('general.cancel')}</a>
                    </div> :
                    <div className="button-wrap">
                        <a className="button is-active"
                            onClick={(e) => {
                                e.preventDefault()
                                setCreating(!creating);
                            }}
                        >{t('jobs.create')}</a>
                    </div>
                }
                <LineBreak />
                {creating &&
                    <FormContainer>
                        <EditJobForm setOpen={setCreating} groups={[allGroups, ...orgGroups]} mutateAdminJob={mutateAdminJob} isNew={true}/>
                    </FormContainer>
                }
            </Heading>

            {adminJobs && typeof adminJobs.map === 'function' && adminJobs.map((currJob, key) => {
                return <JobCrudCard setOpen={setCreating} mutateAdminJob={mutateAdminJob} key={key} job={currJob} groups={orgGroups && [...orgGroups]}/>
            })}
            
        </div>
    )
}

const JobCrudCard = ({setOpen, mutateAdminJob, job, groups}) => {
    const [editing, setEditing] = useState(false)
    const history = useHistory()
    const {t} = useTranslation()

    const onDelete = ( elem ) => {
        Swal.fire({
            title: t('settings.jobs.deletionConfirm'),
            showDenyButton: true,
            confirmButtonText: t(`settings.groups.confirmButtonLabel`),
            denyButtonText: t(`settings.groups.cancelButtonLabel`),
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                axios.delete(`https://api.bazookka.com/posts/job/${elem.id}`).then(() => {
                    Swal.fire(t("settings.jobs.delete"))
                    setOpen(false)
                    mutateAdminJob()
                   })
            } else if (result.isDenied) {
                Swal.fire(t('settings.groups.denyLabel'))
            }
        })
    }

    return (
        <StyleJobCrud>
            <div className="template">
                <div>
                    <ShareBig share={job} size={'40px'}/>
                </div>

                <JobTitle>{job.title}</JobTitle>
                <Box sx={{ display: 'flex' }}>
                    {editing ?
                        <div className="go-button float-left" onClick={(e) => {
                            setEditing(false)
                        }}>
                            <i style={{fontSize : "20px"}} className="mdi mdi-close"></i>
                        </div> :
                        <div className="go-button float-left" onClick={(e) => {
                            e.preventDefault()
                            setEditing(!editing);
                        }}>
                            <i style={{fontSize : "20px"}} className="mdi mdi-pencil"></i>
                        </div>
                    }

                    <div className="go-button float-left" onClick={()=> {
                        onDelete(job)
                    }}>
                        <i style={{fontSize : "20px"}} className="mdi mdi-delete"></i>
                    </div>

                    <div className="go-button float-left" onClick={() =>  history.push(`/jobs/${job.id}`)}>
                        <EyeIcon sx={{fontSize : "20px"}} />
                    </div>
                </Box>
            </div>
            {editing &&
                <FormContainer>
                    <EditJobForm setOpen={setEditing} mutateAdminJob={mutateAdminJob} job={job ? job : []} groups={[allGroups, ...groups]}/>
                </FormContainer>
            }
        </StyleJobCrud>
    )
}
