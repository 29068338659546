import React, {useState, useEffect, useContext} from 'react'
import {firebaseInstance} from "../firestore/firestoreService";
import ModalWrapper from "./index";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import {MyContext} from "../stores";
import {userRoles} from "../stores/enums/userRoles";
import {NiceSelect} from "../components/FormElements";
import Loader from "../components/Loader";

export default function EditUserRoleModal({user}) {
    const {t} = useTranslation()
    const {dispatchModal} = useContext(MyContext)
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({
        role: ""
    });

    useEffect(() => {
        setIsLoading(true);
        if(user && user.id){
            const getUserRoleById = firebaseInstance().functions().httpsCallable('GetUserRoleById');
            getUserRoleById({uid: user.id}).then((response) => {
                if(user.select.id && response.data[user.select.id]){
                    setInitialValues({role: response.data[user.select.id]});
                    setIsLoading(false);
                }
            });
        }
    }, [user])

    const onSubmit = async (values) => {
        const updateGroupsUsers = firebaseInstance().functions().httpsCallable('UpdateUserRole');
        updateGroupsUsers({uid: user.id, selectId: user.select.id, newRole: values.role}).then(() => {
            dispatchModal({type: 'CLOSE_MODAL'});
        });
    }

    if(isLoading){
        return (<ModalWrapper>
                    <Loader />
                </ModalWrapper>);
    }

    return (
        <ModalWrapper>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({values, isSubmitting}) => {
                    return (
                        <Form>
                            <NiceSelect label={t('general.role')} name="role">
                            <option value="">{t('general.selecttype')}</option>
                            {
                                Object.entries(userRoles).map( option => {
                                    return <option key={option[0]} value={option[1]}>{option[1]}</option>
                                })
                            }

                            </NiceSelect>
                            <div>
                                <button type="submit" className="button">{t('general.save')}</button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </ModalWrapper>
    )
}