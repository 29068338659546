import React, {useEffect, useState, useContext} from "react";
import {MyContext} from "../stores";
import {firebaseInstance, postsDoc} from '../firestore/firestoreService';
import styled from 'styled-components';

const LikeWrapper = styled.div`

    &:hover{
        .like-button{
            transition: all .3s;
            transform: scale(1.1);
        }

        i{
            transition: all .3s;
            font-size:20px;
        }

        .like-count{
            transition: all .3s;
            opacity:0;
            height:0px;
        }
    }
`

const LikeCountWrapper = styled.div`
    color:black;
`

const LikeButtonContainer = styled.a`
    flex-direction: column;
`

const LikeButton = ({postId, likes = []}) => {
    const {globalId} = useContext(MyContext);
    const [isLiked, setIsLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    //This Css class sound contradictory because of the core theme.
    //not-liked means liked in this case.
    const [isLikedClass, setIsLikedClass] = useState("");

    useEffect(() => {
        setIsLiked(likes.includes(globalId));
        setLikeCount(likes.length);
        const tempLikedClass = isLiked ? "not-liked" : "";
        setIsLikedClass(tempLikedClass);
    }, [globalId, likes, isLiked])

    const handleClick = () => {
        if(isLiked){
            //Removing Like
            postsDoc.doc(postId).update({
                likedBy: firebaseInstance().firestore.FieldValue.arrayRemove(globalId)
            });
            setIsLiked(false);
        }else{
            //Adding Like
            postsDoc.doc(postId).update({
                likedBy: firebaseInstance().firestore.FieldValue.arrayUnion(globalId)
            });
            setIsLiked(true);
        }
    }

    return (
        <LikeWrapper className="like-wrapper">
            <LikeButtonContainer className="like-button" onClick={handleClick}>
                <div>
                    <i className={`mdi mdi-heart bouncy ${isLikedClass}`}></i>
                    <span className="like-overlay"></span>
                </div>
                <LikeCountWrapper className="like-count">{likeCount}</LikeCountWrapper>
            </LikeButtonContainer>
        </LikeWrapper>
    )
}

export default LikeButton;
