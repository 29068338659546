import * as React from 'react';
import PropTypes from 'prop-types';
import Rating from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon style={{fontSize: '3rem'}}/>,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon style={{fontSize: '3rem'}}/>,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon style={{fontSize: '3rem'}}/>,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon style={{fontSize: '3rem'}}/>,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon style={{fontSize: '3rem'}} />,
        label: 'Very Satisfied',
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other} >{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired
};

export default function({...all}) {
    return (
        <Rating
            name="highlight-selected-only"
            defaultValue={2}
            IconContainerComponent={IconContainer}
            highlightSelectedOnly
            {...all}
        />
    );
}
