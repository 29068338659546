import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from "../stores";
import { notificationsDocs } from "../firestore/firestoreService";
import styled from 'styled-components';
import { formatDistance } from "date-fns";
import { fr } from 'date-fns/locale';
import Loader from "./Loader";
import { MenuItem } from '@mui/material';
import { Grid } from '@mui/material';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotificationsContainer = styled.div`
    min-width:100%;
    min-height:100%;
    border: 1px solid var(--bazookka-white);
    box-shadow:0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    user-select: none;
    max-height:770px;
    overflow-y:scroll;

    a:last-child div{
        border-bottom:0
    }
`


const NotificationContainer = styled.div`
    padding:10px;
    border-bottom:1px solid var(--bazookka-white);
    background-color:var(--bazookka-blue);
    width: 1500px;
    color:var(--bazookka-white);
    cursor:pointer;

    &:read{
        
    }

    i{
        font-size:30px;
        margin-right:10px;
        vertical-align:middle;
    }

    &:hover{
        background-color:var(--bazookka-white);
        color:var(--bazookka-blue);

        i{
            color:var(--bazookka-blue) !important;
        }
    }
`

const NotificationText = styled.div`
    display:inline-block;
    vertical-align:middle;
    width:190px;
    word-wrap: break-word;
    margin-right:10px;
`

const NotificationDate = styled.span`
    display:inline-block;
    margin:auto;
    margin-right:10px;
    width:50px;
    vertical-align:middle;
    font-size:12px;
`

const NotificationBadge = styled.span`
    display:inline-block;
    width:17px;
    margin-right:10px;

    i{
        line-height:50px;
        vertical-align:middle;
        font-size:16px;
        color:var(--bazookka-pink) !important;
    }
`

const NotificationNumber = styled.span`
    display:inline-block;
    width:30px;
    margin:auto;
    font-size:12px;
    vertical-align:middle;
`

const EmptyMessage = styled.div`
    background-color:var(--bazookka-blue);
    color:var(--bazookka-white);
    height:72px;
    width:100%;
    vertical-align:middle;

    span{
        font-size:18px;
        position:absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        margin:auto;
        display:block;
        height:fit-content;
        width:fit-content;

        i{
            margin-right:10px;
        }
    }
`

function NotificationList({globalId, closeNotfications}) {
    const {langChoice, notifications, setNotifications} = useContext(MyContext)
    const {t} = useTranslation();
    useEffect(()=> {
        notificationsDocs.doc(globalId).collection('notifications').where('read', '==', false).orderBy('creationDate', 'asc')
            .onSnapshot(snap => {
                setNotifications(snap.docs.map(doc => {
                    return { id: doc.id, ...doc.data() }
                }))
            })
    }, [globalId])

    const handleUnreadClick = (id, status) => {
        if (!status) {
            notificationsDocs.doc(globalId).collection('notifications').doc(id).update({
                read: true,
                readDate: Date.now()
            });
        }
    }

    return (
        <NotificationsContainer>
            {notifications && notifications.length === '' &&
                <Loader />
            }
            {notifications && notifications.length === 0 &&
                <EmptyMessage>
                    <span><i className="mdi mdi-inbox"></i>{t('general.emptyNotifications')}</span>
                </EmptyMessage>
            }
            {notifications && notifications.length > 0 && notifications.map( (notification, i) => {
                    const notifCircle = notification.read ? 'mdi-circle-outline' : 'mdi-circle';
                    return (
                        <Link  to={notification.link} key={i}>
                        <MenuItem style={{whiteSpace: 'normal'}}>
                        <NotificationContainer key={i} onClick={() => {
                            closeNotfications()
                            handleUnreadClick(notifications[i].id, notifications[i].read)}}>
                        <Grid container direction="row">
                        <Grid  item xs={6}>
                        <i className={`mdi ${selectIco(notification.type)}`}></i>
                            <NotificationText>{notification.text}</NotificationText>
                        </Grid>

                        <NotificationDate>{formatDistance(notification.creationDate, new Date(), { addSuffix: true, locale: langChoice === 'fr'? fr : null })}</NotificationDate>

                        <NotificationBadge><i className={`mdi ${notifCircle}`}></i></NotificationBadge>

                        <NotificationNumber>({notification.count})</NotificationNumber>
                        </Grid>
                        </NotificationContainer>
                      </MenuItem>
                      </Link>
                    )
                })
            }
        </NotificationsContainer>
    )
}

const selectIco = (value) => {
    switch (value) {
        case 'message':
            return 'mdi-comment-account'
        case 'myinternship-message':
        case 'myinternship-attachment':
            return 'mdi-book'
        case 'comment':
            return 'mdi-message'
        case 'applicant':
            return 'mdi-account-plus'
        case 'important-message':
            return 'mdi-alert-octagon'
        case 'appointment':
            return 'mdi-calendar'
        case 'custom-form':
            return 'mdi-card-bulleted-settings-outline'
        default:
        return ""
    }
}

export default NotificationList
