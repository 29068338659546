import React, {useState, useContext, useEffect} from 'react'
import {MyContext} from "../stores";
import {Link, useParams, useHistory} from 'react-router-dom'
import {db, groupsDoc, usersDoc} from "../firestore/firestoreService";
import Avatar from 'react-avatar'
import {useTranslation} from "react-i18next";
import styled from 'styled-components'
import EditGroups from "../pages/EditGroups";
import { useAlert } from 'react-alert';
import axios from 'axios';
import EditGroupsMigration from '../migration/pages/EditGroupsMegration';


const Students = ({students}) => {
    return (
        <div className="followers">
            {students.students && students.students.map( (student, i) => {
                return (
                    <a key={i} className="follower">
                        <Avatar name={`${student.displayName}`} round={true} style={{zIndex: 1, width: '100%', height: '100%'}} alt={student.displayName}/>
                    </a>
                )
            } )}

        </div>
    )
}

export const ClassRoom = () => {
    const {t} = useTranslation()
    const {dispatchModal, me} = useContext(MyContext)
    const [apicall, setApicall] = useState(false)
    const [groups, setGroups] = useState([])
    const [groupListSql, setGroupListSql] = useState([])
    const [group, setGroup] = useState(null)
    const [register, setRegister] = useState([])
    const {id} = useParams()
    const history = useHistory()


    const removeGroup = (removedGroupId) => {
        setGroups(groups.filter(el => el.id != removedGroupId))
    }
    function displayGroup(groups){
        return (
            <div className="posts-wrapper" style={{marginTop : "7vw"}}>
                <div id="profile-timeline-posts" className="box-heading">
                    <h4>{t('general.communicationgroups')}</h4>
                    <div className="button-wrap">
                        <Link className="button is-active" to={`/groups/create`}
                        >{t('general.groupcreate')}</Link>
                    </div>

                </div>
                {groupListSql.map( (grp, key) => <ClassRoomCrud removeGroup={removeGroup}  group={grp} key={key}/> )}
            </div>
        )
    }

    const getGroupListSql = async () => {
            const { data } = await axios.get(`https://api.bazookka.com/groups/school/${me.schoolId[0]}`)
            console.log(`https://api.bazookka.com/groups/school/${me.schoolId[0]}`)
            console.log("data ",data)
           setGroupListSql(data)
        }


    useEffect(() => {
        getGroupListSql()
},[me,groups])


    useEffect(()=> {
        if(!apicall && me && !id) {
            groupsDoc.where('school', '==', me.select.id).get()
                .then( data => {
                    setApicall(true)
                    setGroups(data.docs.map( d => {
                        return {id:d.id, ...d.data()}
                    }))

                })
        }
        if(id !== "create") {

        }
        if(id && !apicall && id !=="create") {
            groupsDoc.doc(id).get().then( doc => {
                setGroup({id, ...doc.data()})
            })
            usersDoc.where('groups', 'array-contains', id)
                .onSnapshot( docs => {
                    setRegister(docs.docs.map( doc => {
                        return {id: doc.id, ...doc.data()}
                    }))
                    setApicall(true)
                })
        }


    }, [apicall, me,groups])
    if(!id) {
        return displayGroup(groups)
    }else {
        return (
            <EditGroupsMigration />
        )
    }
}

const StyleClassRoom = styled.div`
  height: 58px;
  margin: 1rem 0px;
  display: flex;
  align-items: center;
  transition: all .3s;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  background: white;
  img {
    width: 42px;
    height: 42px;
    border-radius: 50%;

  }
  .template {
    width: 100%;
    display: grid;
    grid-template-columns: 50px auto 150px;
  }
  .flex-block-meta {
    margin-left: 10px;
  }
  .div-button {
    background-color: var(--bazookka-green);
    cursor: pointer;
    width: 42px;
    padding: 0px 5px !important;
    margin: 0px 5px !important;
    float: left;
    height: 42px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.25));
  }
  .titletext {
    display: flex;
    padding-left: 1rem;
    align-items: center;
  }
  .go-button {
    cursor: pointer;
    background-color: var(--bazookka-blue);
    width: 42px;
    padding: 0px 5px !important;
    margin: 0px 5px !important;
    float: left;
    height: 42px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    opacity: 0;
    box-shadow: 0 14px 26px -12px rgb(85 150 230 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(85 150 230 / 20%) !important;

  }
  :hover .go-button {
    opacity: 1;
    transform: rotate(360deg);
    transition: all .3s;
  }
`

const ClassRoomCrud = ({group,removeGroup,deletionAlert}) => {
    const {dispatchModal} = useContext(MyContext)
    const {t} = useTranslation()
    const alert = useAlert()

    return (
        <StyleClassRoom>
            <div className="template">
                <div>
                    <div className="div-button">
                        <i className="mdi mdi-google-classroom"></i>
                    </div>

                </div>
                <div className="titletext">{group.name}</div>
                <div>
                    <Link className="go-button float-left" to={`/migration/groups/${group.groupid}`}

                    >
                        <i className="mdi mdi-pencil"></i>
                    </Link>
                    {!group.noDelete &&
                    (<div className="go-button float-left" onClick={() => { 
                        groupsDoc.doc(group.id).delete().then(() => {
                            removeGroup(group.id)
                        }).catch((error) => {
                            alert.error(t('settings.groups.deleteFailure'))
                        })
                        alert.success(t('settings.groups.delete'))
                        console.log("delete clicked")
                    
                    }
                         }>
                        <i className="mdi mdi-delete"></i>
                    </div>)
                    }

                </div>
            </div>
        </StyleClassRoom>
    )
}

export const ClassRoom1 = () => {
    const [classes, setClass] = useState(null)
    const {globalId, me} = useContext(MyContext)
    const {t} = useTranslation()
    const setTheClass = data => {
        return classes.reduce((acc, current) => {
            if(current.id === data) {
                acc.push(current)
            }
            return acc;
        }, [])
    }
    useEffect( ()=> {
       if(me) {
            let ref = db.collection(`schools/${me.select.id}/classroom`)
            ref.get().then( s=> {
                setClass(s.docs.map( s => {
                    let temp = s.data()
                    temp.id = s.id;
                    return temp;
                }))
            }).catch(e => console.log('cls',e))
       }
    }, [me])
    return (
        <div id="education-content" className={`content-section is-active`}>
            <div id="education-glider" className="slider-timeline about-glider">
                {/*{me && me.role !== 'student' && (*/}
                {/*    <CreateClassRoom />*/}
                {/*)}*/}

                <div className="community-content">
                    {me && me.classrooms && me.classrooms.map( (cls,key) => {
                        return (
                            <div key={key} className="community-info">
                                <div className="page-followers">
                                    <div className="header">
                                        <h3>{t('general.classroom')}</h3>
                                        <h6>{cls.name}</h6>
                                    </div>

                                    {classes &&
                                        setTheClass(cls.id).map(room => <Students key={room.id} students={room} />)}
                                    {me && me.role !== 'student' && (
                                        <div className="more-followers">
                                            <a>{t('general.edit')}</a><a>{t('general.delete')}</a>
                                        </div>
                                    ) }

                                </div>
                            </div>
                        )
                    })}
                    {me && me.role !== 'student' && (
                        <div className="community-widgets">
                            <div className="page-counts">
                                <div className="like-count">
                                    <span>3</span>
                                    <span>classes</span>
                                </div>
                                <div className="follow-count">
                                    <span>45</span>
                                    <span>{t('overlay.students')}</span>
                                </div>
                            </div>

                            <div className="page-invites">
                                <div className="header">
                                    <h3>{t('general.inviteyourstudents')}</h3>
                                    <div className="control">
                                        <input type="text" className="input" placeholder={`${t('general.search')}...`} />
                                        <div className="search-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-search">
                                                <circle cx="11" cy="11" r="8"></circle>
                                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="suggestions">
                                    <div className="suggestion">
                                        <img src="assets/old/wallpaper.png"
                                             data-demo-src="assets/img/avatars/aline.jpg" data-user-popover="16" alt=""
                                             data-target="webuiPopover21"/>
                                        <div className="meta">
                                            <span>Aline</span>
                                        </div>
                                        <a className="button">{t('general.invite')}</a>
                                    </div>
                                    <div className="suggestion">
                                        <img src="assets/old/wallpaper.png"
                                             data-demo-src="assets/img/avatars/bobby.jpg" data-user-popover="8" alt=""/>
                                        <div className="meta">
                                            <span>Bobby</span>
                                        </div>
                                        <a className="button">{t('general.invite')}</a>
                                    </div>
                                    <div className="suggestion">
                                        <img src="assets/old/wallpaper.png"
                                             data-demo-src="assets/img/avatars/rolf.jpg" data-user-popover="13" alt=""/>
                                        <div className="meta">
                                            <span>Rolf</span>
                                        </div>
                                        <a className="button">{t('general.invite')}</a>
                                    </div>
                                    <div className="suggestion">
                                        <img src="assets/old/wallpaper.png"
                                             data-demo-src="assets/img/avatars/stella.jpg" data-user-popover="2" alt=""/>
                                        <div className="meta">
                                            <span>Stella</span>
                                        </div>
                                        <a className="button">{t('general.invite')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    )
}

const CreateClassRoom = () => {
    const [highlighted, setHighlighted] = useState(false)
    const {setAppOver} = useContext(MyContext)
    const onFocus = evt => {
        setHighlighted(true)
        setAppOver(true)
    }

    const closeCreate = evt => {
        setHighlighted(false)
        setAppOver(false)
    }
    return (
        <div id="compose-card" className={`card is-new-content ${highlighted ? 'is-highlighted':''}`}>

            <div className="tabs-wrapper">
                <div className="tabs is-boxed is-fullwidth">
                    <ul>
                        <li className="is-active">
                            <a>
                                <span className="icon is-small"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none"
                                                                     stroke="currentColor" strokeWidth="2"
                                                                     strokeLinecap="round" strokeLinejoin="round"
                                                                     className="feather feather-edit-3"><path
                                    d="M12 20h9"></path><path
                                    d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg></span>
                                <span>Create</span>
                            </a>
                        </li>
                        <li className="close-wrap" onClick={closeCreate}>
                                                <span className="close-publish">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-x"><line x1="18" y1="6" x2="6"
                                                                                             y2="18"></line><line x1="6"
                                                                                                                  y1="6"
                                                                                                                  x2="18"
                                                                                                                  y2="18"></line></svg>
                                                </span>
                        </li>
                    </ul>
                </div>


                <div className="tab-content">

                    <div className="compose">
                        <div className="compose-form">
                            <div className="control">
                                <textarea id="publish" className="textarea" rows="3"
                                          placeholder="Named the new classroom" onFocus={onFocus}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="hidden-options">
                        <div className="target-channels">

                            <div className="channel">
                                <div className="round-checkbox is-small">
                                    <div>
                                        <input type="checkbox" id="checkbox-1" checked="" />
                                            <label htmlFor="checkbox-1"></label>
                                    </div>
                                </div>
                                <div className="channel-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-bell">
                                        <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                        <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                    </svg>
                                </div>
                                <div className="channel-name">Activity Feed</div>

                                <div className="dropdown is-spaced is-modern is-right is-neutral dropdown-trigger">
                                    <div>
                                        <button className="button" aria-haspopup="true">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-smile main-icon">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                                <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                                <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                            </svg>
                                            <span>students</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-chevron-down caret">
                                                <polyline points="6 9 12 15 18 9"></polyline>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="dropdown-menu" role="menu">
                                        <div className="dropdown-content">
                                            <a >
                                                <div className="media">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-globe">
                                                        <circle cx="12" cy="12" r="10"></circle>
                                                        <line x1="2" y1="12" x2="22" y2="12"></line>
                                                        <path
                                                            d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                                                    </svg>
                                                    <div className="media-content">
                                                        <h3>Public</h3>
                                                        <small>Anyone can see this publication.</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item">
                                                <div className="media">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-users">
                                                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                        <circle cx="9" cy="7" r="4"></circle>
                                                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                                    </svg>
                                                    <div className="media-content">
                                                        <h3>students</h3>
                                                        <small>only students can see this publication.</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item">
                                                <div className="media">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-user">
                                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                        <circle cx="12" cy="7" r="4"></circle>
                                                    </svg>
                                                    <div className="media-content">
                                                        <h3>Specific students</h3>
                                                        <small>Don't show it to some students.</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <hr className="dropdown-divider" />
                                                <a className="dropdown-item">
                                                    <div className="media">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                             viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                             strokeWidth="2" strokeLinecap="round"
                                                             strokeLinejoin="round" className="feather feather-lock">
                                                            <rect x="3" y="11" width="18" height="11" rx="2"
                                                                  ry="2"></rect>
                                                            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                        </svg>
                                                        <div className="media-content">
                                                            <h3>Only me</h3>
                                                            <small>Only me can see this publication.</small>
                                                        </div>
                                                    </div>
                                                </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="channel">
                                <div className="round-checkbox is-small">
                                    <div>
                                        <input type="checkbox" id="checkbox-2" />
                                            <label htmlFor="checkbox-2"></label>
                                    </div>
                                </div>
                                <div className="story-icon">
                                    <div className="plus-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-plus">
                                            <line x1="12" y1="5" x2="12" y2="19"></line>
                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                        </svg>
                                    </div>
                                </div>

                                <div className="channel-name">My Story</div>

                                <div className="dropdown is-spaced is-modern is-right is-neutral dropdown-trigger">
                                    <div>
                                        <button className="button" aria-haspopup="true">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-smile main-icon">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                                                <line x1="9" y1="9" x2="9.01" y2="9"></line>
                                                <line x1="15" y1="9" x2="15.01" y2="9"></line>
                                            </svg>
                                            <span>students</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                                 strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-chevron-down caret">
                                                <polyline points="6 9 12 15 18 9"></polyline>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="dropdown-menu" role="menu">
                                        <div className="dropdown-content">
                                            <a >
                                                <div className="media">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-globe">
                                                        <circle cx="12" cy="12" r="10"></circle>
                                                        <line x1="2" y1="12" x2="22" y2="12"></line>
                                                        <path
                                                            d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                                                    </svg>
                                                    <div className="media-content">
                                                        <h3>Public</h3>
                                                        <small>Anyone can see this publication.</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item">
                                                <div className="media">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-users">
                                                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                        <circle cx="9" cy="7" r="4"></circle>
                                                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                                    </svg>
                                                    <div className="media-content">
                                                        <h3>students</h3>
                                                        <small>only students can see this publication.</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item">
                                                <div className="media">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="feather feather-users">
                                                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                        <circle cx="9" cy="7" r="4"></circle>
                                                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                                    </svg>
                                                    <div className="media-content">
                                                        <h3>students and contacts</h3>
                                                        <small>Your students and contacts.</small>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="friends-list is-hidden">

                            <div className="list-header">
                                <span>Send in a message</span>
                                <div className="actions">
                                    <a id="open-compose-search" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="feather feather-search">
                                            <circle cx="11" cy="11" r="8"></circle>
                                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                        </svg>
                                    </a>

                                    <div id="compose-search" className="control is-hidden">
                                        <input type="text" className="input" placeholder="Search People" />
                                                            <span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                     height="24" viewBox="0 0 24 24" fill="none"
                                                                     stroke="currentColor" strokeWidth="2"
                                                                     strokeLinecap="round" strokeLinejoin="round"
                                                                     className="feather feather-search"><circle cx="11"
                                                                                                                cy="11"
                                                                                                                r="8"></circle><line
                                                                    x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                                            </span>
                                    </div>
                                    <a
                                       data-modal="create-group-modal">Create group</a>
                                </div>
                            </div>

                            <div className="list-body">


                                <div className="friend-block">
                                    <div className="round-checkbox is-small">
                                        <div>
                                            <input type="checkbox" id="checkbox-3" />
                                                <label htmlFor="checkbox-3"></label>
                                        </div>
                                    </div>
                                    <img className="friend-avatar" src="https://via.placeholder.com/300x300"
                                         data-demo-src="assets/img/avatars/dan.jpg" alt="" />
                                        <div className="friend-name">Dan Walker</div>
                                </div>

                                <div className="friend-block">
                                    <div className="round-checkbox is-small">
                                        <div>
                                            <input type="checkbox" id="checkbox-4" />
                                                <label htmlFor="checkbox-4"></label>
                                        </div>
                                    </div>
                                    <img className="friend-avatar" src="https://via.placeholder.com/300x300"
                                         data-demo-src="assets/img/avatars/daniel.jpg" alt="" />
                                        <div className="friend-name">Daniel Wellington</div>
                                </div>

                                <div className="friend-block">
                                    <div className="round-checkbox is-small">
                                        <div>
                                            <input type="checkbox" id="checkbox-5" />
                                                <label htmlFor="checkbox-5"></label>
                                        </div>
                                    </div>
                                    <img className="friend-avatar" src="https://via.placeholder.com/300x300"
                                         data-demo-src="assets/img/avatars/stella.jpg" alt="" />
                                        <div className="friend-name">Stella Bergmann</div>
                                </div>

                                <div className="friend-block">
                                    <div className="round-checkbox is-small">
                                        <div>
                                            <input type="checkbox" id="checkbox-6" />
                                                <label htmlFor="checkbox-6"></label>
                                        </div>
                                    </div>
                                    <img className="friend-avatar" src="https://via.placeholder.com/300x300"
                                         data-demo-src="assets/img/avatars/david.jpg" alt="" />
                                        <div className="friend-name">David Kim</div>
                                </div>

                                <div className="friend-block">
                                    <div className="round-checkbox is-small">
                                        <div>
                                            <input type="checkbox" id="checkbox-7" />
                                                <label htmlFor="checkbox-7"></label>
                                        </div>
                                    </div>
                                    <img className="friend-avatar" src="https://via.placeholder.com/300x300"
                                         data-demo-src="assets/img/avatars/nelly.png" alt="" />
                                        <div className="friend-name">Nelly Schwartz</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="more-wrap">

                        <button id="show-compose-friends" type="button" className="button is-more" aria-haspopup="true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-more-vertical">
                                <circle cx="12" cy="12" r="1"></circle>
                                <circle cx="12" cy="5" r="1"></circle>
                                <circle cx="12" cy="19" r="1"></circle>
                            </svg>
                            <span>View More</span>
                        </button>

                        <button id="publish-button" type="button"
                                className="button is-solid accent-button is-fullwidth is-disabled">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
