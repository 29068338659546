import React, {useEffect, useContext, useState} from "react";
import {Link, useParams} from 'react-router-dom'
import { SearchBar } from "../components/SearchBar";
import useFirestore from "../firestore/useFirestore";
import {users, jobPosts, postsDoc} from "../firestore/firestoreService";
import Avatar from "react-avatar";
import {MyContext} from "../stores";
import {JobPostCard} from '../components/JobPostCard'
import FormTemplate from "../components/MultiForm";
import AppliedList from "./AppliedList";

const Internship = props => {
  return (
      <div>internship</div>
  )
}

const SubCompanySection = ({me, langChoice}) => {
  const [jobPost,setJobPost]=useState([])
  useEffect(()=>{
    postsDoc.where('businessId','array-contains',me.select.id).get().then(data=>{
        setJobPost(data.docs.map(d=>Object.assign({id: d.id, ...d.data()})))
    }).catch(e => console.log(e))
},[])

  return (
    <div className="posts-wrapper">
      <div>
        <h2>Our job post</h2>
      </div>
      <SearchBar />
      <div style={{paddingTop: 15}}>
        <Link to={`/${langChoice}/dashboard/createJob`} className="button is-primary">Add new</Link>
      </div>
      <div className="columns is-multiline" style={{ marginTop: 25 }}>
        <div className="section is-news">
          <div className="news-grid">
            {jobPost &&
            jobPost.map((doc, key) => (
                <JobPostCard key={key} data={doc} type={`owner`}/>
            ))}
          </div>
        </div>

      </div>
    </div>
  );
};

const BusinessesProfile = () => {
    const {me, langChoice} = useContext(MyContext)
    const {page, job} = useParams()
    const [businessUsers, setBusinessUsers] = useState([])
    const [job_select, setJob] = useState(null)
    useEffect(()=>{
      if(me && me.select) {
        users.where('businessId', 'array-contains', me.select.id).get().then(data => {
          setBusinessUsers(data.docs.map(d => d.data()))
        }).catch(e => console.log(e))
      }

    },[me])
  useEffect(()=> {
    if(job) {

      postsDoc.doc(job).onSnapshot( result => {
        setJob({...result.data(), id:job})
      })

    }
  }, [job])
  return (
    <div className="view-wrapper">
      <div className="feed-profile-wrapper">
        <div className="profile-wrapper">
          <div className="profile-avatar-wrapper">
            <div className="avatar-wrap">
              <Avatar name={me && me.displayName} size={58} round={true}/>
              <div className="badge">
              </div>
            </div>
          </div>
          <h2 style={{ textAlign: "center" }}>McGill</h2>
          <div className="user-stats">
            <div className="stat-block">
              <span>518</span>
              <span>Posts</span>
            </div>
            <div className="stat-block">
              <span>220k</span>
              <span>Applications</span>
            </div>
            <div className="stat-block">
              <span>197</span>
              <span>Enrolls</span>
            </div>
          </div>
          <div className="button-wrap">
            <Link to={`/${langChoice}/dashboard/bob`} className="button is-solid secondary-button is-fullwidth raised">
              Active job posts
            </Link>
          </div>
          <div className="button-wrap">
            <button className="button is-solid secondary-button is-fullwidth raised">
              Active internship posts
            </button>
          </div>
          <div className="button-wrap">
            <button className="button is-solid secondary-button is-fullwidth raised">
              Company documents
            </button>
          </div>
          <div className="user-bio">
            <h4>{me && me.displayName}</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Iam enim
              adesse poterit. Quis est tam dissimile homini. At ille pellit, qui
              permulcet sensum voluptate.
            </p>
          </div>

          <div className="connections">
              {businessUsers.map((f, idx)=>{
                return (
                    <a className="connection" key={idx}>
                    <div className="connection-avatar" >
                    <Avatar name={`${f && f.displayName}`}  size={42} round={true} style={{zIndex: 1}}/>
                    </div>
                  </a>
                  )
              })}
          </div>

          <div className="more-connections">
            <a>View More</a>
          </div>
        </div>
        {/** next code represent the grid of sny stuff **/}
        {!page && me && <SubCompanySection me={me} langChoice={langChoice}/>}
        {/** end next code represent the grid of sny stuff **/}
        {page === 'editJob' && job && job_select && <FormTemplate data={job_select}/>}
        {page === 'createJob' &&  <FormTemplate me={me}/>}
        {page === 'applications' &&  <AppliedList me={me} job={job}/>}
      </div>
    </div>
  );
};

export default BusinessesProfile;
