import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import styled from 'styled-components'
import {MyContext} from "../../stores";


const AccountTypeStyled = styled.div`
  width: var(--bazookka-desktop-size);
  margin: auto;
  display: grid;
  grid-gap: 1rem;
  justify-content: center;
  //grid: repeat(auto-fill, minmax(250px, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  div {
    max-width: 300px;
    margin: auto;
  }
  
`
const general_account_roles = {
    student: 'seeker',
    business: 'admin_company',
    school: 'admin_school'
};

const AccountType = ({updateStep, setAcccountRole, handleToken, accountRole}) => {
    const {t} = useTranslation()
    return (
        <AccountTypeStyled>
            <div className="account-type">

                <h3>{t('general.business')}</h3>
                <a
                    className="button is-fullwidth is-rounded process-button"

                    onClick={() => {
                        updateStep(2);
                        setAcccountRole('cmp.business');
                    }}>
                    {t('general.continue')}
                </a>
            </div>
        </AccountTypeStyled>
    )
}

const AccountTypes = ({updateStep, setAcccountRole, handleToken, accountRole}) =>  {
    const {t} = useTranslation()
    return(
        <div>
            <div style={{ textAlign: 'center' }} class="column">
                { handleToken !== false ? <p className="error">
                    You need an invitation in order to create an account.<br />For more informations, please <a href="/contact">contact us</a>.
                </p> : '' }
                { handleToken === 1 ? <p className="error">Bad token</p> : '' }
                { handleToken === 2 ? <p className="error">Token not found</p> : '' }
                { handleToken === 3 ? <div>
                    <p>Invitation already accepted!</p>
                    <Link id="signup-finish" class="button is-rounded" to={`/login`}>Sign-In</Link>
                </div> : '' }
            </div>
            {handleToken !== 3 && (
                <div className="columns" >

                    <div className="column is-6" style={{}}>
                        <div className="account-type">
                            <img className="light-image" src="assets/img/illustrations/signup/public.svg" alt="" />
                            <img className="dark-image" src="assets/img/illustrations/signup/public-dark.svg" alt="" />
                            <h3>{t('general.school')}</h3>
                            <p>Create a School account to be able to do some awesome things.</p>
                            <a
                                className="button is-fullwidth is-rounded process-button"
                                style={{ pointerEvents: accountRole === 'sch.business'? 'none': 'auto' }}
                                onClick={() => {
                                    updateStep(2);
                                    setAcccountRole(accountRole);
                                }}>
                                {t('general.continue')}
                            </a>
                        </div>
                    </div>
                    <div className="column is-6">
                        <div className="account-type">
                            <img className="light-image" src="assets/img/illustrations/signup/company.svg" alt="" />
                            <img className="dark-image" src="assets/img/illustrations/signup/company-dark.svg" alt="" />
                            <h3>{t('general.business')}</h3>
                            <p>Create a Business account to be able to do some awesome things.</p>
                            <a
                                className="button is-fullwidth is-rounded process-button"
                                style={{ pointerEvents: accountRole !== 'sch.business'? 'none': 'auto' }}
                                onClick={() => {
                                    updateStep(2);
                                    setAcccountRole(accountRole);
                                }}>
                                {t('general.continue')}
                            </a>
                        </div>
                    </div>
                    <div className="column is-6">
                        <div className="account-type">
                            <img className="light-image" src="assets/img/illustrations/signup/personal.svg" alt="" />
                            <img className="dark-image" src="assets/img/illustrations/signup/personal-dark.svg" alt="" />
                            <h3>{t('general.student')}</h3>
                            <p>Create a Student account to be able to do some awesome things.</p>
                            <a
                                className="button is-fullwidth is-rounded process-button"
                                style={{ pointerEvents: accountRole === 'sch.business'? 'none': 'auto' }}
                                onClick={() => {
                                    updateStep(2);
                                    setAcccountRole(accountRole);
                                }}>
                                {t('general.continue')}
                            </a>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default AccountType
