import React, {useContext, useState, useEffect} from "react";
import {MyContext} from "../../stores";
import {useTranslation} from "react-i18next";
import Routes from "../../config/routes";
import {NavLink} from "react-router-dom";
import styled from "styled-components";
import {getUserRole} from "../../helper/acl";

const MenuItem = styled.div`
    filter: drop-shadow(0 0.1rem 0 rgba(0,0,0,0.5));
    background-color: var(--bazookka-blue) !important;
    color: white !important; 

    :hover {
        background-color: var(--bazookka-green) !important;
        margin-left:3px;
    }
    
    span {
        color: white !important;
    }
    
    i {
        color: white !important;
    }
    
    .active {
        background: var(--bazookka-pink) !important;
    }

    &.sub-item{
        margin-left:20px;

        &:hover{
            margin-left:23px;
        }
    }
`
const Anchor = styled.div`
    .active div {
        background: var(--bazookka-pink) !important;
    }
`

const FeedMenuItem = ({me, locale}) => {
    const {t} = useTranslation()
    const [userRole, setUserRole] = useState(null)

    useEffect(() => {
        if(me && me.select)
        getUserRole(me.select.id).then((role) => {
            setUserRole(role);
        });
    }, [me])

    return (
        <React.Fragment>
            {
                me && userRole && Routes.filter(bylink => {
                    if(bylink.locales && bylink.roles) {
                        if(bylink.locales.includes(locale) && bylink.roles.includes(userRole)) {
                            return true
                        }
                        return false
                    }
                    return false;
                })
                    .map( (routeItem, key) => {
                        const itemClasses = routeItem.subItem ? 'menu-icon': 'menu-icon';
                        return (
                            <Anchor className={`menu-item`} data-content="feeds-content" key={key}>
                                <NavLink

                                to={routeItem.path}
                                isActive={(match, location)=> {
                                    if(match && match.isExact) {
                                        return true
                                    }
                                    return false
                                }}
                                >
                                    <MenuItem className={itemClasses}>
                                        {routeItem.ico}
                                        <span>{t(routeItem.label)}</span>
                                    </MenuItem>
                                </NavLink>
                            </Anchor>

                        )
                    })
            }
        </React.Fragment>
    )
}

export default function InternalLeftMenu({update, homeNav, locale}) {
    const {langChoice, me} = useContext(MyContext)
    return (
        <div className="left-menu">
            <div className="left-menu-inner">
                <FeedMenuItem me={me} locale={locale}></FeedMenuItem>
            </div>
        </div>
    )
}
