import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { useAlert } from "react-alert";
import { db, firestore } from "../../firestore/firestoreService";
import { FormContainer, Heading, LineBreak } from "../../pages/Applications";
import { MyContext } from "../../stores";
import AttachmentList from "../AttachmentList";
import FileUpload from "../FileUpload";
import { ComposeText } from "../FormElements";
import { ErrorContainer } from "../FormManager/ErrorContainer";
import {Multiselect} from "multiselect-react-dropdown";
import * as Yup from 'yup'

function CreatePost({t, groups}) {
    const { me, globalId } = useContext(MyContext);
    const [modalAttachments, setModalAttachments] = useState([]);
    const [fileUploadStatus, setFileUploadStatus] = useState("idle");
    const [fileDestination, setFileDestination] = useState("");
    const [creating, setCreating] =useState(false)
    const [createdDoc, setCreatedDoc] = useState("");
    const alert = useAlert()
    const composeoptions = {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        transition: "all .3s",
        color: "#888da8",
        borderRadius: 4,
        backgroundColor: "#f7f7f7",
        padding: "6px 16px",
        position: "relative",
    };
    const span = {
        padding: "0 8px",
    };
    const initialValues = {
        message: "",
        users: [],
        files: [],
        groups:[],
        type:'post'
    };
    const handleModalUpload = (fileRef, metadata) => {
        fileRef.getDownloadURL().then((url) => {
            const newAttachment = {
                name: metadata.name,
                path: url,
                type: metadata.contentType,
                timestamp: Date.now(),
            };
            db.collection("posts")
                .doc(createdDoc)
                .update({
                    attachments: firestore.FieldValue.arrayUnion(newAttachment),
                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                });
        });
    };

    const handleDelete = (toDelete) => {
        setModalAttachments((prevState) => {
            const newState = prevState.filter(function (att) {
                return att !== toDelete;
            });
            return newState;
        });
    };

    const handleQueueComplete = () => {
        //Reset all the states and Close the modal
        setFileUploadStatus("idle");
        setModalAttachments([]);
        setFileDestination("");
        setCreatedDoc("");

    };

    const onSubmit = (values, { setSubmitting }) => {

        db.collection("posts")
            .add({
                author: { id: globalId, displayName: me.displayName },
                text: values.message,
                type: values.type,
                groups: firestore.FieldValue.arrayUnion(...values.groups.map(g => g.id)),
                date: new Date().getTime(),
                authorIds: globalId,
                hasAttachment: modalAttachments.length > 0,
            })
            .then((r) => {
                setSubmitting(false);
                alert.success(`${t("general.mesinvstu")} ${r.id}`);
                setCreatedDoc(r.id);
                setFileDestination(`posts_attachments/${r.id}/${globalId}/`);
                setFileUploadStatus("uploading");
                setCreating(false)
            });
    };

    return (
        <div className="posts-wrapper">
            <Heading id="profile-timeline-posts" className="box-heading">
                <h4>{t('general.globalmessage')}</h4>
                {creating ?
                    <div className="button-wrap">
                        <div  className="button is-active"
                            onClick={(e) => {
                                e.preventDefault()
                                setCreating(!creating);
                            }}
                        >{t('general.cancel')}</div>
                    </div> :
                    <div className="button-wrap">
                        <div  className="button is-active"
                            onClick={(e) => {
                                e.preventDefault()
                                setCreating(!creating);
                            }}
                        >{t('general.messagecreate')}</div>
                    </div>}
                    <LineBreak />
                {creating &&
                <FormContainer>
                    <Formik initialValues={initialValues} onSubmit={onSubmit}
                     validationSchema={Yup.object({
                         message: Yup.string().required(t('validation.required')),
                         groups: Yup.array().min(1).required(t('validation.musthaveonegroupe'))
                     })}
                    >
                    {({isSubmitting, setFieldValue, values}) => {
                        return (
                            <Form>
                                <div className="settings-form-wrapper">
                                    <div className="settings-form">
                                        <div className="field">
                                            <ComposeText name="message"/>
                                            <ErrorContainer component="div" name={`message`} />
                                        </div>
                                        <div className="field">
                                            <div
                                                id="extended-options"
                                                className="compose-options"
                                                style={composeoptions}
                                            >
                                                <div
                                                    className="columns is-multiline is-full"
                                                    style={{width: "100%"}}
                                                >
                                                    <div className="column is-12 is-narrower">
                                                        <div className="compose-option is-centered">
                                                            <FileUpload
                                                                dest={fileDestination}
                                                                onUpload={handleModalUpload}
                                                                queueStatus={fileUploadStatus}
                                                                queue={modalAttachments}
                                                                setQueue={setModalAttachments}
                                                                onQueueComplete={handleQueueComplete}
                                                            >
                                                                <svg
                                                                    style={{
                                                                        height: 20,
                                                                        width: 20,
                                                                        verticalAlign: "sub",
                                                                    }}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    className="feather feather-camera"
                                                                >
                                                                    <path
                                                                        d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
                                                                    <circle cx="12" cy="13" r="4"></circle>
                                                                </svg>
                                                                <span style={span}>{t("upload.addDocsMessage")}</span>
                                                            </FileUpload>
                                                            <div>
                                                                <AttachmentList
                                                                    attachments={modalAttachments}
                                                                    metas={["name"]}
                                                                    onDelete={handleDelete}
                                                                    isHorizontal
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <div className="hidden-options">
                                                <label>{t('general.whoshouldseethepost')}</label>
                                                <div className="target-channels">
                                                    <Multiselect
                                                        options={groups}
                                                        displayValue={'name'}
                                                        selectedValues={values.groups}
                                                        placeholder={t("overlay.select")}
                                                        emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                        name={'groups'}
                                                        onSelect={v => {
                                                            setFieldValue('groups', v)
                                                        }}
                                                    />
                                                    <ErrorContainer component="div" name={`groups`} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <button
                                                type="submit"
                                                className="button is-solid primary-button is-fullwidth"
                                                disabled={isSubmitting}
                                            >
                                                {t('general.publish')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
                </FormContainer>
                }

            </Heading>
        </div>
    )
}

export default CreatePost