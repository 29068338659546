import React, {createContext, useContext, useReducer, useState} from 'react'
import firebase from '../config/firebase'
import { usersDoc, schoolsDoc } from '../firestore/firestoreService'
import { schoolsReducer } from "./schools";
import { useModalReducer } from './modals/ModalReducer'
import useRolesReducer from "./roles";
import * as files from './files'
import * as MyDocs from './myDoc'
import { InMemoryCache, ApolloProvider, ApolloClient, ApolloLink, gql } from '@apollo/client';
import { WebSocketLink } from "@apollo/client/link/ws";
import fr from './language-ressources/fr.json'
import en from "./language-ressources/en.json";
import fakeSchools from '../faker/schools.json'
import fakeUsers from '../faker/users.json'
import fakeBusinesses from '../faker/businesses.json'
import fakeBlogpost from '../faker/blogPost.json'
import fakeMessagepost from '../faker/messagePost.json'
import fakejobpost from '../faker/jobPost.json'
import fakeinternshipPost from '../faker/internshipPost.json'
import fakerappointment from '../faker/appointments.json'
import fakerEvents from '../faker/events.json'
import { registerLocale } from "react-datepicker";
import * as locales from 'date-fns/locale'
import { useTranslation } from "react-i18next";
import i18next from 'i18next'
import { useCatReducer } from "./categories";
import { userState } from "./enums/userPresence";
import axios from 'axios';
import io from 'socket.io-client'



registerLocale('fr', locales.frCA)
registerLocale('en', locales.enCA)

export const MyContext = createContext();


export const UserContext = createContext({
    user: {
        name: 'Steve Alliance',
        email: 'steve.alliance@gmail.com',
    }
})


const UserReducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'get_user':
            return {
                ...state,
                user: payload
            };
        default:
            return state;
    }
}

export const MyUserProvider = props => {
    let initialState = {
        user: {
            name: 'Steve Alliance',
            email: 'steve.alliance@gmail.com',

        }
    }

    const [state, dispatch] = useReducer(UserReducer, initialState)

    return (
        <UserContext.Provider value={{ user: state.user }}>
            {props.children}
        </UserContext.Provider>
    )
}

export const MyProvider = props => {

    const { i18n } = useTranslation("common");

    const GET_MESSAGES = gql`
         subscription {
             messages {
                 id
                 content
                 user
             }
         }
     `;

    const POST_MESSAGE = gql`
         mutation($user: String!, $content: String!) {
             postMessage(user: $user, content: $content)
         }
     `;
    const generateBzkDef = () => {
        let arrayBzkDef = []
        Object.keys(i18next.services.resourceStore.data).forEach((language) =>
            language === i18next.language
                ? arrayBzkDef.push({
                    name: languageNameFormatter(language),
                    small: language,
                    select: true,
                })
                : arrayBzkDef.push({
                    name: languageNameFormatter(language),
                    small: language,
                    select: false,
                })
        );
        return arrayBzkDef;
    }

    const languageNameFormatter = (lang) => {
        switch (lang) {
            case "fr":
                return "Français";
            case "en":
                return "English";
            default:
                return lang.toUpperCase();
        }
    }

    let state = {
        me: null,
        // me:  {
        //     name: 'Steve Alliance',
        //     firstname: 'Steve',
        //     lastname: 'Alliance',
        //     email: 'steve.alliance@gmail.com',
        //     profiles: [
        //         {
        //             name: 'Rosemont',
        //             rules: 'student',
        //             selected: false
        //         },
        //         {
        //             name: 'Bazookka',
        //             rules: 'interns',
        //             selected: false
        //         }
        //     ]
        //
        // },
        isLogIn: false,
        time: 0,
        selected: i18next.language,
        langLabels: localStorage.getItem('bzk_def') ? JSON.parse(localStorage.getItem('bzk_def')) : generateBzkDef()
    }

    let [me, setMe] = useState(state.me)
    let [predicate, setPredicate] = useState(new Map([
        ['notifications', false],
        ['lang', false],
        ['profile', false],
        ['bigdrop', false]
    ]))
    const handlePredicate = (key, value) => {
        setPredicate(new Map(value))
    }
    const { modal, dispatchModal } = useModalReducer(null)
    const setAllFalse = () => {
        setPredicate(new Map([
            ['notifications', false],
            ['lang', false],
            ['profile', false],
            ['bigdrop', false]
        ]))
    }
    let [content, setContent] = useState([{
        type: 'paragraph',
        children: [{ text: 'A line of text in a paragraph.' }],
    },])
    let [rolesValue, dispatchRoles] = useRolesReducer([])
    let [globalId, setId] = useState(null)
    let [globalIdSql, setIdSql] = useState(null)
    let [organizationId,setOrganizationId] = useState()
    let [categories, dispatchCategories] = useCatReducer(null)
    let [currentUser, setCurrentUser] = useState(null)
    let [appOver, setAppOver] = useState(false)
    let [appAddUsers, setAppAddUsers] = useState(false)
    let [appAddMUsers, setAppAddMUsers] = useState(false)
    let [appEditCalendar, setAppEditCalendar] = useState(false)
    let [langChoice, setLangChoice] = useState(state.selected)
    let [i10nLoadContent,setI10nLoadContent] = useState({ test : "test"})
    let [inviteUser, setInvite] = useState(false)
    let [showLangWidget, setLangWidget] = useState(false)
    let [footer, setFooter] = useState(true)
    let [langLabels, setLangLabels] = useState(state.langLabels)
    let [schools, setSchools] = useReducer(schoolsReducer, [])
    let [users, setUsers] = useState(fakeUsers)
    let [building, setBuilding] = useState(null)
    let [businesses, setBusinesses] = useState(fakeBusinesses)
    let [blogs, setBlog] = useState(fakeBlogpost)
    let [messagePost, setMessagePost] = useState(fakeMessagepost)
    let [jobPost, setJobPost] = useState(fakejobpost)
    let [internshipPost, setInternshipPost] = useState(fakeinternshipPost)
    let [appointments, setAppointments] = useState(fakerappointment)
    let [events, setEvents] = useState(fakerEvents)
    let [maincontact, setMaincontact] = useState(null)
    let [notifications, setNotifications] = useState(null)
    let [isMobileView, setIsMobileView] = useState(true)
    let [isDrawerOpen, setIsDrawerOpen] = useState(true)
    let [fullDrawer,setFullDrawer] = useState(true)
    let [scope,setScope] = useState(null)
    let [freeze, setFreeze] = useState(true)
    let [aremove, setaRemove] = useState([])
    let [uremove, setuRemove] = useState([])
    let [homeNav, setHomeNav] = useState({
        feeds: true,
        educations: false,
        jobs: false,
        calendar: false,
        classroom: false
    })
    let [settingsNav, setSettingsNav] = useState({
        general: true,
        security: false,
        account: false

    })
    const setters = {
        setHomeNav,
        setSettingsNav
    }
    const states = {
        homeNav,
        settingsNav
    }

    const updateHomeNav = (value, state, setter) => {

        let temp = {}
        for (let k in states[state]) {
            if (k === value) {
                temp[k] = true
            } else {
                temp[k] = false
            }
        }
        setters[setter](temp);
    }
    const updateStateLanguage = evt => {

        let newValue = langLabels.map(l => {
            if (l.small === evt) {
                l.select = true
                return l
            }
            l.select = false
            return l;
        })
        i18n.changeLanguage(evt);
        setLangLabels(newValue)
        setLangChoice(evt)
        localStorage.setItem('bzk_lang', evt)
        localStorage.setItem('bzk_def', JSON.stringify(newValue))
    }

    const setUserPresence = (userId) => {
        const userStatusDatabaseRef = firebase.database().ref('/status/' + userId);
        const isOfflineForDatabase = {
            state: userState.OFFLINE,
            last_changed: firebase.database.ServerValue.TIMESTAMP,
        };
        const isOnlineForDatabase = {
            state: userState.ONLINE,
            last_changed: firebase.database.ServerValue.TIMESTAMP,
        };

        // Create a reference to the special '.info/connected' path in
        // Realtime Database. This path returns `true` when connected
        // and `false` when disconnected.
        firebase.database().ref('.info/connected').on('value', function (snapshot) {
            // If we're not currently connected, don't do anything.
            if (snapshot.val() === false) {
                return;
            };

            // If we are currently connected, then use the 'onDisconnect()'
            // method to add a set which will only trigger once this
            // client has disconnected by closing the app,
            // losing internet, or any other means.
            userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
                userStatusDatabaseRef.set(isOnlineForDatabase);
            });
        });
    }

    /**
     * this function set a user main state to know about change
     * regarding personal information and making sure token get refresh
     * @returns {firebase.Unsubscribe}
     */
    const verifyAuth = () => {
        return firebase.auth().onAuthStateChanged(user => {
            console.log("ca rentre verify auth")

            if (user) {
                setUserPresence(user.uid);
                (async () => {
                    let u = await usersDoc.doc(user.uid).get()
                    const userInfoSql = await axios.get(`https://api.bazookka.com/users/u/${user.uid}`)
                    console.log("org id ",userInfoSql.data.organisation_id)
                    setIdSql(userInfoSql.data.id)
                    setOrganizationId(userInfoSql.data.organisation_id)
                    if (!globalId) {
                        userOnChange(user.uid, user)
                        if (JSON.stringify(u.data()) !== JSON.stringify(me)) {
                            setMe(u.data())
                        }
                        if (user.uid !== globalId) {
                            setId(user.uid)
                            
                        }
                      
                 
                    }
                })()
            } else {
                setMe(null)
                setId(null)
            
            }
        })
    }
    const checkPredicateActive = pr => {
        let isTrue = true

        return isTrue
    }
    const userOnChange = (uid, user) => {
        setCurrentUser(user)
        usersDoc.doc(uid).onSnapshot(function (doc) {
            if (JSON.stringify(doc.data()) !== JSON.stringify(me)) {
                setMe(doc.data())
            }
        })
    }




     const logout = async (history) => {
         await firebase.auth().signOut()
         history.push('/')
     }

    const documentClick = () => {

        // document.addEventListener('click', function (e) {
        // const target = e.target;
        // console.log('near', target.closest('.drop-trigger'))
        // if (!target.closest('.drop-trigger') && checkPredicateActive(predicate)) {
        //     setAllFalse()
        // }
        //     if (!$(target).is('.custom-select') && !$(target).parents().is('.control')) {
        //         $('.custom-select').removeClass('is-active');
        //     }
        // });
        // })
    }

    let socket



    return (

        <MyContext.Provider
            value={{
                socket,
                freeze,
                setFreeze,
                scope,
                setScope,
                aremove,
                setaRemove,
                uremove, setuRemove,
                rolesValue, dispatchRoles,
                categories,
                dispatchCategories,
                maincontact,
                setMaincontact,
                notifications,
                setNotifications,
                isMobileView,
                setIsMobileView,
                isDrawerOpen,
                setIsDrawerOpen,
                fullDrawer,
                setFullDrawer,
                modal,
                dispatchModal,
                locales,
                MyDocs,
                files,
                POST_MESSAGE,
                GET_MESSAGES,
                building,
                setBuilding,
                content,
                setContent,
                currentUser,
                setCurrentUser,
                globalIdSql,
                i10nLoadContent,
                setI10nLoadContent,
                organizationId,
                setOrganizationId,
                documentClick, setAllFalse, predicate, handlePredicate, globalId, verifyAuth, logout, events, appointments, internshipPost, jobPost, blogs, messagePost, updateHomeNav, appAddMUsers, setAppAddMUsers, appAddUsers, setAppAddUsers, appEditCalendar, setAppEditCalendar, appOver, setAppOver, settingsNav, homeNav, users, businesses, schools, setSchools, me, setMe, langChoice, inviteUser, langLabels, setInvite, footer, setFooter, showLangWidget, setLangWidget, state, updateStateLanguage
            }}>
            {props.children}
        </MyContext.Provider>
    )

}

export function useMyProvider(){
    return useContext(MyContext)
}

export default MyProvider
