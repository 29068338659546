import styled from "styled-components";

const FieldSetStyled = styled.fieldset`
font-size: 1.5em;
text-align: center;
border: 2px solid var(--bazookka-blue);
border-radius: 8px;
text-align: center;
padding: 10px; 
margin-bottom : 2em;
display: flex;
flex-direction: column;  
  
`;

export default FieldSetStyled