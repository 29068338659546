import React, {useContext, useEffect, useState} from 'react'
import {firestore, jobPosts, postsDoc} from "../firestore/firestoreService";
import Avatar from "react-avatar";


export default function AppliedList({job}) {
    const [appli, setAppli] = useState([])
    useEffect(()=> {
        postsDoc.doc(job).collection('applications')
            .onSnapshot(snaps => {
                setAppli( snaps.docs.map( doc => doc.data()))
            })
    }, [job])
    return (
        <div className="column is-6">
            <h1>Applications</h1>
            <div style={{padding: 20}}></div>
            <div className="stats-wrapper">
                {appli.map( (applied,key) => {
                    return (
                        <div key={key} className="stats-header">
                            <div className="avatar-wrapper">
                                <Avatar name={applied.displayName} round={true} size={80}/>

                            </div>
                            <div className="user-info">
                                <h4>{applied.displayName}</h4>
                            </div>
                            <div className="main-stats">
                                <div className="stat-block">
                                    <a className="button is-follow">View</a>
                                </div>
                                <div className="stat-block">
                                    <a className="button is-follow">Select</a>
                                </div>
                            </div>
                        </div>
                    )
                }
                )
                }

            </div>
        </div>
    )
}
