import React, {useContext} from 'react';
import {MyContext} from "../stores";
import {useSubscription} from "@apollo/client";

const Businesses = props => {
    const {me, GET_MESSAGES} = useContext(MyContext)
    const {data} = useSubscription(GET_MESSAGES)

    return (
        <div style={{marginTop: 150}}>
            <h1>Business page</h1>
        </div>

    )
}

export default Businesses
