import React, {useEffect} from "react"
import {Route, Switch, useParams, Link, useRouteMatch} from "react-router-dom"
import tw from "tailwind-styled-components"
import styled from 'styled-components'
import TableComponent from "../components/Table";
import {GridComponent, ColumnDirective, ColumnsDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, Inject} from "@syncfusion/ej2-react-grids";

import useSWR from 'swr'

const DisplayAbsolute = tw.div`
  flex fixed z-10 top-0 w-full h-full bg-slate-50 left-0 pt-20 justify-center overflow-y-auto secondary-dark-bg
`

const InnerContent = tw.div`
   flex absolute width:600px h-full secondary-dark-bg
`

const CallableLink = (props) => {
    const {path} = useRouteMatch()
    const onSubmit = () => {
        alert(JSON.stringify(props))
    }
    return (
        <Link to={`${path}/edit/${props.id}`} className="bg-blue-700 p-2 rounded-l font-white" onClick={onSubmit}>
            color
        </Link>
    )
}

const companyGrid = [
    {
        headerText: 'Name',
        field: 'name',
        width: '200',
    },
    {
        headerText: 'Type',
        field: 'type',
        width: '200',
    },
    {
        headerText: 'Users',
        field: 'users',
        width: '200',
        textAlign: 'center'
    },
    {
        headerText: 'Edit',
        width: '200',
        template: CallableLink
    }
]


const fetcher = (url) => fetch(url).then((res) => res.json());
export default () => {
    const {data, error, mutate} = useSWR(`https://api.bazookka.com/organizations/GetUsersPerAccount`, fetcher)
    const {path, url} = useRouteMatch()
    console.log(path, url)
    function convertCol(data){
        let cols = ['name', 'type', 'users'];
        return cols
    }
    if(error) return <div>ERROR</div>
    if(!data) return <div>LOADING</div>

    return (
        <>
            <TableComponent
                mutate={mutate}
                fetcher={fetcher}
                columns={convertCol(data)}
                data={data}
                action={true}
                searchLink={`https://api.bazookka.com/organizations/GetUsersPerAccount?q=`}
                actionButons={[{edit: path}]}
                title={'List of clients schools and businesses'}
            />
            <GridComponent
                id="gridcomp"
                dataSource={data}
                allowPaging
                allowSorting
                contextMenuItems={['AutoFit']}
            >
                <ColumnsDirective
                >
                    {companyGrid.map((item, index) => {
                        return <ColumnDirective
                            key={index}
                            {...item}
                        />
                    })}
                </ColumnsDirective>
                <Inject services={[Resize, Sort]} />
            </GridComponent>
            <Switch>
                <Route path={`${path}/edit/:id`}>
                    <DisplayAbsolute>
                        <InnerContent>
                            <div>Edit</div>
                        </InnerContent>

                    </DisplayAbsolute>

                </Route>
                <Route path={`${path}/new`} component={() => <div className="flex">new</div>} exact></Route>
            </Switch>

        </>
    )

}