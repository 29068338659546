import { CircularProgress, Tab, Tabs } from "@mui/material"
import { useState } from "react"
import { groupsDoc } from "../../firestore/firestoreService"
import { usePromise } from "../../helper"
import {
    Work as WorkIcon,
    Event as EventIcon,
    Create as  PencilIcon
} from '@mui/icons-material'
import CreatePost from "./CreatePost"
import JobInternship from "./JobInternship"
import EventCreate from "./EventCreate"
import { Box } from "@mui/system"
import TabPanel from "./TabPanel"

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TopForms = ({t, globalId, panels}) => {
    const [groups, groupError, groupLoad ] = usePromise(async ()=> {
        let doc= await groupsDoc.where('admin', 'array-contains-any', [globalId]).get()
        return doc.docs.map( d => Object.assign({id: d.id, ...d.data()}))
    }, [globalId])

    const allGroup = {
        groupChat: "false",
        id: "all",
        name: "public",
    }

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        console.log("newValue ",newValue)
        setValue(newValue);
    };

    if(groupLoad){
        return <CircularProgress />
    }
    
    const panelList = {
        'message': {
            'label': t('general.globalmessage'),
            'icon': <PencilIcon />,
            'component': <CreatePost  t={t} groups={[allGroup, ...groups]}></CreatePost>,
        },
        'job': {
            'label': t('general.joborintern'),
            'icon': <WorkIcon />,
            'component': <JobInternship t={t} groups={[allGroup ,...groups]}></JobInternship>,
        },
        'event': {
            'label': t('event'),
            'icon': <EventIcon />,
            'component': <EventCreate t={t} groups={[allGroup ,...groups]} />,
        },
    }

    if(panels){
        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: '#d3d3d3' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {panels.map((panel, i) => {
                        return <Tab key={i} label={panelList[panel].label} {...a11yProps(i)} icon={panelList[panel].icon}/>
                    })}
                    </Tabs>
                </Box>
                {panels.map((panel, i) => {
                    return (
                        <TabPanel value={value} index={i} key={i}>
                            {panelList[panel].component}
                        </TabPanel>
                    )
                })}
            </Box>
        );
    }else{
        return <CircularProgress />
    }
}

export default TopForms