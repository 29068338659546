import React, {useContext, useEffect, useState} from 'react';
import InvitationForm from "./SignUp/InvitationForm";
import {MyContext} from "../stores";
import {db} from "../firestore/firestoreService";
import {Applicants, InviteCrud} from './Applications'
import {Trans} from "react-i18next";

const InviteUsers = props => {
    const {me} = useContext(MyContext)
    const [apicall, setApicall] = useState(false)
    const [invites, setInvite] = useState([])
    useEffect(()=>{
        if(me && me.select && !apicall) {
            db.collection("createAccountInvitations")
                .where('schoolId', 'array-contains-any', [me.select.id])
                .where('acceptedAt', '==', null)
                .onSnapshot( doc => {
                    setInvite(doc.docs.map( data => {
                        return {id: data.id, ...data.data().meta, ...data.data()}
                    }))
                    setApicall(true)
                })
        }
    },[apicall, me])

    return (
        <React.Fragment>
            <InvitationForm />
            <h1><Trans>general.pendingconfirmation</Trans></h1>
            {invites.map( (invite, key)=> {
                return <InviteCrud key={key} invite={invite} type={'invite'} />
            })}

        </React.Fragment>

    )
}

export default InviteUsers
