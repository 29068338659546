import React, {useState, useContext, useEffect} from "react"
import {MyContext} from "../stores";
import {Route, Switch, useParams, Link, useRouteMatch} from "react-router-dom"
import {L10n} from '@syncfusion/ej2-base'
import {Search, GridComponent, ColumnDirective, ColumnsDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, Inject, Toolbar} from "@syncfusion/ej2-react-grids";
import useSWR from 'swr'
import Avatar from "react-avatar";
import {useTranslation} from "react-i18next";
import styled from 'styled-components'
import Loader from "../components/Loader";
import EditFormComponent from "./formGenerator";

function ListForms(){
    const {t} = useTranslation()
    const {path} = useRouteMatch()
    const {me, globalId, langChoice, setI10nLoadContent} = useContext(MyContext)

    useEffect(() => {
        setI10nLoadContent(
            {
                'fr-FR': {
                    'grid': {
                        'EmptyDataSourceError': 'Erreur de chargement des données',
                        'EmptyRecord': `Aucun formulaire á charger`,
                        'Item': 'formulaire',
                        'Items': 'formulaires',
                        'Search': "Chercher"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} de {1}',
                        'totalItemsInfo': '({0} formulaires)',
                        'totalItemInfo': '({0} formulaire)'
                    }
                },
                'en-US': {
                    'grid': {
                        'EmptyDataSourceError': 'Error loading data',
                        'EmptyRecord': `There's no form to load`,
                        'Item': 'form',
                        'Items': 'forms',
                        'Search': "Search"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} of {1}',
                        'totalItemsInfo': '({0} forms)',
                        'totalItemInfo': '({0} form)'
                    }
                }
            }
        )

    },[])

    let {data, error} = useSWR("https://api.bazookka.com/forms",
        url => fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'eid': globalId
            },
            method: 'get'}).then(r => r.json()));

    const internGrip = [
        {
            headerText: 'forms.formGrid.nameColumn',
            width: 'auto',
            field: 'title'
        },
        {
            headerText: '',
            width: '90',
            template: function Display(props){
                return (
                    <div className="flex gap-0.5">
                        <Link className="bg-pink-700 rounded text-white p-2 hover:text-yellow-300"><i className="mdi mdi-eye"></i></Link>
                        <Link to={`${path}edit/${props.id}`} className="bg-pink-700 rounded text-white p-2 hover:text-yellow-300"><i className="mdi mdi-pencil"></i></Link>
                    </div>
                )

            }
        }
    ]

    if(!data) {
        return <Loader small={false} />
    }

    return (
        <GridComponent
            locale={langChoice === "fr" ? "fr-FR" : "en-US"}
            width={`auto`}
            id="gridcomp"
            dataSource={data}
            allowPaging
            allowSorting
            pageSettings={{pageSize: 25}}
            contextMenuItems={['AutoFit']}
            toolbar={["Search"]}
            allowResizing={true}
        >
            <ColumnsDirective
            >
                {internGrip.map((item, index) => {
                    return <ColumnDirective
                        key={index}
                        {...item}
                        headerText={t(item.headerText)}
                        maxWidth={800}
                    />
                })}
            </ColumnsDirective>
            <Inject services={[Resize, Sort, Page, ContextMenu, Filter, Search, Toolbar]} />
        </GridComponent>

    )
}

export default function MyForms(){
    const {path} = useRouteMatch()
    return (
        <div className="h-full" style={{position: "relative"}}>
            <div className="flex flex-row gap-1">
                <div className="w-1/2">
                    <Link to={`${path}`} className="flex justify-center align-middle  border-1 border-gray-800 p-3 rounded w-full hover:text-gray-800 shadow">Share with me</Link>
                </div>
                <div className="w-1/2">
                        <Link to={`${path}/edit/:id`} className="flex justify-center align-middle  border-1 border-gray-800 p-3 rounded w-full hover:text-gray-800 shadow">Edit forms</Link>
                </div>
                <div className="w-1/2">
                    <Link to={`${path}/create`} className="flex justify-center align-middle  border-1 border-gray-800 p-3 rounded w-full hover:text-gray-800 shadow">Create forms</Link>
                </div>
                <div className="w-1/2">
                    <Link to={`${path}/create`} className="flex justify-center align-middle  border-1 border-gray-800 p-3 rounded w-full hover:text-gray-800 shadow">Edit forms</Link>
                </div>
            </div>
            <Switch>
                <Route path={`${path}/`} component={ListForms} exact>
                </Route>
                <Route path={`${path}/edit/:id`} component={EditFormComponent} exact>
                </Route>
            </Switch>
        </div>
    )
}