const countries =[
	{
	 "fr": "Afghanistan",
	 "en": "Afghanistan"
	},
	{
	 "fr": "Albanie",
	 "en": "Albania"
	},
	{
	 "fr": "Antarctique",
	 "en": "Antarctica"
	},
	{
	 "fr": "Algérie",
	 "en": "Algeria"
	},
	{
	 "fr": "Samoa Américaines",
	 "en": "American Samoa"
	},
	{
	 "fr": "Andorre",
	 "en": "Andorra"
	},
	{
	 "fr": "Angola",
	 "en": "Angola"
	},
	{
	 "fr": "Antigua-et-Barbuda",
	 "en": "Antigua and Barbuda"
	},
	{
	 "fr": "Azerbaïdjan",
	 "en": "Azerbaijan"
	},
	{
	 "fr": "Argentine",
	 "en": "Argentina"
	},
	{
	 "fr": "Australie",
	 "en": "Australia"
	},
	{
	 "fr": "Autriche",
	 "en": "Austria"
	},
	{
	 "fr": "Bahamas",
	 "en": "Bahamas"
	},
	{
	 "fr": "Bahreïn",
	 "en": "Bahrain"
	},
	{
	 "fr": "Bangladesh",
	 "en": "Bangladesh"
	},
	{
	 "fr": "Arménie",
	 "en": "Armenia"
	},
	{
	 "fr": "Barbade",
	 "en": "Barbados"
	},
	{
	 "fr": "Belgique",
	 "en": "Belgium"
	},
	{
	 "fr": "Bermudes",
	 "en": "Bermuda"
	},
	{
	 "fr": "Bhoutan",
	 "en": "Bhutan"
	},
	{
	 "fr": "Bolivie",
	 "en": "Bolivia"
	},
	{
	 "fr": "Bosnie-Herzégovine",
	 "en": "Bosnia and Herzegovina"
	},
	{
	 "fr": "Botswana",
	 "en": "Botswana"
	},
	{
	 "fr": "Île Bouvet",
	 "en": "Bouvet Island"
	},
	{
	 "fr": "Brésil",
	 "en": "Brazil"
	},
	{
	 "fr": "Belize",
	 "en": "Belize"
	},
	{
	 "fr": "Territoire Britannique de l'Océan Indien",
	 "en": "British Indian Ocean Territory"
	},
	{
	 "fr": "Îles Salomon",
	 "en": "Solomon Islands"
	},
	{
	 "fr": "Îles Vierges Britanniques",
	 "en": "British Virgin Islands"
	},
	{
	 "fr": "Brunéi Darussalam",
	 "en": "Brunei Darussalam"
	},
	{
	 "fr": "Bulgarie",
	 "en": "Bulgaria"
	},
	{
	 "fr": "Myanmar",
	 "en": "Myanmar"
	},
	{
	 "fr": "Burundi",
	 "en": "Burundi"
	},
	{
	 "fr": "Bélarus",
	 "en": "Belarus"
	},
	{
	 "fr": "Cambodge",
	 "en": "Cambodia"
	},
	{
	 "fr": "Cameroun",
	 "en": "Cameroon"
	},
	{
	 "fr": "Canada",
	 "en": "Canada"
	},
	{
	 "fr": "Cap-vert",
	 "en": "Cape Verde"
	},
	{
	 "fr": "Îles Caïmanes",
	 "en": "Cayman Islands"
	},
	{
	 "fr": "République Centrafricaine",
	 "en": "Central African"
	},
	{
	 "fr": "Sri Lanka",
	 "en": "Sri Lanka"
	},
	{
	 "fr": "Tchad",
	 "en": "Chad"
	},
	{
	 "fr": "Chili",
	 "en": "Chile"
	},
	{
	 "fr": "Chine",
	 "en": "China"
	},
	{
	 "fr": "Taïwan",
	 "en": "Taiwan"
	},
	{
	 "fr": "Île Christmas",
	 "en": "Christmas Island"
	},
	{
	 "fr": "Îles Cocos (Keeling)",
	 "en": "Cocos (Keeling) Islands"
	},
	{
	 "fr": "Colombie",
	 "en": "Colombia"
	},
	{
	 "fr": "Comores",
	 "en": "Comoros"
	},
	{
	 "fr": "Mayotte",
	 "en": "Mayotte"
	},
	{
	 "fr": "République du Congo",
	 "en": "Republic of the Congo"
	},
	{
	 "fr": "République Démocratique du Congo",
	 "en": "The Democratic Republic Of The Congo"
	},
	{
	 "fr": "Îles Cook",
	 "en": "Cook Islands"
	},
	{
	 "fr": "Costa Rica",
	 "en": "Costa Rica"
	},
	{
	 "fr": "Croatie",
	 "en": "Croatia"
	},
	{
	 "fr": "Cuba",
	 "en": "Cuba"
	},
	{
	 "fr": "Chypre",
	 "en": "Cyprus"
	},
	{
	 "fr": "République Tchèque",
	 "en": "Czech Republic"
	},
	{
	 "fr": "Bénin",
	 "en": "Benin"
	},
	{
	 "fr": "Danemark",
	 "en": "Denmark"
	},
	{
	 "fr": "Dominique",
	 "en": "Dominica"
	},
	{
	 "fr": "République Dominicaine",
	 "en": "Dominican Republic"
	},
	{
	 "fr": "Équateur",
	 "en": "Ecuador"
	},
	{
	 "fr": "El Salvador",
	 "en": "El Salvador"
	},
	{
	 "fr": "Guinée Équatoriale",
	 "en": "Equatorial Guinea"
	},
	{
	 "fr": "Éthiopie",
	 "en": "Ethiopia"
	},
	{
	 "fr": "Érythrée",
	 "en": "Eritrea"
	},
	{
	 "fr": "Estonie",
	 "en": "Estonia"
	},
	{
	 "fr": "Îles Féroé",
	 "en": "Faroe Islands"
	},
	{
	 "fr": "Îles (malvinas) Falkland",
	 "en": "Falkland Islands"
	},
	{
	 "fr": "Géorgie du Sud et les Îles Sandwich du Sud",
	 "en": "South Georgia and the South Sandwich Islands"
	},
	{
	 "fr": "Fidji",
	 "en": "Fiji"
	},
	{
	 "fr": "Finlande",
	 "en": "Finland"
	},
	{
	 "fr": "Îles Åland",
	 "en": "Åland Islands"
	},
	{
	 "fr": "France",
	 "en": "France"
	},
	{
	 "fr": "Guyane Française",
	 "en": "French Guiana"
	},
	{
	 "fr": "Polynésie Française",
	 "en": "French Polynesia"
	},
	{
	 "fr": "Terres Australes Françaises",
	 "en": "French Southern Territories"
	},
	{
	 "fr": "Djibouti",
	 "en": "Djibouti"
	},
	{
	 "fr": "Gabon",
	 "en": "Gabon"
	},
	{
	 "fr": "Géorgie",
	 "en": "Georgia"
	},
	{
	 "fr": "Gambie",
	 "en": "Gambia"
	},
	{
	 "fr": "Territoire Palestinien Occupé",
	 "en": "Occupied Palestinian Territory"
	},
	{
	 "fr": "Allemagne",
	 "en": "Germany"
	},
	{
	 "fr": "Ghana",
	 "en": "Ghana"
	},
	{
	 "fr": "Gibraltar",
	 "en": "Gibraltar"
	},
	{
	 "fr": "Kiribati",
	 "en": "Kiribati"
	},
	{
	 "fr": "Grèce",
	 "en": "Greece"
	},
	{
	 "fr": "Groenland",
	 "en": "Greenland"
	},
	{
	 "fr": "Grenade",
	 "en": "Grenada"
	},
	{
	 "fr": "Guadeloupe",
	 "en": "Guadeloupe"
	},
	{
	 "fr": "Guam",
	 "en": "Guam"
	},
	{
	 "fr": "Guatemala",
	 "en": "Guatemala"
	},
	{
	 "fr": "Guinée",
	 "en": "Guinea"
	},
	{
	 "fr": "Guyana",
	 "en": "Guyana"
	},
	{
	 "fr": "Haïti",
	 "en": "Haiti"
	},
	{
	 "fr": "Îles Heard et Mcdonald",
	 "en": "Heard Island and McDonald Islands"
	},
	{
	 "fr": "Saint-Siège (état de la Cité du Vatican)",
	 "en": "Vatican City State"
	},
	{
	 "fr": "Honduras",
	 "en": "Honduras"
	},
	{
	 "fr": "Hong-Kong",
	 "en": "Hong Kong"
	},
	{
	 "fr": "Hongrie",
	 "en": "Hungary"
	},
	{
	 "fr": "Islande",
	 "en": "Iceland"
	},
	{
	 "fr": "Inde",
	 "en": "India"
	},
	{
	 "fr": "Indonésie",
	 "en": "Indonesia"
	},
	{
	 "fr": "République Islamique d'Iran",
	 "en": "Islamic Republic of Iran"
	},
	{
	 "fr": "Iraq",
	 "en": "Iraq"
	},
	{
	 "fr": "Irlande",
	 "en": "Ireland"
	},
	{
	 "fr": "Israël",
	 "en": "Israel"
	},
	{
	 "fr": "Italie",
	 "en": "Italy"
	},
	{
	 "fr": "Côte d'Ivoire",
	 "en": "Côte d'Ivoire"
	},
	{
	 "fr": "Jamaïque",
	 "en": "Jamaica"
	},
	{
	 "fr": "Japon",
	 "en": "Japan"
	},
	{
	 "fr": "Kazakhstan",
	 "en": "Kazakhstan"
	},
	{
	 "fr": "Jordanie",
	 "en": "Jordan"
	},
	{
	 "fr": "Kenya",
	 "en": "Kenya"
	},
	{
	 "fr": "République Populaire Démocratique de Corée",
	 "en": "Democratic People's Republic of Korea"
	},
	{
	 "fr": "République de Corée",
	 "en": "Republic of Korea"
	},
	{
	 "fr": "Koweït",
	 "en": "Kuwait"
	},
	{
	 "fr": "Kirghizistan",
	 "en": "Kyrgyzstan"
	},
	{
	 "fr": "République Démocratique Populaire Lao",
	 "en": "Lao People's Democratic Republic"
	},
	{
	 "fr": "Liban",
	 "en": "Lebanon"
	},
	{
	 "fr": "Lesotho",
	 "en": "Lesotho"
	},
	{
	 "fr": "Lettonie",
	 "en": "Latvia"
	},
	{
	 "fr": "Libéria",
	 "en": "Liberia"
	},
	{
	 "fr": "Jamahiriya Arabe Libyenne",
	 "en": "Libyan Arab Jamahiriya"
	},
	{
	 "fr": "Liechtenstein",
	 "en": "Liechtenstein"
	},
	{
	 "fr": "Lituanie",
	 "en": "Lithuania"
	},
	{
	 "fr": "Luxembourg",
	 "en": "Luxembourg"
	},
	{
	 "fr": "Macao",
	 "en": "Macao"
	},
	{
	 "fr": "Madagascar",
	 "en": "Madagascar"
	},
	{
	 "fr": "Malawi",
	 "en": "Malawi"
	},
	{
	 "fr": "Malaisie",
	 "en": "Malaysia"
	},
	{
	 "fr": "Maldives",
	 "en": "Maldives"
	},
	{
	 "fr": "Mali",
	 "en": "Mali"
	},
	{
	 "fr": "Malte",
	 "en": "Malta"
	},
	{
	 "fr": "Martinique",
	 "en": "Martinique"
	},
	{
	 "fr": "Mauritanie",
	 "en": "Mauritania"
	},
	{
	 "fr": "Maurice",
	 "en": "Mauritius"
	},
	{
	 "fr": "Mexique",
	 "en": "Mexico"
	},
	{
	 "fr": "Monaco",
	 "en": "Monaco"
	},
	{
	 "fr": "Mongolie",
	 "en": "Mongolia"
	},
	{
	 "fr": "République de Moldova",
	 "en": "Republic of Moldova"
	},
	{
	 "fr": "Montserrat",
	 "en": "Montserrat"
	},
	{
	 "fr": "Maroc",
	 "en": "Morocco"
	},
	{
	 "fr": "Mozambique",
	 "en": "Mozambique"
	},
	{
	 "fr": "Oman",
	 "en": "Oman"
	},
	{
	 "fr": "Namibie",
	 "en": "Namibia"
	},
	{
	 "fr": "Nauru",
	 "en": "Nauru"
	},
	{
	 "fr": "Népal",
	 "en": "Nepal"
	},
	{
	 "fr": "Pays-Bas",
	 "en": "Netherlands"
	},
	{
	 "fr": "Antilles Néerlandaises",
	 "en": "Netherlands Antilles"
	},
	{
	 "fr": "Aruba",
	 "en": "Aruba"
	},
	{
	 "fr": "Nouvelle-Calédonie",
	 "en": "New Caledonia"
	},
	{
	 "fr": "Vanuatu",
	 "en": "Vanuatu"
	},
	{
	 "fr": "Nouvelle-Zélande",
	 "en": "New Zealand"
	},
	{
	 "fr": "Nicaragua",
	 "en": "Nicaragua"
	},
	{
	 "fr": "Niger",
	 "en": "Niger"
	},
	{
	 "fr": "Nigéria",
	 "en": "Nigeria"
	},
	{
	 "fr": "Niué",
	 "en": "Niue"
	},
	{
	 "fr": "Île Norfolk",
	 "en": "Norfolk Island"
	},
	{
	 "fr": "Norvège",
	 "en": "Norway"
	},
	{
	 "fr": "Îles Mariannes du Nord",
	 "en": "Northern Mariana Islands"
	},
	{
	 "fr": "Îles Mineures Éloignées des États-Unis",
	 "en": "United States Minor Outlying Islands"
	},
	{
	 "fr": "États Fédérés de Micronésie",
	 "en": "Federated States of Micronesia"
	},
	{
	 "fr": "Îles Marshall",
	 "en": "Marshall Islands"
	},
	{
	 "fr": "Palaos",
	 "en": "Palau"
	},
	{
	 "fr": "Pakistan",
	 "en": "Pakistan"
	},
	{
	 "fr": "Panama",
	 "en": "Panama"
	},
	{
	 "fr": "Papouasie-Nouvelle-Guinée",
	 "en": "Papua New Guinea"
	},
	{
	 "fr": "Paraguay",
	 "en": "Paraguay"
	},
	{
	 "fr": "Pérou",
	 "en": "Peru"
	},
	{
	 "fr": "Philippines",
	 "en": "Philippines"
	},
	{
	 "fr": "Pitcairn",
	 "en": "Pitcairn"
	},
	{
	 "fr": "Pologne",
	 "en": "Poland"
	},
	{
	 "fr": "Portugal",
	 "en": "Portugal"
	},
	{
	 "fr": "Guinée-Bissau",
	 "en": "Guinea-Bissau"
	},
	{
	 "fr": "Timor-Leste",
	 "en": "Timor-Leste"
	},
	{
	 "fr": "Porto Rico",
	 "en": "Puerto Rico"
	},
	{
	 "fr": "Qatar",
	 "en": "Qatar"
	},
	{
	 "fr": "Réunion",
	 "en": "Réunion"
	},
	{
	 "fr": "Roumanie",
	 "en": "Romania"
	},
	{
	 "fr": "Fédération de Russie",
	 "en": "Russian Federation"
	},
	{
	 "fr": "Rwanda",
	 "en": "Rwanda"
	},
	{
	 "fr": "Sainte-Hélène",
	 "en": "Saint Helena"
	},
	{
	 "fr": "Saint-Kitts-et-Nevis",
	 "en": "Saint Kitts and Nevis"
	},
	{
	 "fr": "Anguilla",
	 "en": "Anguilla"
	},
	{
	 "fr": "Sainte-Lucie",
	 "en": "Saint Lucia"
	},
	{
	 "fr": "Saint-Pierre-et-Miquelon",
	 "en": "Saint-Pierre and Miquelon"
	},
	{
	 "fr": "Saint-Vincent-et-les Grenadines",
	 "en": "Saint Vincent and the Grenadines"
	},
	{
	 "fr": "Saint-Marin",
	 "en": "San Marino"
	},
	{
	 "fr": "Sao Tomé-et-Principe",
	 "en": "Sao Tome and Principe"
	},
	{
	 "fr": "Arabie Saoudite",
	 "en": "Saudi Arabia"
	},
	{
	 "fr": "Sénégal",
	 "en": "Senegal"
	},
	{
	 "fr": "Seychelles",
	 "en": "Seychelles"
	},
	{
	 "fr": "Sierra Leone",
	 "en": "Sierra Leone"
	},
	{
	 "fr": "Singapour",
	 "en": "Singapore"
	},
	{
	 "fr": "Slovaquie",
	 "en": "Slovakia"
	},
	{
	 "fr": "Viet Nam",
	 "en": "Vietnam"
	},
	{
	 "fr": "Slovénie",
	 "en": "Slovenia"
	},
	{
	 "fr": "Somalie",
	 "en": "Somalia"
	},
	{
	 "fr": "Afrique du Sud",
	 "en": "South Africa"
	},
	{
	 "fr": "Zimbabwe",
	 "en": "Zimbabwe"
	},
	{
	 "fr": "Espagne",
	 "en": "Spain"
	},
	{
	 "fr": "Sahara Occidental",
	 "en": "Western Sahara"
	},
	{
	 "fr": "Soudan",
	 "en": "Sudan"
	},
	{
	 "fr": "Suriname",
	 "en": "Suriname"
	},
	{
	 "fr": "Svalbard etÎle Jan Mayen",
	 "en": "Svalbard and Jan Mayen"
	},
	{
	 "fr": "Swaziland",
	 "en": "Swaziland"
	},
	{
	 "fr": "Suède",
	 "en": "Sweden"
	},
	{
	 "fr": "Suisse",
	 "en": "Switzerland"
	},
	{
	 "fr": "République Arabe Syrienne",
	 "en": "Syrian Arab Republic"
	},
	{
	 "fr": "Tadjikistan",
	 "en": "Tajikistan"
	},
	{
	 "fr": "Thaïlande",
	 "en": "Thailand"
	},
	{
	 "fr": "Togo",
	 "en": "Togo"
	},
	{
	 "fr": "Tokelau",
	 "en": "Tokelau"
	},
	{
	 "fr": "Tonga",
	 "en": "Tonga"
	},
	{
	 "fr": "Trinité-et-Tobago",
	 "en": "Trinidad and Tobago"
	},
	{
	 "fr": "Émirats Arabes Unis",
	 "en": "United Arab Emirates"
	},
	{
	 "fr": "Tunisie",
	 "en": "Tunisia"
	},
	{
	 "fr": "Turquie",
	 "en": "Turkey"
	},
	{
	 "fr": "Turkménistan",
	 "en": "Turkmenistan"
	},
	{
	 "fr": "Îles Turks et Caïques",
	 "en": "Turks and Caicos Islands"
	},
	{
	 "fr": "Tuvalu",
	 "en": "Tuvalu"
	},
	{
	 "fr": "Ouganda",
	 "en": "Uganda"
	},
	{
	 "fr": "Ukraine",
	 "en": "Ukraine"
	},
	{
	 "fr": "L'ex-République Yougoslave de Macédoine",
	 "en": "The Former Yugoslav Republic of Macedonia"
	},
	{
	 "fr": "Égypte",
	 "en": "Egypt"
	},
	{
	 "fr": "Royaume-Uni",
	 "en": "United Kingdom"
	},
	{
	 "fr": "Île de Man",
	 "en": "Isle of Man"
	},
	{
	 "fr": "République-Unie de Tanzanie",
	 "en": "United Republic Of Tanzania"
	},
	{
	 "fr": "États-Unis",
	 "en": "United States"
	},
	{
	 "fr": "Îles Vierges des États-Unis",
	 "en": "U.S. Virgin Islands"
	},
	{
	 "fr": "Burkina Faso",
	 "en": "Burkina Faso"
	},
	{
	 "fr": "Uruguay",
	 "en": "Uruguay"
	},
	{
	 "fr": "Ouzbékistan",
	 "en": "Uzbekistan"
	},
	{
	 "fr": "Venezuela",
	 "en": "Venezuela"
	},
	{
	 "fr": "Wallis et Futuna",
	 "en": "Wallis and Futuna"
	},
	{
	 "fr": "Samoa",
	 "en": "Samoa"
	},
	{
	 "fr": "Yémen",
	 "en": "Yemen"
	},
	{
	 "fr": "Serbie-et-Monténégro",
	 "en": "Serbia and Montenegro"
	},
	{
	 "fr": "Zambie",
	 "en": "Zambia"
	}
   ]

   export default countries