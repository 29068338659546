import React, {useCallback, useContext, useMemo, useState} from 'react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import {
    Editor,
    Transforms,
    createEditor,
    Descendant,
    Node,
    Element as SlateElement,
} from 'slate'
import { withHistory } from 'slate-history'

import { Button, Icon, Toolbar } from './AdvanceElem'
import {MyContext} from "../stores";
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}



const WrapperTextEditor = styled.div`
  ul, li {
    list-style: decimal !important;
    margin-left: 1em;
  }
  html,
  input,
  textarea {
    font-family: 'Roboto', sans-serif;
    line-height: 1.4;
    background: #eee;
  }

  body {
    margin: 0;
  }

  p {
    margin: 0;
  }

  pre {
    padding: 10px;
    background-color: #eee;
    white-space: pre-wrap;
  }

  :not(pre) > code {
    font-family: monospace;
    background-color: #eee;
    padding: 3px;
  }

  img {
    max-width: 100%;
    max-height: 20em;
  }

  blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
  }

  blockquote[dir='rtl'] {
    border-left: none;
    padding-left: 0;
    padding-right: 10px;
    border-right: 2px solid #ddd;
  }

  table {
    border-collapse: collapse;
  }

  td {
    padding: 10px;
    border: 2px solid #ddd;
  }

  input {
    box-sizing: border-box;
    font-size: 0.85em;
    width: 100%;
    padding: 0.5em;
    border: 2px solid #ddd;
    background: #fafafa;
  }

  input:focus {
    outline: 0;
    border-color: blue;
  }

  iframe {
    width: 100%;
    border: 1px solid #eee;
  }
  h1 {
    font-size: 2em !important;
  }
  
  h2 {
    font-size: 1.5em;
  }

  [data-slate-editor] > * + * {
    margin-top: 1em;
  }
`

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const RichTextEditor = ({init, update}) => {
    const {content, setContent} = useContext(MyContext)
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])
    const {t} = useTranslation()

    return (
        <WrapperTextEditor className="richTextContainer">
            <Slate editor={editor} value={content} onChange={value => setContent(value)}>
                <Toolbar>
                    <MarkButton format="bold" icon="mdi mdi-format-bold" />
                    <MarkButton format="italic" icon="mdi mdi-format-italic" />
                    <MarkButton format="underline" icon="mdi mdi-format-underline" />
                    <MarkButton format="code" icon="mdi mdi-code-tags" />
                    <BlockButton format="heading-one" icon="mdi mdi-numeric-1-box" />
                    <BlockButton format="heading-two" icon="mdi mdi-numeric-2-box" />
                    <BlockButton format="block-quote" icon="mdi mdi-format-quote" />
                    <BlockButton format="numbered-list" icon="mdi mdi-format-list-numbered" />
                    <BlockButton format="bulleted-list" icon="mdi mdi-format-list-bulleted" />
                </Toolbar>
                <Editable
                    className="richInput"
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder={t("general.richTextPLaceHolder")}
                    spellCheck
                    autoFocus
                    onKeyDown={event => {
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                                event.preventDefault()
                                const mark = HOTKEYS[hotkey]
                                toggleMark(editor, mark)
                            }
                        }
                    }}
                />
            </Slate>
        </WrapperTextEditor>

    )
}

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format)
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n =>
            LIST_TYPES.includes(
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
            ),
        split: true,
    })
    const newProperties = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })

    return !!match
}

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }) => {
    switch (element.type) {
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>
        case 'list-item':
            return <li {...attributes}>{children}</li>
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>
        default:
            return <p {...attributes}>{children}</p>
    }
}

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isBlockActive(editor, format)}
            className="toolButton"
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
        >
            <Icon className={icon}></Icon>
        </Button>
    )
}

const MarkButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isMarkActive(editor, format)}
            className="toolButton"
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            <Icon className={icon}></Icon>
        </Button>
    )
}

const initialValue = [
    {
        type: 'paragraph',
        children: [
            { text: 'This is editable ' },
            { text: 'rich', bold: true },
            { text: ' text, ' },
            { text: 'much', italic: true },
            { text: ' better than a ' },
            { text: '<textarea>', code: true },
           // { text: '!' },
        ],
    },
    {
        type: 'paragraph',
        children: [
            {
                text:
                    "Since it's rich text, you can do things like turn a selection of text ",
            },
            { text: 'bold', bold: true },
            {
                text:
                    ', or add a semantically rendered block quote in the middle of the page, like this:',
            },
        ],
    },
    {
        type: 'block-quote',
        children: [{ text: 'A wise quote.' }],
    },
    {
        type: 'paragraph',
        children: [{ text: 'Try it out for yourself!' }],
    },
]

export const RichTextEditorFix = ({init, update, content, setContent}) => {
   // const {content, setContent} = useContext(MyContext)
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])
    const {t} = useTranslation()

    return (
        <WrapperTextEditor className="richTextContainer">
            <Slate editor={editor} value={content} onChange={value => setContent(value)}>
                <Toolbar>
                    <MarkButton format="bold" icon="mdi mdi-format-bold" />
                    <MarkButton format="italic" icon="mdi mdi-format-italic" />
                    <MarkButton format="underline" icon="mdi mdi-format-underline" />
                    <MarkButton format="code" icon="mdi mdi-code-tags" />
                    <BlockButton format="heading-one" icon="mdi mdi-numeric-1-box" />
                    <BlockButton format="heading-two" icon="mdi mdi-numeric-2-box" />
                    <BlockButton format="block-quote" icon="mdi mdi-format-quote" />
                    <BlockButton format="numbered-list" icon="mdi mdi-format-list-numbered" />
                    <BlockButton format="bulleted-list" icon="mdi mdi-format-list-bulleted" />
                </Toolbar>
                <Editable
                    className="richInput"
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder={t("general.richTextPLaceHolder")}
                    spellCheck
                    onKeyDown={event => {
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                                event.preventDefault()
                                const mark = HOTKEYS[hotkey]
                                toggleMark(editor, mark)
                            }
                        }
                    }}
                />
            </Slate>
        </WrapperTextEditor>

    )
}

export const RichTextEditorSimple = ({data, onChange}) => {
    const [content, setContent] = useState(data)
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])
    const {t} = useTranslation()

    return (
        <WrapperTextEditor className="richTextContainer">
            <Slate editor={editor} value={content} onChange={onChange}>
                <Toolbar>
                    <MarkButton format="bold" icon="mdi mdi-format-bold" />
                    <MarkButton format="italic" icon="mdi mdi-format-italic" />
                    <MarkButton format="underline" icon="mdi mdi-format-underline" />
                    <MarkButton format="code" icon="mdi mdi-code-tags" />
                    <BlockButton format="heading-one" icon="mdi mdi-numeric-1-box" />
                    <BlockButton format="heading-two" icon="mdi mdi-numeric-2-box" />
                    <BlockButton format="block-quote" icon="mdi mdi-format-quote" />
                    <BlockButton format="numbered-list" icon="mdi mdi-format-list-numbered" />
                    <BlockButton format="bulleted-list" icon="mdi mdi-format-list-bulleted" />
                </Toolbar>
                <Editable
                    className="richInput"
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder={t("general.richTextPLaceHolder")}
                    spellCheck
                    onKeyDown={event => {
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                                event.preventDefault()
                                const mark = HOTKEYS[hotkey]
                                toggleMark(editor, mark)
                            }
                        }
                    }}
                />
            </Slate>
        </WrapperTextEditor>

    )
}

export default RichTextEditor
