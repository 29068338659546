import React, {useState, useEffect, useContext, useRef} from "react";
import {MyContext} from "../stores";
import styled from 'styled-components'

const AdvanceStyledText = styled.div`
  
  width: 95%;
  background: transparent;
  margin: 100px auto;
  font-family: Arial !important;
  filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.50));
  .toolbar {
    border-radius: 0px 0px 8px 8px;
    background-color: white;
    border: 1px solid #e8e8e8;
    padding: 10px;
    display: flex;
    flex-direction: row;
    ul  {
      display: flex;
      flex-direction: row;
      gap: 5px;
    }
  }
  i {
    font-size: 20px;
    padding: 3px;
  }
  button {
    border: 1px solid transparent;
  }
  
  .textcontent {
    
    :focus {
      outline: 1px solid transparent !important;
    }
    font-family: Arial !important;
    border-radius: 8px 8px 0px 0px;
    width: 100%;
    padding: 2rem;
    background-color: white;
    border: 1px solid #e8e8e8;
    
    ul {
      list-style: inherit !important;
    }
    
  }
`


export default function AdvanceInputText({stateData, onContentStateChange}){
    const [state, setState] = useState(stateData || "...")

    const textbox = useRef()
    useEffect(()=> {
        textbox.current.innerHTML = stateData || "..."
    }, [stateData])
    const onEdit = (target) => {
        document.execCommand(target, false, null)
        // setState(textbox.current.innerHTML)
        // onContentStateChange(state)
    }
    const onSave = () => {
        onContentStateChange(textbox.current.innerHTML)
    }

    return (
        <AdvanceStyledText>
            <div className="textcontent" contentEditable={true} ref={textbox}></div>
            <div className="toolbar">
                <ul>
                    <li>
                        <button type="button" data-command="justifytLeft" onClick={(e)=> {
                            onEdit("delete")
                        }}>
                            <i className="fas fa-remove"></i>
                        </button>
                    </li>
                    <li>
                        <button type="button" data-command="justifytLeft" onClick={(e)=> {
                            onEdit("undo")
                        }}>
                            <i className="fas fa-undo"></i>
                        </button>
                    </li>
                    <li>
                        <button type="button" data-command="justifytLeft" onClick={(e)=> {
                            onEdit("justifyLeft")
                        }}>
                            <i className="fas fa-align-left"></i>
                        </button>
                    </li>
                    <li>
                        <button type="button" data-command="justifytLeft" onClick={(e)=> {
                            onEdit("justifyRight")
                        }}>
                            <i className="fas fa-align-right"></i>
                        </button>
                    </li>
                    <li>
                        <button type="button" data-command="justifytLeft" onClick={(e)=> {
                            onEdit("insertUnorderedList")
                        }}>
                            <i className="fas fa-list-ol"></i>
                        </button>
                    </li>
                    <li>
                        <button type="button" data-command="justifytLeft" onClick={(e)=> {
                            onEdit("insertOrderedList")
                        }}>
                            <i className="fas fa-list-ul"></i>
                        </button>
                    </li>
                    <li>
                        <button type="button" data-command="justifytLeft" onClick={(e)=> {
                            onSave()
                        }}>
                            <i className="fas fa-save"></i>
                        </button>
                    </li>
                </ul>
            </div>

        </AdvanceStyledText>
    )
}
