import React, {useContext, useEffect} from 'react'
import {InputDate, InputText, SwitchBox, TextAreaInput} from "../FormElements";
import {format} from "date-fns";
import {withFormik} from "formik";
import {MyContext} from "../../stores";

const MyFormWrapper = ({ setValidationAction, submitForm, ...other }) => {
    // Akin to componentDidMount, componentDidUpdate
    useEffect(() => {
        setValidationAction(other);
    });
    return <FormTemplate {...other} />;
};

const MyEnhancedForm = withFormik({
    mapPropsToValues: (props) => {
        return (props.initialValues)},

    // Custom sync validation
    validate: values => {
        const errors = {};
        return errors;
    },

    handleSubmit: (values, { setSubmitting }) => {
        setSubmitting(true);
        setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 1000);
    },

    displayName: "BasicForm"
})(MyFormWrapper);


function FormTemplate({values,touched, errors, handleChange, handleBlur, handleSubmit, resetForm, isSubmitting}){
    const {langChoice} = useContext(MyContext)

    return (
        <form onSubmit={handleSubmit}>
            <div className="settings-form-wrapper">
                <div className="settings-form">
                    <div style={{width: '95%'}}>
                        <div className="field">
                            <InputText
                                label={ langChoice === 'fr'? 'titre': 'title'}
                                value={values.title}
                                name="title" />
                        </div>
                        <div className="field">
                            <InputText
                                label={ langChoice === 'fr'? 'Petit résumé': 'Little summary'}
                                name="tiny" />
                        </div>
                        <div className="field">
                            <InputDate
                                label={langChoice === 'fr'? 'Date de début':'Start date'}
                                value={format(new Date(values.startDate), 'dd/MM/yyyy')}
                                name="startDate"
                            />
                        </div>
                        <div className="field">
                            <InputDate
                                label={langChoice === 'fr'? 'Date de fin':'End date'}
                                value={format(new Date(values.endDate), 'dd/MM/yyyy')}
                                name="endDate"
                            />
                        </div>
                        <div className="field">
                            <SwitchBox
                                label={langChoice === 'fr' ? 'Actif': 'Active'}
                                name="active"
                            />
                        </div>

                        {/*<div className="field" style={{display:'flex', justifyContent: 'space-between'}}>*/}
                        {/*    <button type="submit" className="button is-primary" disabled={isSubmitting}>{langChoice === 'fr' ? 'sauvegarder': 'save'}</button>*/}
                        {/*    /!*<button onClick={(e) => returnBack(e, resetForm)} className="button is-danger">{langChoice === 'fr' ? 'ne pas sauvegarder': 'do not save'}</button>*!/*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </form>
    )
}

export default MyEnhancedForm
