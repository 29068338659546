import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en_translations from "./stores/language-ressources/en.json";
import fr_translations from "./stores/language-ressources/fr.json";
import fr_erreur_404 from "./stores/language-ressources/fr_error.json";
import en_erreur_404 from "./stores/language-ressources/en_error.json";


const resources = {
  en: {
    common: en_translations,
    error: en_erreur_404,
  },
  fr: {
    common: fr_translations,
    error: fr_erreur_404,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('bzk_lang') || "fr",
    defaultNS: "common",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
