import React, {useContext, useState, useEffect} from 'react'
import {MyContext} from "../../stores";
import {db, firebaseInstance} from "../../firestore/firestoreService";
import {Formik, Form} from "formik";
import styled from 'styled-components'
import {InputText, SwitchBox} from "../../components/FormElements";
import * as Yup from 'yup'
import { UserAction } from '../../stores/enums/UserAction';
import Loader from "../../components/Loader";
import { Box } from '@mui/system';

const PasswordStyled = styled.div`
  border-radius: 1rem;
  background: white;
  display: flex;
  justify-content: center;
  width: 600px;

  border: 1px solid #e0e0e0;
  height: auto;
  margin: 2rem auto;
  padding: 3rem 0;
`
export default function Password({id, t, createNewAccount, setSignUpStep}) {
    const {langChoice, me, globalId} = useContext(MyContext)
    const [password, setPwd] = useState('')
    const [cpassword, setCPwd] = useState('')
    const [type, setType] = useState('password')
    const [loading, setLoading] = useState(false);
    const onSubmit = async (value, {setSubmitting}) => {
        setSubmitting(true)
        setLoading(true)
        let user = await db.collection('createAccountInvitations').doc(id).get()

        const createAccountPromise = new Promise(function(resolve, reject) {
            const userId = createNewAccount({...value, ...user.data()});
            if(userId){
                resolve(userId)
            }else{
                reject("Unable to get creation ID");
            }
        });
        
        createAccountPromise.then((userId) => {
            const createUsersActions = firebaseInstance().functions().httpsCallable("createUsersActions")
            createUsersActions({
                type : UserAction.INVITE_ACCEPTATION,
                postTitle : "",
                postType : "",
                displayName : user.data().displayName,
                globalId: userId,
                }).then(res => {
                    setSubmitting(false)
                    setSignUpStep(3)
                    setLoading(false);
                })
        }).catch(e => {
            console.log(e);
        })
    }

    const validate = Yup.object().shape({
        password: Yup.string().min(5).required(),
        cpassword: Yup.string().min(5).oneOf([Yup.ref('password'), null], 'password must match').required()
    })

    return (
        <PasswordStyled>
            
            <Formik
               onSubmit={onSubmit}
               initialValues={{password: '', cpassword: '', display: false}}
               validationSchema={validate}
            >
                {({values, isSubmitting, errors, touched}) => {
                    if(loading){
                        return(<Loader small />);
                    }else{
                        return (
                            <Form>
                                <InputText
                                    label={t('general.pwd')}
                                    name="password"
                                    value={values.password}
                                    type={type}
                                />
                                <InputText
                                    label={t('general.rpwd')}
                                    name="cpassword"
                                    value={values.cpassword}
                                    type={type}
                                />
                                {errors.cpassword && touched.cpassword ? (
                                    <div>{errors.cpassword}</div>
                                ) : null}
                                <SwitchBox
                                label={t('form.revealPassword')}
                                name="display"
                                onClick={()=> values.display ? setType('password') : setType('text')}
                                ></SwitchBox>
                                {}
                                <Box sx={{display:"flex",justifyContent: "center" }}>
                                    <div className="split">
                                        <button className="button" type="submit" disabled={isSubmitting}>{t('general.register')}</button>
                                    </div>
                                </Box>
                            </Form>
                        )
                    }
                }}

            </Formik>
        </PasswordStyled>
    )
}
