import i18next from 'i18next';
import React, {useState, useMemo, useContext} from 'react'
import { useTranslation } from 'react-i18next';
import { createEditor, Descendant, Element, Node } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import {MyContext} from "../stores";

const DisplayContentFromSlate = ({content}) => {
    const [value, setValue] = useState(validation(content))
    const editor = useMemo(() => withReact(createEditor()), [])
    const {t} = useTranslation()
    return (
        <Slate editor={editor} value={value} onChange={value => setValue(value)}>
            <Editable readOnly placeholder="..." />
        </Slate>
    )
}
function validation( v ) {
    if(Array.isArray(v)) {
        return v
    }
    return [
        {
            type: 'paragraph',
            children: [
                {
                    text: v,
                },
            ],
        },
    ]
}
i18next.t("message.contentPlaceHolder")
export const initialValue = [
    {
        type: 'paragraph',
        children: [
            {
                text: "",
            },
        ],
    },
]

export default DisplayContentFromSlate
