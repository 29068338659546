import React, {useContext, useState, useEffect} from 'react'
import io from 'socket.io-client'

const SocketContext = React.createContext()

export function useSocket(){
    return useContext(SocketContext)
}

export function SocketProvider({children, globalId}){
    const [socket, setSocket] = useState()

    useEffect(()=> {
        const newSocket = io(`ws://api.bazookka.com:5004`, {withCredentials: true})
        setSocket(newSocket)
        return ()=> newSocket.close()
    }, [])

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    )
}