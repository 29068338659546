import React, { useState, useEffect, useContext } from 'react'
import { MyContext } from "../stores";
import { firebaseInstance, users, groupsDoc, storage } from "../firestore/firestoreService";
import { useTranslation } from "react-i18next";
import EditDocumentForm from "../forms/EditDocumentForm";
import moment from 'moment';
import styled, { ThemeProvider } from 'styled-components';
import dedupArray from '../utils/dedupArray';
import { useAlert } from 'react-alert';
import SearchIcon from '@mui/icons-material/Search';
import { Button, createTheme, InputBase } from '@mui/material';
import { Box } from '@mui/system';
import accents from 'remove-accents';
import formatFileSize from '../utils/fileSizeFormatter';
import { PageLoader } from "../components/OtherOvers";


const DocumentsContainer = styled.div`
    
`

const DocumentWrapper = styled.div`
  margin: 1rem 0px;
  align-items: center;
  transition: all .3s;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  background: white;
  img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }
  .template {
    width: 100%;
    display: grid;
    grid-template-columns: auto 110px;
    gap:15px;
    align-items: center;
  }
  .flex-block-meta {
    margin-left: 10px;
  }
  .div-button {
    background-color: var(--bazookka-green);
    cursor: pointer;
    width: 42px;
    padding: 0px 5px !important;
    margin: 0px 5px !important;
    height: 42px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.25));
  }
  .titletext {
    display: flex;
    padding-left: 1rem;
    align-items: center;
  }
  .go-button {
    cursor: pointer;
    background-color: var(--bazookka-blue);
    width: 42px;
    padding: 0px 5px !important;
    margin: 0px 5px !important;
    float: left;
    height: 42px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    opacity: 0;
    box-shadow: 0 14px 26px -12px rgb(85 150 230 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(85 150 230 / 20%) !important;

  }
  :hover .go-button {
    opacity: 1;
    transform: rotate(360deg);
    transition: all .3s;
  }`

const DocumentHeading = styled.div`
  a{
    display:grid;
    grid-template-columns:50px auto;
    cursor:pointer;
    color:#344258;
  }
`

const DocumentGroup = styled.div`
    display:grid;
    grid-template-columns:repeat(3, 1fr);
    gap:10px;
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 468px) {
    grid-template-columns: repeat(1, 1fr);
    }
`

const DocumentTitle = styled.div`
    padding-left:5px;
    margin:auto 0;
`
const theme = createTheme();

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    color: "black",
    backgroundColor: "#d0fefe",
    '&:hover': {
      backgroundColor: "white",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    marginTop: "30px",
    marginBottom: "30px",
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: '50vh',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));

const DocumentSearchBar = ({setSearchQuery}) => {
   const {t} = useTranslation()
    const onChange = (event) => {
        setSearchQuery(event.target.value);
      };

    return (
        <div style={{margin: "auto", width: "50%"}}>
            <Box sx={{display : "flex"}}>
                <Box>
                    <ThemeProvider theme={theme}>
                    <Search>
                        <SearchIconWrapper>
                        <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                        onChange={onChange}
                        placeholder={t('general.docsearch')}
                        inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>
                    </ThemeProvider>
                </Box>
            </Box>

        </div>


    )
}

const DocumentCard = ({ document, groups, options, creating = false, authored = false, handleCreate = null }) => {
    const { dispatchModal } = useContext(MyContext);

    const [editing, setEditing] = useState(false);
    const { t } = useTranslation();
    const alert = useAlert();
    const documentUrl = (document && document.url) ? document.url : '';
    const [userFilesSize, setUserFilesSize] = useState(-1)

    const creatingClass = creating ? 'create' : '';

    const handleCreateAndClose = () => {
        handleCreate();
        setEditing(false);
    }

    const getFileSize = (url) => {
        url && url.length > 0 && storage.refFromURL(url).getMetadata().then(meta => {
            setUserFilesSize(meta.size)
        })
    }

    getFileSize(documentUrl)
    return (
        <DocumentWrapper className={creatingClass}>
            <div className="template">
                <DocumentHeading onClick={(e) => {
                    if (creating) {
                        e.preventDefault()
                        setEditing(!editing);
                    }
                }}
                >

                    <a href={documentUrl} rel="noreferrer" target="_blank">
                        <div className="div-button">
                            {creating ? <i className="mdi mdi-plus"></i> : <i className="mdi mdi-file-document"></i>}
                        </div>
                        {creating ?
                            <DocumentTitle>{t('general.adddocument')}</DocumentTitle>
                            :
                            <DocumentTitle>{document.name}</DocumentTitle>
                        }
                    </a>
                    {userFilesSize > 0 && <h1 style={{paddingLeft:"5px",margin:"auto 0"}}>{formatFileSize(userFilesSize)}</h1>}
                </DocumentHeading>
                {!creating && authored &&
                    <div>
                        {editing ?
                            <div className="go-button float-left" onClick={(e) => {
                                setEditing(false)
                            }}>
                                <i className="mdi mdi-close"></i>
                            </div> :
                            <div className="go-button float-left" onClick={(e) => {
                                e.preventDefault()
                                setEditing(!editing);
                            }}>
                                <i className="mdi mdi-pencil"></i>
                            </div>
                        }

                        <div className="go-button float-left">
                            <i className="mdi mdi-delete"></i>
                        </div>
                    </div>
                }
            </div>
            {editing &&
                <EditDocumentForm document={document} groups={groups} options={options} creating={creating} onCreate={handleCreateAndClose} />
            }
        </DocumentWrapper>
    )
}

const Documents = props => {
    const { me, globalId } = useContext(MyContext);
    const { t } = useTranslation();
    const [UserDocuments, setUserDocuments] = useState([]);
    const [GroupDocuments, setGroupDocuments] = useState([]);
    const [AuthoredDocuments, setAuthoredDocuments] = useState([]);
    const [groups, setGroups] = useState([]);
    const [options, setOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState("")
    const [totalSize, setTotalSize] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const alert = useAlert()

    const LoadUserDocuments = async () => {
        const userDocumentsCallable = firebaseInstance().functions().httpsCallable('GetUserDocuments');
        userDocumentsCallable({ userId: globalId, groupsId: me.groupsId }).then(results => {

            setAuthoredDocuments(results.data.authoredDocuments);
            setUserDocuments(results.data.userDocuments);
            setGroupDocuments(results.data.groupDocuments);
            setIsLoading(false)
        }).catch(err => {
            console.log(err.code, err.message, err.details)
        })
    }


    const searchCriteria = (ad, searchQuery) => {
        return accents.remove(ad.name.toLocaleLowerCase()).includes(accents.remove(searchQuery.toLocaleLowerCase()));
    }


    useEffect(() => {
        let results = []
        AuthoredDocuments && AuthoredDocuments.length > 0 && Promise.all(
            AuthoredDocuments && AuthoredDocuments.length > 0 && AuthoredDocuments.map((d) =>
              {
                  console.log("d ",d)
                 d.url && storage.refFromURL(d.url).getMetadata()
                      .then(res => {
                          results.push(res)
                      })
                      .catch(err => {
                          console.log(err)
                      })

              }
            )).then(() => setTotalSize(results.map(result => {
                const obj = {}
                obj.size = result.size
                setIsLoading(false)
                return obj
            }
            ))).catch(e => {
                alert.info("Une erreur s'est introduite")
                console.log("Une erreur s'est introduite")
            })
    }, [AuthoredDocuments])

    useEffect(() => {
        if (globalId && me) {
            LoadUserDocuments();

            groupsDoc.where("school", "==", me.select.id).get().then(snap => {
                setGroups(snap.docs.map(doc => {
                    return { id: doc.id, ...doc.data(), label: doc.data().name, value: doc.id }
                }))
            }).catch(error => alert.error(error.message));

            users.where("schoolsId", "array-contains-any", [me.select.id]).get().then((querySnapshot) => {
                setOptions(querySnapshot.docs.map(doc => {
                    return { id: doc.id, label: doc.data().displayName, value: doc.id }
                }));
            });
        }
    }, [me, globalId])

    return (
        !isLoading ?
        <div style = {{ "paddingTop" : '30px'}} className="view-wrapper">
            <div className="navbar-ghost is-hidden-mobile"></div>
            <div className="container is-custom">
                <h1>{t('overlay.files')}</h1>
                <DocumentCard key="createForm" groups={groups} options={options} creating={true} handleCreate={LoadUserDocuments} />
                <DocumentsContainer>
                    {AuthoredDocuments && AuthoredDocuments.length > 0 &&
                        <React.Fragment>
                            <h1>{t('settings.myDocuments')} : {AuthoredDocuments.length} documents</h1>
                            <DocumentSearchBar setSearchQuery={setSearchQuery}/>
                            <DocumentGroup>
                                {AuthoredDocuments && AuthoredDocuments.length > 0 && AuthoredDocuments.filter(ad => searchCriteria(ad, searchQuery) ).map( (document, key) => {
                                    return <DocumentCard key={key} document={document} groups={groups} options={options} authored={true} handleCreate={LoadUserDocuments}/>

                                })}
                            </DocumentGroup>
                                {totalSize.length > 0 && <h2>{t('documents.totalSize')} : {formatFileSize(totalSize.reduce((a, b) => +a + +b.size, 0))}</h2>}
                                <br/>
                        </React.Fragment>
                    }

                    {UserDocuments && UserDocuments.length > 0 &&
                        <React.Fragment>
                            <h1>{t('documents.userDocuments')} : {UserDocuments.length} documents </h1>
                            <DocumentGroup>
                            {UserDocuments.filter(d => searchCriteria(d,searchQuery)).map( (document, key) => {
                                return <DocumentCard key={key} document={document} groups={groups} options={options} handleCreate={LoadUserDocuments} />
                            })}
                            </DocumentGroup>
                        </React.Fragment>
                    }

                    {GroupDocuments && GroupDocuments.length > 0 && GroupDocuments.filter(d => searchCriteria(d,searchQuery)).length > 0 &&
                        <React.Fragment>
                            <h1>{t('documents.groupDocuments')} : {GroupDocuments.length} documents </h1>
                            <DocumentGroup>
                                {GroupDocuments && GroupDocuments.length > 0 && GroupDocuments.map((document, key) => {
                                    return <DocumentCard key={key} document={document} groups={groups} options={options} handleCreate={LoadUserDocuments} />
                                })}
                            </DocumentGroup>
                        </React.Fragment>
                    }
                    <h1>{t("documents.count")} : {GroupDocuments.length + UserDocuments.length + AuthoredDocuments.length } </h1>
                </DocumentsContainer>
            </div>
        </div> :
        <PageLoader/>
    )
}

export default Documents;

