import styled from "styled-components";
import { useState } from "react";

const RadioSwitchStyled = styled.div`
  z-index: 9999;
  position: absolute;
  bottom: 0;
  .switch-field {
    overflow: hidden;
  }

  .switch-title {
    font-weight: bold;
    margin-bottom: 6px;
  }

  .switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  .switch-field label {
    display: inline-block;
    background-color: #e4e4e4;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    justify-content: center;
    text-shadow: none;
    padding: 6px 14px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3),
      0 1px rgba(255, 255, 255, 0.1);
    transition: all 0.1s ease-in-out;
  }

  .switch-field label:hover {
    cursor: pointer;
  }

  .switch-field .selected {
    background-color: #1955a5;
    color: #fff;
    box-shadow: none;
  }
`;
const RadioShareSwitchStyled = styled.div`
  .switch-field {
    overflow: hidden;
  }

  .switch-title {
    font-weight: bold;
    margin-bottom: 6px;
  }

  .switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  .switch-field label {
    display: inline-block;
    background-color: #e4e4e4;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    justify-content: center;
    text-shadow: none;
    padding: 6px 14px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3),
      0 1px rgba(255, 255, 255, 0.1);
    transition: all 0.1s ease-in-out;
  }

  .switch-field label:hover {
    cursor: pointer;
  }

  .switch-field .selected {
    background-color: #1955a5;
    color: #fff;
    box-shadow: none;
  }
`;

export const ToggleElements = ({ toggle, setToggle }) => {
  const handleToggle = (event) => {
    let value = event.target.value;
    setToggle(value);
  };

  return (
    <RadioSwitchStyled>
      <div className="switch-field">
        <label className={toggle === "Prebuilt elements" ? 'selected' : ''} onClick={(event) => handleToggle(event)}>
          <input
            type="radio"
            name="switchToggle"
            value="Prebuilt elements"
            checked={toggle === "Prebuilt elements"}
            onClick={(event) => handleToggle(event)}
          />
          Prebuilt elements
        </label>
        <label className={toggle === "Custom elements" ? 'selected' : ''} onClick={(event) => handleToggle(event)}>
          <input
            type="radio"
            name="switchToggle"
            value="Custom elements"
            checked={toggle === "Custom elements"}
            onClick={(event) => handleToggle(event)}
          />
          Custom elements
        </label>
      </div>
    </RadioSwitchStyled>
  );
};

export const ToggleSharingOptions = ({ translator, toggle, setToggle }) => {
  const handleToggle = (event) => {
    if(event.target.children.length !== 0){
      let value = event.target.children[0].value;
      setToggle(value);
    }
  };

  return (
    <RadioShareSwitchStyled>
      <div className="switch-field">
        <label className={toggle === "user" ? 'selected' : ''} onClick={(event) => handleToggle(event)}>
          <input
            type="radio"
            name="switchToggle"
            value="user"
            checked={toggle === "user"}
          />
          {translator("general.users")}
        </label>
        <label className={toggle === "group" ? 'selected' : ''} onClick={(event) => handleToggle(event)}>
          <input
            type="radio"
            name="switchToggle"
            value="group"
            checked={toggle === "group"}
          />
          {translator("general.groups")}
        </label>
      </div>
    </RadioShareSwitchStyled>
  );
};

export default ToggleElements;
