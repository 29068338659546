import React, {useContext, useEffect, useState} from 'react'
import {MyContext} from '../stores'
import {SectionHeader} from "./Dashboard";
import useSWR from 'swr'
import Loader from '../components/Loader'
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Selection, Inject, Edit, Toolbar, Sort, Filter } from '@syncfusion/ej2-react-grids';
import Avatar from "react-avatar";
import {useRouteMatch} from "react-router-dom";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {useTranslation} from "react-i18next";


export default function NoMatch(){

    const {globalId, setI10nLoadContent, langChoice} = useContext(MyContext)
    const selectionsettings = { persistSelection: true };
    const toolbarOptions = ['Delete'];
    const editing = { allowDeleting: true, allowEditing: true };
    const [hideDialog, setHideDialog] = useState(false)
    const [currentUserId, setCurrentUserId] = useState()
    const {t} = useTranslation()
    const onOverlayClick = () => {
        setHideDialog(false)
    };
    const dialogClose = () => {
        setHideDialog(false)
    };

    const handleClick = (id) => {
        setCurrentUserId(id)
        setHideDialog(true)
    }
    function headerTemplate() {
        const userD = data.find(elem => elem.id === currentUserId)
        return (userD ? <div className="flex flex-row justify-items-center align-item">
            <Avatar className="mr-2" name={userD.display_name} size={40} round={true} />
            <div clas title={userD.display_name} className="e-icon-settings dlg-template mb-3">{userD.display_name}</div>
        </div> : "");
    }
    const separatorWithTitle = (title) => {
        return (<div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-4 border-gray-400"></div>
            <span class="flex-shrink mx-4 text-gray-400">{title}</span>
            <div class="flex-grow border-t border-4 border-gray-400"></div>
        </div>)
    }
    const {data, error, mutation} = useSWR(`https://api.bazookka.com/stats/nomatch`, url =>
    fetch(url, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            eid: globalId
        }
    }).then(r => r.json()))

    function bodyTemplate() {
        const user = data.find(elem => elem.id === currentUserId)
        if (user) {
            return (
                <div className="m-auto" style={{ fontWeight: "normal" }}>
                    {separatorWithTitle(t("mentorView.userView.details.contactInformations"))}
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.zohoId")} : </span> {user.meta.zohoId}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.address")} : </span> {user.meta.address}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.kidEmail")} : </span> {user.meta.email}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.kidPhone")} : </span> {user.meta.phone}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.schoolLevel")} : </span> {user.meta.schoolLevel}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.parentPhone")} : </span> {user.meta.parent.phone}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.parentEmail")} </span> {user.meta.parent.email}</div>
                    {separatorWithTitle("Trait")}
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.trait1")} : </span> {user.meta.traits.trait1}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.trait2")} : </span> {user.meta.traits.trait2}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.trait3")} </span> {user.meta.traits.trait3}</div>

                    {separatorWithTitle(t("mentorView.userView.details.interests"))}
                    {
                        <div className="grid grid-cols-1 divide-y-2">
                            {user.meta.interests.map((i, index) => {
                                let label1 = `mentorView.userView.details.sector${index + 1}`
                                let label2 = `mentorView.userView.details.sectorPrecision${index + 1}`
                                return <div key={index} className="mb-3" >
                                    <div>
                                        <div className="mb-3" style={{ fontWeight: "bold" }}>{t(label1)}: <span style={{ fontWeight: "normal" }} >{i.field}</span></div>
                                        <div style={{ fontWeight: "bold" }}>{t(label2)}:  <span style={{ fontWeight: "normal" }}>{i.reason}</span></div>
                                    </div>
                                </div>
                            })}
                        </div>
                    }




                </div>
            )
        }
        return ""
    }

    useEffect(() => {
        setI10nLoadContent(
            {
                'fr-FR': {
                    'grid': {
                        'EmptyDataSourceError': 'Erreur de chargement des données',
                        'EmptyRecord': `Aucun stagiaire á charger`,
                        'Item': 'stagiaire',
                        'Items': 'stagiaires',
                        'name': 'nom',
                        'Search': "Chercher"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} de {1}',
                        'totalItemsInfo': '({0} stagiaires)',
                        'totalItemInfo': '({0} stagiaire)'
                    }
                },
                'en-US': {
                    'grid': {
                        'EmptyDataSourceError': 'Error loading data',
                        'EmptyRecord': `There's no intern to load`,
                        'Item': 'intern',
                        'Items': 'interns',
                        'name': 'name',
                        'Search': "Search"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} of {1}',
                        'totalItemsInfo': '({0} interns)',
                        'totalItemInfo': '({0} intern)'
                    }
                }
            }
        )

    }, [])

    const usersGrid = [
        {
            width: '50',

            template: function Display(props) {
                return <Avatar name={props.display_name} size={40} round={true}/>
            }
        },
        {
            headerText: 'MRID',
            field: 'meta.MRID',
            width: '200',
        },
        {
            headerText: 'Nom',
            field: 'display_name',
            width: '200',
        },

        {
            headerText: 'Details',
            field: '',
            width: '200',
            template: function Buttons(props) {
                const { path } = useRouteMatch()
                const id = props.id
                return <button onClick={() => handleClick(id)}  ><i className="mdi mdi-eye-check" style={{ fontSize: '1rem' }}></i> </button>
            }

        },
    ]

    if(!data) return <Loader />

    return (
        <div className="mt-24 w-full">
            <DialogComponent allowDragging showCloseIcon animationSettings={{ effect: 'Zoom', duration: 400, delay: 0 }} cssClass="e-dlg-content" width='500px' isModal={true} header={headerTemplate} visible={hideDialog} close={dialogClose} overlayClick={onOverlayClick}>
                {bodyTemplate()}

            </DialogComponent>
            <SectionHeader title={'Aucune correspondance'} />
            <GridComponent
                locale={langChoice === "fr" ? "fr-FR" : "en-US"}
                dataSource={data}
                enableHover={false}
                allowPaging
                pageSettings={{ pageCount: 5 }}
                selectionSettings={selectionsettings}
                allowSorting
            >
                <ColumnsDirective>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    {usersGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
                </ColumnsDirective>
                <Inject services={[Page, Selection, Toolbar, Sort, Filter]} />
            </GridComponent>
        </div>
    )
}