import React, { useState, useEffect } from "react";
import {firebaseInstance} from "../../firestore/firestoreService";

const SubscriptionProfile  = ({ me }) => { 
    const [customerDetails, setCustomerDetails] = useState(null);
    const [paymentMethodDetails, setPaymentMethodDetails] = useState(null);
    const [invoiceDetails, setInvoiceDetails] = useState(null);
    const [processing, setProcessing] = useState('');
    const [error, setError] = useState(null);
    const isCustomer = "cus_J9l0Tnx9XEbrBf"; 

    useEffect(() => {
        if ( (customerDetails && paymentMethodDetails) || !isCustomer) {
            return;
        }
        setProcessing(true);
        (async () => {
            let getPaymentDetails = firebaseInstance().functions().httpsCallable('GetPaymentDetails');
            getPaymentDetails({ customerId: isCustomer }).then( res => {
                const { customer, paymentMethod, invoice } = res.data;
                if (customer.error) {
                    setError(`${customer.error.message}`);
                } else {
                    setCustomerDetails(customer); 
                    setPaymentMethodDetails(paymentMethod);
                    setInvoiceDetails(invoice);
                }
                setProcessing(false);
            }).catch ( err => {
                setError(err.message);
            });
        })();
    }, []); 
    
    const cancelSubscription = async (event) => {
        event.preventDefault();
    };   

    const updatePaymentMethod = async (event) => {
        event.preventDefault();
    };

    return (
        <div id="general-settings" className="settings-section is-active">
            <div className="settings-panel">
                { !isCustomer ? <div>Aucun paiement effectué.</div> :
                    processing || !paymentMethodDetails || !invoiceDetails  ? 
                    <span id="button-text"><div className="spinner" id="spinner"></div></span> : 
                    <div>
                        <h2>Paiement:</h2>
                            Nom sur la carte: { paymentMethodDetails.billing_details.name }<br/>
                            Adresse de facturation: { 
                                paymentMethodDetails.billing_details.address.line1  +` `+  
                                paymentMethodDetails.billing_details.address.line2  +` `+  
                                paymentMethodDetails.billing_details.address.city  +` `+  
                                paymentMethodDetails.billing_details.address.country  +` `+  
                                paymentMethodDetails.billing_details.address.postal_code 
                                }<br/>
                            Courriel: { paymentMethodDetails.billing_details.email }<br/>
                            Telephone: { paymentMethodDetails.billing_details.phone }<br/>
                            Type de carte: { paymentMethodDetails.card.brand }<br/>
                            Carte se terminant par: { paymentMethodDetails.card.last4 }<br/>
                            expire le: { paymentMethodDetails.card.exp_month } / { paymentMethodDetails.card.exp_year }<br/>

                        <b>Dernière facture:</b><br/>
                        Status de la facture : {invoiceDetails.status}<br/>
                        <a href={ invoiceDetails.hosted_invoice_url } target='_blank'>Accéder à la facture en ligne</a><br/>
                        <a onClick={(e) => cancelSubscription(e)}>Annuler l'abonnement</a><br/>
                        <a onClick={(e) => updatePaymentMethod(e)}>Changer de carte de paiement</a>
                    </div>
                }

                {error && (
                <div className="card-error" role="alert">
                    {error}
                </div>
                )}
            </div>
        </div>
    );
};

export default SubscriptionProfile;