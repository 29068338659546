import React, {useContext, useState, useEffect} from "react";
import ModalWrapper from "./index";
import {Formik, ErrorMessage, Form} from 'formik'
import {firestore, jobPosts, eventsDocs, postsDoc} from "../firestore/firestoreService";
import {format, isToday} from "date-fns";
import {MyContext} from "../stores";
import {useHistory} from "react-router-dom";
import {PageLoader} from "../components/OtherOvers";
import {InputDate, InputText, SwitchBox} from "../components/FormElements";
import RichTextEditor from "../components/RichTextEditor";
import {initialValue} from "../components/DisplayContentFromSlate";
import styled from "styled-components"
import {Multiselect} from 'multiselect-react-dropdown'
import {useTranslation} from "react-i18next";
import {useCatReducer} from "../stores/categories";
import * as Yup from 'yup';
import {ErrorStyle} from "../helper/Styled"
import { useAlert } from "react-alert";


export const StickyFooter = styled.div`
   display: flex;
   flex-direction: row;
   button, a {
     width: 50%;
   }   
`
export default function EditJobModal({job, event, page, type}){
    const {me, globalId, langChoice, content, setContent, dispatchModal} = useContext(MyContext)
    const [edit, setEdit] = useState(true)
    const [cat, setCat] = useState([])
    const [apicall, setApicall] = useState(false)
    const [data, setData] = useState(null)
    const [select, setSelect] = useState([])
    const {t} = useTranslation()
    const alert = useAlert()
    const [categories, dispatchAll] = useCatReducer()
    function validation( v ) {
        if(Array.isArray(v)) {
            return v
        }
        return [
            {
                type: 'paragraph',
                children: [
                    {
                        text: v,
                    },
                ],
            },
        ]
    }

    const validate = Yup.object({
        title: Yup.string()
            .min(3, t('general.nbcharactersmin'))
            .required(t('general.jobtitlerequired')),
        needed: Yup.number().min(1),
        tiny: Yup.string()
            .max(140, t('general.nbcharacters140'))
            .required(t('general.resumerequired')).default(''),
        date: Yup.date().required(t('general.startrequired')),
        // endDate: Yup.date().min(Yup.ref('date'),
        //     ({ min }) => `End date needs to be later than ${min}!!`).required(t('general.endrequired'))
        //category: Yup.array().min(1).required('Field required')

    })

    const initialValues = {
        title: '',
        needed: 1,
        tiny: '',
        desc: '',
        type,
        active: false,
        date: new Date(),
        endDate: new Date(),
        category: []
    }

    const onSubmit = (data) => {
        data.desc = content;
        data.type = type;
        try{
            data.date = new Date(data.date).getTime()
        }catch(e){}
        try{
            data.endDate = new Date(data.endDate).getTime()
        }catch(e){}
        data.category = cat;
        if(data.id) {
            if(job) {
                postsDoc.doc(data.id).update(data).then(r => {
                    //setSubmitting(false)
                    setTimeout(() => {
                        dispatchModal({type: 'CLOSE_MODAL'})
                    }, 500)
                })
            }
            if(event) {
                postsDoc.doc(data.id).update(data).then(r => {
                    //setSubmitting(false)
                    setTimeout(() => {
                        dispatchModal({type: 'CLOSE_MODAL'})
                    }, 500)
                })
            }

        }else{
            data.authorId = firestore.FieldValue.arrayUnion(globalId);
            data.author = {
                id: globalId,
                displayName: me.displayName
            }
            data.businessId = firestore.FieldValue.arrayUnion(me.select.id)
            if(!job) {
                postsDoc.add(data).then(r => {
                    //setSubmitting(false)
                    // setTimeout(() => {
                    //     history.goBack()
                    // }, 0)
                    dispatchModal({type: 'CLOSE_MODAL'})
                }).catch(e => {
                    alert.error(e.message)
                })
            }
            if(page === 'createEvent') {
                postsDoc.add(data).then(r => {
                    //setSubmitting(false)
                    // setTimeout(() => {
                    //     history.goBack()
                    // }, 0)
                    dispatchModal({type: 'CLOSE_MODAL'})
                }).catch(e => {
                    alert.error(e.message)
                })
            }
        }
    }


    useEffect(()=> {
        dispatchAll({type: langChoice === 'fr' ? 'ALL_SET_FR': 'ALL_SET_EN'})


        if(job) {
            setData({...job})
            if(job.category) {
                setSelect(job.category.map( c => {
                    return {key: c, value: t(c)}
                }))
            }

            setContent(validation(job.desc))
            setApicall(true)
        }

        if(event) {
            eventsDocs.doc(event).get().then( doc => {
                setData({id: doc.id, ...doc.data()})
                setApicall(true)
                setContent(validation(doc.data().desc))
            })
        }
        if(page === 'createEvent'){
            setData(initialValues)
            setApicall(true)
            setEdit(false)
        }

        if(!job) {
            setData(initialValues)
            setApicall(true)
            setEdit(false)
        }
        return ()=> {
            setContent(initialValue)
            setSelect([])
            setData(initialValues)
        }
    }, [apicall, type])

    const returnCat = (cat, list, values, a) => {
        return values[cat] && values[cat].map( k => {
            return list.find(l => l.key === k)
        }) || []
    }

    return (
        <ModalWrapper>
            <Formik initialValues={{...data}} onSubmit={onSubmit} validateOnMount={true} enableReinitialize={true} validationSchema={validate}>
                {({values, handleSubmit, errors})=> {
                    return (
                        <Form>
                            <div className="settings-form-wrapper">
                                <div className="settings-form">
                                    <div style={{width: '95%'}}>
                                        <div className="field">
                                            <InputText
                                                label={t('jobs.title')}
                                                value={values.title}
                                                name="title" />
                                            <ErrorStyle component='div' name='title' />
                                        </div>
                                        <div className="field">
                                            <InputText
                                                label={ langChoice === 'fr'? 'Petit résumé': 'Little summary'}
                                                name="tiny" />
                                            <ErrorStyle component='div' name='tiny' />
                                        </div>
                                        {categories && categories.map((cat, i)=> {
                                            return (
                                                <div className="field" key={i}>
                                                    <label>{t(cat[0])}
                                                        <div className="control">
                                                            <Multiselect
                                                                options={cat[1] ||[]}
                                                                displayValue="value"
                                                                placeholder={t('overlay.select')}
                                                                emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                                selectedValues={ returnCat(cat[0], cat[1], values, cat)}
                                                                onSelect={(v) => {
                                                                    values[cat[0]] = v.map( k => k.key)
                                                                }}
                                                                onRemove={(v) => {
                                                                    values[cat[0]] = v.map( k => k.key)
                                                                }}

                                                            >

                                                            </Multiselect>
                                                        </div>
                                                    </label>
                                                </div>
                                            )
                                        })}

                                        <div className="field">
                                            <InputDate
                                                label={langChoice === 'fr'? 'Date de début':'Start date'}
                                                value={new Date(values?.date || 0)}
                                                name="date"
                                            />
                                            <ErrorStyle component='div' name='date' />
                                        </div>

                                        <div className="field">
                                            <InputDate
                                                label={langChoice === 'fr'? 'Date de fin':'End date'}
                                                value={new Date(values?.endDate || 0)}
                                                name="endDate"
                                            />
                                            <ErrorStyle component='div' name='endDate' />
                                        </div>
                                        <div className="field">
                                            <SwitchBox
                                                label={langChoice === 'fr' ? 'Actif': 'Active'}
                                                name="active"
                                            />
                                            <ErrorStyle component='div' name='active' />
                                        </div>

                                        {/*<div className="field" style={{display:'flex', justifyContent: 'space-between'}}>*/}
                                        {/*    <button type="submit" className="button is-primary" disabled={isSubmitting}>{langChoice === 'fr' ? 'sauvegarder': 'save'}</button>*/}
                                        {/*    /!*<button onClick={(e) => returnBack(e, resetForm)} className="button is-danger">{langChoice === 'fr' ? 'ne pas sauvegarder': 'do not save'}</button>*!/*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <RichTextEditor />
                            <StickyFooter>
                                <button type="submit" className="button is-small is-link">{t('general.save')}</button>
                                <button className="button is-small is-danger"
                                 onClick={()=> dispatchModal({type: 'CLOSE_MODAL'})}
                                >{t('general.cancel')}</button>
                            </StickyFooter>
                        </Form>
                    )
                }}
            </Formik>
        </ModalWrapper>

    )
}
