import React, {useContext} from 'react'
import {MyContext} from "../../stores";
import {useTranslation} from "react-i18next";
import {PencilLineIco} from "../../ico";
import Avatar from "react-avatar";
import styled from "styled-components"
import {WrapedSubHeader} from "../../components/SubProfile";

const StyledConverBG = styled.div`
  width: 100%;
  position: relative;
  color: white;
  height: ${props => props.side && '75px' || 'auto'};
  .cover-image {
    height: ${props => props.side && '75px' || 'auto'};
  }
  .avatar {
    position: absolute;
    width: 75px;
    height: 75px;
    bottom: -50%;
    left: calc(50% - (75px/2));
    top: unset !important;
    right: unset !important;
    z-index: 10;
    
  }
  .avatar-button {
    position: absolute;
    top: 0;
    width: 75px !important;
    height: 75px !important;
    left: 0;
    opacity: 0;
    bottom: -50%;
    z-index: 12;
    :hover {
      opacity: .9;
    }
  }

`


export default function CoverBG({me, edit, nav}) {
    const {modalsReducer, dispatchModal} = useContext(MyContext)
    const {t} = useTranslation()

    const editCover = (e) => {
        e.preventDefault();
        dispatchModal({type: 'OPEN_MODAL', payload: {modalType:'CoverCropUploadModal', modalProps: {title: t('general.editcoverimage'), ico: <PencilLineIco />, closeOut: () => dispatchModal({type: 'CLOSE_MODAL'})}}})
    }

    const editAvatar = (e) => {
        e.preventDefault();
        dispatchModal({type: 'OPEN_MODAL', payload: {modalType:'AvatarCropUploadModal', modalProps: {title: t('genral.editAvatar'), ico: <PencilLineIco />, closeOut: () => dispatchModal({type: 'CLOSE_MODAL'})}}})
    }

    return (
        <React.Fragment>
            <StyledConverBG className="cover-bg" side={nav}>
                <img className="cover-image" src={(me && me.cover) ? me.cover : 'assets/old/wallpaper.png'}
                     data-demo-src="assets/img/demo/bg/4.png" alt="" />

                { nav && (<div className="avatar">
                    <Avatar  size={75} src={`${me && me.avatar}`} name={`${me && me.displayName}`} round={true} style={{zIndex: 1}}/>
                    {edit && (
                        <div className="avatar-button" onClick={editAvatar}>
                            <PencilLineIco />
                        </div>
                    )}
                </div>)}
                <div className="cover-overlay"></div>
                {edit && (
                    <a className="cover-edit modal-trigger" data-modal="change-cover-modal" onClick={editCover}>
                        <i className="mdi mdi-camera"></i>
                        <span>{t('general.editcoverimage')}</span>
                    </a>
                )}

            </StyledConverBG>
            {nav && me && (
                <WrapedSubHeader className="profile-subheader">
                    <div className="subheader-middle">
                        <h2 style={{color: 'white'}}>{me.displayName}</h2>
                        <span style={{color: 'white'}}>{me && me.select.name}</span>
                        <hr style={{backgroundColor: 'white', color: 'white', height: 1, width: '100%'}}/>
                    </div>

                </WrapedSubHeader>
            )}

        </React.Fragment>


    )
}

export const JustCoverBG = ({schools, displayName}) => {

    if(!schools) {
        return (
            <div className="cover-bg">
                <img className="cover-image" src={'assets/old/wallpaper.png'}
                     data-demo-src="assets/img/demo/bg/4.png" alt="" />

                <div className="avatar">
                    <Avatar
                        src={`assets/old/ico.png`}
                        name={`${displayName || 'Bazookka Inc.'}`}
                        round={true} style={{zIndex: 1, backgroundColor: 'white'}} size={'100%'} color={'white'}/>
                    <div className="pop-button is-far-left has-tooltip modal-trigger"
                         data-modal="change-profile-pic-modal" data-placement="right"
                         data-title="Change profile picture">
                    </div>
                </div>
                <div className="cover-overlay"></div>
                <div
                    className="dropdown is-spaced is-right is-accent dropdown-trigger timeline-mobile-dropdown is-hidden-desktop">
                    <div>
                        <div className="button">
                            <i data-feather="more-vertical"></i>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="cover-bg">
            <img className="cover-image" src={(schools[0].cover) ? schools[0].cover : 'assets/old/wallpaper.png'}
                 data-demo-src="assets/img/demo/bg/4.png" alt="" />

            <div className="avatar">
                <Avatar src={`${schools && schools[0].avatar}`} name={`${displayName || 'Bazookka Inc.'}`} round={true} style={{zIndex: 1}}/>
                <div className="pop-button is-far-left has-tooltip modal-trigger"
                     data-modal="change-profile-pic-modal" data-placement="right"
                     data-title="Change profile picture">
                </div>
            </div>
            <div className="cover-overlay"></div>
            <div
                className="dropdown is-spaced is-right is-accent dropdown-trigger timeline-mobile-dropdown is-hidden-desktop">
                <div>
                    <div className="button">
                        <i data-feather="more-vertical"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const DeskTopMenu = props => {
    return (
        <div className="profile-menu is-hidden-mobile"  style={{height: '1rem'}}>
            <div className="menu-start" style={{display: 'none'}}>
                <a >Timeline</a>
                <a >About</a>
            </div>
            <div className="menu-end" style={{display: 'none'}}>
                <a id="profile-students-link" >students</a>
                <a >Photos</a>
            </div>
        </div>
    )
}
