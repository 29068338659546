import React, {useContext} from 'react'
import styled from 'styled-components'
import { Trans } from "react-i18next";


const NextFooter = styled.div`
    background-color: var(--bazookka-darkblue);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    .imgLogo{
        margin-top: 0px;
    }
    .middle {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        color: #fff;
        line-height: 10px;
      }
    
    .h4Footer {
        font-size: 20px;
        font-weight: bold;
    }

    .footerLower{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        color: white;
        padding: 20px 70px;
      
    }
    .hrFooter{
        width: 100%;
        margin-top: 1.5rem;
        background-color: #f5f5f5;
        border: none;
        display: block;
        height: 1px;
    }
    .socialFooter{
        display:flex;
        flex-direction: row;
        justify-content: right;
    }
    .listSocial{
        display: inline;
        padding: 3px;
    }
    .a-footer{
        color: #fff;
    }
    .a-social{
        color: #fff;
    }
 
`
const Footer=(props)=>{
      return (
        <NextFooter>
          <div className="imgLogo">
            <img src="assets/img/logotypeB.png" alt="" />
          </div>
          <div className="middle" style={{display: 'none'}}>
            <div>
              <h4 className="h4Footer">
                <Trans i18nKey="footer.bazookka">Bazookka</Trans>
              </h4>
              <p>
                <a
                  href="http://bazookka.herokuapp.com/about"
                  className="a-footer"
                >
                  <Trans i18nKey="footer.about">About</Trans>
                </a>
              </p>
              <p style={{ color: "red" }}>
                <a href="#!" className="a-footer">
                  <Trans i18nKey="footer.career">Career</Trans>
                </a>
              </p>
            </div>
            <div>
              <h4 className="h4Footer">
                <Trans i18nKey="footer.legal">Legal</Trans>
              </h4>
              <p>
                <a href="#!" className="a-footer">
                  <Trans i18nKey="footer.termsandconditions">
                    Terms and Conditions
                  </Trans>
                </a>
              </p>
              <p className="pina-footer">
                <a href="#!" className="a-footer">
                  <Trans i18nKey="footer.privacypolicy">Privacy policy</Trans>
                </a>
              </p>
            </div>
          </div>
          <hr className="hrFooter" />
          <div className="footerLower">
            <div>
              <p className="footerCopyright">
                © 2021 Copyright:
                <a href="https://mdbootstrap.com/" className="a-footer">
                  <strong> Bazookka Inc.</strong>
                </a>
              </p>
            </div>
            <div className="socialFooter">
              <ul className="">
                <li className="listSocial">
                  <a className="a-social" target={'_blank'}  href={"https://www.facebook.com/StagesBazookka"} >
                    <i className="fab fa-facebook-f" aria-hidden="true"></i>
                  </a>
                </li>
                <li className="listSocial">
                  <a className="a-social" target={'_blank'} href={"https://twitter.com/_bazookka"} >
                    <i className="fab fa-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li className="listSocial">
                  <a className="a-social" target={'_blank'} href={"https://www.linkedin.com/company/bazookka/"}>
                    <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                  </a>
                </li>
                <li className="listSocial">
                  <a className="a-social" target={'_blank'} href={"https://www.instagram.com/bazookka.app/"}>
                  <i class="fab fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </NextFooter>
      );
      }
export default Footer;
