import ModalWrapper from "./index";
import React, {useState, useContext, useEffect} from 'react'
import {MyContext} from "../stores";
import {usersDoc} from "../firestore/firestoreService";
import {Multiselect} from 'multiselect-react-dropdown'
import {useTranslation} from "react-i18next";
import styled from 'styled-components';


const StyleStartCon = styled.div`
  position: relative;
  .inv-chat {
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 100px;
      color: var(--bazookka-pink);
      filter: drop-shadow(4px 4px 4px var(--bazookka-blue));
    }
  }
   a {
     color: var(--bazookka-yellow);
     background: var(--bazookka-blue-buble)
   }
`


export default function StartConversationModal({me, onNewConversation, closeOut}){
    const {t} = useTranslation()
    const [users, setUsers] = useState([])
    const [apicall, setApicall] = useState(true)
    const [select, setSelect] = useState([])

    const startNewConversation = () => {
        const usersIds = select.map( d => {
            return d.id;
        });
        onNewConversation(select, usersIds);
        closeOut();
    }

    useEffect(()=> {
        if(apicall && me) {
            usersDoc.where('schoolsId', 'array-contains', me.select.id).get()
                .then( doc => {
                    setUsers(doc.docs.map( u => {
                        return {id: u.id, ...u.data()}
                    }))
                    setApicall(false)
                })
        }
    }, [apicall, me])
    return (
        <ModalWrapper>
            <StyleStartCon>
                <h1>{t('general.startconversation')}</h1>
                <Multiselect
                    showArrow
                    options={users}
                    selectedValues={select}
                    onSelect={(v)=> setSelect(v)}
                    onRemove={(v)=> setSelect(v)}
                    displayValue="displayName"
                    placeholder={t('overlay.select')}
                    emptyRecordMsg={t("multiselect.noOptionAvailable")}
                />

                <div className="inv-chat" style={{height: 200}}>
                    <i className="mdi mdi-chat"></i>
                </div>
                <a className="button is-fullwidth" onClick={startNewConversation}>
                    {t('general.start')}
                </a>
            </StyleStartCon>

        </ModalWrapper>
    )
}
