import React, {useContext, useReducer} from 'react'
import fr from './language-ressources/fr.json'
import en from './language-ressources/en.json'
import { MyContext } from '.'
const SET_FR = 'SET_FR'
const SET_EN = 'SET_EN'
const ALL_SET_FR = 'ALL_SET_FR'
const ALL_SET_EN = 'ALL_SET_EN'

const initialtState = [];

export function useCatReducer(props){
    const [categories, dispatchCategories] = useReducer(Reducer, props)
    return [categories, dispatchCategories]
}
function Object2array(lang, cat = 'cat.'){
    let transformation_object_filter = Object.entries(lang).filter( getFirstItems => getFirstItems[0].includes(cat));
    return transformation_object_filter.map( data => {
        return {
            key: data[0],
            value: data[1]
        }
    })
}

function ObjectCatKey(lang) {
    let categories = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('cat.'))
        .map( data => {
        return {
            categories: 'categories',
            key: data[0],
            value: data[1]
        }
    })
    let skills = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('skills.'))
        .map( data => {
        return {
            categories: 'skills',
            key: data[0],
            value: data[1]
        }
    })
    let qualities = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('candidateQualities.'))
        .map( data => {
            return {
                categories: 'candidateQualities',
                key: data[0],
                value: data[1]
            }
        })
    let teamvalues = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('teamValues.'))
        .map( data => {
            return {
                categories: 'teamValues',
                key: data[0],
                value: data[1]
            }
        })

        let teamvaluesCompany = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('teamValuesCompany.'))
        .map( data => {
            return {
                categories: 'teamValuesCompany',
                key: data[0],
                value: data[1]
            }
        })
        
    let workactivities = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('workActivities.'))
        .map( data => {
            return {
                categories: 'workActivities',
                key: data[0],
                value: data[1]
            }
        })

        let workactivitiesCompany = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('workActivitiesCompany.'))
        .map( data => {
            return {
                categories: 'workActivitiesCompany',
                key: data[0],
                value: data[1]
            }
        })

    let contracttypes = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('contractTypes.'))
        .map( data => {
            return {
                categories: 'contractTypes',
                key: data[0],
                value: data[1]
            }
        })
    let supervisiontype = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('supervisionType.'))
        .map( data => {
            return {
                categories:  'supervisionType',
                key: data[0],
                value: data[1]
            }
        })
    
        let supervisionTypeCompany = Object
        .entries(lang)
        .filter( getFirstItems => {
            return getFirstItems[0].includes('supervisionTypeCompany.')
        })
        .map( data => {
            return {
                categories:  'supervisionTypeCompany',
                key: data[0],
                value: data[1]
            }
        })


    let benefits = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('benefits.'))
        .map( data => {
            return {
                categories: 'benefits',
                key: data[0],
                value: data[1]
            }
        })

        let benefitsCompany = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('benefitsCompany.'))
        .map( data => {
            return {
                categories: 'benefitsCompany',
                key: data[0],
                value: data[1]
            }
        })

    let mot = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('mot.'))
        .map( data => {
            return {
                categories: 'mot',
                key: data[0],
                value: data[1]
            }
        })

        let motCompany = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('motCompany.'))
        .map( data => {
            return {
                categories: 'motCompany',
                key: data[0],
                value: data[1]
            }
        })


    
    let leader = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('leader.'))
        .map( data => {
            return {
                categories: 'leader',
                key: data[0],
                value: data[1]
            }
        })

        let leaderCompany = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('leaderCompany.'))
        .map( data => {
            return {
                categories: 'leaderCompany',
                key: data[0],
                value: data[1]
            }
        })
    let disploma = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('disploma.'))
        .map( data => {
            return {
                categories: 'disploma',
                key: data[0],
                value: data[1]
            }
        })
    let field = Object
        .entries(lang)
        .filter( getFirstItems => getFirstItems[0].includes('field.'))
        .map( data => {
            return {
                categories: 'field',
                key: data[0],
                value: data[1]
            }
        })

    return [
        ['disploma',disploma],
        ['categories',categories],
        ['qualities',qualities],
        ['skills',skills],
        ['teamvalues',teamvalues],
        ['teamvaluesCompany',teamvaluesCompany],
        ['workactivities',workactivities],
        ['workActivitiesCompany',workactivitiesCompany],
        ['contracttypes',contracttypes],
        ['supervisontype',supervisiontype],
        ['supervisionTypeCompany',supervisionTypeCompany],
        ['mot',mot],
        ['motCompany',motCompany],
        ['benefits',benefits],
        ['benefitsCompany',benefitsCompany],
        ['leader',leader],
        ['leaderCompany',leaderCompany],
        ['field',field],
    ]
}
function Reducer(state = initialtState, {type, payload}) {

    switch(type) {
        case SET_EN:
            return Object2array(en, payload)
        case SET_FR:
            return Object2array(fr, payload);
        case ALL_SET_EN:
            return ObjectCatKey(en);
        case ALL_SET_FR:
            return ObjectCatKey(fr);
        default:
            return [];
    }
}
