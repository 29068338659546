import React, {useState, useEffect, useContext} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {contactsDocs, usersDoc, activeJobs} from "../firestore/firestoreService";
import CoverBG, {DeskTopMenu} from "./main/CoverBG";
import SubProfile from "./main/SubProfile";
import {format} from "date-fns";
import {MyContext} from "../stores";
import styled from "styled-components";
import {useTranslation} from "react-i18next";


const MainUsers = styled.div`
  .content-section {
    margin-bottom: 1rem;
  }

  .content-section > .inButton {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 1rem;
    button {
      background: var(--bazookka-blue);
      border: 1px solid var(--bazookka-blue);
      filter: drop-shadow(2px 1px 1px rgba(0,0,0,0.32));

        i{
          transition: all 100ms ease-in;
        }

      .label{
          transition: all 100ms ease-in;
          color: var(--bazookka-white);
          margin-left:10px;
      }
    }
    button:hover {
      filter: drop-shadow(3px 2px 5px rgba(0,0,0,0.32));
      width: 100%;
      i {
        transition: all 100ms ease-in;
        font-size: 45px;
      }

      .label{
        transition: all 100ms ease-in;
        font-size: 25px;
      }
    }
    
    i {
      font-size: 25px;
      color: white;
    }
  }
  .container.is-custom {
    width: 100%;
    max-width: 100%;
  }
  .other-body {
    display: flex;
    flex-direction: row;
    padding: 1rem;
  }
  .pills {
    padding: 5px 10px;
    background: var(--bazookka-blue-buble);
    color: white;
    
    border-radius: 5px;
    width: auto;
    margin: 3px;
  }
  .about-card .body{
    padding-left:40px;
  }
  @media screen and (min-width: 1088px) {
    .container.is-custom {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  @media screen and (max-width: 767px) {
    .content-section > .inButton {
        grid-template-columns: unset;
    }
  }
`
const CardInUserPageView = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
  .about-card {
    margin-bottom: 1rem;
  }
  .bringcenter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    div {
      font-size: 10px;
    }

    .reason{
        color:grey;
        font-size:12px;
    }
  }
  
  .fframe{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
   
    .ico {
      justify-content: center;
      color: white;
      background: var(--bazookka-orange);
      display: flex;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      border: 1px solid var(--bazookka-orange);
      filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.25));
    }
  }
 
`
export default function UserProfilePage(){
    const {userId} = useParams()
    const {me, globalId, dispatchModal} = useContext(MyContext)
    const [backgroundColor, setColor] =useState('white')
    const [user, setUser] = useState(null)
    const [internship, setInternship] = useState(null)
    const [userKeys, setUserKeys] = useState([])
    const history = useHistory()
    const {t} = useTranslation()

    const openChat = async (user) => {
        let contact = [globalId, user.id].sort().join("_")
        const doExist = await contactsDocs
            .where('contact', '==', contact)
            .get()
        if(doExist.empty) {
            await contactsDocs.add({
                type: 'users',
                users: [
                    {id: globalId, ...me},
                    user
                ],
                usersIds: [
                    globalId,
                    user.id
                ],
                contact
            })
        } else {

        }
        let info = await contactsDocs.where('contact', '==', contact).limit(1).get()
        if(!info.empty) {
            history.push(`/chat/${info.docs[0].id}`)
        }
    }

    const onMeet = () => {
        dispatchModal({
            type: 'OPEN_MODAL',
            payload: {
                modalType: 'EditMeetingModal',
                modalProps: {
                    title: t("general.create"),
                    ico: <i className="mdi mdi-calendar"></i>,
                    attend: user
                }
            }
        })
    }

    useEffect(()=>{
        if(userId){
            usersDoc.doc(userId).get().then( doc => {
                setUser({id: doc.id, ...doc.data()})
                setUserKeys(Object.keys(doc.data()));
            })
            
            activeJobs.where(`relatedPeoples.${userId}`, '==', true)
                .onSnapshot( data => {
                    setInternship(data.docs.map( intr => {
                        if(intr.data().student.id === userId){
                            return {id: intr.id, ...intr.data()}
                        }
                    }))
                })
        }
    }, [userId])

    return (
        <MainUsers className="view-wrapper" style={{backgroundColor}}>
            <div className="container is-custom">
                <div id="profile-main" className="view-wrap is-headless">
                    <div className="columns is-multiline no-margin">
                        <div className="column is-paddingless">
                            <CoverBG me={user}/>
                            <DeskTopMenu />
                            <SubProfile me={user} />
                            {/*<NavigationProfile />*/}
                            <div className="column">
                                <div className="content-section is-active">
                                    <div className="inButton">
                                        <button className="button" onClick={()=> openChat(user)}>
                                            <i className="mdi mdi-chat"></i>
                                            <span className="label">{t('overlay.chat')}</span>
                                        </button>
                                        {/*<button className="button">*/}
                                        {/*    <i className="mdi mdi-check-bold"> </i>*/}
                                        {/*    <span className="label">{t('internship')}</span>*/}
                                        {/*</button>*/}
                                        <button className="button" onClick={onMeet}>
                                            <i className="mdi mdi-calendar-clock"></i>
                                            <span className="label">{t('general.createappointment')}</span>
                                        </button>
                                    </div>

                                </div>
                                {userKeys.includes("school") &&
                                    <div className="content-section is-active">
                                        <div className="about-card">
                                            <div className="header">
                                                <div className="icon-title">
                                                    <h3>{t('general.schools')}</h3>
                                                </div>
                                            </div>
                                            
                                            <div className="body">
                                                {user.school}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {userKeys.includes("intro") &&
                                    <div className="content-section is-active">
                                        <div className="about-card">
                                            <div className="header">
                                                <div className="icon-title">
                                                    <h3>{t('listing.intro')}</h3>
                                                </div>
                                            </div>
                                            
                                            <div className="body">
                                                {user?.intro?.split("\n").map( (text, i) => {
                                                    return (
                                                        <p key={i} style={{paddingBottom: 5}}>{text}</p>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {userKeys.includes("experiences") &&
                                    <div className="content-section is-active" style={{marginBottom: '1rem'}}>
                                        <div className="about-card">
                                            <div className="header">
                                                <div className="icon-title">

                                                    <h3>{t('settings.wexperi')}</h3>
                                                </div>
                                            </div>
                                            <div className="body">
                                                {user?.experiences?.reverse(function(a, b){
                                                    return b.startDate - a.startDate
                                                }).map((v, key)=> {
                                                    return <CardInUserPageView key={key}>
                                                        <div className="fframe">
                                                            <div className="ico">
                                                                <i className="mdi mdi-briefcase-account"></i>
                                                            </div>
                                                        </div>
                                                        <div className="bringcenter">
                                                            <h3>{v.title}</h3>
                                                            <div>{format(new Date(v.startDate), "MM yyyy")} - {format(new Date(v.endDate), "MM yyyy")}</div>
                                                        </div>
                                                    </CardInUserPageView>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {userKeys.includes("educations") &&
                                    <div className="content-section is-active">
                                        <div className="about-card">
                                            <div className="header">
                                                <div className="icon-title">
                                                    <i className="mdi mdi-chair-school"></i>
                                                    <h3>{t('settings.educert')}</h3>
                                                </div>

                                            </div>
                                            <div className="body">
                                                {user?.educations?.reverse(function(a, b){
                                                    return a.startDate - b.startDate
                                                }).map((v, key)=> {
                                                    return <CardInUserPageView key={key}>
                                                        <div className="fframe">
                                                            <div className="ico">
                                                                <i className="mdi mdi-school"></i>
                                                            </div>
                                                        </div>
                                                        <div className="bringcenter">
                                                            <h3>{v.title}</h3>
                                                            <div>{format(new Date(v.startDate), "MM yyyy")} - {format(new Date(v.endDate), "MM yyyy")}</div>
                                                        </div>
                                                    </CardInUserPageView>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {userKeys.includes("languages") &&
                                    <div className="content-section is-active">
                                        <div className="about-card">
                                            <div className="header">
                                                <div className="icon-title">
                                                    <h3>{t('settings.languages')}</h3>
                                                </div>

                                            </div>
                                            <div className="other-body">
                                                {Object.entries(user.languages).map((v, key)=> {
                                                    return <div key={key} className="pills">{t('languages.'+v[0])}: {v[1]}</div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {userKeys.includes("interests") &&
                                    <div className="content-section is-active">
                                        <div className="about-card">
                                            <div className="header">
                                                <div className="icon-title">
                                                    <h3>{t('settings.interests')}</h3>
                                                </div>

                                            </div>
                                            <div className="body">
                                                {Object.entries(user.interests).map((v, key)=> {
                                                    return <CardInUserPageView key={key}>
                                                        <div className="fframe">
                                                            <div className="ico">
                                                                <i className="mdi mdi-checkbox-blank-circle-outline"></i>
                                                            </div>
                                                        </div>
                                                        <div className="bringcenter">
                                                            <h3>{v[1].field}</h3>
                                                            <p className='reason'>{v[1].reason}</p>
                                                        </div>
                                                    </CardInUserPageView>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {userKeys.includes("traits") &&
                                    <div className="content-section is-active">
                                        <div className="about-card">
                                            <div className="header">
                                                <div className="icon-title">
                                                    <h3>{t('settings.traits')}</h3>
                                                </div>

                                            </div>
                                            <div className="body">
                                                {Object.entries(user.traits).map((v, key)=> {
                                                    return <CardInUserPageView key={key}>
                                                        <div className="fframe">
                                                            <div className="ico">
                                                                <i className="mdi mdi-checkbox-blank-circle-outline"></i>
                                                            </div>
                                                        </div>
                                                        <div className="bringcenter">
                                                            <h3>{v.join(': ')}</h3>
                                                        </div>
                                                    </CardInUserPageView>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                }
                                        {user && ['categories',
                                            'skills',
                                            'mot',
                                            'disploma',
                                            'workEnv',
                                            'qualities',
                                            'teamvalues',
                                            'workactivities',
                                            'contracttypes',
                                            'benefits',
                                            'leader',
                                        ].map( k => {
                                            if(user[k]) {
                                               return [user[k], k];
                                            }
                                            return undefined;
                                        }).filter( a => Array.isArray(a))
                                            .map((elem, key) => {
                                            return (
                                                <div key={key} className="content-section is-active">
                                                    <div className="about-card">
                                                        <div className="header">
                                                            <div className="icon-title">
                                                                <i className="mdi mdi-chair-school"></i>
                                                                <h3>{t(elem[1])}</h3>
                                                            </div>
                                                        </div>
                                                <div className="other-body" key={key}>
                                                    {elem && elem[0].map( (e, i) => {
                                                        return <div key={i} className="pills">{t(e)}</div>
                                                    })}
                                                </div>
                                                        </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
        </MainUsers>
    )
}
