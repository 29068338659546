import React, {useState, useEffect, useContext} from "react";
import {MyContext} from "../../stores";
import styled from 'styled-components'
import {useParams, useHistory}  from 'react-router-dom'
import {useTranslation} from "react-i18next";
import Answers from "./Answers";
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CenteredTabs from "./CenteredTabs";
import QuestionForm from "./QuestionForm";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Preview from "./Preview";
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import InputBase from '@material-ui/core/InputBase'
import {v4 as uuid} from "uuid";
import {IconButton, FormGroup, InputAdornment} from "@material-ui/core";
import {Save as SaveIcon, BorderColorRounded as EditIcon, Share as SendIcon, Close as CloseIcon} from "@mui/icons-material";
import {formTemplates, groupsDoc, users as usersDoc} from "../../firestore/firestoreService";
import {GlobeIco} from "../../ico";

const ToolBarCus = styled(Toolbar)`
    & header.MuiAppBar-colorPrimary {
      background-color: var(--bazookka-blue) !important;
    }
`

const EditFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0px;
  z-index: 10000;
  background: var(--bazookka-default-bgcolor);
  min-height: 100vh;
  width: 100vw;
  max-height: fit-content;
  .navBar {
    display: grid !important;
    grid-template-columns: 70px 3fr minmax(50px, 100px) 20px;
    align-items: center;
    background: white;
    width: 100%;
    height: 90px;
    .name {
      background: var(--bazookka-blue) !important;
      color: white;
      border: none;
      height: 90%;
      font-size: 90%;
      width: fit-content;
    }
  }
  .main {
    margin-top: 2rem;
    max-width: 90vw;
    min-width: 320px;
    padding: 2rem;
    
  }
`

function allyProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function EditFormComponent(){
    const {slug} = useParams();
    const history = useHistory()
    const {t} = useTranslation()
    const {globalId, me, dispatchModal} = useContext(MyContext)
    const [name, setName] = useState(t('formBuilder.defaultTitle'))
    const [value, setValue] = useState(0)
    const [desc, setDesc] = useState('')
    const [questions, setQuestions] = useState([])
    const [form, setForm] = useState(null)
    const [type, setType] = useState('form.question')
    const [groups, setGroups] = useState([])
    const [formUsers, setFormUsers] = useState([])
    const [options, setOptions] = useState([])
    const [checked, setChecked] = useState(false)
    const [didExecuteApi, setDidExecuteApi] = useState(false)

    async function onSave(){
        if(slug) {
            await formTemplates.doc(slug).update({name, questions, desc, type, signature: checked})
        } else {
            let c = await formTemplates.add({name, questions, adminId: [globalId], desc, type, signature: checked})
            await formTemplates.doc(c.id).collection('users').doc(globalId)
                .set({id: globalId, displayName: me.displayName, avatar: me.avatar})
            history.push(`/formcreator/${c.id}`)
        }
    }
    const handleChange = (e, v) => setValue(v)
    const delay =  () => {
        return new Promise( resolve => {
            setTimeout(()=> {
                resolve()
            }, 1000)
        })
    }

    const onShare = async () => {
        if(groups.length > 0 && formUsers.length > 0){
            await delay()
            dispatchModal({
                type: "OPEN_MODAL",
                payload: {
                    modalType: "FormInviteModal",
                    modalProps: {
                        title: t("formCreator.share"),
                        ico: <GlobeIco />,
                        template: form,
                        formUsers: formUsers,
                        options: options,
                        groups,
                        closeOut: () => dispatchModal({ type: "CLOSE_MODAL" }),
                    },
                },
            });
        }
    }

    useEffect(()=>{
        (async ()=> {
            if(!me?.select) {
                return
            }
            let grp = await groupsDoc.where("school", "==", me.select.id).get()
            let usr = await usersDoc.where("schoolsId", "array-contains-any", [me.select.id]).get()

            if(!grp.empty) {
                setGroups(grp.docs.map( doc => {
                    return {id: doc.id, ...doc.data(), label: doc.data().name, value: doc.id}
                }))
            }
    
            if(!usr.empty) {
                setFormUsers(usr.docs.map( doc => {
                    return {id: doc.id, ...doc.data()}
                }))
                setOptions(usr.docs.map( doc => {
                    return {id: doc.id, label: doc.data().displayName, value: doc.id}
                }));
            }
            
        })()
       
    }, [me, globalId])

    useEffect(()=>{
        let listener;
        if(!didExecuteApi && slug) {
            listener = formTemplates.doc(slug).onSnapshot(doc => {
                setDidExecuteApi(true)
                setName(doc.data().name)
                setQuestions(doc.data().questions)
                setDesc(doc.data().desc)
                setType(doc.data()?.type)
                setForm({id: doc.id, ...doc.data()})
                setChecked(doc.data().signature || false)
            })
        }
        return listener
    }, [didExecuteApi, slug])

    return (
        <EditFormStyled>
            <div className="navBar navbar is-inline-flex is-transparent" id="main-navbar">
            </div>
            <AppBar>
                <ToolBarCus style={{backgroundColor: 'var(--bazookka-blue)' }}>
                    <IconButton>
                        <a className="" href="/">
                            <img src="/assets/old/ico.png" width="50" height="50" />
                        </a>
                    </IconButton>
                    <FormGroup>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Enter name"
                            endAdornment={<EditIcon style={{fill: 'white'}}/>}
                            value={name}
                            onChange={ v => setName(v.target.value)}
                        />
                    </FormGroup>
                    {slug && (
                        <IconButton onClick={onShare}>
                            <SendIcon style={{fill: 'white'}} ></SendIcon>
                        </IconButton>
                    )}

                    <IconButton onClick={onSave}>
                        <SaveIcon color="danger" style={{fill: 'white'}} />
                    </IconButton>
                    <IconButton onClick={ e => history.goBack()}>
                        <CloseIcon style={{fill: 'white'}}></CloseIcon>
                    </IconButton>

                </ToolBarCus>
            </AppBar>
            <Paper style={{width: '100%'}}>
                <Tabs
                    textColor="primary"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    centered

                >

                    <Tab label={t("formCreator.tabs.questions")} {...allyProps(0)}></Tab>
                    <Tab label={t("formCreator.tabs.preview")} {...allyProps(1)}></Tab>
                    <Tab label={t("formCreator.tabs.answers")}  {...allyProps(2)}></Tab>
                </Tabs>
            </Paper>

            <div className="main">
                <CenteredTabs value={value} index={0}>
                    <QuestionForm
                        desc={desc}
                        setDesc={setDesc}
                        type={type}
                        setType={setType}
                        checked={checked}
                        setChecked={setChecked}
                        name={name} questions={questions} setQuestions={setQuestions}/>
                </CenteredTabs>
                <CenteredTabs value={value} index={1}>
                    <Preview desc={desc}
                             setDesc={setDesc}
                             checked={checked}
                             type={type}
                             name={name} questions={questions} setQuestions={setQuestions}></Preview>
                </CenteredTabs>
                <CenteredTabs value={value} index={2}>
                    <Answers slug={slug} questions={questions} title={name}></Answers>
                </CenteredTabs>
            </div>
        </EditFormStyled>
    )
}
