import React, {useContext, useEffect, useState} from "react";
import {MyContext} from "../../stores";
import {useParams} from "react-router-dom";
import Swal from "sweetalert2";
import {db} from "../../firestore/firestoreService";
import {formatDistance} from "date-fns";
import {PencilLineIco} from "../../ico";
import { useTranslation } from "react-i18next";

const ListEduJob = ({data, page}) => {
    let {dispatchModal, globalId, me, langChoice, locales} = useContext(MyContext)
    const [locale, setLocale] = useState(locales.enCA)
    const {t} = useTranslation()

    useEffect(()=> {
        if(langChoice === 'fr') {
            setLocale(locales.frCA)
        }else {
            setLocale(locales.enCA)
        }
    }, [langChoice])
    const onDelete = (data, idx) => {
        Swal.fire({
            title: t("general.job.delete"),
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: t("general.job.deleteConfirm"),
            denyButtonText: t("general.job.deleteDecline"),
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                me[page].splice(idx, 1)
                db.collection('users').doc(globalId).update(me).then( data => {
                    Swal.fire('Saved!', '', 'success')
                })

            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    const editEntries = (data, i) => {
        dispatchModal({type: 'OPEN_MODAL', payload: {
            modalType: 'EducationModal',
            modalProps:
                {
                    page,
                    title: t("general.editeducationTitle"),
                    data,
                    cursor: i,
                    edit: true,
                    closeOut: () => dispatchModal({type: 'CLOSE_MODAL'})}}})

    }

    return (
        <div className="columns true-dom">
            <div className="column">
                <div className="question-content">
                    { data && Array.isArray(data) && data.map( (e, i) => {
                        return (
                            <div className="question-block is-top-spaced" key={i}>
                                {/*<!-- Title -->*/}
                                <h2 className="question-title is-link">{e.title}</h2>
                                {/*<!-- Author -->*/}
                                <div className="question-author">
                                    <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/brian.jpg"
                                         data-user-popover="19" alt="" />
                                    <div className="meta">
                                        <span>{e.name}</span>
                                        <span>{formatDistance(
                                            new Date(e.startDate).getTime(),
                                            new Date().getTime(),
                                            {
                                                addSuffix: true,
                                                locale: locales.frCA
                                            }
                                        )}</span>
                                    </div>
                                </div>
                                {/*<!-- Content -->*/}
                                <div className="question-text">
                                    {/*<div className="tags">
                                        <span className="tag">stylesheets</span>
                                        <span className="tag">sass</span>
                                        <span className="tag">mixins</span>
                                        <span className="tag">bourbon</span>
                                    </div>*/}
                                </div>
                                {/*<!-- Footer -->*/}
                                <div className="question-footer">
                                    <div className="likes">
                                        <div className="like-icon" onClick={() => editEntries(e, i)} style={{cursor: 'pointer'}}>
                                            <PencilLineIco />
                                        </div>
                                        <div style={{width: 20}}></div>
                                        <div className="like-icon" style={{cursor: 'pointer'}} onClick={()=> onDelete(e,i)}>
                                            <i className="mdi mdi-delete-forever" style={{color: 'white'}}></i>
                                        </div>
                                    </div>

                                </div>
                                <div className="answers-count">

                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ListEduJob
