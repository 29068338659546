import React, {useContext, useEffect, useState} from 'react'
import {E404} from "../ico";
import {Link, useHistory} from 'react-router-dom'
import {MyContext} from "../stores";
import { Trans, useTranslation } from "react-i18next";
import styled from 'styled-components'

const StyledError = styled.section`
  a {
    margin: 0px 5px;
    position: relative;
    overflow: hidden;
  }
  a:hover .wave{
    top: 0px;
  }
  a:hover span {
    color: white;
    z-index: 5;
    display: block;
  }
  
  .wave {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: calc(100% + 22px);
    background-color: var(--bazookka-blue);
    transition: all 1s;
  }
  .wave:before {
    content: "";
    position: absolute;
    top: -20px;
    width: calc(100% + 1px);
    left: 0px;
    background: url(assets/img/svg/wave.svg);
    display: block;
    height: 22px;
    
    animation: wave 0.5s linear infinite;
  }
  //.wave:after {
  //  content: "";
  //  position: absolute;
  //  top: -20px;
  //  width: 100%;
  //  left: 0px;
  //  border-radius: 33% 15%;
  //  height: 24px;
  //  background: var(--bazookka-blue);
  //  animation: wave 0.5s linear infinite;
  //}
  
  @keyframes wave {
      0% { background-position-x: 0 }
    100% { background-position-x: 110px;}
}
`

export default function ErrorPage({isMedium}){
    const {langChoice, me} = useContext(MyContext)
    const [wasme, Setwasme] = useState(me)
    const [one, setOne] = useState(false)
    const history = useHistory()

    useEffect(()=> {

        if(me && me.displayName) {
          setOne(true)
          history.push('me')
        }
    },  [me])

    const {t} = useTranslation()
    return (
      <StyledError className={`section ${!isMedium && 'is-medium'}`}>
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column has-text-centered">
              <h1 className="title">
                <Trans i18nKey="error:error.404">404 Page Not Found</Trans>
              </h1>
              <p className="subtitle">
                <Trans i18nKey="error:error.404.message">
                  An unexpected error has occurred. Please contact the site
                  owner.
                </Trans>
              </p>
              <Link className="button" to={`/`}>
                <span><Trans i18nKey="common:general.home">{t('general.home')}</Trans></span>
                <div className="wave"></div>
              </Link>
              <Link className="button">
                <span>{t('general.contact')}</span>
                <div className="wave"></div>
              </Link>
            </div>
            <div className="column has-text-centered">
              <E404 size={400} />
            </div>
          </div>
        </div>
      </StyledError>
    );
}
