import React from 'react'
import {useTranslation} from "react-i18next";
import styled from 'styled-components'


function passColor(color){
    switch(color){
        case 'jobs':
            return 'var(--bazookka-orange)'
        break;
        case 'internships':
            return 'var(--bazookka-pink)'
        break;
        case 'post':
            return 'var(--bazookka-blue)'
        break;
        case 'event':
            return 'var(--bazookka-green)'
        break;
        default:
            return 'var(--bazookka-orange)'
    }
}
const StyleTile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${ props => passColor(props.type)};
  border-radius: 5px;
  margin-bottom: ${ props => props.marginBottom || '0px'};
  color: white;
  margin-left: ${ props => props.margin && '16px'|| '0px'};
  margin-top: ${ props => props.margin  && '16px'|| '0px'};
  height: 42px;
  width: fit-content;
  padding: 0px 12px;
  font-size: 20px;
  filter: drop-shadow(0 0.1rem 0.1rem rgba(0, 0, 0, 0.5));
  .to-left {
    width: 33px;
    height: 33px;
  }
  .to-right {}
`

export default function TileComponent({ico, label, margin, marginBottom}) {
    const {t} = useTranslation()
    return (
        <StyleTile margin={margin} type={label} marginBottom={marginBottom}>
            <div className="to-left">
                {ico || <i className="mdi mdi-plus"></i>}
            </div>
            <div className="to-right">
                {label && t(label) || 'no text'}
            </div>
        </StyleTile>
    )
}
