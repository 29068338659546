import React, {useState, useEffect, useContext} from 'react'
import {firebaseInstance, uToken, usersDoc} from "../firestore/firestoreService";
import {useHistory} from "react-router-dom";
import {MyContext} from "../stores";
import styled from "styled-components"

const DisplayLoad = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 360px;
  }
`

export default function Popup(){
    const {me, setMe, verifyAuth, scope} = useContext(MyContext)
    const  [code, setCode] = useState(getURLParameter('code'));
    const  [state, setState] = useState(getURLParameter('state'));
    const  [error, setError] = useState(getURLParameter('error'));

    const history = useHistory()

    function getURLParameter(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.search) ||
            [null, ''])[1].replace(/\+/g, '%20')) || null;
    }

    /**
     * Returns the ID of the Firebase project.
     */
    function getFirebaseProjectId() {
        return firebaseInstance().app().options.authDomain.split('.')[0];
    }

    /**
     * This callback is called by the JSONP callback of the 'token' Firebase Function with the Firebase auth token.
     */
    async function tokenReceived(data) {
        let doc = await uToken.doc(data).get()
        
        if (data) {
            firebaseInstance().auth().signInWithCustomToken(doc.data().token).then(async function(user) {
                let udoc = await usersDoc.doc(user.user.uid).get()
                setMe(udoc.data())
                verifyAuth()
                window.close();
            });
        } else {
            console.error(data);
            document.body.innerText = 'Error in the token Function: ' + data.error;
        }
    }


    useEffect(()=> {
        if (error) {
            document.body.innerText = 'Error back from the LinkedIn auth page: ' + error;
        } else if(!code) {
            // Start the auth flow.
            setTimeout(()=> {
                window.location.href  = `https://auth.bazookka.com/auth/linkedin`;
            }, 1500)

        } else {
            // Use JSONP to load the 'token' Firebase Function to exchange the auth code against a Firebase custom token.
            // const script = document.createElement('script');
            // script.type = 'text/javascript';
            // This is the URL to the HTTP triggered 'token' Firebase Function.
            // See https://firebase.google.com/docs/functions.
            // var tokenFunctionURL = 'https://us-central1-bazookka-app.cloudfunctions.net/token';
            // script.src = tokenFunctionURL +
            //     '?code=' + encodeURIComponent(code) +
            //     '&state=' + encodeURIComponent(state) +
            //     '&callback=' + tokenReceived.name;
            // document.head.appendChild(script);
            tokenReceived(code)
        }
    })

    return (
        <DisplayLoad>
            <img src="assets/old/bazookka_anim.gif" />
        </DisplayLoad>
    )
}